import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { DynamicComponentsService, LibHttpClientApiService } from '@herdia-common';
import { AppService } from 'src/app/core/shared/service/app.service';
import { ICardConfig } from '../../../herdia-common/lib-interface-card/lib-interface-card.interfaces';
import { HaPageCard, HaPageRowVM } from '../../shared/models/interfaces';
import { CardUrls } from '../../shared/service/api.service';
import { CardService } from '../../shared/service/card.service';

@Component({
    selector: 'app-card-box',
    templateUrl: './card-box.component.html',
    styleUrls: ['./card-box.component.scss'],
})
export class CardBoxComponent implements OnInit {

    private dynamicComponentsService = inject(DynamicComponentsService);

    @Input() cardId: number = 0;
    @Input() card!: HaPageCard;
    @Input() rowAvailableSpace: number = 0;
    @Input() isDynamic: boolean = true;
    @Input() row!: HaPageRowVM;

    @Output() onCardChange = new EventEmitter();

    editMode = false;
    cardConfigured = false;
    collapsedCard = false;
    maximizedCard = false;
    iconCard: IconName = "" as IconName;

    cardInterface: ICardConfig = {
        allowFullScreen: true, allowMobileView: true, hasButton: true, hasFooter: true, hasHeader: true, isConfigurable: true, minWidth: 1, cardCustomClasses: "", configTreeNode: []
    };

    constructor(
        private cardSvc: CardService,
        public appSvc: AppService,
        private apiSvc: LibHttpClientApiService
    ) {
        cardSvc.closeSaveConfigSubject.subscribe(() => {
            this.onCardChange.next(true);
        });
    }

    ngOnInit(): void {
        const cardConfig = this.dynamicComponentsService.getCardComponentConfig(this.card.CardTypeLabel);
        if (cardConfig) {
            this.cardInterface = cardConfig;
            !this.cardInterface.isConfigurable ? this.cardConfigured = true : this.cardConfigured = false;
            this.cardConfigured = this.isDynamic == false || (this.card.Configuration !== undefined && Object.keys(this.card.Configuration).length > 2);
        }

        this.appSvc.editModeSub.subscribe(mode => {
            this.editMode = mode;
        });


        //get the icon string from the config of the card
        if (!this.card ||
            !this.card.Configuration ||
            !this.card.Configuration['icon'] ||
            typeof this.card.Configuration['icon'] === 'undefined' ||
            typeof this.card.Configuration['icon'] === undefined) {
            this.iconCard = "check-circle";
        }
        else {
            this.iconCard = this.card.Configuration['icon'];
        }

    }

    editCardConfig() {
        this.cardSvc.openConfigModal(this.card, this.rowAvailableSpace);
    }

    //used to activate the maximized-card and collapsed-card classes of the card
    fullscreenCard() {
        if (this.maximizedCard == false) {
            this.maximizedCard = true;
        }
        else {
            this.maximizedCard = false;
        }
    }

    moveCardToRight() {
        this.row.HAPageCards.forEach((e, index) => {
            let nextCard = this.row.HAPageCards[index + 1];
            if (nextCard != undefined && e.Id == this.card.Id) {
                let currentCardOrder = this.row.HAPageCards[index].Order;
                this.row.HAPageCards[index].Order = nextCard.Order;
                this.row.HAPageCards[index + 1].Order = currentCardOrder;
            }
        })
        return this.apiSvc.put<HaPageRowVM, HaPageCard[]>(CardUrls.UPDATE_ORDER_CARDS, this.row)?.subscribe(data => {
            data.forEach(e => this.onCardChange.emit(e));
        });
    }

    moveCardToLeft() {
        this.row.HAPageCards.forEach((e, index) => {
            let previousCard = this.row.HAPageCards[index - 1];
            if (previousCard != undefined && e.Id == this.card.Id) {
                let currentCardOrder = this.row.HAPageCards[index].Order;
                this.row.HAPageCards[index].Order = previousCard.Order;
                this.row.HAPageCards[index - 1].Order = currentCardOrder;
            }
        })
        return this.apiSvc.put<HaPageRowVM, HaPageCard[]>(CardUrls.UPDATE_ORDER_CARDS, this.row)?.subscribe(data => {
            data.forEach(e => this.onCardChange.emit(e));
        });
    }
}
