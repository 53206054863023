import { AfterViewInit, Component, Input, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, inject } from '@angular/core';
import { TranslatePipe } from "@ngx-translate/core";
import { DataTableDirective } from "angular-datatables";
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ResizedEvent } from "angular-resize-event";
import { Observable, Subject, takeUntil } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ColumnSetting } from '../lib-column-selector/lib-column-selector.interface';
import { LibDynamicComponentsEventBusService } from '../lib-dynamic-component-event-bus/lib-dynamic-component-event-bus.service';
import { configFileDeposit } from '../lib-file-deposit/lib-file-deposit.interfaces';
import { LibHerdiaDatatableCellRenderComponent } from "./lib-herdia-datatable-cell-render/lib-herdia-datatable-cell-render.component";
import { HerdiaDatatableGlobalConfiguration, ServerSideRequest, ServerSideResponse } from "./lib-herdia-datatable.interfaces";

@Component({
  selector: 'lib-herdia-datatable',
  templateUrl: './lib-herdia-datatable.component.html',
  styleUrls: ['./lib-herdia-datatable.component.scss']
})
export class LibHerdiaDatatableComponent implements OnInit, AfterViewInit, OnDestroy {

  private eventBusService = inject(LibDynamicComponentsEventBusService);
  private translatePipe = inject(TranslatePipe);

  @Input() cardId!: number;

  @Input() fileDepostitConfig!: configFileDeposit;
  @Input() columnSettings: ColumnSetting[] = [];
  @Input() dataGetter!: (datatableParameters: any) => Observable<ServerSideResponse>;
  @Input() keepRowNumber: boolean = true;
  @Input() rowCallback!: (row: Node, data: any[] | Object, index: number) => Node;
  @Input() drawCallback!: (settings: any) => void;
  @Input() hasSums: boolean = false;
  @Input() herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: false,
    defaultRowNumberOnPage: 10,
    autoWidth: false,
    fixedLeftColumns: 0
  };

  @ViewChild(DataTableDirective, { static: false }) dtElement!: DataTableDirective;
  @ViewChild('dtCellRenderer') dtCellRenderer!: TemplateRef<LibHerdiaDatatableCellRenderComponent>;
  IndexRowPointed!: number;

  datatableColumnSettings: ADTColumns[] = [];
  widthUpdate = new Subject<number>();
  initDataTable: Subject<any> = new Subject<any>();
  globalSearchValue = "";
  datatableReady = false;
  numberOfRow = Array(10).fill(0).map((x, i) => i);
  dtTrigger: Subject<DataTables.Settings> = new Subject<DataTables.Settings>();
  showLoader = true;
  showFilters: boolean = false;
  dtOptions: DataTables.Settings = {
    scrollCollapse: false,
    processing: true,
    serverSide: true,
    responsive: false,
    lengthChange: false,
    autoWidth: false,
    scrollX: true,
    scrollY: 420,
    //fixedHeader: {
    //  headerOffset: 54
    //},
    deferRender: false,
    stateSave: false,
    dom: 't',
    pageLength: 10,
    columns: [],
    fixedColumns: {
      leftColumns: this.herdiaDTConfig.fixedLeftColumns
    },
    initComplete: (settings: any, json: any) => {
      this.showLoader = false;
    },
    ajax: (dataTablesParameters: any, callback: any) => {
      dataTablesParameters.search.value = this.globalSearchValue;

      this.dataGetter(dataTablesParameters)!.subscribe((response: ServerSideResponse) => {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          let infos = dtInstance.page.info();
          let pageLength = infos.length;
          let dataLength = response.data?.length;
          // if we have some columns definition without according data, we create fake datas
          if (response.data && dataLength !== undefined && dataLength > 0) {
            let itemRow = response.data[0];
            this.columnSettings.forEach((c: ColumnSetting) => {
              if (Object.keys(itemRow).indexOf(c.name) === -1) {
                response.data?.forEach((item: any) => {
                  item[c.name] = "fake";
                });
              }
            });
          }
          // this is used to create filter row
          if (dataLength !== undefined && dataLength > 0) {
            let emptyObject: { [k: string]: string } = {};
            this.columnSettings.forEach(key => {
              if (dataTablesParameters.columns.find((c: ColumnSetting) => c.name == key.name) !== undefined) {
                emptyObject[key.name] = "[FILTER:" + dataTablesParameters.columns!.find((c: ColumnSetting) => c.name == key.name)!.search?.value + "]";
              }
            });
            if (!response.data)
              response.data = [];
            response.data.unshift(emptyObject);
          }
          // this is used to create empty rows to have the same number of rows for a page. this.config.keepRowNumber is used to enable or disable this.
          if (this.keepRowNumber &&
            dataLength !== undefined &&
            dataLength < infos.length) {
            let emptyObject: { [k: string]: string } = {};
            this.columnSettings.forEach(key => {
              emptyObject[key.name] = "";
            });
            for (let i = 0; i < pageLength - dataLength; i++) {
              if (!response.data)
                response.data = [];
              response.data.push(emptyObject);
            }
          }
          // As datatable doesn't handle null value we have te replace them with empty string
          const dataWithoutNull = JSON.stringify(response.data).replace(/null/g, '""');
          response.data = JSON.parse(dataWithoutNull);
          callback(response);
        });
      });
    },
    destroy: true,

    rowCallback: (row: Node, data: any[] | Object, index: number) => {
      this.IndexRowPointed = index;
      if (index == 0) {
        $(row).addClass("filterRow");
        if (!this.showFilters) {
          $(row).addClass("hideFilters");
        }
      }

      if (!this.rowCallback)
        return row;
      else
        return this.rowCallback(row, data, index);
    },
    drawCallback: (settings: any) => {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        const infos = dtInstance.page.info();
        if (infos.recordsTotal !== 0) {
          this.eventBusService.cardSubjects["PaginationInfoUpdated_" + this.cardId].next(infos);
          dtInstance.columns.adjust();
        }
      });
      if (this.drawCallback)
        this.drawCallback(settings);
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    const self = this;
    if (changes['columnSettings']) {
      this.dtOptions.columns = this.columnSettings.map((c: ColumnSetting) => {

        let templateRef = {
          ref: this.dtCellRenderer,
          context: {
            captureEvents: self.onColumnSearchEvent.bind(self),
            userData: { columnSetting: c },
            datatableConfig: this.herdiaDTConfig
          }
        };

        return {
          title: this.translatePipe.transform(c.name),
          data: c.name,
          name: c.name,
          className: this.getAlignmentClassName(c.horizontalAlign) + (this.herdiaDTConfig.nowrap ? " dt-body-nowrap dt-head-nowrap text-truncate" : ""),
          width: this.herdiaDTConfig.autoWidth ? undefined : c.width + "px",
          ngTemplateRef: templateRef
        } as DataTables.ColumnSettings
      });
    }
  }

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    if (!this.herdiaDTConfig) {
      this.herdiaDTConfig = {
        compactMode: false,
        cellBorder: true,
        hover: true,
        orderColumn: true,
        rowBorder: false,
        stripe: true,
        nowrap: false,
        defaultRowNumberOnPage: 10,
        autoWidth: false,
        fixedLeftColumns: 0
      };
    }

    this.widthUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe(v => {
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          if (this.dtTrigger.observed) {
            dtInstance.columns.adjust();
          }
        });
      }
    });

    this.dtOptions.autoWidth = this.herdiaDTConfig.autoWidth;
    this.dtOptions.pageLength = this.herdiaDTConfig.defaultRowNumberOnPage;
    this.dtOptions.fixedColumns = {
      leftColumns: this.herdiaDTConfig.fixedLeftColumns
    };

    this.initDataTable.pipe(debounceTime(400), distinctUntilChanged()).subscribe(v => {
      this.dtOptions.columns?.forEach((c: any) => {
        if (!c.ngTemplateRef.ref)
          c.ngTemplateRef.ref = this.dtCellRenderer;
      });

      this.dtTrigger.next(this.dtOptions);

      this.datatableReady = true;
    });

    this.initEventBus();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  initEventBus(): void {
    // Pagination capabilities
    this.eventBusService.addSubject("PaginationInfoUpdated", this.cardId, new Subject<DataTables.PageMethodeModelInfoReturn>());

    this.eventBusService.addSubject("RequestPageNumber", this.cardId, new Subject<number>());
    this.eventBusService.cardSubjects["RequestPageNumber_" + this.cardId].pipe(takeUntil(this.destroy$))
      .subscribe((pageNumber: any) => {
        this.goToPageNumber(pageNumber);
      });

    this.eventBusService.addSubject("RequestLinePerPage", this.cardId, new Subject<number>());
    this.eventBusService.cardSubjects["RequestLinePerPage_" + this.cardId].pipe(takeUntil(this.destroy$))
      .subscribe((linePerPage: any) => {
        this.requestLinePerPage(linePerPage);
      });

    // Searches capabilities
    this.eventBusService.addSubject("HideDisplaySearchColumns", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["HideDisplaySearchColumns_" + this.cardId].pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showFilters = false;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          $(dtInstance.rows(0).nodes()[0]).addClass("hideFilters");
        });
      });

    this.eventBusService.addSubject("ShowDisplaySearchColumns", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["ShowDisplaySearchColumns_" + this.cardId].pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showFilters = true;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          $(dtInstance.rows(0).nodes()[0]).removeClass("hideFilters");
        });
      });

    this.eventBusService.addSubject("SearchEntity", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["SearchEntity_" + this.cardId].pipe(takeUntil(this.destroy$))
      .subscribe((searchValue: any) => { this.searchEntity(searchValue); })

    this.eventBusService.addSubject("ToggleColumnVisibility_Datatable", this.cardId, new Subject<{ index: number, visible: boolean }>());
    this.eventBusService.cardSubjects["ToggleColumnVisibility_Datatable_" + this.cardId].pipe(takeUntil(this.destroy$))
      .subscribe((e: { index: number, visible: boolean }) => {
        this.toggleColumnVisibility(e.index, e.visible);
      });

  }

  ngAfterViewInit(): void {
    if (!this.initDataTable.closed) {
      this.initDataTable.next(null);
    }
  }

  public reload(): void {
    if (this.datatableReady) {
      this.dtTrigger.next(this.dtOptions);
    }
  }
  onResized(event: ResizedEvent): void {
    this.widthUpdate.next(event.newRect.width);
  }

  onColumnSearchEvent(data: any): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance
        .column(data.column + ":name")
        .search(data.searchValue)
        .draw();
    });
  }

  searchEntity(searchValue: string): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.globalSearchValue = searchValue;
      dtInstance.search(searchValue).draw();
    });
  }

  goToPageNumber(pageNumber: number): void {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.page(pageNumber).draw('page');
      });
    }
  }

  requestLinePerPage(nbLine: number): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.page.len(nbLine).draw('page');
    });
  }

  toggleColumnVisibility(columnIndex: number, show: boolean): void {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.column(columnIndex).visible(show);
    });
  }
  getAlignmentClassName(requestedAlignment: string): string {
    switch (requestedAlignment) {
      case "left":
        return "dt-left";
      default:
      case "center":
        return "dt-center";
      case "right":
        return "dt-right";
    }
  }

  getDatatableParameters(): Promise<ServerSideRequest> {
    return this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      return dtInstance.ajax.params() as ServerSideRequest;
    });
  }
}
