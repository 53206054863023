import { TranslateManagerComponent } from './core/translate-manager/translate-manager.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./core/home/home.component";
import { LoginComponent } from "./core/login/login.component";
import { PageNotFoundComponent } from "./core/page-not-found/page-not-found.component";
import { ContentManagementComponent } from "./core/content-management/content-management.component";
import { AuthGuard } from './core/shared/guard/auth.guard';
import { LoginGuard } from './core/shared/guard/login.guard';
import { TaskSchedulerManagerComponent } from "./core/task-scheduler-manager/task-scheduler-manager.component";
import { UploaderManagerComponent } from "./core/uploader-manager/uploader-manager.component";
import { UsersComponent } from './core/user/users/users.component';
import { ProfileComponent } from './core/user/profile/profile.component';
import { AddEditUserComponent } from './core/user/add-edit-user/add-edit-user.component';
import { RoleComponent } from './core/role/role.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { EmailConfirmationComponent } from './core/email-confirmation/email-confirmation.component';
import { EmailManagerComponent } from './core/application-email-manager/email-manager.component';
import { CardThemeDesignerComponent } from './core/card-theme-designer/card-theme-designer.component';
import { DBConnectionManagerComponent } from './core/dbconnection-manager/dbconnection-manager.component';
import { UserEmailManagerComponent } from './core/user-email-manager/user-email-manager.component';
import { RolesGuard } from './core/shared/guard/roles.guard.guard';
import { PageUnauthorizedComponent } from './core/page-unauthorized/page-unauthorized.component';
import { FiledepositManagerComponent } from './core/filedeposit-manager/filedeposit-manager.component';



const appRoutes: Routes = [
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'paramsOrQueryParamsChange' },
    { path: 'content-manager', component: ContentManagementComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'card-theme-designer', component: CardThemeDesignerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'translate-manager', component: TranslateManagerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'role', component: RoleComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'user', component: UsersComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'user/profile/:id', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'user/addedit', component: AddEditUserComponent, canActivate: [AuthGuard] },
    { path: 'user/addedit/:id', component: AddEditUserComponent, canActivate: [AuthGuard] },
    { path: 'forgot_password', component: ForgotPasswordComponent, canActivate: [LoginGuard] },
    { path: 'forgot_password/:token/:mail', component: ForgotPasswordComponent, canActivate: [LoginGuard] },
    { path: 'email_confirmation', component: EmailConfirmationComponent, canActivate: [AuthGuard] },
    { path: 'email_confirmation/:token/:mail', component: EmailConfirmationComponent, canActivate: [AuthGuard] },
    { path: 'task-scheduler-manager', component: TaskSchedulerManagerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'email-manager', component: EmailManagerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'user-email-manager', component: UserEmailManagerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'uploader-manager', component: UploaderManagerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'dbconnection-manager', component: DBConnectionManagerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'filedeposit-manager', component: FiledepositManagerComponent, canActivate: [AuthGuard, RolesGuard] },
    { path: 'unauthorized-page', component: PageUnauthorizedComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AddRoutingModule {

}
