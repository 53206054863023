import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LibHttpClientApiModule } from '../lib-http-client-api/lib-http-client-api.module';
import { LibTranslateEditModalComponent } from './lib-translate-edit-modal/lib-translate-edit-modal.component';
import { LibTranslateComponent } from './lib-translate.component';

@NgModule({
    declarations: [
        LibTranslateComponent,
        LibTranslateEditModalComponent
    ],
    imports: [
        TranslateModule,
        FontAwesomeModule,
        FormsModule,
        CommonModule,
        LibHttpClientApiModule
    ],
    exports: [
        LibTranslateComponent,
        NgbModule
    ],
    providers: [
    ]
})
export class LibHerdiaAppTranslateModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
    }
}
