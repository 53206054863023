import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  progressValue = '0%';

  @Input()
  applicationName = '';

  @Input()
  loadingLabel = 'Loading...';

  @Input() set setProgressMercentage(value: number) {
    if (value > 100)
      value = 100;
    this.progressValue = `${value}%`;
  }

  constructor() { }

  ngOnInit(): void {

  }

}
