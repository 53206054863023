<div class="d-inline-flex" style="margin-right: 9.5rem">
  <div class="mx-2 d-inline-flex align-items-center" *ngIf="isStartDate" style="white-space:nowrap">
    <label for="startDate" class="form-label">{{lblStartDate}}</label>
    <input type="date"
           class="form-control w-auto mx-2"
           name="startDate"
           [(ngModel)]="startDate"
           (ngModelChange)="onUpdateStartDate($event)" />
  </div>
  <div class="mx-2 d-inline-flex align-items-center" *ngIf="isEndDate">
    <label for="endDate" class="form-label">{{lblEndDate}}</label>
    <input type="date"
           class="form-control w-auto mx-2"
           name="endDate"
           [(ngModel)]="endDate"
           (ngModelChange)="onUpdateEndDate($event)" />
  </div>
  <div class="mx-2 d-inline-flex align-items-center" *ngIf="isAnalysisDate">
    <label for="analysisDate" class="form-label">{{lblAnalysisDate}}</label>
    <input type="date"
           class="form-control w-auto mx-2"
           name="analysisDate"
           [(ngModel)]="analysisDate"
           (ngModelChange)="onUpdateAnalysisDate($event)" />
  </div>
  <button *ngIf="isAnalysisDate || isEndDate || isStartDate" (click)="onApplyDates()" class="btn btn-secondary">
    <fa-icon icon="circle-check"></fa-icon>&nbsp;<lib-translate [key]="'lbl-apply'"></lib-translate>
  </button>
</div>
