<div class="form-inline col-12 justify-content-end">
  <label><lib-translate [key]="'lbl-rows-per-page'"></lib-translate></label>
  <ng-select class="ngSelectPerPage"
             [items]="linePerPage"
             bindLabel="value"
             bindValue="id"
             [(ngModel)]="selectedLinePerPage"
             [searchable]="false"
             [clearable]="false"
             (change)="onRequestLinePerPage($event)">
  </ng-select>
  <label><lib-translate [key]="'lbl-page'"></lib-translate></label>
  <ng-select class="ngSelectPage" [(ngModel)]="selectedPage" [items]="selectablePages" [searchable]="false" [clearable]="false" (change)="onRequestPageNumber($event)">
  </ng-select>
  <label>&nbsp;/&nbsp;</label><label>{{this.pageInfos.pages}}</label>&nbsp;&nbsp;
  <div class="btn-group">
    <button [disabled]="this.pageInfos.page == 0" class="btn btn-primary" (click)="onRequestPrevPage();"><fa-icon icon="step-backward"></fa-icon></button>
    <button [disabled]="this.pageInfos.page + 1 >= this.pageInfos.pages" class="btn btn-primary" (click)="onRequestNextPage();"><fa-icon icon="step-forward"></fa-icon></button>
  </div>
</div>
