<div id="file-deposit-container">
  <div id="file-deposit-container-top">
    <div class="form-group">
      <label><lib-translate [key]="'file-deposit-selector'"></lib-translate></label>
      <ng-select [items]="availableFileDeposit"
                 [(ngModel)]="configFileDeposit.fileDepositType"
                 placeholder="'lbl-file-deposit-available'"
                 bindLabel="Label"
                 (change)="onSelectedFileDepositChange($event)">
      </ng-select>
    </div>


    <div id="file-deposit-container-top-inputs">
      <div *ngFor="let param of configFileDeposit.paramsFileDeposit">
        <div class="form-group">
          <label>{{param.paramName}}</label>
          <ng-select [items]="availableColumns" bindLabel="name" [(ngModel)]="param.column" (change)="onSelectedColumnForParam()"></ng-select>
        </div>
      </div>
    </div>

  </div>

  <div class="form-group">
      <label><lib-translate [key]="'file-deposit-link'"></lib-translate></label>
      <ng-select [items]="availableColumns" [(ngModel)]="configFileDeposit.columnLink" bindLabel="name" placeholder="'lbl-column-link'" (change)="onFileDepositLinkChange($event)"></ng-select>
  </div>
</div>
