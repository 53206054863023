import { NgModule } from '@angular/core';
import { LibDynamicComponentsEventBusService } from './lib-dynamic-component-event-bus.service';



@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
  ],
  providers: [
    LibDynamicComponentsEventBusService
  ]
})
export class LibDynamicComponentEventBusModule { }
