<div class="d-inline-flex justify-content-between">
  <lib-header-binding [context]="context"
                      [config]="config">
  </lib-header-binding>
  <lib-filter-card *ngIf="hasCardFilter()"
                   [config]="config">
  </lib-filter-card>
  <lib-herdia-datatable-export-button [exportName]="title"
                                      [config]="config"></lib-herdia-datatable-export-button>
  <!--<lib-herdia-datatable-column-selector-button [columnSettings]="config['WebReport']['ColumnsSettings']"
                                               [config]="this.config">
  </lib-herdia-datatable-column-selector-button>-->
</div>
