import { Component, OnInit, inject } from '@angular/core';
import { CardConfigBaseComponent } from '@herdia-common';
import { LibCardChartService } from '../lib-card-chart.service';

@Component({
    selector: 'lib-card-chart-config',
    templateUrl: './lib-card-chart-config.component.html',
    styleUrls: ['./lib-card-chart-config.component.css']
})
export class LibCardChartConfigComponent extends CardConfigBaseComponent implements OnInit {

    protected chartService = inject(LibCardChartService);

    ngOnInit(): void {
        if (!this.config['ChartConfig']) {
            this.config['ChartConfig'] = {};
        }
    }
}
