import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormComponentBase } from '../shared/models/baseClasses';
import { ForgotPasswordService } from '../shared/service/forgot.password.service';
import { LibHerdiaAppTranslateService } from '@herdia-common';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends FormComponentBase implements OnInit {
  //lib translate
  selectedLocal: string = navigator.language;
  //form send email for for change password:
  formSendMail!: FormGroup;
  email_to_send: string = "";
  ShowFormSendMail = false;
  ShowErrorSendingMail = false;
  ShowValidateSendingMail = false;
  //form change password form
  formNewpassword!: FormGroup;
  user_email: string = "";
  new_password: string = "";
  new_password_confirmation: string = "";
  token: string = "";
  ShowFormChangePassword = false;
  ShowErrorValidationPassword = false;
  ShowValidateChangePassword = false;
  ShowPasswordNotMatch = false;
  PasswordValidationErrors: string = '';
  ShowPassword = true;
  ShowPasswordConfirmation = true;


  constructor(private route: ActivatedRoute, private fBuilder: FormBuilder, private forgotPasswordService: ForgotPasswordService,private translateService: LibHerdiaAppTranslateService)
  {
    super();
    //validators forms
    this.formSendMail = this.fBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.formNewpassword = this.fBuilder.group({
      password: ['', [Validators.required, Validators.minLength]],
      password_confirm: ['', [Validators.required, Validators.minLength]],

    }
    )
  }

  ngOnInit(): void {
    //show the right form according to the parameters in the url
    this.route.queryParams
      .subscribe(params => {
        this.token = params['token'];
        this.user_email = params['mail'];
      });
    if (this.token == null && this.user_email == null){
      this.ShowFormSendMail = true;
    }
    else{
      this.ShowFormChangePassword = true;
    }
  }

  //function to send mail for reset password
  sendMail() {
    this.submitted = true;
    //check the status of the form
    if(this.formSendMail.status == "VALID"){
      this.email_to_send = this.formSendMail.value.email;
      this.forgotPasswordService.SendMailForForgotPassword(this.email_to_send).subscribe((res : any) => {
        console.log(res);
        //condition for show the good div to inform the user if the request doing well
        if(res.success == true){
          this.ShowErrorSendingMail = false;
          this.ShowValidateSendingMail = true;
        }
        else{
          this.ShowValidateSendingMail = false;
          this.ShowErrorSendingMail = true;
        }
      },
      error => {
        console.log(error);
      });
    }
  }

  //function to reset password
  changePassword(){
    this.submitted = true;
    this.new_password = this.formNewpassword.value.password;
    this.new_password_confirmation = this.formNewpassword.value.password_confirm;

    //check the password confirmation is the same as password
    if(this.new_password == this.new_password_confirmation){
      this.ShowPasswordNotMatch = false;

      //check password validator
      this.forgotPasswordService.CheckPassword(this.new_password).subscribe((res : any) => {
        console.log(res);

        //check the status of the form and if password and password confirmation match or not
        if(this.formNewpassword.status == "VALID" && res.success == true){
          this.ShowErrorValidationPassword = false;
          this.forgotPasswordService.ResetPassword(this.new_password, this.new_password_confirmation, this.user_email, this.token).subscribe((res : any) => {
              console.log(res);
              //condition for show the good div to inform the user if the request doing well
              if(res.success == true){
                this.ShowErrorValidationPassword = false;
                this.ShowValidateChangePassword = true;
              }
              else{
                this.ShowValidateChangePassword = false;
                this.PasswordValidationErrors = this.translateService.get('password-reset-errors');
                this.ShowErrorValidationPassword = true;
              }
            },
            error => {
              console.log(error);
            });
            return;
        }
        else{
          this.PasswordValidationErrors = "";
          this.ShowValidateChangePassword = false
          res.errors.forEach((element: any) => {
            switch(element){
              case "PasswordRequiresDigit": {
                this.PasswordValidationErrors = this.PasswordValidationErrors + this.translateService.get('password-requires-digit-alert') + " \n";
                break;
              }
              case "PasswordTooShort": {
                this.PasswordValidationErrors = this.PasswordValidationErrors + this.translateService.get('password-too-short-alert') + " \n";
                break;
              }
              case "PasswordRequiresNonAlphanumeric": {
                this.PasswordValidationErrors = this.PasswordValidationErrors + this.translateService.get('password-Requires-non-alphanumeric-alert') + " \n";
                break;
              }
              case "PasswordRequiresUpper": {
                this.PasswordValidationErrors = this.PasswordValidationErrors + this.translateService.get('password-Requires-upper-alert') + " \n";
                break;
              }
              case "PasswordRequiresLower": {
                this.PasswordValidationErrors = this.PasswordValidationErrors + this.translateService.get('password-Requires-lower-alert') + " \n";
                break;
              }
              case "PasswordRequiresUniqueChars": {
                this.PasswordValidationErrors = this.PasswordValidationErrors + this.translateService.get('password-Requires-unique-chars') + " \n";
                break;
              }
              default: {
                this.PasswordValidationErrors = this.PasswordValidationErrors + this.translateService.get('password-reset-errors') + " \n";
                break;
              }
            }
          });
          this.ShowErrorValidationPassword = true;
        }
      })

    }
    else{
      this.ShowPasswordNotMatch = true;
      return;
    }

  }

  //function to change the translation of the page
  onLocalChanged(local: string) {
    //this.translationSvc.updateTranslations(local);
    localStorage.setItem("userLocale", local);
    this.translateService.use(local);
  }

  showPassword(whichPasswor:string){
    if(whichPasswor == "passsword")
    {
      var show_eye = document.getElementById("show_eye");
      var hide_eye = document.getElementById("hide_eye");
      if(hide_eye && show_eye){
        if (this.ShowPassword == true) //password est caché
        {
          this.ShowPassword = false;
          show_eye.style.display = "none";
          hide_eye.style.display = "block";
        } else {
          this.ShowPassword = true;
          show_eye.style.display = "block";
          hide_eye.style.display = "none";
        }
      }
    }
    else
    {
      var show_eye = document.getElementById("show_eye2");
      var hide_eye = document.getElementById("hide_eye2");
      if(hide_eye && show_eye){
        if (this.ShowPasswordConfirmation == true) //password est caché
        {
          this.ShowPasswordConfirmation = false;
          show_eye.style.display = "none";
          hide_eye.style.display = "block";
        } else {
          this.ShowPasswordConfirmation = true;
          show_eye.style.display = "block";
          hide_eye.style.display = "none";
        }
      }
    }

  }
}
