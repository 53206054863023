import { Component, OnInit, inject, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LibDynamicComponentsEventBusService } from '../lib-dynamic-component-event-bus/lib-dynamic-component-event-bus.service';

@Component({
  selector: 'lib-datatable-pagination',
  templateUrl: './lib-datatable-pagination.component.html',
  styleUrls: ['./lib-datatable-pagination.component.scss']
})
export class LibDatatablePaginationComponent implements OnInit, OnDestroy {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  @Input() cardId!: number;

  pageInfos: DataTables.PageMethodeModelInfoReturn = {
    page: 0,
    pages: 1,
    start: 0,
    end: 10,
    length: 10,
    recordsTotal: 10,
    recordsDisplay: 10,
    serverSide: true
  };

  linePerPage: any = [
    { id: 10, value: "- 10 -" },
    { id: 15, value: "- 15 -" },
    { id: 20, value: "- 20 -" },
    { id: 25, value: "- 25 -" },
    { id: 30, value: "- 30 -" },
    { id: 35, value: "- 35 -" },
    { id: 40, value: "- 40 -" },
    { id: 50, value: "- 50 -" },
    { id: -1, value: "- All -" }
  ];

  selectablePages: number[] = [];

  selectedLinePerPage: number = 10;
  selectedPage: number = 1;

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.eventBusService.addSubject("PaginationInfoUpdated", this.cardId, new Subject<DataTables.PageMethodeModelInfoReturn>());
    this.eventBusService.cardSubjects["PaginationInfoUpdated_" + this.cardId].pipe(takeUntil(this.destroy$))
      .subscribe((info: DataTables.PageMethodeModelInfoReturn) => {
        this.pageInfos = info;
        this.selectedLinePerPage = this.pageInfos.length;
        this.selectedPage = this.pageInfos.page + 1;
        this.selectablePages = this.counter(this.pageInfos.pages);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRequestPageNumber(pageNumber: number): void {
    this.eventBusService.addSubject("RequestPageNumber", this.cardId, new Subject<number>());
    this.eventBusService.cardSubjects["RequestPageNumber_" + this.cardId].next(pageNumber - 1);
  }

  onRequestPrevPage(): void {
    this.onRequestPageNumber(this.selectedPage - 1);
  }

  onRequestNextPage(): void {
    this.onRequestPageNumber(this.selectedPage + 1);
  }

  onRequestLinePerPage(nbLine: any): void {
    this.eventBusService.addSubject("RequestLinePerPage", this.cardId, new Subject<number>());
    this.eventBusService.cardSubjects["RequestLinePerPage_" + this.cardId].next(nbLine["id"]);
  }

  counter(i: number) {
    const result: number[] = [];
    for (let idx = 0; idx < i; idx++)
      result.push(idx + 1);
    return result;
  }
}
