import { IAddDBConnectionResponse } from './../../shared/models/interfaces';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAddDbConnection } from 'src/app/core/shared/models/interfaces';
import { DBConnectionService } from 'src/app/core/shared/service/dbconnection.service';

@Component({
    selector: 'app-add-dbconnection-modal',
    templateUrl: './add-dbconnection-modal.component.html',
    styleUrls: ['./add-dbconnection-modal.component.scss']
})
export class AddDbconnectionModalComponent implements OnInit {

    @Output() public dbConnectonAdded = new EventEmitter<void>();
    connectionTypes = [
        { id: 0, name: "Sql Server" },
        { id: 1, name: "MySQL" },
        { id: 2, name: "PgSQL" }
    ]
    newConnection: IAddDbConnection = {
        connectionType: 0,
        name: "",
        connectionString: "",
        contextApiRoute: ""
    };
    isLoadingDynamicContext: boolean = false;

    constructor(private dbconnectionService: DBConnectionService, private activeModal: NgbActiveModal) { }

    dismiss() {
        return this.activeModal.dismiss();
    }
    
    ngOnInit(): void {

    }

    onValidate(): void {
        this.isLoadingDynamicContext = true;
        this.dbconnectionService.add(this.newConnection)?.subscribe((r: IAddDBConnectionResponse) => {
            if (!r.IsInError) {
                this.dbConnectonAdded.emit();
                this.activeModal.close();
                this.isLoadingDynamicContext = false;
            }
            else {
                this.isLoadingDynamicContext = false;
            }
        });
    }
}
