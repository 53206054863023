<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'lib-translate-edit-modal.title' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="mb-3">
    <label for="translateValue"></label>
    <div class="input-group">
      <input [(ngModel)]="currentTranslationLabel" id="translateValue" class="form-control" name="translateValue" placeholder="{{key}}" [value]="currentTranslationLabel" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onSave()">{{'lib-translate-edit-modal.save' | translate }}</button>
</div>
