import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent, HerdiaDatatableGlobalConfiguration } from '@herdia-common';

@Component({
    selector: 'lib-card-report-config-datatable-aspect',
    templateUrl: './lib-card-report-config-datatable-aspect.component.html',
    styleUrls: ['./lib-card-report-config-datatable-aspect.component.css']
})
export class LibCardReportConfigDatatableAspectComponent extends CardConfigBaseComponent implements OnInit {

    ngOnInit(): void {
        if (this.config["DataTableConfig"] == null) {
            this.config["DataTableConfig"] = {
                compactMode: false,
                cellBorder: true,
                hover: true,
                orderColumn: true,
                rowBorder: false,
                stripe: true,
                nowrap: false,
                defaultRowNumberOnPage: 10,
                autoWidth: false,
                fixedLeftColumns: 1
            } as HerdiaDatatableGlobalConfiguration;
        }
    }

}
