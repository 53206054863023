import { NgModule } from '@angular/core';
import { environment } from '@environment';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

@NgModule({
    imports: [
        CoreModule,
        ...environment.projectModules,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }