import { Injectable, inject } from '@angular/core';
import { ServerSideResponse } from '@herdia-common';
import { LibHttpClientApiService } from '@herdia-common';
import { Observable } from 'rxjs';
import { IPropertyManagerFilter, ITranscodingItemsValues, ITranscodingItemsValuesParams, ITranscodingTypeFilter, ITranscodingTypeParam, IUpdateParamDestinationParams } from './lib-card-input-mapping.interface';

export enum InputMappingUrls {
  GET_PROPERTY_MANAGERS = "api/alhambra/GetPropertyManagersDistinct/Datas",
  GET_TRANSCODING_TYPE_LIST = "api/alhambra/GetTranscodingTypeList/Datas",
  GET_PARAM_TRANSCODING_ITEMS = "api/alhambra/GetParamTranscodingItems/ReportDatas",
  GET_LIST_VALUES = "api/alhambra/GetListValues/Datas",
  UPDATE_PARAM_DESTINATION = "api/alhambra/UpdateParamDestination/Datas"
}

@Injectable({
  providedIn: 'root'
})
export class LibCardInputMappingService {

  private httpClientApiService = inject(LibHttpClientApiService)

  public getPropertyManagerList(): Observable<IPropertyManagerFilter[]> {
    return this.httpClientApiService.post<any, IPropertyManagerFilter[]>(InputMappingUrls.GET_PROPERTY_MANAGERS, {});
  }

  public updateTranscodingTypesList(params: ITranscodingTypeParam): Observable<ITranscodingTypeFilter[]> {
    return this.httpClientApiService.post<ITranscodingTypeParam, ITranscodingTypeFilter[]>(InputMappingUrls.GET_TRANSCODING_TYPE_LIST, params);
  }

  public getTranscodingItems(paramTranscodingItemsParams: any): Observable<ServerSideResponse> {
    return this.httpClientApiService.post<any, ServerSideResponse>(InputMappingUrls.GET_PARAM_TRANSCODING_ITEMS, paramTranscodingItemsParams);
  }

  public getDestinationCodes(params: ITranscodingItemsValuesParams): Observable<ITranscodingItemsValues[]> {
    return this.httpClientApiService.post<ITranscodingItemsValuesParams, ITranscodingItemsValues[]>(InputMappingUrls.GET_LIST_VALUES, params);
  }

  public updateParamDestination(params: IUpdateParamDestinationParams): Observable<any> | null {
    return this.httpClientApiService.post<IUpdateParamDestinationParams, any>(InputMappingUrls.UPDATE_PARAM_DESTINATION, params);
  }

}
