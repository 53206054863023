<div class="login-box">
  <div class="login-logo">
    <a href="../../index2.html"><b>{{applicationName}}</b></a>
  </div>
  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">{{loadingLabel}}</p>
      <div class="progress">
        <div [ngStyle]="{'width': progressValue}" class="progress-bar bg-primary progress-bar-striped" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
