import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DynamicComponentsModule } from './dynamic-components/dynamic-components.module';
import { LibIconPickerModule } from './icon-picker/lib-icon-picker.module';
import { LibCardGenericCrudModule } from './lib-card-generic-crud/lib-card-generic-crud.module';
import { LibCardHtmlEditorModule } from './lib-card-html-editor/lib-card-html-editor.module';
import { LibUserModule } from './user/lib-user.module';
import { LibCardHeaderCommonButtonsModule } from './lib-card-header-common-buttons/lib-card-header-common-buttons.module';
import { LibColumnSelectorModule } from './lib-column-selector/lib-column-selector.module';
import { LibConfirmBoxModule } from './lib-confirm-box/lib-confirm-box.module';
import { LibDatatablePaginationModule } from './lib-datatable-pagination/lib-datatable-pagination.module';
import { LibDynamicComponentEventBusModule } from './lib-dynamic-component-event-bus/lib-dynamic-component-event-bus.module';
import { LibCronEditorModule } from './lib-cron-editor/lib-cron-editor.module';
import { LibEditModeModule } from './lib-edit-mode/lib-edit-mode.module';
import { LibFileDepositModule } from './lib-file-deposit/lib-file-deposit.module';
import { LibFileDepositConfigModule } from './lib-file-deposit-config/lib-file-deposit-config.module';
import { LibHerdiaAppEnvironmentModule } from './lib-herdia-app-environment/lib-herdia-app-environment.module';
import { LibHerdiaAppTranslateModule } from './lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibHerdiaDatatableModule } from './lib-herdia-datatable/lib-herdia-datatable.module';
import { LibHerdiaEnumerationModule } from './lib-herdia-enurmeration/lib-herdia-enumeration.module';
import { LibHttpClientApiModule } from './lib-http-client-api/lib-http-client-api.module';
import { LibPropertyTypeInputModule } from './lib-property-type-input/lib-property-type-input.module';
import { LibSchedulerServiceModule } from './lib-scheduler-service/lib-scheduler-service.module';
import { LibUploaderModule } from './lib-uploader/lib-uploader.module';

const HERDIA_COMMON_MODULES = [
    DynamicComponentsModule,
    FontAwesomeModule,
    LibCardGenericCrudModule,
    LibCardHeaderCommonButtonsModule,
    LibCardHtmlEditorModule,
    LibIconPickerModule,
    LibUserModule,
    LibColumnSelectorModule,
    LibConfirmBoxModule,
    LibDatatablePaginationModule,
    LibDynamicComponentEventBusModule,
    LibCronEditorModule,
    LibFileDepositModule,
    LibFileDepositConfigModule,
    LibHerdiaAppEnvironmentModule,
    LibHerdiaAppTranslateModule,
    LibHerdiaDatatableModule,
    LibHerdiaEnumerationModule,
    LibHttpClientApiModule,
    LibPropertyTypeInputModule,
    LibSchedulerServiceModule,
    LibUploaderModule,
    LibEditModeModule
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ...HERDIA_COMMON_MODULES,
    ],
    exports: [
        ...HERDIA_COMMON_MODULES,
    ]
})
export class HerdiaCommonModule { }
