import { Component, OnInit } from '@angular/core';
import { AppService } from '../shared/service/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  herdiaAppBuildId: string = "";
  constructor(private appSvc: AppService) {
    this.appSvc.herdiaAppBuildId.subscribe(buildid => this.herdiaAppBuildId = buildid);
  }

  ngOnInit(): void {
  }

}
