import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragulaModule } from "ng2-dragula";
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibColumnSelectorComponent } from './lib-column-selector.component';

library.add(fas);
@NgModule({
    declarations: [
        LibColumnSelectorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        LibHerdiaAppTranslateModule,
        FontAwesomeModule,
        DragulaModule.forRoot(),
        LibHerdiaAppTranslateModule
    ],
    exports: [
        LibColumnSelectorComponent,
    ]
})
export class LibColumnSelectorModule { }
