import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LibHerdiaAppTranslateModule } from '@herdia-common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LibSpecificParamReportConfigComponent } from './lib-specific-param-report-config.component';

@NgModule({
    declarations: [
        LibSpecificParamReportConfigComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        LibHerdiaAppTranslateModule,
        NgSelectModule,
        TranslateModule
    ],
    exports: [
        LibSpecificParamReportConfigComponent
    ]
})
export class LibSpecificParamReportConfigModule { }
