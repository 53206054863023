<div class="col-md-12">
    <div class="card card-primary haCardBox container-toggle">
        <div class="card-header">
            <h3 class="card-title"><fa-icon icon="check-circle"></fa-icon>&nbsp; <lib-translate
                    [key]="'card-title-email-manager'"
                ></lib-translate></h3>
        </div>

        <div class="card-body">
            <div class="mt-3">
                <lib-herdia-datatable
                    #herdiaDatatable
                    [cardId]="cardId"
                    [columnSettings]="columnSettings"
                    [dataGetter]="getData"
                    [keepRowNumber]="true"
                    [herdiaDTConfig]="herdiaDTConfig"
                ></lib-herdia-datatable>
            </div>
        </div>
        <div class="card-footer">
            <lib-datatable-pagination [cardId]="cardId"></lib-datatable-pagination>
        </div>
    </div>
</div>

<ng-template
    #dtActions
    let-data="adtData"
    let-emitter="captureEvents"
>
    <app-datatable-app-mail-row-action
        [data]="data"
        (emitter)="emitter($event)"
    ></app-datatable-app-mail-row-action>
</ng-template>

<ng-template #modal>
    <div class="modal-header">
        <h4 class="modal-title"><lib-translate [key]="'modal-title-editor-template-email'"></lib-translate></h4>

        <a
            class="nav-link"
            data-toggle="dropdown"
            role="button"
            popoverClass=""
            [popoverTitle]="popTitle"
            [ngbPopover]="popContent"
        >
            <fa-icon icon="circle-info"></fa-icon>
        </a>
        <ng-template #popTitle>
            <lib-translate [key]="'title-variable-information-template'"></lib-translate>
        </ng-template>
        <ng-template #popContent>
            <ul class="list-group">
                <li *ngFor="let variable of VariableEmailTemplate | keyvalue">
                    <p>{{variable.key}} : {{variable.value}}</p>
                </li>
            </ul>
            <div class="dropdown-divider"></div>
        </ng-template>
    </div>
    <div class="modal-body">
        <angular-editor
            [config]="editorConfig"
            [(ngModel)]="htmlContent"
        ></angular-editor>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            (click)="updateEmailTemplate()"
        ><lib-translate [key]="'button-validate'"></lib-translate></button>
    </div>
</ng-template>
