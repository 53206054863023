import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EntityDefinition, PropertyDefinition } from "../lib-card-generic-crud.interface";
import { PropertyOption } from "../lib-card-generic-crud.enums";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-card-generic-crud-actions',
  templateUrl: './lib-card-generic-crud-actions.component.html',
  styleUrls: ['./lib-card-generic-crud-actions.component.scss']
})
export class LibCardGenericCrudActionsComponent implements OnInit {

  @Input() id: string = "id";
  @Input() title: string = "title";
  @Input() itemDefinition: EntityDefinition = { Name: "", Properties: [], ColumnSettings: [] };
  @Input() currentItem: any = null;
  @Input() context: string = "update";
  @Input() showDelete: boolean = true;
  @Output() itemUpdated = new EventEmitter<any>();
  @Output() itemAdded = new EventEmitter<any>();
  @Output() itemDeleted = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal) {
  }

  dismiss() {
    return this.activeModal.dismiss();
  }

  ngOnInit(): void {
  }

  isPropertyKey(property: PropertyDefinition): boolean {
    return property.Options.indexOf(PropertyOption.IsKey) !== -1;
  }

  isPropertyNullable(property: PropertyDefinition): boolean {
    return property.Options.indexOf(PropertyOption.IsNullable) !== -1;
  }

  onAdd(): void {
    this.currentItem = {};

    this.itemDefinition.Properties.forEach((property) => {
      this.currentItem[property.Name] = "";
    });
    this.context = "add";
    this.showDelete = false;
  }

  onEdit(item: any): void {
    this.currentItem = item;
    this.context = "update";
    this.showDelete = true;
  }

  onValidateAdd(): void {
    this.itemAdded.emit(this.currentItem);
  }

  onValidateUpdate(): void {
    this.itemUpdated.emit(this.currentItem);
  }

  onDelete(): void {
    this.itemDeleted.emit(this.currentItem);
  }

  onPropertyUpdated(propertyName: string, $event: any) {
    if (this.currentItem)
      this.currentItem[propertyName] = $event.target.value;
  }
}
