import { Component, Input, OnInit } from '@angular/core';
import { ColumnSetting } from '@herdia-common';
import { LibHttpClientApiService, CardConfigBaseComponent } from '@herdia-common';
import { TranslatePipe } from "@ngx-translate/core";
import { LibCardReportService } from '../lib-card-report.service';

@Component({
    selector: 'lib-card-report-config-file-deposit',
    templateUrl: './lib-card-report-config-file-deposit.component.html',
    styleUrls: ['./lib-card-report-config-file-deposit.component.scss']
})
export class LibCardReportConfigFileDepositComponent extends CardConfigBaseComponent implements OnInit {

    availableCols: ColumnSetting[] = [];
    constructor(private apiService: LibHttpClientApiService, private translatePipe: TranslatePipe, private cardReportService: LibCardReportService) {
        super();
    }

    ngOnInit(): void {
        if (this.config['fileDeposit'] === undefined) {
            this.config['fileDeposit'] = {
                fileDepositType: {
                    Id: null,
                    Label: "",
                    Enable: true,
                    EnableString: "yes",
                    Type: 0,
                    Tag: "",
                    Filter: "",
                    Auth: 0,
                    Login: "",
                    Password: "",
                    Host: "",
                    Port: 0,
                    RootPath: "",
                    Capabilities: [0]
                },
                paramsFileDeposit: [],
                columnLink: { name: "", label: "", type: "", visible: true, nbDecimal: 2, nature: 'normal', horizontalAlign: 'left', displaySum: false, width: null, actionColumnDef: null }
            };
        }

        if (this.config['DataTableRequestParams'].columns !== undefined) {
            this.availableCols = this.config['DataTableRequestParams'].columns;
        }

    }

    onFileDepositConfChange(event: any) {//type d'entré :configFileDeposit
        this.config['fileDeposit'] = event;
    }

}
