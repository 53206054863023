import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LibDynamicComponentsEventBusService } from '@herdia-common';
import { ITranscodingItemsValues, ITranscodingItemsValuesParams, IUpdateParamDestinationParams } from '../lib-card-input-mapping.interface';
import { LibCardInputMappingService } from '../lib-card-input-mapping.service';

@Component({
  selector: 'lib-edit-input-mapping-modal',
  templateUrl: './edit-input-mapping-modal.component.html',
  styleUrls: ['./edit-input-mapping-modal.component.css']
})
export class EditInputMappingModalComponent implements OnInit {
  @Input() data: any;
  @Input() selectedTranscodingType!: string;
  @Output() public destinationCodeUpdated = new EventEmitter<void>();

  private activeModal = inject(NgbActiveModal);
  private inputMappingService = inject(LibCardInputMappingService);
  private eventBusService = inject(LibDynamicComponentsEventBusService);

  destinationCodes$!: Observable<ITranscodingItemsValues[]>;
  selectedDestinationCode: string = "";

  sourceLabel: string = "";

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    let transcodingItemsValuesParams = {
      "LanguageCode": "FR",
      "TranscodingType": this.selectedTranscodingType,
      "IsInterfaceRelated": "0"
    } as ITranscodingItemsValuesParams;
    this.destinationCodes$ = this.inputMappingService.getDestinationCodes(transcodingItemsValuesParams);
    this.selectedDestinationCode = this.data.DESTINATION_CODE;
    this.sourceLabel = this.data.SOURCE_LABEL;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save(): void {
    let updateParamDestinationParams = {
      "TranscodingCode": this.data.TRANSCODING_CODE,
      "TranscodingType": this.selectedTranscodingType,
      "SourceLabel": this.sourceLabel,
      "DestinationCode": this.selectedDestinationCode,
      "IsInterfaceRelated": "0"
    } as IUpdateParamDestinationParams
    this.inputMappingService.updateParamDestination(updateParamDestinationParams)?.pipe(takeUntil(this.destroy$))
      .subscribe(e => {
        this.eventBusService.addSubject("ParamDestinationUpdated", 0, new Subject<string>());
        this.eventBusService.cardSubjects["ParamDestinationUpdated_0"].next("ParamDestinationUpdated");
      });
    this.destinationCodeUpdated.emit();
    this.activeModal.close();
  }

  dismiss() {
    return this.activeModal.dismiss();
  }

  close() {
    return this.activeModal.close();
  }

}
