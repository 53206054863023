<ngb-toast
			*ngFor="let toast of getToastService.toasts"
			[class]="toast.className"
			[autohide]="true"
			[delay]="toast.delay || 5000"
			(hidden)="getToastService.remove(toast)"
		>
    <ng-template ngbToastHeader>
      <div class="me-auto">
        <fa-icon icon="exclamation-circle"></fa-icon>
        <strong class="mx-1">{{ toast.message.TitleCode | translate }}</strong>
      </div>
    </ng-template>
    {{ toast.message.ContentCode | translate }}
</ngb-toast>
