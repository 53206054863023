import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';
import { CardConfigBaseComponent } from '../../dynamic-components/card-config-base-component';
import { LibCardHtmlEditorService } from '../lib-card-html-editor.service';

@Component({
    selector: 'lib-card-html-editor-config',
    templateUrl: './lib-card-html-editor-config.component.html',
    styleUrls: ['./lib-card-html-editor-config.component.scss']
})
export class LibCardHtmlEditorConfigComponent extends CardConfigBaseComponent implements OnInit {

    @Output()
    updateConfigEvtEmitter = new EventEmitter<{ result: boolean }>();

    saveSubject = new Subject<boolean>();
    htmlContent!: string;
    UploadId!: number;

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '20rem',
        minHeight: '20rem',
        maxHeight: '20rem',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        sanitize: false,
        toolbarHiddenButtons: [['insertVideo'], ['toggleEditorMode']],
        customClasses: [
            {
                name: "Main Color",
                class: "mainColor",
            },
            {
                name: "Secondary Color",
                class: "secondaryColor",
            },
        ]
    };

    listLanguages: any;
    languageSelected: string = 'fr';

    constructor(private serviceHtmlEdit: LibCardHtmlEditorService) {
        super();
    }

    languageChange(event: any) {
        this.languageSelected = event.id;

        //reload the content html depanding of the select language
        this.serviceHtmlEdit.GetHtmlPartiel(this.id, this.languageSelected).subscribe((res: any) => {

            if (res.response == null) //the content was not find so the content will be empty
            {
                this.htmlContent = '';
            }
            else {
                this.htmlContent = res.response.Content;
                this.languageSelected = res.response.Language;
            }
        },
            error => {
                console.log(error);
            });
    }



    saveCallback = async (): Promise<boolean> => {
        try {
            const res: any = await this.serviceHtmlEdit.SaveHtmlPartiel(this.htmlContent, this.languageSelected, this.id).toPromise(); //deprecated, we better use firstValueFrom instead of toPromise()
            if (res.response.success == true) {
                this.UploadId = res.response.uploadId;
                if (this.config.hasOwnProperty('FileUploadId')) {
                    this.config['FileUploadId'][this.languageSelected] = this.UploadId;
                }
                else {
                    this.config['FileUploadId'] = {};
                    this.config['FileUploadId'][this.languageSelected] = this.UploadId;
                }
                return true;
            }
            else {
                return false;
            }
        }
        catch (e) {
            console.log(e);
            return false;
        }
    };

    ngOnInit(): void {
        this.listLanguages = [
            { id: "fr", value: "French" },
            { id: "en", value: "English" },
            { id: "de", value: "German" }
        ];

        //get content existed from db
        var lang: string = localStorage.getItem("userLocale") as string;
        this.serviceHtmlEdit.GetHtmlPartiel(this.id, 'fr').subscribe((res: any) => { //lang
            if (res.response === null) {
                this.htmlContent = "";
                this.languageSelected = "";
            }
            else {
                this.htmlContent = res.response.Content;
                this.languageSelected = res.response.Language;
            }
        },
            error => {
                console.log(error);
            });

    }

}
