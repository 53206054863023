import { Component, Input, OnInit, TemplateRef, ViewChild, inject, OnDestroy } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { LibCardInputMappingService } from './lib-card-input-mapping.service';
import { ICardConfig, TreeNode } from '@herdia-common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IParamTranscodingItemsParams, ITranscodingTypeFilter, ITranscodingTypeParam } from './lib-card-input-mapping.interface';
import { LibHerdiaDatatableComponent, HerdiaDatatableGlobalConfiguration, ServerSideResponse, ServerSideRequest } from '@herdia-common';
import { LibDynamicComponentsEventBusService } from '@herdia-common';
import { ColumnSetting } from '@herdia-common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditInputMappingModalComponent } from './edit-input-mapping-modal/edit-input-mapping-modal.component';

@Component({
  selector: 'lib-card-input-mapping',
  templateUrl: './lib-card-input-mapping.component.html',
  styles: [
  ]
})
export class LibCardInputMappingComponent implements ICardConfig, OnInit, OnDestroy {
  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;

  @Input() config: { [key: string]: any } = {};

  eventBusService = inject(LibDynamicComponentsEventBusService);

  // Give a high number so there wil be no card with this number (same number as in the table HAPageCards)
  cardId: number = 999999999;

  // variables from interface ICardConfig, required for the card to work
  allowFullScreen: boolean = true;
  allowMobileView: boolean = false;
  hasFooter: boolean = true;
  hasHeader: boolean = true;
  hasButton: boolean = true;
  isConfigurable: boolean = false;
  minWidth: number = 6;
  cardCustomClasses: string = "";
  configTreeNode: TreeNode[] = [];
  //

  searchEntitySubject: Subject<string> = new Subject<string>();
  searchValue = "";

  propertyManagerFilter$ = this.inputMappingService.getPropertyManagerList();
  selectedPropertyManager: string = "ALL";

  transcodingTypeFilter$!: Observable<ITranscodingTypeFilter[]>;
  selectedTranscodingType!: string;

  placeholderText: string = this.translatePipe.transform("lbl-text-search-in-columns");

  // Datatable configuration
  herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: false,
    defaultRowNumberOnPage: 10,
    autoWidth: false,
    fixedLeftColumns: 1
  };

  modalRef!: NgbModalRef;

  onEdit = (data: any) => {
    this.modalRef = this.modalService.open(EditInputMappingModalComponent);
    this.modalRef.componentInstance.data = data;
    this.modalRef.componentInstance.selectedTranscodingType = this.selectedTranscodingType;
    this.modalRef.componentInstance.destinationCodeUpdated.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.herdiaDatatable.reload();
    });
  }

  // Columns that will be displayed in the datatable
  columnSetting: ColumnSetting[] = [
    {
      name: "TRANSCODING_CODE",
      label: this.translatePipe.transform("TRANSCODING_CODE"),
      nature: "normal",
      nbDecimal: 0,
      type: "string",
      displaySum: false,
      horizontalAlign: "left",
      visible: false,
      width: null,
      actionColumnDef: null
    },
    {
      name: "SOURCE_CODE",
      label: this.translatePipe.transform("SOURCE_CODE"),
      nature: "normal",
      nbDecimal: 0,
      type: "string",
      displaySum: false,
      horizontalAlign: "left",
      visible: true,
      width: null,
      actionColumnDef: null
    },
    {
      name: "SOURCE_LABEL",
      label: this.translatePipe.transform("SOURCE_LABEL"),
      nature: "normal",
      nbDecimal: 0,
      type: "string",
      displaySum: false,
      horizontalAlign: "left",
      visible: true,
      width: null,
      actionColumnDef: null
    },
    {
      name: "DESTINATION_CODE",
      label: this.translatePipe.transform("DESTINATION_CODE"),
      nature: "normal",
      nbDecimal: 0,
      type: "string",
      displaySum: false,
      horizontalAlign: "left",
      visible: true,
      width: null,
      actionColumnDef: null
    },
    {
      name: "DESTINATION_LABEL",
      label: this.translatePipe.transform("DESTINATION_LABEL"),
      nature: "normal",
      nbDecimal: 0,
      type: "string",
      displaySum: false,
      horizontalAlign: "left",
      visible: true,
      width: null,
      actionColumnDef: null
    },
    {
      name: "IS_EDITABLE",
      label: this.translatePipe.transform("IS_EDITABLE"),
      nature: "normal",
      nbDecimal: 0,
      type: "string",
      displaySum: false,
      horizontalAlign: "left",
      visible: false,
      width: null,
      actionColumnDef: null
    },
    {
      name: "Actions",
      label: "Actions",
      type: "custom",
      visible: true,
      actionColumnDef: {
        align: "center",
        buttons: [
          {
            type: "button",
            icon: "edit",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.onEdit
          }
        ]
      },
      displaySum: false,
      nbDecimal: 0,
      nature: "normal",
      width: null,
      horizontalAlign: "center"
    }
  ];

  // Datatable parameters to retrieve the data
  datatableParams: ServerSideRequest = {
    "draw": 1,
    "columns": [
      {
        "data": "TRANSCODING_CODE",
        "name": "TRANSCODING_CODE",
        "searchable": true,
        "orderable": true,
        "search": {
          "value": "",
          "regex": false
        }
      },
      {
        "data": "SOURCE_CODE",
        "name": "SOURCE_CODE",
        "searchable": true,
        "orderable": true,
        "search": {
          "value": "",
          "regex": false
        }
      },
      {
        "data": "SOURCE_LABEL",
        "name": "SOURCE_LABEL",
        "searchable": true,
        "orderable": true,
        "search": {
          "value": "",
          "regex": false
        }
      },
      {
        "data": "DESTINATION_CODE",
        "name": "DESTINATION_CODE",
        "searchable": true,
        "orderable": true,
        "search": {
          "value": "",
          "regex": false
        }
      },
      {
        "data": "DESTINATION_LABEL",
        "name": "DESTINATION_LABEL",
        "searchable": true,
        "orderable": true,
        "search": {
          "value": "",
          "regex": false
        }
      },
      {
        "data": "IS_EDITABLE",
        "name": "IS_EDITABLE",
        "searchable": true,
        "orderable": true,
        "search": {
          "value": "",
          "regex": false
        }
      }
    ],
    "order": [
      {
        "column": 0,
        "dir": "asc"
      }
    ],
    "start": 0,
    "length": 10,
    "search": {
      "value": "",
      "regex": false
    }
  };

  // Parameters of the stored procedure to retrieve the data
  procedureParams: IParamTranscodingItemsParams = {
    "PropertyManagerCode": this.selectedPropertyManager,
    "TranscodingType": this.selectedTranscodingType,
    "PortfolioCode": "00000000000000000000",
    "LanguageCode": "FR",
    "IsInterfaceRelated": "0"
  };
  constructor(public inputMappingService: LibCardInputMappingService, private modalService: NgbModal, private translatePipe: TranslatePipe) { }

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.onPropertyManagerChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPropertyManagerChange(): void {
    let updateTranscodingTypesListParam = {
      "LanguageCode": "FR",
      "PropertyManagerCode": this.selectedPropertyManager
    } as ITranscodingTypeParam
    this.transcodingTypeFilter$ = this.inputMappingService.updateTranscodingTypesList(updateTranscodingTypesListParam);
    this.transcodingTypeFilter$.pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.selectedTranscodingType = result[0].TRANSCODING_TYPE;
      this.herdiaDatatable.reload();
    });
  }

  onTranscodingTypeChange(): void {
    this.herdiaDatatable.reload();
  }

  getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
    this.procedureParams = {
      "PropertyManagerCode": this.selectedPropertyManager,
      "TranscodingType": this.selectedTranscodingType,
      "PortfolioCode": "00000000000000000000",
      "LanguageCode": "FR",
      "IsInterfaceRelated": "0"
    }
    return this.inputMappingService.getTranscodingItems(
      {
        "datatableParams": dataTablesParameters,
        "procedureParams": this.procedureParams
      }
    );
  }

  onSearch(): void {
    this.eventBusService.addSubject("SearchEntity", this.cardId, this.searchEntitySubject);
    this.eventBusService.cardSubjects["SearchEntity_" + this.cardId].next(this.searchValue);
  }

}
