import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from '../shared/service/app.service';
import { PersistentNotificationService } from '../shared/service/persistent-notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  editMode = false;
  hasRightPanel = false;
  applicationName = '';
  sidebar_collapse_menu = false;
  sidebar_collapse_filter = false;
  private destroy$ = new Subject<void>();

  constructor(private appSvc: AppService, private persistentNotificationService: PersistentNotificationService, @Inject(DOCUMENT) private document: Document) { }

  get notifications() {
    return this.persistentNotificationService.Notifications;
  }

  ngOnInit(): void {
    this.appSvc.editModeSub.pipe(takeUntil(this.destroy$)).subscribe(mode => this.editMode = mode);
    this.appSvc.hasRightPanel.pipe(takeUntil(this.destroy$)).subscribe(rp => this.hasRightPanel = rp);
    this.appSvc.applicationName.pipe(takeUntil(this.destroy$)).subscribe(an => this.applicationName = an);
  }

  setEditMode() {
    this.appSvc.setEditMode();
  }

  sideBarAnim() {
    if (this.sidebar_collapse_menu == false) {
      this.document.body.classList.add('sidebar-collapse');
      this.sidebar_collapse_menu = true;
    }
    else {
      this.document.body.classList.remove('sidebar-collapse')
      this.sidebar_collapse_menu = false;
    }
  }

  sideBarFilterAnim() {
    if (this.sidebar_collapse_filter == false) {
      this.document.body.classList.add('control-sidebar-slide-open');
      this.sidebar_collapse_filter = true;
    }
    else {
      this.document.body.classList.remove('control-sidebar-slide-open')
      this.sidebar_collapse_filter = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
