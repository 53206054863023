import { ChangeDetectorRef, Component, inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { addDays, addMonths, addYears, format } from 'date-fns';
import { Subject, takeUntil } from 'rxjs';
import { CardService } from '../../core/shared/service/card.service';
import { LibDynamicComponentsEventBusService, LibHerdiaAppTranslateService } from '../../herdia-common';
import { DataSourceParameter } from '../lib-card-report/lib-card-report.interfaces';
import { LibPanelRightService } from '../lib-panel-right/lib-panel-right.service';
import { Context } from './lib-header-binding.enum';

@Component({
  selector: 'lib-header-binding',
  templateUrl: './lib-header-binding.component.html',
  styleUrls: ['./lib-header-binding.component.scss']
})
export class LibHeaderBindingComponent implements OnInit, OnDestroy {

  cardId!: number;

  @Input() config: { [key: string]: any } = {};
  @Input() context!: Context;

  private eventBusService = inject(LibDynamicComponentsEventBusService);
  private libFilterService = inject(LibPanelRightService);
  private translateService = inject(LibHerdiaAppTranslateService);
  private cardService = inject(CardService);
  private cdr = inject(ChangeDetectorRef);
  private zone = inject(NgZone);

  isStartDate: boolean = false;
  isEndDate: boolean = false;
  isAnalysisDate: boolean = false;
  cardFilterParameters: DataSourceParameter[] = [];
  parameterList: { parameterName: string, parameterValue: any }[] = [];

  private _analysisDate: string = "";
  get analysisDate(): string {
    return this._analysisDate;
  }
  set analysisDate(value: string) {
    this._analysisDate = value;
    // Appeler la méthode ou l'action que vous souhaitez déclencher ici
    this.onUpdateAnalysisDate(value);
  }
  private _endDate: string = "";
  get endDate(): string {
    return this._endDate;
  }
  set endDate(value: string) {
    this._endDate = value;
    // Appeler la méthode ou l'action que vous souhaitez déclencher ici
    this.onUpdateEndDate(value);
  }
  private _startDate: string = "";
  get startDate(): string {
    return this._startDate;
  }
  set startDate(value: string) {
    this._startDate = value;
    // Appeler la méthode ou l'action que vous souhaitez déclencher ici
    this.onUpdateStartDate(value);
  }

  lblAnalysisDate = this.translateService.get("ANALYSIS_DATE");
  lblStartDate = this.translateService.get("START_DATE");
  lblEndDate = this.translateService.get("END_DATE");

  // importDateChangedSubject: Subject<any> = new Subject<any>();.pipe(takeUntil(this.destroy$))

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.cardId = this.config['cardId'];

    if (this.libFilterService.selectedImportDate)
      this.loadingDate(this.libFilterService.selectedImportDate, false);

    this.libFilterService.Filters.importDate.subscribe(date => {
      this.zone.run(() => {
        this.loadingDate(date, true);
        this.cdr.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadingDate(importDate: Date, filterImportChange: boolean): void {
    if (!this.config["CardFilters"])
      this.config["CardFilters"] = {};

    // Assurez-vous que les paramètres existent avant de les parcourir
    if (this.config["DataSource"]["Parameters"]) {
      // Filtrez les paramètres de la carte en fonction du type "cardFilter"
      this.cardFilterParameters = this.config['DataSource']['Parameters'].filter((p: DataSourceParameter) => p.ParameterBinding.Type == "cardFilter");
      this.cardFilterParameters.forEach((p: DataSourceParameter) => {
        switch (p.ParameterName) {
          case "StartDate":
            this.isStartDate = true;
            break;
          case "EndDate":
            this.isEndDate = true;
            break;
          case "AnalysisDate":
            this.isAnalysisDate = true;
            break;
        }

        // Vérifiez si le paramètre est de type "datetime"
        if (p.ParameterType.toLowerCase().indexOf("datetime") !== -1) {
          // Effectuez les calculs sur la date en fonction des opérations, des opérateurs et des multiplicateurs
          let operand = p.ParameterBinding.Value.Operand;
          if (p.ParameterBinding.Value.Operator.toLowerCase() != "plus") {
            operand = -operand;
          }

          // Initialisez la date résultante
          let resultDate = new Date();
          resultDate.setUTCHours(0, 0, 0, 0);

          // Utilisez la date d'importation ou la date actuelle en fonction de la configuration
          switch (p.ParameterBinding.Value.InitDate.toLowerCase()) {
            case "today":
              resultDate = new Date();
              break;
            case "importdate":
              resultDate = new Date(importDate);
              break;
          }

          if (filterImportChange && p.ParameterBinding.Value.InitDate.toLowerCase() === "importdate") {
            p.ParameterBinding.Value.Operand = 0;
          }
          else {
            switch (p.ParameterBinding.Value.Multiplier.toLowerCase()) {
              default:
              case "days":
                resultDate = addDays(resultDate, operand);
                break;
              case "months":
                resultDate = addMonths(resultDate, operand);
                break;
              case "years":
                resultDate = addYears(resultDate, operand);
                break;
            }
          }

          // Mettez à jour les variables de date correspondantes
          switch (p.ParameterName) {
            case "StartDate":
              const startIdx = this.parameterList.findIndex(item => item.parameterName === p.ParameterName);
              this.startDate = this.convertDateToString(resultDate);
              if (startIdx !== -1) {
                this.parameterList[startIdx].parameterValue = this.startDate;
              } else {
                this.parameterList.push({ parameterName: "StartDate", parameterValue: this.startDate });
              }
              break;
            case "EndDate":
              const endIdx = this.parameterList.findIndex(item => item.parameterName === p.ParameterName);
              this.endDate = this.convertDateToString(resultDate);
              if (endIdx !== -1) {
                this.parameterList[endIdx].parameterValue = this.endDate;
              } else {
                this.parameterList.push({ parameterName: "EndDate", parameterValue: this.endDate });
              }
              break;
            case "AnalysisDate":
              const analysisIdx = this.parameterList.findIndex(item => item.parameterName === p.ParameterName);
              this.analysisDate = this.convertDateToString(resultDate);
              if (analysisIdx !== -1) {
                this.parameterList[analysisIdx].parameterValue = this.analysisDate;
              } else {
                this.parameterList.push({ parameterName: "AnalysisDate", parameterValue: this.analysisDate });
              }
              break;
          }

          // Mettez à jour la configuration de la carte avec la date convertie
          this.config["CardFilters"][p.ParameterName] = this.convertDateToString(resultDate);
        }
      });
    }
  }


  onUpdateAnalysisDate(event: any): void {
    let parsedDate = format(new Date(event), "yyyy-MM-dd");
    this.config['DataSource']['Parameters'].forEach((parameter: DataSourceParameter) => {
      if (parameter.ParameterName === 'AnalysisDate') {
        this.createDatasourceParameter(parsedDate, parameter);
        this.parameterList.filter(el => el.parameterName === 'AnalysisDate').map(item => item.parameterValue = parsedDate);
      }
    });
  }

  onUpdateStartDate(event: any): void {
    let parsedDate = format(new Date(event), "yyyy-MM-dd");
    this.config['DataSource']['Parameters'].forEach((parameter: DataSourceParameter) => {
      if (parameter.ParameterName === 'StartDate') {
        this.createDatasourceParameter(parsedDate, parameter);
        this.parameterList.filter(el => el.parameterName === 'StartDate').map(item => item.parameterValue = parsedDate);
      }
    });
  }

  onUpdateEndDate(event: any): void {
    let parsedDate = format(new Date(event), "yyyy-MM-dd");
    this.config['DataSource']['Parameters'].forEach((parameter: DataSourceParameter) => {
      if (parameter.ParameterName === 'EndDate') {
        this.createDatasourceParameter(parsedDate, parameter);
        this.parameterList.filter(el => el.parameterName === 'EndDate').map(item => item.parameterValue = parsedDate);
      }
    });
  }

  convertDateToString(date: Date): string {
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  differenceInDays(date1: string, date2: string): number {
    const date1Obj = new Date(date1);
    const date2Obj = new Date(date2);

    // Convertir les dates en millisecondes et calculer la différence en jours
    const differenceEnMilliseconds = date2Obj.getTime() - date1Obj.getTime();
    const differenceEnJours = differenceEnMilliseconds / (1000 * 60 * 60 * 24);

    return Math.floor(differenceEnJours);
  }

  createDatasourceParameter(dateToProcess: string, p: DataSourceParameter): void {
    let dateToCompare;
    if (p.ParameterBinding.Value.InitDate == "today")
      dateToCompare = format(new Date(), "yyyy-MM-dd");
    else
      dateToCompare = format(new Date(this.libFilterService.selectedImportDate!), "yyyy-MM-dd");

    this.config["CardFilters"][p.ParameterName] = dateToProcess;

    let days = this.differenceInDays(dateToCompare, dateToProcess);

    if (days < 0) {
      p.ParameterBinding.Type = 'cardFilter';
      p.ParameterBinding.Value.Operator = "minus";
      p.ParameterBinding.Value.Multiplier = "days";
      p.ParameterBinding.Value.Operand = Math.abs(days);
      p.ParameterBinding.CardFilterBindingCategory = "raw";
      p.ParameterBinding.CardFilterSelectedFilter = "minus";
      p.ParameterBinding.Nullable = false;
    }
    else {
      p.ParameterBinding.Type = 'cardFilter';
      p.ParameterBinding.Value.Operator = "plus";
      p.ParameterBinding.Value.Multiplier = "days";
      p.ParameterBinding.Value.Operand = Math.abs(days);
      p.ParameterBinding.CardFilterBindingCategory = "raw";
      p.ParameterBinding.CardFilterSelectedFilter = "plus";
      p.ParameterBinding.Nullable = false;
    }
  }

  shareEvent(parameterList: { parameterName: string, parameterValue: any }[]): void {
    if (this.context === Context.Chart) {
      this.eventBusService.addSubject('CardFilterChange', this.cardId, new Subject<{ parameterName: string, parameterValue: any }[]>());
      this.eventBusService.cardSubjects["CardFilterChange_" + this.cardId].next(parameterList);
    }
    else {
      this.eventBusService.addSubject('ParameterDateChangedReport', this.cardId, new Subject<{ parameterName: string, parameterValue: any }[]>());
      this.eventBusService.cardSubjects["ParameterDateChangedReport_" + this.cardId].next(parameterList);
    }
  }

  onApplyDates(): void {
    this.cardService.updateConfig(this.config).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.shareEvent(this.parameterList);
    });
  }
}
