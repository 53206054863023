import { Component, OnInit, Input, inject } from '@angular/core';
import { CardThemeDesignerService } from '../shared/service/card-theme-designer.service';
import { ThemeVariables, UpdateThemeRequest } from '../shared/models/interfaces';;

@Component({
    selector: 'app-card-theme-designer',
    templateUrl: './card-theme-designer.component.html',
    styleUrls: ['./card-theme-designer.component.scss']
})
export class CardThemeDesignerComponent implements OnInit {

    @Input() config: { [key: string]: string } = {};

    primaryColor: string = "";
    secondaryColor: string = "";
    customFontSize: string = "";
    leftNavbarColor: string = "";
    topNavbarColor: string = "";
    active = "";

    constructor(private ThemeDesignerService: CardThemeDesignerService) { }

    ngOnInit(): void {
        const actualTheme = localStorage.getItem('ActualTheme');
        if (!actualTheme) {
            console.log("debug");
            return;
        }

        this.active = actualTheme; // Soit 'Theme1' soit 'Theme2'

        this.ThemeDesignerService.getThemeDefinition(actualTheme).subscribe((r) => {
            this.primaryColor = r.find(element => element.VariableName == "PrimaryColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--primaryColor', this.primaryColor);

            this.secondaryColor = r.find(element => element.VariableName == "SecondaryColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--secondaryColor', this.secondaryColor);

            this.leftNavbarColor = r.find(element => element.VariableName == "NavbarColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--leftNavbarColor', this.leftNavbarColor);

            this.topNavbarColor = r.find(element => element.VariableName == "TopNavbarColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--topNavbarColor', this.topNavbarColor);

            this.customFontSize = r.find(element => element.VariableName == "customFontSize")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--customFontSize', String(this.customFontSize) + "rem");
        });
    }

    Theme1Show() {
        this.showTheme("Theme1");
    }

    Theme2Show() {
        this.showTheme("Theme2");
    }

    showTheme(Label: string) { //Applique un theme en fonction du label envoyé en parametre
        this.ThemeDesignerService.showTheme(Label).subscribe(r => {
            this.primaryColor = r.find(element => element.VariableName == "PrimaryColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--primaryColor', this.primaryColor);

            this.secondaryColor = r.find(element => element.VariableName == "SecondaryColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--secondaryColor', this.secondaryColor);

            this.leftNavbarColor = r.find(element => element.VariableName == "NavbarColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--leftNavbarColor', this.leftNavbarColor);

            this.topNavbarColor = r.find(element => element.VariableName == "TopNavbarColor")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--topNavbarColor', this.topNavbarColor);

            this.customFontSize = r.find(element => element.VariableName == "customFontSize")?.VariableValue as string;
            this.ThemeDesignerService.themeWrapper?.style.setProperty('--customFontSize', String(this.customFontSize) + "rem");

            localStorage.setItem('ActualTheme', Label);
        });
    }

    Theme1Save() {
        let TargetTheme = {
            Label: "Theme1",
            PrimaryValue: this.primaryColor,
            SecondaryValue: this.secondaryColor,
            navbarValue: this.leftNavbarColor,
            topNavbarValue: this.topNavbarColor,
            customFontSize: this.customFontSize
        } as UpdateThemeRequest;

        this.ThemeDesignerService.updateThemeVariableValues(TargetTheme)?.subscribe((l) => {
        });
    }

    Theme2Save() {
        let TargetTheme = {
            Label: "Theme2",
            PrimaryValue: this.primaryColor,
            SecondaryValue: this.secondaryColor,
            navbarValue: this.leftNavbarColor,
            topNavbarValue: this.topNavbarColor,
            customFontSize: this.customFontSize
        } as UpdateThemeRequest;
        this.ThemeDesignerService.updateThemeVariableValues(TargetTheme)?.subscribe((l) => {
        });
    }

    primaryColorChange() {
        this.ThemeDesignerService.themeWrapper?.style.setProperty('--primaryColor', this.primaryColor);
    }

    secondaryColorChange() {
        this.ThemeDesignerService.themeWrapper?.style.setProperty('--secondaryColor', this.secondaryColor);
    }

    navbarColorChange() {
        this.ThemeDesignerService.themeWrapper?.style.setProperty('--leftNavbarColor', this.leftNavbarColor);
    }

    topNavbarColorChange() {
        this.ThemeDesignerService.themeWrapper?.style.setProperty('--topNavbarColor', this.topNavbarColor);
    }

    fontSizeChange() {
        this.ThemeDesignerService.themeWrapper?.style.setProperty('--customFontSize', String(this.customFontSize) + "rem");
    }

    SetDefaultTheme(): void {
        this.ThemeDesignerService.setDefaultTheme().subscribe(r => {
            this.primaryColor = r[0];
            this.secondaryColor = r[1];
            this.customFontSize = r[2];
            this.leftNavbarColor = r[3];
            this.topNavbarColor = r[4];

            let Theme = {
                PrimaryColor: r[0],
                SecondaryColor: r[1],
                CustomFontSize: r[2],
                LeftNavbarColor: r[3],
                TopNavbarColor: r[4]
            } as ThemeVariables;

            this.ThemeDesignerService.showCustomTheme(Theme);

            const actualTheme = localStorage.getItem('ActualTheme');
            if (actualTheme)
                localStorage.removeItem('ActualTheme');
        });
    }


}
