<div class="card card-primary addCardContent">
  <ng-select [items]="cardActionsList"
           bindLabel="label"
           bindValue="action"
           (change)="addCard($event)">
  </ng-select>
</div>



