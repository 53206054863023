<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <lib-translate [key]="'app-role-add-lbl'"></lib-translate>
  </h4>
  <button type="button" class="btn-close" (click)="dismiss('cancelled')"></button>
</div>
<div class="modal-body">
  <input type="text" name="name" class="form-control" [(ngModel)]="name" placeholder="Nouveau role" />
</div>
<div class="modal-footer">
  <div class="btn-group">
    <button type="button" class="btn btn-secondary" [disabled]="!name" (click)="confirm()">
      <span *ngIf="id === ''; then addBtn else saveBtn"></span>
      <ng-template #addBtn>
        <lib-translate [key]="'app-role-add-btn'"></lib-translate>
      </ng-template>
      <ng-template #saveBtn>
        <lib-translate [key]="'app-role-save-btn'"></lib-translate>
      </ng-template>
    </button>
    <button type="button" class="btn btn-danger" (click)="dismiss('cancelled')">
      <lib-translate [key]="'app-role-add-cancel-btn'"></lib-translate>
    </button>
  </div>
</div>
