<div class="col-md-12">
    <div class="card card-primary haCardBox container-toggle">
        <div class="card-header">
            <h3 class="card-title"><fa-icon icon="check-circle"></fa-icon>&nbsp;<lib-translate
                    [key]="'card-title-translation-manager'"
                ></lib-translate></h3>
        </div>
        <div class="card-body">

            <div class="input-group input-group-sm divTableSearch">
                <!-- Search bar for specific translation terms-->
                <input
                    type="search"
                    placeholder="Rechercher dans les colonnes..."
                    class="form-control"
                    [(ngModel)]="searchValue"
                >
                <div class="input-group-append">
                    <button
                        type="submit"
                        class="btn btn-tool"
                        (click)="onSearch()"
                    >
                        <fa-icon icon="search"></fa-icon>
                    </button>
                </div>
            </div>

            <lib-herdia-datatable
                #herdiaDatatable
                [cardId]="cardId"
                [columnSettings]="columnSettings"
                [dataGetter]="getData"
                [keepRowNumber]="true"
                [herdiaDTConfig]="herdiaDTConfig"
            ></lib-herdia-datatable>
        </div>
        <div class="card-footer">
            <lib-datatable-pagination [cardId]="cardId"></lib-datatable-pagination>
        </div>
    </div>
</div>
