import { Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { LibHttpClientApiService } from '@herdia-common';
import { ApiController } from '@herdia-common';
import { LibProcedureInputService } from '../lib-procedure-input.service';
import { PropertyDefinition } from '@herdia-common';

@Component({
  selector: 'lib-data-input-selector',
  templateUrl: './lib-data-input-selector.component.html',
  styleUrls: ['./lib-data-input-selector.component.css']
})
export class LibDataInputSelectorComponent implements OnInit {

  @ViewChild('inputParameters', { static: false }) inputParameters!: ElementRef;
  selectedProcedure!: { technicalName: string, label: string};
  apiControllerList: ApiController[] = [];
  labeledControllerList: { name: string, label : string }[] = [];
  listValuesPropertyDefinitions: PropertyDefinition[] = [];

  @Input() config: { [key: string]: any } = {};
  @Input() displayStoredProcedure!: boolean;
  @Input() allowRawBinding!: boolean;
  @Input() allowCardBinding!: boolean;
  @Input() allowPageBinding!: boolean;
  @Input() dsFilter?: string;

  constructor(private apiService: LibHttpClientApiService, private libTranslatePipe: TranslatePipe, private libProcedureInputSvc: LibProcedureInputService) { }

  ProcedureChanged(controller:any): void {
    this.config["DataSource"]["ProcedureName"] = controller.name;
    this.config["DataSource"]["Parameters"] = [];
    this.listValuesPropertyDefinitions = [];

    if (controller.name != undefined && controller.name != '') { //attention
      this.libProcedureInputSvc.getProcedureParametersListValues(controller.name).subscribe((res: PropertyDefinition[]) => {
        this.listValuesPropertyDefinitions.push(...res);
        let actionParameters = this.apiService.getActionParameters("/api/alhambra", controller.name, "Datas", "post");
        Object.keys(actionParameters.requestBody.properties).forEach((parameterKey: string) => {
          let parameter = actionParameters.requestBody.properties[parameterKey];
          // if (parameterKey.toLowerCase() !== "decryptkey") { // We exclude the parameter "DecryptKey" which is no more needed
          let paramType = this.apiService.getCSTypeFromJSONType(parameter);
          this.config["DataSource"]["Parameters"].push({
            ParameterName: parameterKey,
            ParameterLabel: parameterKey,
            ParameterType: paramType,
            ParameterBinding: this.getDefaultParameterBindings(parameterKey)
          });
          //}
        });
      });
    }
  }

  ngOnInit(): void {
    if (!this.config["DataSource"]) {
      this.config["DataSource"] = { ProcedureName: "", Parameters: {} };
    }

    this.apiControllerList = this.apiService.getAvailableControllers("/api/alhambra");
    this.apiControllerList.forEach(c => {
      this.labeledControllerList.push({ name: c.name, label: this.libTranslatePipe.transform(c.name) });
    });

    if (this.config["DataSource"]["ProcedureName"] && this.config["DataSource"]["ProcedureName"] !== "") {
      this.libProcedureInputSvc.getProcedureParametersListValues(this.config["DataSource"]["ProcedureName"]).subscribe((res: PropertyDefinition[]) => {
        this.listValuesPropertyDefinitions.push(...res);
      });
    }
  }

  ngAfterViewInit() {
  }

  // This method offers a default binding based on the parameter name
  getDefaultParameterBindings(parameterName: string): any {
    const bindingDefinition: { [key: string]: any } = {};

    bindingDefinition["Type"] = "raw";
    bindingDefinition["Value"] = "";
    bindingDefinition["Nullable"] = false;
    switch (parameterName.toLowerCase()) {
      case "importdate":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.ImportDate]";
        break;
      case "analysisdate":
        bindingDefinition["Type"] = "cardFilter";
        bindingDefinition["Value"] = {
          InitDate: "today",
          Operator: "plus",
          Operand: 0,
          Multiplier: "days"
        };
        break;
      case "user":
        bindingDefinition["Value"] = "alhambra";
        break;
      case "idownerlist":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.OwnerList]";
        break;
      case "buildingnaturecodelist":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.BuildingNatureList]";
        break;
      case "geographicalzoneslist":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.GeographicalZoneList]";
        break;
      case "neutralizationtype":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.NeutralizationType]";
        break;
      case "portfoliocode":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.PortfolioCode]";
        break;
      case "languagecode":
        bindingDefinition["Value"] = "fr-FR";
        break;
      case "idbuildinglist":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.BuildingsList]";
        break;
      case "targetareaunitcode":
        bindingDefinition["Value"] = "SQM";
        break;
      case "targetcurrency":
        bindingDefinition["Value"] = "EUR";
        break;
      case "currencyratedate":
        bindingDefinition["Type"] = "pageFilter";
        bindingDefinition["Value"] = "[Filter.ImportDate]";
        break;
    }

    return bindingDefinition;
  }
}
