<form class="form-group mt-4" [formGroup]="myForm">
  <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" class="mt-2">
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <input
          class="form-check-input mx-3"
          type="radio"
          id="RadioCsv"
          formControlName="flexRadio"
          value="csv"
        />
        <span class="text-truncate"
          ><fa-icon icon="file-csv" class="nav-icon"></fa-icon
          >&nbsp;<lib-translate [key]="'lbl-csv-file'"></lib-translate
        ></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="form-group">
          <input
            type="checkbox"
            id="quoteAllFields2"
            class="form-check-input"
            formControlName="quoteAllFields"
          />&nbsp;
          <label for="quoteAllFields2"
            ><lib-translate [key]="'lbl-quote-all-fields'"></lib-translate
          ></label>
        </div>
        <div class="form-group">
          <label for="csv-delimiter2"
            ><lib-translate [key]="'lbl-csv-delimiter-char'"></lib-translate
          ></label>
          <input
            type="text"
            id="csv-delimiter2"
            class="form-control"
            formControlName="csvDelimiter"
          />
        </div>
        <div class="form-group">
          <label for="csv-export-name"
            ><lib-translate [key]="'lbl-csv-export-name'"></lib-translate
          ></label>
          <input
            type="text"
            id="csv-export-name"
            class="form-control"
            formControlName="csvExportName"
          />
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <input
          class="form-check-input mx-3"
          type="radio"
          id="radioXlsx"
          formControlName="flexRadio"
          value="xlsx"
        />
        <span class="text-truncate"
          ><fa-icon icon="file-excel" class="nav-icon"></fa-icon
          >&nbsp;<lib-translate [key]="'lbl-xlsx-file'"></lib-translate
        ></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="form-group">
          <label for="xlsx-export-name2"
            ><lib-translate [key]="'lbl-csv-export-name'"></lib-translate
          ></label>
          <input
            type="text"
            id="xlsx-export-name2"
            class="form-control"
            formControlName="xlsxExportName"
          />
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <div class="d-flex mt-3">
    <input formControlName="checkboxFile" class="form-check-input" type="checkbox" name="fileSystemRadioInput">
    <label class="mx-2"><lib-translate [key]="'lbl-file-system'"></lib-translate></label>
  </div>
  <div class="form-inline mt-3">
    <input formControlName="checkboxEmail" class="form-check-input" type="checkbox" name="emailRadioInput">
    <label for="user" class="m-2"><lib-translate [key]="'lbl-email'"></lib-translate></label>
    <input type="email" formControlName="email" class="form-control w-25" name="emailRadioInput" [attr.disabled]="f['checkboxEmail'].value == true ? null : 'disabled'">
    <button (click)="addEmail()" [disabled]="myForm.invalid" class="btn btn-primary w-25 ml-2"><lib-translate [key]="'lbl-add-email'"></lib-translate></button>
  </div>
  <ul class="list-group list-group-horizontal mt-3">
    <li class="list-group-item" *ngFor="let email of listEmail">{{email}} <button class="btn" (click)="deleteEmail(email)"><fa-icon icon="xmark"></fa-icon></button></li>
  </ul>
</form>
<div class="d-flex mt-3">
  <lib-translate [key]="'lbl-choose-language-code'"></lib-translate>
  <div class="ml-2">
    <ng-select [clearable]="false" [searchable]="false" class="langSelector" [ngModel]="selectedLocal" [items]="['en-GB', 'fr-FR', 'de-DE']">
      <ng-template ng-label-tmp let-item="item">
        <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
      </ng-template>
    </ng-select>
  </div>
</div>
