<div class="container d-flex justify-content-between align-items-center">
  <div>
    <label><lib-translate [key]="'lib-card-report-columns-config'"></lib-translate></label>
  </div>
  <div class="d-flex">
    <button class="btn btn-primary" (click)="copyWebReportConfiguration()"><fa-icon icon="copy"></fa-icon>&nbsp;<lib-translate key='lbl-copy-from-webreport'></lib-translate></button>
  </div>
</div>

<ng-select [(ngModel)]="selectedCols" [hideSelected]="true" [items]="availableCols" bindLabel="label" [multiple]="true" [closeOnSelect]="false" (change)="onSelectedColumnsChange($event)" groupBy="csType">
</ng-select>
<div class="multiSelectButtons">
  <button (click)="selectAllColumns()" class="btn btn-sm btn-primary"><lib-translate [key]="'select-all'"></lib-translate></button>
  <button (click)="unselectAllColumns()" class="btn btn-sm btn-secondary"><lib-translate [key]="'select-none'"></lib-translate></button>
</div>

<ul class="list-group sortable mt-3" dragula="HANDLES" [(dragulaModel)]="selectedCols" style="max-height: 50vw; overflow-y:scroll;">
  <li *ngFor="let item of selectedCols; let index = index" class='list-group-item container'>
    <div class="d-flex justify-content-between align-items-center w-100">
      <div>
        <span class="handle"><fa-icon icon="grip-lines"></fa-icon></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="checkbox" class="form-check-input" [(ngModel)]="item.visible" />&nbsp;&nbsp;
        <lib-translate [key]="item.name"></lib-translate>
      </div>
      <div style="display: flex; align-items: center;">
        <div *ngIf="isNumber(item.type)" style="display: flex; align-items: center;">
            <label><lib-translate key="decimal-places"></lib-translate></label>:&nbsp;
            <input type="number" [(ngModel)]="item.nbDecimal" class="form-control" style="max-width:4rem;">
        </div>
        &nbsp;
        <div *ngIf="isNumber(item.type)">
          <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="btnNature_{{item.name}}" id="btnNature_{{item.name}}_percent"  autocomplete="off" value="percent" [checked]="item.nature === 'percent'" [(ngModel)]="item.nature">
            <label class="btn btn-outline-primary" for="btnNature_{{item.name}}_percent"><fa-icon icon="percentage"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnNature_{{item.name}}" id="btnNature_{{item.name}}_currency" autocomplete="off" value="currency" [checked]="item.nature === 'currency'" [(ngModel)]="item.nature">
            <label class="btn btn-outline-primary" for="btnNature_{{item.name}}_currency"><fa-icon icon="euro-sign"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnNature_{{item.name}}" id="btnNature_{{item.name}}_normal" autocomplete="off" value="normal" [checked]="item.nature === 'normal'" [(ngModel)]="item.nature">
            <label class="btn btn-outline-primary" for="btnNature_{{item.name}}_normal"><fa-icon icon="ban"></fa-icon></label>
          </div>
        </div>
        &nbsp;
        <div class="btn-group btn-group-sm btn-group-toggle" data-toggle="buttons">
          <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="btnAlign_{{item.name}}" id="btnAlign_{{item.name}}_left"  autocomplete="off" value="left" [checked]="item.horizontalAlign === 'left'" [(ngModel)]="item.horizontalAlign">
            <label class="btn btn-outline-primary" for="btnAlign_{{item.name}}_left"><fa-icon icon="align-left"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnAlign_{{item.name}}" id="btnAlign_{{item.name}}_center" autocomplete="off" value="center" [checked]="item.horizontalAlign === 'center'" [(ngModel)]="item.horizontalAlign">
            <label class="btn btn-outline-primary" for="btnAlign_{{item.name}}_center"><fa-icon icon="align-center"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnAlign_{{item.name}}" id="btnAlign_{{item.name}}_right" autocomplete="off" value="right" [checked]="item.horizontalAlign === 'right'" [(ngModel)]="item.horizontalAlign">
            <label class="btn btn-outline-primary" for="btnAlign_{{item.name}}_right"><fa-icon icon="align-right"></fa-icon></label>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
