import { Injectable } from '@angular/core';
import { ServerSideRequest, ServerSideResponse } from '../lib-herdia-datatable/lib-herdia-datatable.interfaces';
import { LibHttpClientApiService } from '../lib-http-client-api/lib-http-client-api.service';
import { FileDeposit } from './lib-file-deposit.enum';
import { GetDatatableSpecificInfosRequest, GetSpecificInfosResponse, IFileDeposit } from './lib-file-deposit.interfaces';

@Injectable({
    providedIn: 'root'
})
export class LibFileDepositService {

    constructor(private apiSvc: LibHttpClientApiService) { }
    GetAllFileDeposit(param: ServerSideRequest) {
        return this.apiSvc.post<ServerSideRequest, ServerSideResponse>(FileDeposit.GET_ALL_FILE_DEPOSIT, param);
    }

    EditFileDeposit(request: IFileDeposit) {
        return this.apiSvc.post<IFileDeposit, any>(FileDeposit.EDIT_FILE_DEPOSIT, request);
    }

    RemoveFileDeposit(FileDepositId: number) {
        let url = FileDeposit.REMOVE_FILE_DEPOSIT + `${FileDepositId}`;
        return this.apiSvc.get(url);
    }

    AddFileDeposit(request: IFileDeposit) {
        return this.apiSvc.post<IFileDeposit, any>(FileDeposit.ADD_FILE_DEPSOIT, request);
    }

    FillFileInformationByType(fileDepositType: number) {
        let url = FileDeposit.FILL_FILE_INFORMATION_BY_TYPE + `${fileDepositType}`;
        return this.apiSvc.get(url);
    }

    GetDatatableSpecificInformationByType(requestParam: GetDatatableSpecificInfosRequest) {
        let url = FileDeposit.GET_DATATABLE_SPECIFIQUE_INFO_BY_TYPE;
        return this.apiSvc.post<GetDatatableSpecificInfosRequest, ServerSideResponse>(url, requestParam);
    }

    GetDocumentViewerByType(idTable: number, type: number) {
        let url = FileDeposit.GET_DOCUMENT_VIEWER_BY_TYPE + `${idTable}` + '/' + `${type}`;
        return this.apiSvc.get(url);
    }

    GetSpecificInformationByType(varaiblesFilter: { key: string, value: string }[], type: number) {
        let url = FileDeposit.GET_SPECIFIC_INFO_BY_TYPE + `${type}`;
        return this.apiSvc.post<{ key: string, value: string }[], GetSpecificInfosResponse[]>(url, varaiblesFilter);
    }

    GetAllFileDepositActive() {
        return this.apiSvc.get(FileDeposit.GET_ALL_FILE_DEPOSIT_ACTIVE);
    }
}
