import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IDDatatableEventType } from 'src/app/core/shared/models/interfaces';

@Component({
    selector: 'app-datatable-app-mail-row-action',
    templateUrl: './datatable-app-mail-row-action.component.html',
    styleUrls: ['./datatable-app-mail-row-action.component.scss']
})
export class DatatableAppMailRowActionComponent implements OnInit, OnDestroy {

    constructor() { }

    @Output()
    emitter = new Subject<IDDatatableEventType>();

    @Input()
    data = {};

    ngOnInit(): void {
    }

    onEdit(): void {
        this.emitter.next({
            cmd: "edit",
            data: this.data,
        });
    }

    sendTestMail(): void {
        this.emitter.next({
            cmd: "sendTestMail",
            data: this.data,
        });
    }

    ngOnDestroy() {
        this.emitter.unsubscribe();
    }

}
