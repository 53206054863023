import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { LibHerdiaAppEnvironmentService } from "@herdia-common";

export enum Chart {
  POST_CHART_CONF = 'api/ChartCard/GetChartByPost',
  GET_LIST_CHART_PROC = 'api/DataServices/GetDataServicesCharts'
}

@Injectable({
  providedIn: 'root'
})
export class LibCardChartService {

  availableChartTypes = [
    { key: 'pie', value: this.translatePipe.transform('lib-card-chart-type-pie') },
    { key: 'bar', value: this.translatePipe.transform('lib-card-chart-type-bar') },
  ];

  availablePalettes = [
    {
      value: "green",
      label: this.translatePipe.transform("palette-green"),
      palette: ['#82DACC', '#1B998B', '#AAFCB8', '#8CD790', '#C8FFBE', '#E1F0C4', '#7DAF9C', '#A9FFCB', '#B6EEA6', '#8FC0A9', '#8DDDD0', '#6CBDB4', '#B1FCBE', '#96DA9A', '#CDFFC3', '#7FB7B4', '#44ABA0', '#B9FCC4', '#A0DEA4', '#D2FFC9', '#77C7BA', '#198C7F', '#9BE6A8', '#80C483', '#B6E8AD', '#E3F1C9', '#88B6A5', '#B0FFCF', '#99C5B0', '#E6F2CE', '#94BDAE', '#B8FFD4', '#C3F1B6', '#A3CBB8', '#CDDBB3', '#72A08E', '#9AE8B9', '#A6D997', '#82AF9A', '#12381B', '#130E0E', '#315C8A', '#0B0C0E', '#183220', '#169E70', '#1FCC10', '#BFFF00', '#034732', '#008148', '#7ABE42', '#245906', '#0A2A04', '#DADCB4', '#1C4651', '#1F663F', '#497C3E', '#9ECC8C', '#79AF94', '#018E42', '#00D6BA', '#1CD600', '#0DAB76', '#0B5D1E', '#1C9613', '#053B06', '#56FFDA', '#23FFA7', '#46F2C1', '#23961F', '#26BF00', '#6AB344', '#D8F793', '#A0CA92', '#75B09C', '#7AB961', '#47916D', '#1C939A', '#52B4B7', '#289B98', '#1A6659', '#0D4B40', '#C9E9A8', '#9DD861', '#488728', '#2F6F0E', '#00826E', '#00B7A2', '#009B72', '#002E2C']
    }, //green
    {
      value: "blue",
      label: this.translatePipe.transform("palette-blue"),
      palette: ['#93BADA', '#E2F7F6', '#B9F0E9', '#1C6E8C', '#274156', '#8DA7BE', '#8AF3FF', '#E1EFF6', '#A4B8C4', '#A9BCD0', '#58A4B0', '#E0F2E9', '#ECF8F8']
    }, //blue
    {
      value: "purple",
      label: this.translatePipe.transform("palette-purple"),
      palette: ['#A0ABED', '#E3E2F7', '#81717A', '#9D8CA1', '#BCABAE', '#ECB0E1', '#8F3985', '#EDE3E4', '#A93F55', '#C97B84']
    }, //purple
    {
      value: "red",
      label: this.translatePipe.transform("palette-red"),
      palette: ['#E26D5A', '#FF9C8B', '#F9E1D5', '#EFA8B8', '#BB4430', '#FF6B6B', '#DE6C83', '#A30B37', '#BD6B73', '#F7F0F5', '#F2545B', '#9C0D38']
    }, //red
    {
      value: "redDate",
      label: this.translatePipe.transform("palette-redDate"),
      palette: ['#D3887B', '#C76655', '#C15542', '#AA3E2C']
    }, //redDate,
    {
      value: "redOccupancyDate4Pie1",
      label: this.translatePipe.transform("palette-redOccupancyDate4Pie1"),
      palette: ['#FFB7AA', '#FFAEA0', '#FFA595', '#FF9C8B']
    }, //redOccupancyDate4Pie1
    {
      value: "yellow",
      label: this.translatePipe.transform("palette-yellow"),
      palette: ['#FBDD73', '#F3DFA2', '#F2F6D0', '#FFF7AE', '#FFEDE1', '#EDFFAB']
    }, //yellow
    {
      value: "grey",
      label: this.translatePipe.transform("palette-grey"),
      palette: ['#CFCDCD', '#87919E']
    }, //grey
    {
      value: "brown",
      label: this.translatePipe.transform("palette-brown"),
      palette: ['#D7BBA8', '#E4BE9E', '#A18276', '#E4BE9E', '#ECC8AF', '#A85751', '#CEB5A7', '#CA907E']
    }, //brown
    {
      value: "all",
      label: this.translatePipe.transform("palette-all"),
      palette: ['#82DACC', '#1B998B', '#AAFCB8', '#8CD790', '#C8FFBE', '#E1F0C4', '#7DAF9C', '#A9FFCB', '#B6EEA6', '#8FC0A9', '#8DDDD0', '#6CBDB4', '#B1FCBE', '#96DA9A', '#CDFFC3', '#7FB7B4', '#44ABA0', '#B9FCC4', '#A0DEA4', '#D2FFC9', '#77C7BA', '#198C7F', '#9BE6A8', '#80C483', '#B6E8AD', '#E3F1C9', '#88B6A5', '#B0FFCF', '#99C5B0', '#E6F2CE', '#94BDAE', '#B8FFD4', '#C3F1B6', '#A3CBB8', '#CDDBB3', '#72A08E', '#9AE8B9', '#A6D997', '#82AF9A', '#12381B', '#130E0E', '#315C8A', '#0B0C0E', '#183220', '#169E70', '#1FCC10', '#BFFF00', '#034732', '#008148', '#7ABE42', '#245906', '#0A2A04', '#DADCB4', '#1C4651', '#1F663F', '#497C3E', '#9ECC8C', '#79AF94', '#018E42', '#00D6BA', '#1CD600', '#0DAB76', '#0B5D1E', '#1C9613', '#053B06', '#56FFDA', '#23FFA7', '#46F2C1', '#23961F', '#26BF00', '#6AB344', '#D8F793', '#A0CA92', '#75B09C', '#7AB961', '#47916D', '#1C939A', '#52B4B7', '#289B98', '#1A6659', '#0D4B40', '#C9E9A8', '#9DD861', '#488728', '#2F6F0E', '#00826E', '#00B7A2', '#009B72', '#002E2C', '#93BADA', '#E2F7F6', '#B9F0E9', '#1C6E8C', '#274156', '#8DA7BE', '#8AF3FF', '#E1EFF6', '#A4B8C4', '#A9BCD0', '#58A4B0', '#E0F2E9', '#ECF8F8', '#A0ABED', '#E3E2F7', '#81717A', '#9D8CA1', '#BCABAE', '#ECB0E1', '#8F3985', '#EDE3E4', '#A93F55', '#C97B84', '#FF9C8B', '#F9E1D5', '#E26D5A', '#EFA8B8', '#BB4430', '#FF6B6B', '#DE6C83', '#A30B37', '#BD6B73', '#F7F0F5', '#F2545B', '#9C0D38', '#FBDD73', '#F3DFA2', '#F2F6D0', '#FFF7AE', '#FFEDE1', '#EDFFAB', '#CFCDCD', '#87919E', '#D7BBA8', '#E4BE9E', '#A18276', '#E4BE9E', '#ECC8AF', '#A85751', '#CEB5A7', '#CA907E']
    }, //all
    {
      value: "default",
      label: this.translatePipe.transform("palette-default"),
      palette: ['#82DACC', '#FF9C8B', '#93BADA', '#FBDD73', '#A0ABED', '#CFCDCD', '#E2F7F6', '#F9E1D5', '#E3E2F7', '#B9F0E9']
    }, //default
    {
      value: "collectMonitBreakdown",
      label: this.translatePipe.transform("palette-collectMonitBreakdown"),
      palette: ['#FF9C8B', '#F9E1D5', '#FBDD73', '#93BADA', '#E2F7F6', '#82DACC', '#B9F0E9']
    }, //collectMonitBreakdown
    {
      value: "waterFall1",
      label: this.translatePipe.transform("palette-waterFall1"),
      palette: ['#000000', '#FF9C8B', '#82DACC', '#CFCDCD']
    }, //waterFall1
    {
      value: "defaultGauge",
      label: this.translatePipe.transform("palette-defaultGauge"),
      palette: ['rgba(0, 177, 106, .8)', 'rgba(46, 204, 113, .8)', 'rgba(123, 239, 178, .8)', 'rgba(254, 241, 96, .8)', 'rgba(252, 185, 65, .8)', 'rgba(242, 38, 19, .8)', 'rgba(255, 255, 255, 0)']
    }
  ];

  constructor(private http: HttpClient, private translatePipe: TranslatePipe, private environmentService: LibHerdiaAppEnvironmentService) { }

  post<TRequestData, TResult>(url: string, data: TRequestData) {
    let observable;
    if (data)
      observable = this.http.post(url, data);
    else {
      console.error('payload data is null');
      return null;
    }

    return observable.pipe(
      map(data => {
        return data as TResult;
      }));
  }

  generateChartConfig(data: any[], config: any) {
    if (!data || data.length < 0) {
      console.log('La données d\'entrée du chart est vide');
      return config;
    }

    const chartConfig = config['ChartConf'] as {
      ChartType: string,
      ChartDataSource: { Legend: string, DataSourceType: string, Label: string, DataSource: string, }[]
    };

    if (chartConfig.ChartDataSource.length === 0) {
      console.error('Aucune source de données n\'est sélectionnée');
      return null;
    }

    if (chartConfig.ChartType === 'lib-card-chart-type-pie') {
      if (chartConfig.ChartDataSource[0].DataSourceType === 'Colonne') {
        const labelColumn = chartConfig.ChartDataSource[0].Label;
        if (!labelColumn) {
          console.error(`La colonne de libellée n'existe pas pour la procédure '${config['DataSource']['ProcedureName']}'`);
          return null;
        }

        let calculatedConfig: {
          type: string,
          data: {
            datasets: { data: number[], backgroundColor: string[], label: string }[],
            labels: string[]
          }
        } = {
          type: 'pie',
          data: {
            datasets: [],
            labels: (data as any[]).map(d => d[labelColumn])
          }
        };

        const bkgColors = this.getRandomColors(calculatedConfig.data.labels.length);

        for (let ds of chartConfig.ChartDataSource) {
          const dataSet: { data: number[], backgroundColor: string[], label: string } = {
            label: ds.Legend,
            data: (data as any[]).map(d => d[ds.DataSource]),
            backgroundColor: bkgColors
          }
          calculatedConfig.data.datasets.push(dataSet);
        }

        config['ChartConfig'] = calculatedConfig;
        return config;
      } else {
        console.error('La sélection de la source de données par ligne n\'est pas encore implémentée');
      }
    } else {
      console.error(`Le type de charte '${chartConfig.ChartType}' n'est pas traité`);
      return null;
    }
  }

  generatePieChart(data: string[], label: string): any {
    const disctinctLabels = [...new Set(data)];
    const labelsValues = [];
    for (let label of disctinctLabels) {
      const count = data.filter(l => l === label).length;
      labelsValues.push(count);
    }
    let calculatedConfig: {
      type: string,
      data: {
        datasets: { data: number[], backgroundColor: string[], label: string }[],
        labels: string[]
      }
    } = {
      type: 'pie',
      data: {
        datasets: [{
          data: labelsValues,
          backgroundColor: this.getRandomColors(disctinctLabels.length),
          label: label
        }],
        labels: disctinctLabels
      }
    };
    return calculatedConfig;
  }

  getRandomColors(count: number): string[] {
    const colorList = [
      'rgb(205, 92, 92)', 'rgb(240, 128, 128)', 'rgb(250, 128, 114)', 'rgb(233, 150, 122)', 'rgb(255, 160, 122)', 'rgb(220, 20, 60)', 'rgb(255, 0, 0)', 'rgb(139, 0, 0)', 'rgb(255, 192, 203)', 'rgb(255, 182, 193)', 'rgb(255, 105, 180)', 'rgb(255, 20, 147)', 'rgb(199, 21, 133)', 'rgb(219, 112, 147)', 'rgb(255, 127, 80)', 'rgb(255, 99, 71', 'rgb(255, 69, 0)', 'rgb(255, 140, 0)', 'rgb(255, 165, 0)', 'rgb(255, 215, 0)', 'rgb(255, 255, 0)', 'rgb(255, 255, 224)', 'rgb(255, 250, 205)', 'rgb(250, 250, 210)', 'rgb(255, 239, 213)', 'rgb(255, 228, 181)', 'rgb(255, 218, 185)', 'rgb(238, 232, 170)', 'rgb(240, 230, 140)', 'rgb(189, 183, 107)', 'rgb(230, 230, 250)', 'rgb(216, 191, 216)', 'rgb(221, 160, 221)', 'rgb(238, 130, 238)', 'rgb(218, 112, 214)', 'rgb(255, 0, 255)', 'rgb(255, 0, 255)', 'rgb(186, 85, 211)'
    ];
    if (colorList.length < count) {
      console.error('Le nombre de couleurs demandées dépasse le nombre de couleurs existantes');
      return [];
    }
    const selectedColors: string[] = [];
    for (let i = 0; i < count; i++) {
      selectedColors.push(colorList[Math.floor(Math.random() * colorList.length)]);
    }
    return selectedColors;
  }

  getChartUrl() {
    return this.environmentService.apiBaseUrl;
  }
}
