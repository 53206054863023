import { DatePipe } from "@angular/common";
import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, catchError, of } from "rxjs";
import { ColumnSetting } from '../../lib-column-selector/lib-column-selector.interface';
import { GetSpecificInfosResponse, configFileDeposit } from '../../lib-file-deposit/lib-file-deposit.interfaces';
import { LibFileDepositService } from '../../lib-file-deposit/lib-file-deposit.service';
import { LibHerdiaAppTranslateService } from '../../lib-herdia-app-translate/lib-herdia-app-translate.service';
import { LibUserService } from '../../user/lib-user.service';
import { HerdiaDatatableGlobalConfiguration } from "../lib-herdia-datatable.interfaces";

@Component({
    selector: 'lib-herdia-datatable-cell-render',
    templateUrl: './lib-herdia-datatable-cell-render.component.html',
    styleUrls: ['./lib-herdia-datatable-cell-render.component.scss']
})
export class LibHerdiaDatatableCellRenderComponent implements OnInit {
    @Input() data: { [key: string]: any } = {};
    @Input() columnSetting!: ColumnSetting;
    @Input() locale = "fr";
    @Input() datatableConfig: HerdiaDatatableGlobalConfiguration = {
        compactMode: false,
        cellBorder: true,
        hover: true,
        orderColumn: true,
        rowBorder: false,
        stripe: true,
        nowrap: false,
        defaultRowNumberOnPage: 10,
        autoWidth: false,
        fixedLeftColumns: 0
    };
    @Input() fileDepositConfig!: configFileDeposit;
    @Input() IndexRow!: any;
    @Output() searchEmitter = new Subject<any>();
    display = "";
    isFilter: boolean = false;
    filterValue: string = "";
    searchFieldValue: string = "";
    displaySearch: string = "";
    UsefileDeposit!: boolean;

    @ViewChild('cellFileDeposit') cellFileDepos!: ElementRef;
    @ViewChild('cellFileDepositLabel') cellFileDepositLabel!: ElementRef;
    @ViewChild('cellFileDepositIcon') cellFileDepositIcon!: ElementRef;

    constructor(private datePipe: DatePipe, private userService: LibUserService, private fileService: LibFileDepositService, private translateService: LibHerdiaAppTranslateService) { }

    ngOnInit(): void {
        //use a variable used to know if we should use the file repository in the cell
        if (this.fileDepositConfig !== undefined && this.fileDepositConfig.columnLink.name == this.columnSetting.name) {
            this.UsefileDeposit = true;
        } else {
            this.UsefileDeposit = false;
        }

        if (this.data[this.columnSetting.name]) {
            if (this.data[this.columnSetting.name].toString().indexOf("[FILTER") !== 0) {
                let localDisplay = this.formatServerType(this.data[this.columnSetting.name]);
                localDisplay = this.formatServerNature(localDisplay);
                this.display = localDisplay;
                this.isFilter = false;
            }
            else {
                this.isFilter = true;
                this.searchFieldValue = this.data[this.columnSetting.name].replace("[FILTER:", "");
                this.searchFieldValue = this.searchFieldValue.replace("]", "");
            }
        }


        if (this.UsefileDeposit) {
            //creer une balise a dans la div  id="cell-file-deposit" avec le lien qu'on vas recuperer depuis le back
            // creer l'objet variable filter pour faire l'appel pour recuperer l'id du fichier pour ensuite faire un autre appel pour recuperer le lien grace a l'id
            let variableFilter: { key: string, value: string }[];
            variableFilter = this.fileDepositConfig.paramsFileDeposit.map((param) => {
                let val = this.data[param.column.name];
                return { key: param.paramName, value: val };
            });
            const hasInvalidValue = variableFilter.some((obj) => obj.value === "" || obj.value === "[FILTER:]");

            if (!hasInvalidValue) {
                this.fileService.GetSpecificInformationByType(variableFilter, this.fileDepositConfig.fileDepositType.Type).pipe(
                    catchError((error: any) => {
                        this.cellFileDepositIcon.nativeElement.style.display = 'block';
                        this.cellFileDepositLabel.nativeElement.title = error.error.title;
                        return of();
                    }
                    )
                ).subscribe(
                    (res: GetSpecificInfosResponse[]) => {
                        let idFileFromFileDEposit = res[0].IdHAFilesFromFileDeposit;
                        if (idFileFromFileDEposit != 0) {
                            this.fileService.GetDocumentViewerByType(idFileFromFileDEposit, this.fileDepositConfig.fileDepositType.Type).subscribe((resLink: any) => {
                                this.cellFileDepos.nativeElement.removeChild(this.cellFileDepositLabel.nativeElement);
                                let linkHtml = document.createElement('a');
                                linkHtml.href = resLink.message;
                                linkHtml.textContent = this.display;
                                linkHtml.target = '_blank';
                                this.cellFileDepos.nativeElement.appendChild(linkHtml);
                                this.cellFileDepos.nativeElement.style.display = "flex";
                                this.cellFileDepos.nativeElement.style.justifyContent = "center";
                            });
                        } else {
                            this.cellFileDepositIcon.nativeElement.style.display = 'block';
                            this.cellFileDepositLabel.nativeElement.title = this.translateService.get('file-not-find-in-file-deposit');
                        }
                    });
            }
        }
    }


    ngOnDestroy() {
        this.searchEmitter.unsubscribe();
    }

    onSearch(searchItem: { column: string, searchValue: string }): void {
        searchItem.column = this.columnSetting.name;
        this.searchEmitter.next(searchItem);
    }

    formatServerType(data: any): any {
        if (data === "" || data === null)
            return "";
        if (data === "&nbsp;")
            return "&nbsp;";
        switch (this.columnSetting.type) {
            case "DateTime?":
            case "DateTime":
            case "Datetime?":
            case "Datetime":
            case "Date?":
            case "Date":
                return this.datePipe.transform(data, this.userService.getValueFromAKey(this.userService.user!.DateFormat, this.userService.AllDateFormatForDatePipe));
            case "short?":
            case "short":
            case "decimal?":
            case "Decimal":
            case "decimal":
            case "Decimal?":
            case "Double?":
            case "Double":
            case "double":
            case "double?":
                if (isNaN(data))
                    return "";
                if (typeof data === "string") {
                    data = data.replace(",", ".");
                    data = parseFloat(data);
                }
                if (this.columnSetting.nature === "percent")
                    data = data * 100;
                return this.round(data, this.columnSetting.nbDecimal).toLocaleString(this.locale, { minimumFractionDigits: this.columnSetting.nbDecimal });
            case "Byte?":
            case "Int16":
            case "Int16?":
            case "Int32":
            case "Int32?":
            case "int":
            case "int32":
            case "int?":
            case "char":
            case "String":
            case "string?":
            case "string":
            case "String?":
                return data;
            default:
                // console.log("Type non géré: " + this.columnSetting.type + ", il sera affiché avec un format par défaut");
                return data;
        }
    }

    formatServerNature(data: any): any {
        if (data === "" || data === null)
            return "";
        if (data === "&nbsp;")
            return "&nbsp;";
        switch (this.columnSetting.nature) {
            case "percent":
                return data.concat(" %");
            case "currency":
                return data.concat(" " + this.userService.getValueFromAKey("EUR", this.userService.AllCurrency)); /* (this.userService.user!.Currency, this.userService.AllCurrency)) */
            default:
                return data;
        }
    }

    round(value: number, precision: number): number {
        if (precision < 0) {
            let factor = Math.pow(10, precision);
            return Math.round(value * factor) / factor;
        }
        else
            return +(Math.round(Number(value + "e+" + precision)) + "e-" + precision);
    }
}
