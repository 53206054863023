<div class="form-group">
  <label>Contexte des entités</label>
  <div class="input-group">
    <ng-select [items]="entityContextList" [(ngModel)]="selectedEntityContext" (change)="onContextChange($event)" [ngStyle]="{'min-width': '100%'}"></ng-select>
  </div>
</div>

<div class="col-md-12">
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="option" id="DbSet" (change)="onRadioChange()" [(ngModel)]="selectedDbSetOrSQL" value="DbSet">
    <label class="form-check-label" for="DbSet">
      Entité
    </label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="option" id="SQL" (change)="onRadioChange()" [(ngModel)]="selectedDbSetOrSQL" value="SQL">
    <label class="form-check-label" for="SQL">
      SQL
    </label>
  </div>
</div>

<div class="form-group" *ngIf="selectedDbSetOrSQL == 'DbSet'">
  <label> Entités </label>
  <div class="input-group">
    <ng-select [items]="entityList" [(ngModel)]="selectedEntity" bindLabel="Name" (change)="onEntityChange($event)" [ngStyle]="{'min-width': '100%'}"></ng-select>
  </div>
</div>
<div *ngIf="selectedDbSetOrSQL == 'SQL'" class="col-md-12">
  <div class="form-group">
    <label for="SQLQueryTextArea"> Requête SQL </label>
    <textarea spellcheck="false" class="form-control" id="SQLQueryTextArea" [(ngModel)]="SQLQuery" (input)="onSQLQueryChange($event)" rows="7"></textarea>
  </div>
  <button class="btn-sm btn-primary" (click)="testQueryAndUpdateModels();">Test Query and retrieve models</button>
</div>
