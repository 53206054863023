import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common';
import { HaPageCard, HaPageRowVM, HaPageVM } from "../shared/models/interfaces";
import { CardUrls, RowUrls } from '../shared/service/api.service';
import { AppService } from '../shared/service/app.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rows',
  templateUrl: './rows.component.html',
  styleUrls: ['./rows.component.scss']
})
export class RowsComponent implements OnInit {

  editMode: boolean = false;
  availableWidth: number = 0;

  @Input() haPageVM!: HaPageVM;

  _row!: HaPageRowVM;
  get row(): HaPageRowVM {
      return this._row;
  }
  @Input() set row(value: HaPageRowVM) {
      this._row = value;
      this.getCards();
  };

  modelRef: any;

  @Output() rowChange: EventEmitter<HaPageRowVM> = new EventEmitter<HaPageRowVM>();

  constructor(
    private apiSvc: LibHttpClientApiService,
    private appSvc: AppService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.appSvc.editModeSub.subscribe(mode => {
      this.editMode = mode;
    });
  }

  getCards(): void {
    this.apiSvc.get<HaPageCard[]>(CardUrls.GET_CARDS + "/" + this._row.Id).subscribe(r => {
      this._row.HAPageCards = r.sort((a, b) => a.Order - b.Order);
      let used: number = 0;
      for (var i = 0; i < this._row.HAPageCards.length; i++) {
        used += this._row.HAPageCards[i].Width;
      }
      this.availableWidth = 12 - used;
    });
  }

  removeRow(): void {
    this.apiSvc.remove<any>(RowUrls.DELETE_ROW + "/" + this._row?.Id)?.subscribe(data => {
      this.rowChange.emit(this._row);
    });
    this.modelRef.close();
  }

  moveRowToTop(): void {
    this.haPageVM.HAPageRows?.forEach((e, index) => {
      let previousRow = this.haPageVM.HAPageRows![index - 1];
      if (previousRow != undefined && e.Id == this._row.Id) {
        let currentRowOrder = this.haPageVM.HAPageRows![index].Order;
        this.haPageVM.HAPageRows![index].Order = previousRow.Order;
        this.haPageVM.HAPageRows![index - 1].Order = currentRowOrder;
      }
    })
    this.apiSvc.put<HaPageVM, HaPageRowVM[]>(RowUrls.UPDATE_ORDER_ROWS, this.haPageVM)?.subscribe(data => {
      data.forEach(e => this.rowChange.emit(e));
    });
  }

  moveRowToDown(): void{
    this.haPageVM.HAPageRows?.forEach((e, index) => {
      let nextRow = this.haPageVM.HAPageRows![index + 1];
      if (nextRow != undefined && e.Id == this._row.Id) {
        let currentRowOrder = this.haPageVM.HAPageRows![index].Order;
        this.haPageVM.HAPageRows![index].Order = nextRow.Order;
        this.haPageVM.HAPageRows![index + 1].Order = currentRowOrder;
      }
    })
    this.apiSvc.put<HaPageVM, HaPageRowVM[]>(RowUrls.UPDATE_ORDER_ROWS, this.haPageVM)?.subscribe(data => {
      data.forEach(e => this.rowChange.emit(e));
    });
  }

  open(content: any): void {
    this.modelRef = this.modalService.open(content, { size: 'md' });
  }
}
