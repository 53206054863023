import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {ColumnSetting} from "./lib-column-selector.interface";

@Component({
  selector: 'lib-column-selector',
  templateUrl: './lib-column-selector.component.html',
  styleUrls: ['./lib-column-selector.component.scss']
})
export class LibColumnSelectorComponent implements OnInit {

  @Input() availableColumns: ColumnSetting[] = [];
  @Input() selectedColumns: ColumnSetting[] = [];
  @Output() selectedColumnsChange = new EventEmitter<ColumnSetting[]>();

  availableDecimals: number[] = [1, 2, 3, 4, 5];

  numberTypes: string[] = [ "int", "Int32", "Int32?", "int?", "decimal", "decimal?", "Decimal", "Decimal?", "double", "double?", "Double", "Double?", "float", "float?" ];
  booleanTypes: string[] = ["bool", "Boolean", "boolean"];
  dateTypes: string[] = ["Datetime", "Datetime?", "Date", "Date?", "DateTime", "DateTime?"]

  constructor() { }

  ngOnInit(): void {
   
  }

  isNumber(type: string): boolean {
    return this.numberTypes.indexOf(type) !== -1;
  }

  removeColumn(indexColumn: number) {
    this.selectedColumns.splice(indexColumn, 1);
  }

  selectAllColumns(): void {
    this.selectedColumns = this.availableColumns;
    this.selectedColumnsChange.emit(this.selectedColumns);
  }

  unselectAllColumns(): void {
    this.selectedColumns = [];
    this.selectedColumnsChange.emit(this.selectedColumns);
  }

  onSelectedColumnsChange(event: any): void {
    this.selectedColumnsChange.emit(this.selectedColumns);
  }
}
