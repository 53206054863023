<div *ngIf="!isLoadingDynamicContext">
  <div class="modal-header">
    <h4 class="modal-title"><lib-translate [key]="'add-dbconnection-modal-title'"></lib-translate></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="connectionName"><lib-translate [key]="'dbconnection-name'"></lib-translate></label>
      <input type="text" class="form-control" name="connectionName" [(ngModel)]="newConnection.name">
    </div>
    <div class="form-group">
      <label for="connectionType"><lib-translate [key]="'dbconnection-connection-type'"></lib-translate></label>
      <ng-select [items]="connectionTypes"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="newConnection.connectionType">
  </ng-select>
    </div>
    <div class="form-group">
      <label for="connectionString"><lib-translate [key]="'dbconnection-connectionstring'"></lib-translate></label>
      <input type="text" class="form-control" name="connectionString" [(ngModel)]="newConnection.connectionString">
    </div>
    <div class="form-group">
      <label for="apiRoute"><lib-translate [key]="'dbconnection-api-route'"></lib-translate></label>
      <input type="text" class="form-control" name="apiRoute" [(ngModel)]="newConnection.contextApiRoute">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Fermer</button>
    <button type="button" class="btn btn-primary" (click)="onValidate()">Valider</button>
  </div>
</div>
<div *ngIf="isLoadingDynamicContext" class="d-flex justify-content-center">
  <div><fa-icon icon="spinner" [spin]="true" size="5x"></fa-icon></div>
</div>
