<div class="d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
    <li ngbNavItem="Theme1">
      <a ngbNavLink (click)="Theme1Show()">Theme1</a>
      <ng-template ngbNavContent>
        <div class="themeDiv">
          <label>Primary Color </label>
          <input class="color" type="color" [(ngModel)]="primaryColor" (change)="primaryColorChange()">
          <br>
          <label>Secondary Color </label>
          <input class="color" type="color" [(ngModel)]="secondaryColor" (change)="secondaryColorChange()">
          <br>
          <label>Left navigation bar color </label>
          <input class="color" type="color" [(ngModel)]="leftNavbarColor" (change)="navbarColorChange()">
          <br>
          <label>Top navigation bar color </label>
          <input class="color" type="color" [(ngModel)]="topNavbarColor" (change)="topNavbarColorChange()">
          <br>
          <label>Font Size </label>
          <br>
          <input type="range" name="fontsize" id="fontSize" min="1" max="1.5" step="0.1" [(ngModel)]="customFontSize" (change)="fontSizeChange()">
          <br>
          <button type="button" class="btn btn-primary" (click)="Theme1Save()"><lib-translate [key]="'app-btn-validate'"></lib-translate></button>
          <button type="button" class="btn btn-primary" (click)="SetDefaultTheme()"> Set default theme </button>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="Theme2">
      <a ngbNavLink (click)="Theme2Show()">Theme2</a>
      <ng-template ngbNavContent>
        <div class="themeDiv">
          <label>Primary Color </label>
          <input class="color" type="color" [(ngModel)]="primaryColor" (change)="primaryColorChange()">
          <br>
          <label>Secondary Color </label>
          <input class="color" type="color" [(ngModel)]="secondaryColor" (change)="secondaryColorChange()">
          <br>
          <label>Left navigation bar color </label>
          <input class="color" type="color" [(ngModel)]="leftNavbarColor" (change)="navbarColorChange()">
          <br>
          <label>Top navigation bar color </label>
          <input class="color" type="color" [(ngModel)]="topNavbarColor" (change)="topNavbarColorChange()">
          <br>
          <label>Font Size </label>
          <input type="range" name="fontsize" id="fontSize" min="1" max="1.5" step="0.1" [(ngModel)]="customFontSize" (change)="fontSizeChange()">
          <br>
          <button type="button" class="btn btn-primary" (click)="Theme2Save()"><lib-translate [key]="'app-btn-validate'"></lib-translate></button>
          <button type="button" class="btn btn-primary" (click)="SetDefaultTheme()"> Set default theme </button>
          <br>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="ms-4"></div>
</div>
