<div class="modal-header">
  <h5 class="modal-title"><fa-icon icon="file-pen"></fa-icon>&nbsp;<lib-translate [key]="'edit-input-mapping'"></lib-translate></h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">

  <div class="col-md-12"><lib-translate [key]="'lbl-source-code'"></lib-translate>&nbsp; : {{ data.SOURCE_CODE }}</div>
  <div class="col-md-12"><lib-translate [key]="'lbl-source-label'"></lib-translate>&nbsp; : {{ sourceLabel }}</div>
  <br/>
  <div class="col-md-12">
    <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-destination-codes'"></lib-translate></div></span>
    <ng-select [(ngModel)]="selectedDestinationCode">
      <ng-option *ngFor="let destinationCode of destinationCodes$ | async" [value]="destinationCode.ITEM_CODE">{{ destinationCode.ITEM_LABEL }}
      </ng-option>
    </ng-select>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()"><lib-translate [key]="'close'"></lib-translate></button>
  <button type="button" class="btn btn-primary" (click)="save()"><lib-translate [key]="'save'"></lib-translate></button>
</div>

