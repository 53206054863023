import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DynamicComponentDeclaration, DynamicComponentPackage, DynamicComponentsBaseModule, LibDatatablePaginationModule, LibDynamicComponentEventBusModule, LibHerdiaAppTranslateModule, LibHerdiaDatatableModule } from '@herdia-common';
import { NgSelectModule } from '@ng-select/ng-select';
import { LibCardInputMappingComponent } from './lib-card-input-mapping.component';
import { LibCardInputMappingFooterComponent } from './lib-card-input-mapping-footer/lib-card-input-mapping-footer.component';
import { EditInputMappingModalComponent } from './edit-input-mapping-modal/edit-input-mapping-modal.component';
import { LibCardInputMappingService } from './lib-card-input-mapping.service';

@NgModule({
  declarations: [
    LibCardInputMappingComponent,
    LibCardInputMappingFooterComponent,
    EditInputMappingModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibHerdiaAppTranslateModule,
    LibHerdiaDatatableModule,
    LibDatatablePaginationModule,
    LibDynamicComponentEventBusModule,
    NgSelectModule,
    FontAwesomeModule
  ],
  exports: [
    LibCardInputMappingComponent,
    LibCardInputMappingFooterComponent,
    EditInputMappingModalComponent
  ],
  providers: [
    LibCardInputMappingService
  ]
  })
export class LibCardInputMappingModule extends DynamicComponentsBaseModule {

  override getPackageName(): DynamicComponentPackage {
      return 'alhambra';
  }

  override getDynamicComponentsDeclarations(): DynamicComponentDeclaration[] {
      return [
          { component: LibCardInputMappingComponent, type: 'card' },
          { component: LibCardInputMappingFooterComponent, type: 'default' },
          { component: EditInputMappingModalComponent, type: 'default' },
      ]
  }

}
