import { Component, OnInit } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common';
import { TranslatePipe } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HaPage, HaPageCategory, PageManagement, Role } from '../shared/models/interfaces';
import { PageUrls } from '../shared/service/api.service';
import { AppService } from '../shared/service/app.service';
import { PageService } from '../shared/service/page.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IconName } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-page-modal',
  templateUrl: './add-page-modal.component.html',
  styleUrls: ['./add-page-modal.component.scss']
})
export class AddPageModalComponent implements OnInit {

  myFormGroup: FormGroup;
  iconCss = new FormControl();
  fallbackIcon = 'fas fa-user';
  emptyIcon: boolean = false;

  editMode = false;
  pageConfig: HaPage = { Id: 0,  Title: "", Description: "", Icon: "" as IconName };
  addPageModalPageTitle: string = "";
  //addPageModalPageDescription: string = "";
  availableCategories: HaPageCategory[] = [];
  selectedCategory: number = 0;

  ListRoles!: Role[];
  RoleSelected!: string[];

  constructor(
    private apiService: LibHttpClientApiService,
    appService: AppService,
    translatePipe: TranslatePipe,
    private pageService: PageService,
    private activeModal: NgbActiveModal
  ) {
    appService.editModeSub.subscribe(e => { this.editMode = e; });
    this.addPageModalPageTitle = translatePipe.transform("add-page-modal-page-title");
    //this.addPageModalPageDescription = translatePipe.transform("add-page-modal-page-description");

    this.myFormGroup = new FormGroup({iconCss: this.iconCss});
  }

  dismiss() {
    return this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.apiService.get<PageManagement>(PageUrls.INDEX).subscribe(r => {
      if (r) {
        this.availableCategories = r.Categories;
      }
    });

    this.pageService.getRoles().subscribe(res => {
      this.ListRoles = res;
    });

  }

  onValidate(): void {
    if (this.pageConfig.Icon.toString() != "" && !this.emptyIcon) {
      this.pageService.add({
        categoryId: this.selectedCategory,
        pageTitle: this.pageConfig.Title,
        pageDescription: "",
        pageIcon: this.pageConfig.Icon.toString(),
        idRoles: this.RoleSelected
      });
    }
    else {
      this.emptyIcon = true;
    }
  }

  onIconPickerSelect(icon: string): void {
    this.emptyIcon = false;
    this.iconCss.setValue(icon);
    this.pageConfig.Icon = icon as IconName;
  }

  onImport() {
    this.pageService.openAndInitImportConfig();
    this.pageService.closeImportConfigSubject.subscribe(() => {
      //this.cardAdded.emit();
      this.activeModal.close();
    })
  }

}
