import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { LibUserService } from '@herdia-common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormComponentBase } from '../../../shared/models/baseClasses';
import { HaPageCard } from '../../../shared/models/interfaces';
import { CardService } from '../../../shared/service/card.service';

@Component({
    selector: 'app-export-card-config',
    templateUrl: './export-card-config.component.html',
    styleUrls: ['./export-card-config.component.scss']
})
export class ExportCardConfigComponent extends FormComponentBase implements OnInit {

    templateCardTitle = "";
    configTitlePlaceHolder = '';
    card?: HaPageCard;

    @Input() set cardInput(value: HaPageCard) {
        if (!value)
            return;

        this.card = value;

        this.submitted = false;
        if (this.card.Title === undefined)
            console.error('Titre de la carte est vide');
        else {
            this.form.value.configTitle = this.card.Title;
            this.configTitlePlaceHolder = `Export de ${this.card.Title}`;
        }
    }

    form = this.fb.group<{ configTitle: FormControl<string | null> }>({
        configTitle: this.fb.control('')
    })

    constructor(
        private cardSvc: CardService,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private userService: LibUserService,
    ) {
        super();
    }

    ngOnInit(): void { }

    export() {
        this.submitted = true;

        if (!this.form.valid)
            return;

        if (this.card && this.templateCardTitle !== "") {
            const param = {
                title: this.templateCardTitle,
                cardTitle: this.card.Title,
                config: JSON.stringify(this.card.Configuration),
                cardTypeLabel: this.card.CardTypeLabel,
                packageLabel: this.card.Package,
                width: this.card.Width.toString(),
                requestUserEmail: this.userService.user!.Email
            };
            this.cardSvc.exportConfig(param)?.subscribe(res => {
                if (res)
                    this.activeModal.close();
                else
                    console.error(`Erreur lors de la sauvegarde de la configuration de la carte ${this.form.get('configTitle')?.value}`);
            });
        } else
            console.error('La carte est vide');
    }

    cancel() {
        this.activeModal.dismiss();
    }

}
