import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerSideRequest, ServerSideResponse } from '../lib-herdia-datatable/lib-herdia-datatable.interfaces';
import { LibHttpClientApiService } from '../lib-http-client-api/lib-http-client-api.service';
import { LibUserService } from '../user/lib-user.service';
import { EntityCRUDUrls } from './lib-card-generic-crud.enums';
import {
    CRUDAddOrUpdateRequest,
    CRUDCreateOrUpdateResponse,
    CRUDDeleteRequest,
    CRUDReadFromSqlRequest,
    CRUDReadRequest,
    DataFilter,
    EntityDefinition,
    EntityDefinitionSqlRequest,
    ExportRequest,
    ExportSourceType,
    ExportType
} from './lib-card-generic-crud.interface';

@Injectable({
    providedIn: 'root'
})
export class LibCardGenericCRUDService {
    constructor(private apiSvc: LibHttpClientApiService, private userService: LibUserService) { }

    getContexts(): Observable<string[]> {
        return this.apiSvc.get<string[]>(EntityCRUDUrls.GET_CONTEXTS);
    }

    getEntities(contextTypeFullName: string): Observable<EntityDefinition[]> {
        return this.apiSvc.get<EntityDefinition[]>(EntityCRUDUrls.GET_ENTITIES, { contextTypeName: contextTypeFullName });
    }

    getEntityDefinitionSqlRequest(EntityDefinitionSqlRequest: EntityDefinitionSqlRequest): Observable<any> | null {
        return this.apiSvc.post<any, any>(EntityCRUDUrls.GET_ENTITY_FROM_QUERY, EntityDefinitionSqlRequest);
    }

    getEntity(contextTypeFullName: string, entityFullname: string): Observable<EntityDefinition> {
        return this.apiSvc.get<EntityDefinition>(EntityCRUDUrls.GET_ENTITY, { contextTypeName: contextTypeFullName, entityName: entityFullname });
    }

    create(crudCreateRequest: CRUDAddOrUpdateRequest) {
        return this.apiSvc.post<CRUDAddOrUpdateRequest, CRUDCreateOrUpdateResponse>(EntityCRUDUrls.CREATE, crudCreateRequest);
    }

    read(crudReadRequest: CRUDReadRequest): Observable<ServerSideResponse> {
        return this.apiSvc.post<CRUDReadRequest, ServerSideResponse>(EntityCRUDUrls.READ, crudReadRequest);
    }

    readFromSqlQuery(crudReadFromSqlRequest: CRUDReadFromSqlRequest): Observable<ServerSideResponse> {
        return this.apiSvc.post<CRUDReadFromSqlRequest, ServerSideResponse>(EntityCRUDUrls.READ_FROM_SQL_QUERY, crudReadFromSqlRequest);
    }

    update(crudUpdateRequest: CRUDAddOrUpdateRequest) {
        return this.apiSvc.post<CRUDAddOrUpdateRequest, CRUDCreateOrUpdateResponse>(EntityCRUDUrls.UPDATE, crudUpdateRequest);
    }

    createOrUpdate(crudCreateOrUpdateRequest: CRUDAddOrUpdateRequest) {
        return this.apiSvc.post<CRUDAddOrUpdateRequest, CRUDCreateOrUpdateResponse>(EntityCRUDUrls.CREATE_OR_UPDATE, crudCreateOrUpdateRequest);
    }

    delete(crudDeleteRequest: CRUDDeleteRequest) {
        return this.apiSvc.delete(EntityCRUDUrls.DELETE, crudDeleteRequest);
    }

    specificDelete(urlRequest: string, id: number) {
        return this.apiSvc.delete(urlRequest, id);
    }

    askExport(sourceType: "dbset" | "sql" | "service", contextType: string, dataSource: string, exportType: ExportType, exportConfig: {}, datatableParameters: ServerSideRequest, filters: DataFilter[]) {
        let configExport: ExportRequest = {
            datatableRequest: datatableParameters,
            requestUserEmail: this.userService.email,
            fileType: exportType,
            dataSource: {
                type: ExportSourceType.entity,
                expression: {
                    DynamicContextName: contextType
                }
            },
            configuration: exportConfig,
            filters: filters
        };

        switch (sourceType) {
            case "dbset":
                configExport.dataSource.expression["EntityName"] = dataSource;
                break;
            case "sql":
                configExport.dataSource.type = ExportSourceType.sql;
                configExport.dataSource.expression["SQLQuery"] = dataSource;
                break;
        }

        return this.apiSvc.post<ExportRequest, any>(EntityCRUDUrls.ASK_EXPORT, configExport);
    }

    askImportCSV(contextType: string, entityType: string, fileUploadDef: any) {
        const formData = new FormData();
        formData.append("requestUserEmail", this.userService.email);
        formData.append("allowUpdate", fileUploadDef.allowUpdate ? "true" : "false");
        formData.append("identifierColumn", fileUploadDef.identifierColumn);
        formData.append("contextType", contextType);
        formData.append("entityType", entityType);
        formData.append(fileUploadDef.fileName, fileUploadDef.file);
        return this.apiSvc.post(EntityCRUDUrls.ASK_IMPORT_CSV_ENTITY, formData);
    }

    getDefaultAlignForType(type: string): "left" | "center" | "right" {
        switch (type.toLowerCase()) {
            default:
                return "center";
            case "string?":
            case "string":
                return "left";
            case "datetime":
            case "datetime?":
                return "center";
            case "float":
            case "float?":
            case "double":
            case "double?":
            case "decimal":
            case "decimal?":
            case "int":
            case "int?":
                return "right";
        }
    }

    getDefaultNumberOfDecimalForType(type: string): number {
        switch (type.toLowerCase()) {
            default:
                return 0;
            case "decimal":
            case "decimal?":
            case "float":
            case "float?":
            case "double":
            case "double?":
                return 2;
        }
    }
}
