import { Directive, ElementRef, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LibUserService } from '../lib-user.service';

@Directive({
  selector: '[requiredRoles]'
})
export class RoleDirective implements OnInit {
  private _roleArray : string[] = [];
  private readonly userService = inject(LibUserService);

  @Input()
  set requiredRoles(val: string[]) {
    this._roleArray = val;
  }

  constructor(private elementRef: ElementRef, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }

  ngOnInit() {
    this.getView();
  }

  private getView() {
    this.userService.isInRoles(this._roleArray).subscribe((res) => {
      if (res) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      else {
        this.viewContainer.clear();
      }
    });
  }
}
