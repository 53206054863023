import { Component, OnInit, inject, OnDestroy } from '@angular/core';
import { ICardConfig, LibDynamicComponentsEventBusService, TreeNode } from '@herdia-common';
import { Subject, takeUntil } from 'rxjs';
import { AlertAddParams, AlertDictionary, AlertParams, AlertParamsDictionnary, AlertUpdateParams } from './lib-card-alert.interfaces';
import { LibCardAlertService } from './lib-card-alert.service';

@Component({
  selector: 'lib-card-alert',
  templateUrl: './lib-card-alert.component.html',
  styleUrls: ['./lib-card-alert.component.scss']
})
export class LibCardAlertComponent implements OnInit, OnDestroy, ICardConfig {

  private eventBusService = inject(LibDynamicComponentsEventBusService);
  private alertSvc = inject(LibCardAlertService);

  alertCommonParams: any = {
    portfolio: "00000000000000000000",
    userLogin: "ALL",
  };

  // variables from interface ICardConfig, required for the card to work
  allowFullScreen: boolean = true;
  allowMobileView: boolean = false;
  hasFooter: boolean = true;
  hasHeader: boolean = false;
  hasButton: boolean = true;
  isConfigurable: boolean = false;
  minWidth: number = 4;
  cardCustomClasses: string = "";
  configTreeNode: TreeNode[] = [];
  //

  //Output
  alertDictionary: AlertDictionary = {};

  //Input
  alertParamsDictionnary: AlertParamsDictionnary = {};

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.eventBusService.addSubject("SaveAlertConfiguration", 0, new Subject<any>());
    this.eventBusService.cardSubjects["SaveAlertConfiguration_0"].pipe(takeUntil(this.destroy$)).subscribe((obj: any) => {
      this.saveAlertConfiguration();
    });
    this.getAlert();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAlert(): void {
    this.getAlertVacancyRateRetail();
    this.getAlertVacancyOffice();
    this.getAlertVacancyStorage();
    this.getAlertVacancyMixed();
    this.getAlertVacancyResidential();
    this.getAlertVacancyOther();

    this.getAlertVacantUnit();

    this.getAlertLeaseBreakRetail();

    this.getAlertLeaseExpiringRetail();
    this.getAlertLeaseExpiringResidential();
    this.getAlertLeaseExpiringOther();

    this.getAlertRentIncreased();
    this.getAlertRentDecreased();

    this.getAlertRentIncreasedInitialRent();
    this.getAlertRentDecreasedInitialRent();

    this.getAlertPropertyValueIncreased();
    this.getAlertPropertyValueDecreased();
  }

  // For Physical Vacancy Rate
  getAlertVacancyRateRetail(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_VACANCY_RATE_RETAIL"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_VACANCY_RATE_RETAIL"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_VACANCY_RATE_RETAIL"] = result[0];
    });
  }
  getAlertVacancyOffice(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_VACANCY_RATE_OFFICE"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_VACANCY_RATE_OFFICE"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_VACANCY_RATE_OFFICE"] = result[0];
    });
  }
  getAlertVacancyStorage(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_VACANCY_RATE_STORAGE"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_VACANCY_RATE_STORAGE"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_VACANCY_RATE_STORAGE"] = result[0];
    });
  }
  getAlertVacancyMixed(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_VACANCY_RATE_MIXED"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_VACANCY_RATE_MIXED"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_VACANCY_RATE_MIXED"] = result[0];
    });
  }
  getAlertVacancyResidential(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_VACANCY_RATE_RESIDENTIAL"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_VACANCY_RATE_RESIDENTIAL"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_VACANCY_RATE_RESIDENTIAL"] = result[0];
    });
  }
  getAlertVacancyOther(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_VACANCY_RATE_OTHER"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_VACANCY_RATE_OTHER"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_VACANCY_RATE_OTHER"] = result[0];
    });
  }

  // For Duration of Vacancy
  getAlertVacantUnit(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_VACANT_UNIT"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_VACANT_UNIT"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_VACANT_UNIT"] = result[0];
    });
  }

  // For Break Option
  getAlertLeaseBreakRetail(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_LEASE_BREAK_RETAIL"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_LEASE_BREAK_RETAIL"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_LEASE_BREAK_RETAIL"] = result[0];
    });
  }

  // For Expiring Leases
  getAlertLeaseExpiringRetail(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_LEASE_EXPIRING_RETAIL"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_LEASE_EXPIRING_RETAIL"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_LEASE_EXPIRING_RETAIL"] = result[0];
    });
  }
  getAlertLeaseExpiringResidential(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_LEASE_EXPIRING_RESIDENTIAL"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_LEASE_EXPIRING_RESIDENTIAL"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_LEASE_EXPIRING_RESIDENTIAL"] = result[0];
    });
  }
  getAlertLeaseExpiringOther(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_LEASE_EXPIRING_OTHER"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_LEASE_EXPIRING_OTHER"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_LEASE_EXPIRING_OTHER"] = result[0];
    });
  }

  // For Rent after Adjustment
  getAlertRentIncreased(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_RENT_INCREASED_AFTER_ADJUSTMENT"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_RENT_INCREASED_AFTER_ADJUSTMENT"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_RENT_INCREASED_AFTER_ADJUSTMENT"] = result[0];
    });
  }
  getAlertRentDecreased(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_RENT_DECREASED_AFTER_ADJUSTMENT"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_RENT_DECREASED_AFTER_ADJUSTMENT"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_RENT_DECREASED_AFTER_ADJUSTMENT"] = result[0];
    });
  }

  // For Current rent compared to initial rent
  getAlertRentIncreasedInitialRent(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_RENT_INCREASED_INITIAL_RENT"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_RENT_INCREASED_INITIAL_RENT"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_RENT_INCREASED_INITIAL_RENT"] = result[0];
    });
  }
  getAlertRentDecreasedInitialRent(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_RENT_DECREASED_INITIAL_RENT"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_RENT_DECREASED_INITIAL_RENT"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_RENT_DECREASED_INITIAL_RENT"] = result[0];
    });
  }

  // For Evolution of the net market value of a property
  getAlertPropertyValueIncreased(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_PROPERTY_VALUE_INCREASED"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_PROPERTY_VALUE_INCREASED"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_PROPERTY_VALUE_INCREASED"] = result[0];
    });
  }
  getAlertPropertyValueDecreased(): void {
    let params = {
      ...this.alertCommonParams,
      alertCode: "ALERT_PROPERTY_VALUE_DECREASED"
    } as AlertParams;

    this.alertParamsDictionnary["ALERT_PROPERTY_VALUE_DECREASED"] = params;

    this.alertSvc.getAlert(params)?.subscribe(result => {
      this.alertDictionary["ALERT_PROPERTY_VALUE_DECREASED"] = result[0];
    });
  }

  // save the new alert and his threshold on db
  saveAlertConfiguration(): void {
    //Do a verification for the update
    for (let key in this.alertParamsDictionnary) {
      this.alertSvc.getAlert(this.alertParamsDictionnary[key])?.subscribe(result => {
        let currentAlert = result[0];
        if (currentAlert.alert_threshold != this.alertDictionary[key].alert_threshold || currentAlert.alert_condition != this.alertDictionary[key].alert_condition ||
          currentAlert.is_alert_displayed != this.alertDictionary[key].is_alert_displayed || currentAlert.currency != this.alertDictionary[key].currency) {
          if (this.alertDictionary[key].is_alert_displayed?.length == 1) {
            let alertToInsert = {
              ...this.alertCommonParams,
              alertCode: key,
              alertThreshold: this.alertDictionary[key].alert_threshold,
              alertCondition: this.alertDictionary[key].alert_condition,
              isAlertDisplayed: this.alertDictionary[key].is_alert_displayed,
              currency: "EUR"
            } as AlertAddParams;

            let alertToDelete = {
              ...this.alertCommonParams,
              alertCode: key,
            } as AlertAddParams;

            let alertToUpdate = {
              insertAlert: alertToInsert,
              deleteAlert: alertToDelete
            } as AlertUpdateParams;

            this.alertSvc.updateAlert(alertToUpdate)?.subscribe(e => {
              this.eventBusService.addSubject("AlertConfigChanged", 0, new Subject<string>());
              this.eventBusService.cardSubjects["AlertConfigChanged_0"].next("AlertConfigChanged");
            });
          }
        }
      });
    }
  }
}
