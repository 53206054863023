import { Component, OnChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CardConfigBaseComponent, CreateOrUpdateJobRequest, ExportType, HAUploadNature, LibSchedulerService } from '@herdia-common';

@Component({
    selector: 'lib-card-report-config-scheduling-generate',
    templateUrl: './lib-card-report-config-scheduling-generate.component.html',
    styleUrls: ['./lib-card-report-config-scheduling-generate.component.css']
})
export class LibCardReportConfigSchedulingGenerateComponent extends CardConfigBaseComponent implements OnChanges {

    myForm: FormGroup;
    listEmail: string[] = [];
    selectedLocal: string = navigator.language;

    constructor(private taskSchedulerService: LibSchedulerService) {
        super();
        this.myForm = new FormGroup({
            flexRadio: new FormControl(),
            quoteAllFields: new FormControl(true),
            csvDelimiter: new FormControl(';'),
            csvExportName: new FormControl(),
            xlsxExportName: new FormControl(),
            checkboxFile: new FormControl(),
            email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
            checkboxEmail: new FormControl(),
        });
    }

    ngOnChanges(): void {
        if (this.config["DataSource"]) {
            if (this.config["SchedulingReport"]) {
                const dataSource = this.config["SchedulingReport"]["DataSource"];
                this.f["csvExportName"].setValue(dataSource["ProcedureName"]);
                this.f["xlsxExportName"].setValue(dataSource["ProcedureName"]);
                const richReport = this.config["SchedulingReport"]["RichReport"];
                this.f["checkboxFile"].setValue(richReport?.["UseFileSystem"]);
                this.listEmail = richReport?.["Email"] !== undefined ? richReport?.["Email"].split(';') : [];
                this.f["checkboxEmail"].setValue(richReport?.["UseEmail"]);
            }
        }
    }

    get f(): { [key: string]: AbstractControl } {
        return this.myForm.controls;
    }

    addEmail(): void {
        if (this.myForm.valid) {
            this.listEmail.push(this.f["email"].value);
            this.f["email"].setValue("");
        }
        else {
            this.f["email"].setValue("");
        }
    }

    deleteEmail(email: string): void {
        const index = this.listEmail.indexOf(email, 0);
        if (index > -1) {
            this.listEmail.splice(index, 1);
        }
    }

    // save scheduling for report
    saveCallback = async (): Promise<boolean> => {


        if (this.f["flexRadio"].value == 'csv') {
            if (this.f["checkboxFile"].value == true) {
                this.config["SchedulingReport"]["RichReport"] = {
                    Delimiter: this.f["csvDelimiter"].value, Filename: this.f["csvExportName"].value, QuoteAllFields: this.f["quoteAllFields"].value,
                    RenderingType: ExportType.csv, Nature: HAUploadNature.Other, UseFileSystem: this.f["checkboxFile"].value
                };
            }
            else {
                this.config["SchedulingReport"]["RichReport"] = {
                    Delimiter: this.f["csvDelimiter"].value, Filename: this.f["csvExportName"].value, QuoteAllFields: this.f["quoteAllFields"].value,
                    RenderingType: ExportType.csv, Nature: HAUploadNature.Other, UseFileSystem: this.f["checkboxFile"].value
                };
            }
        }
        else {
            if (this.f["checkboxFile"].value == true) {
                this.config["SchedulingReport"]["RichReport"] = {
                    Filename: this.f["xlsxExportName"].value, RenderingType: ExportType.xlsx, Nature: HAUploadNature.Other,
                    UseFileSystem: this.f["checkboxFile"].value
                };
            }
            else {
                this.config["SchedulingReport"]["RichReport"] = {
                    Filename: this.f["xlsxExportName"].value, RenderingType: ExportType.xlsx, Nature: HAUploadNature.Other,
                    UseFileSystem: this.f["checkboxFile"].value
                };
            }
        }

        if (this.f["checkboxEmail"].value == true) {
            this.config["SchedulingReport"]["RichReport"]["UseEmail"] = true;
            this.config["SchedulingReport"]["RichReport"]["Email"] = this.listEmail.join(";");
        }
        else {
            this.config["SchedulingReport"]["RichReport"]["UseEmail"] = false;
            this.config["SchedulingReport"]["RichReport"]["Email"] = "";
        }

        this.config["SchedulingReport"]["RichReport"]["LanguageCode"] = this.selectedLocal;

        let job = {
            CronExpression: this.config["SchedulingReport"]["CronExpression"],
            JobName: this.config["SchedulingReport"]["JobName"],
            Description: this.config["SchedulingReport"]["JobDescription"],
            JobClass: 'Alhambra.Api.Mvc.Quartz.SchedulingReportJob',
            Creator: this.config["SchedulingReport"]["Creator"],
            Config:
            {
                DataSource: this.config["SchedulingReport"]["DataSource"],
                RichReport: this.config["SchedulingReport"]["RichReport"],
            }
        } as CreateOrUpdateJobRequest;

        this.taskSchedulerService.createOrUpdateJob(job)?.subscribe(res => res);

        return true;
    }

}
