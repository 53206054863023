<div class="modal-header">
  <h4 class="modal-title"><lib-translate [key]="'app-page-config-export-header'"></lib-translate></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="cardDefinedConfigTitle"><lib-translate [key]="'app-page-config-export-title'"></lib-translate></label>
    <input type="text" class="form-control" id="pageExportConfigTitle" [placeholder]="configTitlePlaceHolder" [(ngModel)]="templatePageTitle">
  </div>
  <div class="form-group">
    <label for="page"><lib-translate [key]="'add-page-config-page-available'"></lib-translate></label>
    <ng-select [items]="pagesAvailable" name="page" [(ngModel)]="selectedPage" bindValue="Id" bindLabel="Title" groupBy="HAPageCategoryId" ></ng-select>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()"><lib-translate [key]="'app-btn-cancel'"></lib-translate></button>
  <button type="button" class="btn btn-primary" (click)="export()"><lib-translate [key]="'app-btn-export'"></lib-translate></button>
</div>
