import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HATranslation } from '../../shared/models/interfaces';
import { LibHerdiaAppTranslateCustomLoader } from '../../shared/service/translate.service';
@Component({
  selector: 'app-edit-translate-modal',
  templateUrl: './edit-translate-modal.component.html',
  styleUrls: ['./edit-translate-modal.component.scss']
})
export class EditTranslateModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private translateService = inject(LibHerdiaAppTranslateCustomLoader);

  @Input() tradToEdit: HATranslation = {
    Id: 0,
    Code: "",
    EnLabel: "",
    FrLabel: "",
    DeLabel: "",
    Context: ""
  };
  @Output() public tradEdited = new EventEmitter<void>();

  context: any[] = [
    { Id: 0, Label: "Alhambra" },
    { Id: 1, Label: "Socle" },
  ];

  ngOnInit(): void {
  }

  dismiss() {
    return this.activeModal.dismiss();
  }
  
  onValidate() {
    // call translateService method for edit
    this.translateService.updateGlobalTranslation(this.tradToEdit)?.subscribe(() => {
      this.tradEdited.emit();
      this.activeModal.close();
    });
  }
}
