import { Component, QueryList, inject } from '@angular/core';
import { LibDynamicComponentsEventBusService } from '@herdia-common';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-card-alert-footer',
  templateUrl: './lib-card-alert-footer.component.html',
  styleUrls: ['./lib-card-alert-footer.component.css']
})
export class LibCardAlertFooterComponent {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  cardConfigComponents?: QueryList<any>

  onValidate(): void {
    this.eventBusService.addSubject("SaveAlertConfiguration", 0, new Subject<any>());
    this.eventBusService.cardSubjects["SaveAlertConfiguration_0"].next({});
  }
}
