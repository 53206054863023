<div [ngClass]="showLabel ? 'form-group' : ''">
    <label
        *ngIf="showLabel"
        for="{{this.propertyDefinition.Name}}"
    >{{this.propertyLabel}}</label>
    <input
        *ngIf="propertyIsNumber && !isPropertyForeignKey"
        type="number"
        name="{{this.propertyDefinition.Name}}"
        class="form-control"
        [ngClass]="{ 'is-invalid': isInError }"
        placeholder="{{this.propertyLabel}}"
        [(ngModel)]="inputValue"
        (ngModelChange)="onInputUpdated($event)"
    />
    <input
        *ngIf="propertyIsString && !isPropertyForeignKey"
        type="text"
        name="{{this.propertyDefinition.Name}}"
        class="form-control"
        [ngClass]="{ 'is-invalid': isInError }"
        placeholder="{{this.propertyLabel}}"
        [(ngModel)]="inputValue"
        (ngModelChange)="onInputUpdated($event)"
    />
    <input
        *ngIf="propertyIsBoolean && !isPropertyForeignKey"
        type="checkbox"
        name="{{this.propertyDefinition.Name}}"
        class=""
        [(ngModel)]="inputValue"
        (ngModelChange)="onInputUpdated($event)"
    />
    <div
        class="input-group"
        *ngIf="propertyIsDate && !isPropertyForeignKey"
    >
        <input
            class="form-control"
            placeholder="yyyy-dd-mm"
            name="dp"
            [(ngModel)]="inputValue"
            ngbDatepicker
            #d="ngbDatepicker"
            (click)="onClick()"
            (ngModelChange)="onInputUpdated($event)"
        />
        <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d.toggle()"
            type="button"
        ><fa-icon icon="calendar"></fa-icon></button>
    </div>
    <ng-select
        *ngIf="isPropertyForeignKey"
        dropdownPosition="bottom"
        [(ngModel)]="inputValue"
        bindLabel="Label"
        bindValue="Key"
        [items]="this.propertyDefinition.AvailableItems"
    ></ng-select>
</div>
