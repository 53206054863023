<ng-template
    [ngIf]="this.config['DataSource']"
    [ngIfElse]="elseBlock"
>
    <lib-data-input-parameters
        [config]="this.config['SchedulingReport']"
        [displayStoredProcedure]="false"
        [allowRawBinding]="true"
        [allowCardBinding]="false"
        [allowPageBinding]="false"
        [listValuesPropertyDefinitions]="listValuesPropertyDefinitions"
    >
    </lib-data-input-parameters>
</ng-template>
<ng-template #elseBlock>
    <ngb-alert
        type="danger"
        [dismissible]="false"
    ><lib-translate [key]="'lbl-choose-parameters'"></lib-translate></ngb-alert>
</ng-template>