import { Component, ViewChild, inject } from '@angular/core';
import {
  ColumnSetting,
  HerdiaDatatableGlobalConfiguration,
  LibDynamicComponentsEventBusService,
  LibHerdiaDatatableComponent, ServerSideRequest,
  ServerSideResponse
} from "@herdia-common";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable, Subject } from "rxjs";
import { LibHerdiaAppTranslateCustomLoader } from "../shared/service/translate.service";
import { EditTranslateModalComponent } from './edit-translate-modal/edit-translate-modal.component';
@Component({
  selector: 'app-tranlate-manager',
  templateUrl: './translate-manager.component.html',
  styleUrls: ['./translate-manager.component.scss']
})
export class TranslateManagerComponent {
  private eventBusService = inject(LibDynamicComponentsEventBusService);
  private modalService = inject(NgbModal)

  // Give a high number so there wil be no card with this number (same number as in the table HAPageCards)
  cardId: number = 999999998;

  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;

    searchEntitySubject: Subject<string> = new Subject<string>();
    herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
        compactMode: false,
        cellBorder: true,
        hover: true,
        orderColumn: true,
        rowBorder: false,
        stripe: true,
        nowrap: false,
        defaultRowNumberOnPage: 10,
        autoWidth: false,
        fixedLeftColumns: 0
    };

  searchValue = "";

  onSearch(): void {
    this.eventBusService.addSubject("SearchEntity", this.cardId, new Subject<string>());
    this.eventBusService.cardSubjects["SearchEntity_" + this.cardId].next(this.searchValue);
  }

    Edit = (data: any): void => {
      //call modal for a edit traduction
      const modalRef = this.modalService.open(EditTranslateModalComponent);
      modalRef.componentInstance.tradToEdit = data;
      modalRef.componentInstance.tradEdited.subscribe(() => {
        this.herdiaDatatable.reload();
      });
    }

    columnSettings: ColumnSetting[] = [
        {
            name: "Code",
            label: "Code",
            type: "string",
            visible: true,
            nbDecimal: 0,
            nature: "normal",
            horizontalAlign: 'left',
            displaySum: false,
            width: 200,
            actionColumnDef: null
        },
        {
            name: "EnLabel",
            label: "Anglais",
            type: "string",
            visible: true,
            horizontalAlign: 'left',
            displaySum: false,
            actionColumnDef: null,
            nbDecimal: 0,
            nature: "normal",
            width: 200
        },
        {
            name: "FrLabel",
            label: "Français",
            type: "string",
            visible: true,
            horizontalAlign: "left",
            width: 100,
            actionColumnDef: null,
            nature: "normal",
            nbDecimal: 0,
            displaySum: false
        },
        {
            name: "DeLabel",
            label: "Allemand",
            type: "string",
            visible: true,
            horizontalAlign: "left",
            width: 100,
            actionColumnDef: null,
            nature: "normal",
            nbDecimal: 0,
            displaySum: false
        },
        {
            name: "Context",
            label: "Contexte",
            type: "string",
            visible: true,
            horizontalAlign: "left",
            width: 100,
            actionColumnDef: null,
            nature: "normal",
            nbDecimal: 0,
            displaySum: false
        },
        {
            name: "Actions",
            label: "Actions",
            type: "custom",
            visible: true,
            actionColumnDef: {
                align: "center",
                buttons: [
                    {
                        type: "button",
                        icon: "edit",
                        text: "",
                        class: 'btn btn-primary btn-sm btn-tool',
                        onclick: this.Edit
                    }
                ]
            },
            displaySum: false,
            nbDecimal: 0,
            nature: "normal",
            width: 100,
            horizontalAlign: "center"
        }
    ];
    constructor(private translatePipe: TranslatePipe, private translateService: LibHerdiaAppTranslateCustomLoader) { }

  getData = (dataTablesParameters: ServerSideRequest): Observable<ServerSideResponse> => {
    return this.translateService.getTranslationTable(dataTablesParameters);
  }
}
