import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LibUserService, PropertyDefinition, User } from '@herdia-common';
import { Role } from '../../shared/models/interfaces';
import { AppService } from '../../shared/service/app.service';
import { RoleService } from '../../shared/service/role.service';

@Component({
    selector: 'app-add-edit-user',
    templateUrl: './add-edit-user.component.html',
    styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit {

    id?: string;

    user: User = {
        Id: '',
        LastName: '',
        FirstName: '',
        LanguageCode: 'fr-FR',
        Email: '',
        UserName: '',
        Phone: '',
        DateFormat: '',
        Img: '',
        Roles: [],
    };

    ApplicationUserProperties!: PropertyDefinition[];
    hasApplicationUserProperties!: boolean;
    rolesNGSelect: Role[] = [];
    dateFormatNGSelect!: any;
    dateFormat!: { key: string, value: string };

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: LibUserService,
        private roleService: RoleService,
        private appSvc: AppService
    ) {
        const routeId = this.activatedRoute.snapshot.paramMap.get('id');
        if (routeId)
            this.id = routeId;
        else {
            this.id = undefined;
        }

        this.roleService.getRoles().subscribe(res => this.rolesNGSelect = res);
        this.dateFormatNGSelect = this.userService.AllDateFormat;
    }

    ngOnInit(): void {
        this.appSvc.applicationUserProperties.subscribe((res: PropertyDefinition[]) => {
            this.ApplicationUserProperties = res;
            this.hasApplicationUserProperties = this.ApplicationUserProperties.length > 1;
        });

        if (this.id !== undefined)
            this.userService.viewUser(this.id).subscribe((u: User) => {
                this.user = {
                    Id: u.Id,
                    LastName: u.LastName,
                    FirstName: u.FirstName,
                    LanguageCode: u.LanguageCode != null ? u.LanguageCode : 'fr-FR',
                    Email: u.Email,
                    Img: u.Img,
                    UserName: u.UserName,
                    Phone: u.Phone,
                    Roles: u.Roles,
                    DateFormat: (u.DateFormat != null && u.DateFormat != undefined && u.DateFormat !== "") ? u.DateFormat : "FR",
                };

                this.dateFormat = this.userService.getEntryFromAKey(this.user.DateFormat, this.dateFormatNGSelect);
            });
    }

    onImgUpdload(e: any) {
        const file = e.target.files[0];

        if (!file || file.length === 0)
            return;

        if (file.type.match(/image\/*/) == null)
            return;

        const reader = new FileReader();
        reader.onload = () => this.user.Img = reader.result as string;
        reader.readAsDataURL(file);
    }

    onSubmit() {
        this.userService.addOrUpdate(this.user)?.subscribe((succeded: boolean) => {
            if (this.user.Id == this.userService.user?.Id)
                this.userService.setUser(this.user);
            if (succeded) this.router.navigate(['user']);
            else console.error(`Error adding or updating user`);
        });
    }

    onChangeDateFormat(event: any): void {
        this.user.DateFormat = event.key;
    }
}
