import { Component, Input, OnInit } from '@angular/core';
import { TranslatePipe } from "@ngx-translate/core";
import { ColumnSetting } from '../../lib-column-selector/lib-column-selector.interface';
import { EntityDefinition, PropertyDefinition } from '../lib-card-generic-crud.interface';
import { LibCardGenericCRUDService } from '../lib-card-generic-crud.service';
import { CardConfigBaseComponent } from '../../dynamic-components/card-config-base-component';

@Component({
    selector: 'lib-card-generic-crud-config-table',
    templateUrl: './lib-card-generic-crud-config-table.component.html',
    styleUrls: ['./lib-card-generic-crud-config-table.component.scss']
})
export class LibCardGenericCrudConfigTableComponent extends CardConfigBaseComponent implements OnInit {

    availableCols: ColumnSetting[] = [];
    selectedCols: ColumnSetting[] = [];

    selectedContext: string = "";
    selectedEntity: EntityDefinition = { Name: "", Properties: [], ColumnSettings: [] };

    constructor(private translatePipe: TranslatePipe, private libCardGenericCrudService: LibCardGenericCRUDService) {
        super();
    }

    ngOnInit(): void {
        if (this.config["DataSource"] === undefined)
            this.config["DataSource"] = { Context: "", Entity: "" };

        if (this.config["DataTableConfig"] == null) {
            this.config["DataTableConfig"] = {
                compactMode: false,
                cellBorder: true,
                hover: true,
                orderColumn: true,
                rowBorder: false,
                stripe: true,
                nowrap: false,
                defaultRowNumberOnPage: 10
            };
        }

        if (this.config["DataSource"]['Context'] !== undefined &&
            this.config["DataSource"]['Context'] !== "") {
            this.selectedContext = this.config['DataSource']['Context'];
            if (this.config["DataSource"]['Entity'] !== undefined &&
                this.config["DataSource"]['Entity'] !== "") {
                this.selectedEntity = this.config['DataSource']['Entity'];

                // Keep this for retro-compatibility
                let buffer: ColumnSetting[] = [];
                if (this.config['DataSource']['Entity']['Properties'] &&
                    !this.config['DataSource']['AvailableColumns']) {
                    this.selectedEntity.Properties.forEach((e: PropertyDefinition) => {
                        buffer.push({
                            name: e.Name,
                            label: this.translatePipe.transform(e.Name.toUpperCase()),
                            nature: "normal",
                            nbDecimal: this.libCardGenericCrudService.getDefaultNumberOfDecimalForType(e.Type),
                            type: e.Type,
                            displaySum: false,
                            horizontalAlign: this.libCardGenericCrudService.getDefaultAlignForType(e.Type),
                            visible: true,
                            width: 150,
                            actionColumnDef: null
                        });
                    });
                    this.availableCols = buffer;
                    this.selectedCols = buffer;
                    this.config['DataSource']['AvailableColumns'] = buffer;
                    this.config['DataSource']['Entity']['ColumnsSettings'] = buffer;
                }
                //

                if (this.config['DataSource']['AvailableColumns'])
                    this.availableCols = this.config['DataSource']['AvailableColumns'];
                if (this.config['DataSource']['Entity']['ColumnsSettings'])
                    this.selectedCols = this.config['DataSource']['Entity']['ColumnsSettings']
            }
        }
    }

    onSelectedColumnsChange(e: any): void {
        this.config['DataSource']['Entity']['ColumnsSettings'] = this.selectedCols;
    }
}
