import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LibPropertyTypeInputComponent } from './lib-property-type-input/lib-property-type-input.component';

@NgModule({
    declarations: [
        LibPropertyTypeInputComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        NgSelectModule,
        FontAwesomeModule,
    ],
    exports: [
        LibPropertyTypeInputComponent
    ]
})
export class LibPropertyTypeInputModule { }
