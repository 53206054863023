<form class="form-group" [formGroup]="myFormJob">
    <div>
        <label class="mt-2"><lib-translate [key]="'lbl-job-name'"></lib-translate></label>
        <input type="text" class="form-control" formControlName="jobNameTxt">
        <label class="mt-2"><lib-translate [key]="'lbl-job-desc'"></lib-translate></label>
        <input type="text" class="form-control" formControlName="jobDescTxt">
    </div>
</form>

<lib-cron-editor #actions
                 [currentTrigger]="currentTrigger"
                 [context]="context"
                 [displayMinute]="false"
                 [displayHours]="false">
</lib-cron-editor>
