<ng-select [items]="availableColumns" [(ngModel)]="selectedColumns" placeholder="'lbl-select-columns'" bindLabel="label" [multiple]="true" [closeOnSelect]="false" (change)="onSelectedColumnsChange($event)">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

  </ng-template>
  <ng-template ng-header-tmp let-items="items">
    <div class="multiSelectButtons">
      <button (click)="selectAllColumns()" class="btn btn-sm btn-primary"><lib-translate [key]="'select-all'"></lib-translate></button>
      <button (click)="unselectAllColumns()" class="btn btn-sm btn-secondary"><lib-translate [key]="'select-none'"></lib-translate></button>
    </div>
  </ng-template>
  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" />&nbsp;<lib-translate [key]="item.name"></lib-translate></div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" />&nbsp;<lib-translate [key]="item.name"></lib-translate></div>
  </ng-template>
</ng-select>

<ul class="columnSettings list-group sortable" dragula="HANDLES" [(dragulaModel)]="selectedColumns" (dragulaModelChange)="onSelectedColumnsChange($event)">
  <li *ngFor="let item of selectedColumns; let index = index" class='list-group-item container'>
    <div class="d-flex justify-content-between align-items-center w-100">
      <div>
        <span class="handle"><fa-icon icon="grip-lines"></fa-icon></span>
        &nbsp;
        <lib-translate [key]="item.name"></lib-translate>
      </div>
      <div style="display: flex; align-items: center;">
        <div *ngIf="isNumber(item.type)" style="display: flex; align-items: center;">
          <label><lib-translate key="decimal-places"></lib-translate></label>:&nbsp;
          <input type="number" [(ngModel)]="item.nbDecimal" class="form-control form-control-sm" style="max-width:4rem;">
        </div>
        &nbsp;
        <div *ngIf="isNumber(item.type)" class="btn-group btn-group-sm">
          <input type="checkbox" class="btn-check" name="btnAlign_{{item.name}}" id="btnAlign_{{item.name}}_additional" [checked]="item.displaySum === true" [(ngModel)]="item.displaySum">
          <label class="btn btn-outline-primary" for="btnAlign_{{item.name}}_additional"><fa-icon icon="add"></fa-icon></label>
        </div>
        &nbsp;
        <div *ngIf="isNumber(item.type)">
          <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="btnNature_{{item.name}}" id="btnNature_{{item.name}}_percent" autocomplete="off" value="percent" [checked]="item.nature === 'percent'" [(ngModel)]="item.nature">
            <label class="btn btn-outline-primary" for="btnNature_{{item.name}}_percent"><fa-icon icon="percentage"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnNature_{{item.name}}" id="btnNature_{{item.name}}_currency" autocomplete="off" value="currency" [checked]="item.nature === 'currency'" [(ngModel)]="item.nature">
            <label class="btn btn-outline-primary" for="btnNature_{{item.name}}_currency"><fa-icon icon="euro-sign"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnNature_{{item.name}}" id="btnNature_{{item.name}}_normal" autocomplete="off" value="normal" [checked]="item.nature === 'normal'" [(ngModel)]="item.nature">
            <label class="btn btn-outline-primary" for="btnNature_{{item.name}}_normal"><fa-icon icon="ban"></fa-icon></label>
          </div>
        </div>
        &nbsp;
        <div class="btn-group btn-group-sm btn-group-toggle" data-toggle="buttons">
          <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="btnAlign_{{item.name}}" id="btnAlign_{{item.name}}_left" autocomplete="off" value="left" [checked]="item.horizontalAlign === 'left'" [(ngModel)]="item.horizontalAlign">
            <label class="btn btn-outline-primary" for="btnAlign_{{item.name}}_left"><fa-icon icon="align-left"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnAlign_{{item.name}}" id="btnAlign_{{item.name}}_center" autocomplete="off" value="center" [checked]="item.horizontalAlign === 'center'" [(ngModel)]="item.horizontalAlign">
            <label class="btn btn-outline-primary" for="btnAlign_{{item.name}}_center"><fa-icon icon="align-center"></fa-icon></label>
            <input type="radio" class="btn-check" name="btnAlign_{{item.name}}" id="btnAlign_{{item.name}}_right" autocomplete="off" value="right" [checked]="item.horizontalAlign === 'right'" [(ngModel)]="item.horizontalAlign">
            <label class="btn btn-outline-primary" for="btnAlign_{{item.name}}_right"><fa-icon icon="align-right"></fa-icon></label>
          </div>
        </div>
        &nbsp;
        <div>
          <input type="number" [(ngModel)]="item.width" class="form-control  form-control-sm inputNumberStyle" step="10">
        </div>
        &nbsp;
        <div>
          <button type="button" class="btn btn-danger btn-sm" (click)="removeColumn(index)"><fa-icon icon="close"></fa-icon></button>
        </div>
      </div>
    </div>
  </li>
</ul>
