import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LibFileDepositService, AuthFileDeposit, CapabilitiesFileDeposit, IFileDeposit, TypeFileDeposit } from '@herdia-common';

@Component({
  selector: 'app-add-filedeposit-modal',
  templateUrl: './add-filedeposit-modal.component.html',
  styleUrls: ['./add-filedeposit-modal.component.scss']
})
export class AddFiledepositModalComponent implements OnInit {
  @Output() public fileDepositAdded = new EventEmitter<void>();

  typeFileDeposit: TypeFileDeposit[] = [
    { id: 0, Label: "Docuware" },
    { id: 1, Label: "FileSystem" }
  ];
  authTypeFileDeposit: AuthFileDeposit[] = [
    { id: 0, Label: "Basic" },
    { id: 1, Label: "Ldap" },
    { id: 2, Label: "GoogleAuth" },
    { id: 3, Label: "ApiKey" }
  ];
  CapabilitiesFileDeposit: CapabilitiesFileDeposit[] = [
    { id: 0, Label: "None" },
    { id: 1, Label: "Player" },
  ];

  newFileDeposit: IFileDeposit = {
    Id: 0,
    Label: "",
    Enable: true,
    EnableString: 'yes',
    Type: 0,
    Tag: "",
    Filter: "",
    Auth: 0,
    Login: "",
    Password: "",
    Host: "",
    Port: 0,
    RootPath: "",
    Capabilities: [0]
  };


  constructor(private activeModal: NgbActiveModal, private FileDepositService: LibFileDepositService ) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  onValidate(): void {
    //dans le subscribe ajouter this.fileDepositAdded.emit();
    this.FileDepositService.AddFileDeposit(this.newFileDeposit).subscribe((r: any) => {
      if (r.success == true) {
        this.fileDepositAdded.emit();
        this.activeModal.close();
      }
      else {
        console.log("error add : " + r.message);
      }
    });
  }
}
