import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { UpdateThemeRequest, ThemeVariables } from '../models/interfaces';
import { ApplicationSpecificPropertiesUrls } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CardThemeDesignerService {

  applicationDefaultTheme: BehaviorSubject<string> = new BehaviorSubject<string>("");
  themeWrapper = document.querySelector('body');

  constructor(private apiService: LibHttpClientApiService) { }

  getUserThemeList() {
    return this.apiService.get("api/Theme/GetUserThemeList");
  }

  setDefaultTheme(): Observable<string> {
    return this.apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_DEFAULT_THEME);
  }

  showCustomTheme(Theme: ThemeVariables): void {
    this.themeWrapper?.style.setProperty('--primaryColor', Theme.PrimaryColor);
    this.themeWrapper?.style.setProperty('--secondaryColor', Theme.SecondaryColor);
    this.themeWrapper?.style.setProperty('--leftNavbarColor', Theme.LeftNavbarColor)
    this.themeWrapper?.style.setProperty('--customFontSize', Theme.CustomFontSize);
    this.themeWrapper?.style.setProperty('--topNavbarColor', Theme.TopNavbarColor);
  }

  getThemeDefinition(Label: string) {
    return this.apiService.get<{ HATheme: any, HAThemeId: number, Id: number, VariableName: string, VariableValue: string }[]>("api/Theme/GetThemeDefinition/" + Label)
  }

  updateThemeVariableValues(TargetTheme: UpdateThemeRequest) {
    return this.apiService.put<UpdateThemeRequest, any>("api/Theme/UpdateThemeVariableValues", TargetTheme)
  }

  showTheme(Label: string) {
    return this.getThemeDefinition(Label).pipe(tap(r => {

      const primary = r.find(e => e.VariableName == "PrimaryColor")?.VariableValue as string;
      const secondary = r.find(e => e.VariableName == "SecondaryColor")?.VariableValue as string;
      const leftNavbar = r.find(e => e.VariableName == "NavbarColor")?.VariableValue as string;
      const customFontSize = r.find(e => e.VariableName == "customFontSize")?.VariableValue as string;
      const topNavBar = r.find(e => e.VariableName == "TopNavbarColor")?.VariableValue as string;

      this.themeWrapper?.style.setProperty('--primaryColor', primary);
      this.themeWrapper?.style.setProperty('--secondaryColor', secondary);
      this.themeWrapper?.style.setProperty('--leftNavbarColor', leftNavbar);
      this.themeWrapper?.style.setProperty('--customFontSize', customFontSize);
      this.themeWrapper?.style.setProperty('--topNavbarColor', topNavBar);
    }));
  }
}
