<div class="login-box" [ngClass]="{'display-none': isLoading}">
  <div class="login-logo">
    <a href="../../index2.html"><b>{{applicationName}}</b></a>
  </div>

  <div class="card">
    <div class="card-body login-card-body">

      <div class="container">
        <div class="login-box-msg">
          <div class="row">
            <div class="col-lg-7 mt-2">{{'login-from'|translate}}</div>
            <div class="col-lg-3">
              <ng-select [clearable]="false" [searchable]="false" class="langSelector" [ngModel]="selectedLocal" (ngModelChange)="onLocalChanged($event)" [items]="['en-GB', 'fr-FR', 'de-DE']">
                <ng-template ng-label-tmp let-item="item">
                  <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="input-group mb-3">
          <input formControlName="username" type="email" class="form-control"
                 [ngClass]="{'is-invalid': submitted && form.controls['username'].errors}"
                 placeholder="{{'email'|translate}}">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
          <span class="error invalid-feedback" *ngIf="submitted && getError(form, 'username', 'required')">
            {{'required-email'|translate}}
          </span>
          <span class="error invalid-feedback" *ngIf="submitted && getError(form, 'username', 'email')">
            {{'invalid-email'|translate}}
          </span>
        </div>
        <div class="input-group mb-3">
          <input formControlName="password" type="password" class="form-control"
                 [ngClass]="{'is-invalid': submitted && form.controls['password'].errors}"
                 placeholder="{{'password'|translate}}">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
          <span class="error invalid-feedback" *ngIf="submitted && getError(form, 'password', 'required')">
            {{'required-password'|translate}}
          </span>
        </div>

        <span class="error invalid-feedback" *ngIf="submitted && loginInvalide">
          {{'invalid-email-password'|translate}}
        </span>

        <div class="row mt-2 mb-2">
          <div class="col-6">
            <div class="icheck-primary">
              <input type="checkbox" id="remember">
              <label for="remember" class="ml-2">
                {{'remember-me'|translate}}
              </label>
            </div>
          </div>

          <div class="col-1">
          </div>

          <div class="col-5">
            <button type="submit" class="btn btn-primary btn-block">{{'login'|translate}}</button>
          </div>
        </div>
      </form>

      <div GoogleSigninButton class="col-5">
        <asl-google-signin-button type='icon' size='medium'> login with google </asl-google-signin-button>
</div>

      <p class="mb-1">
        <a href="forgot_password">{{'forgot-password'|translate}}</a>
      </p>
    </div>

  </div>
</div>

<app-loading [applicationName]="applicationName" [setProgressMercentage]="loadingProgress" [loadingLabel]="loadingLabel" *ngIf="isLoading"></app-loading>
