import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from '../../shared/service/role.service';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss']
})
export class AddEditRoleComponent implements OnInit {

  @Input()
  id: string = '';

  @Input()
  name: string = '';

  editMode = false;

  constructor(
    private activeModal: NgbActiveModal,
    private roleService: RoleService
  ) { }

  dismiss(message: string) {
    return this.activeModal.dismiss(message);
  }

  ngOnInit(): void {
  }

  confirm() {
    if (!this.name) {
      console.error('Le nom du role est vide');
      return;
    }

    this.roleService.AddUpdateRole(this.id, this.name)?.subscribe(res => { if (res) this.activeModal.close(this.name); });
  }

}
