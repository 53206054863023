import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// const URL = '/api / ';
const URL = 'https://localhost:9443/api/HAUpload/UploadFromVM';

@Component({
  selector: 'lib-uploader',
  templateUrl: './lib-uploader.component.html',
  styleUrls: ['./lib-uploader.component.scss']
})
export class LibUploaderComponent implements OnInit {

  uploader!: FileUploader;
  reader: FileReader = new FileReader();
  hasBaseDropZoneOver: boolean = false;
  @Input() maxSize = 0;
  @Input() dayRetention = 0;
  @Input() sensitiveData = false;
  @Input() multipleUpload = false;
  @Input() showUploadList = false;
  response: string = '';

  private _success = new Subject<string>();
  private _error = new Subject<string>();
  staticAlertClosed = false;
  successMessage = "";
  errorMessage = "";
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

  constructor() {
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      url: URL,
      maxFileSize: this.maxSize,
      disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: false,
      method: 'POST',
    });

    this.uploader.onAfterAddingFile = (item: FileItem) => {
      item.withCredentials = false;
    }

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      let size = fileItem._file.size/1024;
      form.append('Definition[FileName]', fileItem._file.name); //note comma separating key and value
      form.append('Definition[MimeType]', fileItem._file.type);
      form.append('Definition[MaxSize]', this.maxSize);
      form.append('Definition[DayRetention]', this.dayRetention);
      form.append('Definition[SensitiveData]', this.sensitiveData)
      form.append('Definition[Size]', size.toFixed(2)); //
      form.append('File', fileItem);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      console.info('onSuccessItem', fileItem, response, status, headers);
      this.changeSuccessMessage(fileItem._file.name);
    }

    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      console.info('onErrorItem', fileItem, response, status, headers);
      this.changeErrorMessage(fileItem._file.name);
    }

    this.hasBaseDropZoneOver = false;
  }

  clearQueue() {
    /*if (this.uploader.queue.length > 0)*/
      this.uploader.clearQueue();
  }

  public changeSuccessMessage(fileName: string) {
    this.uploader.response.subscribe(res => this.response = res);

    setTimeout(() => this.staticAlert.close(), 20000);

    this._success.subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    this._success.next("the upload was perfectly done for the file : " + fileName);
  }

  public changeErrorMessage(fileName: string) {
    this.uploader.response.subscribe(res => this.response = res);

    setTimeout(() => this.staticAlert.close(), 20000);

    this._error.subscribe((message) => (this.errorMessage =message));
    this._error.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
    this._error.next("the upload occured with an error for the file : " + fileName);
  }
}
