<lib-herdia-datatable
    #herdiaDatatable
    [cardId]="cardId"
    [columnSettings]="columnSettings"
    [dataGetter]="getData"
    [keepRowNumber]="true"
    [herdiaDTConfig]="config['DataTableConfig']"
    [fileDepostitConfig]="this.config['fileDeposit']"
>
</lib-herdia-datatable>
