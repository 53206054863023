import { Component, Input, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { LibCardChartService } from '../lib-card-chart.service';
import { LibHttpClientApiService } from '@herdia-common';

@Component({
  selector: 'lib-card-chart-define-bar',
  templateUrl: './lib-card-chart-define-bar.component.html',
  styleUrls: ['./lib-card-chart-define-bar.component.css']
})
export class LibCardChartDefineBarComponent implements OnInit {

  @Input() config: { [key: string]: any } = {};

  bars: Array<any> = []
  dataBars: Array<string> = []
  availableNumberDatasources: { key: string, label: string }[] = [];

  constructor(private chartService: LibCardChartService, private apiService: LibHttpClientApiService, private translatePipe: TranslatePipe) { }

  get availablePalettes() {
    return this.chartService.availablePalettes;
  }

  ngOnInit(): void {
    if (!this.config["ChartConfig"]["DataSources"]) {
      this.config["ChartConfig"]["DataSources"] = {
        Labels: "",
        Datas: "",
        ColorPalette: ""
      };
    }

    if (this.config["ChartConfig"]["DataSources"]["Datas"] != "") {
      var datas = this.config["ChartConfig"]["DataSources"]["Datas"];

      if (!Array.isArray(datas)) { // On gère le cas ou ["Datas"] est de type 'string' et non de type 'string[]'
        datas = [this.config["ChartConfig"]["DataSources"]["Datas"]];
      }

      (Object.values(datas)).forEach((element: any, index: any) => {
        this.bars.push(index);
        this.dataBars[index] = element;
      })
    }

    let numberDatasources: { key: string, label: string }[] = [];
    let resultSet = this.apiService.getActionResult("/api/alhambra", this.config["DataSource"]["ProcedureName"], "Datas", "post").responses;
    Object.keys(resultSet).forEach(key => {
        numberDatasources.push({ key: key, label: this.translatePipe.transform(key) });
    });
    this.availableNumberDatasources = numberDatasources;
  }

  addBar():void {
    this.bars = [...this.bars, this.bars.length];
  }

  removeBar(): void {
    this.bars.splice(-1);
    if (this.dataBars[this.dataBars.length - 1] != undefined) {
      this.dataBars.splice(-1);
    }
    if (Array.isArray(this.config["ChartConfig"]["DataSources"]['Datas']))
      this.config["ChartConfig"]["DataSources"]['Datas'].splice(-1)
  }

  addingData(barId: any, event : any): void {
    this.dataBars[barId] = event["key"];
    this.config["ChartConfig"]["DataSources"]['Datas'] = this.dataBars;
  }
}
