import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { DynamicComponentComponent, DynamicComponentsService, LibConfirmBoxService } from '@herdia-common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ICardConfig } from '../../../herdia-common/lib-interface-card/lib-interface-card.interfaces';
import { HaPageCard } from '../../shared/models/interfaces';
import { CardService } from '../../shared/service/card.service';

@Component({
  selector: 'app-card-config',
  templateUrl: './card-config.component.html',
  styleUrls: ['./card-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardConfigComponent implements OnInit, OnDestroy {

  private dynamicComponentsService = inject(DynamicComponentsService);

  @ViewChildren(DynamicComponentComponent)
  cardConfigComponents?: QueryList<any>;

  configNodeStates: { [key: string]: boolean } = {};

  cardTitleLabel: string = '';
  card!: HaPageCard;
  configLabels: string[] = [];
  cardInterface: ICardConfig = {
    minWidth: 12,
    allowFullScreen: true,
    hasButton: true,
    hasFooter: true,
    hasHeader: true,
    isConfigurable: true,
    allowMobileView: true,
    cardCustomClasses: '',
    configTreeNode: [],
  };

  maximizedModal: boolean = false;
  onCardUpdated = new EventEmitter();

  deleteTitleModal: string = "";
  deleteMsgModal: string = "";
  deleteValidateModal: string = "";
  deleteCancelModal: string = "";

  constructor(
    private cardSvc: CardService,
    private confirmBoxSvc: LibConfirmBoxService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private translatePipe: TranslatePipe
  ) {
    this.cardTitleLabel = this.translatePipe.transform(
      'app-card-configuration-card-title'
    );
    this.deleteTitleModal = translatePipe.transform("delete-title-modal-card");
    this.deleteMsgModal = translatePipe.transform("delete-message-modal-card");
    this.deleteValidateModal = translatePipe.transform("delete-validate-button-modal-card");
    this.deleteCancelModal = translatePipe.transform("delete-cancel-button-modal-card");

    confirmBoxSvc.confirmationSub.pipe(takeUntil(this.destroy$)).subscribe((c: Boolean) => {
      if (c) {
        this.cardSvc.deleteCard(this.card.Id)?.pipe(takeUntil(this.destroy$)).subscribe((c) => {
          if (c) {
            this.activeModal.close();
          }
        });
      }
    });

    cardSvc.closeSaveConfigSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onCardUpdated.next(true);
    });
  }

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    const cardConfig = this.dynamicComponentsService.getCardComponentConfig(this.card.CardTypeLabel);
    if (!cardConfig) return;

    this.cardInterface = cardConfig;
    this.cardInterface.configTreeNode
      .filter(configTreeNode => configTreeNode.children.length > 0)
      .forEach(configTreeNode => {
        this.configNodeStates[configTreeNode.key] = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onIconPickerSelect(icon: string) {
    //get the icon sting from the config of the card
    this.card.Configuration['icon'] = icon;
  }

  onDelete() {
    this.confirmBoxSvc.open({
      title: this.deleteTitleModal,
      msg: this.deleteMsgModal + " " + this.card.Title + " ?",
      okBtnLbl: this.deleteValidateModal,
      koBtnLbl: this.deleteCancelModal,
    });
  }

  onSave() {
    this.cardSvc.openAndInitSaveConfigModal(this.card);
  }

  /**
   * TODO(migration): Use Angular [@Output](https://angular.io/guide/inputs-outputs) rather than getting the saveCallback() function
   */
  onValidate(): void {
    const saveActions: (() => Subject<boolean>)[] = [];
    if (
      this.cardConfigComponents &&
      this.cardConfigComponents.some((v) => true)
    ) {
      this.cardConfigComponents
        .toArray()

        .forEach((c: DynamicComponentComponent) => {
          if (c.component?.instance['saveCallback']) {
            c.component.instance.saveCallback().then((b: boolean) => {
              if (b) this.updateCard();
            });
          } else {
            this.updateCard();
          }
        });
    } else {
      this.updateCard();
    }
  }

  onCancel(): void {
    //Reset the card
    this.cardSvc.getCard(this.card.Id)?.pipe(takeUntil(this.destroy$)).subscribe((initCard) => {
      if (initCard) {
        this.card = initCard;
        this.updateCard();
      }
    });
  }

  updateCard() {
    if (this.card.Configuration['Filters'])
      delete this.card.Configuration['Filters'];
    if (this.card.Configuration['CardFilters'])
      delete this.card.Configuration['CardFilters'];
    this.cardSvc.updateCard(this.card)?.pipe(takeUntil(this.destroy$)).subscribe((c) => {
      if (c) this.activeModal.close();
    });
  }

  onExport() {
    if (this.card != null && this.card.Id != undefined) {
      if (this.card.Configuration['Filters'])
        delete this.card.Configuration['Filters'];
      if (this.card.Configuration['CardFilters'])
        delete this.card.Configuration['CardFilters'];
      this.cardSvc.openAndInitExportConfig(this.card);
    }
  }

  toggleChildren(parentKey: string): void {
    this.cardInterface.configTreeNode.forEach(treeNode => {
      this.configNodeStates[treeNode.key] = false;
      if (treeNode.children.length > 0) {
        if (parentKey === treeNode.key) {
          this.configNodeStates[parentKey] = !this.configNodeStates[parentKey]
        }
      }
    });
  }

  fullscreenModal() {
    if (this.maximizedModal == false) {
      this.maximizedModal = true;
    }
    else {
      this.maximizedModal = false;
    }
  }
}
