import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { LibPanelRightService } from './lib-panel-right.service';

@Component({
    selector: 'lib-panel-right',
    templateUrl: 'lib-panel-right.component.html',
    styleUrls: ['lib-panel-right.component.scss']
})
export class LibPanelRightComponent implements OnInit {
    constructor(
        private panelService: LibPanelRightService,
        private translatePipe: TranslatePipe
    ) { }

    get getPanelService() {
        return this.panelService;
    }

    ngOnInit(): void {
    }

    onApplyFilters(): void {
        this.panelService.FiltersChange.next({});
    }

    onImportDateChange(): void {
        this.panelService.Filters.importDate.next(this.panelService.selectedImportDate);
        this.unselectAllBuildings();
    }

    onPortfolioChange(): void {
        this.panelService.Filters.portfolio.next(this.panelService.selectedPortfolio);
        this.unselectAllBuildings();
    }

    onFundChange(): void {
        this.panelService.Filters.fund.next(this.panelService.selectedFund);
        this.unselectAllBuildings();
    }

    onAssetManagerChange(): void {
        this.panelService.Filters.assetManager.next(this.panelService.selectedAssetManager);
        this.unselectAllBuildings();
    }

    onBusinessUnitChange(): void {
        this.panelService.Filters.businessUnit.next(this.panelService.selectedBusinessUnit);
        this.unselectAllBuildings();
    }

    onFeeCenterChange(): void {
        this.panelService.Filters.feeCenter.next(this.panelService.selectedFeeCenter);
        this.unselectAllBuildings();
    }

    onAgencyChange(): void {
        this.panelService.Filters.agency.next(this.panelService.selectedAgency);
        this.unselectAllBuildings();
    }

    onPropertyManagerChange(): void {
        this.panelService.Filters.propertyManager.next(this.panelService.selectedPropertyManager);
        this.unselectAllBuildings();
    }

    onNeutralizationChange(): void {
        this.panelService.Filters.neutralization.next(this.panelService.selectedNeutralization);
        this.unselectAllBuildings();
    }

    onOwnersChange(): void {
        this.panelService.Filters.owners.next(this.panelService.selectedOwners);
        this.unselectAllBuildings();
    }

    onGeographicalZonesChange(): void {
        this.panelService.Filters.geographicalZones.next(this.panelService.selectedGeographicalZone);
        this.unselectAllBuildings();
    }

    onBuildingNaturesChange(): void {
        this.panelService.Filters.buildingNatures.next(this.panelService.selectedBuildingNature);
        this.unselectAllBuildings();
    }

    onBuildingsChange(): void {
        this.panelService.Filters.buildings.next(this.panelService.selectedBuildings);
    }

    onFundsScroll(start: number, end: number): void {
        this.panelService.updateFundsBuffer(start, end);
    }

    getBadge(availableList: any[], selectedList: any[]): string {
        return selectedList.length == 0 || selectedList.length == availableList.length ? this.translatePipe.transform('lbl-all') : String(selectedList.length);
    }

    getImportDateBadge(): string | undefined {
        return this.panelService.availableImportDates.find(i => i.importDate == this.panelService.selectedImportDate)?.importDateLabel;
    }

    getPortfolioBadge(): string {
        let selected = this.panelService.availablePortfolios.find(i => i.portfolio_code == this.panelService.selectedPortfolio)?.portfolio_label;
        if (selected === undefined)
            selected = this.translatePipe.transform('lbl-all') as string;
        return selected;
    }

    getFundsBadge(): string {
        let selected = this.panelService.availableFunds.find(i => i.EXTERNAL_FUND_CODE == this.panelService.selectedFund)?.EXTERNAL_FUND;
        if (selected === undefined)
            selected = this.translatePipe.transform('lbl-all') as string;
        return selected;
    }

    getAssetManagersBadge(): string {
        let selected = this.panelService.availableAssetManagers.find(i => i.id_asset_manager == this.panelService.selectedAssetManager)?.asset_manager_name;
        if (selected === undefined)
            selected = this.translatePipe.transform('lbl-all') as string;
        return selected;
    }

    getBusinessUnitsBadge(): string {
        let selected = this.panelService.availableBusinessUnits.find(i => i.business_unit_code == this.panelService.selectedBusinessUnit)?.business_unit_name;
        if (selected === undefined)
            selected = this.translatePipe.transform('lbl-all') as string;
        return selected;
    }

    getFeeCenterBadge(): string {
        let selected = this.panelService.availableFeeCenters.find(i => i.fee_center_code == this.panelService.selectedFeeCenter)?.fee_name;
        if (selected === undefined)
            selected = this.translatePipe.transform('lbl-all') as string;
        return selected;
    }

    getPropertyManagerBadge(): string {
        let selected = this.panelService.availablePropertyManagers.find(i => i.id_property_manager == this.panelService.selectedPropertyManager)?.property_manager_name;
        if (selected === undefined)
            selected = this.translatePipe.transform('lbl-all') as string;
        return selected;
    }

    getNeutralizationBadge(): string {
        let selected = this.panelService.availableNeutralizations.find(i => i.neutralization_code == this.panelService.selectedNeutralization)?.neutralization_label;
        if (selected === undefined)
            selected = this.translatePipe.transform('lbl-all') as string;
        return selected;
    }


    selectAllBuildings(): void {
        this.panelService.selectedBuildings = this.panelService.availableBuildings.map(b => b.id_building);
        this.onBuildingsChange();
    }

    unselectAllBuildings(): void {
        this.panelService.selectedBuildings = [];
        this.onBuildingsChange();
    }

    selectAllGeoZones(): void {
        this.panelService.selectedGeographicalZone = this.panelService.availableGeographicalZones.map(g => g.GEOGRAPHICAL_ZONE);
        this.onGeographicalZonesChange();
    }

    unselectAllGeoZones(): void {
        this.panelService.selectedGeographicalZone = [];
        this.onGeographicalZonesChange();
    }

    selectAllBuildingNatures(): void {
        this.panelService.selectedBuildingNature = this.panelService.availableBuildingNatures.map(g => g.building_nature_code);
        this.onBuildingNaturesChange();
    }

    unselectAllBuildingNatures(): void {
        this.panelService.selectedBuildingNature = [];
        this.onBuildingNaturesChange();
    }

    selectAllOwners(): void {
        this.panelService.selectedOwners = this.panelService.availableOwners.map(g => g.ID_OWNER);
        this.onOwnersChange();
    }

    unselectAllOwners(): void {
        this.panelService.selectedOwners = [];
        this.onOwnersChange();
    }
}
