import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LibHttpClientApiService } from '@herdia-common';
import { AlertAddParams, AlertParams, AlertResult, AlertUpdateParams } from './lib-card-alert.interfaces';


export enum AlertUrls {
  GET_ALERT = "api/alhambra/Alert/GetAlert",
  INSERT_ALERT = "api/alhambra/Alert/InsertAlert",
  DELETE_ALERT = "api/alhambra/Alert/DeleteAlert",
  UPDATE_ALERT = "api/alhambra/Alert/UpdateAlert"
}

@Injectable({
  providedIn: 'root'
})
export class LibCardAlertService {

  constructor(private apiSvc: LibHttpClientApiService) { }

  getAlert(params: AlertParams): Observable<AlertResult[]> | null {
    return this.apiSvc.post<AlertParams, AlertResult[]>(AlertUrls.GET_ALERT, params);
  }

  deleteAlert(params: AlertParams): Observable<any> | null {
    return this.apiSvc.post<AlertParams, any>(AlertUrls.DELETE_ALERT, params);
  }

  insertAlert(params: AlertAddParams): Observable<any> | null {
    return this.apiSvc.post<AlertAddParams, any>(AlertUrls.INSERT_ALERT, params);
  }

  updateAlert(params: AlertUpdateParams): Observable<any> | null {
    return this.apiSvc.post<AlertUpdateParams, any>(AlertUrls.UPDATE_ALERT, params);
  }
}
