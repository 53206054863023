<div *ngFor="let row of haPageVM.HAPageRows; index as i">
  <div class="rowContent">
    <div *ngIf="!editMode" class="cadre border">
      <button type="button" class="btn btn-primary" (click)="animBtn(i, $event)">
        <div class="wrap">
          <span [ngClass]="GetClassAnimBtn(i)" class="arrow">
            <span></span>
            <span></span>
          </span>
        </div>
      </button>
    </div>
    <app-rows [haPageVM]="haPageVM" [row]="row" (rowChange)="getRows()" id="{{'row_'+i}}" attr.row-id="{{row.Id}}" attr.row-order="{{row.Order}}"></app-rows>
  </div>
</div>
<div *ngIf="editMode">
  <button type="button" class="btn btn-outline-primary btn-block" (click)="addRow(pageId)">Ajouter une ligne</button>
</div>
