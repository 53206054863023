import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isValid, parse } from 'date-fns';
import { PropertyOption } from '../../lib-card-generic-crud/lib-card-generic-crud.enums';
import { PropertyDefinition } from '../../lib-card-generic-crud/lib-card-generic-crud.interface';
import { CustomAdapter, CustomDateParserFormatter } from '../lib-property-type-input.service';


@Component({
    selector: 'lib-property-type-input',
    templateUrl: './lib-property-type-input.component.html',
    styleUrls: ['./lib-property-type-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LibPropertyTypeInputComponent),
            multi: true,
        },
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    ]
})
export class LibPropertyTypeInputComponent implements OnInit, ControlValueAccessor {
    @Input() showLabel: boolean = true;
    @Input() propertyLabel: string = "";

    private propertyDef!: PropertyDefinition;
    @Input() set propertyDefinition(value: PropertyDefinition) {
        this.propertyDef = value;

        this.isPropertyNullable = this.propertyDefinition.Options.indexOf(PropertyOption.IsNullable) !== -1;
        this.isPropertyForeignKey = this.propertyDefinition.Options.indexOf(PropertyOption.IsForeignKey) !== -1;
        this.propertyIsString = this.inputTextTypes.indexOf(this.propertyDefinition.Type) !== -1;
        this.propertyIsNumber = this.inputNumberTypes.indexOf(this.propertyDefinition.Type) !== -1;
        this.propertyIsBoolean = this.inputCheckboxType.indexOf(this.propertyDefinition.Type) !== -1;
        this.propertyIsDate = this.inputCalendarType.indexOf(this.propertyDefinition.Type) !== -1;

        this.ngOnInit();
    }
    get propertyDefinition(): PropertyDefinition {
        return this.propertyDef;
    }


    propertyValue: string = "";
    @Input() set inputValue(value: string) {
      this.propertyValue = value;
      this.isInError = this.checkErrorState();
      if (!this.isInError) {
        this.onInputUpdated(this.propertyValue);
      }
    }
    get inputValue(): string {
        return this.propertyValue;
    }

    @Output() inputValueChange = new EventEmitter<string>();
    @Output() inputUpdated = new EventEmitter<any>();

    onChange!: (value: string) => void;
    onTouched!: () => void;

    inputNumberTypes: string[] = ["int", "Int32", "Int32?", "decimal", "decimal?", "Decimal", "Decimal?"];
    inputTextTypes: string[] = ["string?", "string", "String", "String?", "JsonString", "char", "char?"];
    inputCheckboxType: string[] = ["bool", "Boolean", "boolean"];
    inputCalendarType: string[] = ["Datetime", "Datetime?", "Date", "Date?", "DateTime", "DateTime?"];

    isPropertyNullable!: boolean;
    isPropertyForeignKey!: boolean;
    propertyIsNumber !: boolean;
    propertyIsString !: boolean;
    propertyIsBoolean !: boolean;
    propertyIsDate !: boolean;

    isInError = false;
    model!: NgbDateStruct;
    date!: { year: number; month: number };

    constructor(private calendar: NgbCalendar) { }
    writeValue(obj: string): void {
      this.propertyValue = obj;
    }
    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onClick(): void {
      this.onTouched();
    }

    ngOnInit(): void {
      this.isInError = this.checkErrorState();
    }

    onInputUpdated(targetValue: any): void {
        if (!this.isInError) {
            this.inputUpdated.emit(targetValue);
        }

        if (this.onChange && typeof targetValue !== 'object')
            this.onChange(targetValue);
    }

    checkErrorState(): boolean {
        if (!this.propertyDefinition)
            return false;
        switch (this.propertyDefinition.Type) {
            case "Int32":
                if (!this.isPropertyNullable) {
                    return isNaN(Number(this.inputValue));
                } else {
                    return isNaN(Number(this.inputValue)) || this.inputValue === "";
                }
            case "DateTime":
            case "DateTime?":
                try {
                    const parsedDate = parse(this.inputValue, "yyyy-MM-dd", new Date());
                    return !isValid(parsedDate);
                }
                catch (e) {
                    return true;
                }
            case "JsonString":
                try {
                    JSON.parse(this.inputValue);
                    return false;
                }
                catch (e) {
                    return true;
                }
            default:
                return false;
        }
    }
}
