import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

type EventBusSubjectName =
    | 'AddEntity'
    | 'AlertConfigChanged'
    | 'AskExport_Datatable'
    | 'AskExport_GenericCrud'
    | 'AskImportCSV'
    | 'CardFilterChange'
    | 'HideDisplaySearchColumns'
    | 'PaginationInfoUpdated'
    | 'ParamDestinationUpdated'
    | 'RequestLinePerPage'
    | 'RequestPageNumber'
    | 'SaveAlertConfiguration'
    | 'ProfileSubject'
    | 'TimelineSubject'
    | 'SearchEntity'
    | 'ShowFilter'
    | 'ShowDisplaySearchColumns'
    | 'ToggleColumnVisibility_Datatable' // LibHerdiaDatatableComponent / LibHerdiaDatatableColumnSelectorButtonComponent
    | 'ToggleColumnVisibility_GenericCrud'
    | 'ParameterDateChangedReport'
    | 'ParameterDateChangedChart'
    | 'ImportDateChanged'

@Injectable({
  providedIn: 'root'
})
export class LibDynamicComponentsEventBusService {

  cardSubjects: { [key: string]: Subject<any> } = {};
  subjectAdded: Subject<string> = new Subject();
  private subjectName: string = "";

  addSubject(name: EventBusSubjectName, cardId: number, subject: Subject<any>) {
    this.subjectName = name + "_" + cardId;
    if (this.cardSubjects[this.subjectName] === undefined) {
      this.cardSubjects[this.subjectName] = subject;
      this.subjectAdded.next(this.subjectName);
    }
  }

}
