export enum UserUrls {
  LOGIN = 'api/AuthManagement/Login',
  REFRESH_TOKEN = 'api/AuthManagement/RefreshToken'
}

export enum TranslationUrl {
  GET_TRANSLATION_HASH = 'GetTranslation/GetTranslationHash',
  GET_TRANSLATION_DATAS = 'GetTranslation/GetTranslationDatas'
}

export enum CachingDataUrl {
  GET_IMPORT_DATE_LIST_DATAS = 'GetImportDateList/GetImportDateListDatas',
  GET_PORTFOLIOS_LIST_DATAS = 'GetPortfoliosList/GetPortfoliosListDatas',
  GET_EXTERNAL_FUND_LIST_DATAS = 'GetExternalFundList/GetExternalFundListDatas',
  GET_ASSET_MANAGER_LIST_DATAS = 'GetAssetManagerList/GetAssetManagerListDatas',
  GET_BUSINESS_UNIT_LIST_DATAS = 'GetBusinessUnitList/GetBusinessUnitListDatas',
  GET_FEE_CENTER_LIST_DATAS = 'GetFeeCenterList/GetFeeCenterListDatas',
  GET_MANAGER_LIST_DATAS = 'GetManagerList/GetManagerListDatas',
  GET_AGENCY_LIST_DATAS = 'GetAgencyList/GetAgencyListDatas',
  GET_OWNERS_LIST_DATAS = 'GetOwnersList/GetOwnersListDatas'
}

export enum PageUrls {
  GET_PAGE = 'api/UIPage/GetPage',
  GET_PAGES = 'api/UIPage/GetPages',
  INDEX = 'api/UIPage/Index',
  ADD = 'api/UIPage/AddPage',
  DELETE = 'api/UIPage/DeletePage',
  DUPLICATE = 'api/UIPage/DuplicatePage',
  EDIT = 'api/UIPage/EditPage',
  EXPORT = 'api/UIPage/ExportPageConfiguration',
  IMPORT = 'api/UIPage/ImportPageConfiguration'
}

export enum HomeUrls {
  ADD_ROW = 'api/UIMain/AddRow',
  REMOVE_ROW = 'api/UIMain/RemoveRow'
}

export enum RowUrls {
  ADD_ROW = 'api/UIRow/AddRow',
  DELETE_ROW = 'api/UIRow/DeleteRow',
  GET_ROWS = 'api/UIRow/GetRows',
  UPDATE_ORDER_ROWS = 'api/UIRow/UpdateRowOrder'
}

export enum CardUrls {
  ADD = 'api/UICard/AddCard',
  GET = 'api/UICard/CardContent',
  UPDATE = 'api/UICard/UpdateCard',
  DELETE = 'api/UICard/DeleteCard',
  GET_MAX_WIDTH = 'api/UICard/CardMaxWidth',
  SAVE_CONFIG = 'api/UICard/SaveCardConfiguration',
  UPDATE_CONFIG = 'api/UICard/UpdateCardConfiguration',
  EXPORT_CONFIG = 'api/UICard/ExportCardConfiguration',
  IMPORT_CONFIG = 'api/UICard/ImportCardConfiguration',
  GET_CARDS = 'api/UICard/GetCards',
  GET_PREDEFINED_CARDS = 'api/UICard/GetPredefinedCards',
  ADD_PREDEFINED_CARD= 'api/UICard/AddPredefinedCard',
  UPDATE_ORDER_CARDS = 'api/UICard/UpdateCardOrder'
}

export enum CategoryUrls {
  GET = 'api/UICategory/GetCategories',
  ADD = 'api/UICategory/AddCategory',
  UPDATE = 'api/UICategory/UpdateCategory',
  DELETE = 'api/UICategory/RemoveCategory'
}

export enum EntityCRUDUrls {
  GET_CONTEXTS = 'api/EntityCRUD/GetContexts',
  GET_ENTITIES = 'api/EntityCRUD/GetEntities',
  GET_ENTITY = 'api/EntityCRUD/GetEntity',
  CREATE = 'api/EntityCRUD/Create',
  READ = 'api/EntityCRUD/Read',
  UPDATE = 'api/EntityCRUD/Update',
  DELETE = 'api/EntityCRUD/Delete'
}

export enum ApplicationFeatureUrls {
  GET_FEATURES = 'api/ApplicationFeatures/Get'
}

export enum ApplicationSpecificPropertiesUrls {
  GET_FEATURES = 'api/ApplicationSpecificProperties/GetFeatures',
  GET_APPLICATION_NAME = 'api/ApplicationSpecificProperties/GetApplicationName',
  GET_APPLICATION_ICON = 'api/ApplicationSpecificProperties/GetApplicationIcon',
  GET_APPLICATION_FAVICON = 'api/ApplicationSpecificProperties/GetApplicationFavIcon',
  GET_APPLICATION_BACKGROUND = 'api/ApplicationSpecificProperties/GetApplicationBackgroundLogin',
  GET_HERDIAAPP_API_BUILDID = 'api/ApplicationSpecificProperties/GetHerdiaAppAPIBuildId',
  GET_APPLICATION_DEFAULT_THEME = 'api/ApplicationSpecificProperties/GetApplicationDefaultTheme',
  GET_APPLICATION_RIGHTPANEL_PACKAGE_NAME = 'api/ApplicationSpecificProperties/GetApplicationRightPanelPackageName',
  GET_APPLICATION_USER_PROPERTIES = 'api/ApplicationSpecificProperties/GetApplicationUserProperties',
}

export enum RoleUrls {
  GET_ALL = 'api/Role/GetAll',
  ADD = 'api/Role/AddRole',
  UPDATE = 'api/Role/UpdateRole',
  DELETE = 'api/Role/DeleteRole',
  GET_CATEGORIES = 'api/Role/Categories',
  SAVE_CATEGORIES = 'api/Role/UpdateRoleActions',
  ADD_ACTIONS_MISSING = 'api/Role/AddActionsMissing',
  GET_ALL_INFORMATIONS = 'api/Role/GetAllRolesAndPagesAndRelationBetween',
  ADD_REMOVE_VISIBILITY = 'api/Role/AddOrRemoveRoleViewOnPage',
  INSERT_VIEW_PAGE_ROLE = 'api/Role/InsertViewPageRole',
  GET_ALL_PAGES_ROLES = 'api/Role/GetAllPagesWithRoles'
}

export enum UploadUrls {
  ADD = "api/HAUpload/UploadFile",
  DELETE = "api/HAUpload/DeleteFile",
  GET = "api/HAUpload/GetFile",
  GET_ALL = "api/HAUpload/GetAllFiles",
  EXPORT = "api/HAUpload/ExportZip",
  IMPORT = "api/HAUpload/ImportZip"
}

export enum TaskSchedulerCRUDUrls {
  GET_JOBS = 'api/TaskScheduler/GetJobs',
  GET_JOB = 'api/TaskScheduler/GetJob',
  DELETE = 'api/TaskScheduler/Delete',
  CREATE_UPDATE = 'api/TaskScheduler/CreateOrUpdate',
  GET_CLASS_JOBS = 'api/TaskScheduler/GetAllClassJobs'
}

export enum AppEmailManager {
  GET_ALL_EMAIL = "api/EmailTemplateCrudApp/GetAllAppEmailTemplate",
  GET_CONTENT_TEMPLATE = "api/EmailTemplateCrudApp/GetContentEmailTemplateApp/",
  UPDATE_CONTENT_TEMPLATE = "api/EmailTemplateCrudApp/UpdateContentEmailTemplateApp",
  GET_DESCRIPTION_VARIABLES_TEMPLATES = "api/EmailTemplateCrudApp/GetDescriptionVariablesTemplates",
  SEND_EMAIL_FOR_TEST = "api/EmailTemplateCrudApp/SendEmailForTestTemplateUser",
}

export enum UserEmailManager {
  GET_ALL_USER_EMAIL_TEMPLATE = "api/EmailTemplateCrudUser/GetAllUserEmailTemplate",
  DELETE_USER_EMAIL_TEMPLATE = "api/EmailTemplateCrudUser/DeletUserEmailTemplate/",
  ADD_USER_EMAIL_TEMPLATE = "api/EmailTemplateCrudUser/AddUserEmailTemplate",
  UPDATE_USER_EMAIL_TEMPLATE = "api/EmailTemplateCrudUser/UpdateContentEmailTemplateUser",
  GET_USER_EMAIL_CONTENT = "api/EmailTemplateCrudUser/GetContentEmailTemplateUser/",
  SEND_EMAIL_FOR_TEST = "api/EmailTemplateCrudUser/SendEmailForTestTemplateUser",
  GET_DESCRIPTION_VARIABLES_TEMPLATES = "api/EmailTemplateCrudUser/GetDescriptionVariablesTemplates"
}
