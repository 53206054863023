import { Injectable } from '@angular/core';
import { CategoryUrls } from './api.service';
import { HACategory, AddCategoryRequest, UpdateCategoryRequest, DeleteCategoryRequest } from '../models/interfaces';
import { Observable, Subject } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddCategoryModalComponent } from '../../add-category-modal/add-category-modal.component';
import { LibHttpClientApiService } from '@herdia-common';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private categorySource = new Subject<any>();
  private categorySendSource = new Subject<any>();
  public newCategorySubject = new Subject();

  category$ = this.categorySource.asObservable();
  categorySend$ = this.categorySendSource.asObservable();

  modalRef!: NgbModalRef;

  constructor(private apiSvc: LibHttpClientApiService, private modalService: NgbModal) { }

  GetCategories(): Observable<HACategory[]> {
    return this.apiSvc.get<HACategory[]>(CategoryUrls.GET);
  }

  addCategory(uiCategoryRequest: AddCategoryRequest): void {
    this.apiSvc.post<AddCategoryRequest, HACategory[]>(CategoryUrls.ADD, uiCategoryRequest)?.subscribe((c) => {
      this.newCategorySubject.next(true);
      this.modalRef.close();
    });
  }

  updateCategory(uiCategoryRequest: UpdateCategoryRequest): Observable<HACategory[]> | null {
    return this.apiSvc.put<UpdateCategoryRequest, HACategory[]>(CategoryUrls.UPDATE, uiCategoryRequest);
  }

  deleteCategory(uiCategoryRequest: DeleteCategoryRequest): Observable<HACategory[]> | null {
    return this.apiSvc.delete<DeleteCategoryRequest, HACategory[]>(`${CategoryUrls.DELETE}/${uiCategoryRequest.id}`, uiCategoryRequest);
  }

  sendCategory(uiCategories: HACategory[]) {
    if (uiCategories.length === 0) {
      this.GetCategories().subscribe((returnCategories: HACategory[]) => {
        this.categorySendSource.next(returnCategories)
      });
    } else {
      this.categorySendSource.next(uiCategories);
    }
  }

  openAddModal(): void {
    this.modalRef = this.modalService.open(AddCategoryModalComponent);
  }

  closeModal(): void {

  }

}
