<li class='list-group-item d-inline-flex align-items-center w-100 container'>
    <div
        class="col"
        style="max-width: 12rem;"
    >
        <label>{{parameterNameTranslated}}</label>
    </div>
    <div
        class="col form-check"
        style="max-width: 6rem;"
    >
        <input
            type="checkbox"
            id="ParameterIsNullable"
            class="form-check-input"
            [(ngModel)]="parameterBinding.Nullable"
        />
        <label
            class="form-check-label"
            for="ParameterIsNullable"
        >Nullable</label>
    </div>
    <div class="container col d-inline-flex align-items-center">
        <div
            *ngIf="this.displayStoredProcedure"
            class="col"
        >
            <ng-select
                [items]="availableBindingType"
                [(ngModel)]="parameterBinding.Type"
                (change)="updateParameterBindingType($event)"
                bindValue="key"
                bindLabel="value"
                class="auto-grow"
            ></ng-select>
        </div>

        <div
            *ngIf="parameterBinding.Type === 'raw' && !(parameterName == 'IdBuildingList') && this.allowRawBinding"
            class="col"
        >
            <lib-property-type-input
                [showLabel]="false"
                [propertyLabel]="parameterLabel"
                [propertyDefinition]="getPropertyDefinition()"
                [inputValue]="this.parameter['ParameterBinding'].Value"
                (inputUpdated)="onPropertyInputUpdated($event)"
            >
            </lib-property-type-input>
        </div>
        <div
            *ngIf="parameterBinding.Type === 'raw' && parameterName == 'IdBuildingList' && this.allowRawBinding"
            class="col"
        >
            <lib-specific-param-report-config
                [buildingsSelected]="parameterBinding.Value"
                (inputUpdated)="onPropertyInputUpdated($event)"
            ></lib-specific-param-report-config>
        </div>
        <div
            *ngIf="parameterBinding.Type === 'cardFilter' && this.allowCardBinding"
            class="col"
        >
            <div class="container d-inline-flex align-items-center">
                <div
                    class="col"
                >
                    <ng-select
                        [items]="availableCardFilterBindingCategory"
                        [(ngModel)]="parameterBinding.CardFilterBindingCategory"
                        bindValue="key"
                        bindLabel="value"
                        class="auto-grow"
                    ></ng-select>
                </div>

                <div
                    *ngIf="dateTypes.indexOf(parameterType) !== -1 && parameterBinding.CardFilterBindingCategory =='raw'"
                    class="container d-inline-flex align-items-center"
                >
                    <div class="col">
                        <ng-select
                            [items]="availableCardFilterDateType"
                            [(ngModel)]="parameterBinding.Value['InitDate']"
                            bindValue="key"
                            bindLabel="value"
                            class="auto-grow"
                        ></ng-select>
                    </div>
                    <div class="col">
                        <ng-select
                            [items]="availableCardFilterDateOperator"
                            [(ngModel)]="parameterBinding.Value['Operator']"
                            bindValue="key"
                            bindLabel="value"
                            class="auto-grow"
                        ></ng-select>
                    </div>
                    <div class="col">
                        <input
                            type="number"
                            [(ngModel)]="parameterBinding.Value['Operand']"
                            class="form-control"
                            style="width: 5rem;"
                        >
                    </div>
                    <div class="col">
                        <ng-select
                            [items]="availableCardFilterDateOperandMultiplier"
                            [(ngModel)]="parameterBinding.Value['Multiplier']"
                            bindValue="key"
                            bindLabel="value"
                            class="auto-grow"
                        ></ng-select>
                    </div>
                </div>
                <div
                    *ngIf="parameterBinding.CardFilterBindingCategory =='knowFilter'"
                    class="container d-inline-flex align-items-center"
                >
                    <div class="col">
                        <ng-select
                          [items]="availableFilters"
                          [(ngModel)]="parameterBinding.Value"
                          bindValue="key"
                          bindLabel="value"
                          class="auto-grow"
                        ></ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="parameterBinding.Type === 'pageFilter' && this.allowPageBinding"
            class="col"
        >
            <ng-select
                [items]="availableFilters"
                [(ngModel)]="parameterBinding.Value"
                bindValue="key"
                bindLabel="value"
                class="auto-grow"
            ></ng-select>
        </div>
    </div>
</li>
