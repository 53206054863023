<div
    [class.full-screen-modal]="this.maximizedModal"
    class="container"
>
    <div class="modal-header">
        <h4 class="modal-title"><lib-translate [key]="'app-card-configuration'"></lib-translate></h4>
        <div
            class="btn-group"
            role="group"
            aria-label="Basic example"
        >
            <button
                *ngIf="!this.maximizedModal"
                type="button"
                class="btn"
                (click)="fullscreenModal()"
            ><fa-icon
                    icon="expand"
                    size="lg"
                ></fa-icon></button>
            <button
                *ngIf="this.maximizedModal"
                type="button"
                class="btn"
                (click)="fullscreenModal()"
            ><fa-icon
                    icon="compress"
                    size="lg"
                ></fa-icon></button>
            <button
                type="button"
                class="btn"
                aria-label="Close"
                (click)="activeModal.dismiss()"
            ><fa-icon
                    icon="xmark"
                    size="lg"
                ></fa-icon></button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-3 menu-container">
                <ul
                    ngbNav
                    #nav="ngbNav"
                    class="nav-pills"
                    orientation="vertical"
                >
                    <li [ngbNavItem]="1">
                        <a
                            ngbNavLink
                            (click)="toggleChildren('app-card-configuration-general')"
                        ><lib-translate [key]="'app-card-configuration-general'"></lib-translate></a>
                        <ng-template ngbNavContent>
                            <div class="form-group">
                                <label><lib-translate [key]="'lbl-icon'"></lib-translate></label>
                                <lib-icon-picker
                                    [iconOrigin]="card.Configuration['icon']"
                                    (iconSelectedOutput)="onIconPickerSelect($event)"
                                ></lib-icon-picker>
                            </div>
                            <div class="form-group">
                                <label for="cardTitle">{{cardTitleLabel}}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="cardTitle"
                                    placeholder="{{cardTitleLabel}}"
                                    [(ngModel)]="card.Title"
                                >
                            </div>
                            <div class="form-group">
                                <label><lib-translate
                                        [key]="'app-card-configuration-width'"></lib-translate>:&nbsp;{{card.Width}}</label>
                                <input
                                    type="range"
                                    name="width"
                                    id="cardWidth"
                                    min="{{cardInterface.minWidth}}"
                                    max="12"
                                    value="6"
                                    step="1"
                                    [(ngModel)]="card.Width"
                                >
                            </div>
                        </ng-template>
                    </li>
                    <ng-container *ngFor="let node of cardInterface.configTreeNode;let i=index">
                        <li [ngbNavItem]="i+2">
                            <a
                                ngbNavLink
                                (click)="toggleChildren(node.key)"
                            >
                                <lib-translate [key]="node.label"></lib-translate>
                            </a>
                            <ng-template ngbNavContent>
                                <app-dynamic-component
                                    [selector]="node.key"
                                    [inputs]="{ config: card.Configuration, id: card.Id }"
                                ></app-dynamic-component>
                            </ng-template>
                        </li>
                        <ng-container *ngIf="configNodeStates[node.key]">
                            <li
                                *ngFor="let childnode of node.children;let j=index"
                                [ngbNavItem]="i+2+j+1"
                                class="ml-3 {{node.key}}_child"
                            >
                                <a ngbNavLink>
                                    <lib-translate [key]="childnode.label"></lib-translate>
                                </a>
                                <ng-template ngbNavContent>
                                    <app-dynamic-component
                                        [selector]="childnode.key"
                                        [inputs]="{ config: card.Configuration, id: card.Id }"
                                    ></app-dynamic-component>
                                </ng-template>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </div>
            <div
                [ngbNavOutlet]="nav"
                class="nav-outlet col"
            ></div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-danger mr-auto"
            (click)="onDelete()"
        ><lib-translate [key]="'app-btn-delete'"></lib-translate></button>
        <button
            type="button"
            class="btn btn-secondary"
            (click)="onSave()"
        ><lib-translate [key]="'app-btn-save'"></lib-translate></button>
        <button
            type="button"
            class="btn btn-secondary mr-auto"
            (click)="onExport()"
        ><lib-translate [key]="'app-btn-export'"></lib-translate></button>
        <button
            type="button"
            class="btn btn-secondary"
            (click)="onCancel()"
        ><lib-translate [key]="'app-btn-close'"></lib-translate></button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="onValidate()"
        ><lib-translate [key]="'app-btn-validate'"></lib-translate></button>
    </div>
</div>
