import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lib-icon-picker',
  templateUrl: './lib-icon-picker.component.html',
  styleUrls: [ './lib-icon-picker.component.scss' ]
})
export class LibIconPickerComponent implements OnInit {
  @Input() iconOrigin = '';
  
  availableIconNames: string[] = [];
  allIconNames: string[] = [
    'monero' ,
    'hooli' ,
    'yelp' ,
    'cc-visa' ,
    'lastfm' ,
    'shopware' ,
    'creative-commons-nc' ,
    'aws' ,
    'redhat' ,
    'yoast' ,
    'cloudflare' ,
    'ups' ,
    'wpexplorer' ,
    'dyalog' ,
    'bity' ,
    'stackpath' ,
    'buysellads' ,
    'first-order' ,
    'modx' ,
    'guilded' ,
    'vnv' ,
    'square-js' ,
    'js-square' ,
    'microsoft' ,
    'qq' ,
    'orcid' ,
    'java' ,
    'invision' ,
    'creative-commons-pd-alt' ,
    'centercode' ,
    'glide-g' ,
    'drupal' ,
    'hire-a-helper' ,
    'creative-commons-by' ,
    'unity' ,
    'whmcs' ,
    'rocketchat' ,
    'vk' ,
    'untappd' ,
    'mailchimp' ,
    'css3-alt' ,
    'square-reddit' ,
    'reddit-square' ,
    'vimeo-v' ,
    'contao' ,
    'square-font-awesome' ,
    'deskpro' ,
    'sistrix' ,
    'square-instagram' ,
    'instagram-square' ,
    'battle-net' ,
    'the-red-yeti' ,
    'square-hacker-news' ,
    'hacker-news-square' ,
    'edge' ,
    'napster' ,
    'square-snapchat' ,
    'snapchat-square' ,
    'google-plus-g' ,
    'artstation' ,
    'markdown' ,
    'sourcetree' ,
    'google-plus' ,
    'diaspora' ,
    'foursquare' ,
    'stack-overflow' ,
    'github-alt' ,
    'phoenix-squadron' ,
    'pagelines' ,
    'algolia' ,
    'red-river' ,
    'creative-commons-sa' ,
    'safari' ,
    'google' ,
    'square-font-awesome-stroke' ,
    'font-awesome-alt' ,
    'atlassian' ,
    'linkedin-in' ,
    'digital-ocean' ,
    'nimblr' ,
    'chromecast' ,
    'evernote' ,
    'hacker-news' ,
    'creative-commons-sampling' ,
    'adversal' ,
    'creative-commons' ,
    'watchman-monitoring' ,
    'fonticons' ,
    'weixin' ,
    'shirtsinbulk' ,
    'codepen' ,
    'git-alt' ,
    'lyft' ,
    'rev' ,
    'windows' ,
    'wizards-of-the-coast' ,
    'square-viadeo' ,
    'viadeo-square' ,
    'meetup' ,
    'centos' ,
    'adn' ,
    'cloudsmith' ,
    'pied-piper-alt' ,
    'square-dribbble' ,
    'dribbble-square' ,
    'codiepie' ,
    'node' ,
    'mix' ,
    'steam' ,
    'cc-apple-pay' ,
    'scribd' ,
    'openid' ,
    'instalod' ,
    'expeditedssl' ,
    'sellcast' ,
    'square-twitter' ,
    'twitter-square' ,
    'r-project' ,
    'delicious' ,
    'freebsd' ,
    'vuejs' ,
    'accusoft' ,
    'ioxhost' ,
    'fonticons-fi' ,
    'app-store' ,
    'cc-mastercard' ,
    'itunes-note' ,
    'golang' ,
    'kickstarter' ,
    'grav' ,
    'weibo' ,
    'uncharted' ,
    'firstdraft' ,
    'square-youtube' ,
    'youtube-square' ,
    'wikipedia-w' ,
    'wpressr' ,
    'rendact' ,
    'angellist' ,
    'galactic-republic' ,
    'nfc-directional' ,
    'skype' ,
    'joget' ,
    'fedora' ,
    'stripe-s' ,
    'meta' ,
    'laravel' ,
    'hotjar' ,
    'bluetooth-b' ,
    'sticker-mule' ,
    'creative-commons-zero' ,
    'hips' ,
    'behance' ,
    'reddit' ,
    'discord' ,
    'chrome' ,
    'app-store-ios' ,
    'cc-discover' ,
    'wpbeginner' ,
    'confluence' ,
    'mdb' ,
    'dochub' ,
    'accessible-icon' ,
    'ebay' ,
    'amazon' ,
    'unsplash' ,
    'yarn' ,
    'square-steam' ,
    'steam-square' ,
    '500px' ,
    'square-vimeo' ,
    'vimeo-square' ,
    'asymmetrik' ,
    'font-awesome' ,
    'font-awesome-flag' ,
    'font-awesome-logo-full' ,
    'gratipay' ,
    'apple' ,
    'hive' ,
    'gitkraken' ,
    'keybase' ,
    'apple-pay' ,
    'padlet' ,
    'amazon-pay' ,
    'square-github' ,
    'github-square' ,
    'stumbleupon' ,
    'fedex' ,
    'phoenix-framework' ,
    'shopify' ,
    'neos' ,
    'hackerrank' ,
    'researchgate' ,
    'swift' ,
    'angular' ,
    'speakap' ,
    'angrycreative' ,
    'y-combinator' ,
    'empire' ,
    'envira' ,
    'square-gitlab' ,
    'gitlab-square' ,
    'studiovinari' ,
    'pied-piper' ,
    'wordpress' ,
    'product-hunt' ,
    'firefox' ,
    'linode' ,
    'goodreads' ,
    'square-odnoklassniki' ,
    'odnoklassniki-square' ,
    'jsfiddle' ,
    'sith' ,
    'themeisle' ,
    'page4' ,
    'hashnode' ,
    'react' ,
    'cc-paypal' ,
    'squarespace' ,
    'cc-stripe' ,
    'creative-commons-share' ,
    'bitcoin' ,
    'keycdn' ,
    'opera' ,
    'itch-io' ,
    'umbraco' ,
    'galactic-senate' ,
    'ubuntu' ,
    'draft2digital' ,
    'stripe' ,
    'houzz' ,
    'gg' ,
    'dhl' ,
    'square-pinterest' ,
    'pinterest-square' ,
    'xing' ,
    'blackberry' ,
    'creative-commons-pd' ,
    'playstation' ,
    'quinscape' ,
    'less' ,
    'blogger-b' ,
    'opencart' ,
    'vine' ,
    'paypal' ,
    'gitlab' ,
    'typo3' ,
    'reddit-alien' ,
    'yahoo' ,
    'dailymotion' ,
    'affiliatetheme' ,
    'pied-piper-pp' ,
    'bootstrap' ,
    'odnoklassniki' ,
    'nfc-symbol' ,
    'ethereum' ,
    'speaker-deck' ,
    'creative-commons-nc-eu' ,
    'patreon' ,
    'avianex' ,
    'ello' ,
    'gofore' ,
    'bimobject' ,
    'facebook-f' ,
    'square-google-plus' ,
    'google-plus-square' ,
    'mandalorian' ,
    'first-order-alt' ,
    'osi' ,
    'google-wallet' ,
    'd-and-d-beyond' ,
    'periscope' ,
    'fulcrum' ,
    'cloudscale' ,
    'forumbee' ,
    'mizuni' ,
    'schlix' ,
    'square-xing' ,
    'xing-square' ,
    'bandcamp' ,
    'wpforms' ,
    'cloudversify' ,
    'usps' ,
    'megaport' ,
    'magento' ,
    'spotify' ,
    'optin-monster' ,
    'fly' ,
    'aviato' ,
    'itunes' ,
    'cuttlefish' ,
    'blogger' ,
    'flickr' ,
    'viber' ,
    'soundcloud' ,
    'digg' ,
    'tencent-weibo' ,
    'symfony' ,
    'maxcdn' ,
    'etsy' ,
    'facebook-messenger' ,
    'audible' ,
    'think-peaks' ,
    'bilibili' ,
    'erlang' ,
    'cotton-bureau' ,
    'dashcube' ,
    '42-group' ,
    'innosoft' ,
    'stack-exchange' ,
    'elementor' ,
    'square-pied-piper' ,
    'pied-piper-square' ,
    'creative-commons-nd' ,
    'palfed' ,
    'superpowers' ,
    'resolving' ,
    'xbox' ,
    'searchengin' ,
    'tiktok' ,
    'square-facebook' ,
    'facebook-square' ,
    'renren' ,
    'linux' ,
    'glide' ,
    'linkedin' ,
    'hubspot' ,
    'deploydog' ,
    'twitch' ,
    'ravelry' ,
    'mixer' ,
    'square-lastfm' ,
    'lastfm-square' ,
    'vimeo' ,
    'mendeley' ,
    'uniregistry' ,
    'figma' ,
    'creative-commons-remix' ,
    'cc-amazon-pay' ,
    'dropbox' ,
    'instagram' ,
    'cmplid' ,
    'facebook' ,
    'gripfire' ,
    'jedi-order' ,
    'uikit' ,
    'fort-awesome-alt' ,
    'phabricator' ,
    'ussunnah' ,
    'earlybirds' ,
    'trade-federation' ,
    'autoprefixer' ,
    'whatsapp' ,
    'slideshare' ,
    'google-play' ,
    'viadeo' ,
    'line' ,
    'google-drive' ,
    'servicestack' ,
    'simplybuilt' ,
    'bitbucket' ,
    'imdb' ,
    'deezer' ,
    'raspberry-pi' ,
    'jira' ,
    'docker' ,
    'screenpal' ,
    'bluetooth' ,
    'gitter' ,
    'd-and-d' ,
    'microblog' ,
    'cc-diners-club' ,
    'gg-circle' ,
    'pied-piper-hat' ,
    'kickstarter-k' ,
    'yandex' ,
    'readme' ,
    'html5' ,
    'sellsy' ,
    'sass' ,
    'wirsindhandwerk' ,
    'wsh' ,
    'buromobelexperte' ,
    'salesforce' ,
    'octopus-deploy' ,
    'medapps' ,
    'ns8' ,
    'pinterest-p' ,
    'apper' ,
    'fort-awesome' ,
    'waze' ,
    'cc-jcb' ,
    'snapchat' ,
    'snapchat-ghost' ,
    'fantasy-flight-games' ,
    'rust' ,
    'wix' ,
    'square-behance' ,
    'behance-square' ,
    'supple' ,
    'rebel' ,
    'css3' ,
    'staylinked' ,
    'kaggle' ,
    'space-awesome' ,
    'deviantart' ,
    'cpanel' ,
    'goodreads-g' ,
    'square-git' ,
    'git-square' ,
    'square-tumblr' ,
    'tumblr-square' ,
    'trello' ,
    'creative-commons-nc-jp' ,
    'get-pocket' ,
    'perbyte' ,
    'grunt' ,
    'weebly' ,
    'connectdevelop' ,
    'leanpub' ,
    'black-tie' ,
    'themeco' ,
    'python' ,
    'android' ,
    'bots' ,
    'free-code-camp' ,
    'hornbill' ,
    'js' ,
    'ideal' ,
    'git' ,
    'dev' ,
    'sketch' ,
    'yandex-international' ,
    'cc-amex' ,
    'uber' ,
    'github' ,
    'php' ,
    'alipay' ,
    'youtube' ,
    'skyatlas' ,
    'firefox-browser' ,
    'replyd' ,
    'suse' ,
    'jenkins' ,
    'twitter' ,
    'rockrms' ,
    'pinterest' ,
    'buffer' ,
    'npm' ,
    'yammer' ,
    'btc' ,
    'dribbble' ,
    'stumbleupon-circle' ,
    'internet-explorer' ,
    'stubber' ,
    'telegram' ,
    'telegram-plane' ,
    'old-republic' ,
    'odysee' ,
    'square-whatsapp' ,
    'whatsapp-square' ,
    'node-js' ,
    'edge-legacy' ,
    'slack' ,
    'slack-hash' ,
    'medrt' ,
    'usb' ,
    'tumblr' ,
    'vaadin' ,
    'quora' ,
    'reacteurope' ,
    'medium' ,
    'medium-m' ,
    'amilia' ,
    'mixcloud' ,
    'flipboard' ,
    'viacoin' ,
    'critical-role' ,
    'sitrox' ,
    'discourse' ,
    'joomla' ,
    'mastodon' ,
    'airbnb' ,
    'wolf-pack-battalion' ,
    'buy-n-large' ,
    'gulp' ,
    'creative-commons-sampling-plus' ,
    'strava' ,
    'ember' ,
    'canadian-maple-leaf' ,
    'teamspeak' ,
    'pushed' ,
    'wordpress-simple' ,
    'nutritionix' ,
    'wodu' ,
    'google-pay' ,
    'intercom' ,
    'zhihu' ,
    'korvue' ,
    'pix' ,
    'steam-symbol' ,
    '0' ,
    '1' ,
    '2' ,
    '3' ,
    '4' ,
    '5' ,
    '6' ,
    '7' ,
    '8' ,
    '9' ,
    'fill-drip' ,
    'arrows-to-circle' ,
    'circle-chevron-right' ,
    'chevron-circle-right' ,
    'wagon-covered' ,
    'line-height' ,
    'bagel' ,
    'transporter-7' ,
    'at' ,
    'rectangles-mixed' ,
    'phone-arrow-up-right' ,
    'phone-arrow-up' ,
    'phone-outgoing' ,
    'trash-can' ,
    'trash-alt' ,
    'circle-l' ,
    'head-side-goggles' ,
    'head-vr' ,
    'text-height' ,
    'user-xmark' ,
    'user-times' ,
    'face-hand-yawn' ,
    'gauge-simple-min' ,
    'tachometer-slowest' ,
    'stethoscope' ,
    'coffin' ,
    'message' ,
    'comment-alt' ,
    'salad' ,
    'bowl-salad' ,
    'info' ,
    'robot-astromech' ,
    'ring-diamond' ,
    'fondue-pot' ,
    'theta' ,
    'face-hand-peeking' ,
    'square-user' ,
    'down-left-and-up-right-to-center' ,
    'compress-alt' ,
    'explosion' ,
    'file-lines' ,
    'file-alt' ,
    'file-text' ,
    'wave-square' ,
    'ring' ,
    'building-un' ,
    'dice-three' ,
    'tire-pressure-warning' ,
    'wifi-fair' ,
    'wifi2' ,
    'calendar-days' ,
    'calendar-alt' ,
    'mp3-player' ,
    'anchor-circle-check' ,
    'tally-4' ,
    'rectangle-history' ,
    'building-circle-arrow-right' ,
    'volleyball' ,
    'volleyball-ball' ,
    'sun-haze' ,
    'text-size' ,
    'ufo' ,
    'fork' ,
    'utensil-fork' ,
    'arrows-up-to-line' ,
    'mobile-signal' ,
    'barcode-scan' ,
    'sort-down' ,
    'sort-desc' ,
    'folder-arrow-down' ,
    'folder-download' ,
    'circle-minus' ,
    'minus-circle' ,
    'face-icicles' ,
    'shovel' ,
    'door-open' ,
    'films' ,
    'right-from-bracket' ,
    'sign-out-alt' ,
    'face-glasses' ,
    'nfc' ,
    'atom' ,
    'soap' ,
    'icons' ,
    'heart-music-camera-bolt' ,
    'microphone-lines-slash' ,
    'microphone-alt-slash' ,
    'closed-captioning-slash' ,
    'calculator-simple' ,
    'calculator-alt' ,
    'bridge-circle-check' ,
    'sliders-up' ,
    'sliders-v' ,
    'location-minus' ,
    'map-marker-minus' ,
    'pump-medical' ,
    'fingerprint' ,
    'ski-boot' ,
    'standard-definition' ,
    'rectangle-sd' ,
    'h1' ,
    'hand-point-right' ,
    'magnifying-glass-location' ,
    'search-location' ,
    'message-bot' ,
    'forward-step' ,
    'step-forward' ,
    'face-smile-beam' ,
    'smile-beam' ,
    'light-ceiling' ,
    'message-exclamation' ,
    'comment-alt-exclamation' ,
    'bowl-scoop' ,
    'bowl-shaved-ice' ,
    'square-x' ,
    'utility-pole-double' ,
    'flag-checkered' ,
    'chevrons-up' ,
    'chevron-double-up' ,
    'football' ,
    'football-ball' ,
    'user-vneck' ,
    'school-circle-exclamation' ,
    'crop' ,
    'angles-down' ,
    'angle-double-down' ,
    'users-rectangle' ,
    'people-roof' ,
    'square-arrow-right' ,
    'arrow-square-right' ,
    'location-plus' ,
    'map-marker-plus' ,
    'lightbulb-exclamation-on' ,
    'people-line' ,
    'beer-mug-empty' ,
    'beer' ,
    'crate-empty' ,
    'diagram-predecessor' ,
    'transporter' ,
    'calendar-circle-user' ,
    'arrow-up-long' ,
    'long-arrow-up' ,
    'person-carry-box' ,
    'person-carry' ,
    'fire-flame-simple' ,
    'burn' ,
    'person' ,
    'male' ,
    'laptop' ,
    'file-csv' ,
    'menorah' ,
    'union' ,
    'chevrons-left' ,
    'chevron-double-left' ,
    'circle-heart' ,
    'heart-circle' ,
    'truck-plane' ,
    'record-vinyl' ,
    'bring-forward' ,
    'square-p' ,
    'face-grin-stars' ,
    'grin-stars' ,
    'sigma' ,
    'camera-movie' ,
    'bong' ,
    'clarinet' ,
    'truck-flatbed' ,
    'spaghetti-monster-flying' ,
    'pastafarianism' ,
    'arrow-down-up-across-line' ,
    'leaf-heart' ,
    'house-building' ,
    'cheese-swiss' ,
    'spoon' ,
    'utensil-spoon' ,
    'jar-wheat' ,
    'envelopes-bulk' ,
    'mail-bulk' ,
    'file-circle-exclamation' ,
    'bow-arrow' ,
    'cart-xmark' ,
    'hexagon-xmark' ,
    'times-hexagon' ,
    'xmark-hexagon' ,
    'circle-h' ,
    'hospital-symbol' ,
    'merge' ,
    'pager' ,
    'cart-minus' ,
    'address-book' ,
    'contact-book' ,
    'pan-frying' ,
    'grid' ,
    'grid3' ,
    'football-helmet' ,
    'hand-love' ,
    'trees' ,
    'strikethrough' ,
    'page' ,
    'k' ,
    'diagram-previous' ,
    'gauge-min' ,
    'tachometer-alt-slowest' ,
    'folder-grid' ,
    'eggplant' ,
    'ram' ,
    'landmark-flag' ,
    'lips' ,
    'pencil' ,
    'pencil-alt' ,
    'backward' ,
    'caret-right' ,
    'comments' ,
    'paste' ,
    'file-clipboard' ,
    'desktop-arrow-down' ,
    'code-pull-request' ,
    'pumpkin' ,
    'clipboard-list' ,
    'pen-field' ,
    'blueberries' ,
    'truck-ramp-box' ,
    'truck-loading' ,
    'note' ,
    'arrow-down-to-square' ,
    'user-check' ,
    'cloud-xmark' ,
    'vial-virus' ,
    'book-blank' ,
    'book-alt' ,
    'golf-flag-hole' ,
    'message-arrow-down' ,
    'comment-alt-arrow-down' ,
    'face-unamused' ,
    'sheet-plastic' ,
    'circle-9' ,
    'blog' ,
    'user-ninja' ,
    'pencil-slash' ,
    'bowling-pins' ,
    'person-arrow-up-from-line' ,
    'down-right' ,
    'scroll-torah' ,
    'torah' ,
    'blinds-open' ,
    'fence' ,
    'up' ,
    'arrow-alt-up' ,
    'broom-ball' ,
    'quidditch' ,
    'quidditch-broom-ball' ,
    'drumstick' ,
    'square-v' ,
    'face-awesome' ,
    'gave-dandy' ,
    'dial-off' ,
    'toggle-off' ,
    'face-smile-horns' ,
    'box-archive' ,
    'archive' ,
    'grapes' ,
    'person-drowning' ,
    'dial-max' ,
    'circle-m' ,
    'calendar-image' ,
    'circle-caret-down' ,
    'caret-circle-down' ,
    'arrow-down-9-1' ,
    'sort-numeric-desc' ,
    'sort-numeric-down-alt' ,
    'face-grin-tongue-squint' ,
    'grin-tongue-squint' ,
    'shish-kebab' ,
    'spray-can' ,
    'alarm-snooze' ,
    'scarecrow' ,
    'truck-monster' ,
    'gift-card' ,
    'w' ,
    'code-pull-request-draft' ,
    'square-b' ,
    'elephant' ,
    'earth-africa' ,
    'globe-africa' ,
    'rainbow' ,
    'circle-notch' ,
    'tablet-screen-button' ,
    'tablet-alt' ,
    'paw' ,
    'message-question' ,
    'cloud' ,
    'trowel-bricks' ,
    'square-3' ,
    'face-flushed' ,
    'flushed' ,
    'hospital-user' ,
    'microwave' ,
    'tent-arrow-left-right' ,
    'cart-circle-arrow-up' ,
    'trash-clock' ,
    'gavel' ,
    'legal' ,
    'sprinkler-ceiling' ,
    'browsers' ,
    'trillium' ,
    'music-slash' ,
    'truck-ramp' ,
    'binoculars' ,
    'microphone-slash' ,
    'box-tissue' ,
    'circle-c' ,
    'star-christmas' ,
    'chart-bullet' ,
    'motorcycle' ,
    'tree-christmas' ,
    'tire-flat' ,
    'sunglasses' ,
    'badge' ,
    'message-pen' ,
    'comment-alt-edit' ,
    'message-edit' ,
    'bell-concierge' ,
    'concierge-bell' ,
    'pen-ruler' ,
    'pencil-ruler' ,
    'chess-rook-piece' ,
    'chess-rook-alt' ,
    'square-root' ,
    'album-collection-circle-plus' ,
    'people-arrows' ,
    'people-arrows-left-right' ,
    'face-angry-horns' ,
    'mars-and-venus-burst' ,
    'tombstone' ,
    'square-caret-right' ,
    'caret-square-right' ,
    'scissors' ,
    'cut' ,
    'list-music' ,
    'sun-plant-wilt' ,
    'toilets-portable' ,
    'hockey-puck' ,
    'mustache' ,
    'hyphen' ,
    'table' ,
    'user-chef' ,
    'message-image' ,
    'comment-alt-image' ,
    'users-medical' ,
    'sensor-triangle-exclamation' ,
    'sensor-alert' ,
    'magnifying-glass-arrow-right' ,
    'tachograph-digital' ,
    'digital-tachograph' ,
    'face-mask' ,
    'pickleball' ,
    'star-sharp-half' ,
    'users-slash' ,
    'clover' ,
    'meat' ,
    'reply' ,
    'mail-reply' ,
    'star-and-crescent' ,
    'empty-set' ,
    'house-fire' ,
    'square-minus' ,
    'minus-square' ,
    'helicopter' ,
    'bird' ,
    'compass' ,
    'square-caret-down' ,
    'caret-square-down' ,
    'heart-half-stroke' ,
    'heart-half-alt' ,
    'file-circle-question' ,
    'laptop-code' ,
    'joystick' ,
    'grill-fire' ,
    'rectangle-vertical-history' ,
    'swatchbook' ,
    'prescription-bottle' ,
    'bars' ,
    'navicon' ,
    'keyboard-left' ,
    'people-group' ,
    'hourglass-end' ,
    'hourglass3' ,
    'heart-crack' ,
    'heart-broken' ,
    'face-beam-hand-over-mouth' ,
    'droplet-percent' ,
    'humidity' ,
    'square-up-right' ,
    'external-link-square-alt' ,
    'face-kiss-beam' ,
    'kiss-beam' ,
    'corn' ,
    'roller-coaster' ,
    'photo-film-music' ,
    'radar' ,
    'sickle' ,
    'film' ,
    'coconut' ,
    'ruler-horizontal' ,
    'shield-cross' ,
    'cassette-tape' ,
    'square-terminal' ,
    'people-robbery' ,
    'lightbulb' ,
    'caret-left' ,
    'comment-middle' ,
    'trash-can-list' ,
    'block' ,
    'circle-exclamation' ,
    'exclamation-circle' ,
    'school-circle-xmark' ,
    'arrow-right-from-bracket' ,
    'sign-out' ,
    'face-frown-slight' ,
    'circle-chevron-down' ,
    'chevron-circle-down' ,
    'sidebar-flip' ,
    'unlock-keyhole' ,
    'unlock-alt' ,
    'temperature-list' ,
    'cloud-showers-heavy' ,
    'headphones-simple' ,
    'headphones-alt' ,
    'sitemap' ,
    'pipe-section' ,
    'space-station-moon-construction' ,
    'space-station-moon-alt' ,
    'circle-dollar-to-slot' ,
    'donate' ,
    'memory' ,
    'face-sleeping' ,
    'road-spikes' ,
    'fire-burner' ,
    'squirrel' ,
    'arrow-up-to-line' ,
    'arrow-to-top' ,
    'flag' ,
    'face-cowboy-hat' ,
    'hanukiah' ,
    'chart-scatter-3d' ,
    'square-code' ,
    'feather' ,
    'volume-low' ,
    'volume-down' ,
    'xmark-to-slot' ,
    'times-to-slot' ,
    'vote-nay' ,
    'box-taped' ,
    'box-alt' ,
    'comment-slash' ,
    'swords' ,
    'cloud-sun-rain' ,
    'album' ,
    'circle-n' ,
    'compress' ,
    'wheat-awn' ,
    'wheat-alt' ,
    'ankh' ,
    'hands-holding-child' ,
    'asterisk' ,
    'key-skeleton-left-right' ,
    'comment-lines' ,
    'luchador-mask' ,
    'luchador' ,
    'mask-luchador' ,
    'square-check' ,
    'check-square' ,
    'shredder' ,
    'book-open-cover' ,
    'book-open-alt' ,
    'sandwich' ,
    'peseta-sign' ,
    'square-parking-slash' ,
    'parking-slash' ,
    'train-tunnel' ,
    'heading' ,
    'header' ,
    'ghost' ,
    'face-anguished' ,
    'hockey-sticks' ,
    'abacus' ,
    'film-simple' ,
    'film-alt' ,
    'list' ,
    'list-squares' ,
    'tree-palm' ,
    'square-phone-flip' ,
    'phone-square-alt' ,
    'cart-plus' ,
    'gamepad' ,
    'border-center-v' ,
    'circle-dot' ,
    'dot-circle' ,
    'clipboard-medical' ,
    'face-dizzy' ,
    'dizzy' ,
    'egg' ,
    'up-to-line' ,
    'arrow-alt-to-top' ,
    'house-medical-circle-xmark' ,
    'watch-fitness' ,
    'clock-nine-thirty' ,
    'campground' ,
    'folder-plus' ,
    'jug' ,
    'futbol' ,
    'futbol-ball' ,
    'soccer-ball' ,
    'snow-blowing' ,
    'paintbrush' ,
    'paint-brush' ,
    'lock' ,
    'arrow-down-from-line' ,
    'arrow-from-top' ,
    'gas-pump' ,
    'signal-bars-slash' ,
    'signal-alt-slash' ,
    'monkey' ,
    'rectangle-pro' ,
    'pro' ,
    'house-night' ,
    'hot-tub-person' ,
    'hot-tub' ,
    'blanket' ,
    'map-location' ,
    'map-marked' ,
    'house-flood-water' ,
    'comments-question-check' ,
    'tree' ,
    'arrows-cross' ,
    'backpack' ,
    'square-small' ,
    'folder-arrow-up' ,
    'folder-upload' ,
    'bridge-lock' ,
    'crosshairs-simple' ,
    'sack-dollar' ,
    'pen-to-square' ,
    'edit' ,
    'square-sliders' ,
    'sliders-h-square' ,
    'car-side' ,
    'message-middle-top' ,
    'comment-middle-top-alt' ,
    'lightbulb-on' ,
    'knife' ,
    'utensil-knife' ,
    'share-nodes' ,
    'share-alt' ,
    'wave-sine' ,
    'heart-circle-minus' ,
    'circle-w' ,
    'circle-calendar' ,
    'calendar-circle' ,
    'hourglass-half' ,
    'hourglass2' ,
    'microscope' ,
    'sunset' ,
    'sink' ,
    'calendar-exclamation' ,
    'truck-container-empty' ,
    'hand-heart' ,
    'bag-shopping' ,
    'shopping-bag' ,
    'arrow-down-z-a' ,
    'sort-alpha-desc' ,
    'sort-alpha-down-alt' ,
    'mitten' ,
    'reply-clock' ,
    'reply-time' ,
    'person-rays' ,
    'right' ,
    'arrow-alt-right' ,
    'circle-f' ,
    'users' ,
    'face-pleading' ,
    'eye-slash' ,
    'flask-vial' ,
    'police-box' ,
    'cucumber' ,
    'head-side-brain' ,
    'hand' ,
    'hand-paper' ,
    'person-biking-mountain' ,
    'biking-mountain' ,
    'utensils-slash' ,
    'print-magnifying-glass' ,
    'print-search' ,
    'folder-bookmark' ,
    'om' ,
    'pi' ,
    'flask-round-potion' ,
    'flask-potion' ,
    'face-shush' ,
    'worm' ,
    'house-circle-xmark' ,
    'plug' ,
    'calendar-circle-exclamation' ,
    'square-i' ,
    'chevron-up' ,
    'face-saluting' ,
    'gauge-simple-low' ,
    'tachometer-slow' ,
    'face-persevering' ,
    'circle-camera' ,
    'camera-circle' ,
    'hand-spock' ,
    'spider-web' ,
    'circle-microphone' ,
    'microphone-circle' ,
    'book-arrow-up' ,
    'popsicle' ,
    'command' ,
    'blinds' ,
    'stopwatch' ,
    'saxophone' ,
    'square-2' ,
    'field-hockey-stick-ball' ,
    'field-hockey' ,
    'arrow-up-square-triangle' ,
    'sort-shapes-up-alt' ,
    'face-scream' ,
    'square-m' ,
    'camera-web' ,
    'webcam' ,
    'comment-arrow-down' ,
    'lightbulb-cfl' ,
    'window-frame-open' ,
    'face-kiss' ,
    'kiss' ,
    'bridge-circle-xmark' ,
    'period' ,
    'face-grin-tongue' ,
    'grin-tongue' ,
    'up-to-dotted-line' ,
    'thought-bubble' ,
    'raygun' ,
    'flute' ,
    'acorn' ,
    'video-arrow-up-right' ,
    'grate-droplet' ,
    'seal-exclamation' ,
    'chess-bishop' ,
    'message-sms' ,
    'coffee-beans' ,
    'hat-witch' ,
    'face-grin-wink' ,
    'grin-wink' ,
    'clock-three-thirty' ,
    'ear-deaf' ,
    'deaf' ,
    'deafness' ,
    'hard-of-hearing' ,
    'alarm-clock' ,
    'eclipse' ,
    'face-relieved' ,
    'road-circle-check' ,
    'dice-five' ,
    'octagon-minus' ,
    'minus-octagon' ,
    'square-rss' ,
    'rss-square' ,
    'face-zany' ,
    'tricycle' ,
    'land-mine-on' ,
    'square-arrow-up-left' ,
    'i-cursor' ,
    'salt-shaker' ,
    'stamp' ,
    'file-plus' ,
    'draw-square' ,
    'toilet-paper-under-slash' ,
    'toilet-paper-reverse-slash' ,
    'stairs' ,
    'drone-front' ,
    'drone-alt' ,
    'glass-empty' ,
    'dial-high' ,
    'user-helmet-safety' ,
    'user-construction' ,
    'user-hard-hat' ,
    'i' ,
    'hryvnia-sign' ,
    'hryvnia' ,
    'arrow-down-left-and-arrow-up-right-to-center' ,
    'pills' ,
    'face-grin-wide' ,
    'grin-alt' ,
    'tooth' ,
    'basketball-hoop' ,
    'objects-align-bottom' ,
    'v' ,
    'sparkles' ,
    'squid' ,
    'leafy-green' ,
    'circle-arrow-up-right' ,
    'calendars' ,
    'bangladeshi-taka-sign' ,
    'bicycle' ,
    'hammer-war' ,
    'circle-d' ,
    'spider-black-widow' ,
    'staff-snake' ,
    'rod-asclepius' ,
    'rod-snake' ,
    'staff-aesculapius' ,
    'pear' ,
    'head-side-cough-slash' ,
    'triangle' ,
    'apartment' ,
    'truck-medical' ,
    'ambulance' ,
    'pepper' ,
    'piano' ,
    'gun-squirt' ,
    'wheat-awn-circle-exclamation' ,
    'snowman' ,
    'user-alien' ,
    'shield-check' ,
    'mortar-pestle' ,
    'road-barrier' ,
    'chart-candlestick' ,
    'briefcase-blank' ,
    'school' ,
    'igloo' ,
    'bracket-round' ,
    'parenthesis' ,
    'joint' ,
    'horse-saddle' ,
    'mug-marshmallows' ,
    'filters' ,
    'bell-on' ,
    'angle-right' ,
    'dial-med' ,
    'horse' ,
    'q' ,
    'monitor-waveform' ,
    'monitor-heart-rate' ,
    'link-simple' ,
    'whistle' ,
    'g' ,
    'wine-glass-crack' ,
    'fragile' ,
    'slot-machine' ,
    'notes-medical' ,
    'car-wash' ,
    'escalator' ,
    'comment-image' ,
    'temperature-half' ,
    'temperature2' ,
    'thermometer2' ,
    'thermometer-half' ,
    'dong-sign' ,
    'donut' ,
    'doughnut' ,
    'capsules' ,
    'poo-storm' ,
    'poo-bolt' ,
    'tally-1' ,
    'face-frown-open' ,
    'frown-open' ,
    'square-dashed' ,
    'square-j' ,
    'hand-point-up' ,
    'money-bill' ,
    'arrow-up-big-small' ,
    'sort-size-up' ,
    'barcode-read' ,
    'baguette' ,
    'bowl-soft-serve' ,
    'face-holding-back-tears' ,
    'square-up' ,
    'arrow-alt-square-up' ,
    'train-subway-tunnel' ,
    'subway-tunnel' ,
    'square-exclamation' ,
    'exclamation-square' ,
    'semicolon' ,
    'bookmark' ,
    'fan-table' ,
    'align-justify' ,
    'battery-low' ,
    'battery1' ,
    'credit-card-front' ,
    'brain-arrow-curved-right' ,
    'mind-share' ,
    'umbrella-beach' ,
    'helmet-un' ,
    'location-smile' ,
    'map-marker-smile' ,
    'arrow-left-to-line' ,
    'arrow-to-left' ,
    'bullseye' ,
    'sushi' ,
    'nigiri' ,
    'message-captions' ,
    'comment-alt-captions' ,
    'trash-list' ,
    'bacon' ,
    'option' ,
    'hand-point-down' ,
    'arrow-up-from-bracket' ,
    'trash-plus' ,
    'objects-align-top' ,
    'folder' ,
    'folder-blank' ,
    'face-anxious-sweat' ,
    'credit-card-blank' ,
    'file-waveform' ,
    'file-medical-alt' ,
    'microchip-ai' ,
    'mug' ,
    'plane-up-slash' ,
    'radiation' ,
    'pen-circle' ,
    'chart-simple' ,
    'crutches' ,
    'circle-parking' ,
    'parking-circle' ,
    'mars-stroke' ,
    'leaf-oak' ,
    'square-bolt' ,
    'vial' ,
    'gauge' ,
    'dashboard' ,
    'gauge-med' ,
    'tachometer-alt-average' ,
    'wand-magic-sparkles' ,
    'magic-wand-sparkles' ,
    'lambda' ,
    'e' ,
    'pizza' ,
    'bowl-chopsticks-noodles' ,
    'h3' ,
    'pen-clip' ,
    'pen-alt' ,
    'bridge-circle-exclamation' ,
    'badge-percent' ,
    'user' ,
    'sensor' ,
    'comma' ,
    'school-circle-check' ,
    'toilet-paper-under' ,
    'toilet-paper-reverse' ,
    'light-emergency' ,
    'arrow-down-to-arc' ,
    'dumpster' ,
    'van-shuttle' ,
    'shuttle-van' ,
    'building-user' ,
    'light-switch' ,
    'square-caret-left' ,
    'caret-square-left' ,
    'highlighter' ,
    'wave-pulse' ,
    'heart-rate' ,
    'key' ,
    'hat-santa' ,
    'tamale' ,
    'box-check' ,
    'bullhorn' ,
    'steak' ,
    'location-crosshairs-slash' ,
    'location-slash' ,
    'person-dolly' ,
    'globe' ,
    'synagogue' ,
    'file-chart-column' ,
    'file-chart-line' ,
    'person-half-dress' ,
    'folder-image' ,
    'calendar-pen' ,
    'calendar-edit' ,
    'road-bridge' ,
    'face-smile-tear' ,
    'message-plus' ,
    'comment-alt-plus' ,
    'location-arrow' ,
    'c' ,
    'tablet-button' ,
    'rectangle-history-circle-user' ,
    'building-lock' ,
    'chart-line-up' ,
    'mailbox' ,
    'truck-bolt' ,
    'pizza-slice' ,
    'money-bill-wave' ,
    'chart-area' ,
    'area-chart' ,
    'house-flag' ,
    'person-circle-minus' ,
    'scalpel' ,
    'ban' ,
    'cancel' ,
    'bell-exclamation' ,
    'circle-bookmark' ,
    'bookmark-circle' ,
    'egg-fried' ,
    'face-weary' ,
    'uniform-martial-arts' ,
    'camera-rotate' ,
    'sun-dust' ,
    'comment-text' ,
    'spray-can-sparkles' ,
    'air-freshener' ,
    'signal-bars' ,
    'signal-alt' ,
    'signal-alt4' ,
    'signal-bars-strong' ,
    'diamond-exclamation' ,
    'star' ,
    'dial-min' ,
    'repeat' ,
    'cross' ,
    'page-caret-down' ,
    'file-caret-down' ,
    'box' ,
    'venus-mars' ,
    'clock-seven-thirty' ,
    'arrow-pointer' ,
    'mouse-pointer' ,
    'clock-four-thirty' ,
    'signal-bars-good' ,
    'signal-alt3' ,
    'cactus' ,
    'maximize' ,
    'expand-arrows-alt' ,
    'charging-station' ,
    'shapes' ,
    'triangle-circle-square' ,
    'plane-tail' ,
    'gauge-simple-max' ,
    'tachometer-fastest' ,
    'circle-u' ,
    'shield-slash' ,
    'square-phone-hangup' ,
    'phone-square-down' ,
    'arrow-up-left' ,
    'transporter-1' ,
    'peanuts' ,
    'shuffle' ,
    'random' ,
    'person-running' ,
    'running' ,
    'mobile-retro' ,
    'grip-lines-vertical' ,
    'arrow-up-from-square' ,
    'file-dashed-line' ,
    'page-break' ,
    'bracket-curly-right' ,
    'spider' ,
    'clock-three' ,
    'hands-bound' ,
    'scalpel-line-dashed' ,
    'scalpel-path' ,
    'file-invoice-dollar' ,
    'pipe-smoking' ,
    'face-astonished' ,
    'window' ,
    'plane-circle-exclamation' ,
    'ear' ,
    'file-lock' ,
    'diagram-venn' ,
    'x-ray' ,
    'goal-net' ,
    'coffin-cross' ,
    'spell-check' ,
    'location-xmark' ,
    'map-marker-times' ,
    'map-marker-xmark' ,
    'lasso' ,
    'slash' ,
    'person-to-portal' ,
    'portal-enter' ,
    'calendar-star' ,
    'computer-mouse' ,
    'mouse' ,
    'arrow-right-to-bracket' ,
    'sign-in' ,
    'pegasus' ,
    'files-medical' ,
    'nfc-lock' ,
    'person-ski-lift' ,
    'ski-lift' ,
    'square-6' ,
    'shop-slash' ,
    'store-alt-slash' ,
    'wind-turbine' ,
    'sliders-simple' ,
    'badge-sheriff' ,
    'server' ,
    'virus-covid-slash' ,
    'intersection' ,
    'shop-lock' ,
    'family' ,
    'hourglass-start' ,
    'hourglass1' ,
    'user-hair-buns' ,
    'blender-phone' ,
    'hourglass-clock' ,
    'person-seat-reclined' ,
    'paper-plane-top' ,
    'paper-plane-alt' ,
    'send' ,
    'message-arrow-up' ,
    'comment-alt-arrow-up' ,
    'lightbulb-exclamation' ,
    'layer-minus' ,
    'layer-group-minus' ,
    'circle-e' ,
    'building-wheat' ,
    'gauge-max' ,
    'tachometer-alt-fastest' ,
    'person-breastfeeding' ,
    'apostrophe' ,
    'fire-hydrant' ,
    'right-to-bracket' ,
    'sign-in-alt' ,
    'video-plus' ,
    'square-right' ,
    'arrow-alt-square-right' ,
    'comment-smile' ,
    'venus' ,
    'passport' ,
    'inbox-in' ,
    'inbox-arrow-down' ,
    'heart-pulse' ,
    'heartbeat' ,
    'circle-8' ,
    'clouds-moon' ,
    'clock-ten-thirty' ,
    'people-carry-box' ,
    'people-carry' ,
    'folder-user' ,
    'trash-can-xmark' ,
    'temperature-high' ,
    'microchip' ,
    'left-long-to-line' ,
    'crown' ,
    'weight-hanging' ,
    'xmarks-lines' ,
    'file-prescription' ,
    'calendar-range' ,
    'flower-daffodil' ,
    'hand-back-point-up' ,
    'weight-scale' ,
    'weight' ,
    'star-exclamation' ,
    'books' ,
    'user-group' ,
    'user-friends' ,
    'arrow-up-a-z' ,
    'sort-alpha-up' ,
    'layer-plus' ,
    'layer-group-plus' ,
    'play-pause' ,
    'block-question' ,
    'snooze' ,
    'zzz' ,
    'scanner-image' ,
    'tv-retro' ,
    'square-t' ,
    'farm' ,
    'barn-silo' ,
    'chess-knight' ,
    'bars-sort' ,
    'pallet-boxes' ,
    'palette-boxes' ,
    'pallet-alt' ,
    'face-laugh-squint' ,
    'laugh-squint' ,
    'code-simple' ,
    'bolt-slash' ,
    'panel-fire' ,
    'binary-circle-check' ,
    'comment-minus' ,
    'burrito' ,
    'violin' ,
    'objects-column' ,
    'square-chevron-down' ,
    'chevron-square-down' ,
    'comment-plus' ,
    'triangle-instrument' ,
    'triangle-music' ,
    'wheelchair' ,
    'user-pilot-tie' ,
    'piano-keyboard' ,
    'bed-empty' ,
    'circle-arrow-up' ,
    'arrow-circle-up' ,
    'toggle-on' ,
    'rectangle-vertical' ,
    'rectangle-portrait' ,
    'person-walking' ,
    'walking' ,
    'l' ,
    'signal-stream' ,
    'down-to-bracket' ,
    'circle-z' ,
    'stars' ,
    'fire' ,
    'bed-pulse' ,
    'procedures' ,
    'house-day' ,
    'shuttle-space' ,
    'space-shuttle' ,
    'shirt-long-sleeve' ,
    'chart-pie-simple' ,
    'chart-pie-alt' ,
    'face-laugh' ,
    'laugh' ,
    'folder-open' ,
    'album-collection-circle-user' ,
    'candy' ,
    'bowl-hot' ,
    'soup' ,
    'flatbread' ,
    'heart-circle-plus' ,
    'code-fork' ,
    'city' ,
    'signal-bars-weak' ,
    'signal-alt1' ,
    'microphone-lines' ,
    'microphone-alt' ,
    'clock-twelve' ,
    'pepper-hot' ,
    'citrus-slice' ,
    'sheep' ,
    'unlock' ,
    'colon-sign' ,
    'headset' ,
    'badger-honey' ,
    'h4' ,
    'store-slash' ,
    'road-circle-xmark' ,
    'signal-slash' ,
    'user-minus' ,
    'mars-stroke-up' ,
    'mars-stroke-v' ,
    'champagne-glasses' ,
    'glass-cheers' ,
    'taco' ,
    'hexagon-plus' ,
    'plus-hexagon' ,
    'clipboard' ,
    'house-circle-exclamation' ,
    'file-arrow-up' ,
    'file-upload' ,
    'wifi' ,
    'wifi3' ,
    'wifi-strong' ,
    'messages' ,
    'comments-alt' ,
    'bath' ,
    'bathtub' ,
    'umbrella-simple' ,
    'umbrella-alt' ,
    'rectangle-history-circle-plus' ,
    'underline' ,
    'prescription-bottle-pill' ,
    'user-pen' ,
    'user-edit' ,
    'binary-slash' ,
    'square-o' ,
    'signature' ,
    'stroopwafel' ,
    'bold' ,
    'anchor-lock' ,
    'building-ngo' ,
    'transporter-3' ,
    'engine-warning' ,
    'engine-exclamation' ,
    'circle-down-right' ,
    'square-k' ,
    'manat-sign' ,
    'money-check-pen' ,
    'money-check-edit' ,
    'not-equal' ,
    'border-top-left' ,
    'border-style' ,
    'map-location-dot' ,
    'map-marked-alt' ,
    'tilde' ,
    'jedi' ,
    'square-poll-vertical' ,
    'poll' ,
    'arrow-down-square-triangle' ,
    'sort-shapes-down-alt' ,
    'mug-hot' ,
    'dog-leashed' ,
    'car-battery' ,
    'battery-car' ,
    'face-downcast-sweat' ,
    'mailbox-flag-up' ,
    'memo-circle-info' ,
    'gift' ,
    'dice-two' ,
    'volume' ,
    'volume-medium' ,
    'transporter-5' ,
    'gauge-circle-bolt' ,
    'coin-front' ,
    'file-slash' ,
    'message-arrow-up-right' ,
    'treasure-chest' ,
    'chess-queen' ,
    'paintbrush-fine' ,
    'paint-brush-alt' ,
    'paint-brush-fine' ,
    'paintbrush-alt' ,
    'glasses' ,
    'hood-cloak' ,
    'square-quote' ,
    'up-left' ,
    'bring-front' ,
    'chess-board' ,
    'burger-cheese' ,
    'cheeseburger' ,
    'building-circle-check' ,
    'repeat-1' ,
    'arrow-down-to-line' ,
    'arrow-to-bottom' ,
    'grid-5' ,
    'right-long-to-line' ,
    'person-chalkboard' ,
    'mars-stroke-right' ,
    'mars-stroke-h' ,
    'hand-back-fist' ,
    'hand-rock' ,
    'tally' ,
    'tally5' ,
    'square-caret-up' ,
    'caret-square-up' ,
    'cloud-showers-water' ,
    'chart-bar' ,
    'bar-chart' ,
    'hands-bubbles' ,
    'hands-wash' ,
    'less-than-equal' ,
    'train' ,
    'up-from-dotted-line' ,
    'eye-low-vision' ,
    'low-vision' ,
    'traffic-light-go' ,
    'face-exhaling' ,
    'sensor-fire' ,
    'user-unlock' ,
    'hexagon-divide' ,
    '00' ,
    'crow' ,
    'cassette-betamax' ,
    'betamax' ,
    'sailboat' ,
    'window-restore' ,
    'nfc-magnifying-glass' ,
    'file-binary' ,
    'circle-v' ,
    'square-plus' ,
    'plus-square' ,
    'bowl-scoops' ,
    'mistletoe' ,
    'custard' ,
    'lacrosse-stick' ,
    'hockey-mask' ,
    'sunrise' ,
    'panel-ews' ,
    'torii-gate' ,
    'cloud-exclamation' ,
    'message-lines' ,
    'comment-alt-lines' ,
    'frog' ,
    'bucket' ,
    'floppy-disk-pen' ,
    'image' ,
    'window-frame' ,
    'microphone' ,
    'cow' ,
    'square-ring' ,
    'down-from-line' ,
    'arrow-alt-from-top' ,
    'caret-up' ,
    'shield-xmark' ,
    'shield-times' ,
    'screwdriver' ,
    'circle-sort-down' ,
    'sort-circle-down' ,
    'folder-closed' ,
    'house-tsunami' ,
    'square-nfi' ,
    'forklift' ,
    'arrow-up-from-ground-water' ,
    'bracket-square-right' ,
    'martini-glass' ,
    'glass-martini-alt' ,
    'rotate-left' ,
    'rotate-back' ,
    'rotate-backward' ,
    'undo-alt' ,
    'table-columns' ,
    'columns' ,
    'square-a' ,
    'tick' ,
    'lemon' ,
    'head-side-mask' ,
    'handshake' ,
    'gem' ,
    'dolly' ,
    'dolly-box' ,
    'smoking' ,
    'minimize' ,
    'compress-arrows-alt' ,
    'refrigerator' ,
    'monument' ,
    'octagon-xmark' ,
    'times-octagon' ,
    'xmark-octagon' ,
    'align-slash' ,
    'snowplow' ,
    'angles-right' ,
    'angle-double-right' ,
    'truck-ramp-couch' ,
    'truck-couch' ,
    'cannabis' ,
    'circle-play' ,
    'play-circle' ,
    'arrow-up-right-and-arrow-down-left-from-center' ,
    'tablets' ,
    '360-degrees' ,
    'ethernet' ,
    'euro-sign' ,
    'eur' ,
    'euro' ,
    'chair' ,
    'circle-check' ,
    'check-circle' ,
    'square-dashed-circle-plus' ,
    'money-simple-from-bracket' ,
    'bat' ,
    'circle-stop' ,
    'stop-circle' ,
    'head-side-headphones' ,
    'phone-rotary' ,
    'compass-drafting' ,
    'drafting-compass' ,
    'plate-wheat' ,
    'calendar-circle-minus' ,
    'chopsticks' ,
    'car-wrench' ,
    'car-mechanic' ,
    'icicles' ,
    'person-shelter' ,
    'neuter' ,
    'id-badge' ,
    'kazoo' ,
    'marker' ,
    'face-laugh-beam' ,
    'laugh-beam' ,
    'square-arrow-down-left' ,
    'battery-bolt' ,
    'tree-large' ,
    'helicopter-symbol' ,
    'aperture' ,
    'universal-access' ,
    'file-magnifying-glass' ,
    'file-search' ,
    'up-right' ,
    'circle-chevron-up' ,
    'chevron-circle-up' ,
    'user-police' ,
    'lari-sign' ,
    'volcano' ,
    'teddy-bear' ,
    'stocking' ,
    'person-walking-dashed-line-arrow-right' ,
    'image-slash' ,
    'mask-snorkel' ,
    'smoke' ,
    'sterling-sign' ,
    'gbp' ,
    'pound-sign' ,
    'battery-exclamation' ,
    'viruses' ,
    'square-person-confined' ,
    'user-tie' ,
    'arrow-down-long' ,
    'long-arrow-down' ,
    'tent-arrow-down-to-line' ,
    'certificate' ,
    'crystal-ball' ,
    'reply-all' ,
    'mail-reply-all' ,
    'suitcase' ,
    'person-skating' ,
    'skating' ,
    'star-shooting' ,
    'binary-lock' ,
    'filter-circle-dollar' ,
    'funnel-dollar' ,
    'camera-retro' ,
    'circle-arrow-down' ,
    'arrow-circle-down' ,
    'comment-pen' ,
    'comment-edit' ,
    'file-import' ,
    'arrow-right-to-file' ,
    'banjo' ,
    'square-arrow-up-right' ,
    'external-link-square' ,
    'light-emergency-on' ,
    'kerning' ,
    'box-open' ,
    'square-f' ,
    'scroll' ,
    'spa' ,
    'arrow-left-from-line' ,
    'arrow-from-right' ,
    'strawberry' ,
    'location-pin-lock' ,
    'pause' ,
    'clock-eight-thirty' ,
    'plane-engines' ,
    'plane-alt' ,
    'hill-avalanche' ,
    'temperature-empty' ,
    'temperature0' ,
    'thermometer0' ,
    'thermometer-empty' ,
    'bomb' ,
    'gauge-low' ,
    'tachometer-alt-slow' ,
    'registered' ,
    'trash-can-plus' ,
    'address-card' ,
    'contact-card' ,
    'vcard' ,
    'scale-unbalanced-flip' ,
    'balance-scale-right' ,
    'globe-snow' ,
    'subscript' ,
    'diamond-turn-right' ,
    'directions' ,
    'integral' ,
    'burst' ,
    'house-laptop' ,
    'laptop-house' ,
    'face-tired' ,
    'tired' ,
    'money-bills' ,
    'blinds-raised' ,
    'smog' ,
    'ufo-beam' ,
    'circle-caret-up' ,
    'caret-circle-up' ,
    'user-vneck-hair-long' ,
    'square-a-lock' ,
    'crutch' ,
    'gas-pump-slash' ,
    'font-awesome' ,
    'font-awesome-flag' ,
    'font-awesome-logo-full' ,
    'cloud-arrow-up' ,
    'cloud-upload' ,
    'cloud-upload-alt' ,
    'palette' ,
    'transporter-4' ,
    'objects-align-right' ,
    'arrows-turn-right' ,
    'vest' ,
    'pig' ,
    'inbox-full' ,
    'circle-envelope' ,
    'envelope-circle' ,
    'triangle-person-digging' ,
    'construction' ,
    'ferry' ,
    'bullseye-arrow' ,
    'arrows-down-to-people' ,
    'seedling' ,
    'sprout' ,
    'clock-seven' ,
    'left-right' ,
    'arrows-alt-h' ,
    'boxes-packing' ,
    'circle-arrow-left' ,
    'arrow-circle-left' ,
    'flashlight' ,
    'group-arrows-rotate' ,
    'bowl-food' ,
    'square-9' ,
    'candy-cane' ,
    'arrow-down-wide-short' ,
    'sort-amount-asc' ,
    'sort-amount-down' ,
    'square-dollar' ,
    'dollar-square' ,
    'usd-square' ,
    'phone-arrow-right' ,
    'hand-holding-seedling' ,
    'message-check' ,
    'comment-alt-check' ,
    'cloud-bolt' ,
    'thunderstorm' ,
    'text-slash' ,
    'remove-format' ,
    'watch' ,
    'circle-down-left' ,
    'text' ,
    'projector' ,
    'face-smile-wink' ,
    'smile-wink' ,
    'tombstone-blank' ,
    'tombstone-alt' ,
    'chess-king-piece' ,
    'chess-king-alt' ,
    'circle-6' ,
    'left' ,
    'arrow-alt-left' ,
    'file-word' ,
    'file-powerpoint' ,
    'square-down' ,
    'arrow-alt-square-down' ,
    'objects-align-center-vertical' ,
    'arrows-left-right' ,
    'arrows-h' ,
    'house-lock' ,
    'cloud-arrow-down' ,
    'cloud-download' ,
    'cloud-download-alt' ,
    'wreath' ,
    'children' ,
    'meter-droplet' ,
    'chalkboard' ,
    'blackboard' ,
    'user-large-slash' ,
    'user-alt-slash' ,
    'signal-strong' ,
    'signal4' ,
    'lollipop' ,
    'lollypop' ,
    'list-tree' ,
    'envelope-open' ,
    'draw-circle' ,
    'cat-space' ,
    'handshake-simple-slash' ,
    'handshake-alt-slash' ,
    'rabbit-running' ,
    'rabbit-fast' ,
    'memo-pad' ,
    'mattress-pillow' ,
    'alarm-plus' ,
    'alicorn' ,
    'comment-question' ,
    'gingerbread-man' ,
    'guarani-sign' ,
    'burger-fries' ,
    'mug-tea' ,
    'border-top' ,
    'arrows-rotate' ,
    'refresh' ,
    'sync' ,
    'circle-book-open' ,
    'book-circle' ,
    'arrows-to-dotted-line' ,
    'fire-extinguisher' ,
    'garage-open' ,
    'shelves-empty' ,
    'cruzeiro-sign' ,
    'watch-apple' ,
    'watch-calculator' ,
    'list-dropdown' ,
    'cabinet-filing' ,
    'burger-soda' ,
    'square-arrow-up' ,
    'arrow-square-up' ,
    'greater-than-equal' ,
    'pallet-box' ,
    'face-confounded' ,
    'shield-halved' ,
    'shield-alt' ,
    'truck-plow' ,
    'book-atlas' ,
    'atlas' ,
    'virus' ,
    'comment-middle-top' ,
    'envelope-circle-check' ,
    'layer-group' ,
    'restroom-simple' ,
    'arrows-to-dot' ,
    'border-outer' ,
    'hashtag-lock' ,
    'clock-two-thirty' ,
    'archway' ,
    'heart-circle-check' ,
    'house-chimney-crack' ,
    'house-damage' ,
    'file-zipper' ,
    'file-archive' ,
    'heart-half' ,
    'comment-check' ,
    'square' ,
    'memo' ,
    'martini-glass-empty' ,
    'glass-martini' ,
    'couch' ,
    'cedi-sign' ,
    'italic' ,
    'glass-citrus' ,
    'calendar-lines-pen' ,
    'church' ,
    'person-snowmobiling' ,
    'snowmobile' ,
    'face-hushed' ,
    'comments-dollar' ,
    'pickaxe' ,
    'link-simple-slash' ,
    'democrat' ,
    'face-confused' ,
    'pinball' ,
    'z' ,
    'person-skiing' ,
    'skiing' ,
    'deer' ,
    'input-pipe' ,
    'road-lock' ,
    'a' ,
    'bookmark-slash' ,
    'temperature-arrow-down' ,
    'temperature-down' ,
    'mace' ,
    'feather-pointed' ,
    'feather-alt' ,
    'sausage' ,
    'trash-can-clock' ,
    'p' ,
    'snowflake' ,
    'stomach' ,
    'newspaper' ,
    'rectangle-ad' ,
    'ad' ,
    'guitar-electric' ,
    'arrow-turn-down-right' ,
    'moon-cloud' ,
    'bread-slice-butter' ,
    'circle-arrow-right' ,
    'arrow-circle-right' ,
    'user-group-crown' ,
    'users-crown' ,
    'circle-i' ,
    'toilet-paper-check' ,
    'filter-circle-xmark' ,
    'locust' ,
    'sort' ,
    'unsorted' ,
    'list-ol' ,
    'list12' ,
    'list-numeric' ,
    'chart-waterfall' ,
    'face-party' ,
    'kidneys' ,
    'wifi-exclamation' ,
    'chart-network' ,
    'person-dress-burst' ,
    'dice-d4' ,
    'money-check-dollar' ,
    'money-check-alt' ,
    'vector-square' ,
    'bread-slice' ,
    'language' ,
    'wheat-awn-slash' ,
    'face-kiss-wink-heart' ,
    'kiss-wink-heart' ,
    'dagger' ,
    'podium' ,
    'memo-circle-check' ,
    'route-highway' ,
    'down-to-line' ,
    'arrow-alt-to-bottom' ,
    'filter' ,
    'square-g' ,
    'circle-phone' ,
    'phone-circle' ,
    'clipboard-prescription' ,
    'user-nurse-hair' ,
    'question' ,
    'file-signature' ,
    'toggle-large-on' ,
    'up-down-left-right' ,
    'arrows-alt' ,
    'dryer-heat' ,
    'dryer-alt' ,
    'house-chimney-user' ,
    'hand-holding-heart' ,
    'arrow-up-small-big' ,
    'sort-size-up-alt' ,
    'train-track' ,
    'puzzle-piece' ,
    'money-check' ,
    'star-half-stroke' ,
    'star-half-alt' ,
    'file-exclamation' ,
    'code' ,
    'whiskey-glass' ,
    'glass-whiskey' ,
    'moon-stars' ,
    'building-circle-exclamation' ,
    'clothes-hanger' ,
    'mobile-notch' ,
    'mobile-iphone' ,
    'magnifying-glass-chart' ,
    'arrow-up-right-from-square' ,
    'external-link' ,
    'cubes-stacked' ,
    'images-user' ,
    'won-sign' ,
    'krw' ,
    'won' ,
    'image-polaroid-user' ,
    'virus-covid' ,
    'square-ellipsis' ,
    'pie' ,
    'chess-knight-piece' ,
    'chess-knight-alt' ,
    'austral-sign' ,
    'cloud-plus' ,
    'f' ,
    'leaf' ,
    'bed-bunk' ,
    'road' ,
    'taxi' ,
    'cab' ,
    'person-circle-plus' ,
    'chart-pie' ,
    'pie-chart' ,
    'bolt-lightning' ,
    'clock-eight' ,
    'sack-xmark' ,
    'file-excel' ,
    'file-contract' ,
    'fish-fins' ,
    'circle-q' ,
    'building-flag' ,
    'face-grin-beam' ,
    'grin-beam' ,
    'object-ungroup' ,
    'face-disguise' ,
    'circle-arrow-down-right' ,
    'alien-8bit' ,
    'alien-monster' ,
    'hand-point-ribbon' ,
    'poop' ,
    'object-exclude' ,
    'telescope' ,
    'location-pin' ,
    'map-marker' ,
    'square-list' ,
    'kaaba' ,
    'toilet-paper' ,
    'helmet-safety' ,
    'hard-hat' ,
    'hat-hard' ,
    'comment-code' ,
    'sim-cards' ,
    'starship' ,
    'eject' ,
    'circle-right' ,
    'arrow-alt-circle-right' ,
    'plane-circle-check' ,
    'seal' ,
    'user-cowboy' ,
    'hexagon-vertical-nft' ,
    'face-rolling-eyes' ,
    'meh-rolling-eyes' ,
    'bread-loaf' ,
    'rings-wedding' ,
    'object-group' ,
    'french-fries' ,
    'chart-line' ,
    'line-chart' ,
    'calendar-arrow-down' ,
    'calendar-download' ,
    'send-back' ,
    'mask-ventilator' ,
    'signature-lock' ,
    'arrow-right' ,
    'signs-post' ,
    'map-signs' ,
    'octagon-plus' ,
    'plus-octagon' ,
    'cash-register' ,
    'person-circle-question' ,
    'melon-slice' ,
    'space-station-moon' ,
    'message-smile' ,
    'comment-alt-smile' ,
    'cup-straw' ,
    'left-from-line' ,
    'arrow-alt-from-right' ,
    'h' ,
    'basket-shopping-simple' ,
    'shopping-basket-alt' ,
    'hands-holding-heart' ,
    'hands-heart' ,
    'clock-nine' ,
    'tarp' ,
    'face-sleepy' ,
    'hand-horns' ,
    'screwdriver-wrench' ,
    'tools' ,
    'arrows-to-eye' ,
    'circle-three-quarters' ,
    'trophy-star' ,
    'trophy-alt' ,
    'plug-circle-bolt' ,
    'face-thermometer' ,
    'shirt-running' ,
    'book-circle-arrow-up' ,
    'face-nauseated' ,
    'heart' ,
    'file-chart-pie' ,
    'mars-and-venus' ,
    'house-user' ,
    'home-user' ,
    'circle-arrow-down-left' ,
    'dumpster-fire' ,
    'hexagon-minus' ,
    'minus-hexagon' ,
    'left-to-line' ,
    'arrow-alt-to-left' ,
    'house-crack' ,
    'paw-simple' ,
    'paw-alt' ,
    'arrow-left-long-to-line' ,
    'brackets-round' ,
    'parentheses' ,
    'martini-glass-citrus' ,
    'cocktail' ,
    'user-shakespeare' ,
    'arrow-right-to-arc' ,
    'face-surprise' ,
    'surprise' ,
    'bottle-water' ,
    'circle-pause' ,
    'pause-circle' ,
    'gauge-circle-plus' ,
    'folders' ,
    'angel' ,
    'value-absolute' ,
    'rabbit' ,
    'toilet-paper-slash' ,
    'apple-whole' ,
    'apple-alt' ,
    'kitchen-set' ,
    'diamond-half' ,
    'lock-keyhole' ,
    'lock-alt' ,
    'r' ,
    'temperature-quarter' ,
    'temperature1' ,
    'thermometer1' ,
    'thermometer-quarter' ,
    'square-info' ,
    'info-square' ,
    'wifi-slash' ,
    'toilet-paper-xmark' ,
    'hands-holding-dollar' ,
    'hands-usd' ,
    'cube' ,
    'arrow-down-triangle-square' ,
    'sort-shapes-down' ,
    'bitcoin-sign' ,
    'shutters' ,
    'shield-dog' ,
    'solar-panel' ,
    'lock-open' ,
    'table-tree' ,
    'house-chimney-heart' ,
    'tally-3' ,
    'elevator' ,
    'money-bill-transfer' ,
    'money-bill-trend-up' ,
    'house-flood-water-circle-arrow-right' ,
    'square-poll-horizontal' ,
    'poll-h' ,
    'circle' ,
    'cart-circle-exclamation' ,
    'sword' ,
    'backward-fast' ,
    'fast-backward' ,
    'recycle' ,
    'user-astronaut' ,
    'interrobang' ,
    'plane-slash' ,
    'circle-dashed' ,
    'trademark' ,
    'basketball' ,
    'basketball-ball' ,
    'fork-knife' ,
    'utensils-alt' ,
    'satellite-dish' ,
    'badge-check' ,
    'circle-up' ,
    'arrow-alt-circle-up' ,
    'slider' ,
    'mobile-screen-button' ,
    'mobile-alt' ,
    'clock-one-thirty' ,
    'inbox-out' ,
    'inbox-arrow-up' ,
    'cloud-slash' ,
    'volume-high' ,
    'volume-up' ,
    'users-rays' ,
    'wallet' ,
    'octagon-check' ,
    'flatbread-stuffed' ,
    'clipboard-check' ,
    'cart-circle-plus' ,
    'truck-clock' ,
    'shipping-timed' ,
    'pool-8-ball' ,
    'file-audio' ,
    'turn-down-left' ,
    'lock-hashtag' ,
    'chart-radar' ,
    'staff' ,
    'burger' ,
    'hamburger' ,
    'utility-pole' ,
    'transporter-6' ,
    'wrench' ,
    'bugs' ,
    'vector-polygon' ,
    'diagram-nested' ,
    'rupee-sign' ,
    'rupee' ,
    'file-image' ,
    'circle-question' ,
    'question-circle' ,
    'image-user' ,
    'buoy' ,
    'plane-departure' ,
    'handshake-slash' ,
    'book-bookmark' ,
    'border-center-h' ,
    'can-food' ,
    'typewriter' ,
    'arrow-right-from-arc' ,
    'circle-k' ,
    'face-hand-over-mouth' ,
    'popcorn' ,
    'house-water' ,
    'house-flood' ,
    'object-subtract' ,
    'code-branch' ,
    'warehouse-full' ,
    'warehouse-alt' ,
    'hat-cowboy' ,
    'bridge' ,
    'phone-flip' ,
    'phone-alt' ,
    'arrow-down-from-dotted-line' ,
    'square-quarters' ,
    'truck-front' ,
    'cat' ,
    'trash-xmark' ,
    'circle-caret-left' ,
    'caret-circle-left' ,
    'files' ,
    'anchor-circle-exclamation' ,
    'face-clouds' ,
    'user-crown' ,
    'truck-field' ,
    'route' ,
    'cart-circle-check' ,
    'clipboard-question' ,
    'panorama' ,
    'comment-medical' ,
    'teeth-open' ,
    'user-tie-hair-long' ,
    'file-circle-minus' ,
    'head-side-medical' ,
    'tags' ,
    'wine-glass' ,
    'forward-fast' ,
    'fast-forward' ,
    'face-meh-blank' ,
    'meh-blank' ,
    'user-robot' ,
    'square-parking' ,
    'parking' ,
    'card-diamond' ,
    'face-zipper' ,
    'face-raised-eyebrow' ,
    'house-signal' ,
    'square-chevron-up' ,
    'chevron-square-up' ,
    'nfc-symbol' ,
    'bars-progress' ,
    'tasks-alt' ,
    'faucet-drip' ,
    'arrows-to-line' ,
    'dolphin' ,
    'arrow-up-right' ,
    'circle-r' ,
    'cart-flatbed' ,
    'dolly-flatbed' ,
    'ban-smoking' ,
    'smoking-ban' ,
    'circle-sort-up' ,
    'sort-circle-up' ,
    'terminal' ,
    'mobile-button' ,
    'house-medical-flag' ,
    'basket-shopping' ,
    'shopping-basket' ,
    'tape' ,
    'chestnut' ,
    'bus-simple' ,
    'bus-alt' ,
    'eye' ,
    'face-sad-cry' ,
    'sad-cry' ,
    'heat' ,
    'ticket-airline' ,
    'boot-heeled' ,
    'arrows-minimize' ,
    'compress-arrows' ,
    'audio-description' ,
    'person-military-to-person' ,
    'file-shield' ,
    'hexagon' ,
    'manhole' ,
    'user-slash' ,
    'pen' ,
    'tower-observation' ,
    'floppy-disks' ,
    'toilet-paper-blank-under' ,
    'toilet-paper-reverse-alt' ,
    'file-code' ,
    'signal' ,
    'signal5' ,
    'signal-perfect' ,
    'pump' ,
    'bus' ,
    'heart-circle-xmark' ,
    'arrow-up-left-from-circle' ,
    'house-chimney' ,
    'home-lg' ,
    'window-maximize' ,
    'dryer' ,
    'face-frown' ,
    'frown' ,
    'chess-bishop-piece' ,
    'chess-bishop-alt' ,
    'shirt-tank-top' ,
    'diploma' ,
    'scroll-ribbon' ,
    'screencast' ,
    'walker' ,
    'prescription' ,
    'shop' ,
    'store-alt' ,
    'floppy-disk' ,
    'save' ,
    'vihara' ,
    'face-kiss-closed-eyes' ,
    'scale-unbalanced' ,
    'balance-scale-left' ,
    'file-user' ,
    'user-police-tie' ,
    'face-tongue-money' ,
    'tennis-ball' ,
    'square-l' ,
    'sort-up' ,
    'sort-asc' ,
    'calendar-arrow-up' ,
    'calendar-upload' ,
    'comment-dots' ,
    'commenting' ,
    'plant-wilt' ,
    'scarf' ,
    'album-circle-plus' ,
    'user-nurse-hair-long' ,
    'diamond' ,
    'square-left' ,
    'arrow-alt-square-left' ,
    'face-grin-squint' ,
    'grin-squint' ,
    'circle-ellipsis-vertical' ,
    'hand-holding-dollar' ,
    'hand-holding-usd' ,
    'grid-dividers' ,
    'bacterium' ,
    'hand-pointer' ,
    'drum-steelpan' ,
    'hand-scissors' ,
    'hands-praying' ,
    'praying-hands' ,
    'face-pensive' ,
    'user-music' ,
    'arrow-rotate-right' ,
    'arrow-right-rotate' ,
    'arrow-rotate-forward' ,
    'redo' ,
    'messages-dollar' ,
    'comments-alt-dollar' ,
    'sensor-on' ,
    'balloon' ,
    'biohazard' ,
    'chess-queen-piece' ,
    'chess-queen-alt' ,
    'location-crosshairs' ,
    'location' ,
    'mars-double' ,
    'house-person-leave' ,
    'house-leave' ,
    'house-person-depart' ,
    'ruler-triangle' ,
    'card-club' ,
    'child-dress' ,
    'users-between-lines' ,
    'lungs-virus' ,
    'spinner-third' ,
    'face-grin-tears' ,
    'grin-tears' ,
    'phone' ,
    'computer-mouse-scrollwheel' ,
    'mouse-alt' ,
    'calendar-xmark' ,
    'calendar-times' ,
    'child-reaching' ,
    'table-layout' ,
    'narwhal' ,
    'ramp-loading' ,
    'calendar-circle-plus' ,
    'toothbrush' ,
    'border-inner' ,
    'paw-claws' ,
    'kiwi-fruit' ,
    'traffic-light-slow' ,
    'rectangle-code' ,
    'head-side-virus' ,
    'keyboard-brightness' ,
    'books-medical' ,
    'lightbulb-slash' ,
    'house-blank' ,
    'home-blank' ,
    'square-5' ,
    'square-heart' ,
    'heart-square' ,
    'puzzle' ,
    'user-gear' ,
    'user-cog' ,
    'pipe-circle-check' ,
    'arrow-up-1-9' ,
    'sort-numeric-up' ,
    'octagon-exclamation' ,
    'dial-low' ,
    'door-closed' ,
    'laptop-mobile' ,
    'phone-laptop' ,
    'conveyor-belt-boxes' ,
    'conveyor-belt-alt' ,
    'shield-virus' ,
    'starfighter-twin-ion-engine-advanced' ,
    'starfighter-alt-advanced' ,
    'dice-six' ,
    'starfighter-twin-ion-engine' ,
    'starfighter-alt' ,
    'rocket-launch' ,
    'mosquito-net' ,
    'vent-damper' ,
    'bridge-water' ,
    'ban-bug' ,
    'debug' ,
    'person-booth' ,
    'text-width' ,
    'garage-car' ,
    'square-kanban' ,
    'hat-wizard' ,
    'pen-fancy' ,
    'coffee-pot' ,
    'mouse-field' ,
    'person-digging' ,
    'digging' ,
    'shower-down' ,
    'shower-alt' ,
    'box-circle-check' ,
    'brightness' ,
    'car-side-bolt' ,
    'ornament' ,
    'phone-arrow-down-left' ,
    'phone-arrow-down' ,
    'phone-incoming' ,
    'cloud-word' ,
    'hand-fingers-crossed' ,
    'trash' ,
    'gauge-simple' ,
    'gauge-simple-med' ,
    'tachometer-average' ,
    'arrow-down-small-big' ,
    'sort-size-down-alt' ,
    'book-medical' ,
    'face-melting' ,
    'poo' ,
    'pen-clip-slash' ,
    'pen-alt-slash' ,
    'quote-right' ,
    'quote-right-alt' ,
    'scroll-old' ,
    'guitars' ,
    'phone-xmark' ,
    'hose' ,
    'clock-six' ,
    'shirt' ,
    't-shirt' ,
    'tshirt' ,
    'square-r' ,
    'cubes' ,
    'envelope-open-dollar' ,
    'divide' ,
    'sun-cloud' ,
    'lamp-floor' ,
    'square-7' ,
    'tenge-sign' ,
    'tenge' ,
    'headphones' ,
    'hands-holding' ,
    'campfire' ,
    'circle-ampersand' ,
    'snowflakes' ,
    'hands-clapping' ,
    'republican' ,
    'leaf-maple' ,
    'arrow-left' ,
    'person-circle-xmark' ,
    'ruler' ,
    'cup-straw-swoosh' ,
    'temperature-sun' ,
    'temperature-hot' ,
    'align-left' ,
    'dice-d6' ,
    'restroom' ,
    'high-definition' ,
    'rectangle-hd' ,
    'j' ,
    'galaxy' ,
    'users-viewfinder' ,
    'file-video' ,
    'cherries' ,
    'up-right-from-square' ,
    'external-link-alt' ,
    'circle-sort' ,
    'sort-circle' ,
    'table-cells' ,
    'th' ,
    'file-pdf' ,
    'siren' ,
    'arrow-up-to-dotted-line' ,
    'image-landscape' ,
    'landscape' ,
    'tank-water' ,
    'curling-stone' ,
    'curling' ,
    'gamepad-modern' ,
    'gamepad-alt' ,
    'messages-question' ,
    'book-bible' ,
    'bible' ,
    'o' ,
    'suitcase-medical' ,
    'medkit' ,
    'briefcase-arrow-right' ,
    'expand-wide' ,
    'clock-eleven-thirty' ,
    'rv' ,
    'user-secret' ,
    'otter' ,
    'dreidel' ,
    'person-dress' ,
    'female' ,
    'comment-dollar' ,
    'business-time' ,
    'briefcase-clock' ,
    'flower-tulip' ,
    'people-pants-simple' ,
    'cloud-drizzle' ,
    'table-cells-large' ,
    'th-large' ,
    'book-tanakh' ,
    'tanakh' ,
    'solar-system' ,
    'seal-question' ,
    'phone-volume' ,
    'volume-control-phone' ,
    'disc-drive' ,
    'hat-cowboy-side' ,
    'table-rows' ,
    'rows' ,
    'location-exclamation' ,
    'map-marker-exclamation' ,
    'face-fearful' ,
    'clipboard-user' ,
    'bus-school' ,
    'film-slash' ,
    'square-arrow-down-right' ,
    'book-sparkles' ,
    'book-spells' ,
    'washing-machine' ,
    'washer' ,
    'child' ,
    'lira-sign' ,
    'user-visor' ,
    'file-plus-minus' ,
    'chess-clock-flip' ,
    'chess-clock-alt' ,
    'satellite' ,
    'plane-lock' ,
    'steering-wheel' ,
    'tag' ,
    'stretcher' ,
    'book-section' ,
    'book-law' ,
    'inboxes' ,
    'coffee-bean' ,
    'brackets-curly' ,
    'ellipsis-stroke-vertical' ,
    'ellipsis-v-alt' ,
    'comment' ,
    'square-1' ,
    'cake-candles' ,
    'birthday-cake' ,
    'cake' ,
    'head-side' ,
    'envelope' ,
    'dolly-empty' ,
    'face-tissue' ,
    'angles-up' ,
    'angle-double-up' ,
    'paperclip' ,
    'chart-line-down' ,
    'arrow-right-to-city' ,
    'lock-a' ,
    'ribbon' ,
    'lungs' ,
    'person-pinball' ,
    'arrow-up-9-1' ,
    'sort-numeric-up-alt' ,
    'apple-core' ,
    'circle-y' ,
    'h6' ,
    'litecoin-sign' ,
    'circle-small' ,
    'border-none' ,
    'arrow-turn-down-left' ,
    'circle-nodes' ,
    'parachute-box' ,
    'message-medical' ,
    'comment-alt-medical' ,
    'rugby-ball' ,
    'comment-music' ,
    'indent' ,
    'tree-deciduous' ,
    'tree-alt' ,
    'puzzle-piece-simple' ,
    'puzzle-piece-alt' ,
    'truck-field-un' ,
    'nfc-trash' ,
    'hourglass' ,
    'hourglass-empty' ,
    'mountain' ,
    'file-xmark' ,
    'file-times' ,
    'house-heart' ,
    'home-heart' ,
    'house-chimney-blank' ,
    'meter-bolt' ,
    'user-doctor' ,
    'user-md' ,
    'slash-back' ,
    'circle-info' ,
    'info-circle' ,
    'fishing-rod' ,
    'hammer-crash' ,
    'message-heart' ,
    'cloud-meatball' ,
    'camera-polaroid' ,
    'camera' ,
    'camera-alt' ,
    'square-virus' ,
    'cart-arrow-up' ,
    'meteor' ,
    'car-on' ,
    'sleigh' ,
    'arrow-down-1-9' ,
    'sort-numeric-asc' ,
    'sort-numeric-down' ,
    'buoy-mooring' ,
    'square-4' ,
    'hand-holding-droplet' ,
    'hand-holding-water' ,
    'tricycle-adult' ,
    'waveform' ,
    'water' ,
    'star-sharp-half-stroke' ,
    'star-sharp-half-alt' ,
    'nfc-signal' ,
    'plane-prop' ,
    'calendar-check' ,
    'clock-desk' ,
    'calendar-clock' ,
    'calendar-time' ,
    'braille' ,
    'prescription-bottle-medical' ,
    'prescription-bottle-alt' ,
    'plate-utensils' ,
    'family-pants' ,
    'hose-reel' ,
    'house-window' ,
    'landmark' ,
    'truck' ,
    'crosshairs' ,
    'cloud-rainbow' ,
    'person-cane' ,
    'alien' ,
    'tent' ,
    'vest-patches' ,
    'people-dress-simple' ,
    'check-double' ,
    'arrow-down-a-z' ,
    'sort-alpha-asc' ,
    'sort-alpha-down' ,
    'bowling-ball-pin' ,
    'bell-school-slash' ,
    'plus-large' ,
    'money-bill-wheat' ,
    'camera-viewfinder' ,
    'screenshot' ,
    'message-music' ,
    'comment-alt-music' ,
    'car-building' ,
    'border-bottom-right' ,
    'border-style-alt' ,
    'octagon' ,
    'comment-arrow-up-right' ,
    'octagon-divide' ,
    'cookie' ,
    'arrow-rotate-left' ,
    'arrow-left-rotate' ,
    'arrow-rotate-back' ,
    'arrow-rotate-backward' ,
    'undo' ,
    'tv-music' ,
    'hard-drive' ,
    'hdd' ,
    'reel' ,
    'face-grin-squint-tears' ,
    'grin-squint-tears' ,
    'dumbbell' ,
    'rectangle-list' ,
    'list-alt' ,
    'tarp-droplet' ,
    'alarm-exclamation' ,
    'house-medical-circle-check' ,
    'traffic-cone' ,
    'grate' ,
    'arrow-down-right' ,
    'person-skiing-nordic' ,
    'skiing-nordic' ,
    'calendar-plus' ,
    'person-from-portal' ,
    'portal-exit' ,
    'plane-arrival' ,
    'cowbell-circle-plus' ,
    'cowbell-more' ,
    'circle-left' ,
    'arrow-alt-circle-left' ,
    'distribute-spacing-vertical' ,
    'signal-bars-fair' ,
    'signal-alt2' ,
    'sportsball' ,
    'game-console-handheld-crank' ,
    'train-subway' ,
    'subway' ,
    'chart-gantt' ,
    'face-smile-upside-down' ,
    'ball-pile' ,
    'badge-dollar' ,
    'money-bills-simple' ,
    'money-bills-alt' ,
    'list-timeline' ,
    'indian-rupee-sign' ,
    'indian-rupee' ,
    'inr' ,
    'crop-simple' ,
    'crop-alt' ,
    'money-bill-1' ,
    'money-bill-alt' ,
    'left-long' ,
    'long-arrow-alt-left' ,
    'keyboard-down' ,
    'circle-up-right' ,
    'cloud-bolt-moon' ,
    'thunderstorm-moon' ,
    'dna' ,
    'virus-slash' ,
    'bracket-round-right' ,
    'circle-5' ,
    'minus' ,
    'subtract' ,
    'fire-flame' ,
    'flame' ,
    'right-to-line' ,
    'arrow-alt-to-right' ,
    'gif' ,
    'chess' ,
    'trash-slash' ,
    'arrow-left-long' ,
    'long-arrow-left' ,
    'plug-circle-check' ,
    'font-case' ,
    'street-view' ,
    'arrow-down-left' ,
    'franc-sign' ,
    'flask-round-poison' ,
    'flask-poison' ,
    'volume-off' ,
    'book-circle-arrow-right' ,
    'chart-user' ,
    'user-chart' ,
    'hands-asl-interpreting' ,
    'american-sign-language-interpreting' ,
    'asl-interpreting' ,
    'hands-american-sign-language-interpreting' ,
    'presentation-screen' ,
    'presentation' ,
    'circle-bolt' ,
    'face-smile-halo' ,
    'cart-circle-arrow-down' ,
    'house-person-return' ,
    'house-person-arrive' ,
    'house-return' ,
    'message-xmark' ,
    'comment-alt-times' ,
    'message-times' ,
    'file-certificate' ,
    'file-award' ,
    'user-doctor-hair-long' ,
    'camera-security' ,
    'camera-home' ,
    'gear' ,
    'cog' ,
    'droplet-slash' ,
    'tint-slash' ,
    'book-heart' ,
    'mosque' ,
    'duck' ,
    'mosquito' ,
    'star-of-david' ,
    'flag-swallowtail' ,
    'flag-alt' ,
    'person-military-rifle' ,
    'car-garage' ,
    'cart-shopping' ,
    'shopping-cart' ,
    'book-font' ,
    'shield-plus' ,
    'vials' ,
    'eye-dropper-full' ,
    'distribute-spacing-horizontal' ,
    'tablet-rugged' ,
    'temperature-snow' ,
    'temperature-frigid' ,
    'moped' ,
    'face-smile-plus' ,
    'smile-plus' ,
    'radio-tuner' ,
    'radio-alt' ,
    'face-swear' ,
    'water-arrow-down' ,
    'water-lower' ,
    'scanner-touchscreen' ,
    'circle-7' ,
    'plug-circle-plus' ,
    'person-ski-jumping' ,
    'ski-jump' ,
    'place-of-worship' ,
    'water-arrow-up' ,
    'water-rise' ,
    'waveform-lines' ,
    'waveform-path' ,
    'split' ,
    'film-canister' ,
    'film-cannister' ,
    'folder-xmark' ,
    'folder-times' ,
    'toilet-paper-blank' ,
    'toilet-paper-alt' ,
    'tablet-screen' ,
    'tablet-android-alt' ,
    'hexagon-vertical-nft-slanted' ,
    'folder-music' ,
    'display-medical' ,
    'desktop-medical' ,
    'share-all' ,
    'peapod' ,
    'chess-clock' ,
    'axe' ,
    'square-d' ,
    'grip-vertical' ,
    'mobile-signal-out' ,
    'arrow-turn-up' ,
    'level-up' ,
    'u' ,
    'arrow-up-from-dotted-line' ,
    'square-root-variable' ,
    'square-root-alt' ,
    'light-switch-on' ,
    'arrow-down-arrow-up' ,
    'sort-alt' ,
    'raindrops' ,
    'dash' ,
    'minus-large' ,
    'clock' ,
    'clock-four' ,
    'input-numeric' ,
    'truck-tow' ,
    'backward-step' ,
    'step-backward' ,
    'pallet' ,
    'car-bolt' ,
    'arrows-maximize' ,
    'expand-arrows' ,
    'faucet' ,
    'cloud-sleet' ,
    'lamp-street' ,
    'list-radio' ,
    'pen-nib-slash' ,
    'baseball-bat-ball' ,
    'square-up-left' ,
    'overline' ,
    's' ,
    'timeline' ,
    'keyboard' ,
    'arrows-from-dotted-line' ,
    'usb-drive' ,
    'ballot' ,
    'caret-down' ,
    'location-dot-slash' ,
    'map-marker-alt-slash' ,
    'cards' ,
    'house-chimney-medical' ,
    'clinic-medical' ,
    'boxing-glove' ,
    'glove-boxing' ,
    'temperature-three-quarters' ,
    'temperature3' ,
    'thermometer3' ,
    'thermometer-three-quarters' ,
    'bell-school' ,
    'mobile-screen' ,
    'mobile-android-alt' ,
    'plane-up' ,
    'folder-heart' ,
    'circle-location-arrow' ,
    'location-circle' ,
    'face-head-bandage' ,
    'sushi-roll' ,
    'maki-roll' ,
    'makizushi' ,
    'car-bump' ,
    'piggy-bank' ,
    'racquet' ,
    'car-mirrors' ,
    'industry-windows' ,
    'industry-alt' ,
    'bolt-auto' ,
    'battery-half' ,
    'battery3' ,
    'flux-capacitor' ,
    'mountain-city' ,
    'coins' ,
    'honey-pot' ,
    'olive' ,
    'khanda' ,
    'filter-list' ,
    'outlet' ,
    'sliders' ,
    'sliders-h' ,
    'cauldron' ,
    'people' ,
    'folder-tree' ,
    'network-wired' ,
    'croissant' ,
    'map-pin' ,
    'hamsa' ,
    'cent-sign' ,
    'swords-laser' ,
    'flask' ,
    'person-pregnant' ,
    'square-u' ,
    'wand-sparkles' ,
    'router' ,
    'ellipsis-vertical' ,
    'ellipsis-v' ,
    'sword-laser-alt' ,
    'ticket' ,
    'power-off' ,
    'coin' ,
    'laptop-slash' ,
    'right-long' ,
    'long-arrow-alt-right' ,
    'circle-b' ,
    'person-dress-simple' ,
    'pipe-collar' ,
    'lights-holiday' ,
    'citrus' ,
    'flag-usa' ,
    'laptop-file' ,
    'tty' ,
    'teletype' ,
    'chart-tree-map' ,
    'diagram-next' ,
    'person-rifle' ,
    'clock-five-thirty' ,
    'pipe-valve' ,
    'arrow-up-from-arc' ,
    'face-spiral-eyes' ,
    'compress-wide' ,
    'circle-phone-hangup' ,
    'phone-circle-down' ,
    'house-medical-circle-exclamation' ,
    'badminton' ,
    'closed-captioning' ,
    'person-hiking' ,
    'hiking' ,
    'right-from-line' ,
    'arrow-alt-from-left' ,
    'venus-double' ,
    'images' ,
    'calculator' ,
    'shuttlecock' ,
    'user-hair' ,
    'eye-evil' ,
    'people-pulling' ,
    'n' ,
    'garage' ,
    'cable-car' ,
    'tram' ,
    'shovel-snow' ,
    'cloud-rain' ,
    'face-lying' ,
    'sprinkler' ,
    'building-circle-xmark' ,
    'person-sledding' ,
    'sledding' ,
    'game-console-handheld' ,
    'ship' ,
    'clock-six-thirty' ,
    'battery-slash' ,
    'tugrik-sign' ,
    'arrows-down-to-line' ,
    'download' ,
    'shelves' ,
    'inventory' ,
    'cloud-snow' ,
    'face-grin' ,
    'grin' ,
    'delete-left' ,
    'backspace' ,
    'oven' ,
    'eye-dropper' ,
    'eye-dropper-empty' ,
    'eyedropper' ,
    'comment-captions' ,
    'comments-question' ,
    'scribble' ,
    'rotate-exclamation' ,
    'file-circle-check' ,
    'glass' ,
    'loader' ,
    'forward' ,
    'user-pilot' ,
    'mobile' ,
    'mobile-android' ,
    'mobile-phone' ,
    'code-pull-request-closed' ,
    'face-meh' ,
    'meh' ,
    'align-center' ,
    'book-skull' ,
    'book-dead' ,
    'id-card' ,
    'drivers-license' ,
    'face-dotted' ,
    'face-worried' ,
    'outdent' ,
    'dedent' ,
    'heart-circle-exclamation' ,
    'house' ,
    'home' ,
    'home-alt' ,
    'home-lg-alt' ,
    'vector-circle' ,
    'car-circle-bolt' ,
    'calendar-week' ,
    'flying-disc' ,
    'laptop-medical' ,
    'square-down-right' ,
    'b' ,
    'seat-airline' ,
    'moon-over-sun' ,
    'eclipse-alt' ,
    'pipe' ,
    'file-medical' ,
    'potato' ,
    'dice-one' ,
    'circle-a' ,
    'helmet-battle' ,
    'butter' ,
    'blanket-fire' ,
    'kiwi-bird' ,
    'castle' ,
    'golf-club' ,
    'arrow-right-arrow-left' ,
    'exchange' ,
    'rotate-right' ,
    'redo-alt' ,
    'rotate-forward' ,
    'utensils' ,
    'cutlery' ,
    'arrow-up-wide-short' ,
    'sort-amount-up' ,
    'balloons' ,
    'mill-sign' ,
    'bowl-rice' ,
    'timeline-arrow' ,
    'skull' ,
    'game-board-simple' ,
    'game-board-alt' ,
    'circle-video' ,
    'video-circle' ,
    'chart-scatter-bubble' ,
    'house-turret' ,
    'banana' ,
    'hand-holding-skull' ,
    'people-dress' ,
    'loveseat' ,
    'couch-small' ,
    'tower-broadcast' ,
    'broadcast-tower' ,
    'truck-pickup' ,
    'block-quote' ,
    'up-long' ,
    'long-arrow-alt-up' ,
    'stop' ,
    'code-merge' ,
    'money-check-dollar-pen' ,
    'money-check-edit-alt' ,
    'up-from-line' ,
    'arrow-alt-from-bottom' ,
    'upload' ,
    'hurricane' ,
    'people-pants' ,
    'mound' ,
    'windsock' ,
    'circle-half' ,
    'brake-warning' ,
    'toilet-portable' ,
    'compact-disc' ,
    'file-arrow-down' ,
    'file-download' ,
    'saxophone-fire' ,
    'sax-hot' ,
    'camera-web-slash' ,
    'webcam-slash' ,
    'folder-medical' ,
    'folder-gear' ,
    'folder-cog' ,
    'hand-wave' ,
    'arrow-up-arrow-down' ,
    'sort-up-down' ,
    'caravan' ,
    'shield-cat' ,
    'message-slash' ,
    'comment-alt-slash' ,
    'bolt' ,
    'zap' ,
    'trash-can-check' ,
    'glass-water' ,
    'oil-well' ,
    'person-simple' ,
    'vault' ,
    'mars' ,
    'toilet' ,
    'plane-circle-xmark' ,
    'yen-sign' ,
    'cny' ,
    'jpy' ,
    'rmb' ,
    'yen' ,
    'notes' ,
    'ruble-sign' ,
    'rouble' ,
    'rub' ,
    'ruble' ,
    'trash-undo' ,
    'trash-arrow-turn-left' ,
    'champagne-glass' ,
    'glass-champagne' ,
    'objects-align-center-horizontal' ,
    'sun' ,
    'trash-can-slash' ,
    'trash-alt-slash' ,
    'screen-users' ,
    'users-class' ,
    'guitar' ,
    'square-arrow-left' ,
    'arrow-square-left' ,
    'square-8' ,
    'face-smile-hearts' ,
    'brackets-square' ,
    'brackets' ,
    'laptop-arrow-down' ,
    'hockey-stick-puck' ,
    'house-tree' ,
    'signal-fair' ,
    'signal2' ,
    'face-laugh-wink' ,
    'laugh-wink' ,
    'circle-dollar' ,
    'dollar-circle' ,
    'usd-circle' ,
    'horse-head' ,
    'arrows-repeat' ,
    'repeat-alt' ,
    'bore-hole' ,
    'industry' ,
    'image-polaroid' ,
    'wave-triangle' ,
    'circle-down' ,
    'arrow-alt-circle-down' ,
    'grill' ,
    'arrows-turn-to-dots' ,
    'chart-mixed' ,
    'analytics' ,
    'florin-sign' ,
    'arrow-down-short-wide' ,
    'sort-amount-desc' ,
    'sort-amount-down-alt' ,
    'less-than' ,
    'display-code' ,
    'desktop-code' ,
    'face-drooling' ,
    'oil-temperature' ,
    'oil-temp' ,
    'square-question' ,
    'question-square' ,
    'air-conditioner' ,
    'angle-down' ,
    'mountains' ,
    'omega' ,
    'car-tunnel' ,
    'person-dolly-empty' ,
    'pan-food' ,
    'head-side-cough' ,
    'grip-lines' ,
    'thumbs-down' ,
    'user-lock' ,
    'arrow-right-long' ,
    'long-arrow-right' ,
    'tickets-airline' ,
    'anchor-circle-xmark' ,
    'ellipsis' ,
    'ellipsis-h' ,
    'nfc-slash' ,
    'chess-pawn' ,
    'kit-medical' ,
    'first-aid' ,
    'grid-2-plus' ,
    'bells' ,
    'person-through-window' ,
    'toolbox' ,
    'envelope-dot' ,
    'envelope-badge' ,
    'hands-holding-circle' ,
    'bug' ,
    'bowl-chopsticks' ,
    'credit-card' ,
    'credit-card-alt' ,
    'circle-s' ,
    'box-ballot' ,
    'car' ,
    'automobile' ,
    'hand-holding-hand' ,
    'user-tie-hair' ,
    'podium-star' ,
    'user-hair-mullet' ,
    'business-front' ,
    'party-back' ,
    'trian-balbot' ,
    'microphone-stand' ,
    'book-open-reader' ,
    'book-reader' ,
    'family-dress' ,
    'circle-x' ,
    'cabin' ,
    'mountain-sun' ,
    'chart-simple-horizontal' ,
    'bluetooth' ,
    'arrows-left-right-to-line' ,
    'hand-back-point-left' ,
    'message-dots' ,
    'comment-alt-dots' ,
    'messaging' ,
    'file-heart' ,
    'beer-mug' ,
    'beer-foam' ,
    'dice-d20' ,
    'drone' ,
    'truck-droplet' ,
    'file-circle-xmark' ,
    'temperature-arrow-up' ,
    'temperature-up' ,
    'medal' ,
    'bed' ,
    'book-copy' ,
    'square-h' ,
    'h-square' ,
    'square-c' ,
    'clock-two' ,
    'square-ellipsis-vertical' ,
    'podcast' ,
    'bee' ,
    'temperature-full' ,
    'temperature4' ,
    'thermometer4' ,
    'thermometer-full' ,
    'bell' ,
    'candy-bar' ,
    'chocolate-bar' ,
    'xmark-large' ,
    'pinata' ,
    'arrows-from-line' ,
    'superscript' ,
    'bowl-spoon' ,
    'hexagon-check' ,
    'plug-circle-xmark' ,
    'star-of-life' ,
    'phone-slash' ,
    'traffic-light-stop' ,
    'paint-roller' ,
    'accent-grave' ,
    'handshake-angle' ,
    'hands-helping' ,
    'circle-0' ,
    'dial-med-low' ,
    'location-dot' ,
    'map-marker-alt' ,
    'crab' ,
    'box-open-full' ,
    'box-full' ,
    'file' ,
    'greater-than' ,
    'quotes' ,
    'pretzel' ,
    'person-swimming' ,
    'swimmer' ,
    'arrow-down' ,
    'user-robot-xmarks' ,
    'message-quote' ,
    'comment-alt-quote' ,
    'candy-corn' ,
    'folder-magnifying-glass' ,
    'folder-search' ,
    'notebook' ,
    'droplet' ,
    'tint' ,
    'bullseye-pointer' ,
    'eraser' ,
    'hexagon-image' ,
    'earth-americas' ,
    'earth' ,
    'earth-america' ,
    'globe-americas' ,
    'crate-apple' ,
    'apple-crate' ,
    'person-burst' ,
    'game-board' ,
    'hat-chef' ,
    'hand-back-point-right' ,
    'dove' ,
    'snowflake-droplets' ,
    'battery-empty' ,
    'battery0' ,
    'grid-4' ,
    'socks' ,
    'face-sunglasses' ,
    'inbox' ,
    'square-0' ,
    'section' ,
    'square-this-way-up' ,
    'box-up' ,
    'gauge-high' ,
    'tachometer-alt' ,
    'tachometer-alt-fast' ,
    'square-ampersand' ,
    'envelope-open-text' ,
    'lamp-desk' ,
    'hospital' ,
    'hospital-alt' ,
    'hospital-wide' ,
    'poll-people' ,
    'whiskey-glass-ice' ,
    'glass-whiskey-rocks' ,
    'wine-bottle' ,
    'chess-rook' ,
    'user-bounty-hunter' ,
    'bars-staggered' ,
    'reorder' ,
    'stream' ,
    'diagram-sankey' ,
    'cloud-hail-mixed' ,
    'circle-up-left' ,
    'dharmachakra' ,
    'objects-align-left' ,
    'oil-can-drip' ,
    'face-smiling-hands' ,
    'broccoli' ,
    'route-interstate' ,
    'ear-muffs' ,
    'hotdog' ,
    'transporter-empty' ,
    'person-walking-with-cane' ,
    'blind' ,
    'angle-90' ,
    'rectangle-terminal' ,
    'kite' ,
    'drum' ,
    'scrubber' ,
    'ice-cream' ,
    'heart-circle-bolt' ,
    'fish-bones' ,
    'deer-rudolph' ,
    'fax' ,
    'paragraph' ,
    'head-side-heart' ,
    'square-e' ,
    'meter-fire' ,
    'cloud-hail' ,
    'check-to-slot' ,
    'vote-yea' ,
    'money-from-bracket' ,
    'star-half' ,
    'car-bus' ,
    'speaker' ,
    'timer' ,
    'boxes-stacked' ,
    'boxes' ,
    'boxes-alt' ,
    'grill-hot' ,
    'ballot-check' ,
    'link' ,
    'chain' ,
    'ear-listen' ,
    'assistive-listening-systems' ,
    'file-minus' ,
    'tree-city' ,
    'play' ,
    'font' ,
    'cup-togo' ,
    'coffee-togo' ,
    'square-down-left' ,
    'burger-lettuce' ,
    'rupiah-sign' ,
    'magnifying-glass' ,
    'search' ,
    'table-tennis-paddle-ball' ,
    'ping-pong-paddle-ball' ,
    'table-tennis' ,
    'person-dots-from-line' ,
    'diagnoses' ,
    'chevrons-down' ,
    'chevron-double-down' ,
    'trash-can-arrow-up' ,
    'trash-restore-alt' ,
    'signal-good' ,
    'signal3' ,
    'location-question' ,
    'map-marker-question' ,
    'floppy-disk-circle-xmark' ,
    'floppy-disk-times' ,
    'save-circle-xmark' ,
    'save-times' ,
    'naira-sign' ,
    'peach' ,
    'taxi-bus' ,
    'bracket-curly' ,
    'bracket-curly-left' ,
    'lobster' ,
    'cart-flatbed-empty' ,
    'dolly-flatbed-empty' ,
    'colon' ,
    'cart-arrow-down' ,
    'wand' ,
    'walkie-talkie' ,
    'file-pen' ,
    'file-edit' ,
    'receipt' ,
    'table-picnic' ,
    'square-pen' ,
    'pen-square' ,
    'pencil-square' ,
    'circle-microphone-lines' ,
    'microphone-circle-alt' ,
    'display-slash' ,
    'desktop-slash' ,
    'suitcase-rolling' ,
    'person-circle-exclamation' ,
    'transporter-2' ,
    'hands-holding-diamond' ,
    'hand-receiving' ,
    'money-bill-simple-wave' ,
    'chevron-down' ,
    'battery-full' ,
    'battery' ,
    'battery5' ,
    'bell-plus' ,
    'book-arrow-right' ,
    'hospitals' ,
    'club' ,
    'skull-crossbones' ,
    'droplet-degree' ,
    'dewpoint' ,
    'code-compare' ,
    'list-ul' ,
    'list-dots' ,
    'hand-holding-magic' ,
    'watermelon-slice' ,
    'circle-ellipsis' ,
    'school-lock' ,
    'tower-cell' ,
    'sd-cards' ,
    'down-long' ,
    'long-arrow-alt-down' ,
    'envelopes' ,
    'phone-office' ,
    'ranking-star' ,
    'chess-king' ,
    'nfc-pen' ,
    'person-harassing' ,
    'hat-winter' ,
    'brazilian-real-sign' ,
    'landmark-dome' ,
    'landmark-alt' ,
    'bone-break' ,
    'arrow-up' ,
    'down-from-dotted-line' ,
    'tv' ,
    'television' ,
    'tv-alt' ,
    'border-left' ,
    'circle-divide' ,
    'shrimp' ,
    'list-check' ,
    'tasks' ,
    'diagram-subtask' ,
    'jug-detergent' ,
    'circle-user' ,
    'user-circle' ,
    'square-y' ,
    'user-doctor-hair' ,
    'planet-ringed' ,
    'mushroom' ,
    'user-shield' ,
    'megaphone' ,
    'circle-exclamation-check' ,
    'wind' ,
    'box-dollar' ,
    'box-usd' ,
    'car-burst' ,
    'car-crash' ,
    'y' ,
    'user-headset' ,
    'arrows-retweet' ,
    'retweet-alt' ,
    'person-snowboarding' ,
    'snowboarding' ,
    'square-chevron-right' ,
    'chevron-square-right' ,
    'lacrosse-stick-ball' ,
    'truck-fast' ,
    'shipping-fast' ,
    'user-magnifying-glass' ,
    'star-sharp' ,
    'comment-heart' ,
    'circle-1' ,
    'circle-star' ,
    'star-circle' ,
    'fish' ,
    'cloud-fog' ,
    'fog' ,
    'waffle' ,
    'music-note' ,
    'music-alt' ,
    'hexagon-exclamation' ,
    'cart-shopping-fast' ,
    'object-union' ,
    'user-graduate' ,
    'starfighter' ,
    'circle-half-stroke' ,
    'adjust' ,
    'arrow-right-long-to-line' ,
    'square-arrow-down' ,
    'arrow-square-down' ,
    'diamond-half-stroke' ,
    'clapperboard' ,
    'square-chevron-left' ,
    'chevron-square-left' ,
    'phone-intercom' ,
    'link-horizontal' ,
    'chain-horizontal' ,
    'mango' ,
    'music-note-slash' ,
    'music-alt-slash' ,
    'circle-radiation' ,
    'radiation-alt' ,
    'face-tongue-sweat' ,
    'globe-stand' ,
    'baseball' ,
    'baseball-ball' ,
    'circle-p' ,
    'award-simple' ,
    'jet-fighter-up' ,
    'diagram-project' ,
    'project-diagram' ,
    'pedestal' ,
    'chart-pyramid' ,
    'sidebar' ,
    'snowman-head' ,
    'frosty-head' ,
    'copy' ,
    'burger-glass' ,
    'volume-xmark' ,
    'volume-mute' ,
    'volume-times' ,
    'hand-sparkles' ,
    'bars-filter' ,
    'paintbrush-pencil' ,
    'party-bell' ,
    'user-vneck-hair' ,
    'jack-o-lantern' ,
    'grip' ,
    'grip-horizontal' ,
    'share-from-square' ,
    'share-square' ,
    'keynote' ,
    'child-combatant' ,
    'child-rifle' ,
    'gun' ,
    'square-phone' ,
    'phone-square' ,
    'plus' ,
    'add' ,
    'expand' ,
    'computer' ,
    'fort' ,
    'cloud-check' ,
    'xmark' ,
    'close' ,
    'multiply' ,
    'remove' ,
    'times' ,
    'face-smirking' ,
    'arrows-up-down-left-right' ,
    'arrows' ,
    'chalkboard-user' ,
    'chalkboard-teacher' ,
    'rhombus' ,
    'claw-marks' ,
    'peso-sign' ,
    'face-smile-tongue' ,
    'cart-circle-xmark' ,
    'building-shield' ,
    'circle-phone-flip' ,
    'phone-circle-alt' ,
    'baby' ,
    'users-line' ,
    'quote-left' ,
    'quote-left-alt' ,
    'tractor' ,
    'key-skeleton' ,
    'trash-arrow-up' ,
    'trash-restore' ,
    'arrow-down-up-lock' ,
    'arrow-down-to-bracket' ,
    'lines-leaning' ,
    'square-q' ,
    'ruler-combined' ,
    'symbols' ,
    'icons-alt' ,
    'copyright' ,
    'highlighter-line' ,
    'bracket-square' ,
    'bracket' ,
    'bracket-left' ,
    'island-tropical' ,
    'island-tree-palm' ,
    'arrow-right-from-line' ,
    'arrow-from-left' ,
    'h2' ,
    'equals' ,
    'cake-slice' ,
    'shortcake' ,
    'peanut' ,
    'wrench-simple' ,
    'blender' ,
    'teeth' ,
    'tally-2' ,
    'shekel-sign' ,
    'ils' ,
    'shekel' ,
    'sheqel' ,
    'sheqel-sign' ,
    'cars' ,
    'axe-battle' ,
    'user-hair-long' ,
    'map' ,
    'file-circle-info' ,
    'face-disappointed' ,
    'lasso-sparkles' ,
    'clock-eleven' ,
    'rocket' ,
    'siren-on' ,
    'clock-ten' ,
    'candle-holder' ,
    'video-arrow-down-left' ,
    'photo-film' ,
    'photo-video' ,
    'floppy-disk-circle-arrow-right' ,
    'save-circle-arrow-right' ,
    'folder-minus' ,
    'planet-moon' ,
    'face-eyes-xmarks' ,
    'chart-scatter' ,
    'display-arrow-down' ,
    'store' ,
    'arrow-trend-up' ,
    'plug-circle-minus' ,
    'olive-branch' ,
    'angle' ,
    'vacuum-robot' ,
    'sign-hanging' ,
    'sign' ,
    'square-divide' ,
    'signal-stream-slash' ,
    'bezier-curve' ,
    'eye-dropper-half' ,
    'store-lock' ,
    'bell-slash' ,
    'cloud-bolt-sun' ,
    'thunderstorm-sun' ,
    'camera-slash' ,
    'comment-quote' ,
    'tablet' ,
    'tablet-android' ,
    'school-flag' ,
    'message-code' ,
    'glass-half' ,
    'glass-half-empty' ,
    'glass-half-full' ,
    'fill' ,
    'message-minus' ,
    'comment-alt-minus' ,
    'angle-up' ,
    'drumstick-bite' ,
    'link-horizontal-slash' ,
    'chain-horizontal-slash' ,
    'holly-berry' ,
    'nose' ,
    'chevron-left' ,
    'bacteria' ,
    'clouds' ,
    'money-bill-simple' ,
    'hand-lizard' ,
    'table-pivot' ,
    'filter-slash' ,
    'trash-can-undo' ,
    'trash-can-arrow-turn-left' ,
    'trash-undo-alt' ,
    'notdef' ,
    'disease' ,
    'person-to-door' ,
    'turntable' ,
    'briefcase-medical' ,
    'genderless' ,
    'chevron-right' ,
    'signal-weak' ,
    'signal1' ,
    'clock-five' ,
    'retweet' ,
    'car-rear' ,
    'car-alt' ,
    'pump-soap' ,
    'computer-classic' ,
    'frame' ,
    'video-slash' ,
    'battery-quarter' ,
    'battery2' ,
    'ellipsis-stroke' ,
    'ellipsis-h-alt' ,
    'radio' ,
    'baby-carriage' ,
    'carriage-baby' ,
    'face-expressionless' ,
    'down-to-dotted-line' ,
    'cloud-music' ,
    'traffic-light' ,
    'cloud-minus' ,
    'thermometer' ,
    'shield-minus' ,
    'vr-cardboard' ,
    'car-tilt' ,
    'gauge-circle-minus' ,
    'brightness-low' ,
    'hand-middle-finger' ,
    'percent' ,
    'percentage' ,
    'truck-moving' ,
    'glass-water-droplet' ,
    'conveyor-belt' ,
    'location-check' ,
    'map-marker-check' ,
    'coin-vertical' ,
    'display' ,
    'person-sign' ,
    'face-smile' ,
    'smile' ,
    'phone-hangup' ,
    'signature-slash' ,
    'thumbtack' ,
    'thumb-tack' ,
    'wheat-slash' ,
    'trophy' ,
    'clouds-sun' ,
    'person-praying' ,
    'pray' ,
    'hammer' ,
    'face-vomit' ,
    'speakers' ,
    'tty-answer' ,
    'teletype-answer' ,
    'mug-tea-saucer' ,
    'diagram-lean-canvas' ,
    'alt' ,
    'dial' ,
    'dial-med-high' ,
    'hand-peace' ,
    'circle-trash' ,
    'trash-circle' ,
    'rotate' ,
    'sync-alt' ,
    'circle-quarters' ,
    'spinner' ,
    'tower-control' ,
    'arrow-up-triangle-square' ,
    'sort-shapes-up' ,
    'whale' ,
    'robot' ,
    'peace' ,
    'party-horn' ,
    'gears' ,
    'cogs' ,
    'sun-bright' ,
    'sun-alt' ,
    'warehouse' ,
    'lock-keyhole-open' ,
    'lock-open-alt' ,
    'square-fragile' ,
    'box-fragile' ,
    'square-wine-glass-crack' ,
    'arrow-up-right-dots' ,
    'square-n' ,
    'splotch' ,
    'face-grin-hearts' ,
    'grin-hearts' ,
    'meter' ,
    'mandolin' ,
    'dice-four' ,
    'sim-card' ,
    'transgender' ,
    'transgender-alt' ,
    'mercury' ,
    'up-from-bracket' ,
    'knife-kitchen' ,
    'border-right' ,
    'arrow-turn-down' ,
    'level-down' ,
    'spade' ,
    'card-spade' ,
    'line-columns' ,
    'arrow-right-to-line' ,
    'arrow-to-right' ,
    'person-falling-burst' ,
    'flag-pennant' ,
    'pennant' ,
    'conveyor-belt-empty' ,
    'award' ,
    'ticket-simple' ,
    'ticket-alt' ,
    'building' ,
    'angles-left' ,
    'angle-double-left' ,
    'camcorder' ,
    'video-handheld' ,
    'pancakes' ,
    'album-circle-user' ,
    'qrcode' ,
    'dice-d10' ,
    'fireplace' ,
    'browser' ,
    'pen-paintbrush' ,
    'pencil-paintbrush' ,
    'fish-cooked' ,
    'chair-office' ,
    'nesting-dolls' ,
    'clock-rotate-left' ,
    'history' ,
    'trumpet' ,
    'face-grin-beam-sweat' ,
    'grin-beam-sweat' ,
    'fire-smoke' ,
    'phone-missed' ,
    'file-export' ,
    'arrow-right-from-file' ,
    'shield' ,
    'shield-blank' ,
    'arrow-up-short-wide' ,
    'sort-amount-up-alt' ,
    'arrows-repeat-1' ,
    'repeat1-alt' ,
    'gun-slash' ,
    'avocado' ,
    'binary' ,
    'glasses-round' ,
    'glasses-alt' ,
    'phone-plus' ,
    'ditto' ,
    'person-seat' ,
    'house-medical' ,
    'golf-ball-tee' ,
    'golf-ball' ,
    'circle-chevron-left' ,
    'chevron-circle-left' ,
    'house-chimney-window' ,
    'scythe' ,
    'pen-nib' ,
    'ban-parking' ,
    'parking-circle-slash' ,
    'tent-arrow-turn-left' ,
    'face-diagonal-mouth' ,
    'diagram-cells' ,
    'cricket-bat-ball' ,
    'cricket' ,
    'tents' ,
    'wand-magic' ,
    'magic' ,
    'dog' ,
    'pen-line' ,
    'atom-simple' ,
    'atom-alt' ,
    'ampersand' ,
    'carrot' ,
    'arrow-up-from-line' ,
    'arrow-from-bottom' ,
    'moon' ,
    'pen-slash' ,
    'wine-glass-empty' ,
    'wine-glass-alt' ,
    'square-star' ,
    'cheese' ,
    'send-backward' ,
    'yin-yang' ,
    'music' ,
    'compass-slash' ,
    'clock-one' ,
    'file-music' ,
    'code-commit' ,
    'temperature-low' ,
    'person-biking' ,
    'biking' ,
    'skeleton' ,
    'circle-g' ,
    'circle-arrow-up-left' ,
    'coin-blank' ,
    'broom' ,
    'vacuum' ,
    'shield-heart' ,
    'card-heart' ,
    'lightbulb-cfl-on' ,
    'melon' ,
    'gopuram' ,
    'earth-oceania' ,
    'globe-oceania' ,
    'container-storage' ,
    'face-pouting' ,
    'square-xmark' ,
    'times-square' ,
    'xmark-square' ,
    'face-explode' ,
    'exploding-head' ,
    'hashtag' ,
    'up-right-and-down-left-from-center' ,
    'expand-alt' ,
    'oil-can' ,
    't' ,
    'transformer-bolt' ,
    'hippo' ,
    'chart-column' ,
    'cassette-vhs' ,
    'vhs' ,
    'infinity' ,
    'vial-circle-check' ,
    'chimney' ,
    'object-intersect' ,
    'person-arrow-down-to-line' ,
    'voicemail' ,
    'block-brick' ,
    'wall-brick' ,
    'fan' ,
    'bags-shopping' ,
    'paragraph-left' ,
    'paragraph-rtl' ,
    'person-walking-luggage' ,
    'caravan-simple' ,
    'caravan-alt' ,
    'turtle' ,
    'up-down' ,
    'arrows-alt-v' ,
    'cloud-moon-rain' ,
    'booth-curtain' ,
    'calendar' ,
    'box-heart' ,
    'trailer' ,
    'user-doctor-message' ,
    'user-md-chat' ,
    'bahai' ,
    'haykal' ,
    'amp-guitar' ,
    'sd-card' ,
    'volume-slash' ,
    'border-bottom' ,
    'wifi-weak' ,
    'wifi1' ,
    'dragon' ,
    'shoe-prints' ,
    'circle-plus' ,
    'plus-circle' ,
    'face-grin-tongue-wink' ,
    'grin-tongue-wink' ,
    'hand-holding' ,
    'plug-circle-exclamation' ,
    'link-slash' ,
    'chain-broken' ,
    'chain-slash' ,
    'unlink' ,
    'clone' ,
    'person-walking-arrow-loop-left' ,
    'arrow-up-z-a' ,
    'sort-alpha-up-alt' ,
    'fire-flame-curved' ,
    'fire-alt' ,
    'tornado' ,
    'file-circle-plus' ,
    'delete-right' ,
    'book-quran' ,
    'quran' ,
    'circle-quarter' ,
    'anchor' ,
    'border-all' ,
    'function' ,
    'face-angry' ,
    'angry' ,
    'people-simple' ,
    'cookie-bite' ,
    'arrow-trend-down' ,
    'rss' ,
    'feed' ,
    'face-monocle' ,
    'draw-polygon' ,
    'scale-balanced' ,
    'balance-scale' ,
    'calendar-lines' ,
    'calendar-note' ,
    'arrow-down-big-small' ,
    'sort-size-down' ,
    'gauge-simple-high' ,
    'tachometer' ,
    'tachometer-fast' ,
    'do-not-enter' ,
    'shower' ,
    'dice-d8' ,
    'desktop' ,
    'desktop-alt' ,
    'm' ,
    'grip-dots-vertical' ,
    'face-viewfinder' ,
    'soft-serve' ,
    'creemee' ,
    'h5' ,
    'hand-back-point-down' ,
    'table-list' ,
    'th-list' ,
    'comment-sms' ,
    'sms' ,
    'rectangle' ,
    'rectangle-landscape' ,
    'clipboard-list-check' ,
    'turkey' ,
    'book' ,
    'user-plus' ,
    'ice-skate' ,
    'check' ,
    'battery-three-quarters' ,
    'battery4' ,
    'tomato' ,
    'sword-laser' ,
    'house-circle-check' ,
    'buildings' ,
    'angle-left' ,
    'cart-flatbed-boxes' ,
    'dolly-flatbed-alt' ,
    'diagram-successor' ,
    'truck-arrow-right' ,
    'square-w' ,
    'arrows-split-up-and-left' ,
    'lamp' ,
    'airplay' ,
    'hand-fist' ,
    'fist-raised' ,
    'shield-quartered' ,
    'slash-forward' ,
    'location-pen' ,
    'map-marker-edit' ,
    'cloud-moon' ,
    'pot-food' ,
    'briefcase' ,
    'person-falling' ,
    'image-portrait' ,
    'portrait' ,
    'user-tag' ,
    'rug' ,
    'print-slash' ,
    'earth-europe' ,
    'globe-europe' ,
    'cart-flatbed-suitcase' ,
    'luggage-cart' ,
    'hand-back-point-ribbon' ,
    'rectangle-xmark' ,
    'rectangle-times' ,
    'times-rectangle' ,
    'window-close' ,
    'tire-rugged' ,
    'lightbulb-dollar' ,
    'cowbell' ,
    'baht-sign' ,
    'corner' ,
    'chevrons-right' ,
    'chevron-double-right' ,
    'book-open' ,
    'book-journal-whills' ,
    'journal-whills' ,
    'inhaler' ,
    'handcuffs' ,
    'snake' ,
    'triangle-exclamation' ,
    'exclamation-triangle' ,
    'warning' ,
    'note-medical' ,
    'database' ,
    'down-left' ,
    'share' ,
    'arrow-turn-right' ,
    'mail-forward' ,
    'face-thinking' ,
    'turn-down-right' ,
    'bottle-droplet' ,
    'mask-face' ,
    'hill-rockslide' ,
    'scanner-keyboard' ,
    'circle-o' ,
    'grid-horizontal' ,
    'message-dollar' ,
    'comment-alt-dollar' ,
    'right-left' ,
    'exchange-alt' ,
    'columns-3' ,
    'paper-plane' ,
    'road-circle-exclamation' ,
    'dungeon' ,
    'hand-holding-box' ,
    'input-text' ,
    'window-flip' ,
    'window-alt' ,
    'align-right' ,
    'scanner-gun' ,
    'scanner' ,
    'tire' ,
    'engine' ,
    'money-bill-1-wave' ,
    'money-bill-wave-alt' ,
    'life-ring' ,
    'hands' ,
    'sign-language' ,
    'signing' ,
    'circle-caret-right' ,
    'caret-circle-right' ,
    'wheat' ,
    'file-spreadsheet' ,
    'audio-description-slash' ,
    'calendar-day' ,
    'water-ladder' ,
    'ladder-water' ,
    'swimming-pool' ,
    'arrows-up-down' ,
    'arrows-v' ,
    'chess-pawn-piece' ,
    'chess-pawn-alt' ,
    'face-grimace' ,
    'grimace' ,
    'wheelchair-move' ,
    'wheelchair-alt' ,
    'turn-down' ,
    'level-down-alt' ,
    'square-s' ,
    'rectangle-barcode' ,
    'barcode-alt' ,
    'person-walking-arrow-right' ,
    'square-envelope' ,
    'envelope-square' ,
    'dice' ,
    'unicorn' ,
    'bowling-ball' ,
    'pompebled' ,
    'brain' ,
    'watch-smart' ,
    'book-user' ,
    'sensor-cloud' ,
    'sensor-smoke' ,
    'clapperboard-play' ,
    'bandage' ,
    'band-aid' ,
    'calendar-minus' ,
    'circle-xmark' ,
    'times-circle' ,
    'xmark-circle' ,
    'circle-4' ,
    'gifts' ,
    'album-collection' ,
    'hotel' ,
    'earth-asia' ,
    'globe-asia' ,
    'id-card-clip' ,
    'id-card-alt' ,
    'magnifying-glass-plus' ,
    'search-plus' ,
    'thumbs-up' ,
    'cloud-showers' ,
    'user-clock' ,
    'onion' ,
    'clock-twelve-thirty' ,
    'arrow-down-to-dotted-line' ,
    'hand-dots' ,
    'allergies' ,
    'file-invoice' ,
    'window-minimize' ,
    'rectangle-wide' ,
    'comment-arrow-up' ,
    'garlic' ,
    'mug-saucer' ,
    'coffee' ,
    'brush' ,
    'tree-decorated' ,
    'mask' ,
    'calendar-heart' ,
    'magnifying-glass-minus' ,
    'search-minus' ,
    'flower' ,
    'ruler-vertical' ,
    'user-large' ,
    'user-alt' ,
    'starship-freighter' ,
    'train-tram' ,
    'bridge-suspension' ,
    'trash-check' ,
    'user-nurse' ,
    'boombox' ,
    'syringe' ,
    'cloud-sun' ,
    'shield-exclamation' ,
    'stopwatch-20' ,
    'square-full' ,
    'grip-dots' ,
    'comment-exclamation' ,
    'pen-swirl' ,
    'falafel' ,
    'circle-2' ,
    'magnet' ,
    'jar' ,
    'gramophone' ,
    'dice-d12' ,
    'note-sticky' ,
    'sticky-note' ,
    'down' ,
    'arrow-alt-down' ,
    'hundred-points' ,
    '100' ,
    'paperclip-vertical' ,
    'wind-warning' ,
    'wind-circle-exclamation' ,
    'location-pin-slash' ,
    'map-marker-slash' ,
    'face-sad-sweat' ,
    'bug-slash' ,
    'cupcake' ,
    'light-switch-off' ,
    'toggle-large-off' ,
    'pen-fancy-slash' ,
    'truck-container' ,
    'boot' ,
    'arrow-up-from-water-pump' ,
    'file-check' ,
    'bone' ,
    'cards-blank' ,
    'circle-3' ,
    'bench-tree' ,
    'keyboard-brightness-low' ,
    'ski-boot-ski' ,
    'brain-circuit' ,
    'user-injured' ,
    'block-brick-fire' ,
    'firewall' ,
    'face-sad-tear' ,
    'sad-tear' ,
    'plane' ,
    'tent-arrows-down' ,
    'exclamation' ,
    'arrows-spin' ,
    'face-smile-relaxed' ,
    'comment-xmark' ,
    'comment-times' ,
    'print' ,
    'turkish-lira-sign' ,
    'try' ,
    'turkish-lira' ,
    'face-nose-steam' ,
    'circle-waveform-lines' ,
    'waveform-circle' ,
    'dollar-sign' ,
    'dollar' ,
    'usd' ,
    'ferris-wheel' ,
    'computer-speaker' ,
    'skull-cow' ,
    'x' ,
    'magnifying-glass-dollar' ,
    'search-dollar' ,
    'users-gear' ,
    'users-cog' ,
    'person-military-pointing' ,
    'building-columns' ,
    'bank' ,
    'institution' ,
    'museum' ,
    'university' ,
    'circle-t' ,
    'sack' ,
    'grid-2' ,
    'camera-cctv' ,
    'cctv' ,
    'umbrella' ,
    'trowel' ,
    'horizontal-rule' ,
    'bed-front' ,
    'bed-alt' ,
    'd' ,
    'stapler' ,
    'masks-theater' ,
    'theater-masks' ,
    'kip-sign' ,
    'face-woozy' ,
    'cloud-question' ,
    'pineapple' ,
    'hand-point-left' ,
    'gallery-thumbnails' ,
    'circle-j' ,
    'eyes' ,
    'handshake-simple' ,
    'handshake-alt' ,
    'page-caret-up' ,
    'file-caret-up' ,
    'jet-fighter' ,
    'fighter-jet' ,
    'comet' ,
    'square-share-nodes' ,
    'share-alt-square' ,
    'shield-keyhole' ,
    'barcode' ,
    'plus-minus' ,
    'square-sliders-vertical' ,
    'sliders-v-square' ,
    'video' ,
    'video-camera' ,
    'message-middle' ,
    'comment-middle-alt' ,
    'graduation-cap' ,
    'mortar-board' ,
    'hand-holding-medical' ,
    'person-circle-check' ,
    'square-z' ,
    'message-text' ,
    'comment-alt-text' ,
    'turn-up' ,
    'level-up-alt' ,
    '0' ,
    '1' ,
    '2' ,
    '3' ,
    '4' ,
    '5' ,
    '6' ,
    '7' ,
    '8' ,
    '9' ,
    'fill-drip' ,
    'arrows-to-circle' ,
    'circle-chevron-right' ,
    'chevron-circle-right' ,
    'wagon-covered' ,
    'line-height' ,
    'bagel' ,
    'transporter-7' ,
    'at' ,
    'rectangles-mixed' ,
    'phone-arrow-up-right' ,
    'phone-arrow-up' ,
    'phone-outgoing' ,
    'trash-can' ,
    'trash-alt' ,
    'circle-l' ,
    'head-side-goggles' ,
    'head-vr' ,
    'text-height' ,
    'user-xmark' ,
    'user-times' ,
    'face-hand-yawn' ,
    'gauge-simple-min' ,
    'tachometer-slowest' ,
    'stethoscope' ,
    'coffin' ,
    'message' ,
    'comment-alt' ,
    'salad' ,
    'bowl-salad' ,
    'info' ,
    'robot-astromech' ,
    'ring-diamond' ,
    'fondue-pot' ,
    'theta' ,
    'face-hand-peeking' ,
    'square-user' ,
    'down-left-and-up-right-to-center' ,
    'compress-alt' ,
    'explosion' ,
    'file-lines' ,
    'file-alt' ,
    'file-text' ,
    'wave-square' ,
    'ring' ,
    'building-un' ,
    'dice-three' ,
    'tire-pressure-warning' ,
    'wifi-fair' ,
    'wifi2' ,
    'calendar-days' ,
    'calendar-alt' ,
    'mp3-player' ,
    'anchor-circle-check' ,
    'tally-4' ,
    'rectangle-history' ,
    'building-circle-arrow-right' ,
    'volleyball' ,
    'volleyball-ball' ,
    'sun-haze' ,
    'text-size' ,
    'ufo' ,
    'fork' ,
    'utensil-fork' ,
    'arrows-up-to-line' ,
    'mobile-signal' ,
    'barcode-scan' ,
    'sort-down' ,
    'sort-desc' ,
    'folder-arrow-down' ,
    'folder-download' ,
    'circle-minus' ,
    'minus-circle' ,
    'face-icicles' ,
    'shovel' ,
    'door-open' ,
    'films' ,
    'right-from-bracket' ,
    'sign-out-alt' ,
    'face-glasses' ,
    'nfc' ,
    'atom' ,
    'soap' ,
    'icons' ,
    'heart-music-camera-bolt' ,
    'microphone-lines-slash' ,
    'microphone-alt-slash' ,
    'closed-captioning-slash' ,
    'calculator-simple' ,
    'calculator-alt' ,
    'bridge-circle-check' ,
    'sliders-up' ,
    'sliders-v' ,
    'location-minus' ,
    'map-marker-minus' ,
    'pump-medical' ,
    'fingerprint' ,
    'ski-boot' ,
    'standard-definition' ,
    'rectangle-sd' ,
    'h1' ,
    'hand-point-right' ,
    'magnifying-glass-location' ,
    'search-location' ,
    'message-bot' ,
    'forward-step' ,
    'step-forward' ,
    'face-smile-beam' ,
    'smile-beam' ,
    'light-ceiling' ,
    'message-exclamation' ,
    'comment-alt-exclamation' ,
    'bowl-scoop' ,
    'bowl-shaved-ice' ,
    'square-x' ,
    'utility-pole-double' ,
    'flag-checkered' ,
    'chevrons-up' ,
    'chevron-double-up' ,
    'football' ,
    'football-ball' ,
    'user-vneck' ,
    'school-circle-exclamation' ,
    'crop' ,
    'angles-down' ,
    'angle-double-down' ,
    'users-rectangle' ,
    'people-roof' ,
    'square-arrow-right' ,
    'arrow-square-right' ,
    'location-plus' ,
    'map-marker-plus' ,
    'lightbulb-exclamation-on' ,
    'people-line' ,
    'beer-mug-empty' ,
    'beer' ,
    'crate-empty' ,
    'diagram-predecessor' ,
    'transporter' ,
    'calendar-circle-user' ,
    'arrow-up-long' ,
    'long-arrow-up' ,
    'person-carry-box' ,
    'person-carry' ,
    'fire-flame-simple' ,
    'burn' ,
    'person' ,
    'male' ,
    'laptop' ,
    'file-csv' ,
    'menorah' ,
    'union' ,
    'chevrons-left' ,
    'chevron-double-left' ,
    'circle-heart' ,
    'heart-circle' ,
    'truck-plane' ,
    'record-vinyl' ,
    'bring-forward' ,
    'square-p' ,
    'face-grin-stars' ,
    'grin-stars' ,
    'sigma' ,
    'camera-movie' ,
    'bong' ,
    'clarinet' ,
    'truck-flatbed' ,
    'spaghetti-monster-flying' ,
    'pastafarianism' ,
    'arrow-down-up-across-line' ,
    'leaf-heart' ,
    'house-building' ,
    'cheese-swiss' ,
    'spoon' ,
    'utensil-spoon' ,
    'jar-wheat' ,
    'envelopes-bulk' ,
    'mail-bulk' ,
    'file-circle-exclamation' ,
    'bow-arrow' ,
    'cart-xmark' ,
    'hexagon-xmark' ,
    'times-hexagon' ,
    'xmark-hexagon' ,
    'circle-h' ,
    'hospital-symbol' ,
    'merge' ,
    'pager' ,
    'cart-minus' ,
    'address-book' ,
    'contact-book' ,
    'pan-frying' ,
    'grid' ,
    'grid3' ,
    'football-helmet' ,
    'hand-love' ,
    'trees' ,
    'strikethrough' ,
    'page' ,
    'k' ,
    'diagram-previous' ,
    'gauge-min' ,
    'tachometer-alt-slowest' ,
    'folder-grid' ,
    'eggplant' ,
    'ram' ,
    'landmark-flag' ,
    'lips' ,
    'pencil' ,
    'pencil-alt' ,
    'backward' ,
    'caret-right' ,
    'comments' ,
    'paste' ,
    'file-clipboard' ,
    'desktop-arrow-down' ,
    'code-pull-request' ,
    'pumpkin' ,
    'clipboard-list' ,
    'pen-field' ,
    'blueberries' ,
    'truck-ramp-box' ,
    'truck-loading' ,
    'note' ,
    'arrow-down-to-square' ,
    'user-check' ,
    'cloud-xmark' ,
    'vial-virus' ,
    'book-blank' ,
    'book-alt' ,
    'golf-flag-hole' ,
    'message-arrow-down' ,
    'comment-alt-arrow-down' ,
    'face-unamused' ,
    'sheet-plastic' ,
    'circle-9' ,
    'blog' ,
    'user-ninja' ,
    'pencil-slash' ,
    'bowling-pins' ,
    'person-arrow-up-from-line' ,
    'down-right' ,
    'scroll-torah' ,
    'torah' ,
    'blinds-open' ,
    'fence' ,
    'up' ,
    'arrow-alt-up' ,
    'broom-ball' ,
    'quidditch' ,
    'quidditch-broom-ball' ,
    'drumstick' ,
    'square-v' ,
    'face-awesome' ,
    'gave-dandy' ,
    'dial-off' ,
    'toggle-off' ,
    'face-smile-horns' ,
    'box-archive' ,
    'archive' ,
    'grapes' ,
    'person-drowning' ,
    'dial-max' ,
    'circle-m' ,
    'calendar-image' ,
    'circle-caret-down' ,
    'caret-circle-down' ,
    'arrow-down-9-1' ,
    'sort-numeric-desc' ,
    'sort-numeric-down-alt' ,
    'face-grin-tongue-squint' ,
    'grin-tongue-squint' ,
    'shish-kebab' ,
    'spray-can' ,
    'alarm-snooze' ,
    'scarecrow' ,
    'truck-monster' ,
    'gift-card' ,
    'w' ,
    'code-pull-request-draft' ,
    'square-b' ,
    'elephant' ,
    'earth-africa' ,
    'globe-africa' ,
    'rainbow' ,
    'circle-notch' ,
    'tablet-screen-button' ,
    'tablet-alt' ,
    'paw' ,
    'message-question' ,
    'cloud' ,
    'trowel-bricks' ,
    'square-3' ,
    'face-flushed' ,
    'flushed' ,
    'hospital-user' ,
    'microwave' ,
    'tent-arrow-left-right' ,
    'cart-circle-arrow-up' ,
    'trash-clock' ,
    'gavel' ,
    'legal' ,
    'sprinkler-ceiling' ,
    'browsers' ,
    'trillium' ,
    'music-slash' ,
    'truck-ramp' ,
    'binoculars' ,
    'microphone-slash' ,
    'box-tissue' ,
    'circle-c' ,
    'star-christmas' ,
    'chart-bullet' ,
    'motorcycle' ,
    'tree-christmas' ,
    'tire-flat' ,
    'sunglasses' ,
    'badge' ,
    'message-pen' ,
    'comment-alt-edit' ,
    'message-edit' ,
    'bell-concierge' ,
    'concierge-bell' ,
    'pen-ruler' ,
    'pencil-ruler' ,
    'chess-rook-piece' ,
    'chess-rook-alt' ,
    'square-root' ,
    'album-collection-circle-plus' ,
    'people-arrows' ,
    'people-arrows-left-right' ,
    'face-angry-horns' ,
    'mars-and-venus-burst' ,
    'tombstone' ,
    'square-caret-right' ,
    'caret-square-right' ,
    'scissors' ,
    'cut' ,
    'list-music' ,
    'sun-plant-wilt' ,
    'toilets-portable' ,
    'hockey-puck' ,
    'mustache' ,
    'hyphen' ,
    'table' ,
    'user-chef' ,
    'message-image' ,
    'comment-alt-image' ,
    'users-medical' ,
    'sensor-triangle-exclamation' ,
    'sensor-alert' ,
    'magnifying-glass-arrow-right' ,
    'tachograph-digital' ,
    'digital-tachograph' ,
    'face-mask' ,
    'pickleball' ,
    'star-sharp-half' ,
    'users-slash' ,
    'clover' ,
    'meat' ,
    'reply' ,
    'mail-reply' ,
    'star-and-crescent' ,
    'empty-set' ,
    'house-fire' ,
    'square-minus' ,
    'minus-square' ,
    'helicopter' ,
    'bird' ,
    'compass' ,
    'square-caret-down' ,
    'caret-square-down' ,
    'heart-half-stroke' ,
    'heart-half-alt' ,
    'file-circle-question' ,
    'laptop-code' ,
    'joystick' ,
    'grill-fire' ,
    'rectangle-vertical-history' ,
    'swatchbook' ,
    'prescription-bottle' ,
    'bars' ,
    'navicon' ,
    'keyboard-left' ,
    'people-group' ,
    'hourglass-end' ,
    'hourglass3' ,
    'heart-crack' ,
    'heart-broken' ,
    'face-beam-hand-over-mouth' ,
    'droplet-percent' ,
    'humidity' ,
    'square-up-right' ,
    'external-link-square-alt' ,
    'face-kiss-beam' ,
    'kiss-beam' ,
    'corn' ,
    'roller-coaster' ,
    'photo-film-music' ,
    'radar' ,
    'sickle' ,
    'film' ,
    'coconut' ,
    'ruler-horizontal' ,
    'shield-cross' ,
    'cassette-tape' ,
    'square-terminal' ,
    'people-robbery' ,
    'lightbulb' ,
    'caret-left' ,
    'comment-middle' ,
    'trash-can-list' ,
    'block' ,
    'circle-exclamation' ,
    'exclamation-circle' ,
    'school-circle-xmark' ,
    'arrow-right-from-bracket' ,
    'sign-out' ,
    'face-frown-slight' ,
    'circle-chevron-down' ,
    'chevron-circle-down' ,
    'sidebar-flip' ,
    'unlock-keyhole' ,
    'unlock-alt' ,
    'temperature-list' ,
    'cloud-showers-heavy' ,
    'headphones-simple' ,
    'headphones-alt' ,
    'sitemap' ,
    'pipe-section' ,
    'space-station-moon-construction' ,
    'space-station-moon-alt' ,
    'circle-dollar-to-slot' ,
    'donate' ,
    'memory' ,
    'face-sleeping' ,
    'road-spikes' ,
    'fire-burner' ,
    'squirrel' ,
    'arrow-up-to-line' ,
    'arrow-to-top' ,
    'flag' ,
    'face-cowboy-hat' ,
    'hanukiah' ,
    'chart-scatter-3d' ,
    'square-code' ,
    'feather' ,
    'volume-low' ,
    'volume-down' ,
    'xmark-to-slot' ,
    'times-to-slot' ,
    'vote-nay' ,
    'box-taped' ,
    'box-alt' ,
    'comment-slash' ,
    'swords' ,
    'cloud-sun-rain' ,
    'album' ,
    'circle-n' ,
    'compress' ,
    'wheat-awn' ,
    'wheat-alt' ,
    'ankh' ,
    'hands-holding-child' ,
    'asterisk' ,
    'key-skeleton-left-right' ,
    'comment-lines' ,
    'luchador-mask' ,
    'luchador' ,
    'mask-luchador' ,
    'square-check' ,
    'check-square' ,
    'shredder' ,
    'book-open-cover' ,
    'book-open-alt' ,
    'sandwich' ,
    'peseta-sign' ,
    'square-parking-slash' ,
    'parking-slash' ,
    'train-tunnel' ,
    'heading' ,
    'header' ,
    'ghost' ,
    'face-anguished' ,
    'hockey-sticks' ,
    'abacus' ,
    'film-simple' ,
    'film-alt' ,
    'list' ,
    'list-squares' ,
    'tree-palm' ,
    'square-phone-flip' ,
    'phone-square-alt' ,
    'cart-plus' ,
    'gamepad' ,
    'border-center-v' ,
    'circle-dot' ,
    'dot-circle' ,
    'clipboard-medical' ,
    'face-dizzy' ,
    'dizzy' ,
    'egg' ,
    'up-to-line' ,
    'arrow-alt-to-top' ,
    'house-medical-circle-xmark' ,
    'watch-fitness' ,
    'clock-nine-thirty' ,
    'campground' ,
    'folder-plus' ,
    'jug' ,
    'futbol' ,
    'futbol-ball' ,
    'soccer-ball' ,
    'snow-blowing' ,
    'paintbrush' ,
    'paint-brush' ,
    'lock' ,
    'arrow-down-from-line' ,
    'arrow-from-top' ,
    'gas-pump' ,
    'signal-bars-slash' ,
    'signal-alt-slash' ,
    'monkey' ,
    'rectangle-pro' ,
    'pro' ,
    'house-night' ,
    'hot-tub-person' ,
    'hot-tub' ,
    'blanket' ,
    'map-location' ,
    'map-marked' ,
    'house-flood-water' ,
    'comments-question-check' ,
    'tree' ,
    'arrows-cross' ,
    'backpack' ,
    'square-small' ,
    'folder-arrow-up' ,
    'folder-upload' ,
    'bridge-lock' ,
    'crosshairs-simple' ,
    'sack-dollar' ,
    'pen-to-square' ,
    'edit' ,
    'square-sliders' ,
    'sliders-h-square' ,
    'car-side' ,
    'message-middle-top' ,
    'comment-middle-top-alt' ,
    'lightbulb-on' ,
    'knife' ,
    'utensil-knife' ,
    'share-nodes' ,
    'share-alt' ,
    'wave-sine' ,
    'heart-circle-minus' ,
    'circle-w' ,
    'circle-calendar' ,
    'calendar-circle' ,
    'hourglass-half' ,
    'hourglass2' ,
    'microscope' ,
    'sunset' ,
    'sink' ,
    'calendar-exclamation' ,
    'truck-container-empty' ,
    'hand-heart' ,
    'bag-shopping' ,
    'shopping-bag' ,
    'arrow-down-z-a' ,
    'sort-alpha-desc' ,
    'sort-alpha-down-alt' ,
    'mitten' ,
    'reply-clock' ,
    'reply-time' ,
    'person-rays' ,
    'right' ,
    'arrow-alt-right' ,
    'circle-f' ,
    'users' ,
    'face-pleading' ,
    'eye-slash' ,
    'flask-vial' ,
    'police-box' ,
    'cucumber' ,
    'head-side-brain' ,
    'hand' ,
    'hand-paper' ,
    'person-biking-mountain' ,
    'biking-mountain' ,
    'utensils-slash' ,
    'print-magnifying-glass' ,
    'print-search' ,
    'folder-bookmark' ,
    'om' ,
    'pi' ,
    'flask-round-potion' ,
    'flask-potion' ,
    'face-shush' ,
    'worm' ,
    'house-circle-xmark' ,
    'plug' ,
    'calendar-circle-exclamation' ,
    'square-i' ,
    'chevron-up' ,
    'face-saluting' ,
    'gauge-simple-low' ,
    'tachometer-slow' ,
    'face-persevering' ,
    'circle-camera' ,
    'camera-circle' ,
    'hand-spock' ,
    'spider-web' ,
    'circle-microphone' ,
    'microphone-circle' ,
    'book-arrow-up' ,
    'popsicle' ,
    'command' ,
    'blinds' ,
    'stopwatch' ,
    'saxophone' ,
    'square-2' ,
    'field-hockey-stick-ball' ,
    'field-hockey' ,
    'arrow-up-square-triangle' ,
    'sort-shapes-up-alt' ,
    'face-scream' ,
    'square-m' ,
    'camera-web' ,
    'webcam' ,
    'comment-arrow-down' ,
    'lightbulb-cfl' ,
    'window-frame-open' ,
    'face-kiss' ,
    'kiss' ,
    'bridge-circle-xmark' ,
    'period' ,
    'face-grin-tongue' ,
    'grin-tongue' ,
    'up-to-dotted-line' ,
    'thought-bubble' ,
    'raygun' ,
    'flute' ,
    'acorn' ,
    'video-arrow-up-right' ,
    'grate-droplet' ,
    'seal-exclamation' ,
    'chess-bishop' ,
    'message-sms' ,
    'coffee-beans' ,
    'hat-witch' ,
    'face-grin-wink' ,
    'grin-wink' ,
    'clock-three-thirty' ,
    'ear-deaf' ,
    'deaf' ,
    'deafness' ,
    'hard-of-hearing' ,
    'alarm-clock' ,
    'eclipse' ,
    'face-relieved' ,
    'road-circle-check' ,
    'dice-five' ,
    'octagon-minus' ,
    'minus-octagon' ,
    'square-rss' ,
    'rss-square' ,
    'face-zany' ,
    'tricycle' ,
    'land-mine-on' ,
    'square-arrow-up-left' ,
    'i-cursor' ,
    'salt-shaker' ,
    'stamp' ,
    'file-plus' ,
    'draw-square' ,
    'toilet-paper-under-slash' ,
    'toilet-paper-reverse-slash' ,
    'stairs' ,
    'drone-front' ,
    'drone-alt' ,
    'glass-empty' ,
    'dial-high' ,
    'user-helmet-safety' ,
    'user-construction' ,
    'user-hard-hat' ,
    'i' ,
    'hryvnia-sign' ,
    'hryvnia' ,
    'arrow-down-left-and-arrow-up-right-to-center' ,
    'pills' ,
    'face-grin-wide' ,
    'grin-alt' ,
    'tooth' ,
    'basketball-hoop' ,
    'objects-align-bottom' ,
    'v' ,
    'sparkles' ,
    'squid' ,
    'leafy-green' ,
    'circle-arrow-up-right' ,
    'calendars' ,
    'bangladeshi-taka-sign' ,
    'bicycle' ,
    'hammer-war' ,
    'circle-d' ,
    'spider-black-widow' ,
    'staff-snake' ,
    'rod-asclepius' ,
    'rod-snake' ,
    'staff-aesculapius' ,
    'pear' ,
    'head-side-cough-slash' ,
    'triangle' ,
    'apartment' ,
    'truck-medical' ,
    'ambulance' ,
    'pepper' ,
    'piano' ,
    'gun-squirt' ,
    'wheat-awn-circle-exclamation' ,
    'snowman' ,
    'user-alien' ,
    'shield-check' ,
    'mortar-pestle' ,
    'road-barrier' ,
    'chart-candlestick' ,
    'briefcase-blank' ,
    'school' ,
    'igloo' ,
    'bracket-round' ,
    'parenthesis' ,
    'joint' ,
    'horse-saddle' ,
    'mug-marshmallows' ,
    'filters' ,
    'bell-on' ,
    'angle-right' ,
    'dial-med' ,
    'horse' ,
    'q' ,
    'monitor-waveform' ,
    'monitor-heart-rate' ,
    'link-simple' ,
    'whistle' ,
    'g' ,
    'wine-glass-crack' ,
    'fragile' ,
    'slot-machine' ,
    'notes-medical' ,
    'car-wash' ,
    'escalator' ,
    'comment-image' ,
    'temperature-half' ,
    'temperature2' ,
    'thermometer2' ,
    'thermometer-half' ,
    'dong-sign' ,
    'donut' ,
    'doughnut' ,
    'capsules' ,
    'poo-storm' ,
    'poo-bolt' ,
    'tally-1' ,
    'face-frown-open' ,
    'frown-open' ,
    'square-dashed' ,
    'square-j' ,
    'hand-point-up' ,
    'money-bill' ,
    'arrow-up-big-small' ,
    'sort-size-up' ,
    'barcode-read' ,
    'baguette' ,
    'bowl-soft-serve' ,
    'face-holding-back-tears' ,
    'square-up' ,
    'arrow-alt-square-up' ,
    'train-subway-tunnel' ,
    'subway-tunnel' ,
    'square-exclamation' ,
    'exclamation-square' ,
    'semicolon' ,
    'bookmark' ,
    'fan-table' ,
    'align-justify' ,
    'battery-low' ,
    'battery1' ,
    'credit-card-front' ,
    'brain-arrow-curved-right' ,
    'mind-share' ,
    'umbrella-beach' ,
    'helmet-un' ,
    'location-smile' ,
    'map-marker-smile' ,
    'arrow-left-to-line' ,
    'arrow-to-left' ,
    'bullseye' ,
    'sushi' ,
    'nigiri' ,
    'message-captions' ,
    'comment-alt-captions' ,
    'trash-list' ,
    'bacon' ,
    'option' ,
    'hand-point-down' ,
    'arrow-up-from-bracket' ,
    'trash-plus' ,
    'objects-align-top' ,
    'folder' ,
    'folder-blank' ,
    'face-anxious-sweat' ,
    'credit-card-blank' ,
    'file-waveform' ,
    'file-medical-alt' ,
    'microchip-ai' ,
    'mug' ,
    'plane-up-slash' ,
    'radiation' ,
    'pen-circle' ,
    'chart-simple' ,
    'crutches' ,
    'circle-parking' ,
    'parking-circle' ,
    'mars-stroke' ,
    'leaf-oak' ,
    'square-bolt' ,
    'vial' ,
    'gauge' ,
    'dashboard' ,
    'gauge-med' ,
    'tachometer-alt-average' ,
    'wand-magic-sparkles' ,
    'magic-wand-sparkles' ,
    'lambda' ,
    'e' ,
    'pizza' ,
    'bowl-chopsticks-noodles' ,
    'h3' ,
    'pen-clip' ,
    'pen-alt' ,
    'bridge-circle-exclamation' ,
    'badge-percent' ,
    'user' ,
    'sensor' ,
    'comma' ,
    'school-circle-check' ,
    'toilet-paper-under' ,
    'toilet-paper-reverse' ,
    'light-emergency' ,
    'arrow-down-to-arc' ,
    'dumpster' ,
    'van-shuttle' ,
    'shuttle-van' ,
    'building-user' ,
    'light-switch' ,
    'square-caret-left' ,
    'caret-square-left' ,
    'highlighter' ,
    'wave-pulse' ,
    'heart-rate' ,
    'key' ,
    'hat-santa' ,
    'tamale' ,
    'box-check' ,
    'bullhorn' ,
    'steak' ,
    'location-crosshairs-slash' ,
    'location-slash' ,
    'person-dolly' ,
    'globe' ,
    'synagogue' ,
    'file-chart-column' ,
    'file-chart-line' ,
    'person-half-dress' ,
    'folder-image' ,
    'calendar-pen' ,
    'calendar-edit' ,
    'road-bridge' ,
    'face-smile-tear' ,
    'message-plus' ,
    'comment-alt-plus' ,
    'location-arrow' ,
    'c' ,
    'tablet-button' ,
    'rectangle-history-circle-user' ,
    'building-lock' ,
    'chart-line-up' ,
    'mailbox' ,
    'truck-bolt' ,
    'pizza-slice' ,
    'money-bill-wave' ,
    'chart-area' ,
    'area-chart' ,
    'house-flag' ,
    'person-circle-minus' ,
    'scalpel' ,
    'ban' ,
    'cancel' ,
    'bell-exclamation' ,
    'circle-bookmark' ,
    'bookmark-circle' ,
    'egg-fried' ,
    'face-weary' ,
    'uniform-martial-arts' ,
    'camera-rotate' ,
    'sun-dust' ,
    'comment-text' ,
    'spray-can-sparkles' ,
    'air-freshener' ,
    'signal-bars' ,
    'signal-alt' ,
    'signal-alt4' ,
    'signal-bars-strong' ,
    'diamond-exclamation' ,
    'star' ,
    'dial-min' ,
    'repeat' ,
    'cross' ,
    'page-caret-down' ,
    'file-caret-down' ,
    'box' ,
    'venus-mars' ,
    'clock-seven-thirty' ,
    'arrow-pointer' ,
    'mouse-pointer' ,
    'clock-four-thirty' ,
    'signal-bars-good' ,
    'signal-alt3' ,
    'cactus' ,
    'maximize' ,
    'expand-arrows-alt' ,
    'charging-station' ,
    'shapes' ,
    'triangle-circle-square' ,
    'plane-tail' ,
    'gauge-simple-max' ,
    'tachometer-fastest' ,
    'circle-u' ,
    'shield-slash' ,
    'square-phone-hangup' ,
    'phone-square-down' ,
    'arrow-up-left' ,
    'transporter-1' ,
    'peanuts' ,
    'shuffle' ,
    'random' ,
    'person-running' ,
    'running' ,
    'mobile-retro' ,
    'grip-lines-vertical' ,
    'arrow-up-from-square' ,
    'file-dashed-line' ,
    'page-break' ,
    'bracket-curly-right' ,
    'spider' ,
    'clock-three' ,
    'hands-bound' ,
    'scalpel-line-dashed' ,
    'scalpel-path' ,
    'file-invoice-dollar' ,
    'pipe-smoking' ,
    'face-astonished' ,
    'window' ,
    'plane-circle-exclamation' ,
    'ear' ,
    'file-lock' ,
    'diagram-venn' ,
    'x-ray' ,
    'goal-net' ,
    'coffin-cross' ,
    'spell-check' ,
    'location-xmark' ,
    'map-marker-times' ,
    'map-marker-xmark' ,
    'lasso' ,
    'slash' ,
    'person-to-portal' ,
    'portal-enter' ,
    'calendar-star' ,
    'computer-mouse' ,
    'mouse' ,
    'arrow-right-to-bracket' ,
    'sign-in' ,
    'pegasus' ,
    'files-medical' ,
    'nfc-lock' ,
    'person-ski-lift' ,
    'ski-lift' ,
    'square-6' ,
    'shop-slash' ,
    'store-alt-slash' ,
    'wind-turbine' ,
    'sliders-simple' ,
    'badge-sheriff' ,
    'server' ,
    'virus-covid-slash' ,
    'intersection' ,
    'shop-lock' ,
    'family' ,
    'hourglass-start' ,
    'hourglass1' ,
    'user-hair-buns' ,
    'blender-phone' ,
    'hourglass-clock' ,
    'person-seat-reclined' ,
    'paper-plane-top' ,
    'paper-plane-alt' ,
    'send' ,
    'message-arrow-up' ,
    'comment-alt-arrow-up' ,
    'lightbulb-exclamation' ,
    'layer-minus' ,
    'layer-group-minus' ,
    'circle-e' ,
    'building-wheat' ,
    'gauge-max' ,
    'tachometer-alt-fastest' ,
    'person-breastfeeding' ,
    'apostrophe' ,
    'fire-hydrant' ,
    'right-to-bracket' ,
    'sign-in-alt' ,
    'video-plus' ,
    'square-right' ,
    'arrow-alt-square-right' ,
    'comment-smile' ,
    'venus' ,
    'passport' ,
    'inbox-in' ,
    'inbox-arrow-down' ,
    'heart-pulse' ,
    'heartbeat' ,
    'circle-8' ,
    'clouds-moon' ,
    'clock-ten-thirty' ,
    'people-carry-box' ,
    'people-carry' ,
    'folder-user' ,
    'trash-can-xmark' ,
    'temperature-high' ,
    'microchip' ,
    'left-long-to-line' ,
    'crown' ,
    'weight-hanging' ,
    'xmarks-lines' ,
    'file-prescription' ,
    'calendar-range' ,
    'flower-daffodil' ,
    'hand-back-point-up' ,
    'weight-scale' ,
    'weight' ,
    'star-exclamation' ,
    'books' ,
    'user-group' ,
    'user-friends' ,
    'arrow-up-a-z' ,
    'sort-alpha-up' ,
    'layer-plus' ,
    'layer-group-plus' ,
    'play-pause' ,
    'block-question' ,
    'snooze' ,
    'zzz' ,
    'scanner-image' ,
    'tv-retro' ,
    'square-t' ,
    'farm' ,
    'barn-silo' ,
    'chess-knight' ,
    'bars-sort' ,
    'pallet-boxes' ,
    'palette-boxes' ,
    'pallet-alt' ,
    'face-laugh-squint' ,
    'laugh-squint' ,
    'code-simple' ,
    'bolt-slash' ,
    'panel-fire' ,
    'binary-circle-check' ,
    'comment-minus' ,
    'burrito' ,
    'violin' ,
    'objects-column' ,
    'square-chevron-down' ,
    'chevron-square-down' ,
    'comment-plus' ,
    'triangle-instrument' ,
    'triangle-music' ,
    'wheelchair' ,
    'user-pilot-tie' ,
    'piano-keyboard' ,
    'bed-empty' ,
    'circle-arrow-up' ,
    'arrow-circle-up' ,
    'toggle-on' ,
    'rectangle-vertical' ,
    'rectangle-portrait' ,
    'person-walking' ,
    'walking' ,
    'l' ,
    'signal-stream' ,
    'down-to-bracket' ,
    'circle-z' ,
    'stars' ,
    'fire' ,
    'bed-pulse' ,
    'procedures' ,
    'house-day' ,
    'shuttle-space' ,
    'space-shuttle' ,
    'shirt-long-sleeve' ,
    'chart-pie-simple' ,
    'chart-pie-alt' ,
    'face-laugh' ,
    'laugh' ,
    'folder-open' ,
    'album-collection-circle-user' ,
    'candy' ,
    'bowl-hot' ,
    'soup' ,
    'flatbread' ,
    'heart-circle-plus' ,
    'code-fork' ,
    'city' ,
    'signal-bars-weak' ,
    'signal-alt1' ,
    'microphone-lines' ,
    'microphone-alt' ,
    'clock-twelve' ,
    'pepper-hot' ,
    'citrus-slice' ,
    'sheep' ,
    'unlock' ,
    'colon-sign' ,
    'headset' ,
    'badger-honey' ,
    'h4' ,
    'store-slash' ,
    'road-circle-xmark' ,
    'signal-slash' ,
    'user-minus' ,
    'mars-stroke-up' ,
    'mars-stroke-v' ,
    'champagne-glasses' ,
    'glass-cheers' ,
    'taco' ,
    'hexagon-plus' ,
    'plus-hexagon' ,
    'clipboard' ,
    'house-circle-exclamation' ,
    'file-arrow-up' ,
    'file-upload' ,
    'wifi' ,
    'wifi3' ,
    'wifi-strong' ,
    'messages' ,
    'comments-alt' ,
    'bath' ,
    'bathtub' ,
    'umbrella-simple' ,
    'umbrella-alt' ,
    'rectangle-history-circle-plus' ,
    'underline' ,
    'prescription-bottle-pill' ,
    'user-pen' ,
    'user-edit' ,
    'binary-slash' ,
    'square-o' ,
    'signature' ,
    'stroopwafel' ,
    'bold' ,
    'anchor-lock' ,
    'building-ngo' ,
    'transporter-3' ,
    'engine-warning' ,
    'engine-exclamation' ,
    'circle-down-right' ,
    'square-k' ,
    'manat-sign' ,
    'money-check-pen' ,
    'money-check-edit' ,
    'not-equal' ,
    'border-top-left' ,
    'border-style' ,
    'map-location-dot' ,
    'map-marked-alt' ,
    'tilde' ,
    'jedi' ,
    'square-poll-vertical' ,
    'poll' ,
    'arrow-down-square-triangle' ,
    'sort-shapes-down-alt' ,
    'mug-hot' ,
    'dog-leashed' ,
    'car-battery' ,
    'battery-car' ,
    'face-downcast-sweat' ,
    'mailbox-flag-up' ,
    'memo-circle-info' ,
    'gift' ,
    'dice-two' ,
    'volume' ,
    'volume-medium' ,
    'transporter-5' ,
    'gauge-circle-bolt' ,
    'coin-front' ,
    'file-slash' ,
    'message-arrow-up-right' ,
    'treasure-chest' ,
    'chess-queen' ,
    'paintbrush-fine' ,
    'paint-brush-alt' ,
    'paint-brush-fine' ,
    'paintbrush-alt' ,
    'glasses' ,
    'hood-cloak' ,
    'square-quote' ,
    'up-left' ,
    'bring-front' ,
    'chess-board' ,
    'burger-cheese' ,
    'cheeseburger' ,
    'building-circle-check' ,
    'repeat-1' ,
    'arrow-down-to-line' ,
    'arrow-to-bottom' ,
    'grid-5' ,
    'right-long-to-line' ,
    'person-chalkboard' ,
    'mars-stroke-right' ,
    'mars-stroke-h' ,
    'hand-back-fist' ,
    'hand-rock' ,
    'tally' ,
    'tally5' ,
    'square-caret-up' ,
    'caret-square-up' ,
    'cloud-showers-water' ,
    'chart-bar' ,
    'bar-chart' ,
    'hands-bubbles' ,
    'hands-wash' ,
    'less-than-equal' ,
    'train' ,
    'up-from-dotted-line' ,
    'eye-low-vision' ,
    'low-vision' ,
    'traffic-light-go' ,
    'face-exhaling' ,
    'sensor-fire' ,
    'user-unlock' ,
    'hexagon-divide' ,
    '00' ,
    'crow' ,
    'cassette-betamax' ,
    'betamax' ,
    'sailboat' ,
    'window-restore' ,
    'nfc-magnifying-glass' ,
    'file-binary' ,
    'circle-v' ,
    'square-plus' ,
    'plus-square' ,
    'bowl-scoops' ,
    'mistletoe' ,
    'custard' ,
    'lacrosse-stick' ,
    'hockey-mask' ,
    'sunrise' ,
    'panel-ews' ,
    'torii-gate' ,
    'cloud-exclamation' ,
    'message-lines' ,
    'comment-alt-lines' ,
    'frog' ,
    'bucket' ,
    'floppy-disk-pen' ,
    'image' ,
    'window-frame' ,
    'microphone' ,
    'cow' ,
    'square-ring' ,
    'down-from-line' ,
    'arrow-alt-from-top' ,
    'caret-up' ,
    'shield-xmark' ,
    'shield-times' ,
    'screwdriver' ,
    'circle-sort-down' ,
    'sort-circle-down' ,
    'folder-closed' ,
    'house-tsunami' ,
    'square-nfi' ,
    'forklift' ,
    'arrow-up-from-ground-water' ,
    'bracket-square-right' ,
    'martini-glass' ,
    'glass-martini-alt' ,
    'rotate-left' ,
    'rotate-back' ,
    'rotate-backward' ,
    'undo-alt' ,
    'table-columns' ,
    'columns' ,
    'square-a' ,
    'tick' ,
    'lemon' ,
    'head-side-mask' ,
    'handshake' ,
    'gem' ,
    'dolly' ,
    'dolly-box' ,
    'smoking' ,
    'minimize' ,
    'compress-arrows-alt' ,
    'refrigerator' ,
    'monument' ,
    'octagon-xmark' ,
    'times-octagon' ,
    'xmark-octagon' ,
    'align-slash' ,
    'snowplow' ,
    'angles-right' ,
    'angle-double-right' ,
    'truck-ramp-couch' ,
    'truck-couch' ,
    'cannabis' ,
    'circle-play' ,
    'play-circle' ,
    'arrow-up-right-and-arrow-down-left-from-center' ,
    'tablets' ,
    '360-degrees' ,
    'ethernet' ,
    'euro-sign' ,
    'eur' ,
    'euro' ,
    'chair' ,
    'circle-check' ,
    'check-circle' ,
    'square-dashed-circle-plus' ,
    'money-simple-from-bracket' ,
    'bat' ,
    'circle-stop' ,
    'stop-circle' ,
    'head-side-headphones' ,
    'phone-rotary' ,
    'compass-drafting' ,
    'drafting-compass' ,
    'plate-wheat' ,
    'calendar-circle-minus' ,
    'chopsticks' ,
    'car-wrench' ,
    'car-mechanic' ,
    'icicles' ,
    'person-shelter' ,
    'neuter' ,
    'id-badge' ,
    'kazoo' ,
    'marker' ,
    'face-laugh-beam' ,
    'laugh-beam' ,
    'square-arrow-down-left' ,
    'battery-bolt' ,
    'tree-large' ,
    'helicopter-symbol' ,
    'aperture' ,
    'universal-access' ,
    'file-magnifying-glass' ,
    'file-search' ,
    'up-right' ,
    'circle-chevron-up' ,
    'chevron-circle-up' ,
    'user-police' ,
    'lari-sign' ,
    'volcano' ,
    'teddy-bear' ,
    'stocking' ,
    'person-walking-dashed-line-arrow-right' ,
    'image-slash' ,
    'mask-snorkel' ,
    'smoke' ,
    'sterling-sign' ,
    'gbp' ,
    'pound-sign' ,
    'battery-exclamation' ,
    'viruses' ,
    'square-person-confined' ,
    'user-tie' ,
    'arrow-down-long' ,
    'long-arrow-down' ,
    'tent-arrow-down-to-line' ,
    'certificate' ,
    'crystal-ball' ,
    'reply-all' ,
    'mail-reply-all' ,
    'suitcase' ,
    'person-skating' ,
    'skating' ,
    'star-shooting' ,
    'binary-lock' ,
    'filter-circle-dollar' ,
    'funnel-dollar' ,
    'camera-retro' ,
    'circle-arrow-down' ,
    'arrow-circle-down' ,
    'comment-pen' ,
    'comment-edit' ,
    'file-import' ,
    'arrow-right-to-file' ,
    'banjo' ,
    'square-arrow-up-right' ,
    'external-link-square' ,
    'light-emergency-on' ,
    'kerning' ,
    'box-open' ,
    'square-f' ,
    'scroll' ,
    'spa' ,
    'arrow-left-from-line' ,
    'arrow-from-right' ,
    'strawberry' ,
    'location-pin-lock' ,
    'pause' ,
    'clock-eight-thirty' ,
    'plane-engines' ,
    'plane-alt' ,
    'hill-avalanche' ,
    'temperature-empty' ,
    'temperature0' ,
    'thermometer0' ,
    'thermometer-empty' ,
    'bomb' ,
    'gauge-low' ,
    'tachometer-alt-slow' ,
    'registered' ,
    'trash-can-plus' ,
    'address-card' ,
    'contact-card' ,
    'vcard' ,
    'scale-unbalanced-flip' ,
    'balance-scale-right' ,
    'globe-snow' ,
    'subscript' ,
    'diamond-turn-right' ,
    'directions' ,
    'integral' ,
    'burst' ,
    'house-laptop' ,
    'laptop-house' ,
    'face-tired' ,
    'tired' ,
    'money-bills' ,
    'blinds-raised' ,
    'smog' ,
    'ufo-beam' ,
    'circle-caret-up' ,
    'caret-circle-up' ,
    'user-vneck-hair-long' ,
    'square-a-lock' ,
    'crutch' ,
    'gas-pump-slash' ,
    'font-awesome' ,
    'font-awesome-flag' ,
    'font-awesome-logo-full' ,
    'cloud-arrow-up' ,
    'cloud-upload' ,
    'cloud-upload-alt' ,
    'palette' ,
    'transporter-4' ,
    'objects-align-right' ,
    'arrows-turn-right' ,
    'vest' ,
    'pig' ,
    'inbox-full' ,
    'circle-envelope' ,
    'envelope-circle' ,
    'triangle-person-digging' ,
    'construction' ,
    'ferry' ,
    'bullseye-arrow' ,
    'arrows-down-to-people' ,
    'seedling' ,
    'sprout' ,
    'clock-seven' ,
    'left-right' ,
    'arrows-alt-h' ,
    'boxes-packing' ,
    'circle-arrow-left' ,
    'arrow-circle-left' ,
    'flashlight' ,
    'group-arrows-rotate' ,
    'bowl-food' ,
    'square-9' ,
    'candy-cane' ,
    'arrow-down-wide-short' ,
    'sort-amount-asc' ,
    'sort-amount-down' ,
    'square-dollar' ,
    'dollar-square' ,
    'usd-square' ,
    'phone-arrow-right' ,
    'hand-holding-seedling' ,
    'message-check' ,
    'comment-alt-check' ,
    'cloud-bolt' ,
    'thunderstorm' ,
    'text-slash' ,
    'remove-format' ,
    'watch' ,
    'circle-down-left' ,
    'text' ,
    'projector' ,
    'face-smile-wink' ,
    'smile-wink' ,
    'tombstone-blank' ,
    'tombstone-alt' ,
    'chess-king-piece' ,
    'chess-king-alt' ,
    'circle-6' ,
    'left' ,
    'arrow-alt-left' ,
    'file-word' ,
    'file-powerpoint' ,
    'square-down' ,
    'arrow-alt-square-down' ,
    'objects-align-center-vertical' ,
    'arrows-left-right' ,
    'arrows-h' ,
    'house-lock' ,
    'cloud-arrow-down' ,
    'cloud-download' ,
    'cloud-download-alt' ,
    'wreath' ,
    'children' ,
    'meter-droplet' ,
    'chalkboard' ,
    'blackboard' ,
    'user-large-slash' ,
    'user-alt-slash' ,
    'signal-strong' ,
    'signal4' ,
    'lollipop' ,
    'lollypop' ,
    'list-tree' ,
    'envelope-open' ,
    'draw-circle' ,
    'cat-space' ,
    'handshake-simple-slash' ,
    'handshake-alt-slash' ,
    'rabbit-running' ,
    'rabbit-fast' ,
    'memo-pad' ,
    'mattress-pillow' ,
    'alarm-plus' ,
    'alicorn' ,
    'comment-question' ,
    'gingerbread-man' ,
    'guarani-sign' ,
    'burger-fries' ,
    'mug-tea' ,
    'border-top' ,
    'arrows-rotate' ,
    'refresh' ,
    'sync' ,
    'circle-book-open' ,
    'book-circle' ,
    'arrows-to-dotted-line' ,
    'fire-extinguisher' ,
    'garage-open' ,
    'shelves-empty' ,
    'cruzeiro-sign' ,
    'watch-apple' ,
    'watch-calculator' ,
    'list-dropdown' ,
    'cabinet-filing' ,
    'burger-soda' ,
    'square-arrow-up' ,
    'arrow-square-up' ,
    'greater-than-equal' ,
    'pallet-box' ,
    'face-confounded' ,
    'shield-halved' ,
    'shield-alt' ,
    'truck-plow' ,
    'book-atlas' ,
    'atlas' ,
    'virus' ,
    'comment-middle-top' ,
    'envelope-circle-check' ,
    'layer-group' ,
    'restroom-simple' ,
    'arrows-to-dot' ,
    'border-outer' ,
    'hashtag-lock' ,
    'clock-two-thirty' ,
    'archway' ,
    'heart-circle-check' ,
    'house-chimney-crack' ,
    'house-damage' ,
    'file-zipper' ,
    'file-archive' ,
    'heart-half' ,
    'comment-check' ,
    'square' ,
    'memo' ,
    'martini-glass-empty' ,
    'glass-martini' ,
    'couch' ,
    'cedi-sign' ,
    'italic' ,
    'glass-citrus' ,
    'calendar-lines-pen' ,
    'church' ,
    'person-snowmobiling' ,
    'snowmobile' ,
    'face-hushed' ,
    'comments-dollar' ,
    'pickaxe' ,
    'link-simple-slash' ,
    'democrat' ,
    'face-confused' ,
    'pinball' ,
    'z' ,
    'person-skiing' ,
    'skiing' ,
    'deer' ,
    'input-pipe' ,
    'road-lock' ,
    'a' ,
    'bookmark-slash' ,
    'temperature-arrow-down' ,
    'temperature-down' ,
    'mace' ,
    'feather-pointed' ,
    'feather-alt' ,
    'sausage' ,
    'trash-can-clock' ,
    'p' ,
    'snowflake' ,
    'stomach' ,
    'newspaper' ,
    'rectangle-ad' ,
    'ad' ,
    'guitar-electric' ,
    'arrow-turn-down-right' ,
    'moon-cloud' ,
    'bread-slice-butter' ,
    'circle-arrow-right' ,
    'arrow-circle-right' ,
    'user-group-crown' ,
    'users-crown' ,
    'circle-i' ,
    'toilet-paper-check' ,
    'filter-circle-xmark' ,
    'locust' ,
    'sort' ,
    'unsorted' ,
    'list-ol' ,
    'list12' ,
    'list-numeric' ,
    'chart-waterfall' ,
    'face-party' ,
    'kidneys' ,
    'wifi-exclamation' ,
    'chart-network' ,
    'person-dress-burst' ,
    'dice-d4' ,
    'money-check-dollar' ,
    'money-check-alt' ,
    'vector-square' ,
    'bread-slice' ,
    'language' ,
    'wheat-awn-slash' ,
    'face-kiss-wink-heart' ,
    'kiss-wink-heart' ,
    'dagger' ,
    'podium' ,
    'memo-circle-check' ,
    'route-highway' ,
    'down-to-line' ,
    'arrow-alt-to-bottom' ,
    'filter' ,
    'square-g' ,
    'circle-phone' ,
    'phone-circle' ,
    'clipboard-prescription' ,
    'user-nurse-hair' ,
    'question' ,
    'file-signature' ,
    'toggle-large-on' ,
    'up-down-left-right' ,
    'arrows-alt' ,
    'dryer-heat' ,
    'dryer-alt' ,
    'house-chimney-user' ,
    'hand-holding-heart' ,
    'arrow-up-small-big' ,
    'sort-size-up-alt' ,
    'train-track' ,
    'puzzle-piece' ,
    'money-check' ,
    'star-half-stroke' ,
    'star-half-alt' ,
    'file-exclamation' ,
    'code' ,
    'whiskey-glass' ,
    'glass-whiskey' ,
    'moon-stars' ,
    'building-circle-exclamation' ,
    'clothes-hanger' ,
    'mobile-notch' ,
    'mobile-iphone' ,
    'magnifying-glass-chart' ,
    'arrow-up-right-from-square' ,
    'external-link' ,
    'cubes-stacked' ,
    'images-user' ,
    'won-sign' ,
    'krw' ,
    'won' ,
    'image-polaroid-user' ,
    'virus-covid' ,
    'square-ellipsis' ,
    'pie' ,
    'chess-knight-piece' ,
    'chess-knight-alt' ,
    'austral-sign' ,
    'cloud-plus' ,
    'f' ,
    'leaf' ,
    'bed-bunk' ,
    'road' ,
    'taxi' ,
    'cab' ,
    'person-circle-plus' ,
    'chart-pie' ,
    'pie-chart' ,
    'bolt-lightning' ,
    'clock-eight' ,
    'sack-xmark' ,
    'file-excel' ,
    'file-contract' ,
    'fish-fins' ,
    'circle-q' ,
    'building-flag' ,
    'face-grin-beam' ,
    'grin-beam' ,
    'object-ungroup' ,
    'face-disguise' ,
    'circle-arrow-down-right' ,
    'alien-8bit' ,
    'alien-monster' ,
    'hand-point-ribbon' ,
    'poop' ,
    'object-exclude' ,
    'telescope' ,
    'location-pin' ,
    'map-marker' ,
    'square-list' ,
    'kaaba' ,
    'toilet-paper' ,
    'helmet-safety' ,
    'hard-hat' ,
    'hat-hard' ,
    'comment-code' ,
    'sim-cards' ,
    'starship' ,
    'eject' ,
    'circle-right' ,
    'arrow-alt-circle-right' ,
    'plane-circle-check' ,
    'seal' ,
    'user-cowboy' ,
    'hexagon-vertical-nft' ,
    'face-rolling-eyes' ,
    'meh-rolling-eyes' ,
    'bread-loaf' ,
    'rings-wedding' ,
    'object-group' ,
    'french-fries' ,
    'chart-line' ,
    'line-chart' ,
    'calendar-arrow-down' ,
    'calendar-download' ,
    'send-back' ,
    'mask-ventilator' ,
    'signature-lock' ,
    'arrow-right' ,
    'signs-post' ,
    'map-signs' ,
    'octagon-plus' ,
    'plus-octagon' ,
    'cash-register' ,
    'person-circle-question' ,
    'melon-slice' ,
    'space-station-moon' ,
    'message-smile' ,
    'comment-alt-smile' ,
    'cup-straw' ,
    'left-from-line' ,
    'arrow-alt-from-right' ,
    'h' ,
    'basket-shopping-simple' ,
    'shopping-basket-alt' ,
    'hands-holding-heart' ,
    'hands-heart' ,
    'clock-nine' ,
    'tarp' ,
    'face-sleepy' ,
    'hand-horns' ,
    'screwdriver-wrench' ,
    'tools' ,
    'arrows-to-eye' ,
    'circle-three-quarters' ,
    'trophy-star' ,
    'trophy-alt' ,
    'plug-circle-bolt' ,
    'face-thermometer' ,
    'shirt-running' ,
    'book-circle-arrow-up' ,
    'face-nauseated' ,
    'heart' ,
    'file-chart-pie' ,
    'mars-and-venus' ,
    'house-user' ,
    'home-user' ,
    'circle-arrow-down-left' ,
    'dumpster-fire' ,
    'hexagon-minus' ,
    'minus-hexagon' ,
    'left-to-line' ,
    'arrow-alt-to-left' ,
    'house-crack' ,
    'paw-simple' ,
    'paw-alt' ,
    'arrow-left-long-to-line' ,
    'brackets-round' ,
    'parentheses' ,
    'martini-glass-citrus' ,
    'cocktail' ,
    'user-shakespeare' ,
    'arrow-right-to-arc' ,
    'face-surprise' ,
    'surprise' ,
    'bottle-water' ,
    'circle-pause' ,
    'pause-circle' ,
    'gauge-circle-plus' ,
    'folders' ,
    'angel' ,
    'value-absolute' ,
    'rabbit' ,
    'toilet-paper-slash' ,
    'apple-whole' ,
    'apple-alt' ,
    'kitchen-set' ,
    'diamond-half' ,
    'lock-keyhole' ,
    'lock-alt' ,
    'r' ,
    'temperature-quarter' ,
    'temperature1' ,
    'thermometer1' ,
    'thermometer-quarter' ,
    'square-info' ,
    'info-square' ,
    'wifi-slash' ,
    'toilet-paper-xmark' ,
    'hands-holding-dollar' ,
    'hands-usd' ,
    'cube' ,
    'arrow-down-triangle-square' ,
    'sort-shapes-down' ,
    'bitcoin-sign' ,
    'shutters' ,
    'shield-dog' ,
    'solar-panel' ,
    'lock-open' ,
    'table-tree' ,
    'house-chimney-heart' ,
    'tally-3' ,
    'elevator' ,
    'money-bill-transfer' ,
    'money-bill-trend-up' ,
    'house-flood-water-circle-arrow-right' ,
    'square-poll-horizontal' ,
    'poll-h' ,
    'circle' ,
    'cart-circle-exclamation' ,
    'sword' ,
    'backward-fast' ,
    'fast-backward' ,
    'recycle' ,
    'user-astronaut' ,
    'interrobang' ,
    'plane-slash' ,
    'circle-dashed' ,
    'trademark' ,
    'basketball' ,
    'basketball-ball' ,
    'fork-knife' ,
    'utensils-alt' ,
    'satellite-dish' ,
    'badge-check' ,
    'circle-up' ,
    'arrow-alt-circle-up' ,
    'slider' ,
    'mobile-screen-button' ,
    'mobile-alt' ,
    'clock-one-thirty' ,
    'inbox-out' ,
    'inbox-arrow-up' ,
    'cloud-slash' ,
    'volume-high' ,
    'volume-up' ,
    'users-rays' ,
    'wallet' ,
    'octagon-check' ,
    'flatbread-stuffed' ,
    'clipboard-check' ,
    'cart-circle-plus' ,
    'truck-clock' ,
    'shipping-timed' ,
    'pool-8-ball' ,
    'file-audio' ,
    'turn-down-left' ,
    'lock-hashtag' ,
    'chart-radar' ,
    'staff' ,
    'burger' ,
    'hamburger' ,
    'utility-pole' ,
    'transporter-6' ,
    'wrench' ,
    'bugs' ,
    'vector-polygon' ,
    'diagram-nested' ,
    'rupee-sign' ,
    'rupee' ,
    'file-image' ,
    'circle-question' ,
    'question-circle' ,
    'image-user' ,
    'buoy' ,
    'plane-departure' ,
    'handshake-slash' ,
    'book-bookmark' ,
    'border-center-h' ,
    'can-food' ,
    'typewriter' ,
    'arrow-right-from-arc' ,
    'circle-k' ,
    'face-hand-over-mouth' ,
    'popcorn' ,
    'house-water' ,
    'house-flood' ,
    'object-subtract' ,
    'code-branch' ,
    'warehouse-full' ,
    'warehouse-alt' ,
    'hat-cowboy' ,
    'bridge' ,
    'phone-flip' ,
    'phone-alt' ,
    'arrow-down-from-dotted-line' ,
    'square-quarters' ,
    'truck-front' ,
    'cat' ,
    'trash-xmark' ,
    'circle-caret-left' ,
    'caret-circle-left' ,
    'files' ,
    'anchor-circle-exclamation' ,
    'face-clouds' ,
    'user-crown' ,
    'truck-field' ,
    'route' ,
    'cart-circle-check' ,
    'clipboard-question' ,
    'panorama' ,
    'comment-medical' ,
    'teeth-open' ,
    'user-tie-hair-long' ,
    'file-circle-minus' ,
    'head-side-medical' ,
    'tags' ,
    'wine-glass' ,
    'forward-fast' ,
    'fast-forward' ,
    'face-meh-blank' ,
    'meh-blank' ,
    'user-robot' ,
    'square-parking' ,
    'parking' ,
    'card-diamond' ,
    'face-zipper' ,
    'face-raised-eyebrow' ,
    'house-signal' ,
    'square-chevron-up' ,
    'chevron-square-up' ,
    'nfc-symbol' ,
    'bars-progress' ,
    'tasks-alt' ,
    'faucet-drip' ,
    'arrows-to-line' ,
    'dolphin' ,
    'arrow-up-right' ,
    'circle-r' ,
    'cart-flatbed' ,
    'dolly-flatbed' ,
    'ban-smoking' ,
    'smoking-ban' ,
    'circle-sort-up' ,
    'sort-circle-up' ,
    'terminal' ,
    'mobile-button' ,
    'house-medical-flag' ,
    'basket-shopping' ,
    'shopping-basket' ,
    'tape' ,
    'chestnut' ,
    'bus-simple' ,
    'bus-alt' ,
    'eye' ,
    'face-sad-cry' ,
    'sad-cry' ,
    'heat' ,
    'ticket-airline' ,
    'boot-heeled' ,
    'arrows-minimize' ,
    'compress-arrows' ,
    'audio-description' ,
    'person-military-to-person' ,
    'file-shield' ,
    'hexagon' ,
    'manhole' ,
    'user-slash' ,
    'pen' ,
    'tower-observation' ,
    'floppy-disks' ,
    'toilet-paper-blank-under' ,
    'toilet-paper-reverse-alt' ,
    'file-code' ,
    'signal' ,
    'signal5' ,
    'signal-perfect' ,
    'pump' ,
    'bus' ,
    'heart-circle-xmark' ,
    'arrow-up-left-from-circle' ,
    'house-chimney' ,
    'home-lg' ,
    'window-maximize' ,
    'dryer' ,
    'face-frown' ,
    'frown' ,
    'chess-bishop-piece' ,
    'chess-bishop-alt' ,
    'shirt-tank-top' ,
    'diploma' ,
    'scroll-ribbon' ,
    'screencast' ,
    'walker' ,
    'prescription' ,
    'shop' ,
    'store-alt' ,
    'floppy-disk' ,
    'save' ,
    'vihara' ,
    'face-kiss-closed-eyes' ,
    'scale-unbalanced' ,
    'balance-scale-left' ,
    'file-user' ,
    'user-police-tie' ,
    'face-tongue-money' ,
    'tennis-ball' ,
    'square-l' ,
    'sort-up' ,
    'sort-asc' ,
    'calendar-arrow-up' ,
    'calendar-upload' ,
    'comment-dots' ,
    'commenting' ,
    'plant-wilt' ,
    'scarf' ,
    'album-circle-plus' ,
    'user-nurse-hair-long' ,
    'diamond' ,
    'square-left' ,
    'arrow-alt-square-left' ,
    'face-grin-squint' ,
    'grin-squint' ,
    'circle-ellipsis-vertical' ,
    'hand-holding-dollar' ,
    'hand-holding-usd' ,
    'grid-dividers' ,
    'bacterium' ,
    'hand-pointer' ,
    'drum-steelpan' ,
    'hand-scissors' ,
    'hands-praying' ,
    'praying-hands' ,
    'face-pensive' ,
    'user-music' ,
    'arrow-rotate-right' ,
    'arrow-right-rotate' ,
    'arrow-rotate-forward' ,
    'redo' ,
    'messages-dollar' ,
    'comments-alt-dollar' ,
    'sensor-on' ,
    'balloon' ,
    'biohazard' ,
    'chess-queen-piece' ,
    'chess-queen-alt' ,
    'location-crosshairs' ,
    'location' ,
    'mars-double' ,
    'house-person-leave' ,
    'house-leave' ,
    'house-person-depart' ,
    'ruler-triangle' ,
    'card-club' ,
    'child-dress' ,
    'users-between-lines' ,
    'lungs-virus' ,
    'spinner-third' ,
    'face-grin-tears' ,
    'grin-tears' ,
    'phone' ,
    'computer-mouse-scrollwheel' ,
    'mouse-alt' ,
    'calendar-xmark' ,
    'calendar-times' ,
    'child-reaching' ,
    'table-layout' ,
    'narwhal' ,
    'ramp-loading' ,
    'calendar-circle-plus' ,
    'toothbrush' ,
    'border-inner' ,
    'paw-claws' ,
    'kiwi-fruit' ,
    'traffic-light-slow' ,
    'rectangle-code' ,
    'head-side-virus' ,
    'keyboard-brightness' ,
    'books-medical' ,
    'lightbulb-slash' ,
    'house-blank' ,
    'home-blank' ,
    'square-5' ,
    'square-heart' ,
    'heart-square' ,
    'puzzle' ,
    'user-gear' ,
    'user-cog' ,
    'pipe-circle-check' ,
    'arrow-up-1-9' ,
    'sort-numeric-up' ,
    'octagon-exclamation' ,
    'dial-low' ,
    'door-closed' ,
    'laptop-mobile' ,
    'phone-laptop' ,
    'conveyor-belt-boxes' ,
    'conveyor-belt-alt' ,
    'shield-virus' ,
    'starfighter-twin-ion-engine-advanced' ,
    'starfighter-alt-advanced' ,
    'dice-six' ,
    'starfighter-twin-ion-engine' ,
    'starfighter-alt' ,
    'rocket-launch' ,
    'mosquito-net' ,
    'vent-damper' ,
    'bridge-water' ,
    'ban-bug' ,
    'debug' ,
    'person-booth' ,
    'text-width' ,
    'garage-car' ,
    'square-kanban' ,
    'hat-wizard' ,
    'pen-fancy' ,
    'coffee-pot' ,
    'mouse-field' ,
    'person-digging' ,
    'digging' ,
    'shower-down' ,
    'shower-alt' ,
    'box-circle-check' ,
    'brightness' ,
    'car-side-bolt' ,
    'ornament' ,
    'phone-arrow-down-left' ,
    'phone-arrow-down' ,
    'phone-incoming' ,
    'cloud-word' ,
    'hand-fingers-crossed' ,
    'trash' ,
    'gauge-simple' ,
    'gauge-simple-med' ,
    'tachometer-average' ,
    'arrow-down-small-big' ,
    'sort-size-down-alt' ,
    'book-medical' ,
    'face-melting' ,
    'poo' ,
    'pen-clip-slash' ,
    'pen-alt-slash' ,
    'quote-right' ,
    'quote-right-alt' ,
    'scroll-old' ,
    'guitars' ,
    'phone-xmark' ,
    'hose' ,
    'clock-six' ,
    'shirt' ,
    't-shirt' ,
    'tshirt' ,
    'square-r' ,
    'cubes' ,
    'envelope-open-dollar' ,
    'divide' ,
    'sun-cloud' ,
    'lamp-floor' ,
    'square-7' ,
    'tenge-sign' ,
    'tenge' ,
    'headphones' ,
    'hands-holding' ,
    'campfire' ,
    'circle-ampersand' ,
    'snowflakes' ,
    'hands-clapping' ,
    'republican' ,
    'leaf-maple' ,
    'arrow-left' ,
    'person-circle-xmark' ,
    'ruler' ,
    'cup-straw-swoosh' ,
    'temperature-sun' ,
    'temperature-hot' ,
    'align-left' ,
    'dice-d6' ,
    'restroom' ,
    'high-definition' ,
    'rectangle-hd' ,
    'j' ,
    'galaxy' ,
    'users-viewfinder' ,
    'file-video' ,
    'cherries' ,
    'up-right-from-square' ,
    'external-link-alt' ,
    'circle-sort' ,
    'sort-circle' ,
    'table-cells' ,
    'th' ,
    'file-pdf' ,
    'siren' ,
    'arrow-up-to-dotted-line' ,
    'image-landscape' ,
    'landscape' ,
    'tank-water' ,
    'curling-stone' ,
    'curling' ,
    'gamepad-modern' ,
    'gamepad-alt' ,
    'messages-question' ,
    'book-bible' ,
    'bible' ,
    'o' ,
    'suitcase-medical' ,
    'medkit' ,
    'briefcase-arrow-right' ,
    'expand-wide' ,
    'clock-eleven-thirty' ,
    'rv' ,
    'user-secret' ,
    'otter' ,
    'dreidel' ,
    'person-dress' ,
    'female' ,
    'comment-dollar' ,
    'business-time' ,
    'briefcase-clock' ,
    'flower-tulip' ,
    'people-pants-simple' ,
    'cloud-drizzle' ,
    'table-cells-large' ,
    'th-large' ,
    'book-tanakh' ,
    'tanakh' ,
    'solar-system' ,
    'seal-question' ,
    'phone-volume' ,
    'volume-control-phone' ,
    'disc-drive' ,
    'hat-cowboy-side' ,
    'table-rows' ,
    'rows' ,
    'location-exclamation' ,
    'map-marker-exclamation' ,
    'face-fearful' ,
    'clipboard-user' ,
    'bus-school' ,
    'film-slash' ,
    'square-arrow-down-right' ,
    'book-sparkles' ,
    'book-spells' ,
    'washing-machine' ,
    'washer' ,
    'child' ,
    'lira-sign' ,
    'user-visor' ,
    'file-plus-minus' ,
    'chess-clock-flip' ,
    'chess-clock-alt' ,
    'satellite' ,
    'plane-lock' ,
    'steering-wheel' ,
    'tag' ,
    'stretcher' ,
    'book-section' ,
    'book-law' ,
    'inboxes' ,
    'coffee-bean' ,
    'brackets-curly' ,
    'ellipsis-stroke-vertical' ,
    'ellipsis-v-alt' ,
    'comment' ,
    'square-1' ,
    'cake-candles' ,
    'birthday-cake' ,
    'cake' ,
    'head-side' ,
    'envelope' ,
    'dolly-empty' ,
    'face-tissue' ,
    'angles-up' ,
    'angle-double-up' ,
    'paperclip' ,
    'chart-line-down' ,
    'arrow-right-to-city' ,
    'lock-a' ,
    'ribbon' ,
    'lungs' ,
    'person-pinball' ,
    'arrow-up-9-1' ,
    'sort-numeric-up-alt' ,
    'apple-core' ,
    'circle-y' ,
    'h6' ,
    'litecoin-sign' ,
    'circle-small' ,
    'border-none' ,
    'arrow-turn-down-left' ,
    'circle-nodes' ,
    'parachute-box' ,
    'message-medical' ,
    'comment-alt-medical' ,
    'rugby-ball' ,
    'comment-music' ,
    'indent' ,
    'tree-deciduous' ,
    'tree-alt' ,
    'puzzle-piece-simple' ,
    'puzzle-piece-alt' ,
    'truck-field-un' ,
    'nfc-trash' ,
    'hourglass' ,
    'hourglass-empty' ,
    'mountain' ,
    'file-xmark' ,
    'file-times' ,
    'house-heart' ,
    'home-heart' ,
    'house-chimney-blank' ,
    'meter-bolt' ,
    'user-doctor' ,
    'user-md' ,
    'slash-back' ,
    'circle-info' ,
    'info-circle' ,
    'fishing-rod' ,
    'hammer-crash' ,
    'message-heart' ,
    'cloud-meatball' ,
    'camera-polaroid' ,
    'camera' ,
    'camera-alt' ,
    'square-virus' ,
    'cart-arrow-up' ,
    'meteor' ,
    'car-on' ,
    'sleigh' ,
    'arrow-down-1-9' ,
    'sort-numeric-asc' ,
    'sort-numeric-down' ,
    'buoy-mooring' ,
    'square-4' ,
    'hand-holding-droplet' ,
    'hand-holding-water' ,
    'tricycle-adult' ,
    'waveform' ,
    'water' ,
    'star-sharp-half-stroke' ,
    'star-sharp-half-alt' ,
    'nfc-signal' ,
    'plane-prop' ,
    'calendar-check' ,
    'clock-desk' ,
    'calendar-clock' ,
    'calendar-time' ,
    'braille' ,
    'prescription-bottle-medical' ,
    'prescription-bottle-alt' ,
    'plate-utensils' ,
    'family-pants' ,
    'hose-reel' ,
    'house-window' ,
    'landmark' ,
    'truck' ,
    'crosshairs' ,
    'cloud-rainbow' ,
    'person-cane' ,
    'alien' ,
    'tent' ,
    'vest-patches' ,
    'people-dress-simple' ,
    'check-double' ,
    'arrow-down-a-z' ,
    'sort-alpha-asc' ,
    'sort-alpha-down' ,
    'bowling-ball-pin' ,
    'bell-school-slash' ,
    'plus-large' ,
    'money-bill-wheat' ,
    'camera-viewfinder' ,
    'screenshot' ,
    'message-music' ,
    'comment-alt-music' ,
    'car-building' ,
    'border-bottom-right' ,
    'border-style-alt' ,
    'octagon' ,
    'comment-arrow-up-right' ,
    'octagon-divide' ,
    'cookie' ,
    'arrow-rotate-left' ,
    'arrow-left-rotate' ,
    'arrow-rotate-back' ,
    'arrow-rotate-backward' ,
    'undo' ,
    'tv-music' ,
    'hard-drive' ,
    'hdd' ,
    'reel' ,
    'face-grin-squint-tears' ,
    'grin-squint-tears' ,
    'dumbbell' ,
    'rectangle-list' ,
    'list-alt' ,
    'tarp-droplet' ,
    'alarm-exclamation' ,
    'house-medical-circle-check' ,
    'traffic-cone' ,
    'grate' ,
    'arrow-down-right' ,
    'person-skiing-nordic' ,
    'skiing-nordic' ,
    'calendar-plus' ,
    'person-from-portal' ,
    'portal-exit' ,
    'plane-arrival' ,
    'cowbell-circle-plus' ,
    'cowbell-more' ,
    'circle-left' ,
    'arrow-alt-circle-left' ,
    'distribute-spacing-vertical' ,
    'signal-bars-fair' ,
    'signal-alt2' ,
    'sportsball' ,
    'game-console-handheld-crank' ,
    'train-subway' ,
    'subway' ,
    'chart-gantt' ,
    'face-smile-upside-down' ,
    'ball-pile' ,
    'badge-dollar' ,
    'money-bills-simple' ,
    'money-bills-alt' ,
    'list-timeline' ,
    'indian-rupee-sign' ,
    'indian-rupee' ,
    'inr' ,
    'crop-simple' ,
    'crop-alt' ,
    'money-bill-1' ,
    'money-bill-alt' ,
    'left-long' ,
    'long-arrow-alt-left' ,
    'keyboard-down' ,
    'circle-up-right' ,
    'cloud-bolt-moon' ,
    'thunderstorm-moon' ,
    'dna' ,
    'virus-slash' ,
    'bracket-round-right' ,
    'circle-5' ,
    'minus' ,
    'subtract' ,
    'fire-flame' ,
    'flame' ,
    'right-to-line' ,
    'arrow-alt-to-right' ,
    'gif' ,
    'chess' ,
    'trash-slash' ,
    'arrow-left-long' ,
    'long-arrow-left' ,
    'plug-circle-check' ,
    'font-case' ,
    'street-view' ,
    'arrow-down-left' ,
    'franc-sign' ,
    'flask-round-poison' ,
    'flask-poison' ,
    'volume-off' ,
    'book-circle-arrow-right' ,
    'chart-user' ,
    'user-chart' ,
    'hands-asl-interpreting' ,
    'american-sign-language-interpreting' ,
    'asl-interpreting' ,
    'hands-american-sign-language-interpreting' ,
    'presentation-screen' ,
    'presentation' ,
    'circle-bolt' ,
    'face-smile-halo' ,
    'cart-circle-arrow-down' ,
    'house-person-return' ,
    'house-person-arrive' ,
    'house-return' ,
    'message-xmark' ,
    'comment-alt-times' ,
    'message-times' ,
    'file-certificate' ,
    'file-award' ,
    'user-doctor-hair-long' ,
    'camera-security' ,
    'camera-home' ,
    'gear' ,
    'cog' ,
    'droplet-slash' ,
    'tint-slash' ,
    'book-heart' ,
    'mosque' ,
    'duck' ,
    'mosquito' ,
    'star-of-david' ,
    'flag-swallowtail' ,
    'flag-alt' ,
    'person-military-rifle' ,
    'car-garage' ,
    'cart-shopping' ,
    'shopping-cart' ,
    'book-font' ,
    'shield-plus' ,
    'vials' ,
    'eye-dropper-full' ,
    'distribute-spacing-horizontal' ,
    'tablet-rugged' ,
    'temperature-snow' ,
    'temperature-frigid' ,
    'moped' ,
    'face-smile-plus' ,
    'smile-plus' ,
    'radio-tuner' ,
    'radio-alt' ,
    'face-swear' ,
    'water-arrow-down' ,
    'water-lower' ,
    'scanner-touchscreen' ,
    'circle-7' ,
    'plug-circle-plus' ,
    'person-ski-jumping' ,
    'ski-jump' ,
    'place-of-worship' ,
    'water-arrow-up' ,
    'water-rise' ,
    'waveform-lines' ,
    'waveform-path' ,
    'split' ,
    'film-canister' ,
    'film-cannister' ,
    'folder-xmark' ,
    'folder-times' ,
    'toilet-paper-blank' ,
    'toilet-paper-alt' ,
    'tablet-screen' ,
    'tablet-android-alt' ,
    'hexagon-vertical-nft-slanted' ,
    'folder-music' ,
    'display-medical' ,
    'desktop-medical' ,
    'share-all' ,
    'peapod' ,
    'chess-clock' ,
    'axe' ,
    'square-d' ,
    'grip-vertical' ,
    'mobile-signal-out' ,
    'arrow-turn-up' ,
    'level-up' ,
    'u' ,
    'arrow-up-from-dotted-line' ,
    'square-root-variable' ,
    'square-root-alt' ,
    'light-switch-on' ,
    'arrow-down-arrow-up' ,
    'sort-alt' ,
    'raindrops' ,
    'dash' ,
    'minus-large' ,
    'clock' ,
    'clock-four' ,
    'input-numeric' ,
    'truck-tow' ,
    'backward-step' ,
    'step-backward' ,
    'pallet' ,
    'car-bolt' ,
    'arrows-maximize' ,
    'expand-arrows' ,
    'faucet' ,
    'cloud-sleet' ,
    'lamp-street' ,
    'list-radio' ,
    'pen-nib-slash' ,
    'baseball-bat-ball' ,
    'square-up-left' ,
    'overline' ,
    's' ,
    'timeline' ,
    'keyboard' ,
    'arrows-from-dotted-line' ,
    'usb-drive' ,
    'ballot' ,
    'caret-down' ,
    'location-dot-slash' ,
    'map-marker-alt-slash' ,
    'cards' ,
    'house-chimney-medical' ,
    'clinic-medical' ,
    'boxing-glove' ,
    'glove-boxing' ,
    'temperature-three-quarters' ,
    'temperature3' ,
    'thermometer3' ,
    'thermometer-three-quarters' ,
    'bell-school' ,
    'mobile-screen' ,
    'mobile-android-alt' ,
    'plane-up' ,
    'folder-heart' ,
    'circle-location-arrow' ,
    'location-circle' ,
    'face-head-bandage' ,
    'sushi-roll' ,
    'maki-roll' ,
    'makizushi' ,
    'car-bump' ,
    'piggy-bank' ,
    'racquet' ,
    'car-mirrors' ,
    'industry-windows' ,
    'industry-alt' ,
    'bolt-auto' ,
    'battery-half' ,
    'battery3' ,
    'flux-capacitor' ,
    'mountain-city' ,
    'coins' ,
    'honey-pot' ,
    'olive' ,
    'khanda' ,
    'filter-list' ,
    'outlet' ,
    'sliders' ,
    'sliders-h' ,
    'cauldron' ,
    'people' ,
    'folder-tree' ,
    'network-wired' ,
    'croissant' ,
    'map-pin' ,
    'hamsa' ,
    'cent-sign' ,
    'swords-laser' ,
    'flask' ,
    'person-pregnant' ,
    'square-u' ,
    'wand-sparkles' ,
    'router' ,
    'ellipsis-vertical' ,
    'ellipsis-v' ,
    'sword-laser-alt' ,
    'ticket' ,
    'power-off' ,
    'coin' ,
    'laptop-slash' ,
    'right-long' ,
    'long-arrow-alt-right' ,
    'circle-b' ,
    'person-dress-simple' ,
    'pipe-collar' ,
    'lights-holiday' ,
    'citrus' ,
    'flag-usa' ,
    'laptop-file' ,
    'tty' ,
    'teletype' ,
    'chart-tree-map' ,
    'diagram-next' ,
    'person-rifle' ,
    'clock-five-thirty' ,
    'pipe-valve' ,
    'arrow-up-from-arc' ,
    'face-spiral-eyes' ,
    'compress-wide' ,
    'circle-phone-hangup' ,
    'phone-circle-down' ,
    'house-medical-circle-exclamation' ,
    'badminton' ,
    'closed-captioning' ,
    'person-hiking' ,
    'hiking' ,
    'right-from-line' ,
    'arrow-alt-from-left' ,
    'venus-double' ,
    'images' ,
    'calculator' ,
    'shuttlecock' ,
    'user-hair' ,
    'eye-evil' ,
    'people-pulling' ,
    'n' ,
    'garage' ,
    'cable-car' ,
    'tram' ,
    'shovel-snow' ,
    'cloud-rain' ,
    'face-lying' ,
    'sprinkler' ,
    'building-circle-xmark' ,
    'person-sledding' ,
    'sledding' ,
    'game-console-handheld' ,
    'ship' ,
    'clock-six-thirty' ,
    'battery-slash' ,
    'tugrik-sign' ,
    'arrows-down-to-line' ,
    'download' ,
    'shelves' ,
    'inventory' ,
    'cloud-snow' ,
    'face-grin' ,
    'grin' ,
    'delete-left' ,
    'backspace' ,
    'oven' ,
    'eye-dropper' ,
    'eye-dropper-empty' ,
    'eyedropper' ,
    'comment-captions' ,
    'comments-question' ,
    'scribble' ,
    'rotate-exclamation' ,
    'file-circle-check' ,
    'glass' ,
    'loader' ,
    'forward' ,
    'user-pilot' ,
    'mobile' ,
    'mobile-android' ,
    'mobile-phone' ,
    'code-pull-request-closed' ,
    'face-meh' ,
    'meh' ,
    'align-center' ,
    'book-skull' ,
    'book-dead' ,
    'id-card' ,
    'drivers-license' ,
    'face-dotted' ,
    'face-worried' ,
    'outdent' ,
    'dedent' ,
    'heart-circle-exclamation' ,
    'house' ,
    'home' ,
    'home-alt' ,
    'home-lg-alt' ,
    'vector-circle' ,
    'car-circle-bolt' ,
    'calendar-week' ,
    'flying-disc' ,
    'laptop-medical' ,
    'square-down-right' ,
    'b' ,
    'seat-airline' ,
    'moon-over-sun' ,
    'eclipse-alt' ,
    'pipe' ,
    'file-medical' ,
    'potato' ,
    'dice-one' ,
    'circle-a' ,
    'helmet-battle' ,
    'butter' ,
    'blanket-fire' ,
    'kiwi-bird' ,
    'castle' ,
    'golf-club' ,
    'arrow-right-arrow-left' ,
    'exchange' ,
    'rotate-right' ,
    'redo-alt' ,
    'rotate-forward' ,
    'utensils' ,
    'cutlery' ,
    'arrow-up-wide-short' ,
    'sort-amount-up' ,
    'balloons' ,
    'mill-sign' ,
    'bowl-rice' ,
    'timeline-arrow' ,
    'skull' ,
    'game-board-simple' ,
    'game-board-alt' ,
    'circle-video' ,
    'video-circle' ,
    'chart-scatter-bubble' ,
    'house-turret' ,
    'banana' ,
    'hand-holding-skull' ,
    'people-dress' ,
    'loveseat' ,
    'couch-small' ,
    'tower-broadcast' ,
    'broadcast-tower' ,
    'truck-pickup' ,
    'block-quote' ,
    'up-long' ,
    'long-arrow-alt-up' ,
    'stop' ,
    'code-merge' ,
    'money-check-dollar-pen' ,
    'money-check-edit-alt' ,
    'up-from-line' ,
    'arrow-alt-from-bottom' ,
    'upload' ,
    'hurricane' ,
    'people-pants' ,
    'mound' ,
    'windsock' ,
    'circle-half' ,
    'brake-warning' ,
    'toilet-portable' ,
    'compact-disc' ,
    'file-arrow-down' ,
    'file-download' ,
    'saxophone-fire' ,
    'sax-hot' ,
    'camera-web-slash' ,
    'webcam-slash' ,
    'folder-medical' ,
    'folder-gear' ,
    'folder-cog' ,
    'hand-wave' ,
    'arrow-up-arrow-down' ,
    'sort-up-down' ,
    'caravan' ,
    'shield-cat' ,
    'message-slash' ,
    'comment-alt-slash' ,
    'bolt' ,
    'zap' ,
    'trash-can-check' ,
    'glass-water' ,
    'oil-well' ,
    'person-simple' ,
    'vault' ,
    'mars' ,
    'toilet' ,
    'plane-circle-xmark' ,
    'yen-sign' ,
    'cny' ,
    'jpy' ,
    'rmb' ,
    'yen' ,
    'notes' ,
    'ruble-sign' ,
    'rouble' ,
    'rub' ,
    'ruble' ,
    'trash-undo' ,
    'trash-arrow-turn-left' ,
    'champagne-glass' ,
    'glass-champagne' ,
    'objects-align-center-horizontal' ,
    'sun' ,
    'trash-can-slash' ,
    'trash-alt-slash' ,
    'screen-users' ,
    'users-class' ,
    'guitar' ,
    'square-arrow-left' ,
    'arrow-square-left' ,
    'square-8' ,
    'face-smile-hearts' ,
    'brackets-square' ,
    'brackets' ,
    'laptop-arrow-down' ,
    'hockey-stick-puck' ,
    'house-tree' ,
    'signal-fair' ,
    'signal2' ,
    'face-laugh-wink' ,
    'laugh-wink' ,
    'circle-dollar' ,
    'dollar-circle' ,
    'usd-circle' ,
    'horse-head' ,
    'arrows-repeat' ,
    'repeat-alt' ,
    'bore-hole' ,
    'industry' ,
    'image-polaroid' ,
    'wave-triangle' ,
    'circle-down' ,
    'arrow-alt-circle-down' ,
    'grill' ,
    'arrows-turn-to-dots' ,
    'chart-mixed' ,
    'analytics' ,
    'florin-sign' ,
    'arrow-down-short-wide' ,
    'sort-amount-desc' ,
    'sort-amount-down-alt' ,
    'less-than' ,
    'display-code' ,
    'desktop-code' ,
    'face-drooling' ,
    'oil-temperature' ,
    'oil-temp' ,
    'square-question' ,
    'question-square' ,
    'air-conditioner' ,
    'angle-down' ,
    'mountains' ,
    'omega' ,
    'car-tunnel' ,
    'person-dolly-empty' ,
    'pan-food' ,
    'head-side-cough' ,
    'grip-lines' ,
    'thumbs-down' ,
    'user-lock' ,
    'arrow-right-long' ,
    'long-arrow-right' ,
    'tickets-airline' ,
    'anchor-circle-xmark' ,
    'ellipsis' ,
    'ellipsis-h' ,
    'nfc-slash' ,
    'chess-pawn' ,
    'kit-medical' ,
    'first-aid' ,
    'grid-2-plus' ,
    'bells' ,
    'person-through-window' ,
    'toolbox' ,
    'envelope-dot' ,
    'envelope-badge' ,
    'hands-holding-circle' ,
    'bug' ,
    'bowl-chopsticks' ,
    'credit-card' ,
    'credit-card-alt' ,
    'circle-s' ,
    'box-ballot' ,
    'car' ,
    'automobile' ,
    'hand-holding-hand' ,
    'user-tie-hair' ,
    'podium-star' ,
    'user-hair-mullet' ,
    'business-front' ,
    'party-back' ,
    'trian-balbot' ,
    'microphone-stand' ,
    'book-open-reader' ,
    'book-reader' ,
    'family-dress' ,
    'circle-x' ,
    'cabin' ,
    'mountain-sun' ,
    'chart-simple-horizontal' ,
    'bluetooth' ,
    'arrows-left-right-to-line' ,
    'hand-back-point-left' ,
    'message-dots' ,
    'comment-alt-dots' ,
    'messaging' ,
    'file-heart' ,
    'beer-mug' ,
    'beer-foam' ,
    'dice-d20' ,
    'drone' ,
    'truck-droplet' ,
    'file-circle-xmark' ,
    'temperature-arrow-up' ,
    'temperature-up' ,
    'medal' ,
    'bed' ,
    'book-copy' ,
    'square-h' ,
    'h-square' ,
    'square-c' ,
    'clock-two' ,
    'square-ellipsis-vertical' ,
    'podcast' ,
    'bee' ,
    'temperature-full' ,
    'temperature4' ,
    'thermometer4' ,
    'thermometer-full' ,
    'bell' ,
    'candy-bar' ,
    'chocolate-bar' ,
    'xmark-large' ,
    'pinata' ,
    'arrows-from-line' ,
    'superscript' ,
    'bowl-spoon' ,
    'hexagon-check' ,
    'plug-circle-xmark' ,
    'star-of-life' ,
    'phone-slash' ,
    'traffic-light-stop' ,
    'paint-roller' ,
    'accent-grave' ,
    'handshake-angle' ,
    'hands-helping' ,
    'circle-0' ,
    'dial-med-low' ,
    'location-dot' ,
    'map-marker-alt' ,
    'crab' ,
    'box-open-full' ,
    'box-full' ,
    'file' ,
    'greater-than' ,
    'quotes' ,
    'pretzel' ,
    'person-swimming' ,
    'swimmer' ,
    'arrow-down' ,
    'user-robot-xmarks' ,
    'message-quote' ,
    'comment-alt-quote' ,
    'candy-corn' ,
    'folder-magnifying-glass' ,
    'folder-search' ,
    'notebook' ,
    'droplet' ,
    'tint' ,
    'bullseye-pointer' ,
    'eraser' ,
    'hexagon-image' ,
    'earth-americas' ,
    'earth' ,
    'earth-america' ,
    'globe-americas' ,
    'crate-apple' ,
    'apple-crate' ,
    'person-burst' ,
    'game-board' ,
    'hat-chef' ,
    'hand-back-point-right' ,
    'dove' ,
    'snowflake-droplets' ,
    'battery-empty' ,
    'battery0' ,
    'grid-4' ,
    'socks' ,
    'face-sunglasses' ,
    'inbox' ,
    'square-0' ,
    'section' ,
    'square-this-way-up' ,
    'box-up' ,
    'gauge-high' ,
    'tachometer-alt' ,
    'tachometer-alt-fast' ,
    'square-ampersand' ,
    'envelope-open-text' ,
    'lamp-desk' ,
    'hospital' ,
    'hospital-alt' ,
    'hospital-wide' ,
    'poll-people' ,
    'whiskey-glass-ice' ,
    'glass-whiskey-rocks' ,
    'wine-bottle' ,
    'chess-rook' ,
    'user-bounty-hunter' ,
    'bars-staggered' ,
    'reorder' ,
    'stream' ,
    'diagram-sankey' ,
    'cloud-hail-mixed' ,
    'circle-up-left' ,
    'dharmachakra' ,
    'objects-align-left' ,
    'oil-can-drip' ,
    'face-smiling-hands' ,
    'broccoli' ,
    'route-interstate' ,
    'ear-muffs' ,
    'hotdog' ,
    'transporter-empty' ,
    'person-walking-with-cane' ,
    'blind' ,
    'angle-90' ,
    'rectangle-terminal' ,
    'kite' ,
    'drum' ,
    'scrubber' ,
    'ice-cream' ,
    'heart-circle-bolt' ,
    'fish-bones' ,
    'deer-rudolph' ,
    'fax' ,
    'paragraph' ,
    'head-side-heart' ,
    'square-e' ,
    'meter-fire' ,
    'cloud-hail' ,
    'check-to-slot' ,
    'vote-yea' ,
    'money-from-bracket' ,
    'star-half' ,
    'car-bus' ,
    'speaker' ,
    'timer' ,
    'boxes-stacked' ,
    'boxes' ,
    'boxes-alt' ,
    'grill-hot' ,
    'ballot-check' ,
    'link' ,
    'chain' ,
    'ear-listen' ,
    'assistive-listening-systems' ,
    'file-minus' ,
    'tree-city' ,
    'play' ,
    'font' ,
    'cup-togo' ,
    'coffee-togo' ,
    'square-down-left' ,
    'burger-lettuce' ,
    'rupiah-sign' ,
    'magnifying-glass' ,
    'search' ,
    'table-tennis-paddle-ball' ,
    'ping-pong-paddle-ball' ,
    'table-tennis' ,
    'person-dots-from-line' ,
    'diagnoses' ,
    'chevrons-down' ,
    'chevron-double-down' ,
    'trash-can-arrow-up' ,
    'trash-restore-alt' ,
    'signal-good' ,
    'signal3' ,
    'location-question' ,
    'map-marker-question' ,
    'floppy-disk-circle-xmark' ,
    'floppy-disk-times' ,
    'save-circle-xmark' ,
    'save-times' ,
    'naira-sign' ,
    'peach' ,
    'taxi-bus' ,
    'bracket-curly' ,
    'bracket-curly-left' ,
    'lobster' ,
    'cart-flatbed-empty' ,
    'dolly-flatbed-empty' ,
    'colon' ,
    'cart-arrow-down' ,
    'wand' ,
    'walkie-talkie' ,
    'file-pen' ,
    'file-edit' ,
    'receipt' ,
    'table-picnic' ,
    'square-pen' ,
    'pen-square' ,
    'pencil-square' ,
    'circle-microphone-lines' ,
    'microphone-circle-alt' ,
    'display-slash' ,
    'desktop-slash' ,
    'suitcase-rolling' ,
    'person-circle-exclamation' ,
    'transporter-2' ,
    'hands-holding-diamond' ,
    'hand-receiving' ,
    'money-bill-simple-wave' ,
    'chevron-down' ,
    'battery-full' ,
    'battery' ,
    'battery5' ,
    'bell-plus' ,
    'book-arrow-right' ,
    'hospitals' ,
    'club' ,
    'skull-crossbones' ,
    'droplet-degree' ,
    'dewpoint' ,
    'code-compare' ,
    'list-ul' ,
    'list-dots' ,
    'hand-holding-magic' ,
    'watermelon-slice' ,
    'circle-ellipsis' ,
    'school-lock' ,
    'tower-cell' ,
    'sd-cards' ,
    'down-long' ,
    'long-arrow-alt-down' ,
    'envelopes' ,
    'phone-office' ,
    'ranking-star' ,
    'chess-king' ,
    'nfc-pen' ,
    'person-harassing' ,
    'hat-winter' ,
    'brazilian-real-sign' ,
    'landmark-dome' ,
    'landmark-alt' ,
    'bone-break' ,
    'arrow-up' ,
    'down-from-dotted-line' ,
    'tv' ,
    'television' ,
    'tv-alt' ,
    'border-left' ,
    'circle-divide' ,
    'shrimp' ,
    'list-check' ,
    'tasks' ,
    'diagram-subtask' ,
    'jug-detergent' ,
    'circle-user' ,
    'user-circle' ,
    'square-y' ,
    'user-doctor-hair' ,
    'planet-ringed' ,
    'mushroom' ,
    'user-shield' ,
    'megaphone' ,
    'circle-exclamation-check' ,
    'wind' ,
    'box-dollar' ,
    'box-usd' ,
    'car-burst' ,
    'car-crash' ,
    'y' ,
    'user-headset' ,
    'arrows-retweet' ,
    'retweet-alt' ,
    'person-snowboarding' ,
    'snowboarding' ,
    'square-chevron-right' ,
    'chevron-square-right' ,
    'lacrosse-stick-ball' ,
    'truck-fast' ,
    'shipping-fast' ,
    'user-magnifying-glass' ,
    'star-sharp' ,
    'comment-heart' ,
    'circle-1' ,
    'circle-star' ,
    'star-circle' ,
    'fish' ,
    'cloud-fog' ,
    'fog' ,
    'waffle' ,
    'music-note' ,
    'music-alt' ,
    'hexagon-exclamation' ,
    'cart-shopping-fast' ,
    'object-union' ,
    'user-graduate' ,
    'starfighter' ,
    'circle-half-stroke' ,
    'adjust' ,
    'arrow-right-long-to-line' ,
    'square-arrow-down' ,
    'arrow-square-down' ,
    'diamond-half-stroke' ,
    'clapperboard' ,
    'square-chevron-left' ,
    'chevron-square-left' ,
    'phone-intercom' ,
    'link-horizontal' ,
    'chain-horizontal' ,
    'mango' ,
    'music-note-slash' ,
    'music-alt-slash' ,
    'circle-radiation' ,
    'radiation-alt' ,
    'face-tongue-sweat' ,
    'globe-stand' ,
    'baseball' ,
    'baseball-ball' ,
    'circle-p' ,
    'award-simple' ,
    'jet-fighter-up' ,
    'diagram-project' ,
    'project-diagram' ,
    'pedestal' ,
    'chart-pyramid' ,
    'sidebar' ,
    'snowman-head' ,
    'frosty-head' ,
    'copy' ,
    'burger-glass' ,
    'volume-xmark' ,
    'volume-mute' ,
    'volume-times' ,
    'hand-sparkles' ,
    'bars-filter' ,
    'paintbrush-pencil' ,
    'party-bell' ,
    'user-vneck-hair' ,
    'jack-o-lantern' ,
    'grip' ,
    'grip-horizontal' ,
    'share-from-square' ,
    'share-square' ,
    'keynote' ,
    'child-combatant' ,
    'child-rifle' ,
    'gun' ,
    'square-phone' ,
    'phone-square' ,
    'plus' ,
    'add' ,
    'expand' ,
    'computer' ,
    'fort' ,
    'cloud-check' ,
    'xmark' ,
    'close' ,
    'multiply' ,
    'remove' ,
    'times' ,
    'face-smirking' ,
    'arrows-up-down-left-right' ,
    'arrows' ,
    'chalkboard-user' ,
    'chalkboard-teacher' ,
    'rhombus' ,
    'claw-marks' ,
    'peso-sign' ,
    'face-smile-tongue' ,
    'cart-circle-xmark' ,
    'building-shield' ,
    'circle-phone-flip' ,
    'phone-circle-alt' ,
    'baby' ,
    'users-line' ,
    'quote-left' ,
    'quote-left-alt' ,
    'tractor' ,
    'key-skeleton' ,
    'trash-arrow-up' ,
    'trash-restore' ,
    'arrow-down-up-lock' ,
    'arrow-down-to-bracket' ,
    'lines-leaning' ,
    'square-q' ,
    'ruler-combined' ,
    'symbols' ,
    'icons-alt' ,
    'copyright' ,
    'highlighter-line' ,
    'bracket-square' ,
    'bracket' ,
    'bracket-left' ,
    'island-tropical' ,
    'island-tree-palm' ,
    'arrow-right-from-line' ,
    'arrow-from-left' ,
    'h2' ,
    'equals' ,
    'cake-slice' ,
    'shortcake' ,
    'peanut' ,
    'wrench-simple' ,
    'blender' ,
    'teeth' ,
    'tally-2' ,
    'shekel-sign' ,
    'ils' ,
    'shekel' ,
    'sheqel' ,
    'sheqel-sign' ,
    'cars' ,
    'axe-battle' ,
    'user-hair-long' ,
    'map' ,
    'file-circle-info' ,
    'face-disappointed' ,
    'lasso-sparkles' ,
    'clock-eleven' ,
    'rocket' ,
    'siren-on' ,
    'clock-ten' ,
    'candle-holder' ,
    'video-arrow-down-left' ,
    'photo-film' ,
    'photo-video' ,
    'floppy-disk-circle-arrow-right' ,
    'save-circle-arrow-right' ,
    'folder-minus' ,
    'planet-moon' ,
    'face-eyes-xmarks' ,
    'chart-scatter' ,
    'display-arrow-down' ,
    'store' ,
    'arrow-trend-up' ,
    'plug-circle-minus' ,
    'olive-branch' ,
    'angle' ,
    'vacuum-robot' ,
    'sign-hanging' ,
    'sign' ,
    'square-divide' ,
    'signal-stream-slash' ,
    'bezier-curve' ,
    'eye-dropper-half' ,
    'store-lock' ,
    'bell-slash' ,
    'cloud-bolt-sun' ,
    'thunderstorm-sun' ,
    'camera-slash' ,
    'comment-quote' ,
    'tablet' ,
    'tablet-android' ,
    'school-flag' ,
    'message-code' ,
    'glass-half' ,
    'glass-half-empty' ,
    'glass-half-full' ,
    'fill' ,
    'message-minus' ,
    'comment-alt-minus' ,
    'angle-up' ,
    'drumstick-bite' ,
    'link-horizontal-slash' ,
    'chain-horizontal-slash' ,
    'holly-berry' ,
    'nose' ,
    'chevron-left' ,
    'bacteria' ,
    'clouds' ,
    'money-bill-simple' ,
    'hand-lizard' ,
    'table-pivot' ,
    'filter-slash' ,
    'trash-can-undo' ,
    'trash-can-arrow-turn-left' ,
    'trash-undo-alt' ,
    'notdef' ,
    'disease' ,
    'person-to-door' ,
    'turntable' ,
    'briefcase-medical' ,
    'genderless' ,
    'chevron-right' ,
    'signal-weak' ,
    'signal1' ,
    'clock-five' ,
    'retweet' ,
    'car-rear' ,
    'car-alt' ,
    'pump-soap' ,
    'computer-classic' ,
    'frame' ,
    'video-slash' ,
    'battery-quarter' ,
    'battery2' ,
    'ellipsis-stroke' ,
    'ellipsis-h-alt' ,
    'radio' ,
    'baby-carriage' ,
    'carriage-baby' ,
    'face-expressionless' ,
    'down-to-dotted-line' ,
    'cloud-music' ,
    'traffic-light' ,
    'cloud-minus' ,
    'thermometer' ,
    'shield-minus' ,
    'vr-cardboard' ,
    'car-tilt' ,
    'gauge-circle-minus' ,
    'brightness-low' ,
    'hand-middle-finger' ,
    'percent' ,
    'percentage' ,
    'truck-moving' ,
    'glass-water-droplet' ,
    'conveyor-belt' ,
    'location-check' ,
    'map-marker-check' ,
    'coin-vertical' ,
    'display' ,
    'person-sign' ,
    'face-smile' ,
    'smile' ,
    'phone-hangup' ,
    'signature-slash' ,
    'thumbtack' ,
    'thumb-tack' ,
    'wheat-slash' ,
    'trophy' ,
    'clouds-sun' ,
    'person-praying' ,
    'pray' ,
    'hammer' ,
    'face-vomit' ,
    'speakers' ,
    'tty-answer' ,
    'teletype-answer' ,
    'mug-tea-saucer' ,
    'diagram-lean-canvas' ,
    'alt' ,
    'dial' ,
    'dial-med-high' ,
    'hand-peace' ,
    'circle-trash' ,
    'trash-circle' ,
    'rotate' ,
    'sync-alt' ,
    'circle-quarters' ,
    'spinner' ,
    'tower-control' ,
    'arrow-up-triangle-square' ,
    'sort-shapes-up' ,
    'whale' ,
    'robot' ,
    'peace' ,
    'party-horn' ,
    'gears' ,
    'cogs' ,
    'sun-bright' ,
    'sun-alt' ,
    'warehouse' ,
    'lock-keyhole-open' ,
    'lock-open-alt' ,
    'square-fragile' ,
    'box-fragile' ,
    'square-wine-glass-crack' ,
    'arrow-up-right-dots' ,
    'square-n' ,
    'splotch' ,
    'face-grin-hearts' ,
    'grin-hearts' ,
    'meter' ,
    'mandolin' ,
    'dice-four' ,
    'sim-card' ,
    'transgender' ,
    'transgender-alt' ,
    'mercury' ,
    'up-from-bracket' ,
    'knife-kitchen' ,
    'border-right' ,
    'arrow-turn-down' ,
    'level-down' ,
    'spade' ,
    'card-spade' ,
    'line-columns' ,
    'arrow-right-to-line' ,
    'arrow-to-right' ,
    'person-falling-burst' ,
    'flag-pennant' ,
    'pennant' ,
    'conveyor-belt-empty' ,
    'award' ,
    'ticket-simple' ,
    'ticket-alt' ,
    'building' ,
    'angles-left' ,
    'angle-double-left' ,
    'camcorder' ,
    'video-handheld' ,
    'pancakes' ,
    'album-circle-user' ,
    'qrcode' ,
    'dice-d10' ,
    'fireplace' ,
    'browser' ,
    'pen-paintbrush' ,
    'pencil-paintbrush' ,
    'fish-cooked' ,
    'chair-office' ,
    'nesting-dolls' ,
    'clock-rotate-left' ,
    'history' ,
    'trumpet' ,
    'face-grin-beam-sweat' ,
    'grin-beam-sweat' ,
    'fire-smoke' ,
    'phone-missed' ,
    'file-export' ,
    'arrow-right-from-file' ,
    'shield' ,
    'shield-blank' ,
    'arrow-up-short-wide' ,
    'sort-amount-up-alt' ,
    'arrows-repeat-1' ,
    'repeat1-alt' ,
    'gun-slash' ,
    'avocado' ,
    'binary' ,
    'glasses-round' ,
    'glasses-alt' ,
    'phone-plus' ,
    'ditto' ,
    'person-seat' ,
    'house-medical' ,
    'golf-ball-tee' ,
    'golf-ball' ,
    'circle-chevron-left' ,
    'chevron-circle-left' ,
    'house-chimney-window' ,
    'scythe' ,
    'pen-nib' ,
    'ban-parking' ,
    'parking-circle-slash' ,
    'tent-arrow-turn-left' ,
    'face-diagonal-mouth' ,
    'diagram-cells' ,
    'cricket-bat-ball' ,
    'cricket' ,
    'tents' ,
    'wand-magic' ,
    'magic' ,
    'dog' ,
    'pen-line' ,
    'atom-simple' ,
    'atom-alt' ,
    'ampersand' ,
    'carrot' ,
    'arrow-up-from-line' ,
    'arrow-from-bottom' ,
    'moon' ,
    'pen-slash' ,
    'wine-glass-empty' ,
    'wine-glass-alt' ,
    'square-star' ,
    'cheese' ,
    'send-backward' ,
    'yin-yang' ,
    'music' ,
    'compass-slash' ,
    'clock-one' ,
    'file-music' ,
    'code-commit' ,
    'temperature-low' ,
    'person-biking' ,
    'biking' ,
    'skeleton' ,
    'circle-g' ,
    'circle-arrow-up-left' ,
    'coin-blank' ,
    'broom' ,
    'vacuum' ,
    'shield-heart' ,
    'card-heart' ,
    'lightbulb-cfl-on' ,
    'melon' ,
    'gopuram' ,
    'earth-oceania' ,
    'globe-oceania' ,
    'container-storage' ,
    'face-pouting' ,
    'square-xmark' ,
    'times-square' ,
    'xmark-square' ,
    'face-explode' ,
    'exploding-head' ,
    'hashtag' ,
    'up-right-and-down-left-from-center' ,
    'expand-alt' ,
    'oil-can' ,
    't' ,
    'transformer-bolt' ,
    'hippo' ,
    'chart-column' ,
    'cassette-vhs' ,
    'vhs' ,
    'infinity' ,
    'vial-circle-check' ,
    'chimney' ,
    'object-intersect' ,
    'person-arrow-down-to-line' ,
    'voicemail' ,
    'block-brick' ,
    'wall-brick' ,
    'fan' ,
    'bags-shopping' ,
    'paragraph-left' ,
    'paragraph-rtl' ,
    'person-walking-luggage' ,
    'caravan-simple' ,
    'caravan-alt' ,
    'turtle' ,
    'up-down' ,
    'arrows-alt-v' ,
    'cloud-moon-rain' ,
    'booth-curtain' ,
    'calendar' ,
    'box-heart' ,
    'trailer' ,
    'user-doctor-message' ,
    'user-md-chat' ,
    'bahai' ,
    'haykal' ,
    'amp-guitar' ,
    'sd-card' ,
    'volume-slash' ,
    'border-bottom' ,
    'wifi-weak' ,
    'wifi1' ,
    'dragon' ,
    'shoe-prints' ,
    'circle-plus' ,
    'plus-circle' ,
    'face-grin-tongue-wink' ,
    'grin-tongue-wink' ,
    'hand-holding' ,
    'plug-circle-exclamation' ,
    'link-slash' ,
    'chain-broken' ,
    'chain-slash' ,
    'unlink' ,
    'clone' ,
    'person-walking-arrow-loop-left' ,
    'arrow-up-z-a' ,
    'sort-alpha-up-alt' ,
    'fire-flame-curved' ,
    'fire-alt' ,
    'tornado' ,
    'file-circle-plus' ,
    'delete-right' ,
    'book-quran' ,
    'quran' ,
    'circle-quarter' ,
    'anchor' ,
    'border-all' ,
    'function' ,
    'face-angry' ,
    'angry' ,
    'people-simple' ,
    'cookie-bite' ,
    'arrow-trend-down' ,
    'rss' ,
    'feed' ,
    'face-monocle' ,
    'draw-polygon' ,
    'scale-balanced' ,
    'balance-scale' ,
    'calendar-lines' ,
    'calendar-note' ,
    'arrow-down-big-small' ,
    'sort-size-down' ,
    'gauge-simple-high' ,
    'tachometer' ,
    'tachometer-fast' ,
    'do-not-enter' ,
    'shower' ,
    'dice-d8' ,
    'desktop' ,
    'desktop-alt' ,
    'm' ,
    'grip-dots-vertical' ,
    'face-viewfinder' ,
    'soft-serve' ,
    'creemee' ,
    'h5' ,
    'hand-back-point-down' ,
    'table-list' ,
    'th-list' ,
    'comment-sms' ,
    'sms' ,
    'rectangle' ,
    'rectangle-landscape' ,
    'clipboard-list-check' ,
    'turkey' ,
    'book' ,
    'user-plus' ,
    'ice-skate' ,
    'check' ,
    'battery-three-quarters' ,
    'battery4' ,
    'tomato' ,
    'sword-laser' ,
    'house-circle-check' ,
    'buildings' ,
    'angle-left' ,
    'cart-flatbed-boxes' ,
    'dolly-flatbed-alt' ,
    'diagram-successor' ,
    'truck-arrow-right' ,
    'square-w' ,
    'arrows-split-up-and-left' ,
    'lamp' ,
    'airplay' ,
    'hand-fist' ,
    'fist-raised' ,
    'shield-quartered' ,
    'slash-forward' ,
    'location-pen' ,
    'map-marker-edit' ,
    'cloud-moon' ,
    'pot-food' ,
    'briefcase' ,
    'person-falling' ,
    'image-portrait' ,
    'portrait' ,
    'user-tag' ,
    'rug' ,
    'print-slash' ,
    'earth-europe' ,
    'globe-europe' ,
    'cart-flatbed-suitcase' ,
    'luggage-cart' ,
    'hand-back-point-ribbon' ,
    'rectangle-xmark' ,
    'rectangle-times' ,
    'times-rectangle' ,
    'window-close' ,
    'tire-rugged' ,
    'lightbulb-dollar' ,
    'cowbell' ,
    'baht-sign' ,
    'corner' ,
    'chevrons-right' ,
    'chevron-double-right' ,
    'book-open' ,
    'book-journal-whills' ,
    'journal-whills' ,
    'inhaler' ,
    'handcuffs' ,
    'snake' ,
    'triangle-exclamation' ,
    'exclamation-triangle' ,
    'warning' ,
    'note-medical' ,
    'database' ,
    'down-left' ,
    'share' ,
    'arrow-turn-right' ,
    'mail-forward' ,
    'face-thinking' ,
    'turn-down-right' ,
    'bottle-droplet' ,
    'mask-face' ,
    'hill-rockslide' ,
    'scanner-keyboard' ,
    'circle-o' ,
    'grid-horizontal' ,
    'message-dollar' ,
    'comment-alt-dollar' ,
    'right-left' ,
    'exchange-alt' ,
    'columns-3' ,
    'paper-plane' ,
    'road-circle-exclamation' ,
    'dungeon' ,
    'hand-holding-box' ,
    'input-text' ,
    'window-flip' ,
    'window-alt' ,
    'align-right' ,
    'scanner-gun' ,
    'scanner' ,
    'tire' ,
    'engine' ,
    'money-bill-1-wave' ,
    'money-bill-wave-alt' ,
    'life-ring' ,
    'hands' ,
    'sign-language' ,
    'signing' ,
    'circle-caret-right' ,
    'caret-circle-right' ,
    'wheat' ,
    'file-spreadsheet' ,
    'audio-description-slash' ,
    'calendar-day' ,
    'water-ladder' ,
    'ladder-water' ,
    'swimming-pool' ,
    'arrows-up-down' ,
    'arrows-v' ,
    'chess-pawn-piece' ,
    'chess-pawn-alt' ,
    'face-grimace' ,
    'grimace' ,
    'wheelchair-move' ,
    'wheelchair-alt' ,
    'turn-down' ,
    'level-down-alt' ,
    'square-s' ,
    'rectangle-barcode' ,
    'barcode-alt' ,
    'person-walking-arrow-right' ,
    'square-envelope' ,
    'envelope-square' ,
    'dice' ,
    'unicorn' ,
    'bowling-ball' ,
    'pompebled' ,
    'brain' ,
    'watch-smart' ,
    'book-user' ,
    'sensor-cloud' ,
    'sensor-smoke' ,
    'clapperboard-play' ,
    'bandage' ,
    'band-aid' ,
    'calendar-minus' ,
    'circle-xmark' ,
    'times-circle' ,
    'xmark-circle' ,
    'circle-4' ,
    'gifts' ,
    'album-collection' ,
    'hotel' ,
    'earth-asia' ,
    'globe-asia' ,
    'id-card-clip' ,
    'id-card-alt' ,
    'magnifying-glass-plus' ,
    'search-plus' ,
    'thumbs-up' ,
    'cloud-showers' ,
    'user-clock' ,
    'onion' ,
    'clock-twelve-thirty' ,
    'arrow-down-to-dotted-line' ,
    'hand-dots' ,
    'allergies' ,
    'file-invoice' ,
    'window-minimize' ,
    'rectangle-wide' ,
    'comment-arrow-up' ,
    'garlic' ,
    'mug-saucer' ,
    'coffee' ,
    'brush' ,
    'tree-decorated' ,
    'mask' ,
    'calendar-heart' ,
    'magnifying-glass-minus' ,
    'search-minus' ,
    'flower' ,
    'ruler-vertical' ,
    'user-large' ,
    'user-alt' ,
    'starship-freighter' ,
    'train-tram' ,
    'bridge-suspension' ,
    'trash-check' ,
    'user-nurse' ,
    'boombox' ,
    'syringe' ,
    'cloud-sun' ,
    'shield-exclamation' ,
    'stopwatch-20' ,
    'square-full' ,
    'grip-dots' ,
    'comment-exclamation' ,
    'pen-swirl' ,
    'falafel' ,
    'circle-2' ,
    'magnet' ,
    'jar' ,
    'gramophone' ,
    'dice-d12' ,
    'note-sticky' ,
    'sticky-note' ,
    'down' ,
    'arrow-alt-down' ,
    'hundred-points' ,
    '100' ,
    'paperclip-vertical' ,
    'wind-warning' ,
    'wind-circle-exclamation' ,
    'location-pin-slash' ,
    'map-marker-slash' ,
    'face-sad-sweat' ,
    'bug-slash' ,
    'cupcake' ,
    'light-switch-off' ,
    'toggle-large-off' ,
    'pen-fancy-slash' ,
    'truck-container' ,
    'boot' ,
    'arrow-up-from-water-pump' ,
    'file-check' ,
    'bone' ,
    'cards-blank' ,
    'circle-3' ,
    'bench-tree' ,
    'keyboard-brightness-low' ,
    'ski-boot-ski' ,
    'brain-circuit' ,
    'user-injured' ,
    'block-brick-fire' ,
    'firewall' ,
    'face-sad-tear' ,
    'sad-tear' ,
    'plane' ,
    'tent-arrows-down' ,
    'exclamation' ,
    'arrows-spin' ,
    'face-smile-relaxed' ,
    'comment-xmark' ,
    'comment-times' ,
    'print' ,
    'turkish-lira-sign' ,
    'try' ,
    'turkish-lira' ,
    'face-nose-steam' ,
    'circle-waveform-lines' ,
    'waveform-circle' ,
    'dollar-sign' ,
    'dollar' ,
    'usd' ,
    'ferris-wheel' ,
    'computer-speaker' ,
    'skull-cow' ,
    'x' ,
    'magnifying-glass-dollar' ,
    'search-dollar' ,
    'users-gear' ,
    'users-cog' ,
    'person-military-pointing' ,
    'building-columns' ,
    'bank' ,
    'institution' ,
    'museum' ,
    'university' ,
    'circle-t' ,
    'sack' ,
    'grid-2' ,
    'camera-cctv' ,
    'cctv' ,
    'umbrella' ,
    'trowel' ,
    'horizontal-rule' ,
    'bed-front' ,
    'bed-alt' ,
    'd' ,
    'stapler' ,
    'masks-theater' ,
    'theater-masks' ,
    'kip-sign' ,
    'face-woozy' ,
    'cloud-question' ,
    'pineapple' ,
    'hand-point-left' ,
    'gallery-thumbnails' ,
    'circle-j' ,
    'eyes' ,
    'handshake-simple' ,
    'handshake-alt' ,
    'page-caret-up' ,
    'file-caret-up' ,
    'jet-fighter' ,
    'fighter-jet' ,
    'comet' ,
    'square-share-nodes' ,
    'share-alt-square' ,
    'shield-keyhole' ,
    'barcode' ,
    'plus-minus' ,
    'square-sliders-vertical' ,
    'sliders-v-square' ,
    'video' ,
    'video-camera' ,
    'message-middle' ,
    'comment-middle-alt' ,
    'graduation-cap' ,
    'mortar-board' ,
    'hand-holding-medical' ,
    'person-circle-check' ,
    'square-z' ,
    'message-text' ,
    'comment-alt-text' ,
    'turn-up' ,
    'level-up-alt' ,
    '0' ,
    '1' ,
    '2' ,
    '3' ,
    '4' ,
    '5' ,
    '6' ,
    '7' ,
    '8' ,
    '9' ,
    'fill-drip' ,
    'arrows-to-circle' ,
    'circle-chevron-right' ,
    'chevron-circle-right' ,
    'wagon-covered' ,
    'line-height' ,
    'bagel' ,
    'transporter-7' ,
    'at' ,
    'rectangles-mixed' ,
    'phone-arrow-up-right' ,
    'phone-arrow-up' ,
    'phone-outgoing' ,
    'trash-can' ,
    'trash-alt' ,
    'circle-l' ,
    'head-side-goggles' ,
    'head-vr' ,
    'text-height' ,
    'user-xmark' ,
    'user-times' ,
    'face-hand-yawn' ,
    'gauge-simple-min' ,
    'tachometer-slowest' ,
    'stethoscope' ,
    'coffin' ,
    'message' ,
    'comment-alt' ,
    'salad' ,
    'bowl-salad' ,
    'info' ,
    'robot-astromech' ,
    'ring-diamond' ,
    'fondue-pot' ,
    'theta' ,
    'face-hand-peeking' ,
    'square-user' ,
    'down-left-and-up-right-to-center' ,
    'compress-alt' ,
    'explosion' ,
    'file-lines' ,
    'file-alt' ,
    'file-text' ,
    'wave-square' ,
    'ring' ,
    'building-un' ,
    'dice-three' ,
    'tire-pressure-warning' ,
    'wifi-fair' ,
    'wifi2' ,
    'calendar-days' ,
    'calendar-alt' ,
    'mp3-player' ,
    'anchor-circle-check' ,
    'tally-4' ,
    'rectangle-history' ,
    'building-circle-arrow-right' ,
    'volleyball' ,
    'volleyball-ball' ,
    'sun-haze' ,
    'text-size' ,
    'ufo' ,
    'fork' ,
    'utensil-fork' ,
    'arrows-up-to-line' ,
    'mobile-signal' ,
    'barcode-scan' ,
    'sort-down' ,
    'sort-desc' ,
    'folder-arrow-down' ,
    'folder-download' ,
    'circle-minus' ,
    'minus-circle' ,
    'face-icicles' ,
    'shovel' ,
    'door-open' ,
    'films' ,
    'right-from-bracket' ,
    'sign-out-alt' ,
    'face-glasses' ,
    'nfc' ,
    'atom' ,
    'soap' ,
    'icons' ,
    'heart-music-camera-bolt' ,
    'microphone-lines-slash' ,
    'microphone-alt-slash' ,
    'closed-captioning-slash' ,
    'calculator-simple' ,
    'calculator-alt' ,
    'bridge-circle-check' ,
    'sliders-up' ,
    'sliders-v' ,
    'location-minus' ,
    'map-marker-minus' ,
    'pump-medical' ,
    'fingerprint' ,
    'ski-boot' ,
    'standard-definition' ,
    'rectangle-sd' ,
    'h1' ,
    'hand-point-right' ,
    'magnifying-glass-location' ,
    'search-location' ,
    'message-bot' ,
    'forward-step' ,
    'step-forward' ,
    'face-smile-beam' ,
    'smile-beam' ,
    'light-ceiling' ,
    'message-exclamation' ,
    'comment-alt-exclamation' ,
    'bowl-scoop' ,
    'bowl-shaved-ice' ,
    'square-x' ,
    'utility-pole-double' ,
    'flag-checkered' ,
    'chevrons-up' ,
    'chevron-double-up' ,
    'football' ,
    'football-ball' ,
    'user-vneck' ,
    'school-circle-exclamation' ,
    'crop' ,
    'angles-down' ,
    'angle-double-down' ,
    'users-rectangle' ,
    'people-roof' ,
    'square-arrow-right' ,
    'arrow-square-right' ,
    'location-plus' ,
    'map-marker-plus' ,
    'lightbulb-exclamation-on' ,
    'people-line' ,
    'beer-mug-empty' ,
    'beer' ,
    'crate-empty' ,
    'diagram-predecessor' ,
    'transporter' ,
    'calendar-circle-user' ,
    'arrow-up-long' ,
    'long-arrow-up' ,
    'person-carry-box' ,
    'person-carry' ,
    'fire-flame-simple' ,
    'burn' ,
    'person' ,
    'male' ,
    'laptop' ,
    'file-csv' ,
    'menorah' ,
    'union' ,
    'chevrons-left' ,
    'chevron-double-left' ,
    'circle-heart' ,
    'heart-circle' ,
    'truck-plane' ,
    'record-vinyl' ,
    'bring-forward' ,
    'square-p' ,
    'face-grin-stars' ,
    'grin-stars' ,
    'sigma' ,
    'camera-movie' ,
    'bong' ,
    'clarinet' ,
    'truck-flatbed' ,
    'spaghetti-monster-flying' ,
    'pastafarianism' ,
    'arrow-down-up-across-line' ,
    'leaf-heart' ,
    'house-building' ,
    'cheese-swiss' ,
    'spoon' ,
    'utensil-spoon' ,
    'jar-wheat' ,
    'envelopes-bulk' ,
    'mail-bulk' ,
    'file-circle-exclamation' ,
    'bow-arrow' ,
    'cart-xmark' ,
    'hexagon-xmark' ,
    'times-hexagon' ,
    'xmark-hexagon' ,
    'circle-h' ,
    'hospital-symbol' ,
    'merge' ,
    'pager' ,
    'cart-minus' ,
    'address-book' ,
    'contact-book' ,
    'pan-frying' ,
    'grid' ,
    'grid3' ,
    'football-helmet' ,
    'hand-love' ,
    'trees' ,
    'strikethrough' ,
    'page' ,
    'k' ,
    'diagram-previous' ,
    'gauge-min' ,
    'tachometer-alt-slowest' ,
    'folder-grid' ,
    'eggplant' ,
    'ram' ,
    'landmark-flag' ,
    'lips' ,
    'pencil' ,
    'pencil-alt' ,
    'backward' ,
    'caret-right' ,
    'comments' ,
    'paste' ,
    'file-clipboard' ,
    'desktop-arrow-down' ,
    'code-pull-request' ,
    'pumpkin' ,
    'clipboard-list' ,
    'pen-field' ,
    'blueberries' ,
    'truck-ramp-box' ,
    'truck-loading' ,
    'note' ,
    'arrow-down-to-square' ,
    'user-check' ,
    'cloud-xmark' ,
    'vial-virus' ,
    'book-blank' ,
    'book-alt' ,
    'golf-flag-hole' ,
    'message-arrow-down' ,
    'comment-alt-arrow-down' ,
    'face-unamused' ,
    'sheet-plastic' ,
    'circle-9' ,
    'blog' ,
    'user-ninja' ,
    'pencil-slash' ,
    'bowling-pins' ,
    'person-arrow-up-from-line' ,
    'down-right' ,
    'scroll-torah' ,
    'torah' ,
    'blinds-open' ,
    'fence' ,
    'up' ,
    'arrow-alt-up' ,
    'broom-ball' ,
    'quidditch' ,
    'quidditch-broom-ball' ,
    'drumstick' ,
    'square-v' ,
    'face-awesome' ,
    'gave-dandy' ,
    'dial-off' ,
    'toggle-off' ,
    'face-smile-horns' ,
    'box-archive' ,
    'archive' ,
    'grapes' ,
    'person-drowning' ,
    'dial-max' ,
    'circle-m' ,
    'calendar-image' ,
    'circle-caret-down' ,
    'caret-circle-down' ,
    'arrow-down-9-1' ,
    'sort-numeric-desc' ,
    'sort-numeric-down-alt' ,
    'face-grin-tongue-squint' ,
    'grin-tongue-squint' ,
    'shish-kebab' ,
    'spray-can' ,
    'alarm-snooze' ,
    'scarecrow' ,
    'truck-monster' ,
    'gift-card' ,
    'w' ,
    'code-pull-request-draft' ,
    'square-b' ,
    'elephant' ,
    'earth-africa' ,
    'globe-africa' ,
    'rainbow' ,
    'circle-notch' ,
    'tablet-screen-button' ,
    'tablet-alt' ,
    'paw' ,
    'message-question' ,
    'cloud' ,
    'trowel-bricks' ,
    'square-3' ,
    'face-flushed' ,
    'flushed' ,
    'hospital-user' ,
    'microwave' ,
    'tent-arrow-left-right' ,
    'cart-circle-arrow-up' ,
    'trash-clock' ,
    'gavel' ,
    'legal' ,
    'sprinkler-ceiling' ,
    'browsers' ,
    'trillium' ,
    'music-slash' ,
    'truck-ramp' ,
    'binoculars' ,
    'microphone-slash' ,
    'box-tissue' ,
    'circle-c' ,
    'star-christmas' ,
    'chart-bullet' ,
    'motorcycle' ,
    'tree-christmas' ,
    'tire-flat' ,
    'sunglasses' ,
    'badge' ,
    'message-pen' ,
    'comment-alt-edit' ,
    'message-edit' ,
    'bell-concierge' ,
    'concierge-bell' ,
    'pen-ruler' ,
    'pencil-ruler' ,
    'chess-rook-piece' ,
    'chess-rook-alt' ,
    'square-root' ,
    'album-collection-circle-plus' ,
    'people-arrows' ,
    'people-arrows-left-right' ,
    'face-angry-horns' ,
    'mars-and-venus-burst' ,
    'tombstone' ,
    'square-caret-right' ,
    'caret-square-right' ,
    'scissors' ,
    'cut' ,
    'list-music' ,
    'sun-plant-wilt' ,
    'toilets-portable' ,
    'hockey-puck' ,
    'mustache' ,
    'hyphen' ,
    'table' ,
    'user-chef' ,
    'message-image' ,
    'comment-alt-image' ,
    'users-medical' ,
    'sensor-triangle-exclamation' ,
    'sensor-alert' ,
    'magnifying-glass-arrow-right' ,
    'tachograph-digital' ,
    'digital-tachograph' ,
    'face-mask' ,
    'pickleball' ,
    'star-sharp-half' ,
    'users-slash' ,
    'clover' ,
    'meat' ,
    'reply' ,
    'mail-reply' ,
    'star-and-crescent' ,
    'empty-set' ,
    'house-fire' ,
    'square-minus' ,
    'minus-square' ,
    'helicopter' ,
    'bird' ,
    'compass' ,
    'square-caret-down' ,
    'caret-square-down' ,
    'heart-half-stroke' ,
    'heart-half-alt' ,
    'file-circle-question' ,
    'laptop-code' ,
    'joystick' ,
    'grill-fire' ,
    'rectangle-vertical-history' ,
    'swatchbook' ,
    'prescription-bottle' ,
    'bars' ,
    'navicon' ,
    'keyboard-left' ,
    'people-group' ,
    'hourglass-end' ,
    'hourglass3' ,
    'heart-crack' ,
    'heart-broken' ,
    'face-beam-hand-over-mouth' ,
    'droplet-percent' ,
    'humidity' ,
    'square-up-right' ,
    'external-link-square-alt' ,
    'face-kiss-beam' ,
    'kiss-beam' ,
    'corn' ,
    'roller-coaster' ,
    'photo-film-music' ,
    'radar' ,
    'sickle' ,
    'film' ,
    'coconut' ,
    'ruler-horizontal' ,
    'shield-cross' ,
    'cassette-tape' ,
    'square-terminal' ,
    'people-robbery' ,
    'lightbulb' ,
    'caret-left' ,
    'comment-middle' ,
    'trash-can-list' ,
    'block' ,
    'circle-exclamation' ,
    'exclamation-circle' ,
    'school-circle-xmark' ,
    'arrow-right-from-bracket' ,
    'sign-out' ,
    'face-frown-slight' ,
    'circle-chevron-down' ,
    'chevron-circle-down' ,
    'sidebar-flip' ,
    'unlock-keyhole' ,
    'unlock-alt' ,
    'temperature-list' ,
    'cloud-showers-heavy' ,
    'headphones-simple' ,
    'headphones-alt' ,
    'sitemap' ,
    'pipe-section' ,
    'space-station-moon-construction' ,
    'space-station-moon-alt' ,
    'circle-dollar-to-slot' ,
    'donate' ,
    'memory' ,
    'face-sleeping' ,
    'road-spikes' ,
    'fire-burner' ,
    'squirrel' ,
    'arrow-up-to-line' ,
    'arrow-to-top' ,
    'flag' ,
    'face-cowboy-hat' ,
    'hanukiah' ,
    'chart-scatter-3d' ,
    'square-code' ,
    'feather' ,
    'volume-low' ,
    'volume-down' ,
    'xmark-to-slot' ,
    'times-to-slot' ,
    'vote-nay' ,
    'box-taped' ,
    'box-alt' ,
    'comment-slash' ,
    'swords' ,
    'cloud-sun-rain' ,
    'album' ,
    'circle-n' ,
    'compress' ,
    'wheat-awn' ,
    'wheat-alt' ,
    'ankh' ,
    'hands-holding-child' ,
    'asterisk' ,
    'key-skeleton-left-right' ,
    'comment-lines' ,
    'luchador-mask' ,
    'luchador' ,
    'mask-luchador' ,
    'square-check' ,
    'check-square' ,
    'shredder' ,
    'book-open-cover' ,
    'book-open-alt' ,
    'sandwich' ,
    'peseta-sign' ,
    'square-parking-slash' ,
    'parking-slash' ,
    'train-tunnel' ,
    'heading' ,
    'header' ,
    'ghost' ,
    'face-anguished' ,
    'hockey-sticks' ,
    'abacus' ,
    'film-simple' ,
    'film-alt' ,
    'list' ,
    'list-squares' ,
    'tree-palm' ,
    'square-phone-flip' ,
    'phone-square-alt' ,
    'cart-plus' ,
    'gamepad' ,
    'border-center-v' ,
    'circle-dot' ,
    'dot-circle' ,
    'clipboard-medical' ,
    'face-dizzy' ,
    'dizzy' ,
    'egg' ,
    'up-to-line' ,
    'arrow-alt-to-top' ,
    'house-medical-circle-xmark' ,
    'watch-fitness' ,
    'clock-nine-thirty' ,
    'campground' ,
    'folder-plus' ,
    'jug' ,
    'futbol' ,
    'futbol-ball' ,
    'soccer-ball' ,
    'snow-blowing' ,
    'paintbrush' ,
    'paint-brush' ,
    'lock' ,
    'arrow-down-from-line' ,
    'arrow-from-top' ,
    'gas-pump' ,
    'signal-bars-slash' ,
    'signal-alt-slash' ,
    'monkey' ,
    'rectangle-pro' ,
    'pro' ,
    'house-night' ,
    'hot-tub-person' ,
    'hot-tub' ,
    'blanket' ,
    'map-location' ,
    'map-marked' ,
    'house-flood-water' ,
    'comments-question-check' ,
    'tree' ,
    'arrows-cross' ,
    'backpack' ,
    'square-small' ,
    'folder-arrow-up' ,
    'folder-upload' ,
    'bridge-lock' ,
    'crosshairs-simple' ,
    'sack-dollar' ,
    'pen-to-square' ,
    'edit' ,
    'square-sliders' ,
    'sliders-h-square' ,
    'car-side' ,
    'message-middle-top' ,
    'comment-middle-top-alt' ,
    'lightbulb-on' ,
    'knife' ,
    'utensil-knife' ,
    'share-nodes' ,
    'share-alt' ,
    'wave-sine' ,
    'heart-circle-minus' ,
    'circle-w' ,
    'circle-calendar' ,
    'calendar-circle' ,
    'hourglass-half' ,
    'hourglass2' ,
    'microscope' ,
    'sunset' ,
    'sink' ,
    'calendar-exclamation' ,
    'truck-container-empty' ,
    'hand-heart' ,
    'bag-shopping' ,
    'shopping-bag' ,
    'arrow-down-z-a' ,
    'sort-alpha-desc' ,
    'sort-alpha-down-alt' ,
    'mitten' ,
    'reply-clock' ,
    'reply-time' ,
    'person-rays' ,
    'right' ,
    'arrow-alt-right' ,
    'circle-f' ,
    'users' ,
    'face-pleading' ,
    'eye-slash' ,
    'flask-vial' ,
    'police-box' ,
    'cucumber' ,
    'head-side-brain' ,
    'hand' ,
    'hand-paper' ,
    'person-biking-mountain' ,
    'biking-mountain' ,
    'utensils-slash' ,
    'print-magnifying-glass' ,
    'print-search' ,
    'folder-bookmark' ,
    'om' ,
    'pi' ,
    'flask-round-potion' ,
    'flask-potion' ,
    'face-shush' ,
    'worm' ,
    'house-circle-xmark' ,
    'plug' ,
    'calendar-circle-exclamation' ,
    'square-i' ,
    'chevron-up' ,
    'face-saluting' ,
    'gauge-simple-low' ,
    'tachometer-slow' ,
    'face-persevering' ,
    'circle-camera' ,
    'camera-circle' ,
    'hand-spock' ,
    'spider-web' ,
    'circle-microphone' ,
    'microphone-circle' ,
    'book-arrow-up' ,
    'popsicle' ,
    'command' ,
    'blinds' ,
    'stopwatch' ,
    'saxophone' ,
    'square-2' ,
    'field-hockey-stick-ball' ,
    'field-hockey' ,
    'arrow-up-square-triangle' ,
    'sort-shapes-up-alt' ,
    'face-scream' ,
    'square-m' ,
    'camera-web' ,
    'webcam' ,
    'comment-arrow-down' ,
    'lightbulb-cfl' ,
    'window-frame-open' ,
    'face-kiss' ,
    'kiss' ,
    'bridge-circle-xmark' ,
    'period' ,
    'face-grin-tongue' ,
    'grin-tongue' ,
    'up-to-dotted-line' ,
    'thought-bubble' ,
    'raygun' ,
    'flute' ,
    'acorn' ,
    'video-arrow-up-right' ,
    'grate-droplet' ,
    'seal-exclamation' ,
    'chess-bishop' ,
    'message-sms' ,
    'coffee-beans' ,
    'hat-witch' ,
    'face-grin-wink' ,
    'grin-wink' ,
    'clock-three-thirty' ,
    'ear-deaf' ,
    'deaf' ,
    'deafness' ,
    'hard-of-hearing' ,
    'alarm-clock' ,
    'eclipse' ,
    'face-relieved' ,
    'road-circle-check' ,
    'dice-five' ,
    'octagon-minus' ,
    'minus-octagon' ,
    'square-rss' ,
    'rss-square' ,
    'face-zany' ,
    'tricycle' ,
    'land-mine-on' ,
    'square-arrow-up-left' ,
    'i-cursor' ,
    'salt-shaker' ,
    'stamp' ,
    'file-plus' ,
    'draw-square' ,
    'toilet-paper-under-slash' ,
    'toilet-paper-reverse-slash' ,
    'stairs' ,
    'drone-front' ,
    'drone-alt' ,
    'glass-empty' ,
    'dial-high' ,
    'user-helmet-safety' ,
    'user-construction' ,
    'user-hard-hat' ,
    'i' ,
    'hryvnia-sign' ,
    'hryvnia' ,
    'arrow-down-left-and-arrow-up-right-to-center' ,
    'pills' ,
    'face-grin-wide' ,
    'grin-alt' ,
    'tooth' ,
    'basketball-hoop' ,
    'objects-align-bottom' ,
    'v' ,
    'sparkles' ,
    'squid' ,
    'leafy-green' ,
    'circle-arrow-up-right' ,
    'calendars' ,
    'bangladeshi-taka-sign' ,
    'bicycle' ,
    'hammer-war' ,
    'circle-d' ,
    'spider-black-widow' ,
    'staff-snake' ,
    'rod-asclepius' ,
    'rod-snake' ,
    'staff-aesculapius' ,
    'pear' ,
    'head-side-cough-slash' ,
    'triangle' ,
    'apartment' ,
    'truck-medical' ,
    'ambulance' ,
    'pepper' ,
    'piano' ,
    'gun-squirt' ,
    'wheat-awn-circle-exclamation' ,
    'snowman' ,
    'user-alien' ,
    'shield-check' ,
    'mortar-pestle' ,
    'road-barrier' ,
    'chart-candlestick' ,
    'briefcase-blank' ,
    'school' ,
    'igloo' ,
    'bracket-round' ,
    'parenthesis' ,
    'joint' ,
    'horse-saddle' ,
    'mug-marshmallows' ,
    'filters' ,
    'bell-on' ,
    'angle-right' ,
    'dial-med' ,
    'horse' ,
    'q' ,
    'monitor-waveform' ,
    'monitor-heart-rate' ,
    'link-simple' ,
    'whistle' ,
    'g' ,
    'wine-glass-crack' ,
    'fragile' ,
    'slot-machine' ,
    'notes-medical' ,
    'car-wash' ,
    'escalator' ,
    'comment-image' ,
    'temperature-half' ,
    'temperature2' ,
    'thermometer2' ,
    'thermometer-half' ,
    'dong-sign' ,
    'donut' ,
    'doughnut' ,
    'capsules' ,
    'poo-storm' ,
    'poo-bolt' ,
    'tally-1' ,
    'face-frown-open' ,
    'frown-open' ,
    'square-dashed' ,
    'square-j' ,
    'hand-point-up' ,
    'money-bill' ,
    'arrow-up-big-small' ,
    'sort-size-up' ,
    'barcode-read' ,
    'baguette' ,
    'bowl-soft-serve' ,
    'face-holding-back-tears' ,
    'square-up' ,
    'arrow-alt-square-up' ,
    'train-subway-tunnel' ,
    'subway-tunnel' ,
    'square-exclamation' ,
    'exclamation-square' ,
    'semicolon' ,
    'bookmark' ,
    'fan-table' ,
    'align-justify' ,
    'battery-low' ,
    'battery1' ,
    'credit-card-front' ,
    'brain-arrow-curved-right' ,
    'mind-share' ,
    'umbrella-beach' ,
    'helmet-un' ,
    'location-smile' ,
    'map-marker-smile' ,
    'arrow-left-to-line' ,
    'arrow-to-left' ,
    'bullseye' ,
    'sushi' ,
    'nigiri' ,
    'message-captions' ,
    'comment-alt-captions' ,
    'trash-list' ,
    'bacon' ,
    'option' ,
    'hand-point-down' ,
    'arrow-up-from-bracket' ,
    'trash-plus' ,
    'objects-align-top' ,
    'folder' ,
    'folder-blank' ,
    'face-anxious-sweat' ,
    'credit-card-blank' ,
    'file-waveform' ,
    'file-medical-alt' ,
    'microchip-ai' ,
    'mug' ,
    'plane-up-slash' ,
    'radiation' ,
    'pen-circle' ,
    'chart-simple' ,
    'crutches' ,
    'circle-parking' ,
    'parking-circle' ,
    'mars-stroke' ,
    'leaf-oak' ,
    'square-bolt' ,
    'vial' ,
    'gauge' ,
    'dashboard' ,
    'gauge-med' ,
    'tachometer-alt-average' ,
    'wand-magic-sparkles' ,
    'magic-wand-sparkles' ,
    'lambda' ,
    'e' ,
    'pizza' ,
    'bowl-chopsticks-noodles' ,
    'h3' ,
    'pen-clip' ,
    'pen-alt' ,
    'bridge-circle-exclamation' ,
    'badge-percent' ,
    'user' ,
    'sensor' ,
    'comma' ,
    'school-circle-check' ,
    'toilet-paper-under' ,
    'toilet-paper-reverse' ,
    'light-emergency' ,
    'arrow-down-to-arc' ,
    'dumpster' ,
    'van-shuttle' ,
    'shuttle-van' ,
    'building-user' ,
    'light-switch' ,
    'square-caret-left' ,
    'caret-square-left' ,
    'highlighter' ,
    'wave-pulse' ,
    'heart-rate' ,
    'key' ,
    'hat-santa' ,
    'tamale' ,
    'box-check' ,
    'bullhorn' ,
    'steak' ,
    'location-crosshairs-slash' ,
    'location-slash' ,
    'person-dolly' ,
    'globe' ,
    'synagogue' ,
    'file-chart-column' ,
    'file-chart-line' ,
    'person-half-dress' ,
    'folder-image' ,
    'calendar-pen' ,
    'calendar-edit' ,
    'road-bridge' ,
    'face-smile-tear' ,
    'message-plus' ,
    'comment-alt-plus' ,
    'location-arrow' ,
    'c' ,
    'tablet-button' ,
    'rectangle-history-circle-user' ,
    'building-lock' ,
    'chart-line-up' ,
    'mailbox' ,
    'truck-bolt' ,
    'pizza-slice' ,
    'money-bill-wave' ,
    'chart-area' ,
    'area-chart' ,
    'house-flag' ,
    'person-circle-minus' ,
    'scalpel' ,
    'ban' ,
    'cancel' ,
    'bell-exclamation' ,
    'circle-bookmark' ,
    'bookmark-circle' ,
    'egg-fried' ,
    'face-weary' ,
    'uniform-martial-arts' ,
    'camera-rotate' ,
    'sun-dust' ,
    'comment-text' ,
    'spray-can-sparkles' ,
    'air-freshener' ,
    'signal-bars' ,
    'signal-alt' ,
    'signal-alt4' ,
    'signal-bars-strong' ,
    'diamond-exclamation' ,
    'star' ,
    'dial-min' ,
    'repeat' ,
    'cross' ,
    'page-caret-down' ,
    'file-caret-down' ,
    'box' ,
    'venus-mars' ,
    'clock-seven-thirty' ,
    'arrow-pointer' ,
    'mouse-pointer' ,
    'clock-four-thirty' ,
    'signal-bars-good' ,
    'signal-alt3' ,
    'cactus' ,
    'maximize' ,
    'expand-arrows-alt' ,
    'charging-station' ,
    'shapes' ,
    'triangle-circle-square' ,
    'plane-tail' ,
    'gauge-simple-max' ,
    'tachometer-fastest' ,
    'circle-u' ,
    'shield-slash' ,
    'square-phone-hangup' ,
    'phone-square-down' ,
    'arrow-up-left' ,
    'transporter-1' ,
    'peanuts' ,
    'shuffle' ,
    'random' ,
    'person-running' ,
    'running' ,
    'mobile-retro' ,
    'grip-lines-vertical' ,
    'arrow-up-from-square' ,
    'file-dashed-line' ,
    'page-break' ,
    'bracket-curly-right' ,
    'spider' ,
    'clock-three' ,
    'hands-bound' ,
    'scalpel-line-dashed' ,
    'scalpel-path' ,
    'file-invoice-dollar' ,
    'pipe-smoking' ,
    'face-astonished' ,
    'window' ,
    'plane-circle-exclamation' ,
    'ear' ,
    'file-lock' ,
    'diagram-venn' ,
    'x-ray' ,
    'goal-net' ,
    'coffin-cross' ,
    'spell-check' ,
    'location-xmark' ,
    'map-marker-times' ,
    'map-marker-xmark' ,
    'lasso' ,
    'slash' ,
    'person-to-portal' ,
    'portal-enter' ,
    'calendar-star' ,
    'computer-mouse' ,
    'mouse' ,
    'arrow-right-to-bracket' ,
    'sign-in' ,
    'pegasus' ,
    'files-medical' ,
    'nfc-lock' ,
    'person-ski-lift' ,
    'ski-lift' ,
    'square-6' ,
    'shop-slash' ,
    'store-alt-slash' ,
    'wind-turbine' ,
    'sliders-simple' ,
    'badge-sheriff' ,
    'server' ,
    'virus-covid-slash' ,
    'intersection' ,
    'shop-lock' ,
    'family' ,
    'hourglass-start' ,
    'hourglass1' ,
    'user-hair-buns' ,
    'blender-phone' ,
    'hourglass-clock' ,
    'person-seat-reclined' ,
    'paper-plane-top' ,
    'paper-plane-alt' ,
    'send' ,
    'message-arrow-up' ,
    'comment-alt-arrow-up' ,
    'lightbulb-exclamation' ,
    'layer-minus' ,
    'layer-group-minus' ,
    'circle-e' ,
    'building-wheat' ,
    'gauge-max' ,
    'tachometer-alt-fastest' ,
    'person-breastfeeding' ,
    'apostrophe' ,
    'fire-hydrant' ,
    'right-to-bracket' ,
    'sign-in-alt' ,
    'video-plus' ,
    'square-right' ,
    'arrow-alt-square-right' ,
    'comment-smile' ,
    'venus' ,
    'passport' ,
    'inbox-in' ,
    'inbox-arrow-down' ,
    'heart-pulse' ,
    'heartbeat' ,
    'circle-8' ,
    'clouds-moon' ,
    'clock-ten-thirty' ,
    'people-carry-box' ,
    'people-carry' ,
    'folder-user' ,
    'trash-can-xmark' ,
    'temperature-high' ,
    'microchip' ,
    'left-long-to-line' ,
    'crown' ,
    'weight-hanging' ,
    'xmarks-lines' ,
    'file-prescription' ,
    'calendar-range' ,
    'flower-daffodil' ,
    'hand-back-point-up' ,
    'weight-scale' ,
    'weight' ,
    'star-exclamation' ,
    'books' ,
    'user-group' ,
    'user-friends' ,
    'arrow-up-a-z' ,
    'sort-alpha-up' ,
    'layer-plus' ,
    'layer-group-plus' ,
    'play-pause' ,
    'block-question' ,
    'snooze' ,
    'zzz' ,
    'scanner-image' ,
    'tv-retro' ,
    'square-t' ,
    'farm' ,
    'barn-silo' ,
    'chess-knight' ,
    'bars-sort' ,
    'pallet-boxes' ,
    'palette-boxes' ,
    'pallet-alt' ,
    'face-laugh-squint' ,
    'laugh-squint' ,
    'code-simple' ,
    'bolt-slash' ,
    'panel-fire' ,
    'binary-circle-check' ,
    'comment-minus' ,
    'burrito' ,
    'violin' ,
    'objects-column' ,
    'square-chevron-down' ,
    'chevron-square-down' ,
    'comment-plus' ,
    'triangle-instrument' ,
    'triangle-music' ,
    'wheelchair' ,
    'user-pilot-tie' ,
    'piano-keyboard' ,
    'bed-empty' ,
    'circle-arrow-up' ,
    'arrow-circle-up' ,
    'toggle-on' ,
    'rectangle-vertical' ,
    'rectangle-portrait' ,
    'person-walking' ,
    'walking' ,
    'l' ,
    'signal-stream' ,
    'down-to-bracket' ,
    'circle-z' ,
    'stars' ,
    'fire' ,
    'bed-pulse' ,
    'procedures' ,
    'house-day' ,
    'shuttle-space' ,
    'space-shuttle' ,
    'shirt-long-sleeve' ,
    'chart-pie-simple' ,
    'chart-pie-alt' ,
    'face-laugh' ,
    'laugh' ,
    'folder-open' ,
    'album-collection-circle-user' ,
    'candy' ,
    'bowl-hot' ,
    'soup' ,
    'flatbread' ,
    'heart-circle-plus' ,
    'code-fork' ,
    'city' ,
    'signal-bars-weak' ,
    'signal-alt1' ,
    'microphone-lines' ,
    'microphone-alt' ,
    'clock-twelve' ,
    'pepper-hot' ,
    'citrus-slice' ,
    'sheep' ,
    'unlock' ,
    'colon-sign' ,
    'headset' ,
    'badger-honey' ,
    'h4' ,
    'store-slash' ,
    'road-circle-xmark' ,
    'signal-slash' ,
    'user-minus' ,
    'mars-stroke-up' ,
    'mars-stroke-v' ,
    'champagne-glasses' ,
    'glass-cheers' ,
    'taco' ,
    'hexagon-plus' ,
    'plus-hexagon' ,
    'clipboard' ,
    'house-circle-exclamation' ,
    'file-arrow-up' ,
    'file-upload' ,
    'wifi' ,
    'wifi3' ,
    'wifi-strong' ,
    'messages' ,
    'comments-alt' ,
    'bath' ,
    'bathtub' ,
    'umbrella-simple' ,
    'umbrella-alt' ,
    'rectangle-history-circle-plus' ,
    'underline' ,
    'prescription-bottle-pill' ,
    'user-pen' ,
    'user-edit' ,
    'binary-slash' ,
    'square-o' ,
    'signature' ,
    'stroopwafel' ,
    'bold' ,
    'anchor-lock' ,
    'building-ngo' ,
    'transporter-3' ,
    'engine-warning' ,
    'engine-exclamation' ,
    'circle-down-right' ,
    'square-k' ,
    'manat-sign' ,
    'money-check-pen' ,
    'money-check-edit' ,
    'not-equal' ,
    'border-top-left' ,
    'border-style' ,
    'map-location-dot' ,
    'map-marked-alt' ,
    'tilde' ,
    'jedi' ,
    'square-poll-vertical' ,
    'poll' ,
    'arrow-down-square-triangle' ,
    'sort-shapes-down-alt' ,
    'mug-hot' ,
    'dog-leashed' ,
    'car-battery' ,
    'battery-car' ,
    'face-downcast-sweat' ,
    'mailbox-flag-up' ,
    'memo-circle-info' ,
    'gift' ,
    'dice-two' ,
    'volume' ,
    'volume-medium' ,
    'transporter-5' ,
    'gauge-circle-bolt' ,
    'coin-front' ,
    'file-slash' ,
    'message-arrow-up-right' ,
    'treasure-chest' ,
    'chess-queen' ,
    'paintbrush-fine' ,
    'paint-brush-alt' ,
    'paint-brush-fine' ,
    'paintbrush-alt' ,
    'glasses' ,
    'hood-cloak' ,
    'square-quote' ,
    'up-left' ,
    'bring-front' ,
    'chess-board' ,
    'burger-cheese' ,
    'cheeseburger' ,
    'building-circle-check' ,
    'repeat-1' ,
    'arrow-down-to-line' ,
    'arrow-to-bottom' ,
    'grid-5' ,
    'right-long-to-line' ,
    'person-chalkboard' ,
    'mars-stroke-right' ,
    'mars-stroke-h' ,
    'hand-back-fist' ,
    'hand-rock' ,
    'tally' ,
    'tally5' ,
    'square-caret-up' ,
    'caret-square-up' ,
    'cloud-showers-water' ,
    'chart-bar' ,
    'bar-chart' ,
    'hands-bubbles' ,
    'hands-wash' ,
    'less-than-equal' ,
    'train' ,
    'up-from-dotted-line' ,
    'eye-low-vision' ,
    'low-vision' ,
    'traffic-light-go' ,
    'face-exhaling' ,
    'sensor-fire' ,
    'user-unlock' ,
    'hexagon-divide' ,
    '00' ,
    'crow' ,
    'cassette-betamax' ,
    'betamax' ,
    'sailboat' ,
    'window-restore' ,
    'nfc-magnifying-glass' ,
    'file-binary' ,
    'circle-v' ,
    'square-plus' ,
    'plus-square' ,
    'bowl-scoops' ,
    'mistletoe' ,
    'custard' ,
    'lacrosse-stick' ,
    'hockey-mask' ,
    'sunrise' ,
    'panel-ews' ,
    'torii-gate' ,
    'cloud-exclamation' ,
    'message-lines' ,
    'comment-alt-lines' ,
    'frog' ,
    'bucket' ,
    'floppy-disk-pen' ,
    'image' ,
    'window-frame' ,
    'microphone' ,
    'cow' ,
    'square-ring' ,
    'down-from-line' ,
    'arrow-alt-from-top' ,
    'caret-up' ,
    'shield-xmark' ,
    'shield-times' ,
    'screwdriver' ,
    'circle-sort-down' ,
    'sort-circle-down' ,
    'folder-closed' ,
    'house-tsunami' ,
    'square-nfi' ,
    'forklift' ,
    'arrow-up-from-ground-water' ,
    'bracket-square-right' ,
    'martini-glass' ,
    'glass-martini-alt' ,
    'rotate-left' ,
    'rotate-back' ,
    'rotate-backward' ,
    'undo-alt' ,
    'table-columns' ,
    'columns' ,
    'square-a' ,
    'tick' ,
    'lemon' ,
    'head-side-mask' ,
    'handshake' ,
    'gem' ,
    'dolly' ,
    'dolly-box' ,
    'smoking' ,
    'minimize' ,
    'compress-arrows-alt' ,
    'refrigerator' ,
    'monument' ,
    'octagon-xmark' ,
    'times-octagon' ,
    'xmark-octagon' ,
    'align-slash' ,
    'snowplow' ,
    'angles-right' ,
    'angle-double-right' ,
    'truck-ramp-couch' ,
    'truck-couch' ,
    'cannabis' ,
    'circle-play' ,
    'play-circle' ,
    'arrow-up-right-and-arrow-down-left-from-center' ,
    'tablets' ,
    '360-degrees' ,
    'ethernet' ,
    'euro-sign' ,
    'eur' ,
    'euro' ,
    'chair' ,
    'circle-check' ,
    'check-circle' ,
    'square-dashed-circle-plus' ,
    'money-simple-from-bracket' ,
    'bat' ,
    'circle-stop' ,
    'stop-circle' ,
    'head-side-headphones' ,
    'phone-rotary' ,
    'compass-drafting' ,
    'drafting-compass' ,
    'plate-wheat' ,
    'calendar-circle-minus' ,
    'chopsticks' ,
    'car-wrench' ,
    'car-mechanic' ,
    'icicles' ,
    'person-shelter' ,
    'neuter' ,
    'id-badge' ,
    'kazoo' ,
    'marker' ,
    'face-laugh-beam' ,
    'laugh-beam' ,
    'square-arrow-down-left' ,
    'battery-bolt' ,
    'tree-large' ,
    'helicopter-symbol' ,
    'aperture' ,
    'universal-access' ,
    'file-magnifying-glass' ,
    'file-search' ,
    'up-right' ,
    'circle-chevron-up' ,
    'chevron-circle-up' ,
    'user-police' ,
    'lari-sign' ,
    'volcano' ,
    'teddy-bear' ,
    'stocking' ,
    'person-walking-dashed-line-arrow-right' ,
    'image-slash' ,
    'mask-snorkel' ,
    'smoke' ,
    'sterling-sign' ,
    'gbp' ,
    'pound-sign' ,
    'battery-exclamation' ,
    'viruses' ,
    'square-person-confined' ,
    'user-tie' ,
    'arrow-down-long' ,
    'long-arrow-down' ,
    'tent-arrow-down-to-line' ,
    'certificate' ,
    'crystal-ball' ,
    'reply-all' ,
    'mail-reply-all' ,
    'suitcase' ,
    'person-skating' ,
    'skating' ,
    'star-shooting' ,
    'binary-lock' ,
    'filter-circle-dollar' ,
    'funnel-dollar' ,
    'camera-retro' ,
    'circle-arrow-down' ,
    'arrow-circle-down' ,
    'comment-pen' ,
    'comment-edit' ,
    'file-import' ,
    'arrow-right-to-file' ,
    'banjo' ,
    'square-arrow-up-right' ,
    'external-link-square' ,
    'light-emergency-on' ,
    'kerning' ,
    'box-open' ,
    'square-f' ,
    'scroll' ,
    'spa' ,
    'arrow-left-from-line' ,
    'arrow-from-right' ,
    'strawberry' ,
    'location-pin-lock' ,
    'pause' ,
    'clock-eight-thirty' ,
    'plane-engines' ,
    'plane-alt' ,
    'hill-avalanche' ,
    'temperature-empty' ,
    'temperature0' ,
    'thermometer0' ,
    'thermometer-empty' ,
    'bomb' ,
    'gauge-low' ,
    'tachometer-alt-slow' ,
    'registered' ,
    'trash-can-plus' ,
    'address-card' ,
    'contact-card' ,
    'vcard' ,
    'scale-unbalanced-flip' ,
    'balance-scale-right' ,
    'globe-snow' ,
    'subscript' ,
    'diamond-turn-right' ,
    'directions' ,
    'integral' ,
    'burst' ,
    'house-laptop' ,
    'laptop-house' ,
    'face-tired' ,
    'tired' ,
    'money-bills' ,
    'blinds-raised' ,
    'smog' ,
    'ufo-beam' ,
    'circle-caret-up' ,
    'caret-circle-up' ,
    'user-vneck-hair-long' ,
    'square-a-lock' ,
    'crutch' ,
    'gas-pump-slash' ,
    'font-awesome' ,
    'font-awesome-flag' ,
    'font-awesome-logo-full' ,
    'cloud-arrow-up' ,
    'cloud-upload' ,
    'cloud-upload-alt' ,
    'palette' ,
    'transporter-4' ,
    'objects-align-right' ,
    'arrows-turn-right' ,
    'vest' ,
    'pig' ,
    'inbox-full' ,
    'circle-envelope' ,
    'envelope-circle' ,
    'triangle-person-digging' ,
    'construction' ,
    'ferry' ,
    'bullseye-arrow' ,
    'arrows-down-to-people' ,
    'seedling' ,
    'sprout' ,
    'clock-seven' ,
    'left-right' ,
    'arrows-alt-h' ,
    'boxes-packing' ,
    'circle-arrow-left' ,
    'arrow-circle-left' ,
    'flashlight' ,
    'group-arrows-rotate' ,
    'bowl-food' ,
    'square-9' ,
    'candy-cane' ,
    'arrow-down-wide-short' ,
    'sort-amount-asc' ,
    'sort-amount-down' ,
    'square-dollar' ,
    'dollar-square' ,
    'usd-square' ,
    'phone-arrow-right' ,
    'hand-holding-seedling' ,
    'message-check' ,
    'comment-alt-check' ,
    'cloud-bolt' ,
    'thunderstorm' ,
    'text-slash' ,
    'remove-format' ,
    'watch' ,
    'circle-down-left' ,
    'text' ,
    'projector' ,
    'face-smile-wink' ,
    'smile-wink' ,
    'tombstone-blank' ,
    'tombstone-alt' ,
    'chess-king-piece' ,
    'chess-king-alt' ,
    'circle-6' ,
    'left' ,
    'arrow-alt-left' ,
    'file-word' ,
    'file-powerpoint' ,
    'square-down' ,
    'arrow-alt-square-down' ,
    'objects-align-center-vertical' ,
    'arrows-left-right' ,
    'arrows-h' ,
    'house-lock' ,
    'cloud-arrow-down' ,
    'cloud-download' ,
    'cloud-download-alt' ,
    'wreath' ,
    'children' ,
    'meter-droplet' ,
    'chalkboard' ,
    'blackboard' ,
    'user-large-slash' ,
    'user-alt-slash' ,
    'signal-strong' ,
    'signal4' ,
    'lollipop' ,
    'lollypop' ,
    'list-tree' ,
    'envelope-open' ,
    'draw-circle' ,
    'cat-space' ,
    'handshake-simple-slash' ,
    'handshake-alt-slash' ,
    'rabbit-running' ,
    'rabbit-fast' ,
    'memo-pad' ,
    'mattress-pillow' ,
    'alarm-plus' ,
    'alicorn' ,
    'comment-question' ,
    'gingerbread-man' ,
    'guarani-sign' ,
    'burger-fries' ,
    'mug-tea' ,
    'border-top' ,
    'arrows-rotate' ,
    'refresh' ,
    'sync' ,
    'circle-book-open' ,
    'book-circle' ,
    'arrows-to-dotted-line' ,
    'fire-extinguisher' ,
    'garage-open' ,
    'shelves-empty' ,
    'cruzeiro-sign' ,
    'watch-apple' ,
    'watch-calculator' ,
    'list-dropdown' ,
    'cabinet-filing' ,
    'burger-soda' ,
    'square-arrow-up' ,
    'arrow-square-up' ,
    'greater-than-equal' ,
    'pallet-box' ,
    'face-confounded' ,
    'shield-halved' ,
    'shield-alt' ,
    'truck-plow' ,
    'book-atlas' ,
    'atlas' ,
    'virus' ,
    'comment-middle-top' ,
    'envelope-circle-check' ,
    'layer-group' ,
    'restroom-simple' ,
    'arrows-to-dot' ,
    'border-outer' ,
    'hashtag-lock' ,
    'clock-two-thirty' ,
    'archway' ,
    'heart-circle-check' ,
    'house-chimney-crack' ,
    'house-damage' ,
    'file-zipper' ,
    'file-archive' ,
    'heart-half' ,
    'comment-check' ,
    'square' ,
    'memo' ,
    'martini-glass-empty' ,
    'glass-martini' ,
    'couch' ,
    'cedi-sign' ,
    'italic' ,
    'glass-citrus' ,
    'calendar-lines-pen' ,
    'church' ,
    'person-snowmobiling' ,
    'snowmobile' ,
    'face-hushed' ,
    'comments-dollar' ,
    'pickaxe' ,
    'link-simple-slash' ,
    'democrat' ,
    'face-confused' ,
    'pinball' ,
    'z' ,
    'person-skiing' ,
    'skiing' ,
    'deer' ,
    'input-pipe' ,
    'road-lock' ,
    'a' ,
    'bookmark-slash' ,
    'temperature-arrow-down' ,
    'temperature-down' ,
    'mace' ,
    'feather-pointed' ,
    'feather-alt' ,
    'sausage' ,
    'trash-can-clock' ,
    'p' ,
    'snowflake' ,
    'stomach' ,
    'newspaper' ,
    'rectangle-ad' ,
    'ad' ,
    'guitar-electric' ,
    'arrow-turn-down-right' ,
    'moon-cloud' ,
    'bread-slice-butter' ,
    'circle-arrow-right' ,
    'arrow-circle-right' ,
    'user-group-crown' ,
    'users-crown' ,
    'circle-i' ,
    'toilet-paper-check' ,
    'filter-circle-xmark' ,
    'locust' ,
    'sort' ,
    'unsorted' ,
    'list-ol' ,
    'list12' ,
    'list-numeric' ,
    'chart-waterfall' ,
    'face-party' ,
    'kidneys' ,
    'wifi-exclamation' ,
    'chart-network' ,
    'person-dress-burst' ,
    'dice-d4' ,
    'money-check-dollar' ,
    'money-check-alt' ,
    'vector-square' ,
    'bread-slice' ,
    'language' ,
    'wheat-awn-slash' ,
    'face-kiss-wink-heart' ,
    'kiss-wink-heart' ,
    'dagger' ,
    'podium' ,
    'memo-circle-check' ,
    'route-highway' ,
    'down-to-line' ,
    'arrow-alt-to-bottom' ,
    'filter' ,
    'square-g' ,
    'circle-phone' ,
    'phone-circle' ,
    'clipboard-prescription' ,
    'user-nurse-hair' ,
    'question' ,
    'file-signature' ,
    'toggle-large-on' ,
    'up-down-left-right' ,
    'arrows-alt' ,
    'dryer-heat' ,
    'dryer-alt' ,
    'house-chimney-user' ,
    'hand-holding-heart' ,
    'arrow-up-small-big' ,
    'sort-size-up-alt' ,
    'train-track' ,
    'puzzle-piece' ,
    'money-check' ,
    'star-half-stroke' ,
    'star-half-alt' ,
    'file-exclamation' ,
    'code' ,
    'whiskey-glass' ,
    'glass-whiskey' ,
    'moon-stars' ,
    'building-circle-exclamation' ,
    'clothes-hanger' ,
    'mobile-notch' ,
    'mobile-iphone' ,
    'magnifying-glass-chart' ,
    'arrow-up-right-from-square' ,
    'external-link' ,
    'cubes-stacked' ,
    'images-user' ,
    'won-sign' ,
    'krw' ,
    'won' ,
    'image-polaroid-user' ,
    'virus-covid' ,
    'square-ellipsis' ,
    'pie' ,
    'chess-knight-piece' ,
    'chess-knight-alt' ,
    'austral-sign' ,
    'cloud-plus' ,
    'f' ,
    'leaf' ,
    'bed-bunk' ,
    'road' ,
    'taxi' ,
    'cab' ,
    'person-circle-plus' ,
    'chart-pie' ,
    'pie-chart' ,
    'bolt-lightning' ,
    'clock-eight' ,
    'sack-xmark' ,
    'file-excel' ,
    'file-contract' ,
    'fish-fins' ,
    'circle-q' ,
    'building-flag' ,
    'face-grin-beam' ,
    'grin-beam' ,
    'object-ungroup' ,
    'face-disguise' ,
    'circle-arrow-down-right' ,
    'alien-8bit' ,
    'alien-monster' ,
    'hand-point-ribbon' ,
    'poop' ,
    'object-exclude' ,
    'telescope' ,
    'location-pin' ,
    'map-marker' ,
    'square-list' ,
    'kaaba' ,
    'toilet-paper' ,
    'helmet-safety' ,
    'hard-hat' ,
    'hat-hard' ,
    'comment-code' ,
    'sim-cards' ,
    'starship' ,
    'eject' ,
    'circle-right' ,
    'arrow-alt-circle-right' ,
    'plane-circle-check' ,
    'seal' ,
    'user-cowboy' ,
    'hexagon-vertical-nft' ,
    'face-rolling-eyes' ,
    'meh-rolling-eyes' ,
    'bread-loaf' ,
    'rings-wedding' ,
    'object-group' ,
    'french-fries' ,
    'chart-line' ,
    'line-chart' ,
    'calendar-arrow-down' ,
    'calendar-download' ,
    'send-back' ,
    'mask-ventilator' ,
    'signature-lock' ,
    'arrow-right' ,
    'signs-post' ,
    'map-signs' ,
    'octagon-plus' ,
    'plus-octagon' ,
    'cash-register' ,
    'person-circle-question' ,
    'melon-slice' ,
    'space-station-moon' ,
    'message-smile' ,
    'comment-alt-smile' ,
    'cup-straw' ,
    'left-from-line' ,
    'arrow-alt-from-right' ,
    'h' ,
    'basket-shopping-simple' ,
    'shopping-basket-alt' ,
    'hands-holding-heart' ,
    'hands-heart' ,
    'clock-nine' ,
    'tarp' ,
    'face-sleepy' ,
    'hand-horns' ,
    'screwdriver-wrench' ,
    'tools' ,
    'arrows-to-eye' ,
    'circle-three-quarters' ,
    'trophy-star' ,
    'trophy-alt' ,
    'plug-circle-bolt' ,
    'face-thermometer' ,
    'shirt-running' ,
    'book-circle-arrow-up' ,
    'face-nauseated' ,
    'heart' ,
    'file-chart-pie' ,
    'mars-and-venus' ,
    'house-user' ,
    'home-user' ,
    'circle-arrow-down-left' ,
    'dumpster-fire' ,
    'hexagon-minus' ,
    'minus-hexagon' ,
    'left-to-line' ,
    'arrow-alt-to-left' ,
    'house-crack' ,
    'paw-simple' ,
    'paw-alt' ,
    'arrow-left-long-to-line' ,
    'brackets-round' ,
    'parentheses' ,
    'martini-glass-citrus' ,
    'cocktail' ,
    'user-shakespeare' ,
    'arrow-right-to-arc' ,
    'face-surprise' ,
    'surprise' ,
    'bottle-water' ,
    'circle-pause' ,
    'pause-circle' ,
    'gauge-circle-plus' ,
    'folders' ,
    'angel' ,
    'value-absolute' ,
    'rabbit' ,
    'toilet-paper-slash' ,
    'apple-whole' ,
    'apple-alt' ,
    'kitchen-set' ,
    'diamond-half' ,
    'lock-keyhole' ,
    'lock-alt' ,
    'r' ,
    'temperature-quarter' ,
    'temperature1' ,
    'thermometer1' ,
    'thermometer-quarter' ,
    'square-info' ,
    'info-square' ,
    'wifi-slash' ,
    'toilet-paper-xmark' ,
    'hands-holding-dollar' ,
    'hands-usd' ,
    'cube' ,
    'arrow-down-triangle-square' ,
    'sort-shapes-down' ,
    'bitcoin-sign' ,
    'shutters' ,
    'shield-dog' ,
    'solar-panel' ,
    'lock-open' ,
    'table-tree' ,
    'house-chimney-heart' ,
    'tally-3' ,
    'elevator' ,
    'money-bill-transfer' ,
    'money-bill-trend-up' ,
    'house-flood-water-circle-arrow-right' ,
    'square-poll-horizontal' ,
    'poll-h' ,
    'circle' ,
    'cart-circle-exclamation' ,
    'sword' ,
    'backward-fast' ,
    'fast-backward' ,
    'recycle' ,
    'user-astronaut' ,
    'interrobang' ,
    'plane-slash' ,
    'circle-dashed' ,
    'trademark' ,
    'basketball' ,
    'basketball-ball' ,
    'fork-knife' ,
    'utensils-alt' ,
    'satellite-dish' ,
    'badge-check' ,
    'circle-up' ,
    'arrow-alt-circle-up' ,
    'slider' ,
    'mobile-screen-button' ,
    'mobile-alt' ,
    'clock-one-thirty' ,
    'inbox-out' ,
    'inbox-arrow-up' ,
    'cloud-slash' ,
    'volume-high' ,
    'volume-up' ,
    'users-rays' ,
    'wallet' ,
    'octagon-check' ,
    'flatbread-stuffed' ,
    'clipboard-check' ,
    'cart-circle-plus' ,
    'truck-clock' ,
    'shipping-timed' ,
    'pool-8-ball' ,
    'file-audio' ,
    'turn-down-left' ,
    'lock-hashtag' ,
    'chart-radar' ,
    'staff' ,
    'burger' ,
    'hamburger' ,
    'utility-pole' ,
    'transporter-6' ,
    'wrench' ,
    'bugs' ,
    'vector-polygon' ,
    'diagram-nested' ,
    'rupee-sign' ,
    'rupee' ,
    'file-image' ,
    'circle-question' ,
    'question-circle' ,
    'image-user' ,
    'buoy' ,
    'plane-departure' ,
    'handshake-slash' ,
    'book-bookmark' ,
    'border-center-h' ,
    'can-food' ,
    'typewriter' ,
    'arrow-right-from-arc' ,
    'circle-k' ,
    'face-hand-over-mouth' ,
    'popcorn' ,
    'house-water' ,
    'house-flood' ,
    'object-subtract' ,
    'code-branch' ,
    'warehouse-full' ,
    'warehouse-alt' ,
    'hat-cowboy' ,
    'bridge' ,
    'phone-flip' ,
    'phone-alt' ,
    'arrow-down-from-dotted-line' ,
    'square-quarters' ,
    'truck-front' ,
    'cat' ,
    'trash-xmark' ,
    'circle-caret-left' ,
    'caret-circle-left' ,
    'files' ,
    'anchor-circle-exclamation' ,
    'face-clouds' ,
    'user-crown' ,
    'truck-field' ,
    'route' ,
    'cart-circle-check' ,
    'clipboard-question' ,
    'panorama' ,
    'comment-medical' ,
    'teeth-open' ,
    'user-tie-hair-long' ,
    'file-circle-minus' ,
    'head-side-medical' ,
    'tags' ,
    'wine-glass' ,
    'forward-fast' ,
    'fast-forward' ,
    'face-meh-blank' ,
    'meh-blank' ,
    'user-robot' ,
    'square-parking' ,
    'parking' ,
    'card-diamond' ,
    'face-zipper' ,
    'face-raised-eyebrow' ,
    'house-signal' ,
    'square-chevron-up' ,
    'chevron-square-up' ,
    'nfc-symbol' ,
    'bars-progress' ,
    'tasks-alt' ,
    'faucet-drip' ,
    'arrows-to-line' ,
    'dolphin' ,
    'arrow-up-right' ,
    'circle-r' ,
    'cart-flatbed' ,
    'dolly-flatbed' ,
    'ban-smoking' ,
    'smoking-ban' ,
    'circle-sort-up' ,
    'sort-circle-up' ,
    'terminal' ,
    'mobile-button' ,
    'house-medical-flag' ,
    'basket-shopping' ,
    'shopping-basket' ,
    'tape' ,
    'chestnut' ,
    'bus-simple' ,
    'bus-alt' ,
    'eye' ,
    'face-sad-cry' ,
    'sad-cry' ,
    'heat' ,
    'ticket-airline' ,
    'boot-heeled' ,
    'arrows-minimize' ,
    'compress-arrows' ,
    'audio-description' ,
    'person-military-to-person' ,
    'file-shield' ,
    'hexagon' ,
    'manhole' ,
    'user-slash' ,
    'pen' ,
    'tower-observation' ,
    'floppy-disks' ,
    'toilet-paper-blank-under' ,
    'toilet-paper-reverse-alt' ,
    'file-code' ,
    'signal' ,
    'signal5' ,
    'signal-perfect' ,
    'pump' ,
    'bus' ,
    'heart-circle-xmark' ,
    'arrow-up-left-from-circle' ,
    'house-chimney' ,
    'home-lg' ,
    'window-maximize' ,
    'dryer' ,
    'face-frown' ,
    'frown' ,
    'chess-bishop-piece' ,
    'chess-bishop-alt' ,
    'shirt-tank-top' ,
    'diploma' ,
    'scroll-ribbon' ,
    'screencast' ,
    'walker' ,
    'prescription' ,
    'shop' ,
    'store-alt' ,
    'floppy-disk' ,
    'save' ,
    'vihara' ,
    'face-kiss-closed-eyes' ,
    'scale-unbalanced' ,
    'balance-scale-left' ,
    'file-user' ,
    'user-police-tie' ,
    'face-tongue-money' ,
    'tennis-ball' ,
    'square-l' ,
    'sort-up' ,
    'sort-asc' ,
    'calendar-arrow-up' ,
    'calendar-upload' ,
    'comment-dots' ,
    'commenting' ,
    'plant-wilt' ,
    'scarf' ,
    'album-circle-plus' ,
    'user-nurse-hair-long' ,
    'diamond' ,
    'square-left' ,
    'arrow-alt-square-left' ,
    'face-grin-squint' ,
    'grin-squint' ,
    'circle-ellipsis-vertical' ,
    'hand-holding-dollar' ,
    'hand-holding-usd' ,
    'grid-dividers' ,
    'bacterium' ,
    'hand-pointer' ,
    'drum-steelpan' ,
    'hand-scissors' ,
    'hands-praying' ,
    'praying-hands' ,
    'face-pensive' ,
    'user-music' ,
    'arrow-rotate-right' ,
    'arrow-right-rotate' ,
    'arrow-rotate-forward' ,
    'redo' ,
    'messages-dollar' ,
    'comments-alt-dollar' ,
    'sensor-on' ,
    'balloon' ,
    'biohazard' ,
    'chess-queen-piece' ,
    'chess-queen-alt' ,
    'location-crosshairs' ,
    'location' ,
    'mars-double' ,
    'house-person-leave' ,
    'house-leave' ,
    'house-person-depart' ,
    'ruler-triangle' ,
    'card-club' ,
    'child-dress' ,
    'users-between-lines' ,
    'lungs-virus' ,
    'spinner-third' ,
    'face-grin-tears' ,
    'grin-tears' ,
    'phone' ,
    'computer-mouse-scrollwheel' ,
    'mouse-alt' ,
    'calendar-xmark' ,
    'calendar-times' ,
    'child-reaching' ,
    'table-layout' ,
    'narwhal' ,
    'ramp-loading' ,
    'calendar-circle-plus' ,
    'toothbrush' ,
    'border-inner' ,
    'paw-claws' ,
    'kiwi-fruit' ,
    'traffic-light-slow' ,
    'rectangle-code' ,
    'head-side-virus' ,
    'keyboard-brightness' ,
    'books-medical' ,
    'lightbulb-slash' ,
    'house-blank' ,
    'home-blank' ,
    'square-5' ,
    'square-heart' ,
    'heart-square' ,
    'puzzle' ,
    'user-gear' ,
    'user-cog' ,
    'pipe-circle-check' ,
    'arrow-up-1-9' ,
    'sort-numeric-up' ,
    'octagon-exclamation' ,
    'dial-low' ,
    'door-closed' ,
    'laptop-mobile' ,
    'phone-laptop' ,
    'conveyor-belt-boxes' ,
    'conveyor-belt-alt' ,
    'shield-virus' ,
    'starfighter-twin-ion-engine-advanced' ,
    'starfighter-alt-advanced' ,
    'dice-six' ,
    'starfighter-twin-ion-engine' ,
    'starfighter-alt' ,
    'rocket-launch' ,
    'mosquito-net' ,
    'vent-damper' ,
    'bridge-water' ,
    'ban-bug' ,
    'debug' ,
    'person-booth' ,
    'text-width' ,
    'garage-car' ,
    'square-kanban' ,
    'hat-wizard' ,
    'pen-fancy' ,
    'coffee-pot' ,
    'mouse-field' ,
    'person-digging' ,
    'digging' ,
    'shower-down' ,
    'shower-alt' ,
    'box-circle-check' ,
    'brightness' ,
    'car-side-bolt' ,
    'ornament' ,
    'phone-arrow-down-left' ,
    'phone-arrow-down' ,
    'phone-incoming' ,
    'cloud-word' ,
    'hand-fingers-crossed' ,
    'trash' ,
    'gauge-simple' ,
    'gauge-simple-med' ,
    'tachometer-average' ,
    'arrow-down-small-big' ,
    'sort-size-down-alt' ,
    'book-medical' ,
    'face-melting' ,
    'poo' ,
    'pen-clip-slash' ,
    'pen-alt-slash' ,
    'quote-right' ,
    'quote-right-alt' ,
    'scroll-old' ,
    'guitars' ,
    'phone-xmark' ,
    'hose' ,
    'clock-six' ,
    'shirt' ,
    't-shirt' ,
    'tshirt' ,
    'square-r' ,
    'cubes' ,
    'envelope-open-dollar' ,
    'divide' ,
    'sun-cloud' ,
    'lamp-floor' ,
    'square-7' ,
    'tenge-sign' ,
    'tenge' ,
    'headphones' ,
    'hands-holding' ,
    'campfire' ,
    'circle-ampersand' ,
    'snowflakes' ,
    'hands-clapping' ,
    'republican' ,
    'leaf-maple' ,
    'arrow-left' ,
    'person-circle-xmark' ,
    'ruler' ,
    'cup-straw-swoosh' ,
    'temperature-sun' ,
    'temperature-hot' ,
    'align-left' ,
    'dice-d6' ,
    'restroom' ,
    'high-definition' ,
    'rectangle-hd' ,
    'j' ,
    'galaxy' ,
    'users-viewfinder' ,
    'file-video' ,
    'cherries' ,
    'up-right-from-square' ,
    'external-link-alt' ,
    'circle-sort' ,
    'sort-circle' ,
    'table-cells' ,
    'th' ,
    'file-pdf' ,
    'siren' ,
    'arrow-up-to-dotted-line' ,
    'image-landscape' ,
    'landscape' ,
    'tank-water' ,
    'curling-stone' ,
    'curling' ,
    'gamepad-modern' ,
    'gamepad-alt' ,
    'messages-question' ,
    'book-bible' ,
    'bible' ,
    'o' ,
    'suitcase-medical' ,
    'medkit' ,
    'briefcase-arrow-right' ,
    'expand-wide' ,
    'clock-eleven-thirty' ,
    'rv' ,
    'user-secret' ,
    'otter' ,
    'dreidel' ,
    'person-dress' ,
    'female' ,
    'comment-dollar' ,
    'business-time' ,
    'briefcase-clock' ,
    'flower-tulip' ,
    'people-pants-simple' ,
    'cloud-drizzle' ,
    'table-cells-large' ,
    'th-large' ,
    'book-tanakh' ,
    'tanakh' ,
    'solar-system' ,
    'seal-question' ,
    'phone-volume' ,
    'volume-control-phone' ,
    'disc-drive' ,
    'hat-cowboy-side' ,
    'table-rows' ,
    'rows' ,
    'location-exclamation' ,
    'map-marker-exclamation' ,
    'face-fearful' ,
    'clipboard-user' ,
    'bus-school' ,
    'film-slash' ,
    'square-arrow-down-right' ,
    'book-sparkles' ,
    'book-spells' ,
    'washing-machine' ,
    'washer' ,
    'child' ,
    'lira-sign' ,
    'user-visor' ,
    'file-plus-minus' ,
    'chess-clock-flip' ,
    'chess-clock-alt' ,
    'satellite' ,
    'plane-lock' ,
    'steering-wheel' ,
    'tag' ,
    'stretcher' ,
    'book-section' ,
    'book-law' ,
    'inboxes' ,
    'coffee-bean' ,
    'brackets-curly' ,
    'ellipsis-stroke-vertical' ,
    'ellipsis-v-alt' ,
    'comment' ,
    'square-1' ,
    'cake-candles' ,
    'birthday-cake' ,
    'cake' ,
    'head-side' ,
    'envelope' ,
    'dolly-empty' ,
    'face-tissue' ,
    'angles-up' ,
    'angle-double-up' ,
    'paperclip' ,
    'chart-line-down' ,
    'arrow-right-to-city' ,
    'lock-a' ,
    'ribbon' ,
    'lungs' ,
    'person-pinball' ,
    'arrow-up-9-1' ,
    'sort-numeric-up-alt' ,
    'apple-core' ,
    'circle-y' ,
    'h6' ,
    'litecoin-sign' ,
    'circle-small' ,
    'border-none' ,
    'arrow-turn-down-left' ,
    'circle-nodes' ,
    'parachute-box' ,
    'message-medical' ,
    'comment-alt-medical' ,
    'rugby-ball' ,
    'comment-music' ,
    'indent' ,
    'tree-deciduous' ,
    'tree-alt' ,
    'puzzle-piece-simple' ,
    'puzzle-piece-alt' ,
    'truck-field-un' ,
    'nfc-trash' ,
    'hourglass' ,
    'hourglass-empty' ,
    'mountain' ,
    'file-xmark' ,
    'file-times' ,
    'house-heart' ,
    'home-heart' ,
    'house-chimney-blank' ,
    'meter-bolt' ,
    'user-doctor' ,
    'user-md' ,
    'slash-back' ,
    'circle-info' ,
    'info-circle' ,
    'fishing-rod' ,
    'hammer-crash' ,
    'message-heart' ,
    'cloud-meatball' ,
    'camera-polaroid' ,
    'camera' ,
    'camera-alt' ,
    'square-virus' ,
    'cart-arrow-up' ,
    'meteor' ,
    'car-on' ,
    'sleigh' ,
    'arrow-down-1-9' ,
    'sort-numeric-asc' ,
    'sort-numeric-down' ,
    'buoy-mooring' ,
    'square-4' ,
    'hand-holding-droplet' ,
    'hand-holding-water' ,
    'tricycle-adult' ,
    'waveform' ,
    'water' ,
    'star-sharp-half-stroke' ,
    'star-sharp-half-alt' ,
    'nfc-signal' ,
    'plane-prop' ,
    'calendar-check' ,
    'clock-desk' ,
    'calendar-clock' ,
    'calendar-time' ,
    'braille' ,
    'prescription-bottle-medical' ,
    'prescription-bottle-alt' ,
    'plate-utensils' ,
    'family-pants' ,
    'hose-reel' ,
    'house-window' ,
    'landmark' ,
    'truck' ,
    'crosshairs' ,
    'cloud-rainbow' ,
    'person-cane' ,
    'alien' ,
    'tent' ,
    'vest-patches' ,
    'people-dress-simple' ,
    'check-double' ,
    'arrow-down-a-z' ,
    'sort-alpha-asc' ,
    'sort-alpha-down' ,
    'bowling-ball-pin' ,
    'bell-school-slash' ,
    'plus-large' ,
    'money-bill-wheat' ,
    'camera-viewfinder' ,
    'screenshot' ,
    'message-music' ,
    'comment-alt-music' ,
    'car-building' ,
    'border-bottom-right' ,
    'border-style-alt' ,
    'octagon' ,
    'comment-arrow-up-right' ,
    'octagon-divide' ,
    'cookie' ,
    'arrow-rotate-left' ,
    'arrow-left-rotate' ,
    'arrow-rotate-back' ,
    'arrow-rotate-backward' ,
    'undo' ,
    'tv-music' ,
    'hard-drive' ,
    'hdd' ,
    'reel' ,
    'face-grin-squint-tears' ,
    'grin-squint-tears' ,
    'dumbbell' ,
    'rectangle-list' ,
    'list-alt' ,
    'tarp-droplet' ,
    'alarm-exclamation' ,
    'house-medical-circle-check' ,
    'traffic-cone' ,
    'grate' ,
    'arrow-down-right' ,
    'person-skiing-nordic' ,
    'skiing-nordic' ,
    'calendar-plus' ,
    'person-from-portal' ,
    'portal-exit' ,
    'plane-arrival' ,
    'cowbell-circle-plus' ,
    'cowbell-more' ,
    'circle-left' ,
    'arrow-alt-circle-left' ,
    'distribute-spacing-vertical' ,
    'signal-bars-fair' ,
    'signal-alt2' ,
    'sportsball' ,
    'game-console-handheld-crank' ,
    'train-subway' ,
    'subway' ,
    'chart-gantt' ,
    'face-smile-upside-down' ,
    'ball-pile' ,
    'badge-dollar' ,
    'money-bills-simple' ,
    'money-bills-alt' ,
    'list-timeline' ,
    'indian-rupee-sign' ,
    'indian-rupee' ,
    'inr' ,
    'crop-simple' ,
    'crop-alt' ,
    'money-bill-1' ,
    'money-bill-alt' ,
    'left-long' ,
    'long-arrow-alt-left' ,
    'keyboard-down' ,
    'circle-up-right' ,
    'cloud-bolt-moon' ,
    'thunderstorm-moon' ,
    'dna' ,
    'virus-slash' ,
    'bracket-round-right' ,
    'circle-5' ,
    'minus' ,
    'subtract' ,
    'fire-flame' ,
    'flame' ,
    'right-to-line' ,
    'arrow-alt-to-right' ,
    'gif' ,
    'chess' ,
    'trash-slash' ,
    'arrow-left-long' ,
    'long-arrow-left' ,
    'plug-circle-check' ,
    'font-case' ,
    'street-view' ,
    'arrow-down-left' ,
    'franc-sign' ,
    'flask-round-poison' ,
    'flask-poison' ,
    'volume-off' ,
    'book-circle-arrow-right' ,
    'chart-user' ,
    'user-chart' ,
    'hands-asl-interpreting' ,
    'american-sign-language-interpreting' ,
    'asl-interpreting' ,
    'hands-american-sign-language-interpreting' ,
    'presentation-screen' ,
    'presentation' ,
    'circle-bolt' ,
    'face-smile-halo' ,
    'cart-circle-arrow-down' ,
    'house-person-return' ,
    'house-person-arrive' ,
    'house-return' ,
    'message-xmark' ,
    'comment-alt-times' ,
    'message-times' ,
    'file-certificate' ,
    'file-award' ,
    'user-doctor-hair-long' ,
    'camera-security' ,
    'camera-home' ,
    'gear' ,
    'cog' ,
    'droplet-slash' ,
    'tint-slash' ,
    'book-heart' ,
    'mosque' ,
    'duck' ,
    'mosquito' ,
    'star-of-david' ,
    'flag-swallowtail' ,
    'flag-alt' ,
    'person-military-rifle' ,
    'car-garage' ,
    'cart-shopping' ,
    'shopping-cart' ,
    'book-font' ,
    'shield-plus' ,
    'vials' ,
    'eye-dropper-full' ,
    'distribute-spacing-horizontal' ,
    'tablet-rugged' ,
    'temperature-snow' ,
    'temperature-frigid' ,
    'moped' ,
    'face-smile-plus' ,
    'smile-plus' ,
    'radio-tuner' ,
    'radio-alt' ,
    'face-swear' ,
    'water-arrow-down' ,
    'water-lower' ,
    'scanner-touchscreen' ,
    'circle-7' ,
    'plug-circle-plus' ,
    'person-ski-jumping' ,
    'ski-jump' ,
    'place-of-worship' ,
    'water-arrow-up' ,
    'water-rise' ,
    'waveform-lines' ,
    'waveform-path' ,
    'split' ,
    'film-canister' ,
    'film-cannister' ,
    'folder-xmark' ,
    'folder-times' ,
    'toilet-paper-blank' ,
    'toilet-paper-alt' ,
    'tablet-screen' ,
    'tablet-android-alt' ,
    'hexagon-vertical-nft-slanted' ,
    'folder-music' ,
    'display-medical' ,
    'desktop-medical' ,
    'share-all' ,
    'peapod' ,
    'chess-clock' ,
    'axe' ,
    'square-d' ,
    'grip-vertical' ,
    'mobile-signal-out' ,
    'arrow-turn-up' ,
    'level-up' ,
    'u' ,
    'arrow-up-from-dotted-line' ,
    'square-root-variable' ,
    'square-root-alt' ,
    'light-switch-on' ,
    'arrow-down-arrow-up' ,
    'sort-alt' ,
    'raindrops' ,
    'dash' ,
    'minus-large' ,
    'clock' ,
    'clock-four' ,
    'input-numeric' ,
    'truck-tow' ,
    'backward-step' ,
    'step-backward' ,
    'pallet' ,
    'car-bolt' ,
    'arrows-maximize' ,
    'expand-arrows' ,
    'faucet' ,
    'cloud-sleet' ,
    'lamp-street' ,
    'list-radio' ,
    'pen-nib-slash' ,
    'baseball-bat-ball' ,
    'square-up-left' ,
    'overline' ,
    's' ,
    'timeline' ,
    'keyboard' ,
    'arrows-from-dotted-line' ,
    'usb-drive' ,
    'ballot' ,
    'caret-down' ,
    'location-dot-slash' ,
    'map-marker-alt-slash' ,
    'cards' ,
    'house-chimney-medical' ,
    'clinic-medical' ,
    'boxing-glove' ,
    'glove-boxing' ,
    'temperature-three-quarters' ,
    'temperature3' ,
    'thermometer3' ,
    'thermometer-three-quarters' ,
    'bell-school' ,
    'mobile-screen' ,
    'mobile-android-alt' ,
    'plane-up' ,
    'folder-heart' ,
    'circle-location-arrow' ,
    'location-circle' ,
    'face-head-bandage' ,
    'sushi-roll' ,
    'maki-roll' ,
    'makizushi' ,
    'car-bump' ,
    'piggy-bank' ,
    'racquet' ,
    'car-mirrors' ,
    'industry-windows' ,
    'industry-alt' ,
    'bolt-auto' ,
    'battery-half' ,
    'battery3' ,
    'flux-capacitor' ,
    'mountain-city' ,
    'coins' ,
    'honey-pot' ,
    'olive' ,
    'khanda' ,
    'filter-list' ,
    'outlet' ,
    'sliders' ,
    'sliders-h' ,
    'cauldron' ,
    'people' ,
    'folder-tree' ,
    'network-wired' ,
    'croissant' ,
    'map-pin' ,
    'hamsa' ,
    'cent-sign' ,
    'swords-laser' ,
    'flask' ,
    'person-pregnant' ,
    'square-u' ,
    'wand-sparkles' ,
    'router' ,
    'ellipsis-vertical' ,
    'ellipsis-v' ,
    'sword-laser-alt' ,
    'ticket' ,
    'power-off' ,
    'coin' ,
    'laptop-slash' ,
    'right-long' ,
    'long-arrow-alt-right' ,
    'circle-b' ,
    'person-dress-simple' ,
    'pipe-collar' ,
    'lights-holiday' ,
    'citrus' ,
    'flag-usa' ,
    'laptop-file' ,
    'tty' ,
    'teletype' ,
    'chart-tree-map' ,
    'diagram-next' ,
    'person-rifle' ,
    'clock-five-thirty' ,
    'pipe-valve' ,
    'arrow-up-from-arc' ,
    'face-spiral-eyes' ,
    'compress-wide' ,
    'circle-phone-hangup' ,
    'phone-circle-down' ,
    'house-medical-circle-exclamation' ,
    'badminton' ,
    'closed-captioning' ,
    'person-hiking' ,
    'hiking' ,
    'right-from-line' ,
    'arrow-alt-from-left' ,
    'venus-double' ,
    'images' ,
    'calculator' ,
    'shuttlecock' ,
    'user-hair' ,
    'eye-evil' ,
    'people-pulling' ,
    'n' ,
    'garage' ,
    'cable-car' ,
    'tram' ,
    'shovel-snow' ,
    'cloud-rain' ,
    'face-lying' ,
    'sprinkler' ,
    'building-circle-xmark' ,
    'person-sledding' ,
    'sledding' ,
    'game-console-handheld' ,
    'ship' ,
    'clock-six-thirty' ,
    'battery-slash' ,
    'tugrik-sign' ,
    'arrows-down-to-line' ,
    'download' ,
    'shelves' ,
    'inventory' ,
    'cloud-snow' ,
    'face-grin' ,
    'grin' ,
    'delete-left' ,
    'backspace' ,
    'oven' ,
    'eye-dropper' ,
    'eye-dropper-empty' ,
    'eyedropper' ,
    'comment-captions' ,
    'comments-question' ,
    'scribble' ,
    'rotate-exclamation' ,
    'file-circle-check' ,
    'glass' ,
    'loader' ,
    'forward' ,
    'user-pilot' ,
    'mobile' ,
    'mobile-android' ,
    'mobile-phone' ,
    'code-pull-request-closed' ,
    'face-meh' ,
    'meh' ,
    'align-center' ,
    'book-skull' ,
    'book-dead' ,
    'id-card' ,
    'drivers-license' ,
    'face-dotted' ,
    'face-worried' ,
    'outdent' ,
    'dedent' ,
    'heart-circle-exclamation' ,
    'house' ,
    'home' ,
    'home-alt' ,
    'home-lg-alt' ,
    'vector-circle' ,
    'car-circle-bolt' ,
    'calendar-week' ,
    'flying-disc' ,
    'laptop-medical' ,
    'square-down-right' ,
    'b' ,
    'seat-airline' ,
    'moon-over-sun' ,
    'eclipse-alt' ,
    'pipe' ,
    'file-medical' ,
    'potato' ,
    'dice-one' ,
    'circle-a' ,
    'helmet-battle' ,
    'butter' ,
    'blanket-fire' ,
    'kiwi-bird' ,
    'castle' ,
    'golf-club' ,
    'arrow-right-arrow-left' ,
    'exchange' ,
    'rotate-right' ,
    'redo-alt' ,
    'rotate-forward' ,
    'utensils' ,
    'cutlery' ,
    'arrow-up-wide-short' ,
    'sort-amount-up' ,
    'balloons' ,
    'mill-sign' ,
    'bowl-rice' ,
    'timeline-arrow' ,
    'skull' ,
    'game-board-simple' ,
    'game-board-alt' ,
    'circle-video' ,
    'video-circle' ,
    'chart-scatter-bubble' ,
    'house-turret' ,
    'banana' ,
    'hand-holding-skull' ,
    'people-dress' ,
    'loveseat' ,
    'couch-small' ,
    'tower-broadcast' ,
    'broadcast-tower' ,
    'truck-pickup' ,
    'block-quote' ,
    'up-long' ,
    'long-arrow-alt-up' ,
    'stop' ,
    'code-merge' ,
    'money-check-dollar-pen' ,
    'money-check-edit-alt' ,
    'up-from-line' ,
    'arrow-alt-from-bottom' ,
    'upload' ,
    'hurricane' ,
    'people-pants' ,
    'mound' ,
    'windsock' ,
    'circle-half' ,
    'brake-warning' ,
    'toilet-portable' ,
    'compact-disc' ,
    'file-arrow-down' ,
    'file-download' ,
    'saxophone-fire' ,
    'sax-hot' ,
    'camera-web-slash' ,
    'webcam-slash' ,
    'folder-medical' ,
    'folder-gear' ,
    'folder-cog' ,
    'hand-wave' ,
    'arrow-up-arrow-down' ,
    'sort-up-down' ,
    'caravan' ,
    'shield-cat' ,
    'message-slash' ,
    'comment-alt-slash' ,
    'bolt' ,
    'zap' ,
    'trash-can-check' ,
    'glass-water' ,
    'oil-well' ,
    'person-simple' ,
    'vault' ,
    'mars' ,
    'toilet' ,
    'plane-circle-xmark' ,
    'yen-sign' ,
    'cny' ,
    'jpy' ,
    'rmb' ,
    'yen' ,
    'notes' ,
    'ruble-sign' ,
    'rouble' ,
    'rub' ,
    'ruble' ,
    'trash-undo' ,
    'trash-arrow-turn-left' ,
    'champagne-glass' ,
    'glass-champagne' ,
    'objects-align-center-horizontal' ,
    'sun' ,
    'trash-can-slash' ,
    'trash-alt-slash' ,
    'screen-users' ,
    'users-class' ,
    'guitar' ,
    'square-arrow-left' ,
    'arrow-square-left' ,
    'square-8' ,
    'face-smile-hearts' ,
    'brackets-square' ,
    'brackets' ,
    'laptop-arrow-down' ,
    'hockey-stick-puck' ,
    'house-tree' ,
    'signal-fair' ,
    'signal2' ,
    'face-laugh-wink' ,
    'laugh-wink' ,
    'circle-dollar' ,
    'dollar-circle' ,
    'usd-circle' ,
    'horse-head' ,
    'arrows-repeat' ,
    'repeat-alt' ,
    'bore-hole' ,
    'industry' ,
    'image-polaroid' ,
    'wave-triangle' ,
    'circle-down' ,
    'arrow-alt-circle-down' ,
    'grill' ,
    'arrows-turn-to-dots' ,
    'chart-mixed' ,
    'analytics' ,
    'florin-sign' ,
    'arrow-down-short-wide' ,
    'sort-amount-desc' ,
    'sort-amount-down-alt' ,
    'less-than' ,
    'display-code' ,
    'desktop-code' ,
    'face-drooling' ,
    'oil-temperature' ,
    'oil-temp' ,
    'square-question' ,
    'question-square' ,
    'air-conditioner' ,
    'angle-down' ,
    'mountains' ,
    'omega' ,
    'car-tunnel' ,
    'person-dolly-empty' ,
    'pan-food' ,
    'head-side-cough' ,
    'grip-lines' ,
    'thumbs-down' ,
    'user-lock' ,
    'arrow-right-long' ,
    'long-arrow-right' ,
    'tickets-airline' ,
    'anchor-circle-xmark' ,
    'ellipsis' ,
    'ellipsis-h' ,
    'nfc-slash' ,
    'chess-pawn' ,
    'kit-medical' ,
    'first-aid' ,
    'grid-2-plus' ,
    'bells' ,
    'person-through-window' ,
    'toolbox' ,
    'envelope-dot' ,
    'envelope-badge' ,
    'hands-holding-circle' ,
    'bug' ,
    'bowl-chopsticks' ,
    'credit-card' ,
    'credit-card-alt' ,
    'circle-s' ,
    'box-ballot' ,
    'car' ,
    'automobile' ,
    'hand-holding-hand' ,
    'user-tie-hair' ,
    'podium-star' ,
    'user-hair-mullet' ,
    'business-front' ,
    'party-back' ,
    'trian-balbot' ,
    'microphone-stand' ,
    'book-open-reader' ,
    'book-reader' ,
    'family-dress' ,
    'circle-x' ,
    'cabin' ,
    'mountain-sun' ,
    'chart-simple-horizontal' ,
    'bluetooth' ,
    'arrows-left-right-to-line' ,
    'hand-back-point-left' ,
    'message-dots' ,
    'comment-alt-dots' ,
    'messaging' ,
    'file-heart' ,
    'beer-mug' ,
    'beer-foam' ,
    'dice-d20' ,
    'drone' ,
    'truck-droplet' ,
    'file-circle-xmark' ,
    'temperature-arrow-up' ,
    'temperature-up' ,
    'medal' ,
    'bed' ,
    'book-copy' ,
    'square-h' ,
    'h-square' ,
    'square-c' ,
    'clock-two' ,
    'square-ellipsis-vertical' ,
    'podcast' ,
    'bee' ,
    'temperature-full' ,
    'temperature4' ,
    'thermometer4' ,
    'thermometer-full' ,
    'bell' ,
    'candy-bar' ,
    'chocolate-bar' ,
    'xmark-large' ,
    'pinata' ,
    'arrows-from-line' ,
    'superscript' ,
    'bowl-spoon' ,
    'hexagon-check' ,
    'plug-circle-xmark' ,
    'star-of-life' ,
    'phone-slash' ,
    'traffic-light-stop' ,
    'paint-roller' ,
    'accent-grave' ,
    'handshake-angle' ,
    'hands-helping' ,
    'circle-0' ,
    'dial-med-low' ,
    'location-dot' ,
    'map-marker-alt' ,
    'crab' ,
    'box-open-full' ,
    'box-full' ,
    'file' ,
    'greater-than' ,
    'quotes' ,
    'pretzel' ,
    'person-swimming' ,
    'swimmer' ,
    'arrow-down' ,
    'user-robot-xmarks' ,
    'message-quote' ,
    'comment-alt-quote' ,
    'candy-corn' ,
    'folder-magnifying-glass' ,
    'folder-search' ,
    'notebook' ,
    'droplet' ,
    'tint' ,
    'bullseye-pointer' ,
    'eraser' ,
    'hexagon-image' ,
    'earth-americas' ,
    'earth' ,
    'earth-america' ,
    'globe-americas' ,
    'crate-apple' ,
    'apple-crate' ,
    'person-burst' ,
    'game-board' ,
    'hat-chef' ,
    'hand-back-point-right' ,
    'dove' ,
    'snowflake-droplets' ,
    'battery-empty' ,
    'battery0' ,
    'grid-4' ,
    'socks' ,
    'face-sunglasses' ,
    'inbox' ,
    'square-0' ,
    'section' ,
    'square-this-way-up' ,
    'box-up' ,
    'gauge-high' ,
    'tachometer-alt' ,
    'tachometer-alt-fast' ,
    'square-ampersand' ,
    'envelope-open-text' ,
    'lamp-desk' ,
    'hospital' ,
    'hospital-alt' ,
    'hospital-wide' ,
    'poll-people' ,
    'whiskey-glass-ice' ,
    'glass-whiskey-rocks' ,
    'wine-bottle' ,
    'chess-rook' ,
    'user-bounty-hunter' ,
    'bars-staggered' ,
    'reorder' ,
    'stream' ,
    'diagram-sankey' ,
    'cloud-hail-mixed' ,
    'circle-up-left' ,
    'dharmachakra' ,
    'objects-align-left' ,
    'oil-can-drip' ,
    'face-smiling-hands' ,
    'broccoli' ,
    'route-interstate' ,
    'ear-muffs' ,
    'hotdog' ,
    'transporter-empty' ,
    'person-walking-with-cane' ,
    'blind' ,
    'angle-90' ,
    'rectangle-terminal' ,
    'kite' ,
    'drum' ,
    'scrubber' ,
    'ice-cream' ,
    'heart-circle-bolt' ,
    'fish-bones' ,
    'deer-rudolph' ,
    'fax' ,
    'paragraph' ,
    'head-side-heart' ,
    'square-e' ,
    'meter-fire' ,
    'cloud-hail' ,
    'check-to-slot' ,
    'vote-yea' ,
    'money-from-bracket' ,
    'star-half' ,
    'car-bus' ,
    'speaker' ,
    'timer' ,
    'boxes-stacked' ,
    'boxes' ,
    'boxes-alt' ,
    'grill-hot' ,
    'ballot-check' ,
    'link' ,
    'chain' ,
    'ear-listen' ,
    'assistive-listening-systems' ,
    'file-minus' ,
    'tree-city' ,
    'play' ,
    'font' ,
    'cup-togo' ,
    'coffee-togo' ,
    'square-down-left' ,
    'burger-lettuce' ,
    'rupiah-sign' ,
    'magnifying-glass' ,
    'search' ,
    'table-tennis-paddle-ball' ,
    'ping-pong-paddle-ball' ,
    'table-tennis' ,
    'person-dots-from-line' ,
    'diagnoses' ,
    'chevrons-down' ,
    'chevron-double-down' ,
    'trash-can-arrow-up' ,
    'trash-restore-alt' ,
    'signal-good' ,
    'signal3' ,
    'location-question' ,
    'map-marker-question' ,
    'floppy-disk-circle-xmark' ,
    'floppy-disk-times' ,
    'save-circle-xmark' ,
    'save-times' ,
    'naira-sign' ,
    'peach' ,
    'taxi-bus' ,
    'bracket-curly' ,
    'bracket-curly-left' ,
    'lobster' ,
    'cart-flatbed-empty' ,
    'dolly-flatbed-empty' ,
    'colon' ,
    'cart-arrow-down' ,
    'wand' ,
    'walkie-talkie' ,
    'file-pen' ,
    'file-edit' ,
    'receipt' ,
    'table-picnic' ,
    'square-pen' ,
    'pen-square' ,
    'pencil-square' ,
    'circle-microphone-lines' ,
    'microphone-circle-alt' ,
    'display-slash' ,
    'desktop-slash' ,
    'suitcase-rolling' ,
    'person-circle-exclamation' ,
    'transporter-2' ,
    'hands-holding-diamond' ,
    'hand-receiving' ,
    'money-bill-simple-wave' ,
    'chevron-down' ,
    'battery-full' ,
    'battery' ,
    'battery5' ,
    'bell-plus' ,
    'book-arrow-right' ,
    'hospitals' ,
    'club' ,
    'skull-crossbones' ,
    'droplet-degree' ,
    'dewpoint' ,
    'code-compare' ,
    'list-ul' ,
    'list-dots' ,
    'hand-holding-magic' ,
    'watermelon-slice' ,
    'circle-ellipsis' ,
    'school-lock' ,
    'tower-cell' ,
    'sd-cards' ,
    'down-long' ,
    'long-arrow-alt-down' ,
    'envelopes' ,
    'phone-office' ,
    'ranking-star' ,
    'chess-king' ,
    'nfc-pen' ,
    'person-harassing' ,
    'hat-winter' ,
    'brazilian-real-sign' ,
    'landmark-dome' ,
    'landmark-alt' ,
    'bone-break' ,
    'arrow-up' ,
    'down-from-dotted-line' ,
    'tv' ,
    'television' ,
    'tv-alt' ,
    'border-left' ,
    'circle-divide' ,
    'shrimp' ,
    'list-check' ,
    'tasks' ,
    'diagram-subtask' ,
    'jug-detergent' ,
    'circle-user' ,
    'user-circle' ,
    'square-y' ,
    'user-doctor-hair' ,
    'planet-ringed' ,
    'mushroom' ,
    'user-shield' ,
    'megaphone' ,
    'circle-exclamation-check' ,
    'wind' ,
    'box-dollar' ,
    'box-usd' ,
    'car-burst' ,
    'car-crash' ,
    'y' ,
    'user-headset' ,
    'arrows-retweet' ,
    'retweet-alt' ,
    'person-snowboarding' ,
    'snowboarding' ,
    'square-chevron-right' ,
    'chevron-square-right' ,
    'lacrosse-stick-ball' ,
    'truck-fast' ,
    'shipping-fast' ,
    'user-magnifying-glass' ,
    'star-sharp' ,
    'comment-heart' ,
    'circle-1' ,
    'circle-star' ,
    'star-circle' ,
    'fish' ,
    'cloud-fog' ,
    'fog' ,
    'waffle' ,
    'music-note' ,
    'music-alt' ,
    'hexagon-exclamation' ,
    'cart-shopping-fast' ,
    'object-union' ,
    'user-graduate' ,
    'starfighter' ,
    'circle-half-stroke' ,
    'adjust' ,
    'arrow-right-long-to-line' ,
    'square-arrow-down' ,
    'arrow-square-down' ,
    'diamond-half-stroke' ,
    'clapperboard' ,
    'square-chevron-left' ,
    'chevron-square-left' ,
    'phone-intercom' ,
    'link-horizontal' ,
    'chain-horizontal' ,
    'mango' ,
    'music-note-slash' ,
    'music-alt-slash' ,
    'circle-radiation' ,
    'radiation-alt' ,
    'face-tongue-sweat' ,
    'globe-stand' ,
    'baseball' ,
    'baseball-ball' ,
    'circle-p' ,
    'award-simple' ,
    'jet-fighter-up' ,
    'diagram-project' ,
    'project-diagram' ,
    'pedestal' ,
    'chart-pyramid' ,
    'sidebar' ,
    'snowman-head' ,
    'frosty-head' ,
    'copy' ,
    'burger-glass' ,
    'volume-xmark' ,
    'volume-mute' ,
    'volume-times' ,
    'hand-sparkles' ,
    'bars-filter' ,
    'paintbrush-pencil' ,
    'party-bell' ,
    'user-vneck-hair' ,
    'jack-o-lantern' ,
    'grip' ,
    'grip-horizontal' ,
    'share-from-square' ,
    'share-square' ,
    'keynote' ,
    'child-combatant' ,
    'child-rifle' ,
    'gun' ,
    'square-phone' ,
    'phone-square' ,
    'plus' ,
    'add' ,
    'expand' ,
    'computer' ,
    'fort' ,
    'cloud-check' ,
    'xmark' ,
    'close' ,
    'multiply' ,
    'remove' ,
    'times' ,
    'face-smirking' ,
    'arrows-up-down-left-right' ,
    'arrows' ,
    'chalkboard-user' ,
    'chalkboard-teacher' ,
    'rhombus' ,
    'claw-marks' ,
    'peso-sign' ,
    'face-smile-tongue' ,
    'cart-circle-xmark' ,
    'building-shield' ,
    'circle-phone-flip' ,
    'phone-circle-alt' ,
    'baby' ,
    'users-line' ,
    'quote-left' ,
    'quote-left-alt' ,
    'tractor' ,
    'key-skeleton' ,
    'trash-arrow-up' ,
    'trash-restore' ,
    'arrow-down-up-lock' ,
    'arrow-down-to-bracket' ,
    'lines-leaning' ,
    'square-q' ,
    'ruler-combined' ,
    'symbols' ,
    'icons-alt' ,
    'copyright' ,
    'highlighter-line' ,
    'bracket-square' ,
    'bracket' ,
    'bracket-left' ,
    'island-tropical' ,
    'island-tree-palm' ,
    'arrow-right-from-line' ,
    'arrow-from-left' ,
    'h2' ,
    'equals' ,
    'cake-slice' ,
    'shortcake' ,
    'peanut' ,
    'wrench-simple' ,
    'blender' ,
    'teeth' ,
    'tally-2' ,
    'shekel-sign' ,
    'ils' ,
    'shekel' ,
    'sheqel' ,
    'sheqel-sign' ,
    'cars' ,
    'axe-battle' ,
    'user-hair-long' ,
    'map' ,
    'file-circle-info' ,
    'face-disappointed' ,
    'lasso-sparkles' ,
    'clock-eleven' ,
    'rocket' ,
    'siren-on' ,
    'clock-ten' ,
    'candle-holder' ,
    'video-arrow-down-left' ,
    'photo-film' ,
    'photo-video' ,
    'floppy-disk-circle-arrow-right' ,
    'save-circle-arrow-right' ,
    'folder-minus' ,
    'planet-moon' ,
    'face-eyes-xmarks' ,
    'chart-scatter' ,
    'display-arrow-down' ,
    'store' ,
    'arrow-trend-up' ,
    'plug-circle-minus' ,
    'olive-branch' ,
    'angle' ,
    'vacuum-robot' ,
    'sign-hanging' ,
    'sign' ,
    'square-divide' ,
    'signal-stream-slash' ,
    'bezier-curve' ,
    'eye-dropper-half' ,
    'store-lock' ,
    'bell-slash' ,
    'cloud-bolt-sun' ,
    'thunderstorm-sun' ,
    'camera-slash' ,
    'comment-quote' ,
    'tablet' ,
    'tablet-android' ,
    'school-flag' ,
    'message-code' ,
    'glass-half' ,
    'glass-half-empty' ,
    'glass-half-full' ,
    'fill' ,
    'message-minus' ,
    'comment-alt-minus' ,
    'angle-up' ,
    'drumstick-bite' ,
    'link-horizontal-slash' ,
    'chain-horizontal-slash' ,
    'holly-berry' ,
    'nose' ,
    'chevron-left' ,
    'bacteria' ,
    'clouds' ,
    'money-bill-simple' ,
    'hand-lizard' ,
    'table-pivot' ,
    'filter-slash' ,
    'trash-can-undo' ,
    'trash-can-arrow-turn-left' ,
    'trash-undo-alt' ,
    'notdef' ,
    'disease' ,
    'person-to-door' ,
    'turntable' ,
    'briefcase-medical' ,
    'genderless' ,
    'chevron-right' ,
    'signal-weak' ,
    'signal1' ,
    'clock-five' ,
    'retweet' ,
    'car-rear' ,
    'car-alt' ,
    'pump-soap' ,
    'computer-classic' ,
    'frame' ,
    'video-slash' ,
    'battery-quarter' ,
    'battery2' ,
    'ellipsis-stroke' ,
    'ellipsis-h-alt' ,
    'radio' ,
    'baby-carriage' ,
    'carriage-baby' ,
    'face-expressionless' ,
    'down-to-dotted-line' ,
    'cloud-music' ,
    'traffic-light' ,
    'cloud-minus' ,
    'thermometer' ,
    'shield-minus' ,
    'vr-cardboard' ,
    'car-tilt' ,
    'gauge-circle-minus' ,
    'brightness-low' ,
    'hand-middle-finger' ,
    'percent' ,
    'percentage' ,
    'truck-moving' ,
    'glass-water-droplet' ,
    'conveyor-belt' ,
    'location-check' ,
    'map-marker-check' ,
    'coin-vertical' ,
    'display' ,
    'person-sign' ,
    'face-smile' ,
    'smile' ,
    'phone-hangup' ,
    'signature-slash' ,
    'thumbtack' ,
    'thumb-tack' ,
    'wheat-slash' ,
    'trophy' ,
    'clouds-sun' ,
    'person-praying' ,
    'pray' ,
    'hammer' ,
    'face-vomit' ,
    'speakers' ,
    'tty-answer' ,
    'teletype-answer' ,
    'mug-tea-saucer' ,
    'diagram-lean-canvas' ,
    'alt' ,
    'dial' ,
    'dial-med-high' ,
    'hand-peace' ,
    'circle-trash' ,
    'trash-circle' ,
    'rotate' ,
    'sync-alt' ,
    'circle-quarters' ,
    'spinner' ,
    'tower-control' ,
    'arrow-up-triangle-square' ,
    'sort-shapes-up' ,
    'whale' ,
    'robot' ,
    'peace' ,
    'party-horn' ,
    'gears' ,
    'cogs' ,
    'sun-bright' ,
    'sun-alt' ,
    'warehouse' ,
    'lock-keyhole-open' ,
    'lock-open-alt' ,
    'square-fragile' ,
    'box-fragile' ,
    'square-wine-glass-crack' ,
    'arrow-up-right-dots' ,
    'square-n' ,
    'splotch' ,
    'face-grin-hearts' ,
    'grin-hearts' ,
    'meter' ,
    'mandolin' ,
    'dice-four' ,
    'sim-card' ,
    'transgender' ,
    'transgender-alt' ,
    'mercury' ,
    'up-from-bracket' ,
    'knife-kitchen' ,
    'border-right' ,
    'arrow-turn-down' ,
    'level-down' ,
    'spade' ,
    'card-spade' ,
    'line-columns' ,
    'arrow-right-to-line' ,
    'arrow-to-right' ,
    'person-falling-burst' ,
    'flag-pennant' ,
    'pennant' ,
    'conveyor-belt-empty' ,
    'award' ,
    'ticket-simple' ,
    'ticket-alt' ,
    'building' ,
    'angles-left' ,
    'angle-double-left' ,
    'camcorder' ,
    'video-handheld' ,
    'pancakes' ,
    'album-circle-user' ,
    'qrcode' ,
    'dice-d10' ,
    'fireplace' ,
    'browser' ,
    'pen-paintbrush' ,
    'pencil-paintbrush' ,
    'fish-cooked' ,
    'chair-office' ,
    'nesting-dolls' ,
    'clock-rotate-left' ,
    'history' ,
    'trumpet' ,
    'face-grin-beam-sweat' ,
    'grin-beam-sweat' ,
    'fire-smoke' ,
    'phone-missed' ,
    'file-export' ,
    'arrow-right-from-file' ,
    'shield' ,
    'shield-blank' ,
    'arrow-up-short-wide' ,
    'sort-amount-up-alt' ,
    'arrows-repeat-1' ,
    'repeat1-alt' ,
    'gun-slash' ,
    'avocado' ,
    'binary' ,
    'glasses-round' ,
    'glasses-alt' ,
    'phone-plus' ,
    'ditto' ,
    'person-seat' ,
    'house-medical' ,
    'golf-ball-tee' ,
    'golf-ball' ,
    'circle-chevron-left' ,
    'chevron-circle-left' ,
    'house-chimney-window' ,
    'scythe' ,
    'pen-nib' ,
    'ban-parking' ,
    'parking-circle-slash' ,
    'tent-arrow-turn-left' ,
    'face-diagonal-mouth' ,
    'diagram-cells' ,
    'cricket-bat-ball' ,
    'cricket' ,
    'tents' ,
    'wand-magic' ,
    'magic' ,
    'dog' ,
    'pen-line' ,
    'atom-simple' ,
    'atom-alt' ,
    'ampersand' ,
    'carrot' ,
    'arrow-up-from-line' ,
    'arrow-from-bottom' ,
    'moon' ,
    'pen-slash' ,
    'wine-glass-empty' ,
    'wine-glass-alt' ,
    'square-star' ,
    'cheese' ,
    'send-backward' ,
    'yin-yang' ,
    'music' ,
    'compass-slash' ,
    'clock-one' ,
    'file-music' ,
    'code-commit' ,
    'temperature-low' ,
    'person-biking' ,
    'biking' ,
    'skeleton' ,
    'circle-g' ,
    'circle-arrow-up-left' ,
    'coin-blank' ,
    'broom' ,
    'vacuum' ,
    'shield-heart' ,
    'card-heart' ,
    'lightbulb-cfl-on' ,
    'melon' ,
    'gopuram' ,
    'earth-oceania' ,
    'globe-oceania' ,
    'container-storage' ,
    'face-pouting' ,
    'square-xmark' ,
    'times-square' ,
    'xmark-square' ,
    'face-explode' ,
    'exploding-head' ,
    'hashtag' ,
    'up-right-and-down-left-from-center' ,
    'expand-alt' ,
    'oil-can' ,
    't' ,
    'transformer-bolt' ,
    'hippo' ,
    'chart-column' ,
    'cassette-vhs' ,
    'vhs' ,
    'infinity' ,
    'vial-circle-check' ,
    'chimney' ,
    'object-intersect' ,
    'person-arrow-down-to-line' ,
    'voicemail' ,
    'block-brick' ,
    'wall-brick' ,
    'fan' ,
    'bags-shopping' ,
    'paragraph-left' ,
    'paragraph-rtl' ,
    'person-walking-luggage' ,
    'caravan-simple' ,
    'caravan-alt' ,
    'turtle' ,
    'up-down' ,
    'arrows-alt-v' ,
    'cloud-moon-rain' ,
    'booth-curtain' ,
    'calendar' ,
    'box-heart' ,
    'trailer' ,
    'user-doctor-message' ,
    'user-md-chat' ,
    'bahai' ,
    'haykal' ,
    'amp-guitar' ,
    'sd-card' ,
    'volume-slash' ,
    'border-bottom' ,
    'wifi-weak' ,
    'wifi1' ,
    'dragon' ,
    'shoe-prints' ,
    'circle-plus' ,
    'plus-circle' ,
    'face-grin-tongue-wink' ,
    'grin-tongue-wink' ,
    'hand-holding' ,
    'plug-circle-exclamation' ,
    'link-slash' ,
    'chain-broken' ,
    'chain-slash' ,
    'unlink' ,
    'clone' ,
    'person-walking-arrow-loop-left' ,
    'arrow-up-z-a' ,
    'sort-alpha-up-alt' ,
    'fire-flame-curved' ,
    'fire-alt' ,
    'tornado' ,
    'file-circle-plus' ,
    'delete-right' ,
    'book-quran' ,
    'quran' ,
    'circle-quarter' ,
    'anchor' ,
    'border-all' ,
    'function' ,
    'face-angry' ,
    'angry' ,
    'people-simple' ,
    'cookie-bite' ,
    'arrow-trend-down' ,
    'rss' ,
    'feed' ,
    'face-monocle' ,
    'draw-polygon' ,
    'scale-balanced' ,
    'balance-scale' ,
    'calendar-lines' ,
    'calendar-note' ,
    'arrow-down-big-small' ,
    'sort-size-down' ,
    'gauge-simple-high' ,
    'tachometer' ,
    'tachometer-fast' ,
    'do-not-enter' ,
    'shower' ,
    'dice-d8' ,
    'desktop' ,
    'desktop-alt' ,
    'm' ,
    'grip-dots-vertical' ,
    'face-viewfinder' ,
    'soft-serve' ,
    'creemee' ,
    'h5' ,
    'hand-back-point-down' ,
    'table-list' ,
    'th-list' ,
    'comment-sms' ,
    'sms' ,
    'rectangle' ,
    'rectangle-landscape' ,
    'clipboard-list-check' ,
    'turkey' ,
    'book' ,
    'user-plus' ,
    'ice-skate' ,
    'check' ,
    'battery-three-quarters' ,
    'battery4' ,
    'tomato' ,
    'sword-laser' ,
    'house-circle-check' ,
    'buildings' ,
    'angle-left' ,
    'cart-flatbed-boxes' ,
    'dolly-flatbed-alt' ,
    'diagram-successor' ,
    'truck-arrow-right' ,
    'square-w' ,
    'arrows-split-up-and-left' ,
    'lamp' ,
    'airplay' ,
    'hand-fist' ,
    'fist-raised' ,
    'shield-quartered' ,
    'slash-forward' ,
    'location-pen' ,
    'map-marker-edit' ,
    'cloud-moon' ,
    'pot-food' ,
    'briefcase' ,
    'person-falling' ,
    'image-portrait' ,
    'portrait' ,
    'user-tag' ,
    'rug' ,
    'print-slash' ,
    'earth-europe' ,
    'globe-europe' ,
    'cart-flatbed-suitcase' ,
    'luggage-cart' ,
    'hand-back-point-ribbon' ,
    'rectangle-xmark' ,
    'rectangle-times' ,
    'times-rectangle' ,
    'window-close' ,
    'tire-rugged' ,
    'lightbulb-dollar' ,
    'cowbell' ,
    'baht-sign' ,
    'corner' ,
    'chevrons-right' ,
    'chevron-double-right' ,
    'book-open' ,
    'book-journal-whills' ,
    'journal-whills' ,
    'inhaler' ,
    'handcuffs' ,
    'snake' ,
    'triangle-exclamation' ,
    'exclamation-triangle' ,
    'warning' ,
    'note-medical' ,
    'database' ,
    'down-left' ,
    'share' ,
    'arrow-turn-right' ,
    'mail-forward' ,
    'face-thinking' ,
    'turn-down-right' ,
    'bottle-droplet' ,
    'mask-face' ,
    'hill-rockslide' ,
    'scanner-keyboard' ,
    'circle-o' ,
    'grid-horizontal' ,
    'message-dollar' ,
    'comment-alt-dollar' ,
    'right-left' ,
    'exchange-alt' ,
    'columns-3' ,
    'paper-plane' ,
    'road-circle-exclamation' ,
    'dungeon' ,
    'hand-holding-box' ,
    'input-text' ,
    'window-flip' ,
    'window-alt' ,
    'align-right' ,
    'scanner-gun' ,
    'scanner' ,
    'tire' ,
    'engine' ,
    'money-bill-1-wave' ,
    'money-bill-wave-alt' ,
    'life-ring' ,
    'hands' ,
    'sign-language' ,
    'signing' ,
    'circle-caret-right' ,
    'caret-circle-right' ,
    'wheat' ,
    'file-spreadsheet' ,
    'audio-description-slash' ,
    'calendar-day' ,
    'water-ladder' ,
    'ladder-water' ,
    'swimming-pool' ,
    'arrows-up-down' ,
    'arrows-v' ,
    'chess-pawn-piece' ,
    'chess-pawn-alt' ,
    'face-grimace' ,
    'grimace' ,
    'wheelchair-move' ,
    'wheelchair-alt' ,
    'turn-down' ,
    'level-down-alt' ,
    'square-s' ,
    'rectangle-barcode' ,
    'barcode-alt' ,
    'person-walking-arrow-right' ,
    'square-envelope' ,
    'envelope-square' ,
    'dice' ,
    'unicorn' ,
    'bowling-ball' ,
    'pompebled' ,
    'brain' ,
    'watch-smart' ,
    'book-user' ,
    'sensor-cloud' ,
    'sensor-smoke' ,
    'clapperboard-play' ,
    'bandage' ,
    'band-aid' ,
    'calendar-minus' ,
    'circle-xmark' ,
    'times-circle' ,
    'xmark-circle' ,
    'circle-4' ,
    'gifts' ,
    'album-collection' ,
    'hotel' ,
    'earth-asia' ,
    'globe-asia' ,
    'id-card-clip' ,
    'id-card-alt' ,
    'magnifying-glass-plus' ,
    'search-plus' ,
    'thumbs-up' ,
    'cloud-showers' ,
    'user-clock' ,
    'onion' ,
    'clock-twelve-thirty' ,
    'arrow-down-to-dotted-line' ,
    'hand-dots' ,
    'allergies' ,
    'file-invoice' ,
    'window-minimize' ,
    'rectangle-wide' ,
    'comment-arrow-up' ,
    'garlic' ,
    'mug-saucer' ,
    'coffee' ,
    'brush' ,
    'tree-decorated' ,
    'mask' ,
    'calendar-heart' ,
    'magnifying-glass-minus' ,
    'search-minus' ,
    'flower' ,
    'ruler-vertical' ,
    'user-large' ,
    'user-alt' ,
    'starship-freighter' ,
    'train-tram' ,
    'bridge-suspension' ,
    'trash-check' ,
    'user-nurse' ,
    'boombox' ,
    'syringe' ,
    'cloud-sun' ,
    'shield-exclamation' ,
    'stopwatch-20' ,
    'square-full' ,
    'grip-dots' ,
    'comment-exclamation' ,
    'pen-swirl' ,
    'falafel' ,
    'circle-2' ,
    'magnet' ,
    'jar' ,
    'gramophone' ,
    'dice-d12' ,
    'note-sticky' ,
    'sticky-note' ,
    'down' ,
    'arrow-alt-down' ,
    'hundred-points' ,
    '100' ,
    'paperclip-vertical' ,
    'wind-warning' ,
    'wind-circle-exclamation' ,
    'location-pin-slash' ,
    'map-marker-slash' ,
    'face-sad-sweat' ,
    'bug-slash' ,
    'cupcake' ,
    'light-switch-off' ,
    'toggle-large-off' ,
    'pen-fancy-slash' ,
    'truck-container' ,
    'boot' ,
    'arrow-up-from-water-pump' ,
    'file-check' ,
    'bone' ,
    'cards-blank' ,
    'circle-3' ,
    'bench-tree' ,
    'keyboard-brightness-low' ,
    'ski-boot-ski' ,
    'brain-circuit' ,
    'user-injured' ,
    'block-brick-fire' ,
    'firewall' ,
    'face-sad-tear' ,
    'sad-tear' ,
    'plane' ,
    'tent-arrows-down' ,
    'exclamation' ,
    'arrows-spin' ,
    'face-smile-relaxed' ,
    'comment-xmark' ,
    'comment-times' ,
    'print' ,
    'turkish-lira-sign' ,
    'try' ,
    'turkish-lira' ,
    'face-nose-steam' ,
    'circle-waveform-lines' ,
    'waveform-circle' ,
    'dollar-sign' ,
    'dollar' ,
    'usd' ,
    'ferris-wheel' ,
    'computer-speaker' ,
    'skull-cow' ,
    'x' ,
    'magnifying-glass-dollar' ,
    'search-dollar' ,
    'users-gear' ,
    'users-cog' ,
    'person-military-pointing' ,
    'building-columns' ,
    'bank' ,
    'institution' ,
    'museum' ,
    'university' ,
    'circle-t' ,
    'sack' ,
    'grid-2' ,
    'camera-cctv' ,
    'cctv' ,
    'umbrella' ,
    'trowel' ,
    'horizontal-rule' ,
    'bed-front' ,
    'bed-alt' ,
    'd' ,
    'stapler' ,
    'masks-theater' ,
    'theater-masks' ,
    'kip-sign' ,
    'face-woozy' ,
    'cloud-question' ,
    'pineapple' ,
    'hand-point-left' ,
    'gallery-thumbnails' ,
    'circle-j' ,
    'eyes' ,
    'handshake-simple' ,
    'handshake-alt' ,
    'page-caret-up' ,
    'file-caret-up' ,
    'jet-fighter' ,
    'fighter-jet' ,
    'comet' ,
    'square-share-nodes' ,
    'share-alt-square' ,
    'shield-keyhole' ,
    'barcode' ,
    'plus-minus' ,
    'square-sliders-vertical' ,
    'sliders-v-square' ,
    'video' ,
    'video-camera' ,
    'message-middle' ,
    'comment-middle-alt' ,
    'graduation-cap' ,
    'mortar-board' ,
    'hand-holding-medical' ,
    'person-circle-check' ,
    'square-z' ,
    'message-text' ,
    'comment-alt-text' ,
    'turn-up' ,
    'level-up-alt' ,
    '0' ,
    '1' ,
    '2' ,
    '3' ,
    '4' ,
    '5' ,
    '6' ,
    '7' ,
    '8' ,
    '9' ,
    'fill-drip' ,
    'arrows-to-circle' ,
    'circle-chevron-right' ,
    'chevron-circle-right' ,
    'wagon-covered' ,
    'line-height' ,
    'bagel' ,
    'transporter-7' ,
    'at' ,
    'rectangles-mixed' ,
    'phone-arrow-up-right' ,
    'phone-arrow-up' ,
    'phone-outgoing' ,
    'trash-can' ,
    'trash-alt' ,
    'circle-l' ,
    'head-side-goggles' ,
    'head-vr' ,
    'text-height' ,
    'user-xmark' ,
    'user-times' ,
    'face-hand-yawn' ,
    'gauge-simple-min' ,
    'tachometer-slowest' ,
    'stethoscope' ,
    'coffin' ,
    'message' ,
    'comment-alt' ,
    'salad' ,
    'bowl-salad' ,
    'info' ,
    'robot-astromech' ,
    'ring-diamond' ,
    'fondue-pot' ,
    'theta' ,
    'face-hand-peeking' ,
    'square-user' ,
    'down-left-and-up-right-to-center' ,
    'compress-alt' ,
    'explosion' ,
    'file-lines' ,
    'file-alt' ,
    'file-text' ,
    'wave-square' ,
    'ring' ,
    'building-un' ,
    'dice-three' ,
    'tire-pressure-warning' ,
    'wifi-fair' ,
    'wifi2' ,
    'calendar-days' ,
    'calendar-alt' ,
    'mp3-player' ,
    'anchor-circle-check' ,
    'tally-4' ,
    'rectangle-history' ,
    'building-circle-arrow-right' ,
    'volleyball' ,
    'volleyball-ball' ,
    'sun-haze' ,
    'text-size' ,
    'ufo' ,
    'fork' ,
    'utensil-fork' ,
    'arrows-up-to-line' ,
    'mobile-signal' ,
    'barcode-scan' ,
    'sort-down' ,
    'sort-desc' ,
    'folder-arrow-down' ,
    'folder-download' ,
    'circle-minus' ,
    'minus-circle' ,
    'face-icicles' ,
    'shovel' ,
    'door-open' ,
    'films' ,
    'right-from-bracket' ,
    'sign-out-alt' ,
    'face-glasses' ,
    'nfc' ,
    'atom' ,
    'soap' ,
    'icons' ,
    'heart-music-camera-bolt' ,
    'microphone-lines-slash' ,
    'microphone-alt-slash' ,
    'closed-captioning-slash' ,
    'calculator-simple' ,
    'calculator-alt' ,
    'bridge-circle-check' ,
    'sliders-up' ,
    'sliders-v' ,
    'location-minus' ,
    'map-marker-minus' ,
    'pump-medical' ,
    'fingerprint' ,
    'ski-boot' ,
    'standard-definition' ,
    'rectangle-sd' ,
    'h1' ,
    'hand-point-right' ,
    'magnifying-glass-location' ,
    'search-location' ,
    'message-bot' ,
    'forward-step' ,
    'step-forward' ,
    'face-smile-beam' ,
    'smile-beam' ,
    'light-ceiling' ,
    'message-exclamation' ,
    'comment-alt-exclamation' ,
    'bowl-scoop' ,
    'bowl-shaved-ice' ,
    'square-x' ,
    'utility-pole-double' ,
    'flag-checkered' ,
    'chevrons-up' ,
    'chevron-double-up' ,
    'football' ,
    'football-ball' ,
    'user-vneck' ,
    'school-circle-exclamation' ,
    'crop' ,
    'angles-down' ,
    'angle-double-down' ,
    'users-rectangle' ,
    'people-roof' ,
    'square-arrow-right' ,
    'arrow-square-right' ,
    'location-plus' ,
    'map-marker-plus' ,
    'lightbulb-exclamation-on' ,
    'people-line' ,
    'beer-mug-empty' ,
    'beer' ,
    'crate-empty' ,
    'diagram-predecessor' ,
    'transporter' ,
    'calendar-circle-user' ,
    'arrow-up-long' ,
    'long-arrow-up' ,
    'person-carry-box' ,
    'person-carry' ,
    'fire-flame-simple' ,
    'burn' ,
    'person' ,
    'male' ,
    'laptop' ,
    'file-csv' ,
    'menorah' ,
    'union' ,
    'chevrons-left' ,
    'chevron-double-left' ,
    'circle-heart' ,
    'heart-circle' ,
    'truck-plane' ,
    'record-vinyl' ,
    'bring-forward' ,
    'square-p' ,
    'face-grin-stars' ,
    'grin-stars' ,
    'sigma' ,
    'camera-movie' ,
    'bong' ,
    'clarinet' ,
    'truck-flatbed' ,
    'spaghetti-monster-flying' ,
    'pastafarianism' ,
    'arrow-down-up-across-line' ,
    'leaf-heart' ,
    'house-building' ,
    'cheese-swiss' ,
    'spoon' ,
    'utensil-spoon' ,
    'jar-wheat' ,
    'envelopes-bulk' ,
    'mail-bulk' ,
    'file-circle-exclamation' ,
    'bow-arrow' ,
    'cart-xmark' ,
    'hexagon-xmark' ,
    'times-hexagon' ,
    'xmark-hexagon' ,
    'circle-h' ,
    'hospital-symbol' ,
    'merge' ,
    'pager' ,
    'cart-minus' ,
    'address-book' ,
    'contact-book' ,
    'pan-frying' ,
    'grid' ,
    'grid3' ,
    'football-helmet' ,
    'hand-love' ,
    'trees' ,
    'strikethrough' ,
    'page' ,
    'k' ,
    'diagram-previous' ,
    'gauge-min' ,
    'tachometer-alt-slowest' ,
    'folder-grid' ,
    'eggplant' ,
    'ram' ,
    'landmark-flag' ,
    'lips' ,
    'pencil' ,
    'pencil-alt' ,
    'backward' ,
    'caret-right' ,
    'comments' ,
    'paste' ,
    'file-clipboard' ,
    'desktop-arrow-down' ,
    'code-pull-request' ,
    'pumpkin' ,
    'clipboard-list' ,
    'pen-field' ,
    'blueberries' ,
    'truck-ramp-box' ,
    'truck-loading' ,
    'note' ,
    'arrow-down-to-square' ,
    'user-check' ,
    'cloud-xmark' ,
    'vial-virus' ,
    'book-blank' ,
    'book-alt' ,
    'golf-flag-hole' ,
    'message-arrow-down' ,
    'comment-alt-arrow-down' ,
    'face-unamused' ,
    'sheet-plastic' ,
    'circle-9' ,
    'blog' ,
    'user-ninja' ,
    'pencil-slash' ,
    'bowling-pins' ,
    'person-arrow-up-from-line' ,
    'down-right' ,
    'scroll-torah' ,
    'torah' ,
    'blinds-open' ,
    'fence' ,
    'up' ,
    'arrow-alt-up' ,
    'broom-ball' ,
    'quidditch' ,
    'quidditch-broom-ball' ,
    'drumstick' ,
    'square-v' ,
    'face-awesome' ,
    'gave-dandy' ,
    'dial-off' ,
    'toggle-off' ,
    'face-smile-horns' ,
    'box-archive' ,
    'archive' ,
    'grapes' ,
    'person-drowning' ,
    'dial-max' ,
    'circle-m' ,
    'calendar-image' ,
    'circle-caret-down' ,
    'caret-circle-down' ,
    'arrow-down-9-1' ,
    'sort-numeric-desc' ,
    'sort-numeric-down-alt' ,
    'face-grin-tongue-squint' ,
    'grin-tongue-squint' ,
    'shish-kebab' ,
    'spray-can' ,
    'alarm-snooze' ,
    'scarecrow' ,
    'truck-monster' ,
    'gift-card' ,
    'w' ,
    'code-pull-request-draft' ,
    'square-b' ,
    'elephant' ,
    'earth-africa' ,
    'globe-africa' ,
    'rainbow' ,
    'circle-notch' ,
    'tablet-screen-button' ,
    'tablet-alt' ,
    'paw' ,
    'message-question' ,
    'cloud' ,
    'trowel-bricks' ,
    'square-3' ,
    'face-flushed' ,
    'flushed' ,
    'hospital-user' ,
    'microwave' ,
    'tent-arrow-left-right' ,
    'cart-circle-arrow-up' ,
    'trash-clock' ,
    'gavel' ,
    'legal' ,
    'sprinkler-ceiling' ,
    'browsers' ,
    'trillium' ,
    'music-slash' ,
    'truck-ramp' ,
    'binoculars' ,
    'microphone-slash' ,
    'box-tissue' ,
    'circle-c' ,
    'star-christmas' ,
    'chart-bullet' ,
    'motorcycle' ,
    'tree-christmas' ,
    'tire-flat' ,
    'sunglasses' ,
    'badge' ,
    'message-pen' ,
    'comment-alt-edit' ,
    'message-edit' ,
    'bell-concierge' ,
    'concierge-bell' ,
    'pen-ruler' ,
    'pencil-ruler' ,
    'chess-rook-piece' ,
    'chess-rook-alt' ,
    'square-root' ,
    'album-collection-circle-plus' ,
    'people-arrows' ,
    'people-arrows-left-right' ,
    'face-angry-horns' ,
    'mars-and-venus-burst' ,
    'tombstone' ,
    'square-caret-right' ,
    'caret-square-right' ,
    'scissors' ,
    'cut' ,
    'list-music' ,
    'sun-plant-wilt' ,
    'toilets-portable' ,
    'hockey-puck' ,
    'mustache' ,
    'hyphen' ,
    'table' ,
    'user-chef' ,
    'message-image' ,
    'comment-alt-image' ,
    'users-medical' ,
    'sensor-triangle-exclamation' ,
    'sensor-alert' ,
    'magnifying-glass-arrow-right' ,
    'tachograph-digital' ,
    'digital-tachograph' ,
    'face-mask' ,
    'pickleball' ,
    'star-sharp-half' ,
    'users-slash' ,
    'clover' ,
    'meat' ,
    'reply' ,
    'mail-reply' ,
    'star-and-crescent' ,
    'empty-set' ,
    'house-fire' ,
    'square-minus' ,
    'minus-square' ,
    'helicopter' ,
    'bird' ,
    'compass' ,
    'square-caret-down' ,
    'caret-square-down' ,
    'heart-half-stroke' ,
    'heart-half-alt' ,
    'file-circle-question' ,
    'laptop-code' ,
    'joystick' ,
    'grill-fire' ,
    'rectangle-vertical-history' ,
    'swatchbook' ,
    'prescription-bottle' ,
    'bars' ,
    'navicon' ,
    'keyboard-left' ,
    'people-group' ,
    'hourglass-end' ,
    'hourglass3' ,
    'heart-crack' ,
    'heart-broken' ,
    'face-beam-hand-over-mouth' ,
    'droplet-percent' ,
    'humidity' ,
    'square-up-right' ,
    'external-link-square-alt' ,
    'face-kiss-beam' ,
    'kiss-beam' ,
    'corn' ,
    'roller-coaster' ,
    'photo-film-music' ,
    'radar' ,
    'sickle' ,
    'film' ,
    'coconut' ,
    'ruler-horizontal' ,
    'shield-cross' ,
    'cassette-tape' ,
    'square-terminal' ,
    'people-robbery' ,
    'lightbulb' ,
    'caret-left' ,
    'comment-middle' ,
    'trash-can-list' ,
    'block' ,
    'circle-exclamation' ,
    'exclamation-circle' ,
    'school-circle-xmark' ,
    'arrow-right-from-bracket' ,
    'sign-out' ,
    'face-frown-slight' ,
    'circle-chevron-down' ,
    'chevron-circle-down' ,
    'sidebar-flip' ,
    'unlock-keyhole' ,
    'unlock-alt' ,
    'temperature-list' ,
    'cloud-showers-heavy' ,
    'headphones-simple' ,
    'headphones-alt' ,
    'sitemap' ,
    'pipe-section' ,
    'space-station-moon-construction' ,
    'space-station-moon-alt' ,
    'circle-dollar-to-slot' ,
    'donate' ,
    'memory' ,
    'face-sleeping' ,
    'road-spikes' ,
    'fire-burner' ,
    'squirrel' ,
    'arrow-up-to-line' ,
    'arrow-to-top' ,
    'flag' ,
    'face-cowboy-hat' ,
    'hanukiah' ,
    'chart-scatter-3d' ,
    'square-code' ,
    'feather' ,
    'volume-low' ,
    'volume-down' ,
    'xmark-to-slot' ,
    'times-to-slot' ,
    'vote-nay' ,
    'box-taped' ,
    'box-alt' ,
    'comment-slash' ,
    'swords' ,
    'cloud-sun-rain' ,
    'album' ,
    'circle-n' ,
    'compress' ,
    'wheat-awn' ,
    'wheat-alt' ,
    'ankh' ,
    'hands-holding-child' ,
    'asterisk' ,
    'key-skeleton-left-right' ,
    'comment-lines' ,
    'luchador-mask' ,
    'luchador' ,
    'mask-luchador' ,
    'square-check' ,
    'check-square' ,
    'shredder' ,
    'book-open-cover' ,
    'book-open-alt' ,
    'sandwich' ,
    'peseta-sign' ,
    'square-parking-slash' ,
    'parking-slash' ,
    'train-tunnel' ,
    'heading' ,
    'header' ,
    'ghost' ,
    'face-anguished' ,
    'hockey-sticks' ,
    'abacus' ,
    'film-simple' ,
    'film-alt' ,
    'list' ,
    'list-squares' ,
    'tree-palm' ,
    'square-phone-flip' ,
    'phone-square-alt' ,
    'cart-plus' ,
    'gamepad' ,
    'border-center-v' ,
    'circle-dot' ,
    'dot-circle' ,
    'clipboard-medical' ,
    'face-dizzy' ,
    'dizzy' ,
    'egg' ,
    'up-to-line' ,
    'arrow-alt-to-top' ,
    'house-medical-circle-xmark' ,
    'watch-fitness' ,
    'clock-nine-thirty' ,
    'campground' ,
    'folder-plus' ,
    'jug' ,
    'futbol' ,
    'futbol-ball' ,
    'soccer-ball' ,
    'snow-blowing' ,
    'paintbrush' ,
    'paint-brush' ,
    'lock' ,
    'arrow-down-from-line' ,
    'arrow-from-top' ,
    'gas-pump' ,
    'signal-bars-slash' ,
    'signal-alt-slash' ,
    'monkey' ,
    'rectangle-pro' ,
    'pro' ,
    'house-night' ,
    'hot-tub-person' ,
    'hot-tub' ,
    'blanket' ,
    'map-location' ,
    'map-marked' ,
    'house-flood-water' ,
    'comments-question-check' ,
    'tree' ,
    'arrows-cross' ,
    'backpack' ,
    'square-small' ,
    'folder-arrow-up' ,
    'folder-upload' ,
    'bridge-lock' ,
    'crosshairs-simple' ,
    'sack-dollar' ,
    'pen-to-square' ,
    'edit' ,
    'square-sliders' ,
    'sliders-h-square' ,
    'car-side' ,
    'message-middle-top' ,
    'comment-middle-top-alt' ,
    'lightbulb-on' ,
    'knife' ,
    'utensil-knife' ,
    'share-nodes' ,
    'share-alt' ,
    'wave-sine' ,
    'heart-circle-minus' ,
    'circle-w' ,
    'circle-calendar' ,
    'calendar-circle' ,
    'hourglass-half' ,
    'hourglass2' ,
    'microscope' ,
    'sunset' ,
    'sink' ,
    'calendar-exclamation' ,
    'truck-container-empty' ,
    'hand-heart' ,
    'bag-shopping' ,
    'shopping-bag' ,
    'arrow-down-z-a' ,
    'sort-alpha-desc' ,
    'sort-alpha-down-alt' ,
    'mitten' ,
    'reply-clock' ,
    'reply-time' ,
    'person-rays' ,
    'right' ,
    'arrow-alt-right' ,
    'circle-f' ,
    'users' ,
    'face-pleading' ,
    'eye-slash' ,
    'flask-vial' ,
    'police-box' ,
    'cucumber' ,
    'head-side-brain' ,
    'hand' ,
    'hand-paper' ,
    'person-biking-mountain' ,
    'biking-mountain' ,
    'utensils-slash' ,
    'print-magnifying-glass' ,
    'print-search' ,
    'folder-bookmark' ,
    'om' ,
    'pi' ,
    'flask-round-potion' ,
    'flask-potion' ,
    'face-shush' ,
    'worm' ,
    'house-circle-xmark' ,
    'plug' ,
    'calendar-circle-exclamation' ,
    'square-i' ,
    'chevron-up' ,
    'face-saluting' ,
    'gauge-simple-low' ,
    'tachometer-slow' ,
    'face-persevering' ,
    'circle-camera' ,
    'camera-circle' ,
    'hand-spock' ,
    'spider-web' ,
    'circle-microphone' ,
    'microphone-circle' ,
    'book-arrow-up' ,
    'popsicle' ,
    'command' ,
    'blinds' ,
    'stopwatch' ,
    'saxophone' ,
    'square-2' ,
    'field-hockey-stick-ball' ,
    'field-hockey' ,
    'arrow-up-square-triangle' ,
    'sort-shapes-up-alt' ,
    'face-scream' ,
    'square-m' ,
    'camera-web' ,
    'webcam' ,
    'comment-arrow-down' ,
    'lightbulb-cfl' ,
    'window-frame-open' ,
    'face-kiss' ,
    'kiss' ,
    'bridge-circle-xmark' ,
    'period' ,
    'face-grin-tongue' ,
    'grin-tongue' ,
    'up-to-dotted-line' ,
    'thought-bubble' ,
    'raygun' ,
    'flute' ,
    'acorn' ,
    'video-arrow-up-right' ,
    'grate-droplet' ,
    'seal-exclamation' ,
    'chess-bishop' ,
    'message-sms' ,
    'coffee-beans' ,
    'hat-witch' ,
    'face-grin-wink' ,
    'grin-wink' ,
    'clock-three-thirty' ,
    'ear-deaf' ,
    'deaf' ,
    'deafness' ,
    'hard-of-hearing' ,
    'alarm-clock' ,
    'eclipse' ,
    'face-relieved' ,
    'road-circle-check' ,
    'dice-five' ,
    'octagon-minus' ,
    'minus-octagon' ,
    'square-rss' ,
    'rss-square' ,
    'face-zany' ,
    'tricycle' ,
    'land-mine-on' ,
    'square-arrow-up-left' ,
    'i-cursor' ,
    'salt-shaker' ,
    'stamp' ,
    'file-plus' ,
    'draw-square' ,
    'toilet-paper-under-slash' ,
    'toilet-paper-reverse-slash' ,
    'stairs' ,
    'drone-front' ,
    'drone-alt' ,
    'glass-empty' ,
    'dial-high' ,
    'user-helmet-safety' ,
    'user-construction' ,
    'user-hard-hat' ,
    'i' ,
    'hryvnia-sign' ,
    'hryvnia' ,
    'arrow-down-left-and-arrow-up-right-to-center' ,
    'pills' ,
    'face-grin-wide' ,
    'grin-alt' ,
    'tooth' ,
    'basketball-hoop' ,
    'objects-align-bottom' ,
    'v' ,
    'sparkles' ,
    'squid' ,
    'leafy-green' ,
    'circle-arrow-up-right' ,
    'calendars' ,
    'bangladeshi-taka-sign' ,
    'bicycle' ,
    'hammer-war' ,
    'circle-d' ,
    'spider-black-widow' ,
    'staff-snake' ,
    'rod-asclepius' ,
    'rod-snake' ,
    'staff-aesculapius' ,
    'pear' ,
    'head-side-cough-slash' ,
    'triangle' ,
    'apartment' ,
    'truck-medical' ,
    'ambulance' ,
    'pepper' ,
    'piano' ,
    'gun-squirt' ,
    'wheat-awn-circle-exclamation' ,
    'snowman' ,
    'user-alien' ,
    'shield-check' ,
    'mortar-pestle' ,
    'road-barrier' ,
    'chart-candlestick' ,
    'briefcase-blank' ,
    'school' ,
    'igloo' ,
    'bracket-round' ,
    'parenthesis' ,
    'joint' ,
    'horse-saddle' ,
    'mug-marshmallows' ,
    'filters' ,
    'bell-on' ,
    'angle-right' ,
    'dial-med' ,
    'horse' ,
    'q' ,
    'monitor-waveform' ,
    'monitor-heart-rate' ,
    'link-simple' ,
    'whistle' ,
    'g' ,
    'wine-glass-crack' ,
    'fragile' ,
    'slot-machine' ,
    'notes-medical' ,
    'car-wash' ,
    'escalator' ,
    'comment-image' ,
    'temperature-half' ,
    'temperature2' ,
    'thermometer2' ,
    'thermometer-half' ,
    'dong-sign' ,
    'donut' ,
    'doughnut' ,
    'capsules' ,
    'poo-storm' ,
    'poo-bolt' ,
    'tally-1' ,
    'face-frown-open' ,
    'frown-open' ,
    'square-dashed' ,
    'square-j' ,
    'hand-point-up' ,
    'money-bill' ,
    'arrow-up-big-small' ,
    'sort-size-up' ,
    'barcode-read' ,
    'baguette' ,
    'bowl-soft-serve' ,
    'face-holding-back-tears' ,
    'square-up' ,
    'arrow-alt-square-up' ,
    'train-subway-tunnel' ,
    'subway-tunnel' ,
    'square-exclamation' ,
    'exclamation-square' ,
    'semicolon' ,
    'bookmark' ,
    'fan-table' ,
    'align-justify' ,
    'battery-low' ,
    'battery1' ,
    'credit-card-front' ,
    'brain-arrow-curved-right' ,
    'mind-share' ,
    'umbrella-beach' ,
    'helmet-un' ,
    'location-smile' ,
    'map-marker-smile' ,
    'arrow-left-to-line' ,
    'arrow-to-left' ,
    'bullseye' ,
    'sushi' ,
    'nigiri' ,
    'message-captions' ,
    'comment-alt-captions' ,
    'trash-list' ,
    'bacon' ,
    'option' ,
    'hand-point-down' ,
    'arrow-up-from-bracket' ,
    'trash-plus' ,
    'objects-align-top' ,
    'folder' ,
    'folder-blank' ,
    'face-anxious-sweat' ,
    'credit-card-blank' ,
    'file-waveform' ,
    'file-medical-alt' ,
    'microchip-ai' ,
    'mug' ,
    'plane-up-slash' ,
    'radiation' ,
    'pen-circle' ,
    'chart-simple' ,
    'crutches' ,
    'circle-parking' ,
    'parking-circle' ,
    'mars-stroke' ,
    'leaf-oak' ,
    'square-bolt' ,
    'vial' ,
    'gauge' ,
    'dashboard' ,
    'gauge-med' ,
    'tachometer-alt-average' ,
    'wand-magic-sparkles' ,
    'magic-wand-sparkles' ,
    'lambda' ,
    'e' ,
    'pizza' ,
    'bowl-chopsticks-noodles' ,
    'h3' ,
    'pen-clip' ,
    'pen-alt' ,
    'bridge-circle-exclamation' ,
    'badge-percent' ,
    'user' ,
    'sensor' ,
    'comma' ,
    'school-circle-check' ,
    'toilet-paper-under' ,
    'toilet-paper-reverse' ,
    'light-emergency' ,
    'arrow-down-to-arc' ,
    'dumpster' ,
    'van-shuttle' ,
    'shuttle-van' ,
    'building-user' ,
    'light-switch' ,
    'square-caret-left' ,
    'caret-square-left' ,
    'highlighter' ,
    'wave-pulse' ,
    'heart-rate' ,
    'key' ,
    'hat-santa' ,
    'tamale' ,
    'box-check' ,
    'bullhorn' ,
    'steak' ,
    'location-crosshairs-slash' ,
    'location-slash' ,
    'person-dolly' ,
    'globe' ,
    'synagogue' ,
    'file-chart-column' ,
    'file-chart-line' ,
    'person-half-dress' ,
    'folder-image' ,
    'calendar-pen' ,
    'calendar-edit' ,
    'road-bridge' ,
    'face-smile-tear' ,
    'message-plus' ,
    'comment-alt-plus' ,
    'location-arrow' ,
    'c' ,
    'tablet-button' ,
    'rectangle-history-circle-user' ,
    'building-lock' ,
    'chart-line-up' ,
    'mailbox' ,
    'truck-bolt' ,
    'pizza-slice' ,
    'money-bill-wave' ,
    'chart-area' ,
    'area-chart' ,
    'house-flag' ,
    'person-circle-minus' ,
    'scalpel' ,
    'ban' ,
    'cancel' ,
    'bell-exclamation' ,
    'circle-bookmark' ,
    'bookmark-circle' ,
    'egg-fried' ,
    'face-weary' ,
    'uniform-martial-arts' ,
    'camera-rotate' ,
    'sun-dust' ,
    'comment-text' ,
    'spray-can-sparkles' ,
    'air-freshener' ,
    'signal-bars' ,
    'signal-alt' ,
    'signal-alt4' ,
    'signal-bars-strong' ,
    'diamond-exclamation' ,
    'star' ,
    'dial-min' ,
    'repeat' ,
    'cross' ,
    'page-caret-down' ,
    'file-caret-down' ,
    'box' ,
    'venus-mars' ,
    'clock-seven-thirty' ,
    'arrow-pointer' ,
    'mouse-pointer' ,
    'clock-four-thirty' ,
    'signal-bars-good' ,
    'signal-alt3' ,
    'cactus' ,
    'maximize' ,
    'expand-arrows-alt' ,
    'charging-station' ,
    'shapes' ,
    'triangle-circle-square' ,
    'plane-tail' ,
    'gauge-simple-max' ,
    'tachometer-fastest' ,
    'circle-u' ,
    'shield-slash' ,
    'square-phone-hangup' ,
    'phone-square-down' ,
    'arrow-up-left' ,
    'transporter-1' ,
    'peanuts' ,
    'shuffle' ,
    'random' ,
    'person-running' ,
    'running' ,
    'mobile-retro' ,
    'grip-lines-vertical' ,
    'arrow-up-from-square' ,
    'file-dashed-line' ,
    'page-break' ,
    'bracket-curly-right' ,
    'spider' ,
    'clock-three' ,
    'hands-bound' ,
    'scalpel-line-dashed' ,
    'scalpel-path' ,
    'file-invoice-dollar' ,
    'pipe-smoking' ,
    'face-astonished' ,
    'window' ,
    'plane-circle-exclamation' ,
    'ear' ,
    'file-lock' ,
    'diagram-venn' ,
    'x-ray' ,
    'goal-net' ,
    'coffin-cross' ,
    'spell-check' ,
    'location-xmark' ,
    'map-marker-times' ,
    'map-marker-xmark' ,
    'lasso' ,
    'slash' ,
    'person-to-portal' ,
    'portal-enter' ,
    'calendar-star' ,
    'computer-mouse' ,
    'mouse' ,
    'arrow-right-to-bracket' ,
    'sign-in' ,
    'pegasus' ,
    'files-medical' ,
    'nfc-lock' ,
    'person-ski-lift' ,
    'ski-lift' ,
    'square-6' ,
    'shop-slash' ,
    'store-alt-slash' ,
    'wind-turbine' ,
    'sliders-simple' ,
    'badge-sheriff' ,
    'server' ,
    'virus-covid-slash' ,
    'intersection' ,
    'shop-lock' ,
    'family' ,
    'hourglass-start' ,
    'hourglass1' ,
    'user-hair-buns' ,
    'blender-phone' ,
    'hourglass-clock' ,
    'person-seat-reclined' ,
    'paper-plane-top' ,
    'paper-plane-alt' ,
    'send' ,
    'message-arrow-up' ,
    'comment-alt-arrow-up' ,
    'lightbulb-exclamation' ,
    'layer-minus' ,
    'layer-group-minus' ,
    'circle-e' ,
    'building-wheat' ,
    'gauge-max' ,
    'tachometer-alt-fastest' ,
    'person-breastfeeding' ,
    'apostrophe' ,
    'fire-hydrant' ,
    'right-to-bracket' ,
    'sign-in-alt' ,
    'video-plus' ,
    'square-right' ,
    'arrow-alt-square-right' ,
    'comment-smile' ,
    'venus' ,
    'passport' ,
    'inbox-in' ,
    'inbox-arrow-down' ,
    'heart-pulse' ,
    'heartbeat' ,
    'circle-8' ,
    'clouds-moon' ,
    'clock-ten-thirty' ,
    'people-carry-box' ,
    'people-carry' ,
    'folder-user' ,
    'trash-can-xmark' ,
    'temperature-high' ,
    'microchip' ,
    'left-long-to-line' ,
    'crown' ,
    'weight-hanging' ,
    'xmarks-lines' ,
    'file-prescription' ,
    'calendar-range' ,
    'flower-daffodil' ,
    'hand-back-point-up' ,
    'weight-scale' ,
    'weight' ,
    'star-exclamation' ,
    'books' ,
    'user-group' ,
    'user-friends' ,
    'arrow-up-a-z' ,
    'sort-alpha-up' ,
    'layer-plus' ,
    'layer-group-plus' ,
    'play-pause' ,
    'block-question' ,
    'snooze' ,
    'zzz' ,
    'scanner-image' ,
    'tv-retro' ,
    'square-t' ,
    'farm' ,
    'barn-silo' ,
    'chess-knight' ,
    'bars-sort' ,
    'pallet-boxes' ,
    'palette-boxes' ,
    'pallet-alt' ,
    'face-laugh-squint' ,
    'laugh-squint' ,
    'code-simple' ,
    'bolt-slash' ,
    'panel-fire' ,
    'binary-circle-check' ,
    'comment-minus' ,
    'burrito' ,
    'violin' ,
    'objects-column' ,
    'square-chevron-down' ,
    'chevron-square-down' ,
    'comment-plus' ,
    'triangle-instrument' ,
    'triangle-music' ,
    'wheelchair' ,
    'user-pilot-tie' ,
    'piano-keyboard' ,
    'bed-empty' ,
    'circle-arrow-up' ,
    'arrow-circle-up' ,
    'toggle-on' ,
    'rectangle-vertical' ,
    'rectangle-portrait' ,
    'person-walking' ,
    'walking' ,
    'l' ,
    'signal-stream' ,
    'down-to-bracket' ,
    'circle-z' ,
    'stars' ,
    'fire' ,
    'bed-pulse' ,
    'procedures' ,
    'house-day' ,
    'shuttle-space' ,
    'space-shuttle' ,
    'shirt-long-sleeve' ,
    'chart-pie-simple' ,
    'chart-pie-alt' ,
    'face-laugh' ,
    'laugh' ,
    'folder-open' ,
    'album-collection-circle-user' ,
    'candy' ,
    'bowl-hot' ,
    'soup' ,
    'flatbread' ,
    'heart-circle-plus' ,
    'code-fork' ,
    'city' ,
    'signal-bars-weak' ,
    'signal-alt1' ,
    'microphone-lines' ,
    'microphone-alt' ,
    'clock-twelve' ,
    'pepper-hot' ,
    'citrus-slice' ,
    'sheep' ,
    'unlock' ,
    'colon-sign' ,
    'headset' ,
    'badger-honey' ,
    'h4' ,
    'store-slash' ,
    'road-circle-xmark' ,
    'signal-slash' ,
    'user-minus' ,
    'mars-stroke-up' ,
    'mars-stroke-v' ,
    'champagne-glasses' ,
    'glass-cheers' ,
    'taco' ,
    'hexagon-plus' ,
    'plus-hexagon' ,
    'clipboard' ,
    'house-circle-exclamation' ,
    'file-arrow-up' ,
    'file-upload' ,
    'wifi' ,
    'wifi3' ,
    'wifi-strong' ,
    'messages' ,
    'comments-alt' ,
    'bath' ,
    'bathtub' ,
    'umbrella-simple' ,
    'umbrella-alt' ,
    'rectangle-history-circle-plus' ,
    'underline' ,
    'prescription-bottle-pill' ,
    'user-pen' ,
    'user-edit' ,
    'binary-slash' ,
    'square-o' ,
    'signature' ,
    'stroopwafel' ,
    'bold' ,
    'anchor-lock' ,
    'building-ngo' ,
    'transporter-3' ,
    'engine-warning' ,
    'engine-exclamation' ,
    'circle-down-right' ,
    'square-k' ,
    'manat-sign' ,
    'money-check-pen' ,
    'money-check-edit' ,
    'not-equal' ,
    'border-top-left' ,
    'border-style' ,
    'map-location-dot' ,
    'map-marked-alt' ,
    'tilde' ,
    'jedi' ,
    'square-poll-vertical' ,
    'poll' ,
    'arrow-down-square-triangle' ,
    'sort-shapes-down-alt' ,
    'mug-hot' ,
    'dog-leashed' ,
    'car-battery' ,
    'battery-car' ,
    'face-downcast-sweat' ,
    'mailbox-flag-up' ,
    'memo-circle-info' ,
    'gift' ,
    'dice-two' ,
    'volume' ,
    'volume-medium' ,
    'transporter-5' ,
    'gauge-circle-bolt' ,
    'coin-front' ,
    'file-slash' ,
    'message-arrow-up-right' ,
    'treasure-chest' ,
    'chess-queen' ,
    'paintbrush-fine' ,
    'paint-brush-alt' ,
    'paint-brush-fine' ,
    'paintbrush-alt' ,
    'glasses' ,
    'hood-cloak' ,
    'square-quote' ,
    'up-left' ,
    'bring-front' ,
    'chess-board' ,
    'burger-cheese' ,
    'cheeseburger' ,
    'building-circle-check' ,
    'repeat-1' ,
    'arrow-down-to-line' ,
    'arrow-to-bottom' ,
    'grid-5' ,
    'right-long-to-line' ,
    'person-chalkboard' ,
    'mars-stroke-right' ,
    'mars-stroke-h' ,
    'hand-back-fist' ,
    'hand-rock' ,
    'tally' ,
    'tally5' ,
    'square-caret-up' ,
    'caret-square-up' ,
    'cloud-showers-water' ,
    'chart-bar' ,
    'bar-chart' ,
    'hands-bubbles' ,
    'hands-wash' ,
    'less-than-equal' ,
    'train' ,
    'up-from-dotted-line' ,
    'eye-low-vision' ,
    'low-vision' ,
    'traffic-light-go' ,
    'face-exhaling' ,
    'sensor-fire' ,
    'user-unlock' ,
    'hexagon-divide' ,
    '00' ,
    'crow' ,
    'cassette-betamax' ,
    'betamax' ,
    'sailboat' ,
    'window-restore' ,
    'nfc-magnifying-glass' ,
    'file-binary' ,
    'circle-v' ,
    'square-plus' ,
    'plus-square' ,
    'bowl-scoops' ,
    'mistletoe' ,
    'custard' ,
    'lacrosse-stick' ,
    'hockey-mask' ,
    'sunrise' ,
    'panel-ews' ,
    'torii-gate' ,
    'cloud-exclamation' ,
    'message-lines' ,
    'comment-alt-lines' ,
    'frog' ,
    'bucket' ,
    'floppy-disk-pen' ,
    'image' ,
    'window-frame' ,
    'microphone' ,
    'cow' ,
    'square-ring' ,
    'down-from-line' ,
    'arrow-alt-from-top' ,
    'caret-up' ,
    'shield-xmark' ,
    'shield-times' ,
    'screwdriver' ,
    'circle-sort-down' ,
    'sort-circle-down' ,
    'folder-closed' ,
    'house-tsunami' ,
    'square-nfi' ,
    'forklift' ,
    'arrow-up-from-ground-water' ,
    'bracket-square-right' ,
    'martini-glass' ,
    'glass-martini-alt' ,
    'rotate-left' ,
    'rotate-back' ,
    'rotate-backward' ,
    'undo-alt' ,
    'table-columns' ,
    'columns' ,
    'square-a' ,
    'tick' ,
    'lemon' ,
    'head-side-mask' ,
    'handshake' ,
    'gem' ,
    'dolly' ,
    'dolly-box' ,
    'smoking' ,
    'minimize' ,
    'compress-arrows-alt' ,
    'refrigerator' ,
    'monument' ,
    'octagon-xmark' ,
    'times-octagon' ,
    'xmark-octagon' ,
    'align-slash' ,
    'snowplow' ,
    'angles-right' ,
    'angle-double-right' ,
    'truck-ramp-couch' ,
    'truck-couch' ,
    'cannabis' ,
    'circle-play' ,
    'play-circle' ,
    'arrow-up-right-and-arrow-down-left-from-center' ,
    'tablets' ,
    '360-degrees' ,
    'ethernet' ,
    'euro-sign' ,
    'eur' ,
    'euro' ,
    'chair' ,
    'circle-check' ,
    'check-circle' ,
    'square-dashed-circle-plus' ,
    'money-simple-from-bracket' ,
    'bat' ,
    'circle-stop' ,
    'stop-circle' ,
    'head-side-headphones' ,
    'phone-rotary' ,
    'compass-drafting' ,
    'drafting-compass' ,
    'plate-wheat' ,
    'calendar-circle-minus' ,
    'chopsticks' ,
    'car-wrench' ,
    'car-mechanic' ,
    'icicles' ,
    'person-shelter' ,
    'neuter' ,
    'id-badge' ,
    'kazoo' ,
    'marker' ,
    'face-laugh-beam' ,
    'laugh-beam' ,
    'square-arrow-down-left' ,
    'battery-bolt' ,
    'tree-large' ,
    'helicopter-symbol' ,
    'aperture' ,
    'universal-access' ,
    'file-magnifying-glass' ,
    'file-search' ,
    'up-right' ,
    'circle-chevron-up' ,
    'chevron-circle-up' ,
    'user-police' ,
    'lari-sign' ,
    'volcano' ,
    'teddy-bear' ,
    'stocking' ,
    'person-walking-dashed-line-arrow-right' ,
    'image-slash' ,
    'mask-snorkel' ,
    'smoke' ,
    'sterling-sign' ,
    'gbp' ,
    'pound-sign' ,
    'battery-exclamation' ,
    'viruses' ,
    'square-person-confined' ,
    'user-tie' ,
    'arrow-down-long' ,
    'long-arrow-down' ,
    'tent-arrow-down-to-line' ,
    'certificate' ,
    'crystal-ball' ,
    'reply-all' ,
    'mail-reply-all' ,
    'suitcase' ,
    'person-skating' ,
    'skating' ,
    'star-shooting' ,
    'binary-lock' ,
    'filter-circle-dollar' ,
    'funnel-dollar' ,
    'camera-retro' ,
    'circle-arrow-down' ,
    'arrow-circle-down' ,
    'comment-pen' ,
    'comment-edit' ,
    'file-import' ,
    'arrow-right-to-file' ,
    'banjo' ,
    'square-arrow-up-right' ,
    'external-link-square' ,
    'light-emergency-on' ,
    'kerning' ,
    'box-open' ,
    'square-f' ,
    'scroll' ,
    'spa' ,
    'arrow-left-from-line' ,
    'arrow-from-right' ,
    'strawberry' ,
    'location-pin-lock' ,
    'pause' ,
    'clock-eight-thirty' ,
    'plane-engines' ,
    'plane-alt' ,
    'hill-avalanche' ,
    'temperature-empty' ,
    'temperature0' ,
    'thermometer0' ,
    'thermometer-empty' ,
    'bomb' ,
    'gauge-low' ,
    'tachometer-alt-slow' ,
    'registered' ,
    'trash-can-plus' ,
    'address-card' ,
    'contact-card' ,
    'vcard' ,
    'scale-unbalanced-flip' ,
    'balance-scale-right' ,
    'globe-snow' ,
    'subscript' ,
    'diamond-turn-right' ,
    'directions' ,
    'integral' ,
    'burst' ,
    'house-laptop' ,
    'laptop-house' ,
    'face-tired' ,
    'tired' ,
    'money-bills' ,
    'blinds-raised' ,
    'smog' ,
    'ufo-beam' ,
    'circle-caret-up' ,
    'caret-circle-up' ,
    'user-vneck-hair-long' ,
    'square-a-lock' ,
    'crutch' ,
    'gas-pump-slash' ,
    'font-awesome' ,
    'font-awesome-flag' ,
    'font-awesome-logo-full' ,
    'cloud-arrow-up' ,
    'cloud-upload' ,
    'cloud-upload-alt' ,
    'palette' ,
    'transporter-4' ,
    'objects-align-right' ,
    'arrows-turn-right' ,
    'vest' ,
    'pig' ,
    'inbox-full' ,
    'circle-envelope' ,
    'envelope-circle' ,
    'triangle-person-digging' ,
    'construction' ,
    'ferry' ,
    'bullseye-arrow' ,
    'arrows-down-to-people' ,
    'seedling' ,
    'sprout' ,
    'clock-seven' ,
    'left-right' ,
    'arrows-alt-h' ,
    'boxes-packing' ,
    'circle-arrow-left' ,
    'arrow-circle-left' ,
    'flashlight' ,
    'group-arrows-rotate' ,
    'bowl-food' ,
    'square-9' ,
    'candy-cane' ,
    'arrow-down-wide-short' ,
    'sort-amount-asc' ,
    'sort-amount-down' ,
    'square-dollar' ,
    'dollar-square' ,
    'usd-square' ,
    'phone-arrow-right' ,
    'hand-holding-seedling' ,
    'message-check' ,
    'comment-alt-check' ,
    'cloud-bolt' ,
    'thunderstorm' ,
    'text-slash' ,
    'remove-format' ,
    'watch' ,
    'circle-down-left' ,
    'text' ,
    'projector' ,
    'face-smile-wink' ,
    'smile-wink' ,
    'tombstone-blank' ,
    'tombstone-alt' ,
    'chess-king-piece' ,
    'chess-king-alt' ,
    'circle-6' ,
    'left' ,
    'arrow-alt-left' ,
    'file-word' ,
    'file-powerpoint' ,
    'square-down' ,
    'arrow-alt-square-down' ,
    'objects-align-center-vertical' ,
    'arrows-left-right' ,
    'arrows-h' ,
    'house-lock' ,
    'cloud-arrow-down' ,
    'cloud-download' ,
    'cloud-download-alt' ,
    'wreath' ,
    'children' ,
    'meter-droplet' ,
    'chalkboard' ,
    'blackboard' ,
    'user-large-slash' ,
    'user-alt-slash' ,
    'signal-strong' ,
    'signal4' ,
    'lollipop' ,
    'lollypop' ,
    'list-tree' ,
    'envelope-open' ,
    'draw-circle' ,
    'cat-space' ,
    'handshake-simple-slash' ,
    'handshake-alt-slash' ,
    'rabbit-running' ,
    'rabbit-fast' ,
    'memo-pad' ,
    'mattress-pillow' ,
    'alarm-plus' ,
    'alicorn' ,
    'comment-question' ,
    'gingerbread-man' ,
    'guarani-sign' ,
    'burger-fries' ,
    'mug-tea' ,
    'border-top' ,
    'arrows-rotate' ,
    'refresh' ,
    'sync' ,
    'circle-book-open' ,
    'book-circle' ,
    'arrows-to-dotted-line' ,
    'fire-extinguisher' ,
    'garage-open' ,
    'shelves-empty' ,
    'cruzeiro-sign' ,
    'watch-apple' ,
    'watch-calculator' ,
    'list-dropdown' ,
    'cabinet-filing' ,
    'burger-soda' ,
    'square-arrow-up' ,
    'arrow-square-up' ,
    'greater-than-equal' ,
    'pallet-box' ,
    'face-confounded' ,
    'shield-halved' ,
    'shield-alt' ,
    'truck-plow' ,
    'book-atlas' ,
    'atlas' ,
    'virus' ,
    'comment-middle-top' ,
    'envelope-circle-check' ,
    'layer-group' ,
    'restroom-simple' ,
    'arrows-to-dot' ,
    'border-outer' ,
    'hashtag-lock' ,
    'clock-two-thirty' ,
    'archway' ,
    'heart-circle-check' ,
    'house-chimney-crack' ,
    'house-damage' ,
    'file-zipper' ,
    'file-archive' ,
    'heart-half' ,
    'comment-check' ,
    'square' ,
    'memo' ,
    'martini-glass-empty' ,
    'glass-martini' ,
    'couch' ,
    'cedi-sign' ,
    'italic' ,
    'glass-citrus' ,
    'calendar-lines-pen' ,
    'church' ,
    'person-snowmobiling' ,
    'snowmobile' ,
    'face-hushed' ,
    'comments-dollar' ,
    'pickaxe' ,
    'link-simple-slash' ,
    'democrat' ,
    'face-confused' ,
    'pinball' ,
    'z' ,
    'person-skiing' ,
    'skiing' ,
    'deer' ,
    'input-pipe' ,
    'road-lock' ,
    'a' ,
    'bookmark-slash' ,
    'temperature-arrow-down' ,
    'temperature-down' ,
    'mace' ,
    'feather-pointed' ,
    'feather-alt' ,
    'sausage' ,
    'trash-can-clock' ,
    'p' ,
    'snowflake' ,
    'stomach' ,
    'newspaper' ,
    'rectangle-ad' ,
    'ad' ,
    'guitar-electric' ,
    'arrow-turn-down-right' ,
    'moon-cloud' ,
    'bread-slice-butter' ,
    'circle-arrow-right' ,
    'arrow-circle-right' ,
    'user-group-crown' ,
    'users-crown' ,
    'circle-i' ,
    'toilet-paper-check' ,
    'filter-circle-xmark' ,
    'locust' ,
    'sort' ,
    'unsorted' ,
    'list-ol' ,
    'list12' ,
    'list-numeric' ,
    'chart-waterfall' ,
    'face-party' ,
    'kidneys' ,
    'wifi-exclamation' ,
    'chart-network' ,
    'person-dress-burst' ,
    'dice-d4' ,
    'money-check-dollar' ,
    'money-check-alt' ,
    'vector-square' ,
    'bread-slice' ,
    'language' ,
    'wheat-awn-slash' ,
    'face-kiss-wink-heart' ,
    'kiss-wink-heart' ,
    'dagger' ,
    'podium' ,
    'memo-circle-check' ,
    'route-highway' ,
    'down-to-line' ,
    'arrow-alt-to-bottom' ,
    'filter' ,
    'square-g' ,
    'circle-phone' ,
    'phone-circle' ,
    'clipboard-prescription' ,
    'user-nurse-hair' ,
    'question' ,
    'file-signature' ,
    'toggle-large-on' ,
    'up-down-left-right' ,
    'arrows-alt' ,
    'dryer-heat' ,
    'dryer-alt' ,
    'house-chimney-user' ,
    'hand-holding-heart' ,
    'arrow-up-small-big' ,
    'sort-size-up-alt' ,
    'train-track' ,
    'puzzle-piece' ,
    'money-check' ,
    'star-half-stroke' ,
    'star-half-alt' ,
    'file-exclamation' ,
    'code' ,
    'whiskey-glass' ,
    'glass-whiskey' ,
    'moon-stars' ,
    'building-circle-exclamation' ,
    'clothes-hanger' ,
    'mobile-notch' ,
    'mobile-iphone' ,
    'magnifying-glass-chart' ,
    'arrow-up-right-from-square' ,
    'external-link' ,
    'cubes-stacked' ,
    'images-user' ,
    'won-sign' ,
    'krw' ,
    'won' ,
    'image-polaroid-user' ,
    'virus-covid' ,
    'square-ellipsis' ,
    'pie' ,
    'chess-knight-piece' ,
    'chess-knight-alt' ,
    'austral-sign' ,
    'cloud-plus' ,
    'f' ,
    'leaf' ,
    'bed-bunk' ,
    'road' ,
    'taxi' ,
    'cab' ,
    'person-circle-plus' ,
    'chart-pie' ,
    'pie-chart' ,
    'bolt-lightning' ,
    'clock-eight' ,
    'sack-xmark' ,
    'file-excel' ,
    'file-contract' ,
    'fish-fins' ,
    'circle-q' ,
    'building-flag' ,
    'face-grin-beam' ,
    'grin-beam' ,
    'object-ungroup' ,
    'face-disguise' ,
    'circle-arrow-down-right' ,
    'alien-8bit' ,
    'alien-monster' ,
    'hand-point-ribbon' ,
    'poop' ,
    'object-exclude' ,
    'telescope' ,
    'location-pin' ,
    'map-marker' ,
    'square-list' ,
    'kaaba' ,
    'toilet-paper' ,
    'helmet-safety' ,
    'hard-hat' ,
    'hat-hard' ,
    'comment-code' ,
    'sim-cards' ,
    'starship' ,
    'eject' ,
    'circle-right' ,
    'arrow-alt-circle-right' ,
    'plane-circle-check' ,
    'seal' ,
    'user-cowboy' ,
    'hexagon-vertical-nft' ,
    'face-rolling-eyes' ,
    'meh-rolling-eyes' ,
    'bread-loaf' ,
    'rings-wedding' ,
    'object-group' ,
    'french-fries' ,
    'chart-line' ,
    'line-chart' ,
    'calendar-arrow-down' ,
    'calendar-download' ,
    'send-back' ,
    'mask-ventilator' ,
    'signature-lock' ,
    'arrow-right' ,
    'signs-post' ,
    'map-signs' ,
    'octagon-plus' ,
    'plus-octagon' ,
    'cash-register' ,
    'person-circle-question' ,
    'melon-slice' ,
    'space-station-moon' ,
    'message-smile' ,
    'comment-alt-smile' ,
    'cup-straw' ,
    'left-from-line' ,
    'arrow-alt-from-right' ,
    'h' ,
    'basket-shopping-simple' ,
    'shopping-basket-alt' ,
    'hands-holding-heart' ,
    'hands-heart' ,
    'clock-nine' ,
    'tarp' ,
    'face-sleepy' ,
    'hand-horns' ,
    'screwdriver-wrench' ,
    'tools' ,
    'arrows-to-eye' ,
    'circle-three-quarters' ,
    'trophy-star' ,
    'trophy-alt' ,
    'plug-circle-bolt' ,
    'face-thermometer' ,
    'shirt-running' ,
    'book-circle-arrow-up' ,
    'face-nauseated' ,
    'heart' ,
    'file-chart-pie' ,
    'mars-and-venus' ,
    'house-user' ,
    'home-user' ,
    'circle-arrow-down-left' ,
    'dumpster-fire' ,
    'hexagon-minus' ,
    'minus-hexagon' ,
    'left-to-line' ,
    'arrow-alt-to-left' ,
    'house-crack' ,
    'paw-simple' ,
    'paw-alt' ,
    'arrow-left-long-to-line' ,
    'brackets-round' ,
    'parentheses' ,
    'martini-glass-citrus' ,
    'cocktail' ,
    'user-shakespeare' ,
    'arrow-right-to-arc' ,
    'face-surprise' ,
    'surprise' ,
    'bottle-water' ,
    'circle-pause' ,
    'pause-circle' ,
    'gauge-circle-plus' ,
    'folders' ,
    'angel' ,
    'value-absolute' ,
    'rabbit' ,
    'toilet-paper-slash' ,
    'apple-whole' ,
    'apple-alt' ,
    'kitchen-set' ,
    'diamond-half' ,
    'lock-keyhole' ,
    'lock-alt' ,
    'r' ,
    'temperature-quarter' ,
    'temperature1' ,
    'thermometer1' ,
    'thermometer-quarter' ,
    'square-info' ,
    'info-square' ,
    'wifi-slash' ,
    'toilet-paper-xmark' ,
    'hands-holding-dollar' ,
    'hands-usd' ,
    'cube' ,
    'arrow-down-triangle-square' ,
    'sort-shapes-down' ,
    'bitcoin-sign' ,
    'shutters' ,
    'shield-dog' ,
    'solar-panel' ,
    'lock-open' ,
    'table-tree' ,
    'house-chimney-heart' ,
    'tally-3' ,
    'elevator' ,
    'money-bill-transfer' ,
    'money-bill-trend-up' ,
    'house-flood-water-circle-arrow-right' ,
    'square-poll-horizontal' ,
    'poll-h' ,
    'circle' ,
    'cart-circle-exclamation' ,
    'sword' ,
    'backward-fast' ,
    'fast-backward' ,
    'recycle' ,
    'user-astronaut' ,
    'interrobang' ,
    'plane-slash' ,
    'circle-dashed' ,
    'trademark' ,
    'basketball' ,
    'basketball-ball' ,
    'fork-knife' ,
    'utensils-alt' ,
    'satellite-dish' ,
    'badge-check' ,
    'circle-up' ,
    'arrow-alt-circle-up' ,
    'slider' ,
    'mobile-screen-button' ,
    'mobile-alt' ,
    'clock-one-thirty' ,
    'inbox-out' ,
    'inbox-arrow-up' ,
    'cloud-slash' ,
    'volume-high' ,
    'volume-up' ,
    'users-rays' ,
    'wallet' ,
    'octagon-check' ,
    'flatbread-stuffed' ,
    'clipboard-check' ,
    'cart-circle-plus' ,
    'truck-clock' ,
    'shipping-timed' ,
    'pool-8-ball' ,
    'file-audio' ,
    'turn-down-left' ,
    'lock-hashtag' ,
    'chart-radar' ,
    'staff' ,
    'burger' ,
    'hamburger' ,
    'utility-pole' ,
    'transporter-6' ,
    'wrench' ,
    'bugs' ,
    'vector-polygon' ,
    'diagram-nested' ,
    'rupee-sign' ,
    'rupee' ,
    'file-image' ,
    'circle-question' ,
    'question-circle' ,
    'image-user' ,
    'buoy' ,
    'plane-departure' ,
    'handshake-slash' ,
    'book-bookmark' ,
    'border-center-h' ,
    'can-food' ,
    'typewriter' ,
    'arrow-right-from-arc' ,
    'circle-k' ,
    'face-hand-over-mouth' ,
    'popcorn' ,
    'house-water' ,
    'house-flood' ,
    'object-subtract' ,
    'code-branch' ,
    'warehouse-full' ,
    'warehouse-alt' ,
    'hat-cowboy' ,
    'bridge' ,
    'phone-flip' ,
    'phone-alt' ,
    'arrow-down-from-dotted-line' ,
    'square-quarters' ,
    'truck-front' ,
    'cat' ,
    'trash-xmark' ,
    'circle-caret-left' ,
    'caret-circle-left' ,
    'files' ,
    'anchor-circle-exclamation' ,
    'face-clouds' ,
    'user-crown' ,
    'truck-field' ,
    'route' ,
    'cart-circle-check' ,
    'clipboard-question' ,
    'panorama' ,
    'comment-medical' ,
    'teeth-open' ,
    'user-tie-hair-long' ,
    'file-circle-minus' ,
    'head-side-medical' ,
    'tags' ,
    'wine-glass' ,
    'forward-fast' ,
    'fast-forward' ,
    'face-meh-blank' ,
    'meh-blank' ,
    'user-robot' ,
    'square-parking' ,
    'parking' ,
    'card-diamond' ,
    'face-zipper' ,
    'face-raised-eyebrow' ,
    'house-signal' ,
    'square-chevron-up' ,
    'chevron-square-up' ,
    'nfc-symbol' ,
    'bars-progress' ,
    'tasks-alt' ,
    'faucet-drip' ,
    'arrows-to-line' ,
    'dolphin' ,
    'arrow-up-right' ,
    'circle-r' ,
    'cart-flatbed' ,
    'dolly-flatbed' ,
    'ban-smoking' ,
    'smoking-ban' ,
    'circle-sort-up' ,
    'sort-circle-up' ,
    'terminal' ,
    'mobile-button' ,
    'house-medical-flag' ,
    'basket-shopping' ,
    'shopping-basket' ,
    'tape' ,
    'chestnut' ,
    'bus-simple' ,
    'bus-alt' ,
    'eye' ,
    'face-sad-cry' ,
    'sad-cry' ,
    'heat' ,
    'ticket-airline' ,
    'boot-heeled' ,
    'arrows-minimize' ,
    'compress-arrows' ,
    'audio-description' ,
    'person-military-to-person' ,
    'file-shield' ,
    'hexagon' ,
    'manhole' ,
    'user-slash' ,
    'pen' ,
    'tower-observation' ,
    'floppy-disks' ,
    'toilet-paper-blank-under' ,
    'toilet-paper-reverse-alt' ,
    'file-code' ,
    'signal' ,
    'signal5' ,
    'signal-perfect' ,
    'pump' ,
    'bus' ,
    'heart-circle-xmark' ,
    'arrow-up-left-from-circle' ,
    'house-chimney' ,
    'home-lg' ,
    'window-maximize' ,
    'dryer' ,
    'face-frown' ,
    'frown' ,
    'chess-bishop-piece' ,
    'chess-bishop-alt' ,
    'shirt-tank-top' ,
    'diploma' ,
    'scroll-ribbon' ,
    'screencast' ,
    'walker' ,
    'prescription' ,
    'shop' ,
    'store-alt' ,
    'floppy-disk' ,
    'save' ,
    'vihara' ,
    'face-kiss-closed-eyes' ,
    'scale-unbalanced' ,
    'balance-scale-left' ,
    'file-user' ,
    'user-police-tie' ,
    'face-tongue-money' ,
    'tennis-ball' ,
    'square-l' ,
    'sort-up' ,
    'sort-asc' ,
    'calendar-arrow-up' ,
    'calendar-upload' ,
    'comment-dots' ,
    'commenting' ,
    'plant-wilt' ,
    'scarf' ,
    'album-circle-plus' ,
    'user-nurse-hair-long' ,
    'diamond' ,
    'square-left' ,
    'arrow-alt-square-left' ,
    'face-grin-squint' ,
    'grin-squint' ,
    'circle-ellipsis-vertical' ,
    'hand-holding-dollar' ,
    'hand-holding-usd' ,
    'grid-dividers' ,
    'bacterium' ,
    'hand-pointer' ,
    'drum-steelpan' ,
    'hand-scissors' ,
    'hands-praying' ,
    'praying-hands' ,
    'face-pensive' ,
    'user-music' ,
    'arrow-rotate-right' ,
    'arrow-right-rotate' ,
    'arrow-rotate-forward' ,
    'redo' ,
    'messages-dollar' ,
    'comments-alt-dollar' ,
    'sensor-on' ,
    'balloon' ,
    'biohazard' ,
    'chess-queen-piece' ,
    'chess-queen-alt' ,
    'location-crosshairs' ,
    'location' ,
    'mars-double' ,
    'house-person-leave' ,
    'house-leave' ,
    'house-person-depart' ,
    'ruler-triangle' ,
    'card-club' ,
    'child-dress' ,
    'users-between-lines' ,
    'lungs-virus' ,
    'spinner-third' ,
    'face-grin-tears' ,
    'grin-tears' ,
    'phone' ,
    'computer-mouse-scrollwheel' ,
    'mouse-alt' ,
    'calendar-xmark' ,
    'calendar-times' ,
    'child-reaching' ,
    'table-layout' ,
    'narwhal' ,
    'ramp-loading' ,
    'calendar-circle-plus' ,
    'toothbrush' ,
    'border-inner' ,
    'paw-claws' ,
    'kiwi-fruit' ,
    'traffic-light-slow' ,
    'rectangle-code' ,
    'head-side-virus' ,
    'keyboard-brightness' ,
    'books-medical' ,
    'lightbulb-slash' ,
    'house-blank' ,
    'home-blank' ,
    'square-5' ,
    'square-heart' ,
    'heart-square' ,
    'puzzle' ,
    'user-gear' ,
    'user-cog' ,
    'pipe-circle-check' ,
    'arrow-up-1-9' ,
    'sort-numeric-up' ,
    'octagon-exclamation' ,
    'dial-low' ,
    'door-closed' ,
    'laptop-mobile' ,
    'phone-laptop' ,
    'conveyor-belt-boxes' ,
    'conveyor-belt-alt' ,
    'shield-virus' ,
    'starfighter-twin-ion-engine-advanced' ,
    'starfighter-alt-advanced' ,
    'dice-six' ,
    'starfighter-twin-ion-engine' ,
    'starfighter-alt' ,
    'rocket-launch' ,
    'mosquito-net' ,
    'vent-damper' ,
    'bridge-water' ,
    'ban-bug' ,
    'debug' ,
    'person-booth' ,
    'text-width' ,
    'garage-car' ,
    'square-kanban' ,
    'hat-wizard' ,
    'pen-fancy' ,
    'coffee-pot' ,
    'mouse-field' ,
    'person-digging' ,
    'digging' ,
    'shower-down' ,
    'shower-alt' ,
    'box-circle-check' ,
    'brightness' ,
    'car-side-bolt' ,
    'ornament' ,
    'phone-arrow-down-left' ,
    'phone-arrow-down' ,
    'phone-incoming' ,
    'cloud-word' ,
    'hand-fingers-crossed' ,
    'trash' ,
    'gauge-simple' ,
    'gauge-simple-med' ,
    'tachometer-average' ,
    'arrow-down-small-big' ,
    'sort-size-down-alt' ,
    'book-medical' ,
    'face-melting' ,
    'poo' ,
    'pen-clip-slash' ,
    'pen-alt-slash' ,
    'quote-right' ,
    'quote-right-alt' ,
    'scroll-old' ,
    'guitars' ,
    'phone-xmark' ,
    'hose' ,
    'clock-six' ,
    'shirt' ,
    't-shirt' ,
    'tshirt' ,
    'square-r' ,
    'cubes' ,
    'envelope-open-dollar' ,
    'divide' ,
    'sun-cloud' ,
    'lamp-floor' ,
    'square-7' ,
    'tenge-sign' ,
    'tenge' ,
    'headphones' ,
    'hands-holding' ,
    'campfire' ,
    'circle-ampersand' ,
    'snowflakes' ,
    'hands-clapping' ,
    'republican' ,
    'leaf-maple' ,
    'arrow-left' ,
    'person-circle-xmark' ,
    'ruler' ,
    'cup-straw-swoosh' ,
    'temperature-sun' ,
    'temperature-hot' ,
    'align-left' ,
    'dice-d6' ,
    'restroom' ,
    'high-definition' ,
    'rectangle-hd' ,
    'j' ,
    'galaxy' ,
    'users-viewfinder' ,
    'file-video' ,
    'cherries' ,
    'up-right-from-square' ,
    'external-link-alt' ,
    'circle-sort' ,
    'sort-circle' ,
    'table-cells' ,
    'th' ,
    'file-pdf' ,
    'siren' ,
    'arrow-up-to-dotted-line' ,
    'image-landscape' ,
    'landscape' ,
    'tank-water' ,
    'curling-stone' ,
    'curling' ,
    'gamepad-modern' ,
    'gamepad-alt' ,
    'messages-question' ,
    'book-bible' ,
    'bible' ,
    'o' ,
    'suitcase-medical' ,
    'medkit' ,
    'briefcase-arrow-right' ,
    'expand-wide' ,
    'clock-eleven-thirty' ,
    'rv' ,
    'user-secret' ,
    'otter' ,
    'dreidel' ,
    'person-dress' ,
    'female' ,
    'comment-dollar' ,
    'business-time' ,
    'briefcase-clock' ,
    'flower-tulip' ,
    'people-pants-simple' ,
    'cloud-drizzle' ,
    'table-cells-large' ,
    'th-large' ,
    'book-tanakh' ,
    'tanakh' ,
    'solar-system' ,
    'seal-question' ,
    'phone-volume' ,
    'volume-control-phone' ,
    'disc-drive' ,
    'hat-cowboy-side' ,
    'table-rows' ,
    'rows' ,
    'location-exclamation' ,
    'map-marker-exclamation' ,
    'face-fearful' ,
    'clipboard-user' ,
    'bus-school' ,
    'film-slash' ,
    'square-arrow-down-right' ,
    'book-sparkles' ,
    'book-spells' ,
    'washing-machine' ,
    'washer' ,
    'child' ,
    'lira-sign' ,
    'user-visor' ,
    'file-plus-minus' ,
    'chess-clock-flip' ,
    'chess-clock-alt' ,
    'satellite' ,
    'plane-lock' ,
    'steering-wheel' ,
    'tag' ,
    'stretcher' ,
    'book-section' ,
    'book-law' ,
    'inboxes' ,
    'coffee-bean' ,
    'brackets-curly' ,
    'ellipsis-stroke-vertical' ,
    'ellipsis-v-alt' ,
    'comment' ,
    'square-1' ,
    'cake-candles' ,
    'birthday-cake' ,
    'cake' ,
    'head-side' ,
    'envelope' ,
    'dolly-empty' ,
    'face-tissue' ,
    'angles-up' ,
    'angle-double-up' ,
    'paperclip' ,
    'chart-line-down' ,
    'arrow-right-to-city' ,
    'lock-a' ,
    'ribbon' ,
    'lungs' ,
    'person-pinball' ,
    'arrow-up-9-1' ,
    'sort-numeric-up-alt' ,
    'apple-core' ,
    'circle-y' ,
    'h6' ,
    'litecoin-sign' ,
    'circle-small' ,
    'border-none' ,
    'arrow-turn-down-left' ,
    'circle-nodes' ,
    'parachute-box' ,
    'message-medical' ,
    'comment-alt-medical' ,
    'rugby-ball' ,
    'comment-music' ,
    'indent' ,
    'tree-deciduous' ,
    'tree-alt' ,
    'puzzle-piece-simple' ,
    'puzzle-piece-alt' ,
    'truck-field-un' ,
    'nfc-trash' ,
    'hourglass' ,
    'hourglass-empty' ,
    'mountain' ,
    'file-xmark' ,
    'file-times' ,
    'house-heart' ,
    'home-heart' ,
    'house-chimney-blank' ,
    'meter-bolt' ,
    'user-doctor' ,
    'user-md' ,
    'slash-back' ,
    'circle-info' ,
    'info-circle' ,
    'fishing-rod' ,
    'hammer-crash' ,
    'message-heart' ,
    'cloud-meatball' ,
    'camera-polaroid' ,
    'camera' ,
    'camera-alt' ,
    'square-virus' ,
    'cart-arrow-up' ,
    'meteor' ,
    'car-on' ,
    'sleigh' ,
    'arrow-down-1-9' ,
    'sort-numeric-asc' ,
    'sort-numeric-down' ,
    'buoy-mooring' ,
    'square-4' ,
    'hand-holding-droplet' ,
    'hand-holding-water' ,
    'tricycle-adult' ,
    'waveform' ,
    'water' ,
    'star-sharp-half-stroke' ,
    'star-sharp-half-alt' ,
    'nfc-signal' ,
    'plane-prop' ,
    'calendar-check' ,
    'clock-desk' ,
    'calendar-clock' ,
    'calendar-time' ,
    'braille' ,
    'prescription-bottle-medical' ,
    'prescription-bottle-alt' ,
    'plate-utensils' ,
    'family-pants' ,
    'hose-reel' ,
    'house-window' ,
    'landmark' ,
    'truck' ,
    'crosshairs' ,
    'cloud-rainbow' ,
    'person-cane' ,
    'alien' ,
    'tent' ,
    'vest-patches' ,
    'people-dress-simple' ,
    'check-double' ,
    'arrow-down-a-z' ,
    'sort-alpha-asc' ,
    'sort-alpha-down' ,
    'bowling-ball-pin' ,
    'bell-school-slash' ,
    'plus-large' ,
    'money-bill-wheat' ,
    'camera-viewfinder' ,
    'screenshot' ,
    'message-music' ,
    'comment-alt-music' ,
    'car-building' ,
    'border-bottom-right' ,
    'border-style-alt' ,
    'octagon' ,
    'comment-arrow-up-right' ,
    'octagon-divide' ,
    'cookie' ,
    'arrow-rotate-left' ,
    'arrow-left-rotate' ,
    'arrow-rotate-back' ,
    'arrow-rotate-backward' ,
    'undo' ,
    'tv-music' ,
    'hard-drive' ,
    'hdd' ,
    'reel' ,
    'face-grin-squint-tears' ,
    'grin-squint-tears' ,
    'dumbbell' ,
    'rectangle-list' ,
    'list-alt' ,
    'tarp-droplet' ,
    'alarm-exclamation' ,
    'house-medical-circle-check' ,
    'traffic-cone' ,
    'grate' ,
    'arrow-down-right' ,
    'person-skiing-nordic' ,
    'skiing-nordic' ,
    'calendar-plus' ,
    'person-from-portal' ,
    'portal-exit' ,
    'plane-arrival' ,
    'cowbell-circle-plus' ,
    'cowbell-more' ,
    'circle-left' ,
    'arrow-alt-circle-left' ,
    'distribute-spacing-vertical' ,
    'signal-bars-fair' ,
    'signal-alt2' ,
    'sportsball' ,
    'game-console-handheld-crank' ,
    'train-subway' ,
    'subway' ,
    'chart-gantt' ,
    'face-smile-upside-down' ,
    'ball-pile' ,
    'badge-dollar' ,
    'money-bills-simple' ,
    'money-bills-alt' ,
    'list-timeline' ,
    'indian-rupee-sign' ,
    'indian-rupee' ,
    'inr' ,
    'crop-simple' ,
    'crop-alt' ,
    'money-bill-1' ,
    'money-bill-alt' ,
    'left-long' ,
    'long-arrow-alt-left' ,
    'keyboard-down' ,
    'circle-up-right' ,
    'cloud-bolt-moon' ,
    'thunderstorm-moon' ,
    'dna' ,
    'virus-slash' ,
    'bracket-round-right' ,
    'circle-5' ,
    'minus' ,
    'subtract' ,
    'fire-flame' ,
    'flame' ,
    'right-to-line' ,
    'arrow-alt-to-right' ,
    'gif' ,
    'chess' ,
    'trash-slash' ,
    'arrow-left-long' ,
    'long-arrow-left' ,
    'plug-circle-check' ,
    'font-case' ,
    'street-view' ,
    'arrow-down-left' ,
    'franc-sign' ,
    'flask-round-poison' ,
    'flask-poison' ,
    'volume-off' ,
    'book-circle-arrow-right' ,
    'chart-user' ,
    'user-chart' ,
    'hands-asl-interpreting' ,
    'american-sign-language-interpreting' ,
    'asl-interpreting' ,
    'hands-american-sign-language-interpreting' ,
    'presentation-screen' ,
    'presentation' ,
    'circle-bolt' ,
    'face-smile-halo' ,
    'cart-circle-arrow-down' ,
    'house-person-return' ,
    'house-person-arrive' ,
    'house-return' ,
    'message-xmark' ,
    'comment-alt-times' ,
    'message-times' ,
    'file-certificate' ,
    'file-award' ,
    'user-doctor-hair-long' ,
    'camera-security' ,
    'camera-home' ,
    'gear' ,
    'cog' ,
    'droplet-slash' ,
    'tint-slash' ,
    'book-heart' ,
    'mosque' ,
    'duck' ,
    'mosquito' ,
    'star-of-david' ,
    'flag-swallowtail' ,
    'flag-alt' ,
    'person-military-rifle' ,
    'car-garage' ,
    'cart-shopping' ,
    'shopping-cart' ,
    'book-font' ,
    'shield-plus' ,
    'vials' ,
    'eye-dropper-full' ,
    'distribute-spacing-horizontal' ,
    'tablet-rugged' ,
    'temperature-snow' ,
    'temperature-frigid' ,
    'moped' ,
    'face-smile-plus' ,
    'smile-plus' ,
    'radio-tuner' ,
    'radio-alt' ,
    'face-swear' ,
    'water-arrow-down' ,
    'water-lower' ,
    'scanner-touchscreen' ,
    'circle-7' ,
    'plug-circle-plus' ,
    'person-ski-jumping' ,
    'ski-jump' ,
    'place-of-worship' ,
    'water-arrow-up' ,
    'water-rise' ,
    'waveform-lines' ,
    'waveform-path' ,
    'split' ,
    'film-canister' ,
    'film-cannister' ,
    'folder-xmark' ,
    'folder-times' ,
    'toilet-paper-blank' ,
    'toilet-paper-alt' ,
    'tablet-screen' ,
    'tablet-android-alt' ,
    'hexagon-vertical-nft-slanted' ,
    'folder-music' ,
    'display-medical' ,
    'desktop-medical' ,
    'share-all' ,
    'peapod' ,
    'chess-clock' ,
    'axe' ,
    'square-d' ,
    'grip-vertical' ,
    'mobile-signal-out' ,
    'arrow-turn-up' ,
    'level-up' ,
    'u' ,
    'arrow-up-from-dotted-line' ,
    'square-root-variable' ,
    'square-root-alt' ,
    'light-switch-on' ,
    'arrow-down-arrow-up' ,
    'sort-alt' ,
    'raindrops' ,
    'dash' ,
    'minus-large' ,
    'clock' ,
    'clock-four' ,
    'input-numeric' ,
    'truck-tow' ,
    'backward-step' ,
    'step-backward' ,
    'pallet' ,
    'car-bolt' ,
    'arrows-maximize' ,
    'expand-arrows' ,
    'faucet' ,
    'cloud-sleet' ,
    'lamp-street' ,
    'list-radio' ,
    'pen-nib-slash' ,
    'baseball-bat-ball' ,
    'square-up-left' ,
    'overline' ,
    's' ,
    'timeline' ,
    'keyboard' ,
    'arrows-from-dotted-line' ,
    'usb-drive' ,
    'ballot' ,
    'caret-down' ,
    'location-dot-slash' ,
    'map-marker-alt-slash' ,
    'cards' ,
    'house-chimney-medical' ,
    'clinic-medical' ,
    'boxing-glove' ,
    'glove-boxing' ,
    'temperature-three-quarters' ,
    'temperature3' ,
    'thermometer3' ,
    'thermometer-three-quarters' ,
    'bell-school' ,
    'mobile-screen' ,
    'mobile-android-alt' ,
    'plane-up' ,
    'folder-heart' ,
    'circle-location-arrow' ,
    'location-circle' ,
    'face-head-bandage' ,
    'sushi-roll' ,
    'maki-roll' ,
    'makizushi' ,
    'car-bump' ,
    'piggy-bank' ,
    'racquet' ,
    'car-mirrors' ,
    'industry-windows' ,
    'industry-alt' ,
    'bolt-auto' ,
    'battery-half' ,
    'battery3' ,
    'flux-capacitor' ,
    'mountain-city' ,
    'coins' ,
    'honey-pot' ,
    'olive' ,
    'khanda' ,
    'filter-list' ,
    'outlet' ,
    'sliders' ,
    'sliders-h' ,
    'cauldron' ,
    'people' ,
    'folder-tree' ,
    'network-wired' ,
    'croissant' ,
    'map-pin' ,
    'hamsa' ,
    'cent-sign' ,
    'swords-laser' ,
    'flask' ,
    'person-pregnant' ,
    'square-u' ,
    'wand-sparkles' ,
    'router' ,
    'ellipsis-vertical' ,
    'ellipsis-v' ,
    'sword-laser-alt' ,
    'ticket' ,
    'power-off' ,
    'coin' ,
    'laptop-slash' ,
    'right-long' ,
    'long-arrow-alt-right' ,
    'circle-b' ,
    'person-dress-simple' ,
    'pipe-collar' ,
    'lights-holiday' ,
    'citrus' ,
    'flag-usa' ,
    'laptop-file' ,
    'tty' ,
    'teletype' ,
    'chart-tree-map' ,
    'diagram-next' ,
    'person-rifle' ,
    'clock-five-thirty' ,
    'pipe-valve' ,
    'arrow-up-from-arc' ,
    'face-spiral-eyes' ,
    'compress-wide' ,
    'circle-phone-hangup' ,
    'phone-circle-down' ,
    'house-medical-circle-exclamation' ,
    'badminton' ,
    'closed-captioning' ,
    'person-hiking' ,
    'hiking' ,
    'right-from-line' ,
    'arrow-alt-from-left' ,
    'venus-double' ,
    'images' ,
    'calculator' ,
    'shuttlecock' ,
    'user-hair' ,
    'eye-evil' ,
    'people-pulling' ,
    'n' ,
    'garage' ,
    'cable-car' ,
    'tram' ,
    'shovel-snow' ,
    'cloud-rain' ,
    'face-lying' ,
    'sprinkler' ,
    'building-circle-xmark' ,
    'person-sledding' ,
    'sledding' ,
    'game-console-handheld' ,
    'ship' ,
    'clock-six-thirty' ,
    'battery-slash' ,
    'tugrik-sign' ,
    'arrows-down-to-line' ,
    'download' ,
    'shelves' ,
    'inventory' ,
    'cloud-snow' ,
    'face-grin' ,
    'grin' ,
    'delete-left' ,
    'backspace' ,
    'oven' ,
    'eye-dropper' ,
    'eye-dropper-empty' ,
    'eyedropper' ,
    'comment-captions' ,
    'comments-question' ,
    'scribble' ,
    'rotate-exclamation' ,
    'file-circle-check' ,
    'glass' ,
    'loader' ,
    'forward' ,
    'user-pilot' ,
    'mobile' ,
    'mobile-android' ,
    'mobile-phone' ,
    'code-pull-request-closed' ,
    'face-meh' ,
    'meh' ,
    'align-center' ,
    'book-skull' ,
    'book-dead' ,
    'id-card' ,
    'drivers-license' ,
    'face-dotted' ,
    'face-worried' ,
    'outdent' ,
    'dedent' ,
    'heart-circle-exclamation' ,
    'house' ,
    'home' ,
    'home-alt' ,
    'home-lg-alt' ,
    'vector-circle' ,
    'car-circle-bolt' ,
    'calendar-week' ,
    'flying-disc' ,
    'laptop-medical' ,
    'square-down-right' ,
    'b' ,
    'seat-airline' ,
    'moon-over-sun' ,
    'eclipse-alt' ,
    'pipe' ,
    'file-medical' ,
    'potato' ,
    'dice-one' ,
    'circle-a' ,
    'helmet-battle' ,
    'butter' ,
    'blanket-fire' ,
    'kiwi-bird' ,
    'castle' ,
    'golf-club' ,
    'arrow-right-arrow-left' ,
    'exchange' ,
    'rotate-right' ,
    'redo-alt' ,
    'rotate-forward' ,
    'utensils' ,
    'cutlery' ,
    'arrow-up-wide-short' ,
    'sort-amount-up' ,
    'balloons' ,
    'mill-sign' ,
    'bowl-rice' ,
    'timeline-arrow' ,
    'skull' ,
    'game-board-simple' ,
    'game-board-alt' ,
    'circle-video' ,
    'video-circle' ,
    'chart-scatter-bubble' ,
    'house-turret' ,
    'banana' ,
    'hand-holding-skull' ,
    'people-dress' ,
    'loveseat' ,
    'couch-small' ,
    'tower-broadcast' ,
    'broadcast-tower' ,
    'truck-pickup' ,
    'block-quote' ,
    'up-long' ,
    'long-arrow-alt-up' ,
    'stop' ,
    'code-merge' ,
    'money-check-dollar-pen' ,
    'money-check-edit-alt' ,
    'up-from-line' ,
    'arrow-alt-from-bottom' ,
    'upload' ,
    'hurricane' ,
    'people-pants' ,
    'mound' ,
    'windsock' ,
    'circle-half' ,
    'brake-warning' ,
    'toilet-portable' ,
    'compact-disc' ,
    'file-arrow-down' ,
    'file-download' ,
    'saxophone-fire' ,
    'sax-hot' ,
    'camera-web-slash' ,
    'webcam-slash' ,
    'folder-medical' ,
    'folder-gear' ,
    'folder-cog' ,
    'hand-wave' ,
    'arrow-up-arrow-down' ,
    'sort-up-down' ,
    'caravan' ,
    'shield-cat' ,
    'message-slash' ,
    'comment-alt-slash' ,
    'bolt' ,
    'zap' ,
    'trash-can-check' ,
    'glass-water' ,
    'oil-well' ,
    'person-simple' ,
    'vault' ,
    'mars' ,
    'toilet' ,
    'plane-circle-xmark' ,
    'yen-sign' ,
    'cny' ,
    'jpy' ,
    'rmb' ,
    'yen' ,
    'notes' ,
    'ruble-sign' ,
    'rouble' ,
    'rub' ,
    'ruble' ,
    'trash-undo' ,
    'trash-arrow-turn-left' ,
    'champagne-glass' ,
    'glass-champagne' ,
    'objects-align-center-horizontal' ,
    'sun' ,
    'trash-can-slash' ,
    'trash-alt-slash' ,
    'screen-users' ,
    'users-class' ,
    'guitar' ,
    'square-arrow-left' ,
    'arrow-square-left' ,
    'square-8' ,
    'face-smile-hearts' ,
    'brackets-square' ,
    'brackets' ,
    'laptop-arrow-down' ,
    'hockey-stick-puck' ,
    'house-tree' ,
    'signal-fair' ,
    'signal2' ,
    'face-laugh-wink' ,
    'laugh-wink' ,
    'circle-dollar' ,
    'dollar-circle' ,
    'usd-circle' ,
    'horse-head' ,
    'arrows-repeat' ,
    'repeat-alt' ,
    'bore-hole' ,
    'industry' ,
    'image-polaroid' ,
    'wave-triangle' ,
    'circle-down' ,
    'arrow-alt-circle-down' ,
    'grill' ,
    'arrows-turn-to-dots' ,
    'chart-mixed' ,
    'analytics' ,
    'florin-sign' ,
    'arrow-down-short-wide' ,
    'sort-amount-desc' ,
    'sort-amount-down-alt' ,
    'less-than' ,
    'display-code' ,
    'desktop-code' ,
    'face-drooling' ,
    'oil-temperature' ,
    'oil-temp' ,
    'square-question' ,
    'question-square' ,
    'air-conditioner' ,
    'angle-down' ,
    'mountains' ,
    'omega' ,
    'car-tunnel' ,
    'person-dolly-empty' ,
    'pan-food' ,
    'head-side-cough' ,
    'grip-lines' ,
    'thumbs-down' ,
    'user-lock' ,
    'arrow-right-long' ,
    'long-arrow-right' ,
    'tickets-airline' ,
    'anchor-circle-xmark' ,
    'ellipsis' ,
    'ellipsis-h' ,
    'nfc-slash' ,
    'chess-pawn' ,
    'kit-medical' ,
    'first-aid' ,
    'grid-2-plus' ,
    'bells' ,
    'person-through-window' ,
    'toolbox' ,
    'envelope-dot' ,
    'envelope-badge' ,
    'hands-holding-circle' ,
    'bug' ,
    'bowl-chopsticks' ,
    'credit-card' ,
    'credit-card-alt' ,
    'circle-s' ,
    'box-ballot' ,
    'car' ,
    'automobile' ,
    'hand-holding-hand' ,
    'user-tie-hair' ,
    'podium-star' ,
    'user-hair-mullet' ,
    'business-front' ,
    'party-back' ,
    'trian-balbot' ,
    'microphone-stand' ,
    'book-open-reader' ,
    'book-reader' ,
    'family-dress' ,
    'circle-x' ,
    'cabin' ,
    'mountain-sun' ,
    'chart-simple-horizontal' ,
    'bluetooth' ,
    'arrows-left-right-to-line' ,
    'hand-back-point-left' ,
    'message-dots' ,
    'comment-alt-dots' ,
    'messaging' ,
    'file-heart' ,
    'beer-mug' ,
    'beer-foam' ,
    'dice-d20' ,
    'drone' ,
    'truck-droplet' ,
    'file-circle-xmark' ,
    'temperature-arrow-up' ,
    'temperature-up' ,
    'medal' ,
    'bed' ,
    'book-copy' ,
    'square-h' ,
    'h-square' ,
    'square-c' ,
    'clock-two' ,
    'square-ellipsis-vertical' ,
    'podcast' ,
    'bee' ,
    'temperature-full' ,
    'temperature4' ,
    'thermometer4' ,
    'thermometer-full' ,
    'bell' ,
    'candy-bar' ,
    'chocolate-bar' ,
    'xmark-large' ,
    'pinata' ,
    'arrows-from-line' ,
    'superscript' ,
    'bowl-spoon' ,
    'hexagon-check' ,
    'plug-circle-xmark' ,
    'star-of-life' ,
    'phone-slash' ,
    'traffic-light-stop' ,
    'paint-roller' ,
    'accent-grave' ,
    'handshake-angle' ,
    'hands-helping' ,
    'circle-0' ,
    'dial-med-low' ,
    'location-dot' ,
    'map-marker-alt' ,
    'crab' ,
    'box-open-full' ,
    'box-full' ,
    'file' ,
    'greater-than' ,
    'quotes' ,
    'pretzel' ,
    'person-swimming' ,
    'swimmer' ,
    'arrow-down' ,
    'user-robot-xmarks' ,
    'message-quote' ,
    'comment-alt-quote' ,
    'candy-corn' ,
    'folder-magnifying-glass' ,
    'folder-search' ,
    'notebook' ,
    'droplet' ,
    'tint' ,
    'bullseye-pointer' ,
    'eraser' ,
    'hexagon-image' ,
    'earth-americas' ,
    'earth' ,
    'earth-america' ,
    'globe-americas' ,
    'crate-apple' ,
    'apple-crate' ,
    'person-burst' ,
    'game-board' ,
    'hat-chef' ,
    'hand-back-point-right' ,
    'dove' ,
    'snowflake-droplets' ,
    'battery-empty' ,
    'battery0' ,
    'grid-4' ,
    'socks' ,
    'face-sunglasses' ,
    'inbox' ,
    'square-0' ,
    'section' ,
    'square-this-way-up' ,
    'box-up' ,
    'gauge-high' ,
    'tachometer-alt' ,
    'tachometer-alt-fast' ,
    'square-ampersand' ,
    'envelope-open-text' ,
    'lamp-desk' ,
    'hospital' ,
    'hospital-alt' ,
    'hospital-wide' ,
    'poll-people' ,
    'whiskey-glass-ice' ,
    'glass-whiskey-rocks' ,
    'wine-bottle' ,
    'chess-rook' ,
    'user-bounty-hunter' ,
    'bars-staggered' ,
    'reorder' ,
    'stream' ,
    'diagram-sankey' ,
    'cloud-hail-mixed' ,
    'circle-up-left' ,
    'dharmachakra' ,
    'objects-align-left' ,
    'oil-can-drip' ,
    'face-smiling-hands' ,
    'broccoli' ,
    'route-interstate' ,
    'ear-muffs' ,
    'hotdog' ,
    'transporter-empty' ,
    'person-walking-with-cane' ,
    'blind' ,
    'angle-90' ,
    'rectangle-terminal' ,
    'kite' ,
    'drum' ,
    'scrubber' ,
    'ice-cream' ,
    'heart-circle-bolt' ,
    'fish-bones' ,
    'deer-rudolph' ,
    'fax' ,
    'paragraph' ,
    'head-side-heart' ,
    'square-e' ,
    'meter-fire' ,
    'cloud-hail' ,
    'check-to-slot' ,
    'vote-yea' ,
    'money-from-bracket' ,
    'star-half' ,
    'car-bus' ,
    'speaker' ,
    'timer' ,
    'boxes-stacked' ,
    'boxes' ,
    'boxes-alt' ,
    'grill-hot' ,
    'ballot-check' ,
    'link' ,
    'chain' ,
    'ear-listen' ,
    'assistive-listening-systems' ,
    'file-minus' ,
    'tree-city' ,
    'play' ,
    'font' ,
    'cup-togo' ,
    'coffee-togo' ,
    'square-down-left' ,
    'burger-lettuce' ,
    'rupiah-sign' ,
    'magnifying-glass' ,
    'search' ,
    'table-tennis-paddle-ball' ,
    'ping-pong-paddle-ball' ,
    'table-tennis' ,
    'person-dots-from-line' ,
    'diagnoses' ,
    'chevrons-down' ,
    'chevron-double-down' ,
    'trash-can-arrow-up' ,
    'trash-restore-alt' ,
    'signal-good' ,
    'signal3' ,
    'location-question' ,
    'map-marker-question' ,
    'floppy-disk-circle-xmark' ,
    'floppy-disk-times' ,
    'save-circle-xmark' ,
    'save-times' ,
    'naira-sign' ,
    'peach' ,
    'taxi-bus' ,
    'bracket-curly' ,
    'bracket-curly-left' ,
    'lobster' ,
    'cart-flatbed-empty' ,
    'dolly-flatbed-empty' ,
    'colon' ,
    'cart-arrow-down' ,
    'wand' ,
    'walkie-talkie' ,
    'file-pen' ,
    'file-edit' ,
    'receipt' ,
    'table-picnic' ,
    'square-pen' ,
    'pen-square' ,
    'pencil-square' ,
    'circle-microphone-lines' ,
    'microphone-circle-alt' ,
    'display-slash' ,
    'desktop-slash' ,
    'suitcase-rolling' ,
    'person-circle-exclamation' ,
    'transporter-2' ,
    'hands-holding-diamond' ,
    'hand-receiving' ,
    'money-bill-simple-wave' ,
    'chevron-down' ,
    'battery-full' ,
    'battery' ,
    'battery5' ,
    'bell-plus' ,
    'book-arrow-right' ,
    'hospitals' ,
    'club' ,
    'skull-crossbones' ,
    'droplet-degree' ,
    'dewpoint' ,
    'code-compare' ,
    'list-ul' ,
    'list-dots' ,
    'hand-holding-magic' ,
    'watermelon-slice' ,
    'circle-ellipsis' ,
    'school-lock' ,
    'tower-cell' ,
    'sd-cards' ,
    'down-long' ,
    'long-arrow-alt-down' ,
    'envelopes' ,
    'phone-office' ,
    'ranking-star' ,
    'chess-king' ,
    'nfc-pen' ,
    'person-harassing' ,
    'hat-winter' ,
    'brazilian-real-sign' ,
    'landmark-dome' ,
    'landmark-alt' ,
    'bone-break' ,
    'arrow-up' ,
    'down-from-dotted-line' ,
    'tv' ,
    'television' ,
    'tv-alt' ,
    'border-left' ,
    'circle-divide' ,
    'shrimp' ,
    'list-check' ,
    'tasks' ,
    'diagram-subtask' ,
    'jug-detergent' ,
    'circle-user' ,
    'user-circle' ,
    'square-y' ,
    'user-doctor-hair' ,
    'planet-ringed' ,
    'mushroom' ,
    'user-shield' ,
    'megaphone' ,
    'circle-exclamation-check' ,
    'wind' ,
    'box-dollar' ,
    'box-usd' ,
    'car-burst' ,
    'car-crash' ,
    'y' ,
    'user-headset' ,
    'arrows-retweet' ,
    'retweet-alt' ,
    'person-snowboarding' ,
    'snowboarding' ,
    'square-chevron-right' ,
    'chevron-square-right' ,
    'lacrosse-stick-ball' ,
    'truck-fast' ,
    'shipping-fast' ,
    'user-magnifying-glass' ,
    'star-sharp' ,
    'comment-heart' ,
    'circle-1' ,
    'circle-star' ,
    'star-circle' ,
    'fish' ,
    'cloud-fog' ,
    'fog' ,
    'waffle' ,
    'music-note' ,
    'music-alt' ,
    'hexagon-exclamation' ,
    'cart-shopping-fast' ,
    'object-union' ,
    'user-graduate' ,
    'starfighter' ,
    'circle-half-stroke' ,
    'adjust' ,
    'arrow-right-long-to-line' ,
    'square-arrow-down' ,
    'arrow-square-down' ,
    'diamond-half-stroke' ,
    'clapperboard' ,
    'square-chevron-left' ,
    'chevron-square-left' ,
    'phone-intercom' ,
    'link-horizontal' ,
    'chain-horizontal' ,
    'mango' ,
    'music-note-slash' ,
    'music-alt-slash' ,
    'circle-radiation' ,
    'radiation-alt' ,
    'face-tongue-sweat' ,
    'globe-stand' ,
    'baseball' ,
    'baseball-ball' ,
    'circle-p' ,
    'award-simple' ,
    'jet-fighter-up' ,
    'diagram-project' ,
    'project-diagram' ,
    'pedestal' ,
    'chart-pyramid' ,
    'sidebar' ,
    'snowman-head' ,
    'frosty-head' ,
    'copy' ,
    'burger-glass' ,
    'volume-xmark' ,
    'volume-mute' ,
    'volume-times' ,
    'hand-sparkles' ,
    'bars-filter' ,
    'paintbrush-pencil' ,
    'party-bell' ,
    'user-vneck-hair' ,
    'jack-o-lantern' ,
    'grip' ,
    'grip-horizontal' ,
    'share-from-square' ,
    'share-square' ,
    'keynote' ,
    'child-combatant' ,
    'child-rifle' ,
    'gun' ,
    'square-phone' ,
    'phone-square' ,
    'plus' ,
    'add' ,
    'expand' ,
    'computer' ,
    'fort' ,
    'cloud-check' ,
    'xmark' ,
    'close' ,
    'multiply' ,
    'remove' ,
    'times' ,
    'face-smirking' ,
    'arrows-up-down-left-right' ,
    'arrows' ,
    'chalkboard-user' ,
    'chalkboard-teacher' ,
    'rhombus' ,
    'claw-marks' ,
    'peso-sign' ,
    'face-smile-tongue' ,
    'cart-circle-xmark' ,
    'building-shield' ,
    'circle-phone-flip' ,
    'phone-circle-alt' ,
    'baby' ,
    'users-line' ,
    'quote-left' ,
    'quote-left-alt' ,
    'tractor' ,
    'key-skeleton' ,
    'trash-arrow-up' ,
    'trash-restore' ,
    'arrow-down-up-lock' ,
    'arrow-down-to-bracket' ,
    'lines-leaning' ,
    'square-q' ,
    'ruler-combined' ,
    'symbols' ,
    'icons-alt' ,
    'copyright' ,
    'highlighter-line' ,
    'bracket-square' ,
    'bracket' ,
    'bracket-left' ,
    'island-tropical' ,
    'island-tree-palm' ,
    'arrow-right-from-line' ,
    'arrow-from-left' ,
    'h2' ,
    'equals' ,
    'cake-slice' ,
    'shortcake' ,
    'peanut' ,
    'wrench-simple' ,
    'blender' ,
    'teeth' ,
    'tally-2' ,
    'shekel-sign' ,
    'ils' ,
    'shekel' ,
    'sheqel' ,
    'sheqel-sign' ,
    'cars' ,
    'axe-battle' ,
    'user-hair-long' ,
    'map' ,
    'file-circle-info' ,
    'face-disappointed' ,
    'lasso-sparkles' ,
    'clock-eleven' ,
    'rocket' ,
    'siren-on' ,
    'clock-ten' ,
    'candle-holder' ,
    'video-arrow-down-left' ,
    'photo-film' ,
    'photo-video' ,
    'floppy-disk-circle-arrow-right' ,
    'save-circle-arrow-right' ,
    'folder-minus' ,
    'planet-moon' ,
    'face-eyes-xmarks' ,
    'chart-scatter' ,
    'display-arrow-down' ,
    'store' ,
    'arrow-trend-up' ,
    'plug-circle-minus' ,
    'olive-branch' ,
    'angle' ,
    'vacuum-robot' ,
    'sign-hanging' ,
    'sign' ,
    'square-divide' ,
    'signal-stream-slash' ,
    'bezier-curve' ,
    'eye-dropper-half' ,
    'store-lock' ,
    'bell-slash' ,
    'cloud-bolt-sun' ,
    'thunderstorm-sun' ,
    'camera-slash' ,
    'comment-quote' ,
    'tablet' ,
    'tablet-android' ,
    'school-flag' ,
    'message-code' ,
    'glass-half' ,
    'glass-half-empty' ,
    'glass-half-full' ,
    'fill' ,
    'message-minus' ,
    'comment-alt-minus' ,
    'angle-up' ,
    'drumstick-bite' ,
    'link-horizontal-slash' ,
    'chain-horizontal-slash' ,
    'holly-berry' ,
    'nose' ,
    'chevron-left' ,
    'bacteria' ,
    'clouds' ,
    'money-bill-simple' ,
    'hand-lizard' ,
    'table-pivot' ,
    'filter-slash' ,
    'trash-can-undo' ,
    'trash-can-arrow-turn-left' ,
    'trash-undo-alt' ,
    'notdef' ,
    'disease' ,
    'person-to-door' ,
    'turntable' ,
    'briefcase-medical' ,
    'genderless' ,
    'chevron-right' ,
    'signal-weak' ,
    'signal1' ,
    'clock-five' ,
    'retweet' ,
    'car-rear' ,
    'car-alt' ,
    'pump-soap' ,
    'computer-classic' ,
    'frame' ,
    'video-slash' ,
    'battery-quarter' ,
    'battery2' ,
    'ellipsis-stroke' ,
    'ellipsis-h-alt' ,
    'radio' ,
    'baby-carriage' ,
    'carriage-baby' ,
    'face-expressionless' ,
    'down-to-dotted-line' ,
    'cloud-music' ,
    'traffic-light' ,
    'cloud-minus' ,
    'thermometer' ,
    'shield-minus' ,
    'vr-cardboard' ,
    'car-tilt' ,
    'gauge-circle-minus' ,
    'brightness-low' ,
    'hand-middle-finger' ,
    'percent' ,
    'percentage' ,
    'truck-moving' ,
    'glass-water-droplet' ,
    'conveyor-belt' ,
    'location-check' ,
    'map-marker-check' ,
    'coin-vertical' ,
    'display' ,
    'person-sign' ,
    'face-smile' ,
    'smile' ,
    'phone-hangup' ,
    'signature-slash' ,
    'thumbtack' ,
    'thumb-tack' ,
    'wheat-slash' ,
    'trophy' ,
    'clouds-sun' ,
    'person-praying' ,
    'pray' ,
    'hammer' ,
    'face-vomit' ,
    'speakers' ,
    'tty-answer' ,
    'teletype-answer' ,
    'mug-tea-saucer' ,
    'diagram-lean-canvas' ,
    'alt' ,
    'dial' ,
    'dial-med-high' ,
    'hand-peace' ,
    'circle-trash' ,
    'trash-circle' ,
    'rotate' ,
    'sync-alt' ,
    'circle-quarters' ,
    'spinner' ,
    'tower-control' ,
    'arrow-up-triangle-square' ,
    'sort-shapes-up' ,
    'whale' ,
    'robot' ,
    'peace' ,
    'party-horn' ,
    'gears' ,
    'cogs' ,
    'sun-bright' ,
    'sun-alt' ,
    'warehouse' ,
    'lock-keyhole-open' ,
    'lock-open-alt' ,
    'square-fragile' ,
    'box-fragile' ,
    'square-wine-glass-crack' ,
    'arrow-up-right-dots' ,
    'square-n' ,
    'splotch' ,
    'face-grin-hearts' ,
    'grin-hearts' ,
    'meter' ,
    'mandolin' ,
    'dice-four' ,
    'sim-card' ,
    'transgender' ,
    'transgender-alt' ,
    'mercury' ,
    'up-from-bracket' ,
    'knife-kitchen' ,
    'border-right' ,
    'arrow-turn-down' ,
    'level-down' ,
    'spade' ,
    'card-spade' ,
    'line-columns' ,
    'arrow-right-to-line' ,
    'arrow-to-right' ,
    'person-falling-burst' ,
    'flag-pennant' ,
    'pennant' ,
    'conveyor-belt-empty' ,
    'award' ,
    'ticket-simple' ,
    'ticket-alt' ,
    'building' ,
    'angles-left' ,
    'angle-double-left' ,
    'camcorder' ,
    'video-handheld' ,
    'pancakes' ,
    'album-circle-user' ,
    'qrcode' ,
    'dice-d10' ,
    'fireplace' ,
    'browser' ,
    'pen-paintbrush' ,
    'pencil-paintbrush' ,
    'fish-cooked' ,
    'chair-office' ,
    'nesting-dolls' ,
    'clock-rotate-left' ,
    'history' ,
    'trumpet' ,
    'face-grin-beam-sweat' ,
    'grin-beam-sweat' ,
    'fire-smoke' ,
    'phone-missed' ,
    'file-export' ,
    'arrow-right-from-file' ,
    'shield' ,
    'shield-blank' ,
    'arrow-up-short-wide' ,
    'sort-amount-up-alt' ,
    'arrows-repeat-1' ,
    'repeat1-alt' ,
    'gun-slash' ,
    'avocado' ,
    'binary' ,
    'glasses-round' ,
    'glasses-alt' ,
    'phone-plus' ,
    'ditto' ,
    'person-seat' ,
    'house-medical' ,
    'golf-ball-tee' ,
    'golf-ball' ,
    'circle-chevron-left' ,
    'chevron-circle-left' ,
    'house-chimney-window' ,
    'scythe' ,
    'pen-nib' ,
    'ban-parking' ,
    'parking-circle-slash' ,
    'tent-arrow-turn-left' ,
    'face-diagonal-mouth' ,
    'diagram-cells' ,
    'cricket-bat-ball' ,
    'cricket' ,
    'tents' ,
    'wand-magic' ,
    'magic' ,
    'dog' ,
    'pen-line' ,
    'atom-simple' ,
    'atom-alt' ,
    'ampersand' ,
    'carrot' ,
    'arrow-up-from-line' ,
    'arrow-from-bottom' ,
    'moon' ,
    'pen-slash' ,
    'wine-glass-empty' ,
    'wine-glass-alt' ,
    'square-star' ,
    'cheese' ,
    'send-backward' ,
    'yin-yang' ,
    'music' ,
    'compass-slash' ,
    'clock-one' ,
    'file-music' ,
    'code-commit' ,
    'temperature-low' ,
    'person-biking' ,
    'biking' ,
    'skeleton' ,
    'circle-g' ,
    'circle-arrow-up-left' ,
    'coin-blank' ,
    'broom' ,
    'vacuum' ,
    'shield-heart' ,
    'card-heart' ,
    'lightbulb-cfl-on' ,
    'melon' ,
    'gopuram' ,
    'earth-oceania' ,
    'globe-oceania' ,
    'container-storage' ,
    'face-pouting' ,
    'square-xmark' ,
    'times-square' ,
    'xmark-square' ,
    'face-explode' ,
    'exploding-head' ,
    'hashtag' ,
    'up-right-and-down-left-from-center' ,
    'expand-alt' ,
    'oil-can' ,
    't' ,
    'transformer-bolt' ,
    'hippo' ,
    'chart-column' ,
    'cassette-vhs' ,
    'vhs' ,
    'infinity' ,
    'vial-circle-check' ,
    'chimney' ,
    'object-intersect' ,
    'person-arrow-down-to-line' ,
    'voicemail' ,
    'block-brick' ,
    'wall-brick' ,
    'fan' ,
    'bags-shopping' ,
    'paragraph-left' ,
    'paragraph-rtl' ,
    'person-walking-luggage' ,
    'caravan-simple' ,
    'caravan-alt' ,
    'turtle' ,
    'up-down' ,
    'arrows-alt-v' ,
    'cloud-moon-rain' ,
    'booth-curtain' ,
    'calendar' ,
    'box-heart' ,
    'trailer' ,
    'user-doctor-message' ,
    'user-md-chat' ,
    'bahai' ,
    'haykal' ,
    'amp-guitar' ,
    'sd-card' ,
    'volume-slash' ,
    'border-bottom' ,
    'wifi-weak' ,
    'wifi1' ,
    'dragon' ,
    'shoe-prints' ,
    'circle-plus' ,
    'plus-circle' ,
    'face-grin-tongue-wink' ,
    'grin-tongue-wink' ,
    'hand-holding' ,
    'plug-circle-exclamation' ,
    'link-slash' ,
    'chain-broken' ,
    'chain-slash' ,
    'unlink' ,
    'clone' ,
    'person-walking-arrow-loop-left' ,
    'arrow-up-z-a' ,
    'sort-alpha-up-alt' ,
    'fire-flame-curved' ,
    'fire-alt' ,
    'tornado' ,
    'file-circle-plus' ,
    'delete-right' ,
    'book-quran' ,
    'quran' ,
    'circle-quarter' ,
    'anchor' ,
    'border-all' ,
    'function' ,
    'face-angry' ,
    'angry' ,
    'people-simple' ,
    'cookie-bite' ,
    'arrow-trend-down' ,
    'rss' ,
    'feed' ,
    'face-monocle' ,
    'draw-polygon' ,
    'scale-balanced' ,
    'balance-scale' ,
    'calendar-lines' ,
    'calendar-note' ,
    'arrow-down-big-small' ,
    'sort-size-down' ,
    'gauge-simple-high' ,
    'tachometer' ,
    'tachometer-fast' ,
    'do-not-enter' ,
    'shower' ,
    'dice-d8' ,
    'desktop' ,
    'desktop-alt' ,
    'm' ,
    'grip-dots-vertical' ,
    'face-viewfinder' ,
    'soft-serve' ,
    'creemee' ,
    'h5' ,
    'hand-back-point-down' ,
    'table-list' ,
    'th-list' ,
    'comment-sms' ,
    'sms' ,
    'rectangle' ,
    'rectangle-landscape' ,
    'clipboard-list-check' ,
    'turkey' ,
    'book' ,
    'user-plus' ,
    'ice-skate' ,
    'check' ,
    'battery-three-quarters' ,
    'battery4' ,
    'tomato' ,
    'sword-laser' ,
    'house-circle-check' ,
    'buildings' ,
    'angle-left' ,
    'cart-flatbed-boxes' ,
    'dolly-flatbed-alt' ,
    'diagram-successor' ,
    'truck-arrow-right' ,
    'square-w' ,
    'arrows-split-up-and-left' ,
    'lamp' ,
    'airplay' ,
    'hand-fist' ,
    'fist-raised' ,
    'shield-quartered' ,
    'slash-forward' ,
    'location-pen' ,
    'map-marker-edit' ,
    'cloud-moon' ,
    'pot-food' ,
    'briefcase' ,
    'person-falling' ,
    'image-portrait' ,
    'portrait' ,
    'user-tag' ,
    'rug' ,
    'print-slash' ,
    'earth-europe' ,
    'globe-europe' ,
    'cart-flatbed-suitcase' ,
    'luggage-cart' ,
    'hand-back-point-ribbon' ,
    'rectangle-xmark' ,
    'rectangle-times' ,
    'times-rectangle' ,
    'window-close' ,
    'tire-rugged' ,
    'lightbulb-dollar' ,
    'cowbell' ,
    'baht-sign' ,
    'corner' ,
    'chevrons-right' ,
    'chevron-double-right' ,
    'book-open' ,
    'book-journal-whills' ,
    'journal-whills' ,
    'inhaler' ,
    'handcuffs' ,
    'snake' ,
    'triangle-exclamation' ,
    'exclamation-triangle' ,
    'warning' ,
    'note-medical' ,
    'database' ,
    'down-left' ,
    'share' ,
    'arrow-turn-right' ,
    'mail-forward' ,
    'face-thinking' ,
    'turn-down-right' ,
    'bottle-droplet' ,
    'mask-face' ,
    'hill-rockslide' ,
    'scanner-keyboard' ,
    'circle-o' ,
    'grid-horizontal' ,
    'message-dollar' ,
    'comment-alt-dollar' ,
    'right-left' ,
    'exchange-alt' ,
    'columns-3' ,
    'paper-plane' ,
    'road-circle-exclamation' ,
    'dungeon' ,
    'hand-holding-box' ,
    'input-text' ,
    'window-flip' ,
    'window-alt' ,
    'align-right' ,
    'scanner-gun' ,
    'scanner' ,
    'tire' ,
    'engine' ,
    'money-bill-1-wave' ,
    'money-bill-wave-alt' ,
    'life-ring' ,
    'hands' ,
    'sign-language' ,
    'signing' ,
    'circle-caret-right' ,
    'caret-circle-right' ,
    'wheat' ,
    'file-spreadsheet' ,
    'audio-description-slash' ,
    'calendar-day' ,
    'water-ladder' ,
    'ladder-water' ,
    'swimming-pool' ,
    'arrows-up-down' ,
    'arrows-v' ,
    'chess-pawn-piece' ,
    'chess-pawn-alt' ,
    'face-grimace' ,
    'grimace' ,
    'wheelchair-move' ,
    'wheelchair-alt' ,
    'turn-down' ,
    'level-down-alt' ,
    'square-s' ,
    'rectangle-barcode' ,
    'barcode-alt' ,
    'person-walking-arrow-right' ,
    'square-envelope' ,
    'envelope-square' ,
    'dice' ,
    'unicorn' ,
    'bowling-ball' ,
    'pompebled' ,
    'brain' ,
    'watch-smart' ,
    'book-user' ,
    'sensor-cloud' ,
    'sensor-smoke' ,
    'clapperboard-play' ,
    'bandage' ,
    'band-aid' ,
    'calendar-minus' ,
    'circle-xmark' ,
    'times-circle' ,
    'xmark-circle' ,
    'circle-4' ,
    'gifts' ,
    'album-collection' ,
    'hotel' ,
    'earth-asia' ,
    'globe-asia' ,
    'id-card-clip' ,
    'id-card-alt' ,
    'magnifying-glass-plus' ,
    'search-plus' ,
    'thumbs-up' ,
    'cloud-showers' ,
    'user-clock' ,
    'onion' ,
    'clock-twelve-thirty' ,
    'arrow-down-to-dotted-line' ,
    'hand-dots' ,
    'allergies' ,
    'file-invoice' ,
    'window-minimize' ,
    'rectangle-wide' ,
    'comment-arrow-up' ,
    'garlic' ,
    'mug-saucer' ,
    'coffee' ,
    'brush' ,
    'tree-decorated' ,
    'mask' ,
    'calendar-heart' ,
    'magnifying-glass-minus' ,
    'search-minus' ,
    'flower' ,
    'ruler-vertical' ,
    'user-large' ,
    'user-alt' ,
    'starship-freighter' ,
    'train-tram' ,
    'bridge-suspension' ,
    'trash-check' ,
    'user-nurse' ,
    'boombox' ,
    'syringe' ,
    'cloud-sun' ,
    'shield-exclamation' ,
    'stopwatch-20' ,
    'square-full' ,
    'grip-dots' ,
    'comment-exclamation' ,
    'pen-swirl' ,
    'falafel' ,
    'circle-2' ,
    'magnet' ,
    'jar' ,
    'gramophone' ,
    'dice-d12' ,
    'note-sticky' ,
    'sticky-note' ,
    'down' ,
    'arrow-alt-down' ,
    'hundred-points' ,
    '100' ,
    'paperclip-vertical' ,
    'wind-warning' ,
    'wind-circle-exclamation' ,
    'location-pin-slash' ,
    'map-marker-slash' ,
    'face-sad-sweat' ,
    'bug-slash' ,
    'cupcake' ,
    'light-switch-off' ,
    'toggle-large-off' ,
    'pen-fancy-slash' ,
    'truck-container' ,
    'boot' ,
    'arrow-up-from-water-pump' ,
    'file-check' ,
    'bone' ,
    'cards-blank' ,
    'circle-3' ,
    'bench-tree' ,
    'keyboard-brightness-low' ,
    'ski-boot-ski' ,
    'brain-circuit' ,
    'user-injured' ,
    'block-brick-fire' ,
    'firewall' ,
    'face-sad-tear' ,
    'sad-tear' ,
    'plane' ,
    'tent-arrows-down' ,
    'exclamation' ,
    'arrows-spin' ,
    'face-smile-relaxed' ,
    'comment-xmark' ,
    'comment-times' ,
    'print' ,
    'turkish-lira-sign' ,
    'try' ,
    'turkish-lira' ,
    'face-nose-steam' ,
    'circle-waveform-lines' ,
    'waveform-circle' ,
    'dollar-sign' ,
    'dollar' ,
    'usd' ,
    'ferris-wheel' ,
    'computer-speaker' ,
    'skull-cow' ,
    'x' ,
    'magnifying-glass-dollar' ,
    'search-dollar' ,
    'users-gear' ,
    'users-cog' ,
    'person-military-pointing' ,
    'building-columns' ,
    'bank' ,
    'institution' ,
    'museum' ,
    'university' ,
    'circle-t' ,
    'sack' ,
    'grid-2' ,
    'camera-cctv' ,
    'cctv' ,
    'umbrella' ,
    'trowel' ,
    'horizontal-rule' ,
    'bed-front' ,
    'bed-alt' ,
    'd' ,
    'stapler' ,
    'masks-theater' ,
    'theater-masks' ,
    'kip-sign' ,
    'face-woozy' ,
    'cloud-question' ,
    'pineapple' ,
    'hand-point-left' ,
    'gallery-thumbnails' ,
    'circle-j' ,
    'eyes' ,
    'handshake-simple' ,
    'handshake-alt' ,
    'page-caret-up' ,
    'file-caret-up' ,
    'jet-fighter' ,
    'fighter-jet' ,
    'comet' ,
    'square-share-nodes' ,
    'share-alt-square' ,
    'shield-keyhole' ,
    'barcode' ,
    'plus-minus' ,
    'square-sliders-vertical' ,
    'sliders-v-square' ,
    'video' ,
    'video-camera' ,
    'message-middle' ,
    'comment-middle-alt' ,
    'graduation-cap' ,
    'mortar-board' ,
    'hand-holding-medical' ,
    'person-circle-check' ,
    'square-z' ,
    'message-text' ,
    'comment-alt-text' ,
    'turn-up' ,
    'level-up-alt' ,
    '0' ,
    '1' ,
    '2' ,
    '3' ,
    '4' ,
    '5' ,
    '6' ,
    '7' ,
    '8' ,
    '9' ,
    'fill-drip' ,
    'arrows-to-circle' ,
    'circle-chevron-right' ,
    'chevron-circle-right' ,
    'wagon-covered' ,
    'line-height' ,
    'bagel' ,
    'transporter-7' ,
    'at' ,
    'rectangles-mixed' ,
    'phone-arrow-up-right' ,
    'phone-arrow-up' ,
    'phone-outgoing' ,
    'trash-can' ,
    'trash-alt' ,
    'circle-l' ,
    'head-side-goggles' ,
    'head-vr' ,
    'text-height' ,
    'user-xmark' ,
    'user-times' ,
    'face-hand-yawn' ,
    'gauge-simple-min' ,
    'tachometer-slowest' ,
    'stethoscope' ,
    'coffin' ,
    'message' ,
    'comment-alt' ,
    'salad' ,
    'bowl-salad' ,
    'info' ,
    'robot-astromech' ,
    'ring-diamond' ,
    'fondue-pot' ,
    'theta' ,
    'face-hand-peeking' ,
    'square-user' ,
    'down-left-and-up-right-to-center' ,
    'compress-alt' ,
    'explosion' ,
    'file-lines' ,
    'file-alt' ,
    'file-text' ,
    'wave-square' ,
    'ring' ,
    'building-un' ,
    'dice-three' ,
    'tire-pressure-warning' ,
    'wifi-fair' ,
    'wifi2' ,
    'calendar-days' ,
    'calendar-alt' ,
    'mp3-player' ,
    'anchor-circle-check' ,
    'tally-4' ,
    'rectangle-history' ,
    'building-circle-arrow-right' ,
    'volleyball' ,
    'volleyball-ball' ,
    'sun-haze' ,
    'text-size' ,
    'ufo' ,
    'fork' ,
    'utensil-fork' ,
    'arrows-up-to-line' ,
    'mobile-signal' ,
    'barcode-scan' ,
    'sort-down' ,
    'sort-desc' ,
    'folder-arrow-down' ,
    'folder-download' ,
    'circle-minus' ,
    'minus-circle' ,
    'face-icicles' ,
    'shovel' ,
    'door-open' ,
    'films' ,
    'right-from-bracket' ,
    'sign-out-alt' ,
    'face-glasses' ,
    'nfc' ,
    'atom' ,
    'soap' ,
    'icons' ,
    'heart-music-camera-bolt' ,
    'microphone-lines-slash' ,
    'microphone-alt-slash' ,
    'closed-captioning-slash' ,
    'calculator-simple' ,
    'calculator-alt' ,
    'bridge-circle-check' ,
    'sliders-up' ,
    'sliders-v' ,
    'location-minus' ,
    'map-marker-minus' ,
    'pump-medical' ,
    'fingerprint' ,
    'ski-boot' ,
    'standard-definition' ,
    'rectangle-sd' ,
    'h1' ,
    'hand-point-right' ,
    'magnifying-glass-location' ,
    'search-location' ,
    'message-bot' ,
    'forward-step' ,
    'step-forward' ,
    'face-smile-beam' ,
    'smile-beam' ,
    'light-ceiling' ,
    'message-exclamation' ,
    'comment-alt-exclamation' ,
    'bowl-scoop' ,
    'bowl-shaved-ice' ,
    'square-x' ,
    'utility-pole-double' ,
    'flag-checkered' ,
    'chevrons-up' ,
    'chevron-double-up' ,
    'football' ,
    'football-ball' ,
    'user-vneck' ,
    'school-circle-exclamation' ,
    'crop' ,
    'angles-down' ,
    'angle-double-down' ,
    'users-rectangle' ,
    'people-roof' ,
    'square-arrow-right' ,
    'arrow-square-right' ,
    'location-plus' ,
    'map-marker-plus' ,
    'lightbulb-exclamation-on' ,
    'people-line' ,
    'beer-mug-empty' ,
    'beer' ,
    'crate-empty' ,
    'diagram-predecessor' ,
    'transporter' ,
    'calendar-circle-user' ,
    'arrow-up-long' ,
    'long-arrow-up' ,
    'person-carry-box' ,
    'person-carry' ,
    'fire-flame-simple' ,
    'burn' ,
    'person' ,
    'male' ,
    'laptop' ,
    'file-csv' ,
    'menorah' ,
    'union' ,
    'chevrons-left' ,
    'chevron-double-left' ,
    'circle-heart' ,
    'heart-circle' ,
    'truck-plane' ,
    'record-vinyl' ,
    'bring-forward' ,
    'square-p' ,
    'face-grin-stars' ,
    'grin-stars' ,
    'sigma' ,
    'camera-movie' ,
    'bong' ,
    'clarinet' ,
    'truck-flatbed' ,
    'spaghetti-monster-flying' ,
    'pastafarianism' ,
    'arrow-down-up-across-line' ,
    'leaf-heart' ,
    'house-building' ,
    'cheese-swiss' ,
    'spoon' ,
    'utensil-spoon' ,
    'jar-wheat' ,
    'envelopes-bulk' ,
    'mail-bulk' ,
    'file-circle-exclamation' ,
    'bow-arrow' ,
    'cart-xmark' ,
    'hexagon-xmark' ,
    'times-hexagon' ,
    'xmark-hexagon' ,
    'circle-h' ,
    'hospital-symbol' ,
    'merge' ,
    'pager' ,
    'cart-minus' ,
    'address-book' ,
    'contact-book' ,
    'pan-frying' ,
    'grid' ,
    'grid3' ,
    'football-helmet' ,
    'hand-love' ,
    'trees' ,
    'strikethrough' ,
    'page' ,
    'k' ,
    'diagram-previous' ,
    'gauge-min' ,
    'tachometer-alt-slowest' ,
    'folder-grid' ,
    'eggplant' ,
    'ram' ,
    'landmark-flag' ,
    'lips' ,
    'pencil' ,
    'pencil-alt' ,
    'backward' ,
    'caret-right' ,
    'comments' ,
    'paste' ,
    'file-clipboard' ,
    'desktop-arrow-down' ,
    'code-pull-request' ,
    'pumpkin' ,
    'clipboard-list' ,
    'pen-field' ,
    'blueberries' ,
    'truck-ramp-box' ,
    'truck-loading' ,
    'note' ,
    'arrow-down-to-square' ,
    'user-check' ,
    'cloud-xmark' ,
    'vial-virus' ,
    'book-blank' ,
    'book-alt' ,
    'golf-flag-hole' ,
    'message-arrow-down' ,
    'comment-alt-arrow-down' ,
    'face-unamused' ,
    'sheet-plastic' ,
    'circle-9' ,
    'blog' ,
    'user-ninja' ,
    'pencil-slash' ,
    'bowling-pins' ,
    'person-arrow-up-from-line' ,
    'down-right' ,
    'scroll-torah' ,
    'torah' ,
    'blinds-open' ,
    'fence' ,
    'up' ,
    'arrow-alt-up' ,
    'broom-ball' ,
    'quidditch' ,
    'quidditch-broom-ball' ,
    'drumstick' ,
    'square-v' ,
    'face-awesome' ,
    'gave-dandy' ,
    'dial-off' ,
    'toggle-off' ,
    'face-smile-horns' ,
    'box-archive' ,
    'archive' ,
    'grapes' ,
    'person-drowning' ,
    'dial-max' ,
    'circle-m' ,
    'calendar-image' ,
    'circle-caret-down' ,
    'caret-circle-down' ,
    'arrow-down-9-1' ,
    'sort-numeric-desc' ,
    'sort-numeric-down-alt' ,
    'face-grin-tongue-squint' ,
    'grin-tongue-squint' ,
    'shish-kebab' ,
    'spray-can' ,
    'alarm-snooze' ,
    'scarecrow' ,
    'truck-monster' ,
    'gift-card' ,
    'w' ,
    'code-pull-request-draft' ,
    'square-b' ,
    'elephant' ,
    'earth-africa' ,
    'globe-africa' ,
    'rainbow' ,
    'circle-notch' ,
    'tablet-screen-button' ,
    'tablet-alt' ,
    'paw' ,
    'message-question' ,
    'cloud' ,
    'trowel-bricks' ,
    'square-3' ,
    'face-flushed' ,
    'flushed' ,
    'hospital-user' ,
    'microwave' ,
    'tent-arrow-left-right' ,
    'cart-circle-arrow-up' ,
    'trash-clock' ,
    'gavel' ,
    'legal' ,
    'sprinkler-ceiling' ,
    'browsers' ,
    'trillium' ,
    'music-slash' ,
    'truck-ramp' ,
    'binoculars' ,
    'microphone-slash' ,
    'box-tissue' ,
    'circle-c' ,
    'star-christmas' ,
    'chart-bullet' ,
    'motorcycle' ,
    'tree-christmas' ,
    'tire-flat' ,
    'sunglasses' ,
    'badge' ,
    'message-pen' ,
    'comment-alt-edit' ,
    'message-edit' ,
    'bell-concierge' ,
    'concierge-bell' ,
    'pen-ruler' ,
    'pencil-ruler' ,
    'chess-rook-piece' ,
    'chess-rook-alt' ,
    'square-root' ,
    'album-collection-circle-plus' ,
    'people-arrows' ,
    'people-arrows-left-right' ,
    'face-angry-horns' ,
    'mars-and-venus-burst' ,
    'tombstone' ,
    'square-caret-right' ,
    'caret-square-right' ,
    'scissors' ,
    'cut' ,
    'list-music' ,
    'sun-plant-wilt' ,
    'toilets-portable' ,
    'hockey-puck' ,
    'mustache' ,
    'hyphen' ,
    'table' ,
    'user-chef' ,
    'message-image' ,
    'comment-alt-image' ,
    'users-medical' ,
    'sensor-triangle-exclamation' ,
    'sensor-alert' ,
    'magnifying-glass-arrow-right' ,
    'tachograph-digital' ,
    'digital-tachograph' ,
    'face-mask' ,
    'pickleball' ,
    'star-sharp-half' ,
    'users-slash' ,
    'clover' ,
    'meat' ,
    'reply' ,
    'mail-reply' ,
    'star-and-crescent' ,
    'empty-set' ,
    'house-fire' ,
    'square-minus' ,
    'minus-square' ,
    'helicopter' ,
    'bird' ,
    'compass' ,
    'square-caret-down' ,
    'caret-square-down' ,
    'heart-half-stroke' ,
    'heart-half-alt' ,
    'file-circle-question' ,
    'laptop-code' ,
    'joystick' ,
    'grill-fire' ,
    'rectangle-vertical-history' ,
    'swatchbook' ,
    'prescription-bottle' ,
    'bars' ,
    'navicon' ,
    'keyboard-left' ,
    'people-group' ,
    'hourglass-end' ,
    'hourglass3' ,
    'heart-crack' ,
    'heart-broken' ,
    'face-beam-hand-over-mouth' ,
    'droplet-percent' ,
    'humidity' ,
    'square-up-right' ,
    'external-link-square-alt' ,
    'face-kiss-beam' ,
    'kiss-beam' ,
    'corn' ,
    'roller-coaster' ,
    'photo-film-music' ,
    'radar' ,
    'sickle' ,
    'film' ,
    'coconut' ,
    'ruler-horizontal' ,
    'shield-cross' ,
    'cassette-tape' ,
    'square-terminal' ,
    'people-robbery' ,
    'lightbulb' ,
    'caret-left' ,
    'comment-middle' ,
    'trash-can-list' ,
    'block' ,
    'circle-exclamation' ,
    'exclamation-circle' ,
    'school-circle-xmark' ,
    'arrow-right-from-bracket' ,
    'sign-out' ,
    'face-frown-slight' ,
    'circle-chevron-down' ,
    'chevron-circle-down' ,
    'sidebar-flip' ,
    'unlock-keyhole' ,
    'unlock-alt' ,
    'temperature-list' ,
    'cloud-showers-heavy' ,
    'headphones-simple' ,
    'headphones-alt' ,
    'sitemap' ,
    'pipe-section' ,
    'space-station-moon-construction' ,
    'space-station-moon-alt' ,
    'circle-dollar-to-slot' ,
    'donate' ,
    'memory' ,
    'face-sleeping' ,
    'road-spikes' ,
    'fire-burner' ,
    'squirrel' ,
    'arrow-up-to-line' ,
    'arrow-to-top' ,
    'flag' ,
    'face-cowboy-hat' ,
    'hanukiah' ,
    'chart-scatter-3d' ,
    'square-code' ,
    'feather' ,
    'volume-low' ,
    'volume-down' ,
    'xmark-to-slot' ,
    'times-to-slot' ,
    'vote-nay' ,
    'box-taped' ,
    'box-alt' ,
    'comment-slash' ,
    'swords' ,
    'cloud-sun-rain' ,
    'album' ,
    'circle-n' ,
    'compress' ,
    'wheat-awn' ,
    'wheat-alt' ,
    'ankh' ,
    'hands-holding-child' ,
    'asterisk' ,
    'key-skeleton-left-right' ,
    'comment-lines' ,
    'luchador-mask' ,
    'luchador' ,
    'mask-luchador' ,
    'square-check' ,
    'check-square' ,
    'shredder' ,
    'book-open-cover' ,
    'book-open-alt' ,
    'sandwich' ,
    'peseta-sign' ,
    'square-parking-slash' ,
    'parking-slash' ,
    'train-tunnel' ,
    'heading' ,
    'header' ,
    'ghost' ,
    'face-anguished' ,
    'hockey-sticks' ,
    'abacus' ,
    'film-simple' ,
    'film-alt' ,
    'list' ,
    'list-squares' ,
    'tree-palm' ,
    'square-phone-flip' ,
    'phone-square-alt' ,
    'cart-plus' ,
    'gamepad' ,
    'border-center-v' ,
    'circle-dot' ,
    'dot-circle' ,
    'clipboard-medical' ,
    'face-dizzy' ,
    'dizzy' ,
    'egg' ,
    'up-to-line' ,
    'arrow-alt-to-top' ,
    'house-medical-circle-xmark' ,
    'watch-fitness' ,
    'clock-nine-thirty' ,
    'campground' ,
    'folder-plus' ,
    'jug' ,
    'futbol' ,
    'futbol-ball' ,
    'soccer-ball' ,
    'snow-blowing' ,
    'paintbrush' ,
    'paint-brush' ,
    'lock' ,
    'arrow-down-from-line' ,
    'arrow-from-top' ,
    'gas-pump' ,
    'signal-bars-slash' ,
    'signal-alt-slash' ,
    'monkey' ,
    'rectangle-pro' ,
    'pro' ,
    'house-night' ,
    'hot-tub-person' ,
    'hot-tub' ,
    'blanket' ,
    'map-location' ,
    'map-marked' ,
    'house-flood-water' ,
    'comments-question-check' ,
    'tree' ,
    'arrows-cross' ,
    'backpack' ,
    'square-small' ,
    'folder-arrow-up' ,
    'folder-upload' ,
    'bridge-lock' ,
    'crosshairs-simple' ,
    'sack-dollar' ,
    'pen-to-square' ,
    'edit' ,
    'square-sliders' ,
    'sliders-h-square' ,
    'car-side' ,
    'message-middle-top' ,
    'comment-middle-top-alt' ,
    'lightbulb-on' ,
    'knife' ,
    'utensil-knife' ,
    'share-nodes' ,
    'share-alt' ,
    'wave-sine' ,
    'heart-circle-minus' ,
    'circle-w' ,
    'circle-calendar' ,
    'calendar-circle' ,
    'hourglass-half' ,
    'hourglass2' ,
    'microscope' ,
    'sunset' ,
    'sink' ,
    'calendar-exclamation' ,
    'truck-container-empty' ,
    'hand-heart' ,
    'bag-shopping' ,
    'shopping-bag' ,
    'arrow-down-z-a' ,
    'sort-alpha-desc' ,
    'sort-alpha-down-alt' ,
    'mitten' ,
    'reply-clock' ,
    'reply-time' ,
    'person-rays' ,
    'right' ,
    'arrow-alt-right' ,
    'circle-f' ,
    'users' ,
    'face-pleading' ,
    'eye-slash' ,
    'flask-vial' ,
    'police-box' ,
    'cucumber' ,
    'head-side-brain' ,
    'hand' ,
    'hand-paper' ,
    'person-biking-mountain' ,
    'biking-mountain' ,
    'utensils-slash' ,
    'print-magnifying-glass' ,
    'print-search' ,
    'folder-bookmark' ,
    'om' ,
    'pi' ,
    'flask-round-potion' ,
    'flask-potion' ,
    'face-shush' ,
    'worm' ,
    'house-circle-xmark' ,
    'plug' ,
    'calendar-circle-exclamation' ,
    'square-i' ,
    'chevron-up' ,
    'face-saluting' ,
    'gauge-simple-low' ,
    'tachometer-slow' ,
    'face-persevering' ,
    'circle-camera' ,
    'camera-circle' ,
    'hand-spock' ,
    'spider-web' ,
    'circle-microphone' ,
    'microphone-circle' ,
    'book-arrow-up' ,
    'popsicle' ,
    'command' ,
    'blinds' ,
    'stopwatch' ,
    'saxophone' ,
    'square-2' ,
    'field-hockey-stick-ball' ,
    'field-hockey' ,
    'arrow-up-square-triangle' ,
    'sort-shapes-up-alt' ,
    'face-scream' ,
    'square-m' ,
    'camera-web' ,
    'webcam' ,
    'comment-arrow-down' ,
    'lightbulb-cfl' ,
    'window-frame-open' ,
    'face-kiss' ,
    'kiss' ,
    'bridge-circle-xmark' ,
    'period' ,
    'face-grin-tongue' ,
    'grin-tongue' ,
    'up-to-dotted-line' ,
    'thought-bubble' ,
    'raygun' ,
    'flute' ,
    'acorn' ,
    'video-arrow-up-right' ,
    'grate-droplet' ,
    'seal-exclamation' ,
    'chess-bishop' ,
    'message-sms' ,
    'coffee-beans' ,
    'hat-witch' ,
    'face-grin-wink' ,
    'grin-wink' ,
    'clock-three-thirty' ,
    'ear-deaf' ,
    'deaf' ,
    'deafness' ,
    'hard-of-hearing' ,
    'alarm-clock' ,
    'eclipse' ,
    'face-relieved' ,
    'road-circle-check' ,
    'dice-five' ,
    'octagon-minus' ,
    'minus-octagon' ,
    'square-rss' ,
    'rss-square' ,
    'face-zany' ,
    'tricycle' ,
    'land-mine-on' ,
    'square-arrow-up-left' ,
    'i-cursor' ,
    'salt-shaker' ,
    'stamp' ,
    'file-plus' ,
    'draw-square' ,
    'toilet-paper-under-slash' ,
    'toilet-paper-reverse-slash' ,
    'stairs' ,
    'drone-front' ,
    'drone-alt' ,
    'glass-empty' ,
    'dial-high' ,
    'user-helmet-safety' ,
    'user-construction' ,
    'user-hard-hat' ,
    'i' ,
    'hryvnia-sign' ,
    'hryvnia' ,
    'arrow-down-left-and-arrow-up-right-to-center' ,
    'pills' ,
    'face-grin-wide' ,
    'grin-alt' ,
    'tooth' ,
    'basketball-hoop' ,
    'objects-align-bottom' ,
    'v' ,
    'sparkles' ,
    'squid' ,
    'leafy-green' ,
    'circle-arrow-up-right' ,
    'calendars' ,
    'bangladeshi-taka-sign' ,
    'bicycle' ,
    'hammer-war' ,
    'circle-d' ,
    'spider-black-widow' ,
    'staff-snake' ,
    'rod-asclepius' ,
    'rod-snake' ,
    'staff-aesculapius' ,
    'pear' ,
    'head-side-cough-slash' ,
    'triangle' ,
    'apartment' ,
    'truck-medical' ,
    'ambulance' ,
    'pepper' ,
    'piano' ,
    'gun-squirt' ,
    'wheat-awn-circle-exclamation' ,
    'snowman' ,
    'user-alien' ,
    'shield-check' ,
    'mortar-pestle' ,
    'road-barrier' ,
    'chart-candlestick' ,
    'briefcase-blank' ,
    'school' ,
    'igloo' ,
    'bracket-round' ,
    'parenthesis' ,
    'joint' ,
    'horse-saddle' ,
    'mug-marshmallows' ,
    'filters' ,
    'bell-on' ,
    'angle-right' ,
    'dial-med' ,
    'horse' ,
    'q' ,
    'monitor-waveform' ,
    'monitor-heart-rate' ,
    'link-simple' ,
    'whistle' ,
    'g' ,
    'wine-glass-crack' ,
    'fragile' ,
    'slot-machine' ,
    'notes-medical' ,
    'car-wash' ,
    'escalator' ,
    'comment-image' ,
    'temperature-half' ,
    'temperature2' ,
    'thermometer2' ,
    'thermometer-half' ,
    'dong-sign' ,
    'donut' ,
    'doughnut' ,
    'capsules' ,
    'poo-storm' ,
    'poo-bolt' ,
    'tally-1' ,
    'face-frown-open' ,
    'frown-open' ,
    'square-dashed' ,
    'square-j' ,
    'hand-point-up' ,
    'money-bill' ,
    'arrow-up-big-small' ,
    'sort-size-up' ,
    'barcode-read' ,
    'baguette' ,
    'bowl-soft-serve' ,
    'face-holding-back-tears' ,
    'square-up' ,
    'arrow-alt-square-up' ,
    'train-subway-tunnel' ,
    'subway-tunnel' ,
    'square-exclamation' ,
    'exclamation-square' ,
    'semicolon' ,
    'bookmark' ,
    'fan-table' ,
    'align-justify' ,
    'battery-low' ,
    'battery1' ,
    'credit-card-front' ,
    'brain-arrow-curved-right' ,
    'mind-share' ,
    'umbrella-beach' ,
    'helmet-un' ,
    'location-smile' ,
    'map-marker-smile' ,
    'arrow-left-to-line' ,
    'arrow-to-left' ,
    'bullseye' ,
    'sushi' ,
    'nigiri' ,
    'message-captions' ,
    'comment-alt-captions' ,
    'trash-list' ,
    'bacon' ,
    'option' ,
    'hand-point-down' ,
    'arrow-up-from-bracket' ,
    'trash-plus' ,
    'objects-align-top' ,
    'folder' ,
    'folder-blank' ,
    'face-anxious-sweat' ,
    'credit-card-blank' ,
    'file-waveform' ,
    'file-medical-alt' ,
    'microchip-ai' ,
    'mug' ,
    'plane-up-slash' ,
    'radiation' ,
    'pen-circle' ,
    'chart-simple' ,
    'crutches' ,
    'circle-parking' ,
    'parking-circle' ,
    'mars-stroke' ,
    'leaf-oak' ,
    'square-bolt' ,
    'vial' ,
    'gauge' ,
    'dashboard' ,
    'gauge-med' ,
    'tachometer-alt-average' ,
    'wand-magic-sparkles' ,
    'magic-wand-sparkles' ,
    'lambda' ,
    'e' ,
    'pizza' ,
    'bowl-chopsticks-noodles' ,
    'h3' ,
    'pen-clip' ,
    'pen-alt' ,
    'bridge-circle-exclamation' ,
    'badge-percent' ,
    'user' ,
    'sensor' ,
    'comma' ,
    'school-circle-check' ,
    'toilet-paper-under' ,
    'toilet-paper-reverse' ,
    'light-emergency' ,
    'arrow-down-to-arc' ,
    'dumpster' ,
    'van-shuttle' ,
    'shuttle-van' ,
    'building-user' ,
    'light-switch' ,
    'square-caret-left' ,
    'caret-square-left' ,
    'highlighter' ,
    'wave-pulse' ,
    'heart-rate' ,
    'key' ,
    'hat-santa' ,
    'tamale' ,
    'box-check' ,
    'bullhorn' ,
    'steak' ,
    'location-crosshairs-slash' ,
    'location-slash' ,
    'person-dolly' ,
    'globe' ,
    'synagogue' ,
    'file-chart-column' ,
    'file-chart-line' ,
    'person-half-dress' ,
    'folder-image' ,
    'calendar-pen' ,
    'calendar-edit' ,
    'road-bridge' ,
    'face-smile-tear' ,
    'message-plus' ,
    'comment-alt-plus' ,
    'location-arrow' ,
    'c' ,
    'tablet-button' ,
    'rectangle-history-circle-user' ,
    'building-lock' ,
    'chart-line-up' ,
    'mailbox' ,
    'truck-bolt' ,
    'pizza-slice' ,
    'money-bill-wave' ,
    'chart-area' ,
    'area-chart' ,
    'house-flag' ,
    'person-circle-minus' ,
    'scalpel' ,
    'ban' ,
    'cancel' ,
    'bell-exclamation' ,
    'circle-bookmark' ,
    'bookmark-circle' ,
    'egg-fried' ,
    'face-weary' ,
    'uniform-martial-arts' ,
    'camera-rotate' ,
    'sun-dust' ,
    'comment-text' ,
    'spray-can-sparkles' ,
    'air-freshener' ,
    'signal-bars' ,
    'signal-alt' ,
    'signal-alt4' ,
    'signal-bars-strong' ,
    'diamond-exclamation' ,
    'star' ,
    'dial-min' ,
    'repeat' ,
    'cross' ,
    'page-caret-down' ,
    'file-caret-down' ,
    'box' ,
    'venus-mars' ,
    'clock-seven-thirty' ,
    'arrow-pointer' ,
    'mouse-pointer' ,
    'clock-four-thirty' ,
    'signal-bars-good' ,
    'signal-alt3' ,
    'cactus' ,
    'maximize' ,
    'expand-arrows-alt' ,
    'charging-station' ,
    'shapes' ,
    'triangle-circle-square' ,
    'plane-tail' ,
    'gauge-simple-max' ,
    'tachometer-fastest' ,
    'circle-u' ,
    'shield-slash' ,
    'square-phone-hangup' ,
    'phone-square-down' ,
    'arrow-up-left' ,
    'transporter-1' ,
    'peanuts' ,
    'shuffle' ,
    'random' ,
    'person-running' ,
    'running' ,
    'mobile-retro' ,
    'grip-lines-vertical' ,
    'arrow-up-from-square' ,
    'file-dashed-line' ,
    'page-break' ,
    'bracket-curly-right' ,
    'spider' ,
    'clock-three' ,
    'hands-bound' ,
    'scalpel-line-dashed' ,
    'scalpel-path' ,
    'file-invoice-dollar' ,
    'pipe-smoking' ,
    'face-astonished' ,
    'window' ,
    'plane-circle-exclamation' ,
    'ear' ,
    'file-lock' ,
    'diagram-venn' ,
    'x-ray' ,
    'goal-net' ,
    'coffin-cross' ,
    'spell-check' ,
    'location-xmark' ,
    'map-marker-times' ,
    'map-marker-xmark' ,
    'lasso' ,
    'slash' ,
    'person-to-portal' ,
    'portal-enter' ,
    'calendar-star' ,
    'computer-mouse' ,
    'mouse' ,
    'arrow-right-to-bracket' ,
    'sign-in' ,
    'pegasus' ,
    'files-medical' ,
    'nfc-lock' ,
    'person-ski-lift' ,
    'ski-lift' ,
    'square-6' ,
    'shop-slash' ,
    'store-alt-slash' ,
    'wind-turbine' ,
    'sliders-simple' ,
    'badge-sheriff' ,
    'server' ,
    'virus-covid-slash' ,
    'intersection' ,
    'shop-lock' ,
    'family' ,
    'hourglass-start' ,
    'hourglass1' ,
    'user-hair-buns' ,
    'blender-phone' ,
    'hourglass-clock' ,
    'person-seat-reclined' ,
    'paper-plane-top' ,
    'paper-plane-alt' ,
    'send' ,
    'message-arrow-up' ,
    'comment-alt-arrow-up' ,
    'lightbulb-exclamation' ,
    'layer-minus' ,
    'layer-group-minus' ,
    'circle-e' ,
    'building-wheat' ,
    'gauge-max' ,
    'tachometer-alt-fastest' ,
    'person-breastfeeding' ,
    'apostrophe' ,
    'fire-hydrant' ,
    'right-to-bracket' ,
    'sign-in-alt' ,
    'video-plus' ,
    'square-right' ,
    'arrow-alt-square-right' ,
    'comment-smile' ,
    'venus' ,
    'passport' ,
    'inbox-in' ,
    'inbox-arrow-down' ,
    'heart-pulse' ,
    'heartbeat' ,
    'circle-8' ,
    'clouds-moon' ,
    'clock-ten-thirty' ,
    'people-carry-box' ,
    'people-carry' ,
    'folder-user' ,
    'trash-can-xmark' ,
    'temperature-high' ,
    'microchip' ,
    'left-long-to-line' ,
    'crown' ,
    'weight-hanging' ,
    'xmarks-lines' ,
    'file-prescription' ,
    'calendar-range' ,
    'flower-daffodil' ,
    'hand-back-point-up' ,
    'weight-scale' ,
    'weight' ,
    'star-exclamation' ,
    'books' ,
    'user-group' ,
    'user-friends' ,
    'arrow-up-a-z' ,
    'sort-alpha-up' ,
    'layer-plus' ,
    'layer-group-plus' ,
    'play-pause' ,
    'block-question' ,
    'snooze' ,
    'zzz' ,
    'scanner-image' ,
    'tv-retro' ,
    'square-t' ,
    'farm' ,
    'barn-silo' ,
    'chess-knight' ,
    'bars-sort' ,
    'pallet-boxes' ,
    'palette-boxes' ,
    'pallet-alt' ,
    'face-laugh-squint' ,
    'laugh-squint' ,
    'code-simple' ,
    'bolt-slash' ,
    'panel-fire' ,
    'binary-circle-check' ,
    'comment-minus' ,
    'burrito' ,
    'violin' ,
    'objects-column' ,
    'square-chevron-down' ,
    'chevron-square-down' ,
    'comment-plus' ,
    'triangle-instrument' ,
    'triangle-music' ,
    'wheelchair' ,
    'user-pilot-tie' ,
    'piano-keyboard' ,
    'bed-empty' ,
    'circle-arrow-up' ,
    'arrow-circle-up' ,
    'toggle-on' ,
    'rectangle-vertical' ,
    'rectangle-portrait' ,
    'person-walking' ,
    'walking' ,
    'l' ,
    'signal-stream' ,
    'down-to-bracket' ,
    'circle-z' ,
    'stars' ,
    'fire' ,
    'bed-pulse' ,
    'procedures' ,
    'house-day' ,
    'shuttle-space' ,
    'space-shuttle' ,
    'shirt-long-sleeve' ,
    'chart-pie-simple' ,
    'chart-pie-alt' ,
    'face-laugh' ,
    'laugh' ,
    'folder-open' ,
    'album-collection-circle-user' ,
    'candy' ,
    'bowl-hot' ,
    'soup' ,
    'flatbread' ,
    'heart-circle-plus' ,
    'code-fork' ,
    'city' ,
    'signal-bars-weak' ,
    'signal-alt1' ,
    'microphone-lines' ,
    'microphone-alt' ,
    'clock-twelve' ,
    'pepper-hot' ,
    'citrus-slice' ,
    'sheep' ,
    'unlock' ,
    'colon-sign' ,
    'headset' ,
    'badger-honey' ,
    'h4' ,
    'store-slash' ,
    'road-circle-xmark' ,
    'signal-slash' ,
    'user-minus' ,
    'mars-stroke-up' ,
    'mars-stroke-v' ,
    'champagne-glasses' ,
    'glass-cheers' ,
    'taco' ,
    'hexagon-plus' ,
    'plus-hexagon' ,
    'clipboard' ,
    'house-circle-exclamation' ,
    'file-arrow-up' ,
    'file-upload' ,
    'wifi' ,
    'wifi3' ,
    'wifi-strong' ,
    'messages' ,
    'comments-alt' ,
    'bath' ,
    'bathtub' ,
    'umbrella-simple' ,
    'umbrella-alt' ,
    'rectangle-history-circle-plus' ,
    'underline' ,
    'prescription-bottle-pill' ,
    'user-pen' ,
    'user-edit' ,
    'binary-slash' ,
    'square-o' ,
    'signature' ,
    'stroopwafel' ,
    'bold' ,
    'anchor-lock' ,
    'building-ngo' ,
    'transporter-3' ,
    'engine-warning' ,
    'engine-exclamation' ,
    'circle-down-right' ,
    'square-k' ,
    'manat-sign' ,
    'money-check-pen' ,
    'money-check-edit' ,
    'not-equal' ,
    'border-top-left' ,
    'border-style' ,
    'map-location-dot' ,
    'map-marked-alt' ,
    'tilde' ,
    'jedi' ,
    'square-poll-vertical' ,
    'poll' ,
    'arrow-down-square-triangle' ,
    'sort-shapes-down-alt' ,
    'mug-hot' ,
    'dog-leashed' ,
    'car-battery' ,
    'battery-car' ,
    'face-downcast-sweat' ,
    'mailbox-flag-up' ,
    'memo-circle-info' ,
    'gift' ,
    'dice-two' ,
    'volume' ,
    'volume-medium' ,
    'transporter-5' ,
    'gauge-circle-bolt' ,
    'coin-front' ,
    'file-slash' ,
    'message-arrow-up-right' ,
    'treasure-chest' ,
    'chess-queen' ,
    'paintbrush-fine' ,
    'paint-brush-alt' ,
    'paint-brush-fine' ,
    'paintbrush-alt' ,
    'glasses' ,
    'hood-cloak' ,
    'square-quote' ,
    'up-left' ,
    'bring-front' ,
    'chess-board' ,
    'burger-cheese' ,
    'cheeseburger' ,
    'building-circle-check' ,
    'repeat-1' ,
    'arrow-down-to-line' ,
    'arrow-to-bottom' ,
    'grid-5' ,
    'right-long-to-line' ,
    'person-chalkboard' ,
    'mars-stroke-right' ,
    'mars-stroke-h' ,
    'hand-back-fist' ,
    'hand-rock' ,
    'tally' ,
    'tally5' ,
    'square-caret-up' ,
    'caret-square-up' ,
    'cloud-showers-water' ,
    'chart-bar' ,
    'bar-chart' ,
    'hands-bubbles' ,
    'hands-wash' ,
    'less-than-equal' ,
    'train' ,
    'up-from-dotted-line' ,
    'eye-low-vision' ,
    'low-vision' ,
    'traffic-light-go' ,
    'face-exhaling' ,
    'sensor-fire' ,
    'user-unlock' ,
    'hexagon-divide' ,
    '00' ,
    'crow' ,
    'cassette-betamax' ,
    'betamax' ,
    'sailboat' ,
    'window-restore' ,
    'nfc-magnifying-glass' ,
    'file-binary' ,
    'circle-v' ,
    'square-plus' ,
    'plus-square' ,
    'bowl-scoops' ,
    'mistletoe' ,
    'custard' ,
    'lacrosse-stick' ,
    'hockey-mask' ,
    'sunrise' ,
    'panel-ews' ,
    'torii-gate' ,
    'cloud-exclamation' ,
    'message-lines' ,
    'comment-alt-lines' ,
    'frog' ,
    'bucket' ,
    'floppy-disk-pen' ,
    'image' ,
    'window-frame' ,
    'microphone' ,
    'cow' ,
    'square-ring' ,
    'down-from-line' ,
    'arrow-alt-from-top' ,
    'caret-up' ,
    'shield-xmark' ,
    'shield-times' ,
    'screwdriver' ,
    'circle-sort-down' ,
    'sort-circle-down' ,
    'folder-closed' ,
    'house-tsunami' ,
    'square-nfi' ,
    'forklift' ,
    'arrow-up-from-ground-water' ,
    'bracket-square-right' ,
    'martini-glass' ,
    'glass-martini-alt' ,
    'rotate-left' ,
    'rotate-back' ,
    'rotate-backward' ,
    'undo-alt' ,
    'table-columns' ,
    'columns' ,
    'square-a' ,
    'tick' ,
    'lemon' ,
    'head-side-mask' ,
    'handshake' ,
    'gem' ,
    'dolly' ,
    'dolly-box' ,
    'smoking' ,
    'minimize' ,
    'compress-arrows-alt' ,
    'refrigerator' ,
    'monument' ,
    'octagon-xmark' ,
    'times-octagon' ,
    'xmark-octagon' ,
    'align-slash' ,
    'snowplow' ,
    'angles-right' ,
    'angle-double-right' ,
    'truck-ramp-couch' ,
    'truck-couch' ,
    'cannabis' ,
    'circle-play' ,
    'play-circle' ,
    'arrow-up-right-and-arrow-down-left-from-center' ,
    'tablets' ,
    '360-degrees' ,
    'ethernet' ,
    'euro-sign' ,
    'eur' ,
    'euro' ,
    'chair' ,
    'circle-check' ,
    'check-circle' ,
    'square-dashed-circle-plus' ,
    'money-simple-from-bracket' ,
    'bat' ,
    'circle-stop' ,
    'stop-circle' ,
    'head-side-headphones' ,
    'phone-rotary' ,
    'compass-drafting' ,
    'drafting-compass' ,
    'plate-wheat' ,
    'calendar-circle-minus' ,
    'chopsticks' ,
    'car-wrench' ,
    'car-mechanic' ,
    'icicles' ,
    'person-shelter' ,
    'neuter' ,
    'id-badge' ,
    'kazoo' ,
    'marker' ,
    'face-laugh-beam' ,
    'laugh-beam' ,
    'square-arrow-down-left' ,
    'battery-bolt' ,
    'tree-large' ,
    'helicopter-symbol' ,
    'aperture' ,
    'universal-access' ,
    'file-magnifying-glass' ,
    'file-search' ,
    'up-right' ,
    'circle-chevron-up' ,
    'chevron-circle-up' ,
    'user-police' ,
    'lari-sign' ,
    'volcano' ,
    'teddy-bear' ,
    'stocking' ,
    'person-walking-dashed-line-arrow-right' ,
    'image-slash' ,
    'mask-snorkel' ,
    'smoke' ,
    'sterling-sign' ,
    'gbp' ,
    'pound-sign' ,
    'battery-exclamation' ,
    'viruses' ,
    'square-person-confined' ,
    'user-tie' ,
    'arrow-down-long' ,
    'long-arrow-down' ,
    'tent-arrow-down-to-line' ,
    'certificate' ,
    'crystal-ball' ,
    'reply-all' ,
    'mail-reply-all' ,
    'suitcase' ,
    'person-skating' ,
    'skating' ,
    'star-shooting' ,
    'binary-lock' ,
    'filter-circle-dollar' ,
    'funnel-dollar' ,
    'camera-retro' ,
    'circle-arrow-down' ,
    'arrow-circle-down' ,
    'comment-pen' ,
    'comment-edit' ,
    'file-import' ,
    'arrow-right-to-file' ,
    'banjo' ,
    'square-arrow-up-right' ,
    'external-link-square' ,
    'light-emergency-on' ,
    'kerning' ,
    'box-open' ,
    'square-f' ,
    'scroll' ,
    'spa' ,
    'arrow-left-from-line' ,
    'arrow-from-right' ,
    'strawberry' ,
    'location-pin-lock' ,
    'pause' ,
    'clock-eight-thirty' ,
    'plane-engines' ,
    'plane-alt' ,
    'hill-avalanche' ,
    'temperature-empty' ,
    'temperature0' ,
    'thermometer0' ,
    'thermometer-empty' ,
    'bomb' ,
    'gauge-low' ,
    'tachometer-alt-slow' ,
    'registered' ,
    'trash-can-plus' ,
    'address-card' ,
    'contact-card' ,
    'vcard' ,
    'scale-unbalanced-flip' ,
    'balance-scale-right' ,
    'globe-snow' ,
    'subscript' ,
    'diamond-turn-right' ,
    'directions' ,
    'integral' ,
    'burst' ,
    'house-laptop' ,
    'laptop-house' ,
    'face-tired' ,
    'tired' ,
    'money-bills' ,
    'blinds-raised' ,
    'smog' ,
    'ufo-beam' ,
    'circle-caret-up' ,
    'caret-circle-up' ,
    'user-vneck-hair-long' ,
    'square-a-lock' ,
    'crutch' ,
    'gas-pump-slash' ,
    'font-awesome' ,
    'font-awesome-flag' ,
    'font-awesome-logo-full' ,
    'cloud-arrow-up' ,
    'cloud-upload' ,
    'cloud-upload-alt' ,
    'palette' ,
    'transporter-4' ,
    'objects-align-right' ,
    'arrows-turn-right' ,
    'vest' ,
    'pig' ,
    'inbox-full' ,
    'circle-envelope' ,
    'envelope-circle' ,
    'triangle-person-digging' ,
    'construction' ,
    'ferry' ,
    'bullseye-arrow' ,
    'arrows-down-to-people' ,
    'seedling' ,
    'sprout' ,
    'clock-seven' ,
    'left-right' ,
    'arrows-alt-h' ,
    'boxes-packing' ,
    'circle-arrow-left' ,
    'arrow-circle-left' ,
    'flashlight' ,
    'group-arrows-rotate' ,
    'bowl-food' ,
    'square-9' ,
    'candy-cane' ,
    'arrow-down-wide-short' ,
    'sort-amount-asc' ,
    'sort-amount-down' ,
    'square-dollar' ,
    'dollar-square' ,
    'usd-square' ,
    'phone-arrow-right' ,
    'hand-holding-seedling' ,
    'message-check' ,
    'comment-alt-check' ,
    'cloud-bolt' ,
    'thunderstorm' ,
    'text-slash' ,
    'remove-format' ,
    'watch' ,
    'circle-down-left' ,
    'text' ,
    'projector' ,
    'face-smile-wink' ,
    'smile-wink' ,
    'tombstone-blank' ,
    'tombstone-alt' ,
    'chess-king-piece' ,
    'chess-king-alt' ,
    'circle-6' ,
    'left' ,
    'arrow-alt-left' ,
    'file-word' ,
    'file-powerpoint' ,
    'square-down' ,
    'arrow-alt-square-down' ,
    'objects-align-center-vertical' ,
    'arrows-left-right' ,
    'arrows-h' ,
    'house-lock' ,
    'cloud-arrow-down' ,
    'cloud-download' ,
    'cloud-download-alt' ,
    'wreath' ,
    'children' ,
    'meter-droplet' ,
    'chalkboard' ,
    'blackboard' ,
    'user-large-slash' ,
    'user-alt-slash' ,
    'signal-strong' ,
    'signal4' ,
    'lollipop' ,
    'lollypop' ,
    'list-tree' ,
    'envelope-open' ,
    'draw-circle' ,
    'cat-space' ,
    'handshake-simple-slash' ,
    'handshake-alt-slash' ,
    'rabbit-running' ,
    'rabbit-fast' ,
    'memo-pad' ,
    'mattress-pillow' ,
    'alarm-plus' ,
    'alicorn' ,
    'comment-question' ,
    'gingerbread-man' ,
    'guarani-sign' ,
    'burger-fries' ,
    'mug-tea' ,
    'border-top' ,
    'arrows-rotate' ,
    'refresh' ,
    'sync' ,
    'circle-book-open' ,
    'book-circle' ,
    'arrows-to-dotted-line' ,
    'fire-extinguisher' ,
    'garage-open' ,
    'shelves-empty' ,
    'cruzeiro-sign' ,
    'watch-apple' ,
    'watch-calculator' ,
    'list-dropdown' ,
    'cabinet-filing' ,
    'burger-soda' ,
    'square-arrow-up' ,
    'arrow-square-up' ,
    'greater-than-equal' ,
    'pallet-box' ,
    'face-confounded' ,
    'shield-halved' ,
    'shield-alt' ,
    'truck-plow' ,
    'book-atlas' ,
    'atlas' ,
    'virus' ,
    'comment-middle-top' ,
    'envelope-circle-check' ,
    'layer-group' ,
    'restroom-simple' ,
    'arrows-to-dot' ,
    'border-outer' ,
    'hashtag-lock' ,
    'clock-two-thirty' ,
    'archway' ,
    'heart-circle-check' ,
    'house-chimney-crack' ,
    'house-damage' ,
    'file-zipper' ,
    'file-archive' ,
    'heart-half' ,
    'comment-check' ,
    'square' ,
    'memo' ,
    'martini-glass-empty' ,
    'glass-martini' ,
    'couch' ,
    'cedi-sign' ,
    'italic' ,
    'glass-citrus' ,
    'calendar-lines-pen' ,
    'church' ,
    'person-snowmobiling' ,
    'snowmobile' ,
    'face-hushed' ,
    'comments-dollar' ,
    'pickaxe' ,
    'link-simple-slash' ,
    'democrat' ,
    'face-confused' ,
    'pinball' ,
    'z' ,
    'person-skiing' ,
    'skiing' ,
    'deer' ,
    'input-pipe' ,
    'road-lock' ,
    'a' ,
    'bookmark-slash' ,
    'temperature-arrow-down' ,
    'temperature-down' ,
    'mace' ,
    'feather-pointed' ,
    'feather-alt' ,
    'sausage' ,
    'trash-can-clock' ,
    'p' ,
    'snowflake' ,
    'stomach' ,
    'newspaper' ,
    'rectangle-ad' ,
    'ad' ,
    'guitar-electric' ,
    'arrow-turn-down-right' ,
    'moon-cloud' ,
    'bread-slice-butter' ,
    'circle-arrow-right' ,
    'arrow-circle-right' ,
    'user-group-crown' ,
    'users-crown' ,
    'circle-i' ,
    'toilet-paper-check' ,
    'filter-circle-xmark' ,
    'locust' ,
    'sort' ,
    'unsorted' ,
    'list-ol' ,
    'list12' ,
    'list-numeric' ,
    'chart-waterfall' ,
    'face-party' ,
    'kidneys' ,
    'wifi-exclamation' ,
    'chart-network' ,
    'person-dress-burst' ,
    'dice-d4' ,
    'money-check-dollar' ,
    'money-check-alt' ,
    'vector-square' ,
    'bread-slice' ,
    'language' ,
    'wheat-awn-slash' ,
    'face-kiss-wink-heart' ,
    'kiss-wink-heart' ,
    'dagger' ,
    'podium' ,
    'memo-circle-check' ,
    'route-highway' ,
    'down-to-line' ,
    'arrow-alt-to-bottom' ,
    'filter' ,
    'square-g' ,
    'circle-phone' ,
    'phone-circle' ,
    'clipboard-prescription' ,
    'user-nurse-hair' ,
    'question' ,
    'file-signature' ,
    'toggle-large-on' ,
    'up-down-left-right' ,
    'arrows-alt' ,
    'dryer-heat' ,
    'dryer-alt' ,
    'house-chimney-user' ,
    'hand-holding-heart' ,
    'arrow-up-small-big' ,
    'sort-size-up-alt' ,
    'train-track' ,
    'puzzle-piece' ,
    'money-check' ,
    'star-half-stroke' ,
    'star-half-alt' ,
    'file-exclamation' ,
    'code' ,
    'whiskey-glass' ,
    'glass-whiskey' ,
    'moon-stars' ,
    'building-circle-exclamation' ,
    'clothes-hanger' ,
    'mobile-notch' ,
    'mobile-iphone' ,
    'magnifying-glass-chart' ,
    'arrow-up-right-from-square' ,
    'external-link' ,
    'cubes-stacked' ,
    'images-user' ,
    'won-sign' ,
    'krw' ,
    'won' ,
    'image-polaroid-user' ,
    'virus-covid' ,
    'square-ellipsis' ,
    'pie' ,
    'chess-knight-piece' ,
    'chess-knight-alt' ,
    'austral-sign' ,
    'cloud-plus' ,
    'f' ,
    'leaf' ,
    'bed-bunk' ,
    'road' ,
    'taxi' ,
    'cab' ,
    'person-circle-plus' ,
    'chart-pie' ,
    'pie-chart' ,
    'bolt-lightning' ,
    'clock-eight' ,
    'sack-xmark' ,
    'file-excel' ,
    'file-contract' ,
    'fish-fins' ,
    'circle-q' ,
    'building-flag' ,
    'face-grin-beam' ,
    'grin-beam' ,
    'object-ungroup' ,
    'face-disguise' ,
    'circle-arrow-down-right' ,
    'alien-8bit' ,
    'alien-monster' ,
    'hand-point-ribbon' ,
    'poop' ,
    'object-exclude' ,
    'telescope' ,
    'location-pin' ,
    'map-marker' ,
    'square-list' ,
    'kaaba' ,
    'toilet-paper' ,
    'helmet-safety' ,
    'hard-hat' ,
    'hat-hard' ,
    'comment-code' ,
    'sim-cards' ,
    'starship' ,
    'eject' ,
    'circle-right' ,
    'arrow-alt-circle-right' ,
    'plane-circle-check' ,
    'seal' ,
    'user-cowboy' ,
    'hexagon-vertical-nft' ,
    'face-rolling-eyes' ,
    'meh-rolling-eyes' ,
    'bread-loaf' ,
    'rings-wedding' ,
    'object-group' ,
    'french-fries' ,
    'chart-line' ,
    'line-chart' ,
    'calendar-arrow-down' ,
    'calendar-download' ,
    'send-back' ,
    'mask-ventilator' ,
    'signature-lock' ,
    'arrow-right' ,
    'signs-post' ,
    'map-signs' ,
    'octagon-plus' ,
    'plus-octagon' ,
    'cash-register' ,
    'person-circle-question' ,
    'melon-slice' ,
    'space-station-moon' ,
    'message-smile' ,
    'comment-alt-smile' ,
    'cup-straw' ,
    'left-from-line' ,
    'arrow-alt-from-right' ,
    'h' ,
    'basket-shopping-simple' ,
    'shopping-basket-alt' ,
    'hands-holding-heart' ,
    'hands-heart' ,
    'clock-nine' ,
    'tarp' ,
    'face-sleepy' ,
    'hand-horns' ,
    'screwdriver-wrench' ,
    'tools' ,
    'arrows-to-eye' ,
    'circle-three-quarters' ,
    'trophy-star' ,
    'trophy-alt' ,
    'plug-circle-bolt' ,
    'face-thermometer' ,
    'shirt-running' ,
    'book-circle-arrow-up' ,
    'face-nauseated' ,
    'heart' ,
    'file-chart-pie' ,
    'mars-and-venus' ,
    'house-user' ,
    'home-user' ,
    'circle-arrow-down-left' ,
    'dumpster-fire' ,
    'hexagon-minus' ,
    'minus-hexagon' ,
    'left-to-line' ,
    'arrow-alt-to-left' ,
    'house-crack' ,
    'paw-simple' ,
    'paw-alt' ,
    'arrow-left-long-to-line' ,
    'brackets-round' ,
    'parentheses' ,
    'martini-glass-citrus' ,
    'cocktail' ,
    'user-shakespeare' ,
    'arrow-right-to-arc' ,
    'face-surprise' ,
    'surprise' ,
    'bottle-water' ,
    'circle-pause' ,
    'pause-circle' ,
    'gauge-circle-plus' ,
    'folders' ,
    'angel' ,
    'value-absolute' ,
    'rabbit' ,
    'toilet-paper-slash' ,
    'apple-whole' ,
    'apple-alt' ,
    'kitchen-set' ,
    'diamond-half' ,
    'lock-keyhole' ,
    'lock-alt' ,
    'r' ,
    'temperature-quarter' ,
    'temperature1' ,
    'thermometer1' ,
    'thermometer-quarter' ,
    'square-info' ,
    'info-square' ,
    'wifi-slash' ,
    'toilet-paper-xmark' ,
    'hands-holding-dollar' ,
    'hands-usd' ,
    'cube' ,
    'arrow-down-triangle-square' ,
    'sort-shapes-down' ,
    'bitcoin-sign' ,
    'shutters' ,
    'shield-dog' ,
    'solar-panel' ,
    'lock-open' ,
    'table-tree' ,
    'house-chimney-heart' ,
    'tally-3' ,
    'elevator' ,
    'money-bill-transfer' ,
    'money-bill-trend-up' ,
    'house-flood-water-circle-arrow-right' ,
    'square-poll-horizontal' ,
    'poll-h' ,
    'circle' ,
    'cart-circle-exclamation' ,
    'sword' ,
    'backward-fast' ,
    'fast-backward' ,
    'recycle' ,
    'user-astronaut' ,
    'interrobang' ,
    'plane-slash' ,
    'circle-dashed' ,
    'trademark' ,
    'basketball' ,
    'basketball-ball' ,
    'fork-knife' ,
    'utensils-alt' ,
    'satellite-dish' ,
    'badge-check' ,
    'circle-up' ,
    'arrow-alt-circle-up' ,
    'slider' ,
    'mobile-screen-button' ,
    'mobile-alt' ,
    'clock-one-thirty' ,
    'inbox-out' ,
    'inbox-arrow-up' ,
    'cloud-slash' ,
    'volume-high' ,
    'volume-up' ,
    'users-rays' ,
    'wallet' ,
    'octagon-check' ,
    'flatbread-stuffed' ,
    'clipboard-check' ,
    'cart-circle-plus' ,
    'truck-clock' ,
    'shipping-timed' ,
    'pool-8-ball' ,
    'file-audio' ,
    'turn-down-left' ,
    'lock-hashtag' ,
    'chart-radar' ,
    'staff' ,
    'burger' ,
    'hamburger' ,
    'utility-pole' ,
    'transporter-6' ,
    'wrench' ,
    'bugs' ,
    'vector-polygon' ,
    'diagram-nested' ,
    'rupee-sign' ,
    'rupee' ,
    'file-image' ,
    'circle-question' ,
    'question-circle' ,
    'image-user' ,
    'buoy' ,
    'plane-departure' ,
    'handshake-slash' ,
    'book-bookmark' ,
    'border-center-h' ,
    'can-food' ,
    'typewriter' ,
    'arrow-right-from-arc' ,
    'circle-k' ,
    'face-hand-over-mouth' ,
    'popcorn' ,
    'house-water' ,
    'house-flood' ,
    'object-subtract' ,
    'code-branch' ,
    'warehouse-full' ,
    'warehouse-alt' ,
    'hat-cowboy' ,
    'bridge' ,
    'phone-flip' ,
    'phone-alt' ,
    'arrow-down-from-dotted-line' ,
    'square-quarters' ,
    'truck-front' ,
    'cat' ,
    'trash-xmark' ,
    'circle-caret-left' ,
    'caret-circle-left' ,
    'files' ,
    'anchor-circle-exclamation' ,
    'face-clouds' ,
    'user-crown' ,
    'truck-field' ,
    'route' ,
    'cart-circle-check' ,
    'clipboard-question' ,
    'panorama' ,
    'comment-medical' ,
    'teeth-open' ,
    'user-tie-hair-long' ,
    'file-circle-minus' ,
    'head-side-medical' ,
    'tags' ,
    'wine-glass' ,
    'forward-fast' ,
    'fast-forward' ,
    'face-meh-blank' ,
    'meh-blank' ,
    'user-robot' ,
    'square-parking' ,
    'parking' ,
    'card-diamond' ,
    'face-zipper' ,
    'face-raised-eyebrow' ,
    'house-signal' ,
    'square-chevron-up' ,
    'chevron-square-up' ,
    'nfc-symbol' ,
    'bars-progress' ,
    'tasks-alt' ,
    'faucet-drip' ,
    'arrows-to-line' ,
    'dolphin' ,
    'arrow-up-right' ,
    'circle-r' ,
    'cart-flatbed' ,
    'dolly-flatbed' ,
    'ban-smoking' ,
    'smoking-ban' ,
    'circle-sort-up' ,
    'sort-circle-up' ,
    'terminal' ,
    'mobile-button' ,
    'house-medical-flag' ,
    'basket-shopping' ,
    'shopping-basket' ,
    'tape' ,
    'chestnut' ,
    'bus-simple' ,
    'bus-alt' ,
    'eye' ,
    'face-sad-cry' ,
    'sad-cry' ,
    'heat' ,
    'ticket-airline' ,
    'boot-heeled' ,
    'arrows-minimize' ,
    'compress-arrows' ,
    'audio-description' ,
    'person-military-to-person' ,
    'file-shield' ,
    'hexagon' ,
    'manhole' ,
    'user-slash' ,
    'pen' ,
    'tower-observation' ,
    'floppy-disks' ,
    'toilet-paper-blank-under' ,
    'toilet-paper-reverse-alt' ,
    'file-code' ,
    'signal' ,
    'signal5' ,
    'signal-perfect' ,
    'pump' ,
    'bus' ,
    'heart-circle-xmark' ,
    'arrow-up-left-from-circle' ,
    'house-chimney' ,
    'home-lg' ,
    'window-maximize' ,
    'dryer' ,
    'face-frown' ,
    'frown' ,
    'chess-bishop-piece' ,
    'chess-bishop-alt' ,
    'shirt-tank-top' ,
    'diploma' ,
    'scroll-ribbon' ,
    'screencast' ,
    'walker' ,
    'prescription' ,
    'shop' ,
    'store-alt' ,
    'floppy-disk' ,
    'save' ,
    'vihara' ,
    'face-kiss-closed-eyes' ,
    'scale-unbalanced' ,
    'balance-scale-left' ,
    'file-user' ,
    'user-police-tie' ,
    'face-tongue-money' ,
    'tennis-ball' ,
    'square-l' ,
    'sort-up' ,
    'sort-asc' ,
    'calendar-arrow-up' ,
    'calendar-upload' ,
    'comment-dots' ,
    'commenting' ,
    'plant-wilt' ,
    'scarf' ,
    'album-circle-plus' ,
    'user-nurse-hair-long' ,
    'diamond' ,
    'square-left' ,
    'arrow-alt-square-left' ,
    'face-grin-squint' ,
    'grin-squint' ,
    'circle-ellipsis-vertical' ,
    'hand-holding-dollar' ,
    'hand-holding-usd' ,
    'grid-dividers' ,
    'bacterium' ,
    'hand-pointer' ,
    'drum-steelpan' ,
    'hand-scissors' ,
    'hands-praying' ,
    'praying-hands' ,
    'face-pensive' ,
    'user-music' ,
    'arrow-rotate-right' ,
    'arrow-right-rotate' ,
    'arrow-rotate-forward' ,
    'redo' ,
    'messages-dollar' ,
    'comments-alt-dollar' ,
    'sensor-on' ,
    'balloon' ,
    'biohazard' ,
    'chess-queen-piece' ,
    'chess-queen-alt' ,
    'location-crosshairs' ,
    'location' ,
    'mars-double' ,
    'house-person-leave' ,
    'house-leave' ,
    'house-person-depart' ,
    'ruler-triangle' ,
    'card-club' ,
    'child-dress' ,
    'users-between-lines' ,
    'lungs-virus' ,
    'spinner-third' ,
    'face-grin-tears' ,
    'grin-tears' ,
    'phone' ,
    'computer-mouse-scrollwheel' ,
    'mouse-alt' ,
    'calendar-xmark' ,
    'calendar-times' ,
    'child-reaching' ,
    'table-layout' ,
    'narwhal' ,
    'ramp-loading' ,
    'calendar-circle-plus' ,
    'toothbrush' ,
    'border-inner' ,
    'paw-claws' ,
    'kiwi-fruit' ,
    'traffic-light-slow' ,
    'rectangle-code' ,
    'head-side-virus' ,
    'keyboard-brightness' ,
    'books-medical' ,
    'lightbulb-slash' ,
    'house-blank' ,
    'home-blank' ,
    'square-5' ,
    'square-heart' ,
    'heart-square' ,
    'puzzle' ,
    'user-gear' ,
    'user-cog' ,
    'pipe-circle-check' ,
    'arrow-up-1-9' ,
    'sort-numeric-up' ,
    'octagon-exclamation' ,
    'dial-low' ,
    'door-closed' ,
    'laptop-mobile' ,
    'phone-laptop' ,
    'conveyor-belt-boxes' ,
    'conveyor-belt-alt' ,
    'shield-virus' ,
    'starfighter-twin-ion-engine-advanced' ,
    'starfighter-alt-advanced' ,
    'dice-six' ,
    'starfighter-twin-ion-engine' ,
    'starfighter-alt' ,
    'rocket-launch' ,
    'mosquito-net' ,
    'vent-damper' ,
    'bridge-water' ,
    'ban-bug' ,
    'debug' ,
    'person-booth' ,
    'text-width' ,
    'garage-car' ,
    'square-kanban' ,
    'hat-wizard' ,
    'pen-fancy' ,
    'coffee-pot' ,
    'mouse-field' ,
    'person-digging' ,
    'digging' ,
    'shower-down' ,
    'shower-alt' ,
    'box-circle-check' ,
    'brightness' ,
    'car-side-bolt' ,
    'ornament' ,
    'phone-arrow-down-left' ,
    'phone-arrow-down' ,
    'phone-incoming' ,
    'cloud-word' ,
    'hand-fingers-crossed' ,
    'trash' ,
    'gauge-simple' ,
    'gauge-simple-med' ,
    'tachometer-average' ,
    'arrow-down-small-big' ,
    'sort-size-down-alt' ,
    'book-medical' ,
    'face-melting' ,
    'poo' ,
    'pen-clip-slash' ,
    'pen-alt-slash' ,
    'quote-right' ,
    'quote-right-alt' ,
    'scroll-old' ,
    'guitars' ,
    'phone-xmark' ,
    'hose' ,
    'clock-six' ,
    'shirt' ,
    't-shirt' ,
    'tshirt' ,
    'square-r' ,
    'cubes' ,
    'envelope-open-dollar' ,
    'divide' ,
    'sun-cloud' ,
    'lamp-floor' ,
    'square-7' ,
    'tenge-sign' ,
    'tenge' ,
    'headphones' ,
    'hands-holding' ,
    'campfire' ,
    'circle-ampersand' ,
    'snowflakes' ,
    'hands-clapping' ,
    'republican' ,
    'leaf-maple' ,
    'arrow-left' ,
    'person-circle-xmark' ,
    'ruler' ,
    'cup-straw-swoosh' ,
    'temperature-sun' ,
    'temperature-hot' ,
    'align-left' ,
    'dice-d6' ,
    'restroom' ,
    'high-definition' ,
    'rectangle-hd' ,
    'j' ,
    'galaxy' ,
    'users-viewfinder' ,
    'file-video' ,
    'cherries' ,
    'up-right-from-square' ,
    'external-link-alt' ,
    'circle-sort' ,
    'sort-circle' ,
    'table-cells' ,
    'th' ,
    'file-pdf' ,
    'siren' ,
    'arrow-up-to-dotted-line' ,
    'image-landscape' ,
    'landscape' ,
    'tank-water' ,
    'curling-stone' ,
    'curling' ,
    'gamepad-modern' ,
    'gamepad-alt' ,
    'messages-question' ,
    'book-bible' ,
    'bible' ,
    'o' ,
    'suitcase-medical' ,
    'medkit' ,
    'briefcase-arrow-right' ,
    'expand-wide' ,
    'clock-eleven-thirty' ,
    'rv' ,
    'user-secret' ,
    'otter' ,
    'dreidel' ,
    'person-dress' ,
    'female' ,
    'comment-dollar' ,
    'business-time' ,
    'briefcase-clock' ,
    'flower-tulip' ,
    'people-pants-simple' ,
    'cloud-drizzle' ,
    'table-cells-large' ,
    'th-large' ,
    'book-tanakh' ,
    'tanakh' ,
    'solar-system' ,
    'seal-question' ,
    'phone-volume' ,
    'volume-control-phone' ,
    'disc-drive' ,
    'hat-cowboy-side' ,
    'table-rows' ,
    'rows' ,
    'location-exclamation' ,
    'map-marker-exclamation' ,
    'face-fearful' ,
    'clipboard-user' ,
    'bus-school' ,
    'film-slash' ,
    'square-arrow-down-right' ,
    'book-sparkles' ,
    'book-spells' ,
    'washing-machine' ,
    'washer' ,
    'child' ,
    'lira-sign' ,
    'user-visor' ,
    'file-plus-minus' ,
    'chess-clock-flip' ,
    'chess-clock-alt' ,
    'satellite' ,
    'plane-lock' ,
    'steering-wheel' ,
    'tag' ,
    'stretcher' ,
    'book-section' ,
    'book-law' ,
    'inboxes' ,
    'coffee-bean' ,
    'brackets-curly' ,
    'ellipsis-stroke-vertical' ,
    'ellipsis-v-alt' ,
    'comment' ,
    'square-1' ,
    'cake-candles' ,
    'birthday-cake' ,
    'cake' ,
    'head-side' ,
    'envelope' ,
    'dolly-empty' ,
    'face-tissue' ,
    'angles-up' ,
    'angle-double-up' ,
    'paperclip' ,
    'chart-line-down' ,
    'arrow-right-to-city' ,
    'lock-a' ,
    'ribbon' ,
    'lungs' ,
    'person-pinball' ,
    'arrow-up-9-1' ,
    'sort-numeric-up-alt' ,
    'apple-core' ,
    'circle-y' ,
    'h6' ,
    'litecoin-sign' ,
    'circle-small' ,
    'border-none' ,
    'arrow-turn-down-left' ,
    'circle-nodes' ,
    'parachute-box' ,
    'message-medical' ,
    'comment-alt-medical' ,
    'rugby-ball' ,
    'comment-music' ,
    'indent' ,
    'tree-deciduous' ,
    'tree-alt' ,
    'puzzle-piece-simple' ,
    'puzzle-piece-alt' ,
    'truck-field-un' ,
    'nfc-trash' ,
    'hourglass' ,
    'hourglass-empty' ,
    'mountain' ,
    'file-xmark' ,
    'file-times' ,
    'house-heart' ,
    'home-heart' ,
    'house-chimney-blank' ,
    'meter-bolt' ,
    'user-doctor' ,
    'user-md' ,
    'slash-back' ,
    'circle-info' ,
    'info-circle' ,
    'fishing-rod' ,
    'hammer-crash' ,
    'message-heart' ,
    'cloud-meatball' ,
    'camera-polaroid' ,
    'camera' ,
    'camera-alt' ,
    'square-virus' ,
    'cart-arrow-up' ,
    'meteor' ,
    'car-on' ,
    'sleigh' ,
    'arrow-down-1-9' ,
    'sort-numeric-asc' ,
    'sort-numeric-down' ,
    'buoy-mooring' ,
    'square-4' ,
    'hand-holding-droplet' ,
    'hand-holding-water' ,
    'tricycle-adult' ,
    'waveform' ,
    'water' ,
    'star-sharp-half-stroke' ,
    'star-sharp-half-alt' ,
    'nfc-signal' ,
    'plane-prop' ,
    'calendar-check' ,
    'clock-desk' ,
    'calendar-clock' ,
    'calendar-time' ,
    'braille' ,
    'prescription-bottle-medical' ,
    'prescription-bottle-alt' ,
    'plate-utensils' ,
    'family-pants' ,
    'hose-reel' ,
    'house-window' ,
    'landmark' ,
    'truck' ,
    'crosshairs' ,
    'cloud-rainbow' ,
    'person-cane' ,
    'alien' ,
    'tent' ,
    'vest-patches' ,
    'people-dress-simple' ,
    'check-double' ,
    'arrow-down-a-z' ,
    'sort-alpha-asc' ,
    'sort-alpha-down' ,
    'bowling-ball-pin' ,
    'bell-school-slash' ,
    'plus-large' ,
    'money-bill-wheat' ,
    'camera-viewfinder' ,
    'screenshot' ,
    'message-music' ,
    'comment-alt-music' ,
    'car-building' ,
    'border-bottom-right' ,
    'border-style-alt' ,
    'octagon' ,
    'comment-arrow-up-right' ,
    'octagon-divide' ,
    'cookie' ,
    'arrow-rotate-left' ,
    'arrow-left-rotate' ,
    'arrow-rotate-back' ,
    'arrow-rotate-backward' ,
    'undo' ,
    'tv-music' ,
    'hard-drive' ,
    'hdd' ,
    'reel' ,
    'face-grin-squint-tears' ,
    'grin-squint-tears' ,
    'dumbbell' ,
    'rectangle-list' ,
    'list-alt' ,
    'tarp-droplet' ,
    'alarm-exclamation' ,
    'house-medical-circle-check' ,
    'traffic-cone' ,
    'grate' ,
    'arrow-down-right' ,
    'person-skiing-nordic' ,
    'skiing-nordic' ,
    'calendar-plus' ,
    'person-from-portal' ,
    'portal-exit' ,
    'plane-arrival' ,
    'cowbell-circle-plus' ,
    'cowbell-more' ,
    'circle-left' ,
    'arrow-alt-circle-left' ,
    'distribute-spacing-vertical' ,
    'signal-bars-fair' ,
    'signal-alt2' ,
    'sportsball' ,
    'game-console-handheld-crank' ,
    'train-subway' ,
    'subway' ,
    'chart-gantt' ,
    'face-smile-upside-down' ,
    'ball-pile' ,
    'badge-dollar' ,
    'money-bills-simple' ,
    'money-bills-alt' ,
    'list-timeline' ,
    'indian-rupee-sign' ,
    'indian-rupee' ,
    'inr' ,
    'crop-simple' ,
    'crop-alt' ,
    'money-bill-1' ,
    'money-bill-alt' ,
    'left-long' ,
    'long-arrow-alt-left' ,
    'keyboard-down' ,
    'circle-up-right' ,
    'cloud-bolt-moon' ,
    'thunderstorm-moon' ,
    'dna' ,
    'virus-slash' ,
    'bracket-round-right' ,
    'circle-5' ,
    'minus' ,
    'subtract' ,
    'fire-flame' ,
    'flame' ,
    'right-to-line' ,
    'arrow-alt-to-right' ,
    'gif' ,
    'chess' ,
    'trash-slash' ,
    'arrow-left-long' ,
    'long-arrow-left' ,
    'plug-circle-check' ,
    'font-case' ,
    'street-view' ,
    'arrow-down-left' ,
    'franc-sign' ,
    'flask-round-poison' ,
    'flask-poison' ,
    'volume-off' ,
    'book-circle-arrow-right' ,
    'chart-user' ,
    'user-chart' ,
    'hands-asl-interpreting' ,
    'american-sign-language-interpreting' ,
    'asl-interpreting' ,
    'hands-american-sign-language-interpreting' ,
    'presentation-screen' ,
    'presentation' ,
    'circle-bolt' ,
    'face-smile-halo' ,
    'cart-circle-arrow-down' ,
    'house-person-return' ,
    'house-person-arrive' ,
    'house-return' ,
    'message-xmark' ,
    'comment-alt-times' ,
    'message-times' ,
    'file-certificate' ,
    'file-award' ,
    'user-doctor-hair-long' ,
    'camera-security' ,
    'camera-home' ,
    'gear' ,
    'cog' ,
    'droplet-slash' ,
    'tint-slash' ,
    'book-heart' ,
    'mosque' ,
    'duck' ,
    'mosquito' ,
    'star-of-david' ,
    'flag-swallowtail' ,
    'flag-alt' ,
    'person-military-rifle' ,
    'car-garage' ,
    'cart-shopping' ,
    'shopping-cart' ,
    'book-font' ,
    'shield-plus' ,
    'vials' ,
    'eye-dropper-full' ,
    'distribute-spacing-horizontal' ,
    'tablet-rugged' ,
    'temperature-snow' ,
    'temperature-frigid' ,
    'moped' ,
    'face-smile-plus' ,
    'smile-plus' ,
    'radio-tuner' ,
    'radio-alt' ,
    'face-swear' ,
    'water-arrow-down' ,
    'water-lower' ,
    'scanner-touchscreen' ,
    'circle-7' ,
    'plug-circle-plus' ,
    'person-ski-jumping' ,
    'ski-jump' ,
    'place-of-worship' ,
    'water-arrow-up' ,
    'water-rise' ,
    'waveform-lines' ,
    'waveform-path' ,
    'split' ,
    'film-canister' ,
    'film-cannister' ,
    'folder-xmark' ,
    'folder-times' ,
    'toilet-paper-blank' ,
    'toilet-paper-alt' ,
    'tablet-screen' ,
    'tablet-android-alt' ,
    'hexagon-vertical-nft-slanted' ,
    'folder-music' ,
    'display-medical' ,
    'desktop-medical' ,
    'share-all' ,
    'peapod' ,
    'chess-clock' ,
    'axe' ,
    'square-d' ,
    'grip-vertical' ,
    'mobile-signal-out' ,
    'arrow-turn-up' ,
    'level-up' ,
    'u' ,
    'arrow-up-from-dotted-line' ,
    'square-root-variable' ,
    'square-root-alt' ,
    'light-switch-on' ,
    'arrow-down-arrow-up' ,
    'sort-alt' ,
    'raindrops' ,
    'dash' ,
    'minus-large' ,
    'clock' ,
    'clock-four' ,
    'input-numeric' ,
    'truck-tow' ,
    'backward-step' ,
    'step-backward' ,
    'pallet' ,
    'car-bolt' ,
    'arrows-maximize' ,
    'expand-arrows' ,
    'faucet' ,
    'cloud-sleet' ,
    'lamp-street' ,
    'list-radio' ,
    'pen-nib-slash' ,
    'baseball-bat-ball' ,
    'square-up-left' ,
    'overline' ,
    's' ,
    'timeline' ,
    'keyboard' ,
    'arrows-from-dotted-line' ,
    'usb-drive' ,
    'ballot' ,
    'caret-down' ,
    'location-dot-slash' ,
    'map-marker-alt-slash' ,
    'cards' ,
    'house-chimney-medical' ,
    'clinic-medical' ,
    'boxing-glove' ,
    'glove-boxing' ,
    'temperature-three-quarters' ,
    'temperature3' ,
    'thermometer3' ,
    'thermometer-three-quarters' ,
    'bell-school' ,
    'mobile-screen' ,
    'mobile-android-alt' ,
    'plane-up' ,
    'folder-heart' ,
    'circle-location-arrow' ,
    'location-circle' ,
    'face-head-bandage' ,
    'sushi-roll' ,
    'maki-roll' ,
    'makizushi' ,
    'car-bump' ,
    'piggy-bank' ,
    'racquet' ,
    'car-mirrors' ,
    'industry-windows' ,
    'industry-alt' ,
    'bolt-auto' ,
    'battery-half' ,
    'battery3' ,
    'flux-capacitor' ,
    'mountain-city' ,
    'coins' ,
    'honey-pot' ,
    'olive' ,
    'khanda' ,
    'filter-list' ,
    'outlet' ,
    'sliders' ,
    'sliders-h' ,
    'cauldron' ,
    'people' ,
    'folder-tree' ,
    'network-wired' ,
    'croissant' ,
    'map-pin' ,
    'hamsa' ,
    'cent-sign' ,
    'swords-laser' ,
    'flask' ,
    'person-pregnant' ,
    'square-u' ,
    'wand-sparkles' ,
    'router' ,
    'ellipsis-vertical' ,
    'ellipsis-v' ,
    'sword-laser-alt' ,
    'ticket' ,
    'power-off' ,
    'coin' ,
    'laptop-slash' ,
    'right-long' ,
    'long-arrow-alt-right' ,
    'circle-b' ,
    'person-dress-simple' ,
    'pipe-collar' ,
    'lights-holiday' ,
    'citrus' ,
    'flag-usa' ,
    'laptop-file' ,
    'tty' ,
    'teletype' ,
    'chart-tree-map' ,
    'diagram-next' ,
    'person-rifle' ,
    'clock-five-thirty' ,
    'pipe-valve' ,
    'arrow-up-from-arc' ,
    'face-spiral-eyes' ,
    'compress-wide' ,
    'circle-phone-hangup' ,
    'phone-circle-down' ,
    'house-medical-circle-exclamation' ,
    'badminton' ,
    'closed-captioning' ,
    'person-hiking' ,
    'hiking' ,
    'right-from-line' ,
    'arrow-alt-from-left' ,
    'venus-double' ,
    'images' ,
    'calculator' ,
    'shuttlecock' ,
    'user-hair' ,
    'eye-evil' ,
    'people-pulling' ,
    'n' ,
    'garage' ,
    'cable-car' ,
    'tram' ,
    'shovel-snow' ,
    'cloud-rain' ,
    'face-lying' ,
    'sprinkler' ,
    'building-circle-xmark' ,
    'person-sledding' ,
    'sledding' ,
    'game-console-handheld' ,
    'ship' ,
    'clock-six-thirty' ,
    'battery-slash' ,
    'tugrik-sign' ,
    'arrows-down-to-line' ,
    'download' ,
    'shelves' ,
    'inventory' ,
    'cloud-snow' ,
    'face-grin' ,
    'grin' ,
    'delete-left' ,
    'backspace' ,
    'oven' ,
    'eye-dropper' ,
    'eye-dropper-empty' ,
    'eyedropper' ,
    'comment-captions' ,
    'comments-question' ,
    'scribble' ,
    'rotate-exclamation' ,
    'file-circle-check' ,
    'glass' ,
    'loader' ,
    'forward' ,
    'user-pilot' ,
    'mobile' ,
    'mobile-android' ,
    'mobile-phone' ,
    'code-pull-request-closed' ,
    'face-meh' ,
    'meh' ,
    'align-center' ,
    'book-skull' ,
    'book-dead' ,
    'id-card' ,
    'drivers-license' ,
    'face-dotted' ,
    'face-worried' ,
    'outdent' ,
    'dedent' ,
    'heart-circle-exclamation' ,
    'house' ,
    'home' ,
    'home-alt' ,
    'home-lg-alt' ,
    'vector-circle' ,
    'car-circle-bolt' ,
    'calendar-week' ,
    'flying-disc' ,
    'laptop-medical' ,
    'square-down-right' ,
    'b' ,
    'seat-airline' ,
    'moon-over-sun' ,
    'eclipse-alt' ,
    'pipe' ,
    'file-medical' ,
    'potato' ,
    'dice-one' ,
    'circle-a' ,
    'helmet-battle' ,
    'butter' ,
    'blanket-fire' ,
    'kiwi-bird' ,
    'castle' ,
    'golf-club' ,
    'arrow-right-arrow-left' ,
    'exchange' ,
    'rotate-right' ,
    'redo-alt' ,
    'rotate-forward' ,
    'utensils' ,
    'cutlery' ,
    'arrow-up-wide-short' ,
    'sort-amount-up' ,
    'balloons' ,
    'mill-sign' ,
    'bowl-rice' ,
    'timeline-arrow' ,
    'skull' ,
    'game-board-simple' ,
    'game-board-alt' ,
    'circle-video' ,
    'video-circle' ,
    'chart-scatter-bubble' ,
    'house-turret' ,
    'banana' ,
    'hand-holding-skull' ,
    'people-dress' ,
    'loveseat' ,
    'couch-small' ,
    'tower-broadcast' ,
    'broadcast-tower' ,
    'truck-pickup' ,
    'block-quote' ,
    'up-long' ,
    'long-arrow-alt-up' ,
    'stop' ,
    'code-merge' ,
    'money-check-dollar-pen' ,
    'money-check-edit-alt' ,
    'up-from-line' ,
    'arrow-alt-from-bottom' ,
    'upload' ,
    'hurricane' ,
    'people-pants' ,
    'mound' ,
    'windsock' ,
    'circle-half' ,
    'brake-warning' ,
    'toilet-portable' ,
    'compact-disc' ,
    'file-arrow-down' ,
    'file-download' ,
    'saxophone-fire' ,
    'sax-hot' ,
    'camera-web-slash' ,
    'webcam-slash' ,
    'folder-medical' ,
    'folder-gear' ,
    'folder-cog' ,
    'hand-wave' ,
    'arrow-up-arrow-down' ,
    'sort-up-down' ,
    'caravan' ,
    'shield-cat' ,
    'message-slash' ,
    'comment-alt-slash' ,
    'bolt' ,
    'zap' ,
    'trash-can-check' ,
    'glass-water' ,
    'oil-well' ,
    'person-simple' ,
    'vault' ,
    'mars' ,
    'toilet' ,
    'plane-circle-xmark' ,
    'yen-sign' ,
    'cny' ,
    'jpy' ,
    'rmb' ,
    'yen' ,
    'notes' ,
    'ruble-sign' ,
    'rouble' ,
    'rub' ,
    'ruble' ,
    'trash-undo' ,
    'trash-arrow-turn-left' ,
    'champagne-glass' ,
    'glass-champagne' ,
    'objects-align-center-horizontal' ,
    'sun' ,
    'trash-can-slash' ,
    'trash-alt-slash' ,
    'screen-users' ,
    'users-class' ,
    'guitar' ,
    'square-arrow-left' ,
    'arrow-square-left' ,
    'square-8' ,
    'face-smile-hearts' ,
    'brackets-square' ,
    'brackets' ,
    'laptop-arrow-down' ,
    'hockey-stick-puck' ,
    'house-tree' ,
    'signal-fair' ,
    'signal2' ,
    'face-laugh-wink' ,
    'laugh-wink' ,
    'circle-dollar' ,
    'dollar-circle' ,
    'usd-circle' ,
    'horse-head' ,
    'arrows-repeat' ,
    'repeat-alt' ,
    'bore-hole' ,
    'industry' ,
    'image-polaroid' ,
    'wave-triangle' ,
    'circle-down' ,
    'arrow-alt-circle-down' ,
    'grill' ,
    'arrows-turn-to-dots' ,
    'chart-mixed' ,
    'analytics' ,
    'florin-sign' ,
    'arrow-down-short-wide' ,
    'sort-amount-desc' ,
    'sort-amount-down-alt' ,
    'less-than' ,
    'display-code' ,
    'desktop-code' ,
    'face-drooling' ,
    'oil-temperature' ,
    'oil-temp' ,
    'square-question' ,
    'question-square' ,
    'air-conditioner' ,
    'angle-down' ,
    'mountains' ,
    'omega' ,
    'car-tunnel' ,
    'person-dolly-empty' ,
    'pan-food' ,
    'head-side-cough' ,
    'grip-lines' ,
    'thumbs-down' ,
    'user-lock' ,
    'arrow-right-long' ,
    'long-arrow-right' ,
    'tickets-airline' ,
    'anchor-circle-xmark' ,
    'ellipsis' ,
    'ellipsis-h' ,
    'nfc-slash' ,
    'chess-pawn' ,
    'kit-medical' ,
    'first-aid' ,
    'grid-2-plus' ,
    'bells' ,
    'person-through-window' ,
    'toolbox' ,
    'envelope-dot' ,
    'envelope-badge' ,
    'hands-holding-circle' ,
    'bug' ,
    'bowl-chopsticks' ,
    'credit-card' ,
    'credit-card-alt' ,
    'circle-s' ,
    'box-ballot' ,
    'car' ,
    'automobile' ,
    'hand-holding-hand' ,
    'user-tie-hair' ,
    'podium-star' ,
    'user-hair-mullet' ,
    'business-front' ,
    'party-back' ,
    'trian-balbot' ,
    'microphone-stand' ,
    'book-open-reader' ,
    'book-reader' ,
    'family-dress' ,
    'circle-x' ,
    'cabin' ,
    'mountain-sun' ,
    'chart-simple-horizontal' ,
    'bluetooth' ,
    'arrows-left-right-to-line' ,
    'hand-back-point-left' ,
    'message-dots' ,
    'comment-alt-dots' ,
    'messaging' ,
    'file-heart' ,
    'beer-mug' ,
    'beer-foam' ,
    'dice-d20' ,
    'drone' ,
    'truck-droplet' ,
    'file-circle-xmark' ,
    'temperature-arrow-up' ,
    'temperature-up' ,
    'medal' ,
    'bed' ,
    'book-copy' ,
    'square-h' ,
    'h-square' ,
    'square-c' ,
    'clock-two' ,
    'square-ellipsis-vertical' ,
    'podcast' ,
    'bee' ,
    'temperature-full' ,
    'temperature4' ,
    'thermometer4' ,
    'thermometer-full' ,
    'bell' ,
    'candy-bar' ,
    'chocolate-bar' ,
    'xmark-large' ,
    'pinata' ,
    'arrows-from-line' ,
    'superscript' ,
    'bowl-spoon' ,
    'hexagon-check' ,
    'plug-circle-xmark' ,
    'star-of-life' ,
    'phone-slash' ,
    'traffic-light-stop' ,
    'paint-roller' ,
    'accent-grave' ,
    'handshake-angle' ,
    'hands-helping' ,
    'circle-0' ,
    'dial-med-low' ,
    'location-dot' ,
    'map-marker-alt' ,
    'crab' ,
    'box-open-full' ,
    'box-full' ,
    'file' ,
    'greater-than' ,
    'quotes' ,
    'pretzel' ,
    'person-swimming' ,
    'swimmer' ,
    'arrow-down' ,
    'user-robot-xmarks' ,
    'message-quote' ,
    'comment-alt-quote' ,
    'candy-corn' ,
    'folder-magnifying-glass' ,
    'folder-search' ,
    'notebook' ,
    'droplet' ,
    'tint' ,
    'bullseye-pointer' ,
    'eraser' ,
    'hexagon-image' ,
    'earth-americas' ,
    'earth' ,
    'earth-america' ,
    'globe-americas' ,
    'crate-apple' ,
    'apple-crate' ,
    'person-burst' ,
    'game-board' ,
    'hat-chef' ,
    'hand-back-point-right' ,
    'dove' ,
    'snowflake-droplets' ,
    'battery-empty' ,
    'battery0' ,
    'grid-4' ,
    'socks' ,
    'face-sunglasses' ,
    'inbox' ,
    'square-0' ,
    'section' ,
    'square-this-way-up' ,
    'box-up' ,
    'gauge-high' ,
    'tachometer-alt' ,
    'tachometer-alt-fast' ,
    'square-ampersand' ,
    'envelope-open-text' ,
    'lamp-desk' ,
    'hospital' ,
    'hospital-alt' ,
    'hospital-wide' ,
    'poll-people' ,
    'whiskey-glass-ice' ,
    'glass-whiskey-rocks' ,
    'wine-bottle' ,
    'chess-rook' ,
    'user-bounty-hunter' ,
    'bars-staggered' ,
    'reorder' ,
    'stream' ,
    'diagram-sankey' ,
    'cloud-hail-mixed' ,
    'circle-up-left' ,
    'dharmachakra' ,
    'objects-align-left' ,
    'oil-can-drip' ,
    'face-smiling-hands' ,
    'broccoli' ,
    'route-interstate' ,
    'ear-muffs' ,
    'hotdog' ,
    'transporter-empty' ,
    'person-walking-with-cane' ,
    'blind' ,
    'angle-90' ,
    'rectangle-terminal' ,
    'kite' ,
    'drum' ,
    'scrubber' ,
    'ice-cream' ,
    'heart-circle-bolt' ,
    'fish-bones' ,
    'deer-rudolph' ,
    'fax' ,
    'paragraph' ,
    'head-side-heart' ,
    'square-e' ,
    'meter-fire' ,
    'cloud-hail' ,
    'check-to-slot' ,
    'vote-yea' ,
    'money-from-bracket' ,
    'star-half' ,
    'car-bus' ,
    'speaker' ,
    'timer' ,
    'boxes-stacked' ,
    'boxes' ,
    'boxes-alt' ,
    'grill-hot' ,
    'ballot-check' ,
    'link' ,
    'chain' ,
    'ear-listen' ,
    'assistive-listening-systems' ,
    'file-minus' ,
    'tree-city' ,
    'play' ,
    'font' ,
    'cup-togo' ,
    'coffee-togo' ,
    'square-down-left' ,
    'burger-lettuce' ,
    'rupiah-sign' ,
    'magnifying-glass' ,
    'search' ,
    'table-tennis-paddle-ball' ,
    'ping-pong-paddle-ball' ,
    'table-tennis' ,
    'person-dots-from-line' ,
    'diagnoses' ,
    'chevrons-down' ,
    'chevron-double-down' ,
    'trash-can-arrow-up' ,
    'trash-restore-alt' ,
    'signal-good' ,
    'signal3' ,
    'location-question' ,
    'map-marker-question' ,
    'floppy-disk-circle-xmark' ,
    'floppy-disk-times' ,
    'save-circle-xmark' ,
    'save-times' ,
    'naira-sign' ,
    'peach' ,
    'taxi-bus' ,
    'bracket-curly' ,
    'bracket-curly-left' ,
    'lobster' ,
    'cart-flatbed-empty' ,
    'dolly-flatbed-empty' ,
    'colon' ,
    'cart-arrow-down' ,
    'wand' ,
    'walkie-talkie' ,
    'file-pen' ,
    'file-edit' ,
    'receipt' ,
    'table-picnic' ,
    'square-pen' ,
    'pen-square' ,
    'pencil-square' ,
    'circle-microphone-lines' ,
    'microphone-circle-alt' ,
    'display-slash' ,
    'desktop-slash' ,
    'suitcase-rolling' ,
    'person-circle-exclamation' ,
    'transporter-2' ,
    'hands-holding-diamond' ,
    'hand-receiving' ,
    'money-bill-simple-wave' ,
    'chevron-down' ,
    'battery-full' ,
    'battery' ,
    'battery5' ,
    'bell-plus' ,
    'book-arrow-right' ,
    'hospitals' ,
    'club' ,
    'skull-crossbones' ,
    'droplet-degree' ,
    'dewpoint' ,
    'code-compare' ,
    'list-ul' ,
    'list-dots' ,
    'hand-holding-magic' ,
    'watermelon-slice' ,
    'circle-ellipsis' ,
    'school-lock' ,
    'tower-cell' ,
    'sd-cards' ,
    'down-long' ,
    'long-arrow-alt-down' ,
    'envelopes' ,
    'phone-office' ,
    'ranking-star' ,
    'chess-king' ,
    'nfc-pen' ,
    'person-harassing' ,
    'hat-winter' ,
    'brazilian-real-sign' ,
    'landmark-dome' ,
    'landmark-alt' ,
    'bone-break' ,
    'arrow-up' ,
    'down-from-dotted-line' ,
    'tv' ,
    'television' ,
    'tv-alt' ,
    'border-left' ,
    'circle-divide' ,
    'shrimp' ,
    'list-check' ,
    'tasks' ,
    'diagram-subtask' ,
    'jug-detergent' ,
    'circle-user' ,
    'user-circle' ,
    'square-y' ,
    'user-doctor-hair' ,
    'planet-ringed' ,
    'mushroom' ,
    'user-shield' ,
    'megaphone' ,
    'circle-exclamation-check' ,
    'wind' ,
    'box-dollar' ,
    'box-usd' ,
    'car-burst' ,
    'car-crash' ,
    'y' ,
    'user-headset' ,
    'arrows-retweet' ,
    'retweet-alt' ,
    'person-snowboarding' ,
    'snowboarding' ,
    'square-chevron-right' ,
    'chevron-square-right' ,
    'lacrosse-stick-ball' ,
    'truck-fast' ,
    'shipping-fast' ,
    'user-magnifying-glass' ,
    'star-sharp' ,
    'comment-heart' ,
    'circle-1' ,
    'circle-star' ,
    'star-circle' ,
    'fish' ,
    'cloud-fog' ,
    'fog' ,
    'waffle' ,
    'music-note' ,
    'music-alt' ,
    'hexagon-exclamation' ,
    'cart-shopping-fast' ,
    'object-union' ,
    'user-graduate' ,
    'starfighter' ,
    'circle-half-stroke' ,
    'adjust' ,
    'arrow-right-long-to-line' ,
    'square-arrow-down' ,
    'arrow-square-down' ,
    'diamond-half-stroke' ,
    'clapperboard' ,
    'square-chevron-left' ,
    'chevron-square-left' ,
    'phone-intercom' ,
    'link-horizontal' ,
    'chain-horizontal' ,
    'mango' ,
    'music-note-slash' ,
    'music-alt-slash' ,
    'circle-radiation' ,
    'radiation-alt' ,
    'face-tongue-sweat' ,
    'globe-stand' ,
    'baseball' ,
    'baseball-ball' ,
    'circle-p' ,
    'award-simple' ,
    'jet-fighter-up' ,
    'diagram-project' ,
    'project-diagram' ,
    'pedestal' ,
    'chart-pyramid' ,
    'sidebar' ,
    'snowman-head' ,
    'frosty-head' ,
    'copy' ,
    'burger-glass' ,
    'volume-xmark' ,
    'volume-mute' ,
    'volume-times' ,
    'hand-sparkles' ,
    'bars-filter' ,
    'paintbrush-pencil' ,
    'party-bell' ,
    'user-vneck-hair' ,
    'jack-o-lantern' ,
    'grip' ,
    'grip-horizontal' ,
    'share-from-square' ,
    'share-square' ,
    'keynote' ,
    'child-combatant' ,
    'child-rifle' ,
    'gun' ,
    'square-phone' ,
    'phone-square' ,
    'plus' ,
    'add' ,
    'expand' ,
    'computer' ,
    'fort' ,
    'cloud-check' ,
    'xmark' ,
    'close' ,
    'multiply' ,
    'remove' ,
    'times' ,
    'face-smirking' ,
    'arrows-up-down-left-right' ,
    'arrows' ,
    'chalkboard-user' ,
    'chalkboard-teacher' ,
    'rhombus' ,
    'claw-marks' ,
    'peso-sign' ,
    'face-smile-tongue' ,
    'cart-circle-xmark' ,
    'building-shield' ,
    'circle-phone-flip' ,
    'phone-circle-alt' ,
    'baby' ,
    'users-line' ,
    'quote-left' ,
    'quote-left-alt' ,
    'tractor' ,
    'key-skeleton' ,
    'trash-arrow-up' ,
    'trash-restore' ,
    'arrow-down-up-lock' ,
    'arrow-down-to-bracket' ,
    'lines-leaning' ,
    'square-q' ,
    'ruler-combined' ,
    'symbols' ,
    'icons-alt' ,
    'copyright' ,
    'highlighter-line' ,
    'bracket-square' ,
    'bracket' ,
    'bracket-left' ,
    'island-tropical' ,
    'island-tree-palm' ,
    'arrow-right-from-line' ,
    'arrow-from-left' ,
    'h2' ,
    'equals' ,
    'cake-slice' ,
    'shortcake' ,
    'peanut' ,
    'wrench-simple' ,
    'blender' ,
    'teeth' ,
    'tally-2' ,
    'shekel-sign' ,
    'ils' ,
    'shekel' ,
    'sheqel' ,
    'sheqel-sign' ,
    'cars' ,
    'axe-battle' ,
    'user-hair-long' ,
    'map' ,
    'file-circle-info' ,
    'face-disappointed' ,
    'lasso-sparkles' ,
    'clock-eleven' ,
    'rocket' ,
    'siren-on' ,
    'clock-ten' ,
    'candle-holder' ,
    'video-arrow-down-left' ,
    'photo-film' ,
    'photo-video' ,
    'floppy-disk-circle-arrow-right' ,
    'save-circle-arrow-right' ,
    'folder-minus' ,
    'planet-moon' ,
    'face-eyes-xmarks' ,
    'chart-scatter' ,
    'display-arrow-down' ,
    'store' ,
    'arrow-trend-up' ,
    'plug-circle-minus' ,
    'olive-branch' ,
    'angle' ,
    'vacuum-robot' ,
    'sign-hanging' ,
    'sign' ,
    'square-divide' ,
    'signal-stream-slash' ,
    'bezier-curve' ,
    'eye-dropper-half' ,
    'store-lock' ,
    'bell-slash' ,
    'cloud-bolt-sun' ,
    'thunderstorm-sun' ,
    'camera-slash' ,
    'comment-quote' ,
    'tablet' ,
    'tablet-android' ,
    'school-flag' ,
    'message-code' ,
    'glass-half' ,
    'glass-half-empty' ,
    'glass-half-full' ,
    'fill' ,
    'message-minus' ,
    'comment-alt-minus' ,
    'angle-up' ,
    'drumstick-bite' ,
    'link-horizontal-slash' ,
    'chain-horizontal-slash' ,
    'holly-berry' ,
    'nose' ,
    'chevron-left' ,
    'bacteria' ,
    'clouds' ,
    'money-bill-simple' ,
    'hand-lizard' ,
    'table-pivot' ,
    'filter-slash' ,
    'trash-can-undo' ,
    'trash-can-arrow-turn-left' ,
    'trash-undo-alt' ,
    'notdef' ,
    'disease' ,
    'person-to-door' ,
    'turntable' ,
    'briefcase-medical' ,
    'genderless' ,
    'chevron-right' ,
    'signal-weak' ,
    'signal1' ,
    'clock-five' ,
    'retweet' ,
    'car-rear' ,
    'car-alt' ,
    'pump-soap' ,
    'computer-classic' ,
    'frame' ,
    'video-slash' ,
    'battery-quarter' ,
    'battery2' ,
    'ellipsis-stroke' ,
    'ellipsis-h-alt' ,
    'radio' ,
    'baby-carriage' ,
    'carriage-baby' ,
    'face-expressionless' ,
    'down-to-dotted-line' ,
    'cloud-music' ,
    'traffic-light' ,
    'cloud-minus' ,
    'thermometer' ,
    'shield-minus' ,
    'vr-cardboard' ,
    'car-tilt' ,
    'gauge-circle-minus' ,
    'brightness-low' ,
    'hand-middle-finger' ,
    'percent' ,
    'percentage' ,
    'truck-moving' ,
    'glass-water-droplet' ,
    'conveyor-belt' ,
    'location-check' ,
    'map-marker-check' ,
    'coin-vertical' ,
    'display' ,
    'person-sign' ,
    'face-smile' ,
    'smile' ,
    'phone-hangup' ,
    'signature-slash' ,
    'thumbtack' ,
    'thumb-tack' ,
    'wheat-slash' ,
    'trophy' ,
    'clouds-sun' ,
    'person-praying' ,
    'pray' ,
    'hammer' ,
    'face-vomit' ,
    'speakers' ,
    'tty-answer' ,
    'teletype-answer' ,
    'mug-tea-saucer' ,
    'diagram-lean-canvas' ,
    'alt' ,
    'dial' ,
    'dial-med-high' ,
    'hand-peace' ,
    'circle-trash' ,
    'trash-circle' ,
    'rotate' ,
    'sync-alt' ,
    'circle-quarters' ,
    'spinner' ,
    'tower-control' ,
    'arrow-up-triangle-square' ,
    'sort-shapes-up' ,
    'whale' ,
    'robot' ,
    'peace' ,
    'party-horn' ,
    'gears' ,
    'cogs' ,
    'sun-bright' ,
    'sun-alt' ,
    'warehouse' ,
    'lock-keyhole-open' ,
    'lock-open-alt' ,
    'square-fragile' ,
    'box-fragile' ,
    'square-wine-glass-crack' ,
    'arrow-up-right-dots' ,
    'square-n' ,
    'splotch' ,
    'face-grin-hearts' ,
    'grin-hearts' ,
    'meter' ,
    'mandolin' ,
    'dice-four' ,
    'sim-card' ,
    'transgender' ,
    'transgender-alt' ,
    'mercury' ,
    'up-from-bracket' ,
    'knife-kitchen' ,
    'border-right' ,
    'arrow-turn-down' ,
    'level-down' ,
    'spade' ,
    'card-spade' ,
    'line-columns' ,
    'arrow-right-to-line' ,
    'arrow-to-right' ,
    'person-falling-burst' ,
    'flag-pennant' ,
    'pennant' ,
    'conveyor-belt-empty' ,
    'award' ,
    'ticket-simple' ,
    'ticket-alt' ,
    'building' ,
    'angles-left' ,
    'angle-double-left' ,
    'camcorder' ,
    'video-handheld' ,
    'pancakes' ,
    'album-circle-user' ,
    'qrcode' ,
    'dice-d10' ,
    'fireplace' ,
    'browser' ,
    'pen-paintbrush' ,
    'pencil-paintbrush' ,
    'fish-cooked' ,
    'chair-office' ,
    'nesting-dolls' ,
    'clock-rotate-left' ,
    'history' ,
    'trumpet' ,
    'face-grin-beam-sweat' ,
    'grin-beam-sweat' ,
    'fire-smoke' ,
    'phone-missed' ,
    'file-export' ,
    'arrow-right-from-file' ,
    'shield' ,
    'shield-blank' ,
    'arrow-up-short-wide' ,
    'sort-amount-up-alt' ,
    'arrows-repeat-1' ,
    'repeat1-alt' ,
    'gun-slash' ,
    'avocado' ,
    'binary' ,
    'glasses-round' ,
    'glasses-alt' ,
    'phone-plus' ,
    'ditto' ,
    'person-seat' ,
    'house-medical' ,
    'golf-ball-tee' ,
    'golf-ball' ,
    'circle-chevron-left' ,
    'chevron-circle-left' ,
    'house-chimney-window' ,
    'scythe' ,
    'pen-nib' ,
    'ban-parking' ,
    'parking-circle-slash' ,
    'tent-arrow-turn-left' ,
    'face-diagonal-mouth' ,
    'diagram-cells' ,
    'cricket-bat-ball' ,
    'cricket' ,
    'tents' ,
    'wand-magic' ,
    'magic' ,
    'dog' ,
    'pen-line' ,
    'atom-simple' ,
    'atom-alt' ,
    'ampersand' ,
    'carrot' ,
    'arrow-up-from-line' ,
    'arrow-from-bottom' ,
    'moon' ,
    'pen-slash' ,
    'wine-glass-empty' ,
    'wine-glass-alt' ,
    'square-star' ,
    'cheese' ,
    'send-backward' ,
    'yin-yang' ,
    'music' ,
    'compass-slash' ,
    'clock-one' ,
    'file-music' ,
    'code-commit' ,
    'temperature-low' ,
    'person-biking' ,
    'biking' ,
    'skeleton' ,
    'circle-g' ,
    'circle-arrow-up-left' ,
    'coin-blank' ,
    'broom' ,
    'vacuum' ,
    'shield-heart' ,
    'card-heart' ,
    'lightbulb-cfl-on' ,
    'melon' ,
    'gopuram' ,
    'earth-oceania' ,
    'globe-oceania' ,
    'container-storage' ,
    'face-pouting' ,
    'square-xmark' ,
    'times-square' ,
    'xmark-square' ,
    'face-explode' ,
    'exploding-head' ,
    'hashtag' ,
    'up-right-and-down-left-from-center' ,
    'expand-alt' ,
    'oil-can' ,
    't' ,
    'transformer-bolt' ,
    'hippo' ,
    'chart-column' ,
    'cassette-vhs' ,
    'vhs' ,
    'infinity' ,
    'vial-circle-check' ,
    'chimney' ,
    'object-intersect' ,
    'person-arrow-down-to-line' ,
    'voicemail' ,
    'block-brick' ,
    'wall-brick' ,
    'fan' ,
    'bags-shopping' ,
    'paragraph-left' ,
    'paragraph-rtl' ,
    'person-walking-luggage' ,
    'caravan-simple' ,
    'caravan-alt' ,
    'turtle' ,
    'up-down' ,
    'arrows-alt-v' ,
    'cloud-moon-rain' ,
    'booth-curtain' ,
    'calendar' ,
    'box-heart' ,
    'trailer' ,
    'user-doctor-message' ,
    'user-md-chat' ,
    'bahai' ,
    'haykal' ,
    'amp-guitar' ,
    'sd-card' ,
    'volume-slash' ,
    'border-bottom' ,
    'wifi-weak' ,
    'wifi1' ,
    'dragon' ,
    'shoe-prints' ,
    'circle-plus' ,
    'plus-circle' ,
    'face-grin-tongue-wink' ,
    'grin-tongue-wink' ,
    'hand-holding' ,
    'plug-circle-exclamation' ,
    'link-slash' ,
    'chain-broken' ,
    'chain-slash' ,
    'unlink' ,
    'clone' ,
    'person-walking-arrow-loop-left' ,
    'arrow-up-z-a' ,
    'sort-alpha-up-alt' ,
    'fire-flame-curved' ,
    'fire-alt' ,
    'tornado' ,
    'file-circle-plus' ,
    'delete-right' ,
    'book-quran' ,
    'quran' ,
    'circle-quarter' ,
    'anchor' ,
    'border-all' ,
    'function' ,
    'face-angry' ,
    'angry' ,
    'people-simple' ,
    'cookie-bite' ,
    'arrow-trend-down' ,
    'rss' ,
    'feed' ,
    'face-monocle' ,
    'draw-polygon' ,
    'scale-balanced' ,
    'balance-scale' ,
    'calendar-lines' ,
    'calendar-note' ,
    'arrow-down-big-small' ,
    'sort-size-down' ,
    'gauge-simple-high' ,
    'tachometer' ,
    'tachometer-fast' ,
    'do-not-enter' ,
    'shower' ,
    'dice-d8' ,
    'desktop' ,
    'desktop-alt' ,
    'm' ,
    'grip-dots-vertical' ,
    'face-viewfinder' ,
    'soft-serve' ,
    'creemee' ,
    'h5' ,
    'hand-back-point-down' ,
    'table-list' ,
    'th-list' ,
    'comment-sms' ,
    'sms' ,
    'rectangle' ,
    'rectangle-landscape' ,
    'clipboard-list-check' ,
    'turkey' ,
    'book' ,
    'user-plus' ,
    'ice-skate' ,
    'check' ,
    'battery-three-quarters' ,
    'battery4' ,
    'tomato' ,
    'sword-laser' ,
    'house-circle-check' ,
    'buildings' ,
    'angle-left' ,
    'cart-flatbed-boxes' ,
    'dolly-flatbed-alt' ,
    'diagram-successor' ,
    'truck-arrow-right' ,
    'square-w' ,
    'arrows-split-up-and-left' ,
    'lamp' ,
    'airplay' ,
    'hand-fist' ,
    'fist-raised' ,
    'shield-quartered' ,
    'slash-forward' ,
    'location-pen' ,
    'map-marker-edit' ,
    'cloud-moon' ,
    'pot-food' ,
    'briefcase' ,
    'person-falling' ,
    'image-portrait' ,
    'portrait' ,
    'user-tag' ,
    'rug' ,
    'print-slash' ,
    'earth-europe' ,
    'globe-europe' ,
    'cart-flatbed-suitcase' ,
    'luggage-cart' ,
    'hand-back-point-ribbon' ,
    'rectangle-xmark' ,
    'rectangle-times' ,
    'times-rectangle' ,
    'window-close' ,
    'tire-rugged' ,
    'lightbulb-dollar' ,
    'cowbell' ,
    'baht-sign' ,
    'corner' ,
    'chevrons-right' ,
    'chevron-double-right' ,
    'book-open' ,
    'book-journal-whills' ,
    'journal-whills' ,
    'inhaler' ,
    'handcuffs' ,
    'snake' ,
    'triangle-exclamation' ,
    'exclamation-triangle' ,
    'warning' ,
    'note-medical' ,
    'database' ,
    'down-left' ,
    'share' ,
    'arrow-turn-right' ,
    'mail-forward' ,
    'face-thinking' ,
    'turn-down-right' ,
    'bottle-droplet' ,
    'mask-face' ,
    'hill-rockslide' ,
    'scanner-keyboard' ,
    'circle-o' ,
    'grid-horizontal' ,
    'message-dollar' ,
    'comment-alt-dollar' ,
    'right-left' ,
    'exchange-alt' ,
    'columns-3' ,
    'paper-plane' ,
    'road-circle-exclamation' ,
    'dungeon' ,
    'hand-holding-box' ,
    'input-text' ,
    'window-flip' ,
    'window-alt' ,
    'align-right' ,
    'scanner-gun' ,
    'scanner' ,
    'tire' ,
    'engine' ,
    'money-bill-1-wave' ,
    'money-bill-wave-alt' ,
    'life-ring' ,
    'hands' ,
    'sign-language' ,
    'signing' ,
    'circle-caret-right' ,
    'caret-circle-right' ,
    'wheat' ,
    'file-spreadsheet' ,
    'audio-description-slash' ,
    'calendar-day' ,
    'water-ladder' ,
    'ladder-water' ,
    'swimming-pool' ,
    'arrows-up-down' ,
    'arrows-v' ,
    'chess-pawn-piece' ,
    'chess-pawn-alt' ,
    'face-grimace' ,
    'grimace' ,
    'wheelchair-move' ,
    'wheelchair-alt' ,
    'turn-down' ,
    'level-down-alt' ,
    'square-s' ,
    'rectangle-barcode' ,
    'barcode-alt' ,
    'person-walking-arrow-right' ,
    'square-envelope' ,
    'envelope-square' ,
    'dice' ,
    'unicorn' ,
    'bowling-ball' ,
    'pompebled' ,
    'brain' ,
    'watch-smart' ,
    'book-user' ,
    'sensor-cloud' ,
    'sensor-smoke' ,
    'clapperboard-play' ,
    'bandage' ,
    'band-aid' ,
    'calendar-minus' ,
    'circle-xmark' ,
    'times-circle' ,
    'xmark-circle' ,
    'circle-4' ,
    'gifts' ,
    'album-collection' ,
    'hotel' ,
    'earth-asia' ,
    'globe-asia' ,
    'id-card-clip' ,
    'id-card-alt' ,
    'magnifying-glass-plus' ,
    'search-plus' ,
    'thumbs-up' ,
    'cloud-showers' ,
    'user-clock' ,
    'onion' ,
    'clock-twelve-thirty' ,
    'arrow-down-to-dotted-line' ,
    'hand-dots' ,
    'allergies' ,
    'file-invoice' ,
    'window-minimize' ,
    'rectangle-wide' ,
    'comment-arrow-up' ,
    'garlic' ,
    'mug-saucer' ,
    'coffee' ,
    'brush' ,
    'tree-decorated' ,
    'mask' ,
    'calendar-heart' ,
    'magnifying-glass-minus' ,
    'search-minus' ,
    'flower' ,
    'ruler-vertical' ,
    'user-large' ,
    'user-alt' ,
    'starship-freighter' ,
    'train-tram' ,
    'bridge-suspension' ,
    'trash-check' ,
    'user-nurse' ,
    'boombox' ,
    'syringe' ,
    'cloud-sun' ,
    'shield-exclamation' ,
    'stopwatch-20' ,
    'square-full' ,
    'grip-dots' ,
    'comment-exclamation' ,
    'pen-swirl' ,
    'falafel' ,
    'circle-2' ,
    'magnet' ,
    'jar' ,
    'gramophone' ,
    'dice-d12' ,
    'note-sticky' ,
    'sticky-note' ,
    'down' ,
    'arrow-alt-down' ,
    'hundred-points' ,
    '100' ,
    'paperclip-vertical' ,
    'wind-warning' ,
    'wind-circle-exclamation' ,
    'location-pin-slash' ,
    'map-marker-slash' ,
    'face-sad-sweat' ,
    'bug-slash' ,
    'cupcake' ,
    'light-switch-off' ,
    'toggle-large-off' ,
    'pen-fancy-slash' ,
    'truck-container' ,
    'boot' ,
    'arrow-up-from-water-pump' ,
    'file-check' ,
    'bone' ,
    'cards-blank' ,
    'circle-3' ,
    'bench-tree' ,
    'keyboard-brightness-low' ,
    'ski-boot-ski' ,
    'brain-circuit' ,
    'user-injured' ,
    'block-brick-fire' ,
    'firewall' ,
    'face-sad-tear' ,
    'sad-tear' ,
    'plane' ,
    'tent-arrows-down' ,
    'exclamation' ,
    'arrows-spin' ,
    'face-smile-relaxed' ,
    'comment-xmark' ,
    'comment-times' ,
    'print' ,
    'turkish-lira-sign' ,
    'try' ,
    'turkish-lira' ,
    'face-nose-steam' ,
    'circle-waveform-lines' ,
    'waveform-circle' ,
    'dollar-sign' ,
    'dollar' ,
    'usd' ,
    'ferris-wheel' ,
    'computer-speaker' ,
    'skull-cow' ,
    'x' ,
    'magnifying-glass-dollar' ,
    'search-dollar' ,
    'users-gear' ,
    'users-cog' ,
    'person-military-pointing' ,
    'building-columns' ,
    'bank' ,
    'institution' ,
    'museum' ,
    'university' ,
    'circle-t' ,
    'sack' ,
    'grid-2' ,
    'camera-cctv' ,
    'cctv' ,
    'umbrella' ,
    'trowel' ,
    'horizontal-rule' ,
    'bed-front' ,
    'bed-alt' ,
    'd' ,
    'stapler' ,
    'masks-theater' ,
    'theater-masks' ,
    'kip-sign' ,
    'face-woozy' ,
    'cloud-question' ,
    'pineapple' ,
    'hand-point-left' ,
    'gallery-thumbnails' ,
    'circle-j' ,
    'eyes' ,
    'handshake-simple' ,
    'handshake-alt' ,
    'page-caret-up' ,
    'file-caret-up' ,
    'jet-fighter' ,
    'fighter-jet' ,
    'comet' ,
    'square-share-nodes' ,
    'share-alt-square' ,
    'shield-keyhole' ,
    'barcode' ,
    'plus-minus' ,
    'square-sliders-vertical' ,
    'sliders-v-square' ,
    'video' ,
    'video-camera' ,
    'message-middle' ,
    'comment-middle-alt' ,
    'graduation-cap' ,
    'mortar-board' ,
    'hand-holding-medical' ,
    'person-circle-check' ,
    'square-z' ,
    'message-text' ,
    'comment-alt-text' ,
    'turn-up' ,
    'level-up-alt' ,
    '0' ,
    '1' ,
    '2' ,
    '3' ,
    '4' ,
    '5' ,
    '6' ,
    '7' ,
    '8' ,
    '9' ,
    'fill-drip' ,
    'arrows-to-circle' ,
    'circle-chevron-right' ,
    'chevron-circle-right' ,
    'wagon-covered' ,
    'line-height' ,
    'bagel' ,
    'transporter-7' ,
    'at' ,
    'rectangles-mixed' ,
    'phone-arrow-up-right' ,
    'phone-arrow-up' ,
    'phone-outgoing' ,
    'trash-can' ,
    'trash-alt' ,
    'circle-l' ,
    'head-side-goggles' ,
    'head-vr' ,
    'text-height' ,
    'user-xmark' ,
    'user-times' ,
    'face-hand-yawn' ,
    'gauge-simple-min' ,
    'tachometer-slowest' ,
    'stethoscope' ,
    'coffin' ,
    'message' ,
    'comment-alt' ,
    'salad' ,
    'bowl-salad' ,
    'info' ,
    'robot-astromech' ,
    'ring-diamond' ,
    'fondue-pot' ,
    'theta' ,
    'face-hand-peeking' ,
    'square-user' ,
    'down-left-and-up-right-to-center' ,
    'compress-alt' ,
    'explosion' ,
    'file-lines' ,
    'file-alt' ,
    'file-text' ,
    'wave-square' ,
    'ring' ,
    'building-un' ,
    'dice-three' ,
    'tire-pressure-warning' ,
    'wifi-fair' ,
    'wifi2' ,
    'calendar-days' ,
    'calendar-alt' ,
    'mp3-player' ,
    'anchor-circle-check' ,
    'tally-4' ,
    'rectangle-history' ,
    'building-circle-arrow-right' ,
    'volleyball' ,
    'volleyball-ball' ,
    'sun-haze' ,
    'text-size' ,
    'ufo' ,
    'fork' ,
    'utensil-fork' ,
    'arrows-up-to-line' ,
    'mobile-signal' ,
    'barcode-scan' ,
    'sort-down' ,
    'sort-desc' ,
    'folder-arrow-down' ,
    'folder-download' ,
    'circle-minus' ,
    'minus-circle' ,
    'face-icicles' ,
    'shovel' ,
    'door-open' ,
    'films' ,
    'right-from-bracket' ,
    'sign-out-alt' ,
    'face-glasses' ,
    'nfc' ,
    'atom' ,
    'soap' ,
    'icons' ,
    'heart-music-camera-bolt' ,
    'microphone-lines-slash' ,
    'microphone-alt-slash' ,
    'closed-captioning-slash' ,
    'calculator-simple' ,
    'calculator-alt' ,
    'bridge-circle-check' ,
    'sliders-up' ,
    'sliders-v' ,
    'location-minus' ,
    'map-marker-minus' ,
    'pump-medical' ,
    'fingerprint' ,
    'ski-boot' ,
    'standard-definition' ,
    'rectangle-sd' ,
    'h1' ,
    'hand-point-right' ,
    'magnifying-glass-location' ,
    'search-location' ,
    'message-bot' ,
    'forward-step' ,
    'step-forward' ,
    'face-smile-beam' ,
    'smile-beam' ,
    'light-ceiling' ,
    'message-exclamation' ,
    'comment-alt-exclamation' ,
    'bowl-scoop' ,
    'bowl-shaved-ice' ,
    'square-x' ,
    'utility-pole-double' ,
    'flag-checkered' ,
    'chevrons-up' ,
    'chevron-double-up' ,
    'football' ,
    'football-ball' ,
    'user-vneck' ,
    'school-circle-exclamation' ,
    'crop' ,
    'angles-down' ,
    'angle-double-down' ,
    'users-rectangle' ,
    'people-roof' ,
    'square-arrow-right' ,
    'arrow-square-right' ,
    'location-plus' ,
    'map-marker-plus' ,
    'lightbulb-exclamation-on' ,
    'people-line' ,
    'beer-mug-empty' ,
    'beer' ,
    'crate-empty' ,
    'diagram-predecessor' ,
    'transporter' ,
    'calendar-circle-user' ,
    'arrow-up-long' ,
    'long-arrow-up' ,
    'person-carry-box' ,
    'person-carry' ,
    'fire-flame-simple' ,
    'burn' ,
    'person' ,
    'male' ,
    'laptop' ,
    'file-csv' ,
    'menorah' ,
    'union' ,
    'chevrons-left' ,
    'chevron-double-left' ,
    'circle-heart' ,
    'heart-circle' ,
    'truck-plane' ,
    'record-vinyl' ,
    'bring-forward' ,
    'square-p' ,
    'face-grin-stars' ,
    'grin-stars' ,
    'sigma' ,
    'camera-movie' ,
    'bong' ,
    'clarinet' ,
    'truck-flatbed' ,
    'spaghetti-monster-flying' ,
    'pastafarianism' ,
    'arrow-down-up-across-line' ,
    'leaf-heart' ,
    'house-building' ,
    'cheese-swiss' ,
    'spoon' ,
    'utensil-spoon' ,
    'jar-wheat' ,
    'envelopes-bulk' ,
    'mail-bulk' ,
    'file-circle-exclamation' ,
    'bow-arrow' ,
    'cart-xmark' ,
    'hexagon-xmark' ,
    'times-hexagon' ,
    'xmark-hexagon' ,
    'circle-h' ,
    'hospital-symbol' ,
    'merge' ,
    'pager' ,
    'cart-minus' ,
    'address-book' ,
    'contact-book' ,
    'pan-frying' ,
    'grid' ,
    'grid3' ,
    'football-helmet' ,
    'hand-love' ,
    'trees' ,
    'strikethrough' ,
    'page' ,
    'k' ,
    'diagram-previous' ,
    'gauge-min' ,
    'tachometer-alt-slowest' ,
    'folder-grid' ,
    'eggplant' ,
    'ram' ,
    'landmark-flag' ,
    'lips' ,
    'pencil' ,
    'pencil-alt' ,
    'backward' ,
    'caret-right' ,
    'comments' ,
    'paste' ,
    'file-clipboard' ,
    'desktop-arrow-down' ,
    'code-pull-request' ,
    'pumpkin' ,
    'clipboard-list' ,
    'pen-field' ,
    'blueberries' ,
    'truck-ramp-box' ,
    'truck-loading' ,
    'note' ,
    'arrow-down-to-square' ,
    'user-check' ,
    'cloud-xmark' ,
    'vial-virus' ,
    'book-blank' ,
    'book-alt' ,
    'golf-flag-hole' ,
    'message-arrow-down' ,
    'comment-alt-arrow-down' ,
    'face-unamused' ,
    'sheet-plastic' ,
    'circle-9' ,
    'blog' ,
    'user-ninja' ,
    'pencil-slash' ,
    'bowling-pins' ,
    'person-arrow-up-from-line' ,
    'down-right' ,
    'scroll-torah' ,
    'torah' ,
    'blinds-open' ,
    'fence' ,
    'up' ,
    'arrow-alt-up' ,
    'broom-ball' ,
    'quidditch' ,
    'quidditch-broom-ball' ,
    'drumstick' ,
    'square-v' ,
    'face-awesome' ,
    'gave-dandy' ,
    'dial-off' ,
    'toggle-off' ,
    'face-smile-horns' ,
    'box-archive' ,
    'archive' ,
    'grapes' ,
    'person-drowning' ,
    'dial-max' ,
    'circle-m' ,
    'calendar-image' ,
    'circle-caret-down' ,
    'caret-circle-down' ,
    'arrow-down-9-1' ,
    'sort-numeric-desc' ,
    'sort-numeric-down-alt' ,
    'face-grin-tongue-squint' ,
    'grin-tongue-squint' ,
    'shish-kebab' ,
    'spray-can' ,
    'alarm-snooze' ,
    'scarecrow' ,
    'truck-monster' ,
    'gift-card' ,
    'w' ,
    'code-pull-request-draft' ,
    'square-b' ,
    'elephant' ,
    'earth-africa' ,
    'globe-africa' ,
    'rainbow' ,
    'circle-notch' ,
    'tablet-screen-button' ,
    'tablet-alt' ,
    'paw' ,
    'message-question' ,
    'cloud' ,
    'trowel-bricks' ,
    'square-3' ,
    'face-flushed' ,
    'flushed' ,
    'hospital-user' ,
    'microwave' ,
    'tent-arrow-left-right' ,
    'cart-circle-arrow-up' ,
    'trash-clock' ,
    'gavel' ,
    'legal' ,
    'sprinkler-ceiling' ,
    'browsers' ,
    'trillium' ,
    'music-slash' ,
    'truck-ramp' ,
    'binoculars' ,
    'microphone-slash' ,
    'box-tissue' ,
    'circle-c' ,
    'star-christmas' ,
    'chart-bullet' ,
    'motorcycle' ,
    'tree-christmas' ,
    'tire-flat' ,
    'sunglasses' ,
    'badge' ,
    'message-pen' ,
    'comment-alt-edit' ,
    'message-edit' ,
    'bell-concierge' ,
    'concierge-bell' ,
    'pen-ruler' ,
    'pencil-ruler' ,
    'chess-rook-piece' ,
    'chess-rook-alt' ,
    'square-root' ,
    'album-collection-circle-plus' ,
    'people-arrows' ,
    'people-arrows-left-right' ,
    'face-angry-horns' ,
    'mars-and-venus-burst' ,
    'tombstone' ,
    'square-caret-right' ,
    'caret-square-right' ,
    'scissors' ,
    'cut' ,
    'list-music' ,
    'sun-plant-wilt' ,
    'toilets-portable' ,
    'hockey-puck' ,
    'mustache' ,
    'hyphen' ,
    'table' ,
    'user-chef' ,
    'message-image' ,
    'comment-alt-image' ,
    'users-medical' ,
    'sensor-triangle-exclamation' ,
    'sensor-alert' ,
    'magnifying-glass-arrow-right' ,
    'tachograph-digital' ,
    'digital-tachograph' ,
    'face-mask' ,
    'pickleball' ,
    'star-sharp-half' ,
    'users-slash' ,
    'clover' ,
    'meat' ,
    'reply' ,
    'mail-reply' ,
    'star-and-crescent' ,
    'empty-set' ,
    'house-fire' ,
    'square-minus' ,
    'minus-square' ,
    'helicopter' ,
    'bird' ,
    'compass' ,
    'square-caret-down' ,
    'caret-square-down' ,
    'heart-half-stroke' ,
    'heart-half-alt' ,
    'file-circle-question' ,
    'laptop-code' ,
    'joystick' ,
    'grill-fire' ,
    'rectangle-vertical-history' ,
    'swatchbook' ,
    'prescription-bottle' ,
    'bars' ,
    'navicon' ,
    'keyboard-left' ,
    'people-group' ,
    'hourglass-end' ,
    'hourglass3' ,
    'heart-crack' ,
    'heart-broken' ,
    'face-beam-hand-over-mouth' ,
    'droplet-percent' ,
    'humidity' ,
    'square-up-right' ,
    'external-link-square-alt' ,
    'face-kiss-beam' ,
    'kiss-beam' ,
    'corn' ,
    'roller-coaster' ,
    'photo-film-music' ,
    'radar' ,
    'sickle' ,
    'film' ,
    'coconut' ,
    'ruler-horizontal' ,
    'shield-cross' ,
    'cassette-tape' ,
    'square-terminal' ,
    'people-robbery' ,
    'lightbulb' ,
    'caret-left' ,
    'comment-middle' ,
    'trash-can-list' ,
    'block' ,
    'circle-exclamation' ,
    'exclamation-circle' ,
    'school-circle-xmark' ,
    'arrow-right-from-bracket' ,
    'sign-out' ,
    'face-frown-slight' ,
    'circle-chevron-down' ,
    'chevron-circle-down' ,
    'sidebar-flip' ,
    'unlock-keyhole' ,
    'unlock-alt' ,
    'temperature-list' ,
    'cloud-showers-heavy' ,
    'headphones-simple' ,
    'headphones-alt' ,
    'sitemap' ,
    'pipe-section' ,
    'space-station-moon-construction' ,
    'space-station-moon-alt' ,
    'circle-dollar-to-slot' ,
    'donate' ,
    'memory' ,
    'face-sleeping' ,
    'road-spikes' ,
    'fire-burner' ,
    'squirrel' ,
    'arrow-up-to-line' ,
    'arrow-to-top' ,
    'flag' ,
    'face-cowboy-hat' ,
    'hanukiah' ,
    'chart-scatter-3d' ,
    'square-code' ,
    'feather' ,
    'volume-low' ,
    'volume-down' ,
    'xmark-to-slot' ,
    'times-to-slot' ,
    'vote-nay' ,
    'box-taped' ,
    'box-alt' ,
    'comment-slash' ,
    'swords' ,
    'cloud-sun-rain' ,
    'album' ,
    'circle-n' ,
    'compress' ,
    'wheat-awn' ,
    'wheat-alt' ,
    'ankh' ,
    'hands-holding-child' ,
    'asterisk' ,
    'key-skeleton-left-right' ,
    'comment-lines' ,
    'luchador-mask' ,
    'luchador' ,
    'mask-luchador' ,
    'square-check' ,
    'check-square' ,
    'shredder' ,
    'book-open-cover' ,
    'book-open-alt' ,
    'sandwich' ,
    'peseta-sign' ,
    'square-parking-slash' ,
    'parking-slash' ,
    'train-tunnel' ,
    'heading' ,
    'header' ,
    'ghost' ,
    'face-anguished' ,
    'hockey-sticks' ,
    'abacus' ,
    'film-simple' ,
    'film-alt' ,
    'list' ,
    'list-squares' ,
    'tree-palm' ,
    'square-phone-flip' ,
    'phone-square-alt' ,
    'cart-plus' ,
    'gamepad' ,
    'border-center-v' ,
    'circle-dot' ,
    'dot-circle' ,
    'clipboard-medical' ,
    'face-dizzy' ,
    'dizzy' ,
    'egg' ,
    'up-to-line' ,
    'arrow-alt-to-top' ,
    'house-medical-circle-xmark' ,
    'watch-fitness' ,
    'clock-nine-thirty' ,
    'campground' ,
    'folder-plus' ,
    'jug' ,
    'futbol' ,
    'futbol-ball' ,
    'soccer-ball' ,
    'snow-blowing' ,
    'paintbrush' ,
    'paint-brush' ,
    'lock' ,
    'arrow-down-from-line' ,
    'arrow-from-top' ,
    'gas-pump' ,
    'signal-bars-slash' ,
    'signal-alt-slash' ,
    'monkey' ,
    'rectangle-pro' ,
    'pro' ,
    'house-night' ,
    'hot-tub-person' ,
    'hot-tub' ,
    'blanket' ,
    'map-location' ,
    'map-marked' ,
    'house-flood-water' ,
    'comments-question-check' ,
    'tree' ,
    'arrows-cross' ,
    'backpack' ,
    'square-small' ,
    'folder-arrow-up' ,
    'folder-upload' ,
    'bridge-lock' ,
    'crosshairs-simple' ,
    'sack-dollar' ,
    'pen-to-square' ,
    'edit' ,
    'square-sliders' ,
    'sliders-h-square' ,
    'car-side' ,
    'message-middle-top' ,
    'comment-middle-top-alt' ,
    'lightbulb-on' ,
    'knife' ,
    'utensil-knife' ,
    'share-nodes' ,
    'share-alt' ,
    'wave-sine' ,
    'heart-circle-minus' ,
    'circle-w' ,
    'circle-calendar' ,
    'calendar-circle' ,
    'hourglass-half' ,
    'hourglass2' ,
    'microscope' ,
    'sunset' ,
    'sink' ,
    'calendar-exclamation' ,
    'truck-container-empty' ,
    'hand-heart' ,
    'bag-shopping' ,
    'shopping-bag' ,
    'arrow-down-z-a' ,
    'sort-alpha-desc' ,
    'sort-alpha-down-alt' ,
    'mitten' ,
    'reply-clock' ,
    'reply-time' ,
    'person-rays' ,
    'right' ,
    'arrow-alt-right' ,
    'circle-f' ,
    'users' ,
    'face-pleading' ,
    'eye-slash' ,
    'flask-vial' ,
    'police-box' ,
    'cucumber' ,
    'head-side-brain' ,
    'hand' ,
    'hand-paper' ,
    'person-biking-mountain' ,
    'biking-mountain' ,
    'utensils-slash' ,
    'print-magnifying-glass' ,
    'print-search' ,
    'folder-bookmark' ,
    'om' ,
    'pi' ,
    'flask-round-potion' ,
    'flask-potion' ,
    'face-shush' ,
    'worm' ,
    'house-circle-xmark' ,
    'plug' ,
    'calendar-circle-exclamation' ,
    'square-i' ,
    'chevron-up' ,
    'face-saluting' ,
    'gauge-simple-low' ,
    'tachometer-slow' ,
    'face-persevering' ,
    'circle-camera' ,
    'camera-circle' ,
    'hand-spock' ,
    'spider-web' ,
    'circle-microphone' ,
    'microphone-circle' ,
    'book-arrow-up' ,
    'popsicle' ,
    'command' ,
    'blinds' ,
    'stopwatch' ,
    'saxophone' ,
    'square-2' ,
    'field-hockey-stick-ball' ,
    'field-hockey' ,
    'arrow-up-square-triangle' ,
    'sort-shapes-up-alt' ,
    'face-scream' ,
    'square-m' ,
    'camera-web' ,
    'webcam' ,
    'comment-arrow-down' ,
    'lightbulb-cfl' ,
    'window-frame-open' ,
    'face-kiss' ,
    'kiss' ,
    'bridge-circle-xmark' ,
    'period' ,
    'face-grin-tongue' ,
    'grin-tongue' ,
    'up-to-dotted-line' ,
    'thought-bubble' ,
    'raygun' ,
    'flute' ,
    'acorn' ,
    'video-arrow-up-right' ,
    'grate-droplet' ,
    'seal-exclamation' ,
    'chess-bishop' ,
    'message-sms' ,
    'coffee-beans' ,
    'hat-witch' ,
    'face-grin-wink' ,
    'grin-wink' ,
    'clock-three-thirty' ,
    'ear-deaf' ,
    'deaf' ,
    'deafness' ,
    'hard-of-hearing' ,
    'alarm-clock' ,
    'eclipse' ,
    'face-relieved' ,
    'road-circle-check' ,
    'dice-five' ,
    'octagon-minus' ,
    'minus-octagon' ,
    'square-rss' ,
    'rss-square' ,
    'face-zany' ,
    'tricycle' ,
    'land-mine-on' ,
    'square-arrow-up-left' ,
    'i-cursor' ,
    'salt-shaker' ,
    'stamp' ,
    'file-plus' ,
    'draw-square' ,
    'toilet-paper-under-slash' ,
    'toilet-paper-reverse-slash' ,
    'stairs' ,
    'drone-front' ,
    'drone-alt' ,
    'glass-empty' ,
    'dial-high' ,
    'user-helmet-safety' ,
    'user-construction' ,
    'user-hard-hat' ,
    'i' ,
    'hryvnia-sign' ,
    'hryvnia' ,
    'arrow-down-left-and-arrow-up-right-to-center' ,
    'pills' ,
    'face-grin-wide' ,
    'grin-alt' ,
    'tooth' ,
    'basketball-hoop' ,
    'objects-align-bottom' ,
    'v' ,
    'sparkles' ,
    'squid' ,
    'leafy-green' ,
    'circle-arrow-up-right' ,
    'calendars' ,
    'bangladeshi-taka-sign' ,
    'bicycle' ,
    'hammer-war' ,
    'circle-d' ,
    'spider-black-widow' ,
    'staff-snake' ,
    'rod-asclepius' ,
    'rod-snake' ,
    'staff-aesculapius' ,
    'pear' ,
    'head-side-cough-slash' ,
    'triangle' ,
    'apartment' ,
    'truck-medical' ,
    'ambulance' ,
    'pepper' ,
    'piano' ,
    'gun-squirt' ,
    'wheat-awn-circle-exclamation' ,
    'snowman' ,
    'user-alien' ,
    'shield-check' ,
    'mortar-pestle' ,
    'road-barrier' ,
    'chart-candlestick' ,
    'briefcase-blank' ,
    'school' ,
    'igloo' ,
    'bracket-round' ,
    'parenthesis' ,
    'joint' ,
    'horse-saddle' ,
    'mug-marshmallows' ,
    'filters' ,
    'bell-on' ,
    'angle-right' ,
    'dial-med' ,
    'horse' ,
    'q' ,
    'monitor-waveform' ,
    'monitor-heart-rate' ,
    'link-simple' ,
    'whistle' ,
    'g' ,
    'wine-glass-crack' ,
    'fragile' ,
    'slot-machine' ,
    'notes-medical' ,
    'car-wash' ,
    'escalator' ,
    'comment-image' ,
    'temperature-half' ,
    'temperature2' ,
    'thermometer2' ,
    'thermometer-half' ,
    'dong-sign' ,
    'donut' ,
    'doughnut' ,
    'capsules' ,
    'poo-storm' ,
    'poo-bolt' ,
    'tally-1' ,
    'face-frown-open' ,
    'frown-open' ,
    'square-dashed' ,
    'square-j' ,
    'hand-point-up' ,
    'money-bill' ,
    'arrow-up-big-small' ,
    'sort-size-up' ,
    'barcode-read' ,
    'baguette' ,
    'bowl-soft-serve' ,
    'face-holding-back-tears' ,
    'square-up' ,
    'arrow-alt-square-up' ,
    'train-subway-tunnel' ,
    'subway-tunnel' ,
    'square-exclamation' ,
    'exclamation-square' ,
    'semicolon' ,
    'bookmark' ,
    'fan-table' ,
    'align-justify' ,
    'battery-low' ,
    'battery1' ,
    'credit-card-front' ,
    'brain-arrow-curved-right' ,
    'mind-share' ,
    'umbrella-beach' ,
    'helmet-un' ,
    'location-smile' ,
    'map-marker-smile' ,
    'arrow-left-to-line' ,
    'arrow-to-left' ,
    'bullseye' ,
    'sushi' ,
    'nigiri' ,
    'message-captions' ,
    'comment-alt-captions' ,
    'trash-list' ,
    'bacon' ,
    'option' ,
    'hand-point-down' ,
    'arrow-up-from-bracket' ,
    'trash-plus' ,
    'objects-align-top' ,
    'folder' ,
    'folder-blank' ,
    'face-anxious-sweat' ,
    'credit-card-blank' ,
    'file-waveform' ,
    'file-medical-alt' ,
    'microchip-ai' ,
    'mug' ,
    'plane-up-slash' ,
    'radiation' ,
    'pen-circle' ,
    'chart-simple' ,
    'crutches' ,
    'circle-parking' ,
    'parking-circle' ,
    'mars-stroke' ,
    'leaf-oak' ,
    'square-bolt' ,
    'vial' ,
    'gauge' ,
    'dashboard' ,
    'gauge-med' ,
    'tachometer-alt-average' ,
    'wand-magic-sparkles' ,
    'magic-wand-sparkles' ,
    'lambda' ,
    'e' ,
    'pizza' ,
    'bowl-chopsticks-noodles' ,
    'h3' ,
    'pen-clip' ,
    'pen-alt' ,
    'bridge-circle-exclamation' ,
    'badge-percent' ,
    'user' ,
    'sensor' ,
    'comma' ,
    'school-circle-check' ,
    'toilet-paper-under' ,
    'toilet-paper-reverse' ,
    'light-emergency' ,
    'arrow-down-to-arc' ,
    'dumpster' ,
    'van-shuttle' ,
    'shuttle-van' ,
    'building-user' ,
    'light-switch' ,
    'square-caret-left' ,
    'caret-square-left' ,
    'highlighter' ,
    'wave-pulse' ,
    'heart-rate' ,
    'key' ,
    'hat-santa' ,
    'tamale' ,
    'box-check' ,
    'bullhorn' ,
    'steak' ,
    'location-crosshairs-slash' ,
    'location-slash' ,
    'person-dolly' ,
    'globe' ,
    'synagogue' ,
    'file-chart-column' ,
    'file-chart-line' ,
    'person-half-dress' ,
    'folder-image' ,
    'calendar-pen' ,
    'calendar-edit' ,
    'road-bridge' ,
    'face-smile-tear' ,
    'message-plus' ,
    'comment-alt-plus' ,
    'location-arrow' ,
    'c' ,
    'tablet-button' ,
    'rectangle-history-circle-user' ,
    'building-lock' ,
    'chart-line-up' ,
    'mailbox' ,
    'truck-bolt' ,
    'pizza-slice' ,
    'money-bill-wave' ,
    'chart-area' ,
    'area-chart' ,
    'house-flag' ,
    'person-circle-minus' ,
    'scalpel' ,
    'ban' ,
    'cancel' ,
    'bell-exclamation' ,
    'circle-bookmark' ,
    'bookmark-circle' ,
    'egg-fried' ,
    'face-weary' ,
    'uniform-martial-arts' ,
    'camera-rotate' ,
    'sun-dust' ,
    'comment-text' ,
    'spray-can-sparkles' ,
    'air-freshener' ,
    'signal-bars' ,
    'signal-alt' ,
    'signal-alt4' ,
    'signal-bars-strong' ,
    'diamond-exclamation' ,
    'star' ,
    'dial-min' ,
    'repeat' ,
    'cross' ,
    'page-caret-down' ,
    'file-caret-down' ,
    'box' ,
    'venus-mars' ,
    'clock-seven-thirty' ,
    'arrow-pointer' ,
    'mouse-pointer' ,
    'clock-four-thirty' ,
    'signal-bars-good' ,
    'signal-alt3' ,
    'cactus' ,
    'maximize' ,
    'expand-arrows-alt' ,
    'charging-station' ,
    'shapes' ,
    'triangle-circle-square' ,
    'plane-tail' ,
    'gauge-simple-max' ,
    'tachometer-fastest' ,
    'circle-u' ,
    'shield-slash' ,
    'square-phone-hangup' ,
    'phone-square-down' ,
    'arrow-up-left' ,
    'transporter-1' ,
    'peanuts' ,
    'shuffle' ,
    'random' ,
    'person-running' ,
    'running' ,
    'mobile-retro' ,
    'grip-lines-vertical' ,
    'arrow-up-from-square' ,
    'file-dashed-line' ,
    'page-break' ,
    'bracket-curly-right' ,
    'spider' ,
    'clock-three' ,
    'hands-bound' ,
    'scalpel-line-dashed' ,
    'scalpel-path' ,
    'file-invoice-dollar' ,
    'pipe-smoking' ,
    'face-astonished' ,
    'window' ,
    'plane-circle-exclamation' ,
    'ear' ,
    'file-lock' ,
    'diagram-venn' ,
    'x-ray' ,
    'goal-net' ,
    'coffin-cross' ,
    'spell-check' ,
    'location-xmark' ,
    'map-marker-times' ,
    'map-marker-xmark' ,
    'lasso' ,
    'slash' ,
    'person-to-portal' ,
    'portal-enter' ,
    'calendar-star' ,
    'computer-mouse' ,
    'mouse' ,
    'arrow-right-to-bracket' ,
    'sign-in' ,
    'pegasus' ,
    'files-medical' ,
    'nfc-lock' ,
    'person-ski-lift' ,
    'ski-lift' ,
    'square-6' ,
    'shop-slash' ,
    'store-alt-slash' ,
    'wind-turbine' ,
    'sliders-simple' ,
    'badge-sheriff' ,
    'server' ,
    'virus-covid-slash' ,
    'intersection' ,
    'shop-lock' ,
    'family' ,
    'hourglass-start' ,
    'hourglass1' ,
    'user-hair-buns' ,
    'blender-phone' ,
    'hourglass-clock' ,
    'person-seat-reclined' ,
    'paper-plane-top' ,
    'paper-plane-alt' ,
    'send' ,
    'message-arrow-up' ,
    'comment-alt-arrow-up' ,
    'lightbulb-exclamation' ,
    'layer-minus' ,
    'layer-group-minus' ,
    'circle-e' ,
    'building-wheat' ,
    'gauge-max' ,
    'tachometer-alt-fastest' ,
    'person-breastfeeding' ,
    'apostrophe' ,
    'fire-hydrant' ,
    'right-to-bracket' ,
    'sign-in-alt' ,
    'video-plus' ,
    'square-right' ,
    'arrow-alt-square-right' ,
    'comment-smile' ,
    'venus' ,
    'passport' ,
    'inbox-in' ,
    'inbox-arrow-down' ,
    'heart-pulse' ,
    'heartbeat' ,
    'circle-8' ,
    'clouds-moon' ,
    'clock-ten-thirty' ,
    'people-carry-box' ,
    'people-carry' ,
    'folder-user' ,
    'trash-can-xmark' ,
    'temperature-high' ,
    'microchip' ,
    'left-long-to-line' ,
    'crown' ,
    'weight-hanging' ,
    'xmarks-lines' ,
    'file-prescription' ,
    'calendar-range' ,
    'flower-daffodil' ,
    'hand-back-point-up' ,
    'weight-scale' ,
    'weight' ,
    'star-exclamation' ,
    'books' ,
    'user-group' ,
    'user-friends' ,
    'arrow-up-a-z' ,
    'sort-alpha-up' ,
    'layer-plus' ,
    'layer-group-plus' ,
    'play-pause' ,
    'block-question' ,
    'snooze' ,
    'zzz' ,
    'scanner-image' ,
    'tv-retro' ,
    'square-t' ,
    'farm' ,
    'barn-silo' ,
    'chess-knight' ,
    'bars-sort' ,
    'pallet-boxes' ,
    'palette-boxes' ,
    'pallet-alt' ,
    'face-laugh-squint' ,
    'laugh-squint' ,
    'code-simple' ,
    'bolt-slash' ,
    'panel-fire' ,
    'binary-circle-check' ,
    'comment-minus' ,
    'burrito' ,
    'violin' ,
    'objects-column' ,
    'square-chevron-down' ,
    'chevron-square-down' ,
    'comment-plus' ,
    'triangle-instrument' ,
    'triangle-music' ,
    'wheelchair' ,
    'user-pilot-tie' ,
    'piano-keyboard' ,
    'bed-empty' ,
    'circle-arrow-up' ,
    'arrow-circle-up' ,
    'toggle-on' ,
    'rectangle-vertical' ,
    'rectangle-portrait' ,
    'person-walking' ,
    'walking' ,
    'l' ,
    'signal-stream' ,
    'down-to-bracket' ,
    'circle-z' ,
    'stars' ,
    'fire' ,
    'bed-pulse' ,
    'procedures' ,
    'house-day' ,
    'shuttle-space' ,
    'space-shuttle' ,
    'shirt-long-sleeve' ,
    'chart-pie-simple' ,
    'chart-pie-alt' ,
    'face-laugh' ,
    'laugh' ,
    'folder-open' ,
    'album-collection-circle-user' ,
    'candy' ,
    'bowl-hot' ,
    'soup' ,
    'flatbread' ,
    'heart-circle-plus' ,
    'code-fork' ,
    'city' ,
    'signal-bars-weak' ,
    'signal-alt1' ,
    'microphone-lines' ,
    'microphone-alt' ,
    'clock-twelve' ,
    'pepper-hot' ,
    'citrus-slice' ,
    'sheep' ,
    'unlock' ,
    'colon-sign' ,
    'headset' ,
    'badger-honey' ,
    'h4' ,
    'store-slash' ,
    'road-circle-xmark' ,
    'signal-slash' ,
    'user-minus' ,
    'mars-stroke-up' ,
    'mars-stroke-v' ,
    'champagne-glasses' ,
    'glass-cheers' ,
    'taco' ,
    'hexagon-plus' ,
    'plus-hexagon' ,
    'clipboard' ,
    'house-circle-exclamation' ,
    'file-arrow-up' ,
    'file-upload' ,
    'wifi' ,
    'wifi3' ,
    'wifi-strong' ,
    'messages' ,
    'comments-alt' ,
    'bath' ,
    'bathtub' ,
    'umbrella-simple' ,
    'umbrella-alt' ,
    'rectangle-history-circle-plus' ,
    'underline' ,
    'prescription-bottle-pill' ,
    'user-pen' ,
    'user-edit' ,
    'binary-slash' ,
    'square-o' ,
    'signature' ,
    'stroopwafel' ,
    'bold' ,
    'anchor-lock' ,
    'building-ngo' ,
    'transporter-3' ,
    'engine-warning' ,
    'engine-exclamation' ,
    'circle-down-right' ,
    'square-k' ,
    'manat-sign' ,
    'money-check-pen' ,
    'money-check-edit' ,
    'not-equal' ,
    'border-top-left' ,
    'border-style' ,
    'map-location-dot' ,
    'map-marked-alt' ,
    'tilde' ,
    'jedi' ,
    'square-poll-vertical' ,
    'poll' ,
    'arrow-down-square-triangle' ,
    'sort-shapes-down-alt' ,
    'mug-hot' ,
    'dog-leashed' ,
    'car-battery' ,
    'battery-car' ,
    'face-downcast-sweat' ,
    'mailbox-flag-up' ,
    'memo-circle-info' ,
    'gift' ,
    'dice-two' ,
    'volume' ,
    'volume-medium' ,
    'transporter-5' ,
    'gauge-circle-bolt' ,
    'coin-front' ,
    'file-slash' ,
    'message-arrow-up-right' ,
    'treasure-chest' ,
    'chess-queen' ,
    'paintbrush-fine' ,
    'paint-brush-alt' ,
    'paint-brush-fine' ,
    'paintbrush-alt' ,
    'glasses' ,
    'hood-cloak' ,
    'square-quote' ,
    'up-left' ,
    'bring-front' ,
    'chess-board' ,
    'burger-cheese' ,
    'cheeseburger' ,
    'building-circle-check' ,
    'repeat-1' ,
    'arrow-down-to-line' ,
    'arrow-to-bottom' ,
    'grid-5' ,
    'right-long-to-line' ,
    'person-chalkboard' ,
    'mars-stroke-right' ,
    'mars-stroke-h' ,
    'hand-back-fist' ,
    'hand-rock' ,
    'tally' ,
    'tally5' ,
    'square-caret-up' ,
    'caret-square-up' ,
    'cloud-showers-water' ,
    'chart-bar' ,
    'bar-chart' ,
    'hands-bubbles' ,
    'hands-wash' ,
    'less-than-equal' ,
    'train' ,
    'up-from-dotted-line' ,
    'eye-low-vision' ,
    'low-vision' ,
    'traffic-light-go' ,
    'face-exhaling' ,
    'sensor-fire' ,
    'user-unlock' ,
    'hexagon-divide' ,
    '00' ,
    'crow' ,
    'cassette-betamax' ,
    'betamax' ,
    'sailboat' ,
    'window-restore' ,
    'nfc-magnifying-glass' ,
    'file-binary' ,
    'circle-v' ,
    'square-plus' ,
    'plus-square' ,
    'bowl-scoops' ,
    'mistletoe' ,
    'custard' ,
    'lacrosse-stick' ,
    'hockey-mask' ,
    'sunrise' ,
    'panel-ews' ,
    'torii-gate' ,
    'cloud-exclamation' ,
    'message-lines' ,
    'comment-alt-lines' ,
    'frog' ,
    'bucket' ,
    'floppy-disk-pen' ,
    'image' ,
    'window-frame' ,
    'microphone' ,
    'cow' ,
    'square-ring' ,
    'down-from-line' ,
    'arrow-alt-from-top' ,
    'caret-up' ,
    'shield-xmark' ,
    'shield-times' ,
    'screwdriver' ,
    'circle-sort-down' ,
    'sort-circle-down' ,
    'folder-closed' ,
    'house-tsunami' ,
    'square-nfi' ,
    'forklift' ,
    'arrow-up-from-ground-water' ,
    'bracket-square-right' ,
    'martini-glass' ,
    'glass-martini-alt' ,
    'rotate-left' ,
    'rotate-back' ,
    'rotate-backward' ,
    'undo-alt' ,
    'table-columns' ,
    'columns' ,
    'square-a' ,
    'tick' ,
    'lemon' ,
    'head-side-mask' ,
    'handshake' ,
    'gem' ,
    'dolly' ,
    'dolly-box' ,
    'smoking' ,
    'minimize' ,
    'compress-arrows-alt' ,
    'refrigerator' ,
    'monument' ,
    'octagon-xmark' ,
    'times-octagon' ,
    'xmark-octagon' ,
    'align-slash' ,
    'snowplow' ,
    'angles-right' ,
    'angle-double-right' ,
    'truck-ramp-couch' ,
    'truck-couch' ,
    'cannabis' ,
    'circle-play' ,
    'play-circle' ,
    'arrow-up-right-and-arrow-down-left-from-center' ,
    'tablets' ,
    '360-degrees' ,
    'ethernet' ,
    'euro-sign' ,
    'eur' ,
    'euro' ,
    'chair' ,
    'circle-check' ,
    'check-circle' ,
    'square-dashed-circle-plus' ,
    'money-simple-from-bracket' ,
    'bat' ,
    'circle-stop' ,
    'stop-circle' ,
    'head-side-headphones' ,
    'phone-rotary' ,
    'compass-drafting' ,
    'drafting-compass' ,
    'plate-wheat' ,
    'calendar-circle-minus' ,
    'chopsticks' ,
    'car-wrench' ,
    'car-mechanic' ,
    'icicles' ,
    'person-shelter' ,
    'neuter' ,
    'id-badge' ,
    'kazoo' ,
    'marker' ,
    'face-laugh-beam' ,
    'laugh-beam' ,
    'square-arrow-down-left' ,
    'battery-bolt' ,
    'tree-large' ,
    'helicopter-symbol' ,
    'aperture' ,
    'universal-access' ,
    'file-magnifying-glass' ,
    'file-search' ,
    'up-right' ,
    'circle-chevron-up' ,
    'chevron-circle-up' ,
    'user-police' ,
    'lari-sign' ,
    'volcano' ,
    'teddy-bear' ,
    'stocking' ,
    'person-walking-dashed-line-arrow-right' ,
    'image-slash' ,
    'mask-snorkel' ,
    'smoke' ,
    'sterling-sign' ,
    'gbp' ,
    'pound-sign' ,
    'battery-exclamation' ,
    'viruses' ,
    'square-person-confined' ,
    'user-tie' ,
    'arrow-down-long' ,
    'long-arrow-down' ,
    'tent-arrow-down-to-line' ,
    'certificate' ,
    'crystal-ball' ,
    'reply-all' ,
    'mail-reply-all' ,
    'suitcase' ,
    'person-skating' ,
    'skating' ,
    'star-shooting' ,
    'binary-lock' ,
    'filter-circle-dollar' ,
    'funnel-dollar' ,
    'camera-retro' ,
    'circle-arrow-down' ,
    'arrow-circle-down' ,
    'comment-pen' ,
    'comment-edit' ,
    'file-import' ,
    'arrow-right-to-file' ,
    'banjo' ,
    'square-arrow-up-right' ,
    'external-link-square' ,
    'light-emergency-on' ,
    'kerning' ,
    'box-open' ,
    'square-f' ,
    'scroll' ,
    'spa' ,
    'arrow-left-from-line' ,
    'arrow-from-right' ,
    'strawberry' ,
    'location-pin-lock' ,
    'pause' ,
    'clock-eight-thirty' ,
    'plane-engines' ,
    'plane-alt' ,
    'hill-avalanche' ,
    'temperature-empty' ,
    'temperature0' ,
    'thermometer0' ,
    'thermometer-empty' ,
    'bomb' ,
    'gauge-low' ,
    'tachometer-alt-slow' ,
    'registered' ,
    'trash-can-plus' ,
    'address-card' ,
    'contact-card' ,
    'vcard' ,
    'scale-unbalanced-flip' ,
    'balance-scale-right' ,
    'globe-snow' ,
    'subscript' ,
    'diamond-turn-right' ,
    'directions' ,
    'integral' ,
    'burst' ,
    'house-laptop' ,
    'laptop-house' ,
    'face-tired' ,
    'tired' ,
    'money-bills' ,
    'blinds-raised' ,
    'smog' ,
    'ufo-beam' ,
    'circle-caret-up' ,
    'caret-circle-up' ,
    'user-vneck-hair-long' ,
    'square-a-lock' ,
    'crutch' ,
    'gas-pump-slash' ,
    'font-awesome' ,
    'font-awesome-flag' ,
    'font-awesome-logo-full' ,
    'cloud-arrow-up' ,
    'cloud-upload' ,
    'cloud-upload-alt' ,
    'palette' ,
    'transporter-4' ,
    'objects-align-right' ,
    'arrows-turn-right' ,
    'vest' ,
    'pig' ,
    'inbox-full' ,
    'circle-envelope' ,
    'envelope-circle' ,
    'triangle-person-digging' ,
    'construction' ,
    'ferry' ,
    'bullseye-arrow' ,
    'arrows-down-to-people' ,
    'seedling' ,
    'sprout' ,
    'clock-seven' ,
    'left-right' ,
    'arrows-alt-h' ,
    'boxes-packing' ,
    'circle-arrow-left' ,
    'arrow-circle-left' ,
    'flashlight' ,
    'group-arrows-rotate' ,
    'bowl-food' ,
    'square-9' ,
    'candy-cane' ,
    'arrow-down-wide-short' ,
    'sort-amount-asc' ,
    'sort-amount-down' ,
    'square-dollar' ,
    'dollar-square' ,
    'usd-square' ,
    'phone-arrow-right' ,
    'hand-holding-seedling' ,
    'message-check' ,
    'comment-alt-check' ,
    'cloud-bolt' ,
    'thunderstorm' ,
    'text-slash' ,
    'remove-format' ,
    'watch' ,
    'circle-down-left' ,
    'text' ,
    'projector' ,
    'face-smile-wink' ,
    'smile-wink' ,
    'tombstone-blank' ,
    'tombstone-alt' ,
    'chess-king-piece' ,
    'chess-king-alt' ,
    'circle-6' ,
    'left' ,
    'arrow-alt-left' ,
    'file-word' ,
    'file-powerpoint' ,
    'square-down' ,
    'arrow-alt-square-down' ,
    'objects-align-center-vertical' ,
    'arrows-left-right' ,
    'arrows-h' ,
    'house-lock' ,
    'cloud-arrow-down' ,
    'cloud-download' ,
    'cloud-download-alt' ,
    'wreath' ,
    'children' ,
    'meter-droplet' ,
    'chalkboard' ,
    'blackboard' ,
    'user-large-slash' ,
    'user-alt-slash' ,
    'signal-strong' ,
    'signal4' ,
    'lollipop' ,
    'lollypop' ,
    'list-tree' ,
    'envelope-open' ,
    'draw-circle' ,
    'cat-space' ,
    'handshake-simple-slash' ,
    'handshake-alt-slash' ,
    'rabbit-running' ,
    'rabbit-fast' ,
    'memo-pad' ,
    'mattress-pillow' ,
    'alarm-plus' ,
    'alicorn' ,
    'comment-question' ,
    'gingerbread-man' ,
    'guarani-sign' ,
    'burger-fries' ,
    'mug-tea' ,
    'border-top' ,
    'arrows-rotate' ,
    'refresh' ,
    'sync' ,
    'circle-book-open' ,
    'book-circle' ,
    'arrows-to-dotted-line' ,
    'fire-extinguisher' ,
    'garage-open' ,
    'shelves-empty' ,
    'cruzeiro-sign' ,
    'watch-apple' ,
    'watch-calculator' ,
    'list-dropdown' ,
    'cabinet-filing' ,
    'burger-soda' ,
    'square-arrow-up' ,
    'arrow-square-up' ,
    'greater-than-equal' ,
    'pallet-box' ,
    'face-confounded' ,
    'shield-halved' ,
    'shield-alt' ,
    'truck-plow' ,
    'book-atlas' ,
    'atlas' ,
    'virus' ,
    'comment-middle-top' ,
    'envelope-circle-check' ,
    'layer-group' ,
    'restroom-simple' ,
    'arrows-to-dot' ,
    'border-outer' ,
    'hashtag-lock' ,
    'clock-two-thirty' ,
    'archway' ,
    'heart-circle-check' ,
    'house-chimney-crack' ,
    'house-damage' ,
    'file-zipper' ,
    'file-archive' ,
    'heart-half' ,
    'comment-check' ,
    'square' ,
    'memo' ,
    'martini-glass-empty' ,
    'glass-martini' ,
    'couch' ,
    'cedi-sign' ,
    'italic' ,
    'glass-citrus' ,
    'calendar-lines-pen' ,
    'church' ,
    'person-snowmobiling' ,
    'snowmobile' ,
    'face-hushed' ,
    'comments-dollar' ,
    'pickaxe' ,
    'link-simple-slash' ,
    'democrat' ,
    'face-confused' ,
    'pinball' ,
    'z' ,
    'person-skiing' ,
    'skiing' ,
    'deer' ,
    'input-pipe' ,
    'road-lock' ,
    'a' ,
    'bookmark-slash' ,
    'temperature-arrow-down' ,
    'temperature-down' ,
    'mace' ,
    'feather-pointed' ,
    'feather-alt' ,
    'sausage' ,
    'trash-can-clock' ,
    'p' ,
    'snowflake' ,
    'stomach' ,
    'newspaper' ,
    'rectangle-ad' ,
    'ad' ,
    'guitar-electric' ,
    'arrow-turn-down-right' ,
    'moon-cloud' ,
    'bread-slice-butter' ,
    'circle-arrow-right' ,
    'arrow-circle-right' ,
    'user-group-crown' ,
    'users-crown' ,
    'circle-i' ,
    'toilet-paper-check' ,
    'filter-circle-xmark' ,
    'locust' ,
    'sort' ,
    'unsorted' ,
    'list-ol' ,
    'list12' ,
    'list-numeric' ,
    'chart-waterfall' ,
    'face-party' ,
    'kidneys' ,
    'wifi-exclamation' ,
    'chart-network' ,
    'person-dress-burst' ,
    'dice-d4' ,
    'money-check-dollar' ,
    'money-check-alt' ,
    'vector-square' ,
    'bread-slice' ,
    'language' ,
    'wheat-awn-slash' ,
    'face-kiss-wink-heart' ,
    'kiss-wink-heart' ,
    'dagger' ,
    'podium' ,
    'memo-circle-check' ,
    'route-highway' ,
    'down-to-line' ,
    'arrow-alt-to-bottom' ,
    'filter' ,
    'square-g' ,
    'circle-phone' ,
    'phone-circle' ,
    'clipboard-prescription' ,
    'user-nurse-hair' ,
    'question' ,
    'file-signature' ,
    'toggle-large-on' ,
    'up-down-left-right' ,
    'arrows-alt' ,
    'dryer-heat' ,
    'dryer-alt' ,
    'house-chimney-user' ,
    'hand-holding-heart' ,
    'arrow-up-small-big' ,
    'sort-size-up-alt' ,
    'train-track' ,
    'puzzle-piece' ,
    'money-check' ,
    'star-half-stroke' ,
    'star-half-alt' ,
    'file-exclamation' ,
    'code' ,
    'whiskey-glass' ,
    'glass-whiskey' ,
    'moon-stars' ,
    'building-circle-exclamation' ,
    'clothes-hanger' ,
    'mobile-notch' ,
    'mobile-iphone' ,
    'magnifying-glass-chart' ,
    'arrow-up-right-from-square' ,
    'external-link' ,
    'cubes-stacked' ,
    'images-user' ,
    'won-sign' ,
    'krw' ,
    'won' ,
    'image-polaroid-user' ,
    'virus-covid' ,
    'square-ellipsis' ,
    'pie' ,
    'chess-knight-piece' ,
    'chess-knight-alt' ,
    'austral-sign' ,
    'cloud-plus' ,
    'f' ,
    'leaf' ,
    'bed-bunk' ,
    'road' ,
    'taxi' ,
    'cab' ,
    'person-circle-plus' ,
    'chart-pie' ,
    'pie-chart' ,
    'bolt-lightning' ,
    'clock-eight' ,
    'sack-xmark' ,
    'file-excel' ,
    'file-contract' ,
    'fish-fins' ,
    'circle-q' ,
    'building-flag' ,
    'face-grin-beam' ,
    'grin-beam' ,
    'object-ungroup' ,
    'face-disguise' ,
    'circle-arrow-down-right' ,
    'alien-8bit' ,
    'alien-monster' ,
    'hand-point-ribbon' ,
    'poop' ,
    'object-exclude' ,
    'telescope' ,
    'location-pin' ,
    'map-marker' ,
    'square-list' ,
    'kaaba' ,
    'toilet-paper' ,
    'helmet-safety' ,
    'hard-hat' ,
    'hat-hard' ,
    'comment-code' ,
    'sim-cards' ,
    'starship' ,
    'eject' ,
    'circle-right' ,
    'arrow-alt-circle-right' ,
    'plane-circle-check' ,
    'seal' ,
    'user-cowboy' ,
    'hexagon-vertical-nft' ,
    'face-rolling-eyes' ,
    'meh-rolling-eyes' ,
    'bread-loaf' ,
    'rings-wedding' ,
    'object-group' ,
    'french-fries' ,
    'chart-line' ,
    'line-chart' ,
    'calendar-arrow-down' ,
    'calendar-download' ,
    'send-back' ,
    'mask-ventilator' ,
    'signature-lock' ,
    'arrow-right' ,
    'signs-post' ,
    'map-signs' ,
    'octagon-plus' ,
    'plus-octagon' ,
    'cash-register' ,
    'person-circle-question' ,
    'melon-slice' ,
    'space-station-moon' ,
    'message-smile' ,
    'comment-alt-smile' ,
    'cup-straw' ,
    'left-from-line' ,
    'arrow-alt-from-right' ,
    'h' ,
    'basket-shopping-simple' ,
    'shopping-basket-alt' ,
    'hands-holding-heart' ,
    'hands-heart' ,
    'clock-nine' ,
    'tarp' ,
    'face-sleepy' ,
    'hand-horns' ,
    'screwdriver-wrench' ,
    'tools' ,
    'arrows-to-eye' ,
    'circle-three-quarters' ,
    'trophy-star' ,
    'trophy-alt' ,
    'plug-circle-bolt' ,
    'face-thermometer' ,
    'shirt-running' ,
    'book-circle-arrow-up' ,
    'face-nauseated' ,
    'heart' ,
    'file-chart-pie' ,
    'mars-and-venus' ,
    'house-user' ,
    'home-user' ,
    'circle-arrow-down-left' ,
    'dumpster-fire' ,
    'hexagon-minus' ,
    'minus-hexagon' ,
    'left-to-line' ,
    'arrow-alt-to-left' ,
    'house-crack' ,
    'paw-simple' ,
    'paw-alt' ,
    'arrow-left-long-to-line' ,
    'brackets-round' ,
    'parentheses' ,
    'martini-glass-citrus' ,
    'cocktail' ,
    'user-shakespeare' ,
    'arrow-right-to-arc' ,
    'face-surprise' ,
    'surprise' ,
    'bottle-water' ,
    'circle-pause' ,
    'pause-circle' ,
    'gauge-circle-plus' ,
    'folders' ,
    'angel' ,
    'value-absolute' ,
    'rabbit' ,
    'toilet-paper-slash' ,
    'apple-whole' ,
    'apple-alt' ,
    'kitchen-set' ,
    'diamond-half' ,
    'lock-keyhole' ,
    'lock-alt' ,
    'r' ,
    'temperature-quarter' ,
    'temperature1' ,
    'thermometer1' ,
    'thermometer-quarter' ,
    'square-info' ,
    'info-square' ,
    'wifi-slash' ,
    'toilet-paper-xmark' ,
    'hands-holding-dollar' ,
    'hands-usd' ,
    'cube' ,
    'arrow-down-triangle-square' ,
    'sort-shapes-down' ,
    'bitcoin-sign' ,
    'shutters' ,
    'shield-dog' ,
    'solar-panel' ,
    'lock-open' ,
    'table-tree' ,
    'house-chimney-heart' ,
    'tally-3' ,
    'elevator' ,
    'money-bill-transfer' ,
    'money-bill-trend-up' ,
    'house-flood-water-circle-arrow-right' ,
    'square-poll-horizontal' ,
    'poll-h' ,
    'circle' ,
    'cart-circle-exclamation' ,
    'sword' ,
    'backward-fast' ,
    'fast-backward' ,
    'recycle' ,
    'user-astronaut' ,
    'interrobang' ,
    'plane-slash' ,
    'circle-dashed' ,
    'trademark' ,
    'basketball' ,
    'basketball-ball' ,
    'fork-knife' ,
    'utensils-alt' ,
    'satellite-dish' ,
    'badge-check' ,
    'circle-up' ,
    'arrow-alt-circle-up' ,
    'slider' ,
    'mobile-screen-button' ,
    'mobile-alt' ,
    'clock-one-thirty' ,
    'inbox-out' ,
    'inbox-arrow-up' ,
    'cloud-slash' ,
    'volume-high' ,
    'volume-up' ,
    'users-rays' ,
    'wallet' ,
    'octagon-check' ,
    'flatbread-stuffed' ,
    'clipboard-check' ,
    'cart-circle-plus' ,
    'truck-clock' ,
    'shipping-timed' ,
    'pool-8-ball' ,
    'file-audio' ,
    'turn-down-left' ,
    'lock-hashtag' ,
    'chart-radar' ,
    'staff' ,
    'burger' ,
    'hamburger' ,
    'utility-pole' ,
    'transporter-6' ,
    'wrench' ,
    'bugs' ,
    'vector-polygon' ,
    'diagram-nested' ,
    'rupee-sign' ,
    'rupee' ,
    'file-image' ,
    'circle-question' ,
    'question-circle' ,
    'image-user' ,
    'buoy' ,
    'plane-departure' ,
    'handshake-slash' ,
    'book-bookmark' ,
    'border-center-h' ,
    'can-food' ,
    'typewriter' ,
    'arrow-right-from-arc' ,
    'circle-k' ,
    'face-hand-over-mouth' ,
    'popcorn' ,
    'house-water' ,
    'house-flood' ,
    'object-subtract' ,
    'code-branch' ,
    'warehouse-full' ,
    'warehouse-alt' ,
    'hat-cowboy' ,
    'bridge' ,
    'phone-flip' ,
    'phone-alt' ,
    'arrow-down-from-dotted-line' ,
    'square-quarters' ,
    'truck-front' ,
    'cat' ,
    'trash-xmark' ,
    'circle-caret-left' ,
    'caret-circle-left' ,
    'files' ,
    'anchor-circle-exclamation' ,
    'face-clouds' ,
    'user-crown' ,
    'truck-field' ,
    'route' ,
    'cart-circle-check' ,
    'clipboard-question' ,
    'panorama' ,
    'comment-medical' ,
    'teeth-open' ,
    'user-tie-hair-long' ,
    'file-circle-minus' ,
    'head-side-medical' ,
    'tags' ,
    'wine-glass' ,
    'forward-fast' ,
    'fast-forward' ,
    'face-meh-blank' ,
    'meh-blank' ,
    'user-robot' ,
    'square-parking' ,
    'parking' ,
    'card-diamond' ,
    'face-zipper' ,
    'face-raised-eyebrow' ,
    'house-signal' ,
    'square-chevron-up' ,
    'chevron-square-up' ,
    'nfc-symbol' ,
    'bars-progress' ,
    'tasks-alt' ,
    'faucet-drip' ,
    'arrows-to-line' ,
    'dolphin' ,
    'arrow-up-right' ,
    'circle-r' ,
    'cart-flatbed' ,
    'dolly-flatbed' ,
    'ban-smoking' ,
    'smoking-ban' ,
    'circle-sort-up' ,
    'sort-circle-up' ,
    'terminal' ,
    'mobile-button' ,
    'house-medical-flag' ,
    'basket-shopping' ,
    'shopping-basket' ,
    'tape' ,
    'chestnut' ,
    'bus-simple' ,
    'bus-alt' ,
    'eye' ,
    'face-sad-cry' ,
    'sad-cry' ,
    'heat' ,
    'ticket-airline' ,
    'boot-heeled' ,
    'arrows-minimize' ,
    'compress-arrows' ,
    'audio-description' ,
    'person-military-to-person' ,
    'file-shield' ,
    'hexagon' ,
    'manhole' ,
    'user-slash' ,
    'pen' ,
    'tower-observation' ,
    'floppy-disks' ,
    'toilet-paper-blank-under' ,
    'toilet-paper-reverse-alt' ,
    'file-code' ,
    'signal' ,
    'signal5' ,
    'signal-perfect' ,
    'pump' ,
    'bus' ,
    'heart-circle-xmark' ,
    'arrow-up-left-from-circle' ,
    'house-chimney' ,
    'home-lg' ,
    'window-maximize' ,
    'dryer' ,
    'face-frown' ,
    'frown' ,
    'chess-bishop-piece' ,
    'chess-bishop-alt' ,
    'shirt-tank-top' ,
    'diploma' ,
    'scroll-ribbon' ,
    'screencast' ,
    'walker' ,
    'prescription' ,
    'shop' ,
    'store-alt' ,
    'floppy-disk' ,
    'save' ,
    'vihara' ,
    'face-kiss-closed-eyes' ,
    'scale-unbalanced' ,
    'balance-scale-left' ,
    'file-user' ,
    'user-police-tie' ,
    'face-tongue-money' ,
    'tennis-ball' ,
    'square-l' ,
    'sort-up' ,
    'sort-asc' ,
    'calendar-arrow-up' ,
    'calendar-upload' ,
    'comment-dots' ,
    'commenting' ,
    'plant-wilt' ,
    'scarf' ,
    'album-circle-plus' ,
    'user-nurse-hair-long' ,
    'diamond' ,
    'square-left' ,
    'arrow-alt-square-left' ,
    'face-grin-squint' ,
    'grin-squint' ,
    'circle-ellipsis-vertical' ,
    'hand-holding-dollar' ,
    'hand-holding-usd' ,
    'grid-dividers' ,
    'bacterium' ,
    'hand-pointer' ,
    'drum-steelpan' ,
    'hand-scissors' ,
    'hands-praying' ,
    'praying-hands' ,
    'face-pensive' ,
    'user-music' ,
    'arrow-rotate-right' ,
    'arrow-right-rotate' ,
    'arrow-rotate-forward' ,
    'redo' ,
    'messages-dollar' ,
    'comments-alt-dollar' ,
    'sensor-on' ,
    'balloon' ,
    'biohazard' ,
    'chess-queen-piece' ,
    'chess-queen-alt' ,
    'location-crosshairs' ,
    'location' ,
    'mars-double' ,
    'house-person-leave' ,
    'house-leave' ,
    'house-person-depart' ,
    'ruler-triangle' ,
    'card-club' ,
    'child-dress' ,
    'users-between-lines' ,
    'lungs-virus' ,
    'spinner-third' ,
    'face-grin-tears' ,
    'grin-tears' ,
    'phone' ,
    'computer-mouse-scrollwheel' ,
    'mouse-alt' ,
    'calendar-xmark' ,
    'calendar-times' ,
    'child-reaching' ,
    'table-layout' ,
    'narwhal' ,
    'ramp-loading' ,
    'calendar-circle-plus' ,
    'toothbrush' ,
    'border-inner' ,
    'paw-claws' ,
    'kiwi-fruit' ,
    'traffic-light-slow' ,
    'rectangle-code' ,
    'head-side-virus' ,
    'keyboard-brightness' ,
    'books-medical' ,
    'lightbulb-slash' ,
    'house-blank' ,
    'home-blank' ,
    'square-5' ,
    'square-heart' ,
    'heart-square' ,
    'puzzle' ,
    'user-gear' ,
    'user-cog' ,
    'pipe-circle-check' ,
    'arrow-up-1-9' ,
    'sort-numeric-up' ,
    'octagon-exclamation' ,
    'dial-low' ,
    'door-closed' ,
    'laptop-mobile' ,
    'phone-laptop' ,
    'conveyor-belt-boxes' ,
    'conveyor-belt-alt' ,
    'shield-virus' ,
    'starfighter-twin-ion-engine-advanced' ,
    'starfighter-alt-advanced' ,
    'dice-six' ,
    'starfighter-twin-ion-engine' ,
    'starfighter-alt' ,
    'rocket-launch' ,
    'mosquito-net' ,
    'vent-damper' ,
    'bridge-water' ,
    'ban-bug' ,
    'debug' ,
    'person-booth' ,
    'text-width' ,
    'garage-car' ,
    'square-kanban' ,
    'hat-wizard' ,
    'pen-fancy' ,
    'coffee-pot' ,
    'mouse-field' ,
    'person-digging' ,
    'digging' ,
    'shower-down' ,
    'shower-alt' ,
    'box-circle-check' ,
    'brightness' ,
    'car-side-bolt' ,
    'ornament' ,
    'phone-arrow-down-left' ,
    'phone-arrow-down' ,
    'phone-incoming' ,
    'cloud-word' ,
    'hand-fingers-crossed' ,
    'trash' ,
    'gauge-simple' ,
    'gauge-simple-med' ,
    'tachometer-average' ,
    'arrow-down-small-big' ,
    'sort-size-down-alt' ,
    'book-medical' ,
    'face-melting' ,
    'poo' ,
    'pen-clip-slash' ,
    'pen-alt-slash' ,
    'quote-right' ,
    'quote-right-alt' ,
    'scroll-old' ,
    'guitars' ,
    'phone-xmark' ,
    'hose' ,
    'clock-six' ,
    'shirt' ,
    't-shirt' ,
    'tshirt' ,
    'square-r' ,
    'cubes' ,
    'envelope-open-dollar' ,
    'divide' ,
    'sun-cloud' ,
    'lamp-floor' ,
    'square-7' ,
    'tenge-sign' ,
    'tenge' ,
    'headphones' ,
    'hands-holding' ,
    'campfire' ,
    'circle-ampersand' ,
    'snowflakes' ,
    'hands-clapping' ,
    'republican' ,
    'leaf-maple' ,
    'arrow-left' ,
    'person-circle-xmark' ,
    'ruler' ,
    'cup-straw-swoosh' ,
    'temperature-sun' ,
    'temperature-hot' ,
    'align-left' ,
    'dice-d6' ,
    'restroom' ,
    'high-definition' ,
    'rectangle-hd' ,
    'j' ,
    'galaxy' ,
    'users-viewfinder' ,
    'file-video' ,
    'cherries' ,
    'up-right-from-square' ,
    'external-link-alt' ,
    'circle-sort' ,
    'sort-circle' ,
    'table-cells' ,
    'th' ,
    'file-pdf' ,
    'siren' ,
    'arrow-up-to-dotted-line' ,
    'image-landscape' ,
    'landscape' ,
    'tank-water' ,
    'curling-stone' ,
    'curling' ,
    'gamepad-modern' ,
    'gamepad-alt' ,
    'messages-question' ,
    'book-bible' ,
    'bible' ,
    'o' ,
    'suitcase-medical' ,
    'medkit' ,
    'briefcase-arrow-right' ,
    'expand-wide' ,
    'clock-eleven-thirty' ,
    'rv' ,
    'user-secret' ,
    'otter' ,
    'dreidel' ,
    'person-dress' ,
    'female' ,
    'comment-dollar' ,
    'business-time' ,
    'briefcase-clock' ,
    'flower-tulip' ,
    'people-pants-simple' ,
    'cloud-drizzle' ,
    'table-cells-large' ,
    'th-large' ,
    'book-tanakh' ,
    'tanakh' ,
    'solar-system' ,
    'seal-question' ,
    'phone-volume' ,
    'volume-control-phone' ,
    'disc-drive' ,
    'hat-cowboy-side' ,
    'table-rows' ,
    'rows' ,
    'location-exclamation' ,
    'map-marker-exclamation' ,
    'face-fearful' ,
    'clipboard-user' ,
    'bus-school' ,
    'film-slash' ,
    'square-arrow-down-right' ,
    'book-sparkles' ,
    'book-spells' ,
    'washing-machine' ,
    'washer' ,
    'child' ,
    'lira-sign' ,
    'user-visor' ,
    'file-plus-minus' ,
    'chess-clock-flip' ,
    'chess-clock-alt' ,
    'satellite' ,
    'plane-lock' ,
    'steering-wheel' ,
    'tag' ,
    'stretcher' ,
    'book-section' ,
    'book-law' ,
    'inboxes' ,
    'coffee-bean' ,
    'brackets-curly' ,
    'ellipsis-stroke-vertical' ,
    'ellipsis-v-alt' ,
    'comment' ,
    'square-1' ,
    'cake-candles' ,
    'birthday-cake' ,
    'cake' ,
    'head-side' ,
    'envelope' ,
    'dolly-empty' ,
    'face-tissue' ,
    'angles-up' ,
    'angle-double-up' ,
    'paperclip' ,
    'chart-line-down' ,
    'arrow-right-to-city' ,
    'lock-a' ,
    'ribbon' ,
    'lungs' ,
    'person-pinball' ,
    'arrow-up-9-1' ,
    'sort-numeric-up-alt' ,
    'apple-core' ,
    'circle-y' ,
    'h6' ,
    'litecoin-sign' ,
    'circle-small' ,
    'border-none' ,
    'arrow-turn-down-left' ,
    'circle-nodes' ,
    'parachute-box' ,
    'message-medical' ,
    'comment-alt-medical' ,
    'rugby-ball' ,
    'comment-music' ,
    'indent' ,
    'tree-deciduous' ,
    'tree-alt' ,
    'puzzle-piece-simple' ,
    'puzzle-piece-alt' ,
    'truck-field-un' ,
    'nfc-trash' ,
    'hourglass' ,
    'hourglass-empty' ,
    'mountain' ,
    'file-xmark' ,
    'file-times' ,
    'house-heart' ,
    'home-heart' ,
    'house-chimney-blank' ,
    'meter-bolt' ,
    'user-doctor' ,
    'user-md' ,
    'slash-back' ,
    'circle-info' ,
    'info-circle' ,
    'fishing-rod' ,
    'hammer-crash' ,
    'message-heart' ,
    'cloud-meatball' ,
    'camera-polaroid' ,
    'camera' ,
    'camera-alt' ,
    'square-virus' ,
    'cart-arrow-up' ,
    'meteor' ,
    'car-on' ,
    'sleigh' ,
    'arrow-down-1-9' ,
    'sort-numeric-asc' ,
    'sort-numeric-down' ,
    'buoy-mooring' ,
    'square-4' ,
    'hand-holding-droplet' ,
    'hand-holding-water' ,
    'tricycle-adult' ,
    'waveform' ,
    'water' ,
    'star-sharp-half-stroke' ,
    'star-sharp-half-alt' ,
    'nfc-signal' ,
    'plane-prop' ,
    'calendar-check' ,
    'clock-desk' ,
    'calendar-clock' ,
    'calendar-time' ,
    'braille' ,
    'prescription-bottle-medical' ,
    'prescription-bottle-alt' ,
    'plate-utensils' ,
    'family-pants' ,
    'hose-reel' ,
    'house-window' ,
    'landmark' ,
    'truck' ,
    'crosshairs' ,
    'cloud-rainbow' ,
    'person-cane' ,
    'alien' ,
    'tent' ,
    'vest-patches' ,
    'people-dress-simple' ,
    'check-double' ,
    'arrow-down-a-z' ,
    'sort-alpha-asc' ,
    'sort-alpha-down' ,
    'bowling-ball-pin' ,
    'bell-school-slash' ,
    'plus-large' ,
    'money-bill-wheat' ,
    'camera-viewfinder' ,
    'screenshot' ,
    'message-music' ,
    'comment-alt-music' ,
    'car-building' ,
    'border-bottom-right' ,
    'border-style-alt' ,
    'octagon' ,
    'comment-arrow-up-right' ,
    'octagon-divide' ,
    'cookie' ,
    'arrow-rotate-left' ,
    'arrow-left-rotate' ,
    'arrow-rotate-back' ,
    'arrow-rotate-backward' ,
    'undo' ,
    'tv-music' ,
    'hard-drive' ,
    'hdd' ,
    'reel' ,
    'face-grin-squint-tears' ,
    'grin-squint-tears' ,
    'dumbbell' ,
    'rectangle-list' ,
    'list-alt' ,
    'tarp-droplet' ,
    'alarm-exclamation' ,
    'house-medical-circle-check' ,
    'traffic-cone' ,
    'grate' ,
    'arrow-down-right' ,
    'person-skiing-nordic' ,
    'skiing-nordic' ,
    'calendar-plus' ,
    'person-from-portal' ,
    'portal-exit' ,
    'plane-arrival' ,
    'cowbell-circle-plus' ,
    'cowbell-more' ,
    'circle-left' ,
    'arrow-alt-circle-left' ,
    'distribute-spacing-vertical' ,
    'signal-bars-fair' ,
    'signal-alt2' ,
    'sportsball' ,
    'game-console-handheld-crank' ,
    'train-subway' ,
    'subway' ,
    'chart-gantt' ,
    'face-smile-upside-down' ,
    'ball-pile' ,
    'badge-dollar' ,
    'money-bills-simple' ,
    'money-bills-alt' ,
    'list-timeline' ,
    'indian-rupee-sign' ,
    'indian-rupee' ,
    'inr' ,
    'crop-simple' ,
    'crop-alt' ,
    'money-bill-1' ,
    'money-bill-alt' ,
    'left-long' ,
    'long-arrow-alt-left' ,
    'keyboard-down' ,
    'circle-up-right' ,
    'cloud-bolt-moon' ,
    'thunderstorm-moon' ,
    'dna' ,
    'virus-slash' ,
    'bracket-round-right' ,
    'circle-5' ,
    'minus' ,
    'subtract' ,
    'fire-flame' ,
    'flame' ,
    'right-to-line' ,
    'arrow-alt-to-right' ,
    'gif' ,
    'chess' ,
    'trash-slash' ,
    'arrow-left-long' ,
    'long-arrow-left' ,
    'plug-circle-check' ,
    'font-case' ,
    'street-view' ,
    'arrow-down-left' ,
    'franc-sign' ,
    'flask-round-poison' ,
    'flask-poison' ,
    'volume-off' ,
    'book-circle-arrow-right' ,
    'chart-user' ,
    'user-chart' ,
    'hands-asl-interpreting' ,
    'american-sign-language-interpreting' ,
    'asl-interpreting' ,
    'hands-american-sign-language-interpreting' ,
    'presentation-screen' ,
    'presentation' ,
    'circle-bolt' ,
    'face-smile-halo' ,
    'cart-circle-arrow-down' ,
    'house-person-return' ,
    'house-person-arrive' ,
    'house-return' ,
    'message-xmark' ,
    'comment-alt-times' ,
    'message-times' ,
    'file-certificate' ,
    'file-award' ,
    'user-doctor-hair-long' ,
    'camera-security' ,
    'camera-home' ,
    'gear' ,
    'cog' ,
    'droplet-slash' ,
    'tint-slash' ,
    'book-heart' ,
    'mosque' ,
    'duck' ,
    'mosquito' ,
    'star-of-david' ,
    'flag-swallowtail' ,
    'flag-alt' ,
    'person-military-rifle' ,
    'car-garage' ,
    'cart-shopping' ,
    'shopping-cart' ,
    'book-font' ,
    'shield-plus' ,
    'vials' ,
    'eye-dropper-full' ,
    'distribute-spacing-horizontal' ,
    'tablet-rugged' ,
    'temperature-snow' ,
    'temperature-frigid' ,
    'moped' ,
    'face-smile-plus' ,
    'smile-plus' ,
    'radio-tuner' ,
    'radio-alt' ,
    'face-swear' ,
    'water-arrow-down' ,
    'water-lower' ,
    'scanner-touchscreen' ,
    'circle-7' ,
    'plug-circle-plus' ,
    'person-ski-jumping' ,
    'ski-jump' ,
    'place-of-worship' ,
    'water-arrow-up' ,
    'water-rise' ,
    'waveform-lines' ,
    'waveform-path' ,
    'split' ,
    'film-canister' ,
    'film-cannister' ,
    'folder-xmark' ,
    'folder-times' ,
    'toilet-paper-blank' ,
    'toilet-paper-alt' ,
    'tablet-screen' ,
    'tablet-android-alt' ,
    'hexagon-vertical-nft-slanted' ,
    'folder-music' ,
    'display-medical' ,
    'desktop-medical' ,
    'share-all' ,
    'peapod' ,
    'chess-clock' ,
    'axe' ,
    'square-d' ,
    'grip-vertical' ,
    'mobile-signal-out' ,
    'arrow-turn-up' ,
    'level-up' ,
    'u' ,
    'arrow-up-from-dotted-line' ,
    'square-root-variable' ,
    'square-root-alt' ,
    'light-switch-on' ,
    'arrow-down-arrow-up' ,
    'sort-alt' ,
    'raindrops' ,
    'dash' ,
    'minus-large' ,
    'clock' ,
    'clock-four' ,
    'input-numeric' ,
    'truck-tow' ,
    'backward-step' ,
    'step-backward' ,
    'pallet' ,
    'car-bolt' ,
    'arrows-maximize' ,
    'expand-arrows' ,
    'faucet' ,
    'cloud-sleet' ,
    'lamp-street' ,
    'list-radio' ,
    'pen-nib-slash' ,
    'baseball-bat-ball' ,
    'square-up-left' ,
    'overline' ,
    's' ,
    'timeline' ,
    'keyboard' ,
    'arrows-from-dotted-line' ,
    'usb-drive' ,
    'ballot' ,
    'caret-down' ,
    'location-dot-slash' ,
    'map-marker-alt-slash' ,
    'cards' ,
    'house-chimney-medical' ,
    'clinic-medical' ,
    'boxing-glove' ,
    'glove-boxing' ,
    'temperature-three-quarters' ,
    'temperature3' ,
    'thermometer3' ,
    'thermometer-three-quarters' ,
    'bell-school' ,
    'mobile-screen' ,
    'mobile-android-alt' ,
    'plane-up' ,
    'folder-heart' ,
    'circle-location-arrow' ,
    'location-circle' ,
    'face-head-bandage' ,
    'sushi-roll' ,
    'maki-roll' ,
    'makizushi' ,
    'car-bump' ,
    'piggy-bank' ,
    'racquet' ,
    'car-mirrors' ,
    'industry-windows' ,
    'industry-alt' ,
    'bolt-auto' ,
    'battery-half' ,
    'battery3' ,
    'flux-capacitor' ,
    'mountain-city' ,
    'coins' ,
    'honey-pot' ,
    'olive' ,
    'khanda' ,
    'filter-list' ,
    'outlet' ,
    'sliders' ,
    'sliders-h' ,
    'cauldron' ,
    'people' ,
    'folder-tree' ,
    'network-wired' ,
    'croissant' ,
    'map-pin' ,
    'hamsa' ,
    'cent-sign' ,
    'swords-laser' ,
    'flask' ,
    'person-pregnant' ,
    'square-u' ,
    'wand-sparkles' ,
    'router' ,
    'ellipsis-vertical' ,
    'ellipsis-v' ,
    'sword-laser-alt' ,
    'ticket' ,
    'power-off' ,
    'coin' ,
    'laptop-slash' ,
    'right-long' ,
    'long-arrow-alt-right' ,
    'circle-b' ,
    'person-dress-simple' ,
    'pipe-collar' ,
    'lights-holiday' ,
    'citrus' ,
    'flag-usa' ,
    'laptop-file' ,
    'tty' ,
    'teletype' ,
    'chart-tree-map' ,
    'diagram-next' ,
    'person-rifle' ,
    'clock-five-thirty' ,
    'pipe-valve' ,
    'arrow-up-from-arc' ,
    'face-spiral-eyes' ,
    'compress-wide' ,
    'circle-phone-hangup' ,
    'phone-circle-down' ,
    'house-medical-circle-exclamation' ,
    'badminton' ,
    'closed-captioning' ,
    'person-hiking' ,
    'hiking' ,
    'right-from-line' ,
    'arrow-alt-from-left' ,
    'venus-double' ,
    'images' ,
    'calculator' ,
    'shuttlecock' ,
    'user-hair' ,
    'eye-evil' ,
    'people-pulling' ,
    'n' ,
    'garage' ,
    'cable-car' ,
    'tram' ,
    'shovel-snow' ,
    'cloud-rain' ,
    'face-lying' ,
    'sprinkler' ,
    'building-circle-xmark' ,
    'person-sledding' ,
    'sledding' ,
    'game-console-handheld' ,
    'ship' ,
    'clock-six-thirty' ,
    'battery-slash' ,
    'tugrik-sign' ,
    'arrows-down-to-line' ,
    'download' ,
    'shelves' ,
    'inventory' ,
    'cloud-snow' ,
    'face-grin' ,
    'grin' ,
    'delete-left' ,
    'backspace' ,
    'oven' ,
    'eye-dropper' ,
    'eye-dropper-empty' ,
    'eyedropper' ,
    'comment-captions' ,
    'comments-question' ,
    'scribble' ,
    'rotate-exclamation' ,
    'file-circle-check' ,
    'glass' ,
    'loader' ,
    'forward' ,
    'user-pilot' ,
    'mobile' ,
    'mobile-android' ,
    'mobile-phone' ,
    'code-pull-request-closed' ,
    'face-meh' ,
    'meh' ,
    'align-center' ,
    'book-skull' ,
    'book-dead' ,
    'id-card' ,
    'drivers-license' ,
    'face-dotted' ,
    'face-worried' ,
    'outdent' ,
    'dedent' ,
    'heart-circle-exclamation' ,
    'house' ,
    'home' ,
    'home-alt' ,
    'home-lg-alt' ,
    'vector-circle' ,
    'car-circle-bolt' ,
    'calendar-week' ,
    'flying-disc' ,
    'laptop-medical' ,
    'square-down-right' ,
    'b' ,
    'seat-airline' ,
    'moon-over-sun' ,
    'eclipse-alt' ,
    'pipe' ,
    'file-medical' ,
    'potato' ,
    'dice-one' ,
    'circle-a' ,
    'helmet-battle' ,
    'butter' ,
    'blanket-fire' ,
    'kiwi-bird' ,
    'castle' ,
    'golf-club' ,
    'arrow-right-arrow-left' ,
    'exchange' ,
    'rotate-right' ,
    'redo-alt' ,
    'rotate-forward' ,
    'utensils' ,
    'cutlery' ,
    'arrow-up-wide-short' ,
    'sort-amount-up' ,
    'balloons' ,
    'mill-sign' ,
    'bowl-rice' ,
    'timeline-arrow' ,
    'skull' ,
    'game-board-simple' ,
    'game-board-alt' ,
    'circle-video' ,
    'video-circle' ,
    'chart-scatter-bubble' ,
    'house-turret' ,
    'banana' ,
    'hand-holding-skull' ,
    'people-dress' ,
    'loveseat' ,
    'couch-small' ,
    'tower-broadcast' ,
    'broadcast-tower' ,
    'truck-pickup' ,
    'block-quote' ,
    'up-long' ,
    'long-arrow-alt-up' ,
    'stop' ,
    'code-merge' ,
    'money-check-dollar-pen' ,
    'money-check-edit-alt' ,
    'up-from-line' ,
    'arrow-alt-from-bottom' ,
    'upload' ,
    'hurricane' ,
    'people-pants' ,
    'mound' ,
    'windsock' ,
    'circle-half' ,
    'brake-warning' ,
    'toilet-portable' ,
    'compact-disc' ,
    'file-arrow-down' ,
    'file-download' ,
    'saxophone-fire' ,
    'sax-hot' ,
    'camera-web-slash' ,
    'webcam-slash' ,
    'folder-medical' ,
    'folder-gear' ,
    'folder-cog' ,
    'hand-wave' ,
    'arrow-up-arrow-down' ,
    'sort-up-down' ,
    'caravan' ,
    'shield-cat' ,
    'message-slash' ,
    'comment-alt-slash' ,
    'bolt' ,
    'zap' ,
    'trash-can-check' ,
    'glass-water' ,
    'oil-well' ,
    'person-simple' ,
    'vault' ,
    'mars' ,
    'toilet' ,
    'plane-circle-xmark' ,
    'yen-sign' ,
    'cny' ,
    'jpy' ,
    'rmb' ,
    'yen' ,
    'notes' ,
    'ruble-sign' ,
    'rouble' ,
    'rub' ,
    'ruble' ,
    'trash-undo' ,
    'trash-arrow-turn-left' ,
    'champagne-glass' ,
    'glass-champagne' ,
    'objects-align-center-horizontal' ,
    'sun' ,
    'trash-can-slash' ,
    'trash-alt-slash' ,
    'screen-users' ,
    'users-class' ,
    'guitar' ,
    'square-arrow-left' ,
    'arrow-square-left' ,
    'square-8' ,
    'face-smile-hearts' ,
    'brackets-square' ,
    'brackets' ,
    'laptop-arrow-down' ,
    'hockey-stick-puck' ,
    'house-tree' ,
    'signal-fair' ,
    'signal2' ,
    'face-laugh-wink' ,
    'laugh-wink' ,
    'circle-dollar' ,
    'dollar-circle' ,
    'usd-circle' ,
    'horse-head' ,
    'arrows-repeat' ,
    'repeat-alt' ,
    'bore-hole' ,
    'industry' ,
    'image-polaroid' ,
    'wave-triangle' ,
    'circle-down' ,
    'arrow-alt-circle-down' ,
    'grill' ,
    'arrows-turn-to-dots' ,
    'chart-mixed' ,
    'analytics' ,
    'florin-sign' ,
    'arrow-down-short-wide' ,
    'sort-amount-desc' ,
    'sort-amount-down-alt' ,
    'less-than' ,
    'display-code' ,
    'desktop-code' ,
    'face-drooling' ,
    'oil-temperature' ,
    'oil-temp' ,
    'square-question' ,
    'question-square' ,
    'air-conditioner' ,
    'angle-down' ,
    'mountains' ,
    'omega' ,
    'car-tunnel' ,
    'person-dolly-empty' ,
    'pan-food' ,
    'head-side-cough' ,
    'grip-lines' ,
    'thumbs-down' ,
    'user-lock' ,
    'arrow-right-long' ,
    'long-arrow-right' ,
    'tickets-airline' ,
    'anchor-circle-xmark' ,
    'ellipsis' ,
    'ellipsis-h' ,
    'nfc-slash' ,
    'chess-pawn' ,
    'kit-medical' ,
    'first-aid' ,
    'grid-2-plus' ,
    'bells' ,
    'person-through-window' ,
    'toolbox' ,
    'envelope-dot' ,
    'envelope-badge' ,
    'hands-holding-circle' ,
    'bug' ,
    'bowl-chopsticks' ,
    'credit-card' ,
    'credit-card-alt' ,
    'circle-s' ,
    'box-ballot' ,
    'car' ,
    'automobile' ,
    'hand-holding-hand' ,
    'user-tie-hair' ,
    'podium-star' ,
    'user-hair-mullet' ,
    'business-front' ,
    'party-back' ,
    'trian-balbot' ,
    'microphone-stand' ,
    'book-open-reader' ,
    'book-reader' ,
    'family-dress' ,
    'circle-x' ,
    'cabin' ,
    'mountain-sun' ,
    'chart-simple-horizontal' ,
    'bluetooth' ,
    'arrows-left-right-to-line' ,
    'hand-back-point-left' ,
    'message-dots' ,
    'comment-alt-dots' ,
    'messaging' ,
    'file-heart' ,
    'beer-mug' ,
    'beer-foam' ,
    'dice-d20' ,
    'drone' ,
    'truck-droplet' ,
    'file-circle-xmark' ,
    'temperature-arrow-up' ,
    'temperature-up' ,
    'medal' ,
    'bed' ,
    'book-copy' ,
    'square-h' ,
    'h-square' ,
    'square-c' ,
    'clock-two' ,
    'square-ellipsis-vertical' ,
    'podcast' ,
    'bee' ,
    'temperature-full' ,
    'temperature4' ,
    'thermometer4' ,
    'thermometer-full' ,
    'bell' ,
    'candy-bar' ,
    'chocolate-bar' ,
    'xmark-large' ,
    'pinata' ,
    'arrows-from-line' ,
    'superscript' ,
    'bowl-spoon' ,
    'hexagon-check' ,
    'plug-circle-xmark' ,
    'star-of-life' ,
    'phone-slash' ,
    'traffic-light-stop' ,
    'paint-roller' ,
    'accent-grave' ,
    'handshake-angle' ,
    'hands-helping' ,
    'circle-0' ,
    'dial-med-low' ,
    'location-dot' ,
    'map-marker-alt' ,
    'crab' ,
    'box-open-full' ,
    'box-full' ,
    'file' ,
    'greater-than' ,
    'quotes' ,
    'pretzel' ,
    'person-swimming' ,
    'swimmer' ,
    'arrow-down' ,
    'user-robot-xmarks' ,
    'message-quote' ,
    'comment-alt-quote' ,
    'candy-corn' ,
    'folder-magnifying-glass' ,
    'folder-search' ,
    'notebook' ,
    'droplet' ,
    'tint' ,
    'bullseye-pointer' ,
    'eraser' ,
    'hexagon-image' ,
    'earth-americas' ,
    'earth' ,
    'earth-america' ,
    'globe-americas' ,
    'crate-apple' ,
    'apple-crate' ,
    'person-burst' ,
    'game-board' ,
    'hat-chef' ,
    'hand-back-point-right' ,
    'dove' ,
    'snowflake-droplets' ,
    'battery-empty' ,
    'battery0' ,
    'grid-4' ,
    'socks' ,
    'face-sunglasses' ,
    'inbox' ,
    'square-0' ,
    'section' ,
    'square-this-way-up' ,
    'box-up' ,
    'gauge-high' ,
    'tachometer-alt' ,
    'tachometer-alt-fast' ,
    'square-ampersand' ,
    'envelope-open-text' ,
    'lamp-desk' ,
    'hospital' ,
    'hospital-alt' ,
    'hospital-wide' ,
    'poll-people' ,
    'whiskey-glass-ice' ,
    'glass-whiskey-rocks' ,
    'wine-bottle' ,
    'chess-rook' ,
    'user-bounty-hunter' ,
    'bars-staggered' ,
    'reorder' ,
    'stream' ,
    'diagram-sankey' ,
    'cloud-hail-mixed' ,
    'circle-up-left' ,
    'dharmachakra' ,
    'objects-align-left' ,
    'oil-can-drip' ,
    'face-smiling-hands' ,
    'broccoli' ,
    'route-interstate' ,
    'ear-muffs' ,
    'hotdog' ,
    'transporter-empty' ,
    'person-walking-with-cane' ,
    'blind' ,
    'angle-90' ,
    'rectangle-terminal' ,
    'kite' ,
    'drum' ,
    'scrubber' ,
    'ice-cream' ,
    'heart-circle-bolt' ,
    'fish-bones' ,
    'deer-rudolph' ,
    'fax' ,
    'paragraph' ,
    'head-side-heart' ,
    'square-e' ,
    'meter-fire' ,
    'cloud-hail' ,
    'check-to-slot' ,
    'vote-yea' ,
    'money-from-bracket' ,
    'star-half' ,
    'car-bus' ,
    'speaker' ,
    'timer' ,
    'boxes-stacked' ,
    'boxes' ,
    'boxes-alt' ,
    'grill-hot' ,
    'ballot-check' ,
    'link' ,
    'chain' ,
    'ear-listen' ,
    'assistive-listening-systems' ,
    'file-minus' ,
    'tree-city' ,
    'play' ,
    'font' ,
    'cup-togo' ,
    'coffee-togo' ,
    'square-down-left' ,
    'burger-lettuce' ,
    'rupiah-sign' ,
    'magnifying-glass' ,
    'search' ,
    'table-tennis-paddle-ball' ,
    'ping-pong-paddle-ball' ,
    'table-tennis' ,
    'person-dots-from-line' ,
    'diagnoses' ,
    'chevrons-down' ,
    'chevron-double-down' ,
    'trash-can-arrow-up' ,
    'trash-restore-alt' ,
    'signal-good' ,
    'signal3' ,
    'location-question' ,
    'map-marker-question' ,
    'floppy-disk-circle-xmark' ,
    'floppy-disk-times' ,
    'save-circle-xmark' ,
    'save-times' ,
    'naira-sign' ,
    'peach' ,
    'taxi-bus' ,
    'bracket-curly' ,
    'bracket-curly-left' ,
    'lobster' ,
    'cart-flatbed-empty' ,
    'dolly-flatbed-empty' ,
    'colon' ,
    'cart-arrow-down' ,
    'wand' ,
    'walkie-talkie' ,
    'file-pen' ,
    'file-edit' ,
    'receipt' ,
    'table-picnic' ,
    'square-pen' ,
    'pen-square' ,
    'pencil-square' ,
    'circle-microphone-lines' ,
    'microphone-circle-alt' ,
    'display-slash' ,
    'desktop-slash' ,
    'suitcase-rolling' ,
    'person-circle-exclamation' ,
    'transporter-2' ,
    'hands-holding-diamond' ,
    'hand-receiving' ,
    'money-bill-simple-wave' ,
    'chevron-down' ,
    'battery-full' ,
    'battery' ,
    'battery5' ,
    'bell-plus' ,
    'book-arrow-right' ,
    'hospitals' ,
    'club' ,
    'skull-crossbones' ,
    'droplet-degree' ,
    'dewpoint' ,
    'code-compare' ,
    'list-ul' ,
    'list-dots' ,
    'hand-holding-magic' ,
    'watermelon-slice' ,
    'circle-ellipsis' ,
    'school-lock' ,
    'tower-cell' ,
    'sd-cards' ,
    'down-long' ,
    'long-arrow-alt-down' ,
    'envelopes' ,
    'phone-office' ,
    'ranking-star' ,
    'chess-king' ,
    'nfc-pen' ,
    'person-harassing' ,
    'hat-winter' ,
    'brazilian-real-sign' ,
    'landmark-dome' ,
    'landmark-alt' ,
    'bone-break' ,
    'arrow-up' ,
    'down-from-dotted-line' ,
    'tv' ,
    'television' ,
    'tv-alt' ,
    'border-left' ,
    'circle-divide' ,
    'shrimp' ,
    'list-check' ,
    'tasks' ,
    'diagram-subtask' ,
    'jug-detergent' ,
    'circle-user' ,
    'user-circle' ,
    'square-y' ,
    'user-doctor-hair' ,
    'planet-ringed' ,
    'mushroom' ,
    'user-shield' ,
    'megaphone' ,
    'circle-exclamation-check' ,
    'wind' ,
    'box-dollar' ,
    'box-usd' ,
    'car-burst' ,
    'car-crash' ,
    'y' ,
    'user-headset' ,
    'arrows-retweet' ,
    'retweet-alt' ,
    'person-snowboarding' ,
    'snowboarding' ,
    'square-chevron-right' ,
    'chevron-square-right' ,
    'lacrosse-stick-ball' ,
    'truck-fast' ,
    'shipping-fast' ,
    'user-magnifying-glass' ,
    'star-sharp' ,
    'comment-heart' ,
    'circle-1' ,
    'circle-star' ,
    'star-circle' ,
    'fish' ,
    'cloud-fog' ,
    'fog' ,
    'waffle' ,
    'music-note' ,
    'music-alt' ,
    'hexagon-exclamation' ,
    'cart-shopping-fast' ,
    'object-union' ,
    'user-graduate' ,
    'starfighter' ,
    'circle-half-stroke' ,
    'adjust' ,
    'arrow-right-long-to-line' ,
    'square-arrow-down' ,
    'arrow-square-down' ,
    'diamond-half-stroke' ,
    'clapperboard' ,
    'square-chevron-left' ,
    'chevron-square-left' ,
    'phone-intercom' ,
    'link-horizontal' ,
    'chain-horizontal' ,
    'mango' ,
    'music-note-slash' ,
    'music-alt-slash' ,
    'circle-radiation' ,
    'radiation-alt' ,
    'face-tongue-sweat' ,
    'globe-stand' ,
    'baseball' ,
    'baseball-ball' ,
    'circle-p' ,
    'award-simple' ,
    'jet-fighter-up' ,
    'diagram-project' ,
    'project-diagram' ,
    'pedestal' ,
    'chart-pyramid' ,
    'sidebar' ,
    'snowman-head' ,
    'frosty-head' ,
    'copy' ,
    'burger-glass' ,
    'volume-xmark' ,
    'volume-mute' ,
    'volume-times' ,
    'hand-sparkles' ,
    'bars-filter' ,
    'paintbrush-pencil' ,
    'party-bell' ,
    'user-vneck-hair' ,
    'jack-o-lantern' ,
    'grip' ,
    'grip-horizontal' ,
    'share-from-square' ,
    'share-square' ,
    'keynote' ,
    'child-combatant' ,
    'child-rifle' ,
    'gun' ,
    'square-phone' ,
    'phone-square' ,
    'plus' ,
    'add' ,
    'expand' ,
    'computer' ,
    'fort' ,
    'cloud-check' ,
    'xmark' ,
    'close' ,
    'multiply' ,
    'remove' ,
    'times' ,
    'face-smirking' ,
    'arrows-up-down-left-right' ,
    'arrows' ,
    'chalkboard-user' ,
    'chalkboard-teacher' ,
    'rhombus' ,
    'claw-marks' ,
    'peso-sign' ,
    'face-smile-tongue' ,
    'cart-circle-xmark' ,
    'building-shield' ,
    'circle-phone-flip' ,
    'phone-circle-alt' ,
    'baby' ,
    'users-line' ,
    'quote-left' ,
    'quote-left-alt' ,
    'tractor' ,
    'key-skeleton' ,
    'trash-arrow-up' ,
    'trash-restore' ,
    'arrow-down-up-lock' ,
    'arrow-down-to-bracket' ,
    'lines-leaning' ,
    'square-q' ,
    'ruler-combined' ,
    'symbols' ,
    'icons-alt' ,
    'copyright' ,
    'highlighter-line' ,
    'bracket-square' ,
    'bracket' ,
    'bracket-left' ,
    'island-tropical' ,
    'island-tree-palm' ,
    'arrow-right-from-line' ,
    'arrow-from-left' ,
    'h2' ,
    'equals' ,
    'cake-slice' ,
    'shortcake' ,
    'peanut' ,
    'wrench-simple' ,
    'blender' ,
    'teeth' ,
    'tally-2' ,
    'shekel-sign' ,
    'ils' ,
    'shekel' ,
    'sheqel' ,
    'sheqel-sign' ,
    'cars' ,
    'axe-battle' ,
    'user-hair-long' ,
    'map' ,
    'file-circle-info' ,
    'face-disappointed' ,
    'lasso-sparkles' ,
    'clock-eleven' ,
    'rocket' ,
    'siren-on' ,
    'clock-ten' ,
    'candle-holder' ,
    'video-arrow-down-left' ,
    'photo-film' ,
    'photo-video' ,
    'floppy-disk-circle-arrow-right' ,
    'save-circle-arrow-right' ,
    'folder-minus' ,
    'planet-moon' ,
    'face-eyes-xmarks' ,
    'chart-scatter' ,
    'display-arrow-down' ,
    'store' ,
    'arrow-trend-up' ,
    'plug-circle-minus' ,
    'olive-branch' ,
    'angle' ,
    'vacuum-robot' ,
    'sign-hanging' ,
    'sign' ,
    'square-divide' ,
    'signal-stream-slash' ,
    'bezier-curve' ,
    'eye-dropper-half' ,
    'store-lock' ,
    'bell-slash' ,
    'cloud-bolt-sun' ,
    'thunderstorm-sun' ,
    'camera-slash' ,
    'comment-quote' ,
    'tablet' ,
    'tablet-android' ,
    'school-flag' ,
    'message-code' ,
    'glass-half' ,
    'glass-half-empty' ,
    'glass-half-full' ,
    'fill' ,
    'message-minus' ,
    'comment-alt-minus' ,
    'angle-up' ,
    'drumstick-bite' ,
    'link-horizontal-slash' ,
    'chain-horizontal-slash' ,
    'holly-berry' ,
    'nose' ,
    'chevron-left' ,
    'bacteria' ,
    'clouds' ,
    'money-bill-simple' ,
    'hand-lizard' ,
    'table-pivot' ,
    'filter-slash' ,
    'trash-can-undo' ,
    'trash-can-arrow-turn-left' ,
    'trash-undo-alt' ,
    'notdef' ,
    'disease' ,
    'person-to-door' ,
    'turntable' ,
    'briefcase-medical' ,
    'genderless' ,
    'chevron-right' ,
    'signal-weak' ,
    'signal1' ,
    'clock-five' ,
    'retweet' ,
    'car-rear' ,
    'car-alt' ,
    'pump-soap' ,
    'computer-classic' ,
    'frame' ,
    'video-slash' ,
    'battery-quarter' ,
    'battery2' ,
    'ellipsis-stroke' ,
    'ellipsis-h-alt' ,
    'radio' ,
    'baby-carriage' ,
    'carriage-baby' ,
    'face-expressionless' ,
    'down-to-dotted-line' ,
    'cloud-music' ,
    'traffic-light' ,
    'cloud-minus' ,
    'thermometer' ,
    'shield-minus' ,
    'vr-cardboard' ,
    'car-tilt' ,
    'gauge-circle-minus' ,
    'brightness-low' ,
    'hand-middle-finger' ,
    'percent' ,
    'percentage' ,
    'truck-moving' ,
    'glass-water-droplet' ,
    'conveyor-belt' ,
    'location-check' ,
    'map-marker-check' ,
    'coin-vertical' ,
    'display' ,
    'person-sign' ,
    'face-smile' ,
    'smile' ,
    'phone-hangup' ,
    'signature-slash' ,
    'thumbtack' ,
    'thumb-tack' ,
    'wheat-slash' ,
    'trophy' ,
    'clouds-sun' ,
    'person-praying' ,
    'pray' ,
    'hammer' ,
    'face-vomit' ,
    'speakers' ,
    'tty-answer' ,
    'teletype-answer' ,
    'mug-tea-saucer' ,
    'diagram-lean-canvas' ,
    'alt' ,
    'dial' ,
    'dial-med-high' ,
    'hand-peace' ,
    'circle-trash' ,
    'trash-circle' ,
    'rotate' ,
    'sync-alt' ,
    'circle-quarters' ,
    'spinner' ,
    'tower-control' ,
    'arrow-up-triangle-square' ,
    'sort-shapes-up' ,
    'whale' ,
    'robot' ,
    'peace' ,
    'party-horn' ,
    'gears' ,
    'cogs' ,
    'sun-bright' ,
    'sun-alt' ,
    'warehouse' ,
    'lock-keyhole-open' ,
    'lock-open-alt' ,
    'square-fragile' ,
    'box-fragile' ,
    'square-wine-glass-crack' ,
    'arrow-up-right-dots' ,
    'square-n' ,
    'splotch' ,
    'face-grin-hearts' ,
    'grin-hearts' ,
    'meter' ,
    'mandolin' ,
    'dice-four' ,
    'sim-card' ,
    'transgender' ,
    'transgender-alt' ,
    'mercury' ,
    'up-from-bracket' ,
    'knife-kitchen' ,
    'border-right' ,
    'arrow-turn-down' ,
    'level-down' ,
    'spade' ,
    'card-spade' ,
    'line-columns' ,
    'arrow-right-to-line' ,
    'arrow-to-right' ,
    'person-falling-burst' ,
    'flag-pennant' ,
    'pennant' ,
    'conveyor-belt-empty' ,
    'award' ,
    'ticket-simple' ,
    'ticket-alt' ,
    'building' ,
    'angles-left' ,
    'angle-double-left' ,
    'camcorder' ,
    'video-handheld' ,
    'pancakes' ,
    'album-circle-user' ,
    'qrcode' ,
    'dice-d10' ,
    'fireplace' ,
    'browser' ,
    'pen-paintbrush' ,
    'pencil-paintbrush' ,
    'fish-cooked' ,
    'chair-office' ,
    'nesting-dolls' ,
    'clock-rotate-left' ,
    'history' ,
    'trumpet' ,
    'face-grin-beam-sweat' ,
    'grin-beam-sweat' ,
    'fire-smoke' ,
    'phone-missed' ,
    'file-export' ,
    'arrow-right-from-file' ,
    'shield' ,
    'shield-blank' ,
    'arrow-up-short-wide' ,
    'sort-amount-up-alt' ,
    'arrows-repeat-1' ,
    'repeat1-alt' ,
    'gun-slash' ,
    'avocado' ,
    'binary' ,
    'glasses-round' ,
    'glasses-alt' ,
    'phone-plus' ,
    'ditto' ,
    'person-seat' ,
    'house-medical' ,
    'golf-ball-tee' ,
    'golf-ball' ,
    'circle-chevron-left' ,
    'chevron-circle-left' ,
    'house-chimney-window' ,
    'scythe' ,
    'pen-nib' ,
    'ban-parking' ,
    'parking-circle-slash' ,
    'tent-arrow-turn-left' ,
    'face-diagonal-mouth' ,
    'diagram-cells' ,
    'cricket-bat-ball' ,
    'cricket' ,
    'tents' ,
    'wand-magic' ,
    'magic' ,
    'dog' ,
    'pen-line' ,
    'atom-simple' ,
    'atom-alt' ,
    'ampersand' ,
    'carrot' ,
    'arrow-up-from-line' ,
    'arrow-from-bottom' ,
    'moon' ,
    'pen-slash' ,
    'wine-glass-empty' ,
    'wine-glass-alt' ,
    'square-star' ,
    'cheese' ,
    'send-backward' ,
    'yin-yang' ,
    'music' ,
    'compass-slash' ,
    'clock-one' ,
    'file-music' ,
    'code-commit' ,
    'temperature-low' ,
    'person-biking' ,
    'biking' ,
    'skeleton' ,
    'circle-g' ,
    'circle-arrow-up-left' ,
    'coin-blank' ,
    'broom' ,
    'vacuum' ,
    'shield-heart' ,
    'card-heart' ,
    'lightbulb-cfl-on' ,
    'melon' ,
    'gopuram' ,
    'earth-oceania' ,
    'globe-oceania' ,
    'container-storage' ,
    'face-pouting' ,
    'square-xmark' ,
    'times-square' ,
    'xmark-square' ,
    'face-explode' ,
    'exploding-head' ,
    'hashtag' ,
    'up-right-and-down-left-from-center' ,
    'expand-alt' ,
    'oil-can' ,
    't' ,
    'transformer-bolt' ,
    'hippo' ,
    'chart-column' ,
    'cassette-vhs' ,
    'vhs' ,
    'infinity' ,
    'vial-circle-check' ,
    'chimney' ,
    'object-intersect' ,
    'person-arrow-down-to-line' ,
    'voicemail' ,
    'block-brick' ,
    'wall-brick' ,
    'fan' ,
    'bags-shopping' ,
    'paragraph-left' ,
    'paragraph-rtl' ,
    'person-walking-luggage' ,
    'caravan-simple' ,
    'caravan-alt' ,
    'turtle' ,
    'up-down' ,
    'arrows-alt-v' ,
    'cloud-moon-rain' ,
    'booth-curtain' ,
    'calendar' ,
    'box-heart' ,
    'trailer' ,
    'user-doctor-message' ,
    'user-md-chat' ,
    'bahai' ,
    'haykal' ,
    'amp-guitar' ,
    'sd-card' ,
    'volume-slash' ,
    'border-bottom' ,
    'wifi-weak' ,
    'wifi1' ,
    'dragon' ,
    'shoe-prints' ,
    'circle-plus' ,
    'plus-circle' ,
    'face-grin-tongue-wink' ,
    'grin-tongue-wink' ,
    'hand-holding' ,
    'plug-circle-exclamation' ,
    'link-slash' ,
    'chain-broken' ,
    'chain-slash' ,
    'unlink' ,
    'clone' ,
    'person-walking-arrow-loop-left' ,
    'arrow-up-z-a' ,
    'sort-alpha-up-alt' ,
    'fire-flame-curved' ,
    'fire-alt' ,
    'tornado' ,
    'file-circle-plus' ,
    'delete-right' ,
    'book-quran' ,
    'quran' ,
    'circle-quarter' ,
    'anchor' ,
    'border-all' ,
    'function' ,
    'face-angry' ,
    'angry' ,
    'people-simple' ,
    'cookie-bite' ,
    'arrow-trend-down' ,
    'rss' ,
    'feed' ,
    'face-monocle' ,
    'draw-polygon' ,
    'scale-balanced' ,
    'balance-scale' ,
    'calendar-lines' ,
    'calendar-note' ,
    'arrow-down-big-small' ,
    'sort-size-down' ,
    'gauge-simple-high' ,
    'tachometer' ,
    'tachometer-fast' ,
    'do-not-enter' ,
    'shower' ,
    'dice-d8' ,
    'desktop' ,
    'desktop-alt' ,
    'm' ,
    'grip-dots-vertical' ,
    'face-viewfinder' ,
    'soft-serve' ,
    'creemee' ,
    'h5' ,
    'hand-back-point-down' ,
    'table-list' ,
    'th-list' ,
    'comment-sms' ,
    'sms' ,
    'rectangle' ,
    'rectangle-landscape' ,
    'clipboard-list-check' ,
    'turkey' ,
    'book' ,
    'user-plus' ,
    'ice-skate' ,
    'check' ,
    'battery-three-quarters' ,
    'battery4' ,
    'tomato' ,
    'sword-laser' ,
    'house-circle-check' ,
    'buildings' ,
    'angle-left' ,
    'cart-flatbed-boxes' ,
    'dolly-flatbed-alt' ,
    'diagram-successor' ,
    'truck-arrow-right' ,
    'square-w' ,
    'arrows-split-up-and-left' ,
    'lamp' ,
    'airplay' ,
    'hand-fist' ,
    'fist-raised' ,
    'shield-quartered' ,
    'slash-forward' ,
    'location-pen' ,
    'map-marker-edit' ,
    'cloud-moon' ,
    'pot-food' ,
    'briefcase' ,
    'person-falling' ,
    'image-portrait' ,
    'portrait' ,
    'user-tag' ,
    'rug' ,
    'print-slash' ,
    'earth-europe' ,
    'globe-europe' ,
    'cart-flatbed-suitcase' ,
    'luggage-cart' ,
    'hand-back-point-ribbon' ,
    'rectangle-xmark' ,
    'rectangle-times' ,
    'times-rectangle' ,
    'window-close' ,
    'tire-rugged' ,
    'lightbulb-dollar' ,
    'cowbell' ,
    'baht-sign' ,
    'corner' ,
    'chevrons-right' ,
    'chevron-double-right' ,
    'book-open' ,
    'book-journal-whills' ,
    'journal-whills' ,
    'inhaler' ,
    'handcuffs' ,
    'snake' ,
    'triangle-exclamation' ,
    'exclamation-triangle' ,
    'warning' ,
    'note-medical' ,
    'database' ,
    'down-left' ,
    'share' ,
    'arrow-turn-right' ,
    'mail-forward' ,
    'face-thinking' ,
    'turn-down-right' ,
    'bottle-droplet' ,
    'mask-face' ,
    'hill-rockslide' ,
    'scanner-keyboard' ,
    'circle-o' ,
    'grid-horizontal' ,
    'message-dollar' ,
    'comment-alt-dollar' ,
    'right-left' ,
    'exchange-alt' ,
    'columns-3' ,
    'paper-plane' ,
    'road-circle-exclamation' ,
    'dungeon' ,
    'hand-holding-box' ,
    'input-text' ,
    'window-flip' ,
    'window-alt' ,
    'align-right' ,
    'scanner-gun' ,
    'scanner' ,
    'tire' ,
    'engine' ,
    'money-bill-1-wave' ,
    'money-bill-wave-alt' ,
    'life-ring' ,
    'hands' ,
    'sign-language' ,
    'signing' ,
    'circle-caret-right' ,
    'caret-circle-right' ,
    'wheat' ,
    'file-spreadsheet' ,
    'audio-description-slash' ,
    'calendar-day' ,
    'water-ladder' ,
    'ladder-water' ,
    'swimming-pool' ,
    'arrows-up-down' ,
    'arrows-v' ,
    'chess-pawn-piece' ,
    'chess-pawn-alt' ,
    'face-grimace' ,
    'grimace' ,
    'wheelchair-move' ,
    'wheelchair-alt' ,
    'turn-down' ,
    'level-down-alt' ,
    'square-s' ,
    'rectangle-barcode' ,
    'barcode-alt' ,
    'person-walking-arrow-right' ,
    'square-envelope' ,
    'envelope-square' ,
    'dice' ,
    'unicorn' ,
    'bowling-ball' ,
    'pompebled' ,
    'brain' ,
    'watch-smart' ,
    'book-user' ,
    'sensor-cloud' ,
    'sensor-smoke' ,
    'clapperboard-play' ,
    'bandage' ,
    'band-aid' ,
    'calendar-minus' ,
    'circle-xmark' ,
    'times-circle' ,
    'xmark-circle' ,
    'circle-4' ,
    'gifts' ,
    'album-collection' ,
    'hotel' ,
    'earth-asia' ,
    'globe-asia' ,
    'id-card-clip' ,
    'id-card-alt' ,
    'magnifying-glass-plus' ,
    'search-plus' ,
    'thumbs-up' ,
    'cloud-showers' ,
    'user-clock' ,
    'onion' ,
    'clock-twelve-thirty' ,
    'arrow-down-to-dotted-line' ,
    'hand-dots' ,
    'allergies' ,
    'file-invoice' ,
    'window-minimize' ,
    'rectangle-wide' ,
    'comment-arrow-up' ,
    'garlic' ,
    'mug-saucer' ,
    'coffee' ,
    'brush' ,
    'tree-decorated' ,
    'mask' ,
    'calendar-heart' ,
    'magnifying-glass-minus' ,
    'search-minus' ,
    'flower' ,
    'ruler-vertical' ,
    'user-large' ,
    'user-alt' ,
    'starship-freighter' ,
    'train-tram' ,
    'bridge-suspension' ,
    'trash-check' ,
    'user-nurse' ,
    'boombox' ,
    'syringe' ,
    'cloud-sun' ,
    'shield-exclamation' ,
    'stopwatch-20' ,
    'square-full' ,
    'grip-dots' ,
    'comment-exclamation' ,
    'pen-swirl' ,
    'falafel' ,
    'circle-2' ,
    'magnet' ,
    'jar' ,
    'gramophone' ,
    'dice-d12' ,
    'note-sticky' ,
    'sticky-note' ,
    'down' ,
    'arrow-alt-down' ,
    'hundred-points' ,
    '100' ,
    'paperclip-vertical' ,
    'wind-warning' ,
    'wind-circle-exclamation' ,
    'location-pin-slash' ,
    'map-marker-slash' ,
    'face-sad-sweat' ,
    'bug-slash' ,
    'cupcake' ,
    'light-switch-off' ,
    'toggle-large-off' ,
    'pen-fancy-slash' ,
    'truck-container' ,
    'boot' ,
    'arrow-up-from-water-pump' ,
    'file-check' ,
    'bone' ,
    'cards-blank' ,
    'circle-3' ,
    'bench-tree' ,
    'keyboard-brightness-low' ,
    'ski-boot-ski' ,
    'brain-circuit' ,
    'user-injured' ,
    'block-brick-fire' ,
    'firewall' ,
    'face-sad-tear' ,
    'sad-tear' ,
    'plane' ,
    'tent-arrows-down' ,
    'exclamation' ,
    'arrows-spin' ,
    'face-smile-relaxed' ,
    'comment-xmark' ,
    'comment-times' ,
    'print' ,
    'turkish-lira-sign' ,
    'try' ,
    'turkish-lira' ,
    'face-nose-steam' ,
    'circle-waveform-lines' ,
    'waveform-circle' ,
    'dollar-sign' ,
    'dollar' ,
    'usd' ,
    'ferris-wheel' ,
    'computer-speaker' ,
    'skull-cow' ,
    'x' ,
    'magnifying-glass-dollar' ,
    'search-dollar' ,
    'users-gear' ,
    'users-cog' ,
    'person-military-pointing' ,
    'building-columns' ,
    'bank' ,
    'institution' ,
    'museum' ,
    'university' ,
    'circle-t' ,
    'sack' ,
    'grid-2' ,
    'camera-cctv' ,
    'cctv' ,
    'umbrella' ,
    'trowel' ,
    'horizontal-rule' ,
    'bed-front' ,
    'bed-alt' ,
    'd' ,
    'stapler' ,
    'masks-theater' ,
    'theater-masks' ,
    'kip-sign' ,
    'face-woozy' ,
    'cloud-question' ,
    'pineapple' ,
    'hand-point-left' ,
    'gallery-thumbnails' ,
    'circle-j' ,
    'eyes' ,
    'handshake-simple' ,
    'handshake-alt' ,
    'page-caret-up' ,
    'file-caret-up' ,
    'jet-fighter' ,
    'fighter-jet' ,
    'comet' ,
    'square-share-nodes' ,
    'share-alt-square' ,
    'shield-keyhole' ,
    'barcode' ,
    'plus-minus' ,
    'square-sliders-vertical' ,
    'sliders-v-square' ,
    'video' ,
    'video-camera' ,
    'message-middle' ,
    'comment-middle-alt' ,
    'graduation-cap' ,
    'mortar-board' ,
    'hand-holding-medical' ,
    'person-circle-check' ,
    'square-z' ,
    'message-text' ,
    'comment-alt-text' ,
    'turn-up' ,
    'level-up-alt'];
  selectedIconName: string = "";
  @Output() iconSelectedOutput = new EventEmitter<string>();

  constructor(library: FaIconLibrary) {
    for (let i=0; i<this.allIconNames.length; i++) {
      let ic: IconName = this.allIconNames[i] as IconName;
      if (library.getIconDefinition("fas", ic)) {
        if (!this.availableIconNames.includes(this.allIconNames[i]))
          this.availableIconNames.push(this.allIconNames[i]);
      }
    }
    this.availableIconNames.sort();
  }

  ngOnInit(): void {
    if(this.iconOrigin){
      this.selectedIconName = this.iconOrigin;
    }
  }

  updateIconSelected(iconSelected: string){
    this.iconSelectedOutput .emit(iconSelected);
  }
}
