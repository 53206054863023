
<div class="card">
  <div class="card-header">
    <h1><lib-translate [key]="'role-visibility-manager'"></lib-translate></h1>
  </div>
  <div class="card-body">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>  </th>
          <th *ngFor=" let role of RolesArray">{{role.RoleName}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let page of PagesArray">
          <th>{{page.CategoryLabel}} / {{page.PageTitle}}</th>
          <td *ngFor=" let role of RolesArray">
            <input class="form-check-input linesInputs" type="checkbox" [checked]="isChecked(role.RoleId,page.PageId)" (change)="onCheck($event, role.RoleId, page.PageId)" value="isChecked(role.RoleId,page.PageId)">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <button class="btn btn-primary" (click)="onAdd()">
      <lib-translate [key]="'app-role-add-btn'">
      </lib-translate>
    </button>
  </div>
</div>
