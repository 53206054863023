import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common';
import { Observable } from 'rxjs';
import { GetBuildingsResponse, GetPortfoliosResponse, Urls } from './lib-specific-param-report-config.interface';

@Injectable({
  providedIn: 'root'
})
export class LibSpecificParamReportConfigService {

  constructor(private httpClientApiService: LibHttpClientApiService) { }

  public GetBuildings(): Observable<GetBuildingsResponse[]> {
    return this.httpClientApiService.get<GetBuildingsResponse[]>(Urls.GET_BUILDINGS);
  }

  public GetSpecificBuilsings(portfolioId: string): Observable<GetBuildingsResponse[]> {
    return this.httpClientApiService.get<GetBuildingsResponse[]>(Urls.GET_SPECIFIC_PORTFOLIO_BUILDINGS + `/${portfolioId}`);
  }

  public GetPortfolios(): Observable<GetPortfoliosResponse[]> {
    return this.httpClientApiService.get<GetPortfoliosResponse[]>(Urls.GET_PORTFOLIOS);
  }
}
