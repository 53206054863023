<div class="card card-primary container-toggle">
  <div class="card-header">
    <h3 class="card-title" *ngIf="id; else addTitle">
      <fa-icon icon="check-circle"></fa-icon>&nbsp;<lib-translate [key]="'app-user-edit-title'"></lib-translate>
    </h3>
    <ng-template #addTitle>
      <h3 class="card-title">
        <fa-icon icon="check-circle"></fa-icon>&nbsp;<lib-translate [key]="'app-user-add-title'"></lib-translate>
      </h3>
    </ng-template>

    <div class="align-items-baseline card-tools d-flex">
      <button type="button" class="btn btn-tool" data-card-widget="maximize" data-toggle="tooltip" title="Plein écran">
        <fa-icon icon="expand"></fa-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <form #userForm="ngForm" (ngSubmit)="userForm.form.valid && onSubmit()" novalidate>

      <div class="row ml-2 mr-2">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 mt-3 pr-4 pl-4">
              <label for="userLastName" class="form-label"><lib-translate [key]="'app-user-name-label'"></lib-translate></label>
              <input type="text" [(ngModel)]="user.LastName" class="form-control" id="userLastName"
                     [ngClass]="{'is-invalid': (userLastName.touched || userForm.submitted) && userLastName.errors?.['required']}"
                     name="userLastName" #userLastName="ngModel" required />
              <span class="text-danger" *ngIf="(userLastName.touched || userForm.submitted) && userLastName.errors?.['required']">
                <lib-translate [key]="'app-user-name-required'"></lib-translate>
              </span>
            </div>

            <div class="col-lg-4 mt-3 pr-4 pl-4">
              <label for="userFirstName" class="form-label"><lib-translate [key]="'app-user-firstname-label'"></lib-translate></label>
              <input type="text" [(ngModel)]="user.FirstName" class="form-control" id="userFirstName"
                     [ngClass]="{'is-invalid': (userFirstName.touched || userForm.submitted) && userFirstName.errors?.['required']}"
                     name="userFirstName" #userFirstName="ngModel" required />
              <span class="text-danger" *ngIf="(userFirstName.touched || userForm.submitted) && userFirstName.errors?.['required']">
                <lib-translate [key]="'app-user-firstname-required'"></lib-translate>
              </span>
            </div>

            <div class="col-lg-2 mt-3 pr-4 pl-4">
              <label for="userLanguage" class="form-label"><lib-translate [key]="'app-user-language-label'"></lib-translate></label>
              <ng-select class="userLang" id="userLanguage" [clearable]="false" [searchable]="false" [(ngModel)]="user.LanguageCode"
                         [items]="['en-GB', 'fr-FR', 'de-DE']" name="userLanguage">
                <ng-template ng-label-tmp let-item="item">
                  <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
                </ng-template>
              </ng-select>
            </div>

            <div class="col-lg-6 mt-3 pr-4 pl-4">
              <label for="userPhone" class="form-label"><lib-translate [key]="'app-user-phone-label'"></lib-translate></label>
              <input type="text" [(ngModel)]="user.Phone" class="form-control" id="userPhone" name="userPhone" />
            </div>

            <div class="col-lg-6 mt-3 pr-4 pl-4">
              <label for="userEmail" class="form-label"><lib-translate [key]="'app-user-email-label'"></lib-translate></label>
              <input type="email" [(ngModel)]="user.Email" class="form-control" id="userEmail"
                     [ngClass]="{'is-invalid': (userEmail.touched || userForm.submitted) && userEmail.errors}"
                     name="userEmail" #userEmail="ngModel" required email />
              <span class="text-danger" *ngIf="(userEmail.touched || userForm.submitted) && userEmail.errors?.['required']">
                <lib-translate [key]="'app-user-email-required'"></lib-translate>
              </span>
              <span class="text-danger" *ngIf="(userEmail.touched || userForm.submitted) && userEmail.errors?.['email']">
                <lib-translate [key]="'app-user-email-format'"></lib-translate>
              </span>
            </div>

            <div class="col-lg-6 mt-3 pr-4 pl-4">
              <label for="userRole" class="form-label"><lib-translate [key]="'app-user-role-label'"></lib-translate></label>
              <ng-select class="mb-2" [clearable]="false" [searchable]="false" [(ngModel)]="user.Roles" [multiple]="true" bindLabel="Name" [closeOnSelect]="true"
                         id="userRole" [items]="rolesNGSelect" [ngModelOptions]="{standalone: true}">
              </ng-select>
            </div>

            <div class="col-lg-6 mt-3 pr-4 pl-4">
              <label for="userName" class="form-label"><lib-translate [key]="'app-user-login-label'"></lib-translate></label>
              <input type="text" [(ngModel)]="user.UserName" class="form-control" id="userName"
                     [ngClass]="{'is-invalid': (userName.touched || userForm.submitted) && userName.errors}"
                     #userName="ngModel" name="userName" required />
              <span class="text-danger" *ngIf="(userName.touched || userForm.submitted) && userName.errors?.['required']">
                <lib-translate [key]="'app-user-login-required'"></lib-translate>
              </span>
            </div>

            <div class="col-lg-6 mt-3 pr-4 pl-4">
              <label for="dateFormat" class="form-label"><lib-translate [key]="'app-user-dateformat-label'"></lib-translate></label>
              <ng-select class="mb-2" [clearable]="false" [searchable]="false" [multiple]="false" bindLabel="value" [closeOnSelect]="true" [(ngModel)]="dateFormat"
                         id="dateFormat" [items]="dateFormatNGSelect" (change)="onChangeDateFormat($event)" [ngModelOptions]="{standalone: true}" #userDateFormat>
              </ng-select>
            </div>

            <!-- Application user properties -->
            <div *ngFor="let item of ApplicationUserProperties" class="col-lg-6 mt-3 pr-4 pl-4">
              <lib-property-type-input [showLabel]="true"
                                        [propertyLabel]="item.Name"
                                        [propertyDefinition]="item">
              </lib-property-type-input>
            </div>

            <!--<div class="col-lg-12 mt-4 pr-4 pl-4">
              <ng-container *ngIf="id">
                <input type="checkbox" name="setPwd" [(ngModel)]="setPwd" />&nbsp;<lib-translate [key]="'app-user-password-update'"></lib-translate>
              </ng-container>
            </div>

            <ng-container *ngIf="setPwd">
              <div class="col-lg-6 mt-3 pr-4 pl-4">
                <label for="userPassword" class="form-label"><lib-translate [key]="'app-user-password-label'"></lib-translate></label>
                <div class="input-group">
                  <input [type]="pwdType" [(ngModel)]="user.Password" class="form-control" id="userPassword" name="userPassword" #userPassword="ngModel"
                         [ngClass]="{'is-invalid': (userPassword.touched || userForm.submitted) && userPassword.errors}" required
                         pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}" />
                  <span class="input-group-text btn-primary" (click)="onToogleViewPwd()">
                    <fa-icon *ngIf="pwdType === 'password'; else pwdView" icon="eye-slash"></fa-icon>
                    <ng-template #pwdView>
                      <fa-icon icon="eye"></fa-icon>
                    </ng-template>
                  </span>
                </div>
                <span class="text-danger" *ngIf="(userPassword.touched || userForm.submitted) && userPassword.errors?.['required']">
                  <lib-translate [key]="'app-user-password-required'"></lib-translate>
                </span>
                <span class="text-danger" *ngIf="(userPassword.touched || userForm.submitted) && userPassword.errors?.['pattern']">
                  <lib-translate [key]="'app-user-password-complexity'"></lib-translate>
                </span>
              </div>

              <div class="col-lg-6 mt-3 pr-4 pl-4">
                <label for="userPasswordConfirmation" class="form-label"><lib-translate [key]="'app-user-passwordconfirmation-label'"></lib-translate></label>
                <div class="input-group">
                  <input [type]="confirmPwdType" [(ngModel)]="passwordConfirmation" class="form-control" id="userPasswordConfirmation" name="userPasswordConfirmation"
                         [ngClass]="{'is-invalid': (userPasswordConfirmation.touched || userForm.submitted) && userPasswordConfirmation.errors}"
                         pattern="{{passwordConfirmationRegex}}" #userPasswordConfirmation="ngModel" />
                  <span class="input-group-text btn-primary" (click)="onToogleViewConfirmPwd()">
                    <fa-icon *ngIf="confirmPwdType === 'password'; else confPwdView" icon="eye-slash"></fa-icon>
                    <ng-template #confPwdView>
                      <fa-icon icon="eye"></fa-icon>
                    </ng-template>
                  </span>
                </div>

                <span class="text-danger" *ngIf="(userPasswordConfirmation.touched || userForm.submitted) && userPasswordConfirmation.errors?.['pattern']">
                  <lib-translate [key]="'app-user-password-confirmation-complexity'"></lib-translate>
                </span>
              </div>-->
            <!--</ng-container>-->

          </div>

          <div class="d-flex justify-content-center mt-4 mb-3">
            <button class="btn btn-primary" type="submit"><lib-translate [key]="'app-user-submit-btn'"></lib-translate></button>
          </div>

        </div>

        <div class="col-lg-4 mt-4">

          <div class="d-flex justify-content-center m-3">
            <img class="img-fluid" *ngIf="user.Img; else defaultImg" [src]="user.Img" alt="profile d'utilisateur" name="userImg" />
            <ng-template #defaultImg>
              <img src="../../../assets/img/Default_pfp.svg" />
            </ng-template>
          </div>
          <div class="d-flex justify-content-center">
            <input class="btn btn-primary" type="file" name="profileImg" accept="image/png, image/jpeg" (change)="onImgUpdload($event)" />
          </div>

        </div>
      </div>
    </form>
  </div>
</div>
