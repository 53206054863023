<div class="login-box">
    <div class="card">
        <div class="card-body login-card-body">
            <div class="card-body login-card-body">
                <div *ngIf="ShowValidEmailConfirmation">
                    <p class="login-box-msg">{{'text-description-email-confirmation'|translate}}</p>
                </div>
                <div *ngIf="ShowNoValidEmailConfirmation">
                    <p class="login-box-msg">{{'text-description-token-error-email-confirmation'|translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
