import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LibHttpClientApiService } from '../../lib-http-client-api/lib-http-client-api.service';
import { TranslateUrls } from '../lib-herdia-app-translate.enums';
import { LibHerdiaAppTranslateService } from '../lib-herdia-app-translate.service';

@Component({
    selector: 'lib-translate-edit-modal',
    templateUrl: './lib-translate-edit-modal.component.html',
    styleUrls: ['./lib-translate-edit-modal.component.scss']
})
export class LibTranslateEditModalComponent implements OnInit {
    key: string = "";
    currentTranslationLabel: string = "";

    constructor(
        private activeModal: NgbActiveModal,
        private apiService: LibHttpClientApiService,
        private translateService: TranslateService,
        private herdiaAppTranslateService: LibHerdiaAppTranslateService
    ) { }

    dismiss(message: string) {
        return this.activeModal.dismiss();
    }

    ngOnInit(): void {
    }

    onSave(): void {
        this.herdiaAppTranslateService.updateCurrentTranslation(this.key, this.currentTranslationLabel)?.subscribe(t => {
            this.apiService.get(TranslateUrls.GET_SIGNATURE).subscribe((signature: any) => {
                this.apiService.get(TranslateUrls.GET_TRANSLATIONS).subscribe((translations: any) => {
                    localStorage.setItem("herdiaTranslations", JSON.stringify({ translations }));
                    localStorage.setItem("herdiaTranslationsSignature", signature.Signature);
                    this.translateService.reloadLang(this.translateService.currentLang).subscribe(e => {
                        this.activeModal.close();
                    });
                });
            });
        });
    }
}
