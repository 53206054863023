import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DynamicComponentDeclaration, DynamicComponentPackage, DynamicComponentsBaseModule } from '@herdia-common';
import { LibHerdiaAppTranslateModule } from '@herdia-common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LibCardAlertFooterComponent } from './lib-card-alert-footer/lib-card-alert-footer.component';
import { LibCardAlertComponent } from './lib-card-alert.component';

@NgModule({
    declarations: [
        LibCardAlertComponent,
        LibCardAlertFooterComponent
    ],
    imports: [
        NgbModule,
        LibHerdiaAppTranslateModule,
        FontAwesomeModule,
        FormsModule
    ],
    exports: [
        LibCardAlertComponent,
        LibCardAlertFooterComponent
    ]
})
export class LibCardAlertModule extends DynamicComponentsBaseModule {

    override getPackageName(): DynamicComponentPackage {
        return 'alhambra';
    }

    override getDynamicComponentsDeclarations(): DynamicComponentDeclaration[] {
        return [
            { component: LibCardAlertComponent, type: 'card' },
            { component: LibCardAlertFooterComponent, type: 'default' },
        ]
    }

}
