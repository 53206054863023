import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LibHttpClientApiService } from '@herdia-common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { HACategory } from '../shared/models/interfaces';
import { AppService } from '../shared/service/app.service';
import { CategoryService } from '../shared/service/category.service';

@Component({
    selector: 'app-add-category-modal',
    templateUrl: './add-category-modal.component.html',
    styleUrls: ['./add-category-modal.component.scss']
})
export class AddCategoryModalComponent implements OnInit {

    editMode = false;
    categoryConfig: HACategory = { id: 0, label: "", description: "", icon: "" };
    addCategoryModaCategoryLabel: string = "";
    // addCategoryModalCategoryDescription: string = "";
    emptyIcon: boolean = false;

    myFormGroup: FormGroup;
    iconCss = new FormControl();

  constructor(
    private apiService: LibHttpClientApiService,
    appService: AppService,
    translatePipe: TranslatePipe,
    private categoryService: CategoryService,
    private activeModal: NgbActiveModal
  ) {
        this.myFormGroup = new FormGroup({ iconCss: this.iconCss });
        appService.editModeSub.subscribe(e => { this.editMode = e; });
        this.addCategoryModaCategoryLabel = translatePipe.transform("add-category-modal-category-label");
        // this.addCategoryModalCategoryDescription = translatePipe.transform("add-category-modal-category-description");
        // TODO -> ajouter pour l'icon ?
    }

    dismiss() {
        return this.activeModal.dismiss();
    }

    ngOnInit(): void {
    }

    onValidate(): void {
        if (this.categoryConfig.icon != "" && !this.emptyIcon) {
            this.categoryService.addCategory({
                label: this.categoryConfig.label,
                description: this.categoryConfig.description,
                icon: this.categoryConfig.icon
            });
        }
        else {
            this.emptyIcon = true;
        }
    }

    onIconPickerSelect(icon: string): void {
        this.emptyIcon = false;
        this.iconCss.setValue(icon);
        this.categoryConfig.icon = icon;
    }
}
