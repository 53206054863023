import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibSchedulerServiceModule } from '../lib-scheduler-service/lib-scheduler-service.module';
import { LibCronEditorComponent } from './lib-cron-editor.component';

@NgModule({
    declarations: [
        LibCronEditorComponent
    ],
    imports: [
        LibHerdiaAppTranslateModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        LibSchedulerServiceModule,
        TranslateModule
    ],
    exports: [
        LibCronEditorComponent,
        TranslateModule
    ]
})
export class LibCronEditorModule { }
