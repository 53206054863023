import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common';
import { TranslatePipe } from "@ngx-translate/core";
import { Subject, forkJoin } from 'rxjs';
import { LibProcedureInputService } from '../lib-procedure-input/lib-procedure-input.service';
import { AgencyFilter, AssetManagerFilter, BuildingFilter, BuildingNatureFilter, BusinessUnitsFilter, FeeCentersFilter, FundManagerFilter, GeographicalZoneFilter, ImportDateFilter, NeutralizationFilter, OwnersFilter, PortfolioFilter, PropertyManagerFilter } from './lib-panel-right.interface';

@Injectable({
    providedIn: 'root'
})
export class LibPanelRightService {

    availableImportDates: ImportDateFilter[] = [];
    availablePortfolios: PortfolioFilter[] = [];
    availableAssetManagers: AssetManagerFilter[] = [];
    availableFunds: FundManagerFilter[] = [];
    availableFundsBuffer: FundManagerFilter[] = [];
    availableBusinessUnits: BusinessUnitsFilter[] = [];
    availableFeeCenters: FeeCentersFilter[] = [];
    availableAgencies: AgencyFilter[] = [];
    availablePropertyManagers: PropertyManagerFilter[] = [];
    availableNeutralizations: NeutralizationFilter[] = [];
    availableOwners: OwnersFilter[] = [];
    availableGeographicalZones: GeographicalZoneFilter[] = [];
    availableBuildingNatures: BuildingNatureFilter[] = [];
    availableBuildings: BuildingFilter[] = [];
    availableBuildingsBuffer: BuildingFilter[] = [];

    FiltersReady = false;
    bufferSize = 20;
    numberOfItemsFromEndBeforeUpdateBuffers = 10;

    selectedImportDate!: Date;
    selectedPortfolio: string = "00000000000000000000";
    selectedAssetManager: number = -1;
    selectedFund: string = "00000000000000000000";
    selectedBusinessUnit: string = "00000000000000000000";
    selectedFeeCenter: string = "00000000000000000000";
    selectedAgency: string = "00000000000000000000";
    selectedPropertyManager: string = "-1";
    selectedNeutralization: string = "ALL_BUILDINGS";
    selectedOwners: number[] = [];
    selectedGeographicalZone: string[] = [];
    selectedBuildingNature: string[] = [];
    selectedBuildings: number[] = [];

    Filters: {
        importDate: Subject<Date>,
        portfolio: Subject<string>,
        fund: Subject<string>,
        assetManager: Subject<number>,
        businessUnit: Subject<string>,
        feeCenter: Subject<string>,
        agency: Subject<string>,
        propertyManager: Subject<string>,
        neutralization: Subject<string>,
        owners: Subject<number[]>,
        geographicalZones: Subject<string[]>,
        buildingNatures: Subject<string[]>,
        buildings: Subject<number[]>
    }

    FiltersChange: Subject<any> = new Subject<any>();

    constructor(private procedureService: LibProcedureInputService, private httpClientApiService: LibHttpClientApiService, translatePipe: TranslatePipe) {
        const datepipe: DatePipe = new DatePipe('en-US');

        this.availableNeutralizations = [
            { neutralization_code: "ALL_BUILDINGS", neutralization_label: translatePipe.transform("neutralization-all") },
            { neutralization_code: "NOT_NEUTRALIZED", neutralization_label: translatePipe.transform("not-neutralized") },
            { neutralization_code: "NEUTRALIZED", neutralization_label: translatePipe.transform("neutralized") }
        ];

        this.Filters = {
            importDate: new Subject<Date>(),
            portfolio: new Subject<string>(),
            fund: new Subject<string>(),
            assetManager: new Subject<number>(),
            businessUnit: new Subject<string>(),
            feeCenter: new Subject<string>(),
            agency: new Subject<string>(),
            propertyManager: new Subject<string>(),
            neutralization: new Subject<string>(),
            owners: new Subject<number[]>(),
            geographicalZones: new Subject<string[]>(),
            buildingNatures: new Subject<string[]>(),
            buildings: new Subject<number[]>()
        }

        this.Filters.importDate.subscribe(date => {
            forkJoin(
                httpClientApiService.post<any, PortfolioFilter[]>("api/alhambra/GetPortfoliosList/Datas", {
                    "importDate": date,
                    "languageCode": "fr-FR",
                    "user": "alhambra",
                    "allPortfoliosLine": true
                }),

                httpClientApiService.post<any, AssetManagerFilter[]>("api/alhambra/GetAssetManagerList/Datas", {
                    "importDate": date,
                    "languageCode": "fr",
                    "user": "alhambra"
                }),

                httpClientApiService.post<any, BusinessUnitsFilter[]>("api/alhambra/GetBusinessUnitList/Datas", {
                    "importDate": date,
                    "languageCode": "fr",
                    "user": "alhambra"
                }),

                httpClientApiService.post<any, FeeCentersFilter[]>("api/alhambra/GetFeeCenterList/Datas", {
                    "importDate": date,
                    "languageCode": "fr",
                    "user": "alhambra"
                }),

                httpClientApiService.post<any, AgencyFilter[]>("api/alhambra/GetAgencyList/Datas", {
                    "importDate": date,
                    "languageCode": "fr",
                    "user": "alhambra"
                }),

                httpClientApiService.post<any, PropertyManagerFilter[]>("api/alhambra/GetManagerList/Datas", {
                    "importDate": date,
                    "languageCode": "fr",
                    "user": "alhambra"
                }),

                httpClientApiService.post<any, OwnersFilter[]>("api/alhambra/GetOwnersList/Datas", {
                    "importDate": date
                }),

                httpClientApiService.post<any, GeographicalZoneFilter[]>("api/alhambra/custom/GetGeographicalZonesList/Datas", {
                    "sourceTable": "",
                    "importDate": date,
                    "idSaleProgresStateList": ""
                }),

                httpClientApiService.post<any, BuildingNatureFilter[]>("api/alhambra/custom/GetBuildingNaturesList/Datas", {
                    "sourceTable": "",
                    "importDate": date,
                    "languageCode": "fr",
                    "buildingTypeCode": ""
                }),

                httpClientApiService.post<any, any>("api/alhambra/GetExternalFundList/Datas", {
                    ImportDate: date,
                    LanguageCode: "fr-FR",
                    User: "Alhambra"
                })
            ).subscribe(results => {
                let portfolios = results[0];
                let assetManagers = results[1];
                let businessUnits = results[2];
                let feeCenters = results[3];
                let agencies = results[4];
                let propertyManagers = results[5];
                let owners = results[6];
                let geozones = results[7];
                let buildingNatures = results[8];
                let funds = results[9];

                this.availablePortfolios = portfolios;
                this.selectedPortfolio = "00000000000000000000";

                this.selectedAssetManager = -1;
                if (assetManagers.length > 1) {
                    this.availableAssetManagers = assetManagers;
                }
                else
                    this.availableAssetManagers = [];

                if (businessUnits.length > 1) {
                    this.selectedBusinessUnit = "00000000000000000000";
                    let allIndex = businessUnits.findIndex((b: BusinessUnitsFilter) => { return b.business_unit_code == "00000000000000000000" });
                    if (allIndex !== -1)
                        businessUnits.splice(allIndex, 1);
                    this.availableBusinessUnits = businessUnits;
                }
                else
                    this.availableBusinessUnits = [];

                this.selectedFeeCenter = "00000000000000000000";
                if (feeCenters.length > 1) {
                    let allIndex = feeCenters.findIndex((b: FeeCentersFilter) => { return b.fee_center_code == "00000000000000000000" });
                    if (allIndex !== -1)
                        feeCenters.splice(allIndex, 1);
                    this.availableFeeCenters = feeCenters;
                }
                else
                    this.availableFeeCenters = [];

                this.selectedAgency = "00000000000000000000";
                if (agencies.length > 1) {
                    let allIndex = agencies.findIndex((b: AgencyFilter) => { return b.agency_code == "00000000000000000000" });
                    if (allIndex !== -1)
                        agencies.splice(allIndex, 1);
                    this.availableAgencies = agencies;
                }
                else
                    this.availableAgencies = [];

                this.availablePropertyManagers = propertyManagers;
                this.selectedPropertyManager = "-1";

                this.availableOwners = owners;
                this.selectedOwners = [];

                this.availableGeographicalZones = geozones;
                this.selectedGeographicalZone = [];

                this.availableBuildingNatures = buildingNatures;
                this.selectedBuildingNature = [];

                this.availableFunds = funds;
                this.availableFundsBuffer = this.availableFunds.slice(this.availableFundsBuffer.length, this.bufferSize);

                // this.FiltersChange.next({});
                this.FiltersReady = true;
            });

            this.updateBuildingList();
        });

        this.Filters.portfolio.subscribe(p => this.updateBuildingList());
        this.Filters.fund.subscribe(p => this.updateBuildingList());
        this.Filters.assetManager.subscribe(p => this.updateBuildingList());
        this.Filters.businessUnit.subscribe(p => this.updateBuildingList());
        this.Filters.feeCenter.subscribe(p => this.updateBuildingList());
        this.Filters.agency.subscribe(p => this.updateBuildingList());
        this.Filters.propertyManager.subscribe(p => this.updateBuildingList());
        this.Filters.neutralization.subscribe(p => this.updateBuildingList());
        this.Filters.owners.subscribe(p => this.updateBuildingList());
        this.Filters.geographicalZones.subscribe(p => this.updateBuildingList());
        this.Filters.buildingNatures.subscribe(p => this.updateBuildingList());

        httpClientApiService.post<any, any>("api/alhambra/GetImportDateList/Datas", {
        })?.subscribe((dates) => {
            dates.forEach((id: { [x: string]: any; }) => {
                this.availableImportDates.push({ importDate: id["IMPORT_DATE"], importDateLabel: datepipe.transform(id["IMPORT_DATE"], "dd-MM-YYYY") || "invalid date" });
            });
            this.selectedImportDate = this.availableImportDates[0].importDate;
            this.Filters.importDate.next(this.selectedImportDate);
        });

    }

    private AsComaDelimitedOfNumber(id: number[]): string {
        if (Array.isArray(id)) {
            if (id.length > 0) {
                return id.join(",");
            }
        }
        return "";
    }

    private AsComaDelimitedOfString(id: string[]): string {
        if (Array.isArray(id)) {
            if (id.length > 0) {
                return "'" + id.join("','") + "'";
            }
        }
        return "";
    }

    public updateBuildingList(): void {
        this.httpClientApiService.post<any, BuildingFilter[]>("api/alhambra/GetBuildingsList/Datas", {
            ImportDate: this.selectedImportDate,
            StartDate: this.selectedImportDate,
            EndDate: this.selectedImportDate,
            PortfolioCode: this.selectedPortfolio,
            IdRepositoryClient: '-1', //This value is set raw at the moment in order to fix the right panel
            IdOwnerList: this.selectedOwners.length === 0 ? "-1" : this.AsComaDelimitedOfNumber(this.selectedOwners),
            BuildingNatureCodeList: this.selectedBuildingNature.length === 0 ? "'00000000000000000000'" : this.AsComaDelimitedOfString(this.selectedBuildingNature),
            GeographicalZonesList: this.selectedGeographicalZone.length === 0 ? "'00000000000000000000'" : this.AsComaDelimitedOfString(this.selectedGeographicalZone),
            BuildingTypeCode: "00000",
            NatureOccupancyCodeList: "'00000000000000000000'", // Je sais même pas quel filtre c'est
            IdAssetManager: this.selectedAssetManager,
            BusinessUnitCode: this.selectedBusinessUnit,
            FeeCenterCode: this.selectedFeeCenter,
            AgencyCode: this.selectedAgency,
            IdPromanager: this.selectedPropertyManager,
            ExternalFundCode: this.selectedFund,
            SelectType: "'00000000000000000000'",
            NeutralizationType: this.selectedNeutralization,
            User: "Alhambra",
            DecryptKey: null
        })?.subscribe((buildings: BuildingFilter[]) => {
            this.availableBuildings = buildings;
            this.availableBuildingsBuffer = this.availableBuildings.slice(this.availableBuildings.length, this.bufferSize);
        });
    }

    public updateBuildingsBuffer(start: number, end: number): void {
        if (end + this.numberOfItemsFromEndBeforeUpdateBuffers >= this.availableBuildingsBuffer.length) {
            const len = this.availableBuildings.length;
            const more = this.availableBuildings.slice(len, this.bufferSize + len);
            this.availableBuildingsBuffer = this.availableBuildingsBuffer.concat(more);
        }
    }

    public updateFundsBuffer(start: number, end: number): void {
        if (end + this.numberOfItemsFromEndBeforeUpdateBuffers >= this.availableFundsBuffer.length) {
            const len = this.availableFunds.length;
            const more = this.availableFunds.slice(len, this.bufferSize + len);
            this.availableFundsBuffer = this.availableFundsBuffer.concat(more);
        }
    }

    public getSubjectForBind(bind: string): Subject<any> | undefined {
        switch (bind) {
            case "[Filter.ImportDate]": return this.Filters.importDate;
            case "[Filter.PortfolioCode]": return this.Filters.portfolio;
            case "[Filter.ExternalFund]": return this.Filters.fund;
            case "[Filter.AssetManager]": return this.Filters.assetManager;
            case "[Filter.BusinessUnitList]": return this.Filters.businessUnit;
            case "[Filter.FeeCenter]": return this.Filters.feeCenter;
            case "[Filter.Agencies]": return this.Filters.agency;
            case "[Filter.PropertyManagerList]": return this.Filters.propertyManager;
            case "[Filter.NeutralizationType]": return this.Filters.neutralization;
            case "[Filter.OwnerList]": return this.Filters.owners;
            case "[Filter.GeographicalZoneList]": return this.Filters.geographicalZones;
            case "[Filter.BuildingNatureList]": return this.Filters.buildingNatures;
            case "[Filter.BuildingsList]": return this.Filters.buildings;
            default: return undefined;
        }
    }
    public getBindableValues(): any {
        return {
            "[Filter.ImportDate]": this.selectedImportDate,
            "[Filter.PortfolioCode]": this.selectedPortfolio,
            "[Filter.ExternalFund]": this.selectedFund,
            "[Filter.AssetManager]": this.selectedAssetManager,
            "[Filter.BusinessUnitList]": this.selectedBusinessUnit,
            "[Filter.FeeCenter]": this.selectedFeeCenter,
            "[Filter.Agencies]": this.selectedAgency,
            "[Filter.PropertyManagerList]": this.selectedPropertyManager,
            "[Filter.NeutralizationType]": this.selectedNeutralization,
            "[Filter.OwnerList]": this.selectedOwners,
            "[Filter.GeographicalZoneList]": this.selectedGeographicalZone,
            "[Filter.BuildingNatureList]": this.selectedBuildingNature,
            "[Filter.BuildingsList]": this.selectedBuildings
        }
    }
}
