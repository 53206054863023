<div *ngIf="!isFilter && display != '' && (columnSetting.actionColumnDef === null || columnSetting.actionColumnDef === undefined) && !UsefileDeposit" [ngClass]="{'text-truncate': datatableConfig.nowrap}" title="{{display}}">{{display}}</div>
<div *ngIf="!isFilter && display == '' && (columnSetting.actionColumnDef === null || columnSetting.actionColumnDef === undefined) && !UsefileDeposit">&nbsp;</div>
<div *ngIf="isFilter">
  <div class="input-group">
    <input type="text" class="form-control form-control-sm" id="filter" [(ngModel)]="searchFieldValue">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary btn-sm" type="button" (click)="onSearch({column: '', searchValue: searchFieldValue })"><fa-icon icon="magnifying-glass"></fa-icon></button>
    </div>
  </div>
</div>
<div *ngIf="columnSetting.actionColumnDef && display != '' && !UsefileDeposit">
  <div class="d-inline-flex w-100 justify-content-evenly">
    <div *ngFor="let btn of columnSetting.actionColumnDef.buttons">
      <button *ngIf="btn.type=='button'" class="{{btn.class}} d-flex" (click)="btn.onclick(this.data)"><fa-icon [icon]="['fas', btn.icon]"></fa-icon><div *ngIf="btn.text !== ''">&nbsp;</div>{{btn.text}}</button>
    </div>
  </div>
</div>

<div *ngIf="UsefileDeposit" #cellFileDeposit>
  <label #cellFileDepositLabel style="display:flex; justify-content:center">
    {{display}}
    &nbsp;
    <div #cellFileDepositIcon style="display:none">
      <fa-icon icon="triangle-exclamation"></fa-icon>
    </div>
    
  </label>
</div>
