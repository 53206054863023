import { Component, Input } from '@angular/core';
import { HaPageCard } from '../../core/shared/models/interfaces';
import { CardConfigBaseComponent } from './card-config-base-component';

@Component({
    template: ''
})
export class CardBaseComponent extends CardConfigBaseComponent {

  @Input() title: string = '';
}
