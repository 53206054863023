import { NgModule } from '@angular/core';
import { LibDatatablePaginationComponent } from './lib-datatable-pagination.component';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';

@NgModule({
  declarations: [
    LibDatatablePaginationComponent
  ],
  imports: [
    DataTablesModule,
    CommonModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
    LibHerdiaAppTranslateModule
  ],
  exports: [
    LibDatatablePaginationComponent
  ]
})
export class LibDatatablePaginationModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
