import { Injectable } from '@angular/core';
import { AuthRes, Login, RegistrationResponse, TokenRequest, GoogleLogin } from '../models/interfaces';
import { UserUrls } from './api.service';
import { map, tap } from 'rxjs/operators'
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LibHttpClientApiService } from '@herdia-common';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { LibUserService } from '@herdia-common';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    private _refreshTokenTimeout: any;
    userSub: BehaviorSubject<RegistrationResponse | null>;

    constructor(
        private apiSvc: LibHttpClientApiService,
        private router: Router,
        private socialAuthService: SocialAuthService,
        private libUserService: LibUserService
    ) {
        const userStorage = localStorage.getItem('user');
        this.userSub = userStorage === null
            ? new BehaviorSubject<RegistrationResponse | null>(null)
            : new BehaviorSubject<RegistrationResponse | null>(JSON.parse(userStorage));
    }

    login(username: string, password: string): Observable<RegistrationResponse> | undefined {
        const login: Login = { Email: username, Password: password };
        return this.apiSvc.post<Login, RegistrationResponse>(UserUrls.LOGIN, login)?.pipe(tap(response => {
            if (response.Success) {
                this.setUser(response);
                this.userSub.next(response);
                this.startRefreshTokenTimer();
            }
        }));
    }
    googleAuthLogin(email: string, token: string, firstname: string, lastname: string) {
        const googleLogin: GoogleLogin = { Email: email, AuthToken: token, FirstName: firstname, LastName: lastname }
        return this.apiSvc.post<GoogleLogin, RegistrationResponse>('api/AuthManagement/GoogleAuth', googleLogin)?.pipe(tap(response => {
            if (response.Success) {
                this.setUser(response);
                this.userSub.next(response);
                this.startRefreshTokenTimer();
            }
        }))
    }

    getAccessTokenObject() {
        const accessToken = this.userSub.value?.Token?.split('.')[1];
        if (!accessToken) {
            console.log('getAccessTokenObject(). Access token is empty ...');
            return;
        }

        return JSON.parse(atob(accessToken));
    }

    isAccessTokenExpired(): boolean {
        const tokenObject = this.getAccessTokenObject();
        if (!tokenObject) {
            console.log('isTokenExpired(). Access token is empty ...');
            return false;
        }

        const expiresDate = new Date(tokenObject.exp * 1000);
        return expiresDate.getTime() - Date.now() < 0;
    }

    startRefreshTokenTimer() {
        console.log('Start timer ...');
        const accessTokenObject = this.getAccessTokenObject();
        if (!accessTokenObject) {
            console.log('startRefreshTokenTimer(). Access token is empty ...');
            return;
        }

        const expires = new Date(accessTokenObject.exp * 1000);
        const beforeExpiration = 0;
        const timeout = expires.getTime() - Date.now() - beforeExpiration;

        this._refreshTokenTimeout = setTimeout(() => this.refreshToken()?.subscribe(), timeout);
    }

    refreshToken(): Observable<any> | undefined {
        console.log('refreshToken(). refresh token ...');

        if (!this.isAccessTokenExpired()) {
            console.log('refreshToken(). token not expired yet ...');
            return undefined;
        }

        if (this.userSub.value?.Token && this.userSub.value.RefreshToken) {
            const tokenRequest: TokenRequest = {
                Token: this.userSub.value.Token,
                RefreshToken: this.userSub.value.RefreshToken
            };

            return this.apiSvc.post<TokenRequest, AuthRes>(UserUrls.REFRESH_TOKEN, tokenRequest)?.pipe(map(
                res => {
                    if (res && res.Success) {
                        const user = this.userSub.value;
                        if (user) {
                            user.Token = res.Token;
                            user.RefreshToken = res.RefreshToken;
                            user.Success = res.Success;
                            user.Errors = res.Errors;

                            this.setUser(user);
                            this.userSub.next(user);
                            this.startRefreshTokenTimer();
                        }
                        console.log(`Token refreshed at ${new Date().toDateString()}...`);
                        return true;
                    }
                    else {
                        this.logout();
                        console.log('Token NOT refreshed ...');
                        return false;
                    }
                }
            ));
        }
        else {
            console.log('Token or Refreshtoken empty ...');
            return undefined;
        }
    }

    logout() {
        this.apiSvc.get("api/CacheManagement/FlushAll", {}).subscribe(() => {
            this.removeUser();
            this.libUserService.setUser(null);
            this.userSub.next(null);
            this.socialAuthService.signOut();
            localStorage.clear();
            this.router.navigate(['login']);
            clearTimeout(this._refreshTokenTimeout);
        });
    }

    public get userValue(): RegistrationResponse | null {
        return this.userSub.value;
    }

    getUser(): RegistrationResponse | null {
        const user = localStorage.getItem('user');
        if (user)
            return JSON.parse(user) as RegistrationResponse;
        return null;
    }

    setUser(user: RegistrationResponse) {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            this.libUserService.setUser(user);
        }
        else
            console.error('User is empty');
    }

    removeUser() {
        localStorage.removeItem('user');
    }

    emailConfirmation(email: string, token: string) {
        return this.apiSvc.get<boolean>(`api/UserManagement/emailConfirmation/${token}/${email}`);
    }
}
