<div class="dataTable-container">
  <div class="dt-loader" [ngStyle]="{'visibility': showLoader ? 'visible' : 'hidden'}"><fa-icon icon="spinner" [spin]="true" size="5x"></fa-icon></div>
  <table #table [ngClass]="{ 'hadt table': true,
                             'compact': herdiaDTConfig.compactMode,
                             'stripe': herdiaDTConfig.stripe,
                             'hover':  herdiaDTConfig.hover,
                             'row-border': herdiaDTConfig.rowBorder,
                             'order-column': herdiaDTConfig.orderColumn,
                             'cell-border': herdiaDTConfig.cellBorder
                           }" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" (resized)="onResized($event)" style="width:100%">
    <thead>
      <tr>
        <th *ngFor="let col of columnSettings">{{col.name}}></th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let l of numberOfRow">
      <td *ngFor="let col of columnSettings">&nbsp;</td>
    </tr>
    </tbody>
    <tfoot *ngIf="hasSums">
    <tr>
      <th *ngFor="let col of columnSettings">&nbsp;</th>
    </tr>
    </tfoot>
  </table>
  <ng-template #dtCellRenderer let-data="adtData" let-searchEmitter="captureEvents" let-columnSetting="columnSetting" let-datatableConfig="datatableConfig">
    <lib-herdia-datatable-cell-render [datatableConfig]="datatableConfig" [columnSetting]="columnSetting"  [data]="data" [fileDepositConfig]="fileDepostitConfig" [IndexRow]="dtElement" (searchEmitter)="searchEmitter($event)"></lib-herdia-datatable-cell-render>
  </ng-template>
</div>
