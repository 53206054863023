import { Component, Input, OnInit } from '@angular/core';
import { PropertyDefinition, PropertyItemDefinition } from '@herdia-common';
import { TranslatePipe } from '@ngx-translate/core';
import { LibProcedureInputService } from '../lib-procedure-input.service';
import { addDays, format } from 'date-fns';

@Component({
  selector: 'lib-data-input-parameter',
  templateUrl: './lib-data-input-parameter.component.html',
  styleUrls: ['./lib-data-input-parameter.component.css']
})
export class LibDataInputParameterComponent implements OnInit {
  @Input() displayStoredProcedure!: boolean;
  @Input() allowRawBinding!: boolean;
  @Input() allowCardBinding!: boolean;
  @Input() allowPageBinding!: boolean;
  @Input() parameter: { [key: string]: any; } = {};

  private _listValues!: PropertyItemDefinition[];
  @Input() set listValues(value: PropertyItemDefinition[]) {
    this._listValues = value;
  }
  get listValues(): PropertyItemDefinition[] {
    return this._listValues;
  }
  public get parameterName() {
    return this.parameter['ParameterName'];
  }

  public get parameterNameTranslated() {
    return this.libTranslatePipe.transform('lbl-' + this.parameter['ParameterName']);
  }
  public set parameterName(value: string) {
    this.parameter['ParameterName'] = value;
  }

  public get parameterLabel() {
    return this.parameter['ParameterLabel'];
  }
  public set parameterLabel(value: string) {
    this.parameter['ParameterLabel'] = value;
  }

  public get parameterType() {
    return this.parameter['ParameterType'];
  }
  public set parameterType(value: string) {
    this.parameter['ParameterType'] = value;
  }

  public get parameterBinding() {
    return this.parameter['ParameterBinding'];
  }
  public set parameterBinding(value: {Type:string, Value: any, Nullable: boolean, CardFilterBindingCategory: string, CardFilterSelectedFilter: string}) {
    this.parameter['ParameterBinding'] = value;
  }

  textTypes: string[] = ["string", "String", "JsonString", "int", "Int32", "Int32?", "decimal", "Decimal", "decimal?", "Decimal?", "char", "char?"];
  boolTypes: string[] = ["bool", "Boolean", "boolean"];
  dateTypes: string[] = ["Datetime", "Datetime?", "Date", "Date?", "DateTime", "DateTime?"]

  getPropertyDefinition(): PropertyDefinition {
    let result: PropertyDefinition = { AvailableItems: [], Name: '', Type: '', Options: [] };
    if (this._listValues.length > 0)
    {
      result.AvailableItems = this._listValues;
      result.Options.push(1);
    }
    result.Type = this.parameterType;
    result.Name = this.parameterName;
    return result;
  }
  // CardFilter date type
  availableCardFilterDateType: {key: string, value: string}[] = [
    { key: "today", value: this.libTranslatePipe.transform("today")},
    { key: "importDate", value: this.libTranslatePipe.transform("importDate")}
  ]

  // CardFilter date operators
  availableCardFilterDateOperator: {key: string, value: string}[] = [
    { key: "plus", value: this.libTranslatePipe.transform("plus")},
    { key: "minus", value: this.libTranslatePipe.transform("minus")}
  ]

  // CardFilter date operand multipliers
  availableCardFilterDateOperandMultiplier: {key: string, value: string}[] = [
    { key: "days", value: this.libTranslatePipe.transform("days")},
    { key: "months", value: this.libTranslatePipe.transform("months")},
    { key: "years", value: this.libTranslatePipe.transform("years")}
  ]

  // Filters (from the right panel of the app)
  availableFilters: {key: string, value: string}[] = [
    { key: "[Filter.ImportDate]", value: this.libTranslatePipe.transform("lbl-import-date")},
    { key: "[Filter.PortfolioCode]", value: this.libTranslatePipe.transform("lbl-portfolio")},
    { key: "[Filter.ExternalFund]", value: this.libTranslatePipe.transform("lbl-funds")},
    { key: "[Filter.AssetManager]", value: this.libTranslatePipe.transform("lbl-asset-managers")},
    { key: "[Filter.BusinessUnitList]", value: this.libTranslatePipe.transform("lbl-business-units")},
    { key: "[Filter.FeeCenter]", value: this.libTranslatePipe.transform("lbl-fee-centers")},
    { key: "[Filter.Agencies]", value: this.libTranslatePipe.transform("lbl-agencies")},
    { key: "[Filter.PropertyManagerList]", value: this.libTranslatePipe.transform("lbl-property-managers")},
    { key: "[Filter.NeutralizationType]", value: this.libTranslatePipe.transform("lbl-neutralization")},
    { key: "[Filter.OwnerList]", value: this.libTranslatePipe.transform("lbl-owners")},
    { key: "[Filter.GeographicalZoneList]", value: this.libTranslatePipe.transform("lbl-geographical-zone")},
    { key: "[Filter.BuildingNatureList]", value: this.libTranslatePipe.transform("lbl-building-natures")},
    { key: "[Filter.BuildingsList]", value: this.libTranslatePipe.transform("lbl-buildings")},
  ]
  // Parameter bindingType
  availableBindingType: {key: string, value: string}[] = [
    { key: "raw", value: this.libTranslatePipe.transform("raw-binding")},
    { key: "cardFilter", value: this.libTranslatePipe.transform("cardFilter-binding")},
    { key: "pageFilter", value: this.libTranslatePipe.transform("pageFilter-binding")}
  ];
// Category for CardFilter bindings
  availableCardFilterBindingCategory: {key: string, value: string}[] = [
    { key: "raw", value: this.libTranslatePipe.transform("raw-binding")},
    { key: "knowFilter", value: this.libTranslatePipe.transform("knowFilter-binding")}
  ];
  constructor(private libTranslatePipe: TranslatePipe, private libProcedureInputSvc: LibProcedureInputService) { }

  ngOnInit(): void {
    if (!this.displayStoredProcedure)
      this.parameterBinding.Type = "raw";
  }

  updateParameterBindingType(item: {key: string, value: string}): void {
    switch (item.value) {
      case "raw-binding":

      case "pageFilter-binding":
        this.parameterBinding.Value = "";
        this.parameterBinding.Nullable = false;
        this.parameterBinding.CardFilterBindingCategory = "";
        this.parameterBinding.CardFilterSelectedFilter = "";
        break;
      case "cardFilter-binding":
        this.parameterBinding.Value = {
          InitDate: "today",
          Operator: "plus",
          Operand: 0,
          Multiplier: "days"
        };
        this.parameterBinding.Nullable = false;
        this.parameterBinding.CardFilterBindingCategory = "raw";
        this.parameterBinding.CardFilterSelectedFilter = "plus";
        break;
    }
  }

  onPropertyInputUpdated(event: any) {
    this.parameter["ParameterBinding"].Value = event;
  }
}
