import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsertViewPageRoleRequest, ModifyRoleViewOnPageRequest } from '../shared/models/interfaces';
import { RoleService } from '../shared/service/role.service';
import { AddEditRoleComponent } from './add-edit-role/add-edit-role.component';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  PagesRolesResultArray: any;
  PagesArray!: [{ PageId: number, PageTitle: string, CategoryId: number, CategoryLabel: string }];
  RolesArray!: [{ RoleId: string, RoleName: string }];
  CategoryArray!: [{ CategoryId: number, CategoryLabel: string }];
  PagesRolesArray!: [{ ApiRoleId: string, HAPageId: number, View: boolean }];

  constructor(private roleService: RoleService, private modalSvc: NgbModal) { }

  ngOnInit(): void {
    this.getInformationsForTable();
  }

  getInformationsForTable(): void {
    
    this.roleService.GetAllRolesAndPagesAndRelationBetween().subscribe(res => {
      //get all page
      this.PagesArray = res.Pages.map((page: any) => ({
        PageId: page.Id,
        PageTitle: page.Title,
        CategoryId: page.HAPageCategoryId,
        CategoryLabel: ""
      })
      );
      //get all role 
      this.RolesArray = res.Roles.map((role: any) => ({
        RoleId: role.Id,
        RoleName: role.Name
      })
      );
      //get all categories
      this.CategoryArray = res.Category.map((categ: any) => ({
        CategoryId: categ.Id,
        CategoryLabel: categ.Label
      })
      );
      //overload PagesArray with category name
      for (let Page of this.PagesArray) {
        for (let Category of this.CategoryArray) {
          if (Page.CategoryId == Category.CategoryId) {
            Page.CategoryLabel = Category.CategoryLabel;
          }
        }
      }
      //sort PagesArray by category
      this.PagesArray.sort(function (a, b) {
        if (a.CategoryLabel < b.CategoryLabel) { return -1; }
        if (a.CategoryLabel > b.CategoryLabel) { return 1; }
        return 0;
      })
      //get all relation between role and page
      this.PagesRolesArray = res.PagesRoles.map((PageRole: any) => ({
        ApiRoleId: PageRole.ApiRoleId,
        HAPageId: PageRole.HAPageId,
        View: PageRole.View
      })
      );
      //create PagesRolesResultArray which will contains all information needed 
      this.PagesRolesResultArray = this.RolesArray.flatMap((role) => {
        return this.PagesArray.map((page) => ({
          RoleId: role.RoleId,
          RoleName: role.RoleName,
          PageId: page.PageId,
          PageTitle: page.PageTitle,
          View: null
        })
        );
      });
      // fill the property view of PagesRolesResultArray with PagesRolesArray
      for (let RolePage of this.PagesRolesResultArray) {
        for (let RolePageLink of this.PagesRolesArray) {
          if (RolePage.PageId == RolePageLink.HAPageId && RolePage.RoleId == RolePageLink.ApiRoleId) {
            RolePage.View = RolePageLink.View;
          }
        }
      }
    });
  }

  //this function is used to change the visibility of the role on a page 
  onCheck(event: any, RoleId: string, PageId: number): void {
    //get the new value of the checkbox 
    const NewValueChecked = (event.target as HTMLInputElement).checked;

    //find the good element by RoleId and PageId from PagesRolesResultArray
    let Elementfound = this.PagesRolesResultArray.find((element: any) => {
      return element.RoleId == RoleId && element.PageId == PageId;
    })

    //depending on the original value of view we will add or remove visibility of the role(RoleId) on this page(PageId) or insert 
    switch (Elementfound.View) {
      case true:
        case false: {
          let objectRequest: ModifyRoleViewOnPageRequest = {
            Action: NewValueChecked,
            PageId: PageId,
            RoleId: RoleId
          };
          this.roleService.AddOrRemoveRoleViewOnPage(objectRequest)?.subscribe(res => { });
          break;
      }
      case null: {
        let objectRequest: InsertViewPageRoleRequest = {
          PageId: PageId,
          RoleId: RoleId
        };
        this.roleService.InsertViewPageRole(objectRequest)?.subscribe(res => { });
        break;
      }
      default: {
        console.log("c'est louche !");
        break;
      }
    }

    //update PagesRolesResultArray 
    Elementfound.View = NewValueChecked;

  }

  //this function is used to check if the visibility is true or false , if the visibility between role and page not exist we will send false (unchecked) to the checkbox 
  isChecked(RoleId: string, PageId: number): boolean {

    let found = this.PagesRolesResultArray.find((element: any) => {
      return element.RoleId == RoleId && element.PageId == PageId;
    })
    if (found == undefined || found == null) {
      return false;
    }
    return found.View == true ? true : false;
  }

  //add a role and refresh minformations of the table
  onAdd() {
    const modalRef = this.modalSvc.open(AddEditRoleComponent);
    modalRef.result.then((useless: string) => {
        this.getInformationsForTable();
      } 
    )
  }


}
