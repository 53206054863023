import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '../lib-http-client-api/lib-http-client-api.service';
import { EditModeUrls } from './lib-edit-mode.enum';

@Injectable({
    providedIn: 'root'
})
export class LibEditModeService {

    private editMode: boolean = false;
    private authToEdit!: boolean;

    constructor(private apiSvc: LibHttpClientApiService) {
        this.getAuth();
    }

    getAuth() {
        this.apiSvc.get<boolean>(EditModeUrls.GET_AUTH).subscribe(edit => {
            this.authToEdit = edit;
        })
    };

    setEditMode() {
        if (this.authToEdit && this.editMode) {
            this.editMode = false;
        }
        else if (this.authToEdit && !this.editMode) {
            this.editMode = true;
        }
    }

    getEditMode(): boolean {
        return this.editMode;
    }

    getAuthToEdit(): boolean {
      return this.authToEdit;
    }


}
