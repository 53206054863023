import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent } from '../../dynamic-components/card-config-base-component';

@Component({
    selector: 'lib-card-generic-crud-config-aspect',
    templateUrl: './lib-card-generic-crud-config-aspect.component.html',
    styleUrls: ['./lib-card-generic-crud-config-aspect.component.scss']
})
export class LibCardGenericCrudConfigAspectComponent extends CardConfigBaseComponent implements OnInit {

    ngOnInit(): void {
        if (this.config["DataTableConfig"] == null) {
            this.config["DataTableConfig"] = {
                compactMode: false,
                cellBorder: true,
                hover: true,
                orderColumn: true,
                rowBorder: false,
                stripe: true,
                nowrap: false,
                defaultRowNumberOnPage: 10
            };
        }
    }

}
