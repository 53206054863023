import { Component, Input } from '@angular/core';

@Component({
    template: ''
})
export class CardConfigBaseComponent {

    @Input() id: number = 0;
    @Input() config: { [key: string]: any } = {};

}