<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <div class="tab-panel" id="configData" role="tabpanel" aria-labelledby="configData" *ngIf="this.config['DataSource']">
        <label><lib-translate [key]="'lib-data-input-select-data'"></lib-translate></label> <br />
        <ng-select #procedureSelect [items]="labeledControllerList" [(ngModel)]="this.config['DataSource']['ProcedureName']" bindLabel="label" bindValue="name" (change)="ProcedureChanged($event)" [searchable]="true" [clearable]="false"></ng-select>
        <div class="container-parameters">
          <lib-data-input-parameters #inputParameters [config]="this.config"
                                    [displayStoredProcedure]="this.displayStoredProcedure"
                                    [allowRawBinding]="this.allowRawBinding"
                                    [allowCardBinding]="this.allowCardBinding"
                                    [allowPageBinding]="this.allowPageBinding"
                                    [listValuesPropertyDefinitions]="listValuesPropertyDefinitions">
          </lib-data-input-parameters>
        </div>
      </div>
    </div>
  </div>
</div>
