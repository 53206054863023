import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LibColumnSelectorModule } from '../lib-column-selector/lib-column-selector.module';
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibFileDepositConfigComponent } from './lib-file-deposit-config.component';

@NgModule({
    declarations: [
        LibFileDepositConfigComponent
    ],
    imports: [
        NgSelectModule,
        CommonModule,
        FormsModule,
        LibHerdiaAppTranslateModule,
        LibColumnSelectorModule
    ],
    exports: [
        LibFileDepositConfigComponent
    ]
})
export class LibFileDepositConfigModule { }
