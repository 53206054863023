import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  ColumnSetting, HerdiaDatatableGlobalConfiguration, LibHerdiaAppTranslateService, LibHerdiaDatatableComponent,
  ServerSideResponse
} from '@herdia-common';
import { AngularEditorConfig } from '@kolkov/angular-editor/public-api';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HAAddUserEmailTemplateRequest, HASendEmailForTestTemplateRequest, HAUpdateEmailTemplateRequest, IDDatatableEventType } from '../shared/models/interfaces';
import { UserEmailTemplateService } from '../shared/service/user.email.template.service';

@Component({
  selector: 'app-user-email-manager',
  templateUrl: './user-email-manager.component.html',
  styleUrls: ['./user-email-manager.component.scss']
})
export class UserEmailManagerComponent implements OnInit, AfterViewInit, OnDestroy {

  cardId: number = 999999993;

  @ViewChild('modal') modal!: TemplateRef<any>;
  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;

    herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
        compactMode: false,
        cellBorder: true,
        hover: true,
        orderColumn: true,
        rowBorder: false,
        stripe: true,
        nowrap: false,
        defaultRowNumberOnPage: 10,
        autoWidth: false,
        fixedLeftColumns: 0
    };

  //used to delete the template + refresh Dt
  DeleteEmailTemplate = (data: any): void => {
    this.UserEmailTemplate.DeleteUserEmailTemplate(data.Id)?.subscribe((response: any) => {
      if (response == true) {
        this.herdiaDatatable.reload();
      }
    })
  }

  // used to edit the template
  EditEmailTemplate = (data: any): void => {
    this.OpenModalEditorEmailTemplate(data);
  }
  //send mail for testing the template
  SendEmailForTestTemplate = (data: any): void => {
    const requestParam: HASendEmailForTestTemplateRequest = {
      IdEmailTemplate: data.Id
    }
    this.UserEmailTemplate.SendEmailForTestTemplate(requestParam)?.subscribe((response: any) => {
      console.log(response);
    })
  }

  columnSettings: ColumnSetting[] = [
    {
      name: "FileName",
      label: "Nom de fichier",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "DateUpload",
      label: "Date upload",
      type: "string",
      visible: true,
      horizontalAlign: 'left',
      displaySum: false,
      actionColumnDef: null,
      nbDecimal: 0,
      nature: "normal",
      width: 200
    },
    {
      name: "Nature",
      label: "Nature",
      type: "string",
      visible: true,
      horizontalAlign: "left",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    {
      name: "Actions",
      label: "Actions",
      type: "custom",
      visible: true,
      actionColumnDef: {
        align: "center",
        buttons: [
          {
            type: "button",
            icon: "trash",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.DeleteEmailTemplate
          },
          {
            type: "button",
            icon: "pen-to-square",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.EditEmailTemplate
          },
          {
            type: "button",
            icon: "envelope",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.SendEmailForTestTemplate
          }
        ]
      },
      displaySum: false,
      nbDecimal: 0,
      nature: "normal",
      width: 100,
      horizontalAlign: "center"
    }
  ];

  //htmlEditor
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    minHeight: '20rem',
    maxHeight: '20rem',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    toolbarHiddenButtons: [['insertVideo']], //,['toggleEditorMode']
    customClasses: [
      {
        name: "Main Color",
        class: "mainColor",
      },
      {
        name: "Secondary Color",
        class: "secondaryColor",
      },
    ]
  };

  modalRef!: NgbModalRef;
  htmlContent!: string;
  VariableEmailTemplate!: any;
  TemplateSelected: any;
  AddEmailContext: boolean = false;
  EmailTemplateName: string = "";


  constructor(private UserEmailTemplate: UserEmailTemplateService, private modalService: NgbModal, private translateService: LibHerdiaAppTranslateService) {

  }

  ngOnInit(): void {
  }

  getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
    return this.UserEmailTemplate.GetAllUserEmailTemplate(dataTablesParameters);
  }
  ngAfterViewInit(): void {
    //get a description of the variables usable in the template
    this.UserEmailTemplate.GetDescriptionVariablesTemplates().subscribe((response: any) => {
      this.VariableEmailTemplate = response;
      //applies traduction
      for (const key in this.VariableEmailTemplate) {
        this.VariableEmailTemplate[key] = this.translateService.get(this.VariableEmailTemplate[key]);
      }
    });
  }

  OpenModal(): void {
    this.modalRef = this.modalService.open(this.modal, { size: 'xl' });
  }
  closeModal() {
    this.modalRef.close();
  }

  //open modal and get the content html of the current template selected
  OpenModalEditorEmailTemplate(data: any): void {
    this.AddEmailContext = false;
    this.EmailTemplateName = data.FileName;
    this.TemplateSelected = data;

    this.UserEmailTemplate.GetHtmlPartiel(data.Id).subscribe((response: any) => {
      this.htmlContent = response.Content;
    });
    this.OpenModal();
  }

  //open modal and reset all variable used for update a template
  OpenModalAddEmailTemplate(): void {
    this.EmailTemplateName = "";
    this.htmlContent = "";
    this.AddEmailContext = true;
    this.OpenModal();
  }

  //function call when we click on btn validate in the modal
  UpdateOrAddEmailTemplate(): void {
    if (this.AddEmailContext == true) {
      this.AddEmailTempalte();
    }
    else {
      this.UpdateEmailTemplate();
    }
  }

  //update email template + refresh datatable
  UpdateEmailTemplate(): void {
    const requestParam: HAUpdateEmailTemplateRequest = {
      TemplateId: this.TemplateSelected.Id,
      TemplateName: this.EmailTemplateName,
      TemplateNewContent: this.htmlContent
    }
    this.UserEmailTemplate.UpdateUserEmailTemplate(requestParam)?.subscribe((response: any) => {
      if (response == true) {
        // this.dtTrigger.next(this.dtOptions);
      }
      this.modalRef.close();
    })
  }

  //Add template with the name + content of the file
  AddEmailTempalte(): void {
    const requestParam: HAAddUserEmailTemplateRequest = {
      NameEmailTemplate: this.EmailTemplateName,
      ContentEmailTemplate: this.htmlContent
    }

    this.UserEmailTemplate.AddUserEmailTemplate(requestParam)?.subscribe((response: any) => {
      if (response == true) {
        this.herdiaDatatable.reload();
      }
      this.modalRef.close();
    })

  }



  //used to catch which action user want
  onActionEvent(event: IDDatatableEventType): void {
    console.log(`Event '${event.cmd}' with data '${JSON.stringify(event.data)}`);
    if (event.cmd == "edit") {
      this.OpenModalEditorEmailTemplate(event.data);
    }

    if (event.cmd == "delete") {
      this.DeleteEmailTemplate(event.data.Id);
    }

    if (event.cmd == "sendTestMail") {
      this.SendEmailForTestTemplate(event.data.Id);
    }
  }

  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }
}
