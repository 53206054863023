import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LibHttpClientApiService } from '@herdia-common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCardRequest, AddPredefinedCardRequest, HAPageCardDefinedConfiguration } from 'src/app/core/shared/models/interfaces';
import { CardUrls } from 'src/app/core/shared/service/api.service';

@Component({
    selector: 'add-predefined-card-modal',
    templateUrl: './add-predefined-card-modal.component.html',
    styleUrls: ['./add-predefined-card-modal.component.scss']
})
export class AddPredefinedCardModalComponent implements OnInit {

    @Input() maxWidth = 12;
    @Output() cardAdded = new EventEmitter();
    cardBase: AddPredefinedCardRequest = {
        predefinedCardWidth: 12,
        pageRowId: 0,
        predefinedCardId: 0
    };
    availablePredefinedCards: HAPageCardDefinedConfiguration[] = [];
    selectedPredefinedCard!: HAPageCardDefinedConfiguration;

    constructor(
        private apiService: LibHttpClientApiService,
        private activeModal: NgbActiveModal
    ) { }

    dismiss() {
        return this.activeModal.dismiss();
    }

    ngOnInit(): void {
        this.apiService.get(CardUrls.GET_PREDEFINED_CARDS, {})?.subscribe((predefinedCards: any) => {
            this.availablePredefinedCards = predefinedCards;
        });
    }

    saveCard(): void {
        this.apiService.post(CardUrls.ADD_PREDEFINED_CARD, this.cardBase)?.subscribe(() => {
            this.cardAdded.emit();
            this.activeModal.close();
        })
    }
}
