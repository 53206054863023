import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerSideResponse } from '../lib-herdia-datatable/lib-herdia-datatable.interfaces';
import { LibHttpClientApiService } from '../lib-http-client-api/lib-http-client-api.service';
import { CreateOrUpdateJobRequest, DeleteJobResponse, GetAllJobsRequest, RunJobResponse } from './lib-scheduler-service.interfaces';
import { Job, JobAssociatedTrigger } from './lib-scheduler-service.types';


export enum TaskSchedulerCRUDUrls {
    GET_JOBS = 'api/TaskScheduler/GetJobs',
    GET_JOB = 'api/TaskScheduler/GetJob',
    DELETE = 'api/TaskScheduler/Delete',
    CREATE_UPDATE = 'api/TaskScheduler/CreateOrUpdate',
    GET_CLASS_JOBS = 'api/TaskScheduler/GetAllClassJobs',
    RUN_JOB = 'api/TaskScheduler/RunJob'
}

@Injectable({
    providedIn: 'root'
})
export class LibSchedulerService {
    constructor(private apiSvc: LibHttpClientApiService) { }

    getJobs(): Observable<Job[]> {
        return this.apiSvc.get<Job[]>(TaskSchedulerCRUDUrls.GET_JOBS);
    }

    getJob(jobName: string, timeZone: string): Observable<JobAssociatedTrigger> {
        return this.apiSvc.get<JobAssociatedTrigger>(TaskSchedulerCRUDUrls.GET_JOB, { jobName: jobName, timeZone: timeZone });
    }

    deleteJob(jobName: string): Observable<DeleteJobResponse> | null {
        let job = { JobName: jobName }
        return this.apiSvc.delete<any, DeleteJobResponse>(TaskSchedulerCRUDUrls.DELETE, job);
    }

    getAll(param: GetAllJobsRequest) {
        return this.apiSvc.post<GetAllJobsRequest, ServerSideResponse>(TaskSchedulerCRUDUrls.GET_JOBS, param);
    }

    createOrUpdateJob(job: CreateOrUpdateJobRequest): Observable<Job> | null {
        return this.apiSvc.post<CreateOrUpdateJobRequest, Job>(TaskSchedulerCRUDUrls.CREATE_UPDATE, job);
    }

    getAllClassJobs() {
        return this.apiSvc.get(TaskSchedulerCRUDUrls.GET_CLASS_JOBS);
    }

    runJob(jobName: string): Observable<RunJobResponse> | null {
        return this.apiSvc.get<RunJobResponse>(TaskSchedulerCRUDUrls.RUN_JOB + "/" + jobName);
    }
}
