export enum FileDeposit {
  GET_ALL_FILE_DEPOSIT = "api/Ged/GetAllFileDeposit",
  EDIT_FILE_DEPOSIT = "api/Ged/UpdateFileDeposit",
  REMOVE_FILE_DEPOSIT = "api/Ged/DeleteFileDeposit/",
  ADD_FILE_DEPSOIT = "api/Ged/AddFileDeposit",
  FILL_FILE_INFORMATION_BY_TYPE = "api/Ged/FillFileInformationByType/",
  GET_DATATABLE_SPECIFIQUE_INFO_BY_TYPE = "api/Ged/GetDatatableSpecificInformationByType",
  GET_SPECIFIC_INFO_BY_TYPE = "api/Ged/GetSpecificInformationByType/", 
  GET_DOCUMENT_VIEWER_BY_TYPE = "api/Ged/GetDocumentViewerByType/",
  GET_ALL_FILE_DEPOSIT_ACTIVE = "api/Ged/GetAllFileDepositActive"
}
