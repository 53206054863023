import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { PageService } from '../service/page.service';
import { LibUserService } from '@herdia-common';

@Injectable({
    providedIn: 'root'
})
export class RolesGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: LibUserService,
        private pageService: PageService
    ) { }

    //this guard exist for verify if one of roles of user can view the page 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const user = this.userService.user;
        const adminUrls = ['/user', '/role', '/content-manager', '/card-theme-designer', '/translate-manager', '/task-scheduler-manager', '/uploader-manager', '/email-manager', '/dbconnection-manager', '/user-email-manager', '/filedeposit-manager'];
        
        if (user) {
            //Check if user is super admin
            if (user.Email == 'admin@herdia.fr')
                return true

            //Get current user id
            let idUser = user.Id;

            //Check if the page is part of the administration panel 
            if (adminUrls.includes(state.url)) {
                this.userService.getAllRole(idUser).subscribe((currentUserRoles) => {
                    if (currentUserRoles.find(adminRole => adminRole.Name == 'Admin'))
                        return true
                    else {
                        this.router.navigate(['unauthorized-page']);
                        return false
                    }
                })
            }
            //If the page isn't part of the administration panel
            else {
                let idPage = parseInt(state.url.split('=')[1]);
                combineLatest(this.userService.getAllRole(idUser), this.pageService.getPage(idPage)).subscribe(([roles, page]) => {
                    const pageRoles = page.Roles 
                    //Check if user has the roles to check the page
                    if (pageRoles && roles) {
                        for (let role of roles)
                            for (let pageRole of pageRoles)
                                if (role.Id == pageRole.Id)
                                    return true
                    }
                    this.router.navigate(['unauthorized-page']);
                    return false;
                })
            }
            return true
        }
        else {
            this.router.navigate(['unauthorized-page']);
            return false;
        }
    }
}
