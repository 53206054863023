import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent } from '../../dynamic-components/card-config-base-component';

@Component({
    selector: 'lib-card-generic-crud-footer',
    templateUrl: './lib-card-generic-crud-footer.component.html',
    styleUrls: ['./lib-card-generic-crud-footer.component.scss']
})
export class LibCardGenericCrudFooterComponent extends CardConfigBaseComponent implements OnInit {
  cardId!: number;

  ngOnInit(): void {
    this.cardId = this.config['cardId'];
  }

}
