import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LibDynamicComponentsEventBusService, LibHerdiaAppTranslateService, LibHttpClientApiService, LibUserService, PropertyDefinition } from '@herdia-common';
import { HACategory, HaPage, HaPageCategory, PageManagement, PageRole, RegistrationResponse, Role } from './core/shared/models/interfaces';
import { AccountService } from './core/shared/service/account.service';
import { PageUrls } from './core/shared/service/api.service';
import { AppService } from './core/shared/service/app.service';
import { CachingService } from './core/shared/service/caching.service';
import { CategoryService } from './core/shared/service/category.service';
import { PageService } from './core/shared/service/page.service';
import { RoleService } from './core/shared/service/role.service';
import { SignalrService } from './core/shared/service/signalr.service';
import { ToastService } from './core/shared/service/toast-notification.service';
import { DOCUMENT } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  currentPageId: number = 0;
  user: RegistrationResponse | null = null;
  isLoggedIn = false;
  isCachingLoading = false;
  pageMana: PageManagement = { Categories: [], Pages: [] };
  sideBarCollpsed = false;
  editMode = false;
  hasRightPanel = false;
  applicationName = "";
  applicationIcon = new Image();
  applicationBackgroundLogin = new Image();
  userRoles: Role[] = [];
  PagesRoles: PageRole[] = [];
  applicationRightPanelPackageName!: string;
  applicationUserProperties!: PropertyDefinition[];
  translationAdmin = "";
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appSvc: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private accountSvc: AccountService,
    private cachingSvc: CachingService,
    private apiSvc: LibHttpClientApiService,
    private pageSvc: PageService,
    private categorySrv: CategoryService,
    private translateService: LibHerdiaAppTranslateService,
    private signalRService: SignalrService,
    private toastService: ToastService,
    private userService: LibUserService,
    private titleService: Title,
    private roleSvc: RoleService,
    private applicationEventBus: LibDynamicComponentsEventBusService,
    private translatePipe: TranslatePipe,
  )
  {
    this.apiSvc.updateSwaggerDefinition();
    this.subscribeToUserChanges();
    this.initializeTranslationService();
    this.subscribeToCachingLoading();
    this.subscribeToPageEvents();
    this.subscribeToSignalR();
    this.subscribeToEditMode();
    this.subscribeToRightPanel();
    this.subscribeToApplicationProperties();
    this.subscribeToApplicationName();
    this.subscribeToApplicationIcons();
    this.subscribeToBackgroundLogin();
    this.subscribeToRightPanelPackageName();
  }

  private initializeTranslationService() {
    const defaultLocale = this.user ? this.user.LanguageCode : navigator.language;

    this.translateService.setDefaultLang(defaultLocale);
    this.translateService.use(defaultLocale);
  }

  private subscribeToUserChanges() {
    this.accountSvc.userSub.pipe(
      takeUntil(this.destroy$)
    ).subscribe(u => {
      this.user = u;
      this.isLoggedIn = !!this.user;
      if (this.isLoggedIn) {
        this.getCategories();
        this.userService.user = this.user;
      }
    });
  }

  private subscribeToCachingLoading() {
    this.cachingSvc.isLoadingSubject.pipe(takeUntil(this.destroy$)).subscribe(d => {
      this.isCachingLoading = d;
    });
  }

  private subscribeToPageEvents() {
    if (this.isLoggedIn) {
      this.pageSvc.newPageSubject.pipe(takeUntil(this.destroy$)).subscribe(p => this.getCategories());
      this.pageSvc.dropPageSubject.pipe(takeUntil(this.destroy$)).subscribe(p => this.getCategories());
      this.pageSvc.duplicatePageSubject.pipe(takeUntil(this.destroy$)).subscribe(p => this.getCategories());
      this.pageSvc.editPageSubject.pipe(takeUntil(this.destroy$)).subscribe(p => this.getCategories());
      this.categorySrv.newCategorySubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.getCategories();
      });
    }
  }

  private subscribeToSignalR() {
    if (this.isLoggedIn) {
      this.signalRService.startConnection();
      this.signalRService.addToastNotificationListener();
    }
  }

  private subscribeToEditMode() {
    this.appSvc.editModeSub.pipe(takeUntil(this.destroy$)).subscribe(mode => {
      this.editMode = mode;
    });
  }

  private subscribeToRightPanel() {
    this.appSvc.hasRightPanel.pipe(takeUntil(this.destroy$)).subscribe(rp => {
      this.hasRightPanel = rp;
    });
  }

  private subscribeToApplicationProperties() {
    this.appSvc.applicationUserProperties.pipe(takeUntil(this.destroy$)).subscribe(properties => {
      this.applicationUserProperties = properties;
    });
  }

  private subscribeToApplicationName() {
    this.appSvc.applicationName.pipe(takeUntil(this.destroy$)).subscribe(name => {
      this.applicationName = name;
      this.titleService.setTitle(name);
    });
  }

  private subscribeToApplicationIcons() {
    this.appSvc.applicationIconBase64.pipe(takeUntil(this.destroy$)).subscribe(iconBase64 => {
      this.applicationIcon = this.appSvc.base64ToImage(iconBase64);
    });
  }

  private subscribeToBackgroundLogin() {
    this.appSvc.applicationBackgroundBase64.pipe(takeUntil(this.destroy$)).subscribe(backgroundBase64 => {
      this.applicationBackgroundLogin = this.appSvc.base64ToImage(backgroundBase64);
    });
  }

  private subscribeToRightPanelPackageName() {
    this.appSvc.applicationRightPanelPackageName.pipe(takeUntil(this.destroy$)).subscribe(rightPanelPackageName => {
      this.applicationRightPanelPackageName = rightPanelPackageName;
    });
  }

  get getUserService() {
    return this.userService;
  }

  ngOnInit(): void {
    this.router.events.subscribe((ev) => { /* reinitialize cardsubjcts when changing page*/
      if (ev instanceof NavigationEnd) {
        this.applicationEventBus.cardSubjects = {};
        this.route.queryParamMap.subscribe(params => {
          this.currentPageId = Number(params.get('id'));
        });
        if (this.currentPageId == 0) {
          this.currentPageId = 1;
        }
      }
    });

    this.getUserRolesAndPagesRoles();
    this.translationAdmin = this.translatePipe.transform('app-menu-setting-category');
  }

  closeSidebar() { /* Close sidebar (not used)  */
    this.document.body.classList.remove('control-sidebar-slide-open');
  }

  onLogout() {
    if (this.editMode)
      this.appSvc.setEditMode();
    this.accountSvc.logout();
  }

  get showNavigations(): boolean {
    return this.isLoggedIn && !this.isCachingLoading;
  }

  getCategories() {
    this.apiSvc.get<PageManagement>(PageUrls.INDEX).subscribe(r => {
      this.pageMana.Categories = r.Categories;
      this.pageMana.Pages = r.Pages;
    });

    this.getUserRolesAndPagesRoles();
  }

  onAddPage(): void {
    this.pageSvc.openAddModal();
  }

  onAddCategory(): void {
    this.categorySrv.openAddModal();
  }

  getUserRolesAndPagesRoles(): void {
    if (this.user) {
      this.userService.getAllRole(this.user?.Id).subscribe(response => {
        //get all roles of the user
        this.userRoles = response;
      })
      this.roleSvc.GetAllPagesWithRoles().subscribe(response => {
        //get all roles for all pages
        this.PagesRoles = response;
      })
    }
  }

  checkIfUserCanSeePage(page: HaPage): boolean {
    //check if the user is 'super admin'
    if (this.user?.Email == 'admin@herdia.fr')
      return true;

    //get page id and user id
    let idPage = page.Id
    let idUser = this.user?.Id;
    if (idUser) {
      let relatedPage = this.PagesRoles.find(elem => elem.IdPage == idPage);

      //compare the 2 arrays to see if at least one id exists in the other array
      if (this.userRoles && relatedPage) {
        for (let rolePage of relatedPage.Roles) {
          for (let roleUser of this.userRoles) {
            if (rolePage.Id == roleUser.Id) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  checkIfAllPagesHaveRole(category: HaPageCategory): boolean {
    const categoryPages = this.pageMana.Pages.filter(page => category.Id === page.HAPageCategoryId);
    return categoryPages.length === 0 || categoryPages.every(page => this.checkIfUserCanSeePage(page));
  }



  onExportPage(): void {
    this.pageSvc.openExportModal();
  }

  onViewUserProfile = (user: RegistrationResponse | null): void => {
    if (user)
      this.router.navigate(['user', 'profile', user.Id]);
  }

  onDeletePage(pageId: number): void {
    this.pageSvc.remove({
      id: pageId
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
