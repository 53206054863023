import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { AngularResizeEventModule } from 'angular-resize-event';
import { DynamicComponentsBaseModule } from '../dynamic-components/dynamic-components-base-module';
import { DynamicComponentDeclaration, DynamicComponentPackage } from '../dynamic-components/dynamic-components.model';
import { LibCardHeaderCommonButtonsModule } from '../lib-card-header-common-buttons/lib-card-header-common-buttons.module';
import { LibColumnSelectorModule } from '../lib-column-selector/lib-column-selector.module';
import { LibDatatablePaginationModule } from '../lib-datatable-pagination/lib-datatable-pagination.module';
import { LibFileDepositConfigModule } from '../lib-file-deposit-config/lib-file-deposit-config.module';
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibHerdiaDatatableModule } from '../lib-herdia-datatable/lib-herdia-datatable.module';
import { LibPropertyTypeInputModule } from '../lib-property-type-input/lib-property-type-input.module';
import { LibCardGenericCrudActionsComponent } from './lib-card-generic-crud-actions/lib-card-generic-crud-actions.component';
import { LibCardGenericCrudConfigAspectComponent } from './lib-card-generic-crud-config-aspect/lib-card-generic-crud-config-aspect.component';
import { LibCardGenericCrudConfigFileDepositComponent } from './lib-card-generic-crud-config-file-deposit/lib-card-generic-crud-config-file-deposit.component';
import { LibCardGenericCrudConfigTableComponent } from './lib-card-generic-crud-config-table/lib-card-generic-crud-config-table.component';
import { LibCardGenericCrudConfigComponent } from './lib-card-generic-crud-config/lib-card-generic-crud-config.component';
import { LibCardGenericCrudFooterComponent } from "./lib-card-generic-crud-footer/lib-card-generic-crud-footer.component";
import { LibCardGenericCrudHeaderButtonsComponent } from "./lib-card-generic-crud-header-buttons/lib-card-generic-crud-header-buttons.component";
import { LibCardGenericCrudComponent } from './lib-card-generic-crud.component';
import { LibCardGenericCRUDService } from './lib-card-generic-crud.service';

@NgModule({
    declarations: [
        LibCardGenericCrudComponent,
        LibCardGenericCrudActionsComponent,
        LibCardGenericCrudHeaderButtonsComponent,
        LibCardGenericCrudFooterComponent,
        LibCardGenericCrudConfigComponent,
        LibCardGenericCrudConfigTableComponent,
        LibCardGenericCrudConfigAspectComponent,
        LibCardGenericCrudConfigFileDepositComponent
    ],
    imports: [
        DataTablesModule,
        CommonModule,
        NgSelectModule,
        FormsModule,
        LibPropertyTypeInputModule,
        LibDatatablePaginationModule,
        NgbModule,
        LibHerdiaAppTranslateModule,
        AngularResizeEventModule,
        LibColumnSelectorModule,
        LibFileDepositConfigModule,
        LibHerdiaDatatableModule,
        LibCardHeaderCommonButtonsModule,
        FontAwesomeModule,
    ],
    exports: [
        LibCardGenericCrudComponent,
        LibCardGenericCrudHeaderButtonsComponent,
        LibCardGenericCrudFooterComponent,
        LibCardGenericCrudConfigComponent,
        LibCardGenericCrudConfigTableComponent,
        LibCardGenericCrudConfigAspectComponent
    ],
    providers: [
        LibCardGenericCRUDService,
        DatePipe
    ]
})
export class LibCardGenericCrudModule extends DynamicComponentsBaseModule {

    override getDynamicComponentsDeclarations(): DynamicComponentDeclaration[] {
        return [
            { component: LibCardGenericCrudComponent, type: 'card' },
            { component: LibCardGenericCrudConfigAspectComponent, type: 'config' },
            { component: LibCardGenericCrudConfigComponent, type: 'config' },
            { component: LibCardGenericCrudConfigFileDepositComponent, type: 'config' },
            { component: LibCardGenericCrudConfigTableComponent, type: 'config' },
            { component: LibCardGenericCrudFooterComponent, type: 'default' },
            { component: LibCardGenericCrudHeaderButtonsComponent, type: 'default' },
        ]
    }

    override getPackageName(): DynamicComponentPackage {
        return 'common';
    }

}
