<div class="modal-header">
  <h4 class="modal-title">{{this.title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <ng-container *ngFor="let property of itemDefinition.Properties">
    <lib-property-type-input *ngIf="!isPropertyKey(property)"
                              [showLabel]="true"
                              [propertyLabel]="property.Name"
                              [propertyDefinition]="property"
                              (change)="onPropertyUpdated(property.Name, $event)">
    </lib-property-type-input>
  </ng-container>
</div>
<div class="modal-footer">
  <button *ngIf="showDelete" type="button" class='btn btn-danger mr-auto' (click)="onDelete()"><fa-icon icon="trash"></fa-icon></button>
  <button *ngIf="context == 'update'" type="button" class="btn btn-primary" (click)="onValidateUpdate()">Valider</button>
  <button *ngIf="context == 'add'" type="button" class="btn btn-primary" (click)="onValidateAdd()">Valider</button>
  <button type="button" class="btn btn-secondary" (click)="dismiss()">Fermer</button>
</div>
