import { FormGroup } from "@angular/forms";

export class FormComponentBase {

  submitted = false;

  getError(form: FormGroup, controlName: string, errorKey: string) {
    if (!form)
      return false;

    const error = form.controls[controlName]?.errors;
    if (error)
      return error[errorKey];
    return false;
  }

  
}

export class Job {
  Name!: string;
  Group!: string;
  Description!: string;
  JobType!: string;
  JobDataMap!: JobDataMap;
  PreviousFireTime!: Date;
  NextFireTime!: Date;
  Trigger!: Trigger;
}

export class JobDataMap {
  Creator!: string;
}

export class Trigger {
  CronExpressionString!: string;
  Name!: string;
  Group!: string;
  Description!: string;
}

export class JobAssociatedTrigger {
  job!: Job;
  trigger!: Trigger;
}
