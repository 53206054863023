import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '../shared/service/toast-notification.service';

@Component({
  selector: 'toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' }
})
export class ToastNotificationComponent implements OnInit {

  constructor(private toastService: ToastService) {}

  get getToastService() {
    return this.toastService;
  }
  
	isTemplate(toast: any) {
		return toast.textOrTpl instanceof TemplateRef;
	}

  ngOnInit(): void {
  }
}
