import { Component, ElementRef, Input, OnInit, inject, OnDestroy } from '@angular/core';
import { CardConfigBaseComponent, ICardConfig, LibDynamicComponentsEventBusService, TreeNode } from '@herdia-common';
import { ResizedEvent } from 'angular-resize-event';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { LibPanelRightService } from '../../lib-panel-right/lib-panel-right.service';
import { Chart, LibCardChartService } from '../lib-card-chart.service';

@Component({
  selector: 'lib-card-chart',
  templateUrl: './lib-card-chart.component.html',
  styleUrls: ['./lib-card-chart.component.scss']
})
export class LibCardChartComponent extends CardConfigBaseComponent implements OnInit, OnDestroy, ICardConfig {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  cardId!: number;

  // variables from interface ICardConfig, required for the card to work
  allowFullScreen: boolean = true;
  allowMobileView: boolean = false;
  hasFooter: boolean = true;
  hasHeader: boolean = true;
  hasButton: boolean = true;
  isConfigurable: boolean = true;
  minWidth: number = 3;
  cardCustomClasses: string = "";
  configTreeNode: TreeNode[] = [
    {
      key: 'lib-card-chart-config',
      label: 'lbl-chart-config',
      children: []
    },
    {
      key: 'lib-card-chart-config-data-source',
      label: 'lbl-chart-config-data-source',
      children: []
    }
  ];
  //

  constructor(private svc: LibCardChartService, private libFilterService: LibPanelRightService, private element: ElementRef) {
    super();
  }

  imageSrc?: string;
  widthUpdate = new Subject<number>();
  imageLoaded = false;


  onImageLoad() {
    this.imageLoaded = true;
  }

  GetUrlChart() {
    this.imageLoaded = false;
    this.config["Filters"] = this.libFilterService.getBindableValues();
    //this.config["CardFilters"] = this.libFilterCardService.getBindableValues();
    return this.svc.post<any, string>(Chart.POST_CHART_CONF, this.config)?.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.imageSrc = this.svc.getChartUrl() + response;
      this.onImageLoad();
    })
  }

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.cardId = this.config['cardId'];

    this.eventBusService.subjectAdded.pipe(takeUntil(this.destroy$)).subscribe((subjectName: any) => {
      if (subjectName === "CardFilterChange_" + this.cardId) {
        this.imageLoaded = false;
        this.eventBusService.cardSubjects["CardFilterChange_" + this.cardId]?.pipe(takeUntil(this.destroy$)).subscribe({
          next: ((parameterList: { parameterName: string, parameterValue: any }[]) => {
            if (!this.config["CardFilters"])
              this.config["CardFilters"] = {};
            parameterList.forEach(parameter => {
              if (!this.config["CardFilters"][parameter.parameterName])
                this.config["CardFilters"][parameter.parameterName] = "";
              this.config["CardFilters"][parameter.parameterName] = parameter.parameterValue;
            });
            this.GetUrlChart();
          })
        });
      }
    });

    if (!this.config['ChartConfig'])
      this.config['ChartConfig'] = {};

    this.config['ChartConfig'].Width = this.element.nativeElement.offsetWidth;
    if (this.config["DataSource"] != undefined && Array.isArray(this.config["DataSource"]["Parameters"])) {
      this.libFilterService.FiltersChange.pipe(takeUntil(this.destroy$)).subscribe((a: any) => {
        this.GetUrlChart();
      });
    }

    this.widthUpdate.pipe(debounceTime(500), distinctUntilChanged()).pipe(takeUntil(this.destroy$)).subscribe(v => {
      this.config['ChartConfig'].Width = Math.floor(v);
      this.GetUrlChart();
    });

    this.GetUrlChart();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onResized(event: ResizedEvent): void {
    this.widthUpdate.next(event.newRect.width);
  }
}
