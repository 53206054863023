export class Job {
  Name!: string;
  Group!: string;
  Description!: string;
  JobType!: string;
  JobDataMap!: JobDataMap;
  PreviousFireTime!: Date;
  NextFireTime!: Date;
  Trigger!: Trigger;
  JobClass!: string;
}

export class JobDataMap {
  Creator!: string;
}

export class Trigger {
  CronExpressionString!: string;
  Name!: string;
  Group!: string;
  Description!: string;
}

export class JobAssociatedTrigger {
  job!: Job;
  trigger!: Trigger;
}
