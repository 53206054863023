export enum Urls {
  GET_PORTFOLIOS = "api/alhambra/GetBuilding/GetPortfolios",
  GET_BUILDINGS = 'api/alhambra/GetBuilding/GetBuildings',
  GET_SPECIFIC_PORTFOLIO_BUILDINGS = 'api/alhambra/GetBuilding/GetSpecificBuildingsWithPortfolio'
}

export interface GetPortfoliosResponse {
  PortfolioCode: string,
  FrPortfolioLabel: string,
  EnPortfolioLabel: string,
  DePortfolioLabel: string,
  ImportDate: string
}

export interface GetBuildingsResponse {
  IdBuilding: number,
  BuildingName: string,
  PortfolioCode: string,
  FrPortfolioLabel: string,
  EnPortfolioLabel: string,
  DePortfolioLabel: string,
  ImportDate: string
}
