import { Component, OnChanges, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ICardConfig, TreeNode } from "../../lib-interface-card/lib-interface-card.interfaces";
import { LibCardHtmlEditorService } from '../lib-card-html-editor.service';
import { CardBaseComponent } from '../../dynamic-components/card-base-component';

//used for keep the style css of "htmlContent" cause "<div [innerHTML]="htmlContent><div>" remove the style css
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value: any) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Component({
    selector: 'lib-card-html-editor',
    templateUrl: './lib-card-html-editor.component.html',
    styleUrls: ['./lib-card-html-editor.component.scss']
})
export class LibCardHtmlEditorComponent extends CardBaseComponent implements OnChanges, ICardConfig {

    htmlContent: string = "";

    // variables from interface ICardConfig, required for the card to work
    allowFullScreen: boolean = true;
    allowMobileView: boolean = false;
    hasFooter: boolean = false;
    hasHeader: boolean = true;
    hasButton: boolean = true;
    isConfigurable: boolean = true;
    minWidth: number = 4;
    cardCustomClasses: string = "";
    configTreeNode: TreeNode[] = [
        {
            key: 'lib-card-html-editor-config',
            label: 'lbl-html-editor-config',
            children: []
        }
    ];

    constructor(private serviceHtmlEdit: LibCardHtmlEditorService) {
        super();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.setHtmlContent();
    }

    private setHtmlContent() {
        //get content existed from db
        var lang: string = localStorage.getItem("userLocale") as string;
        this.serviceHtmlEdit.GetHtmlPartiel(this.id, 'fr').subscribe((res: any) => { //lang
            if (res.response === null) {
              // if it's a predefined card, we process it
              if (this.config["cardId"]) {
                this.serviceHtmlEdit.GetHtmlPartiel(this.config["cardId"], 'fr').subscribe((res: any) => { //lang
                  if (res.response === null) {
                    this.htmlContent = "";
                  }
                  else {
                    this.htmlContent = res.response.Content;
                  }

                });
              }
              else {
                this.htmlContent = "";
              }
            }
            else {
                this.htmlContent = res.response.Content;
            }
        });
    }

}
