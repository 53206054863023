<div class="d-inline flex">
  <div class="sidebar" style="margin-top: 0;">
    <ngb-accordion class="scrollBar">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-import-date'"></lib-translate><span class="badge badge-info filter-badge">{{getImportDateBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableImportDates" [(ngModel)]="getPanelService.selectedImportDate" bindLabel="importDateLabel" bindValue="importDate" (change)="onImportDateChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availablePortfolios.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-portfolio'"></lib-translate><span class="badge badge-info filter-badge">{{getPortfolioBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availablePortfolios"
                     [(ngModel)]="getPanelService.selectedPortfolio"
                     bindLabel="portfolio_label"
                     bindValue="portfolio_code"
                     (change)="onPortfolioChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableFunds.length">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-funds'"></lib-translate><span class="badge badge-info filter-badge">{{getFundsBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableFunds"
                     [(ngModel)]="getPanelService.selectedFund"
                     bindLabel="EXTERNAL_FUND"
                     bindValue="EXTERNAL_FUND_CODE"
                     [virtualScroll]="true"
                     (scroll)="onFundsScroll($event.start, $event.end)"
                     (change)="onFundChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableAssetManagers.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-asset-managers'"></lib-translate><span class="badge badge-info filter-badge">{{getAssetManagersBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableAssetManagers"
                     [(ngModel)]="getPanelService.selectedAssetManager"
                     bindLabel="ASSET_MANAGER_NAME"
                     bindValue="ID_ASSET_MANAGER"
                     (change)="onAssetManagerChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableBusinessUnits.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-business-units'"></lib-translate><span class="badge badge-info filter-badge">{{getBusinessUnitsBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableBusinessUnits" [(ngModel)]="getPanelService.selectedBusinessUnit" bindLabel="BUSINESS_UNIT_NAME" bindValue="BUSINESS_UNIT_CODE" (change)="onBusinessUnitChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableFeeCenters.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-fee-centers'"></lib-translate><span class="badge badge-info filter-badge">{{getFeeCenterBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableFeeCenters" [(ngModel)]="getPanelService.selectedFeeCenter" bindLabel="FEE_NAME" bindValue="FEE_CENTER_CODE" (change)="onFeeCenterChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableAgencies.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-agencies'"></lib-translate></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableAgencies" [(ngModel)]="getPanelService.selectedAgency" bindLabel="AGENCY" bindValue="AGENCY_CODE" (change)="onAgencyChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availablePropertyManagers.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-property-managers'"></lib-translate><span class="badge badge-info filter-badge">{{getPropertyManagerBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availablePropertyManagers" [(ngModel)]="getPanelService.selectedPropertyManager" bindLabel="PROP_MANAGER_NAME" bindValue="ID_PROP_MANAGER" (change)="onPropertyManagerChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableBuildings.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-neutralization'"></lib-translate><span class="badge badge-info filter-badge">{{getNeutralizationBadge()}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableNeutralizations" [(ngModel)]="getPanelService.selectedNeutralization" bindLabel="neutralization_label" bindValue="neutralization_code" (change)="onNeutralizationChange()">
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableOwners.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-owners'"></lib-translate><span class="badge badge-info filter-badge">{{getBadge(getPanelService.availableOwners, getPanelService.selectedOwners)}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableOwners" [(ngModel)]="getPanelService.selectedOwners" bindLabel="OWNER_NAME" bindValue="ID_OWNER" [multiple]="true" [closeOnSelect]="false" (change)="onOwnersChange()">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div *ngIf="items.length >= 2">
                <span>{{getPanelService.selectedOwners.length}}&nbsp;<lib-translate [key]="'lbl-owners'"></lib-translate></span>
              </div>
              <div *ngIf="items.length == 1">
                <span>{{getPanelService.selectedOwners.length}}&nbsp;<lib-translate [key]="'lbl-owner'"></lib-translate></span>
              </div>
            </ng-template>
            <ng-template ng-header-tmp>
              <div class="multiSelectButtons">
                <button (click)="selectAllOwners()" class="btn btn-sm btn-primary"><lib-translate [key]="'select-all'"></lib-translate></button>
                <button (click)="unselectAllOwners()" class="btn btn-sm btn-secondary"><lib-translate [key]="'select-none'"></lib-translate></button>
              </div>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.OWNER_NAME}}</div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.OWNER_NAME}}</div>
            </ng-template>
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableGeographicalZones.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-geographical-zone'"></lib-translate><span class="badge badge-info filter-badge">{{getBadge(getPanelService.availableGeographicalZones, getPanelService.selectedGeographicalZone)}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableGeographicalZones" [(ngModel)]="getPanelService.selectedGeographicalZone" bindLabel="GEOGRAPHICAL_ZONE" bindValue="GEOGRAPHICAL_ZONE" [multiple]="true" [closeOnSelect]="false" (change)="onGeographicalZonesChange()">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div *ngIf="items.length >= 2">
                <span>{{getPanelService.selectedGeographicalZone.length}}&nbsp;<lib-translate [key]="'lbl-geo-zones'"></lib-translate></span>
              </div>
              <div *ngIf="items.length == 1">
                <span>{{getPanelService.selectedGeographicalZone.length}}&nbsp;<lib-translate [key]="'lbl-geo-zone'"></lib-translate></span>
              </div>
            </ng-template>
            <ng-template ng-header-tmp>
              <div class="multiSelectButtons">
                <button (click)="selectAllGeoZones()" class="btn btn-sm btn-primary"><lib-translate [key]="'select-all'"></lib-translate></button>
                <button (click)="unselectAllGeoZones()" class="btn btn-sm btn-secondary"><lib-translate [key]="'select-none'"></lib-translate></button>
              </div>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.GEOGRAPHICAL_ZONE}}</div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.GEOGRAPHICAL_ZONE}}</div>
            </ng-template>
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableBuildingNatures.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-building-natures'"></lib-translate><span class="badge badge-info filter-badge">{{getBadge(getPanelService.availableBuildingNatures, getPanelService.selectedBuildingNature)}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableBuildingNatures" [(ngModel)]="getPanelService.selectedBuildingNature" bindLabel="building_nature_label" bindValue="building_nature_code" [multiple]="true" [closeOnSelect]="false" (change)="onBuildingNaturesChange()">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div *ngIf="items.length >= 2">
                <span>{{getPanelService.selectedBuildingNature.length}}&nbsp;<lib-translate [key]="'lbl-building-natures'"></lib-translate></span>
              </div>
              <div *ngIf="items.length == 1">
                <span>{{getPanelService.selectedBuildingNature.length}}&nbsp;<lib-translate [key]="'lbl-building-nature'"></lib-translate></span>
              </div>
            </ng-template>
            <ng-template ng-header-tmp>
              <div class="multiSelectButtons">
                <button (click)="selectAllBuildingNatures()" class="btn btn-sm btn-primary"><lib-translate [key]="'select-all'"></lib-translate></button>
                <button (click)="unselectAllBuildingNatures()" class="btn btn-sm btn-secondary"><lib-translate [key]="'select-none'"></lib-translate></button>
              </div>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.building_nature_label}}</div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.building_nature_label}}</div>
            </ng-template>
          </ng-select>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="getPanelService.availableBuildings.length > 0">
        <ng-template ngbPanelTitle>
          <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-buildings'"></lib-translate><span class="badge badge-info filter-badge">{{getBadge(getPanelService.availableBuildings, getPanelService.selectedBuildings)}}</span></div></span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-select [items]="getPanelService.availableBuildings" [(ngModel)]="getPanelService.selectedBuildings" placeholder="{{'lbl-select-buildings'|translate}}" bindLabel="building_name" bindValue="id_building" [multiple]="true" [closeOnSelect]="false" (change)="onBuildingsChange()">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div *ngIf="items.length >= 2">
                <span>{{getPanelService.selectedBuildings.length}}&nbsp;<lib-translate [key]="'lbl-buildings'"></lib-translate></span>
              </div>
              <div *ngIf="items.length == 1">
                <span>{{getPanelService.selectedBuildings.length}}&nbsp;<lib-translate [key]="'lbl-building'"></lib-translate></span>
              </div>
            </ng-template>
            <ng-template ng-header-tmp>
              <div class="multiSelectButtons">
                <button (click)="selectAllBuildings()" class="btn btn-sm btn-primary"><lib-translate [key]="'select-all'"></lib-translate></button>
                <button (click)="unselectAllBuildings()" class="btn btn-sm btn-secondary"><lib-translate [key]="'select-none'"></lib-translate></button>
              </div>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.building_name}}</div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="ListCheckboxes"><input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.building_name}}</div>
            </ng-template>
          </ng-select>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <br />
  </div>
  <button class="btn btn-outline-primary btnFixed" (click)="onApplyFilters()"><fa-icon icon="filter"></fa-icon>&nbsp;<lib-translate [key]="'lbl-apply'"></lib-translate></button>
</div>
