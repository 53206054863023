import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DynamicComponentDeclaration, DynamicComponentPackage, DynamicComponentsBaseModule } from '@herdia-common';
import { LibHerdiaAppTranslateModule } from '@herdia-common';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularResizeEventModule } from 'angular-resize-event';
import { LibCardChartConfigDataSourceComponent } from './lib-card-chart-config-data-source/lib-card-chart-config-data-source.component';
import { LibCardChartConfigComponent } from './lib-card-chart-config/lib-card-chart-config.component';
import { LibCardChartDefineBarComponent } from './lib-card-chart-define-bar/lib-card-chart-define-bar.component';
import { LibCardChartFooterComponent } from './lib-card-chart-footer/lib-card-chart-footer.component';
import { LibCardChartService } from './lib-card-chart.service';
import { LibCardChartComponent } from './lib-card-chart/lib-card-chart.component';
import { LibChartDefinePieComponent } from './lib-chart-define-pie/lib-chart-define-pie.component';
import { LibProcedureInputModule } from '../lib-procedure-input/lib-procedure-input.module';
import { LibFilterCardModule } from '../lib-filter-card/lib-filter-card.module';
import { LibCardChartHeaderButtonsComponent } from './lib-card-chart-header-buttons/lib-card-chart-header-buttons.component';
import { LibHeaderBindingModule } from '../lib-header-binding/lib-header-binding.module';

@NgModule({
    declarations: [
        LibCardChartConfigComponent,
        LibCardChartComponent,
        LibCardChartConfigDataSourceComponent,
        LibChartDefinePieComponent,
        LibCardChartFooterComponent,
        LibCardChartDefineBarComponent,
        LibCardChartHeaderButtonsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        LibProcedureInputModule,
        LibHerdiaAppTranslateModule,
        NgSelectModule,
        FontAwesomeModule,
        LibFilterCardModule,
        AngularResizeEventModule,
        LibHeaderBindingModule
    ],
    exports: [
        LibCardChartConfigComponent,
        LibCardChartComponent,
        LibCardChartConfigDataSourceComponent,
        LibCardChartConfigComponent,
        LibCardChartFooterComponent,
        LibCardChartHeaderButtonsComponent
    ],
    providers: [
        LibCardChartService
    ]
})
export class LibCardChartModule extends DynamicComponentsBaseModule {

    override getPackageName(): DynamicComponentPackage {
        return 'alhambra';
    }

    override getDynamicComponentsDeclarations(): DynamicComponentDeclaration[] {
        return [
            { component: LibCardChartComponent, type: 'card' },
            { component: LibCardChartConfigComponent, type: 'config' },
            { component: LibCardChartConfigDataSourceComponent, type: 'config' },
            { component: LibCardChartFooterComponent, type: 'default' },
            { component: LibCardChartHeaderButtonsComponent, type: 'default' }
        ]
    }

}
