import { Component, OnChanges, OnDestroy, ViewChild, ViewEncapsulation, inject, OnInit, Input } from '@angular/core';
import { CardConfigBaseComponent, ColumnSetting, HerdiaDatatableGlobalConfiguration, ICardConfig, LibDynamicComponentsEventBusService, LibHerdiaDatatableComponent, ServerSideResponse, TreeNode } from '@herdia-common';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable, filter, Subject, takeUntil } from 'rxjs';
import { LibPanelRightService } from '../lib-panel-right/lib-panel-right.service';
import { ReportColumnSetting } from "./lib-card-report.interfaces";
import { LibCardReportService } from './lib-card-report.service';
@Component({
  selector: 'lib-card-report',
  templateUrl: './lib-card-report.component.html',
  styleUrls: ['./lib-card-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LibCardReportComponent extends CardConfigBaseComponent implements OnInit, OnChanges, OnDestroy, ICardConfig {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  cardId!: number;

  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;
  herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: false,
    defaultRowNumberOnPage: 10,
    autoWidth: false,
    fixedLeftColumns: 1
  };
  constructor(private cardReportService: LibCardReportService, private translatePipe: TranslatePipe, private libFilterService: LibPanelRightService) {
    super();
  }

  allowFullScreen: boolean = true;
  allowMobileView: boolean = false;
  hasFooter: boolean = true;
  hasHeader: boolean = true;
  hasButton: boolean = true;
  isConfigurable: boolean = true;
  minWidth: number = 4;
  cardCustomClasses: string = "";
  configTreeNode: TreeNode[] = [
    {
      key: 'lib-card-report-config-datatable-aspect',
      label: 'lbl-report-config-datatable-aspect',
      children: []
    },
    {
      key: 'lib-card-report-config-parameters',
      label: 'lbl-report-config-parameters',
      children: []
    },
    {
      key: 'lib-card-report-config-report',
      label: 'lbl-report-config-report',
      children: []
    },
    {
      key: 'lib-card-report-config-rich-report',
      label: 'lbl-report-config-rich-report',
      children: []
    },
    {
      key: 'lib-card-report-config-file-deposit',
      label: 'lbl-report-config-file-deposit',
      children: []
    },
    //Always last to avoid bugs with NgbNav for display
    {
      key: 'lib-card-report-config-scheduling-parameters',
      label: 'lbl-report-config-scheduling-parameters',
      children: [
        {
          key: 'lib-card-report-config-scheduling-job',
          label: 'lbl-report-config-scheduling-job',
          children: []
        },
        {
          key: 'lib-card-report-config-scheduling-generate',
          label: 'lbl-report-config-scheduling-generate',
          children: []
        }
      ]
    }
  ];

  columnSetting: ColumnSetting[] = [];

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.cardId = this.config['cardId'];

    this.eventBusService.addSubject("AskExport_Datatable", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["AskExport_Datatable_" + this.cardId].pipe(takeUntil(this.destroy$)).subscribe((exportConfig: any) => {
      this.onAskExport(exportConfig);
    });

    this.libFilterService.FiltersChange.pipe(takeUntil(this.destroy$)).subscribe((a: any) => {
      this.herdiaDatatable.reload();
    });

    this.eventBusService.addSubject("AlertConfigChanged", 0, new Subject<string>());
    this.eventBusService.cardSubjects['AlertConfigChanged_0'].pipe(takeUntil(this.destroy$)).subscribe((subject: string) => {
      this.herdiaDatatable.reload();
    });

    this.eventBusService.addSubject("ParameterDateChangedReport", this.cardId, new Subject<{ parameterName: string, parameterValue: any }[]>());
    this.eventBusService.cardSubjects["ParameterDateChangedReport_" + this.cardId].pipe(takeUntil(this.destroy$)).subscribe({
      next: (value: { parameterName: string, parameterValue: any }[]) => {
        if (value.length > 0)
          this.herdiaDatatable.reload();
      }
    });

    this.eventBusService.addSubject("ImportDateChanged", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["ImportDateChanged_" + this.cardId].pipe(takeUntil(this.destroy$)).subscribe((subject: any) => {
      this.herdiaDatatable.reload();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(): void {
    // Retro compatibility for settings
    if (this.config['WebReport']['ColumnsSettings']?.[0]?.['sqlName']) {
      this.config['WebReport']['ColumnsSettings'] = this.config['WebReport']['ColumnsSettings'].map((c: ReportColumnSetting) => {
        return {
          name: c.sqlName,
          label: this.translatePipe.transform(c.sqlName.toUpperCase()),
          nature: c.nature,
          nbDecimal: c.nbDecimal,
          type: c.csType,
          displaySum: c.additional,
          horizontalAlign: this.cardReportService.getDefaultAlignForType(c.csType),
          visible: true,
          width: c.webColumnWidth
        }
      });
    }
    this.config["Filters"] = this.libFilterService.getBindableValues();
    this.columnSetting = this.config['WebReport']['ColumnsSettings'];

    if (this.config["DataTableConfig"]) {
      this.herdiaDTConfig = this.config["DataTableConfig"];
    }
  }

  getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
    this.config["Filters"] = this.libFilterService.getBindableValues();
    this.config["DataTableRequestParams"] = dataTablesParameters;
    return this.cardReportService.read(this.config).pipe(filter(() => this.libFilterService.FiltersReady));
  }

  onAskExport(exportConfig: any): void {
    this.config["RichReport"]["Filename"] = exportConfig.exportName;
    this.config["RichReport"]["QuoteAllFields"] = exportConfig.QuoteAllFields;
    this.config["RichReport"]["Delimiter"] = exportConfig.Delimiter;
    this.config["RichReport"]["RenderingType"] = exportConfig.exportType;
    this.cardReportService.askReport(this.config)?.pipe(takeUntil(this.destroy$)).subscribe((a: any) => { });
  }
}
