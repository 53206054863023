<div class="modal-header">
  <h4 class="modal-title"><lib-translate [key]="'add-page-modal-title'"></lib-translate></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label><lib-translate [key]="'lbl-icon'"></lib-translate></label>
    <lib-icon-picker (iconSelectedOutput)="onIconPickerSelect($event)"></lib-icon-picker>
    <label *ngIf="emptyIcon" class="text-danger"><lib-translate [key]="'lbl-icon-missing'"></lib-translate></label>
  </div>
  <div class="form-group">
    <label for="pageCategory"><lib-translate [key]="'add-page-modal-page-category'"></lib-translate></label>
    <ng-select [items]="availableCategories" name="pageCategory" [(ngModel)]="selectedCategory" bindValue="Id" bindLabel="Label"></ng-select>
  </div>
  <div class="form-group">
    <label for="pageRoles"><lib-translate [key]="'add-page-modal-page-roles'"></lib-translate></label>
    <ng-select [items]="ListRoles" name="pageRoles" [(ngModel)]="RoleSelected" bindValue="Id" bindLabel="Name" [multiple]="true" [closeOnSelect]="false"></ng-select>
  </div>
  <div class="form-group">
    <label for="pageTitle"><lib-translate [key]="'add-page-modal-page-title'"></lib-translate></label>
    <input type="text" class="form-control" name="pageTitle" placeholder="{{addPageModalPageTitle}}" [(ngModel)]="pageConfig.Title">
  </div>
  <!--<div class="form-group">
    <label for="pageDescription"><lib-translate [key]="'add-page-modal-page-description'"></lib-translate></label>
    <input type="text" class="form-control" name="pageDescription" placeholder="{{addPageModalPageDescription}}" [(ngModel)]="pageConfig.Description">
  </div>-->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark mr-auto" (click)="onImport()"><lib-translate [key]="'app-btn-import-page'"></lib-translate></button>
  <button type="button" class="btn btn-secondary" (click)="dismiss()"><lib-translate [key]="'lbl-close'"></lib-translate></button>
  <button type="button" class="btn btn-primary" (click)="onValidate()"><lib-translate [key]="'lbl-validate'"></lib-translate></button>
</div>
