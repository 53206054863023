import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HerdiaDatatableGlobalConfiguration} from "../lib-herdia-datatable.interfaces";

@Component({
  selector: 'lib-herdia-datatable-config',
  templateUrl: './lib-herdia-datatable-config.component.html',
  styleUrls: ['./lib-herdia-datatable-config.component.scss']
})
export class LibHerdiaDatatableConfigComponent implements OnInit {
  @Input() dtConfig!: HerdiaDatatableGlobalConfiguration;
  @Output() dtConfigChange = new EventEmitter<HerdiaDatatableGlobalConfiguration>();

  linePerPage: any = [
    { id: 10, value: "- 10 -" },
    { id: 15, value: "- 15 -" },
    { id: 20, value: "- 20 -" },
    { id: 25, value: "- 25 -" },
    { id: 30, value: "- 30 -" },
    { id: 35, value: "- 35 -" },
    { id: 40, value: "- 40 -" },
    { id: 50, value: "- 50 -" },
    { id: -1, value: "- All -"}
  ];
  constructor() { }

  ngOnInit(): void {
  }
  onAutoWidthChange(event: any) {
    if (event.target.checked) {
      this.dtConfig.nowrap = false;
    }
  }
  onWrapChange(event: any) {
    if (event.target.checked) {
      this.dtConfig.autoWidth = false;
    }
  }
}
