import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LibCardHtmlEditorService {

  constructor(private http: HttpClient) { }

  GetHtmlPartiel(cardId:number, language:string){
    return this.http.get(`api/HtmlEditor/GetHtmlPartiel/${cardId}/${language}`);
  }
  SaveHtmlPartiel(htmlContent:string, writtenLanguage:string, cardId:number){
    const contentRequest = { LanguageCode: writtenLanguage, CardId: cardId, Content: htmlContent };
    return this.http.post('api/HtmlEditor/SaveHtmlPartiel', contentRequest);
  }
}
