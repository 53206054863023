import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LibEditModeService, LibHerdiaAppEnvironmentService, LibHttpClientApiService, PropertyDefinition } from '@herdia-common';
import { BehaviorSubject, Subject, forkJoin, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EditModeUrls } from '../../../herdia-common/lib-edit-mode/lib-edit-mode.enum';
import { ApplicationFeatures } from '../models/enums';
import { ThemeVariables } from '../models/interfaces';
import { ApplicationSpecificPropertiesUrls } from './api.service';
import { CardThemeDesignerService } from './card-theme-designer.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  editModeId = 'editMode';
  editModeSub = new BehaviorSubject<boolean>(false);
  authToEditSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasRightPanel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  applicationName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  herdiaAppBuildId: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationIconBase64: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationBackgroundBase64: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationDefaultTheme: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationRightPanelPackageName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationUserProperties: BehaviorSubject<PropertyDefinition[]> = new BehaviorSubject<PropertyDefinition[]>([]);
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: LibHttpClientApiService,
    private themeDesignerService: CardThemeDesignerService,
    private environmentService: LibHerdiaAppEnvironmentService,
    private editModeService: LibEditModeService
  ) {
    const editModeStr = localStorage.getItem(this.editModeId);
    this.environmentService.apiBaseUrl = environment.apiBaseUrl;

    if (editModeStr && editModeStr.toLowerCase() === 'true') {
      this.editModeSub.next(true);
    }

    this.document.body.style.display = 'none';

    forkJoin([
      this.apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_BACKGROUND),
      this.apiService.get<boolean>(EditModeUrls.GET_AUTH),
      this.apiService.get<number[]>(ApplicationSpecificPropertiesUrls.GET_FEATURES),
      this.apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_NAME),
      this.apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_RIGHTPANEL_PACKAGE_NAME),
      this.apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_ICON),
      this.apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_HERDIAAPP_API_BUILDID),
      this.apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_DEFAULT_THEME),
      this.apiService.get<PropertyDefinition[]>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_USER_PROPERTIES),
    ]).pipe(
      switchMap(([background, edit, features, appName, rightPanelPackageName, icon, buildId, defaultTheme, userProperties]) => {
        this.applicationBackgroundBase64.next(background);
        this.authToEditSub.next(edit);

        if (features.indexOf(ApplicationFeatures.RightPanel) !== -1) {
          this.hasRightPanel.next(true);
        }

        this.applicationName.next(appName);
        this.applicationRightPanelPackageName.next(rightPanelPackageName);
        this.applicationIconBase64.next(icon);
        this.herdiaAppBuildId.next(buildId);
        this.applicationDefaultTheme.next(defaultTheme);

        const theme: ThemeVariables = {
          PrimaryColor: defaultTheme[0],
          SecondaryColor: defaultTheme[1],
          CustomFontSize: defaultTheme[2],
          LeftNavbarColor: defaultTheme[3],
          TopNavbarColor: defaultTheme[4],
        };

        this.setTheme(theme);

        this.applicationUserProperties.next(userProperties);

        return this.apiService.get<PropertyDefinition[]>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_USER_PROPERTIES);
      })
    ).subscribe(() => {
      this.document.body.style.display = 'block';
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setTheme(Theme: ThemeVariables) {
    const actualTheme = localStorage.getItem('ActualTheme');
    if (actualTheme) {
      const label = actualTheme;
      this.themeDesignerService.showTheme(label).subscribe();
    }
    else {
      this.themeDesignerService.showCustomTheme(Theme);
    }
  }

  setEditMode() {
    this.editModeService.setEditMode();
    this.editModeSub.next(this.editModeService.getEditMode());
  }

  getAuth() {
    this.editModeService.getAuth();
    this.authToEditSub.next(this.editModeService.getAuthToEdit());
  }



  // Create an Image object from a base64-encoded string
  public base64ToImage(base64: string): HTMLImageElement {

    // Create an Image object
    const img = new Image();

    // Set the src attribute of the Image object to the URL of the parameter
    img.src = "data:image/png;base64," + base64;

    return img;
  }
}
