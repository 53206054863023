<div class="form-group">
  <input type="checkbox" name="showLegends" class="form-check-input" [(ngModel)]="config['ChartConfig']['DataSources']['ShowLegends']" />
  <label for="showLegends"><lib-translate [key]="'lib-card-chart-datasource-show-legend'"></lib-translate></label>
</div>
<div class="form-group">
  <input type="checkbox" name="showDataLabels" class="form-check-input" [(ngModel)]="config['ChartConfig']['DataSources']['ShowDataLabels']" />
  <label for="showDataLabels"><lib-translate [key]="'lib-card-chart-datasource-show-data-labels'"></lib-translate></label>
</div>
<div class="form-group" *ngIf="config['ChartConfig']['DataSources']['ShowDataLabels']">
  <label for="dataSourceLabels"><lib-translate [key]="'lib-card-chart-datasource-labels'"></lib-translate></label>
  <ng-select name="dataSourceLabels" [items]="availableNumberDatasources" [(ngModel)]="config['ChartConfig']['DataSources']['Labels']" bindLabel="label" bindValue="key"></ng-select>
</div>
<div class="form-group">
  <input type="checkbox" name="showDataValues" class="form-check-input" [(ngModel)]="config['ChartConfig']['DataSources']['ShowDataValues']" />
  <label for="showDataValues"><lib-translate [key]="'lib-card-chart-datasource-show-data-values'"></lib-translate></label>
</div>

<div class="form-group">
  <button type="button" name="addBar" class="btn btn-primary" (click)="addBar()"><lib-translate [key]="'lib-card-chart-datasource-add-bar'"></lib-translate></button>
  <button *ngIf="bars.length > 0" type="button" name="removeBar" class="btn btn-danger" (click)="removeBar()"><lib-translate [key]="'lib-card-chart-datasource-remove-bar'"></lib-translate></button>
</div>
<div class="form-group" *ngFor="let eachBar of bars">
  <label for="dataSourceDatas"><lib-translate [key]="'lib-card-chart-datasource-datas'"></lib-translate></label>
  <ng-select name="dataSourceDatas" [items]="availableNumberDatasources" bindLabel="label" bindValue="key" (change)="addingData(eachBar, $event)" [ngModel]="this.dataBars[eachBar]"></ng-select>
</div>
<div class="form-group">
  <label for="dataSourcePalette"><lib-translate [key]="'lib-card-chart-datasource-palette'"></lib-translate></label>
  <ng-select name="dataSourcePalette" [items]="availablePalettes" [(ngModel)]="config['ChartConfig']['DataSources']['ColorPalette']" bindValue="value">
    <ng-template ng-label-tmp let-item="item">
      <div>
        <table>
          <tr>
            <td *ngFor="let color of item.palette" style="width:10px; height: 15px; background-color:{{color}}"></td>
          </tr>
        </table>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div>
        <table>
          <tr>
            <td *ngFor="let color of item.palette" style="width:10px; height: 15px; background-color:{{color}}"></td>
          </tr>
        </table>
      </div>
    </ng-template>
  </ng-select>
</div>
