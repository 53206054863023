import { CommonModule, NgIf } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { AngularResizeEventModule } from "angular-resize-event";
import { LibColumnSelectorModule } from '../lib-column-selector/lib-column-selector.module';
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibHerdiaDatatableCellRenderComponent } from './lib-herdia-datatable-cell-render/lib-herdia-datatable-cell-render.component';
import { LibHerdiaDatatableConfigComponent } from './lib-herdia-datatable-config/lib-herdia-datatable-config.component';
import { LibHerdiaDatatableComponent } from './lib-herdia-datatable.component';

@NgModule({
    declarations: [
        LibHerdiaDatatableComponent,
        LibHerdiaDatatableCellRenderComponent,
        LibHerdiaDatatableConfigComponent
    ],
    imports: [
        DataTablesModule,
        CommonModule,
        FormsModule,
        AngularResizeEventModule,
        FontAwesomeModule,
        FontAwesomeModule,
        FormsModule,
        NgIf,
        NgSelectModule,
        LibHerdiaAppTranslateModule,
        LibColumnSelectorModule
    ],
    exports: [
        LibHerdiaDatatableComponent,
        LibHerdiaDatatableConfigComponent
    ]
})
export class LibHerdiaDatatableModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
    }
}
