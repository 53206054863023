import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LibConfirmBoxComponent } from './lib-confirm-box.component';

@Injectable({
  providedIn: 'root'
})
export class LibConfirmBoxService {

  confirmationSub = new Subject<boolean>();

  private modalRef?: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  cancel() {
    if (this.modalRef) {
      this.modalRef.dismiss();
      this.confirmationSub.next(false);
    }
  }

  confirm() {
    if (this.modalRef) {
      this.modalRef.close();
      this.confirmationSub.next(true);
    }
  }

  open(p: { title: string, msg: string, okBtnLbl: string, koBtnLbl: string }) {
    this.modalRef = this.modalService.open(LibConfirmBoxComponent);
    this.modalRef.componentInstance.title = p.title;
    this.modalRef.componentInstance.msg = p.msg;
    this.modalRef.componentInstance.okBtnLbl = p.okBtnLbl;
    this.modalRef.componentInstance.koBtnLbl = p.koBtnLbl;
  }
}
