<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <ng-container *ngIf="displayMinute">
    <li [ngbNavItem]="1">
      <a ngbNavLink><lib-translate [key]="'lbl-minute'"></lib-translate></a>
      <ng-template ngbNavContent>
        <div class="CronEditorMinutesContent">

          <div class="d-flex mt-2">
            <input class="form-check-input" type="radio" id="EveryMinutesRadio" name="MinutesRadiosInputs" [(ngModel)]="CronGeneratorFromMinutesMethodeSelected" [value]="'EveryMinutes'" (change)="CronExpressionMinute()" checked>
            <p class="mx-2"><lib-translate [key]="'lbl-every-minute'"></lib-translate></p>
          </div>

          <div class="d-flex">
            <input class="form-check-input" type="radio" id="SpecificMinutesRadio" name="MinutesRadiosInputs" [(ngModel)]="CronGeneratorFromMinutesMethodeSelected" [value]="'SpecificMinutes'" (change)="CronExpressionMinute()">
            <div class="d-flex">
              <p class="mx-2"><lib-translate [key]="'lbl-every'"></lib-translate></p>
              <ng-select appearance="outline" [(ngModel)]="SpecificMinutesValue" (change)="CronExpressionMinuteNg_Select_Change()">
                <ng-option *ngFor="let number of listOfMinutes" [value]="number.value">{{number.label}}</ng-option>
              </ng-select>
              <p class="mx-2"><lib-translate [key]="'lbl-specific-minutes-radio-text'"></lib-translate> : </p>
              <ng-select appearance="outline" [(ngModel)]="SpecificMinutesValuePart2" (change)="CronExpressionMinuteNg_Select_Change()">
                <ng-option *ngFor="let number of listOfMinutesWith0" [value]="number.value">{{number.label}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ng-container>
  <ng-container *ngIf="displayHours">
    <li [ngbNavItem]="2">
      <a ngbNavLink><lib-translate [key]="'lbl-hours'"></lib-translate></a>
      <ng-template ngbNavContent [hidden]="!displayHours">
        <div class="CronEditorHoursContent">
          <div class="d-flex mt-2">
            <input class="form-check-input" type="radio" id="EveryHoursRadio" name="HoursRadiosInputs" [(ngModel)]="CronGeneratorFromHoursMethodeSelected" [value]="'EveryHours'" (change)="CronExpressionHours()" checked>
            <p class="mx-2"><lib-translate [key]="'lbl-every-hours'"></lib-translate></p>
          </div>
          <div class="d-flex">
            <input class="form-check-input " type="radio" id="SpecificHoursRadio" name="HoursRadiosInputs" [(ngModel)]="CronGeneratorFromHoursMethodeSelected" [value]="'SpecificHours'" (change)="CronExpressionHours()">
            <div class="d-flex">
              <p class="mx-2"><lib-translate [key]="'lbl-every'"></lib-translate></p>
              <ng-select appearance="outline" [(ngModel)]="SpecificHoursValue" (change)="CronExpressionHoursNg_Select_Change()">
                <ng-option *ngFor="let number of listOfHours" [value]="number.value">{{number.label}}</ng-option>
              </ng-select>
              <p class="mx-2"><lib-translate [key]="'lbl-specific-hours-radio-text'"></lib-translate> : </p>
              <ng-select appearance="outline" [(ngModel)]="SpecificHoursValuePart2" (change)="CronExpressionHoursNg_Select_Change()">
                <ng-option *ngFor="let number of listOfHoursWith0" [value]="number.value">{{number.label}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ng-container>
  <li [ngbNavItem]="3">
    <a ngbNavLink><lib-translate [key]="'lbl-days'"></lib-translate></a>
    <ng-template ngbNavContent>
      <div class="CronEditorDaysContent">

        <div class="d-flex mt-2">
          <input class="form-check-input" type="radio" id="EveryDaysRadio" name="DaysRadiosInputs" [(ngModel)]="CronGeneratorFromDaysMethodeSelected" [value]="'EveryDays'" (change)="CronExpressionDays()" checked>
          <p class="mx-2"><lib-translate [key]="'lbl-every-days'"></lib-translate></p>
        </div>

        <div class="d-flex">
          <input class="form-check-input" type="radio" id="SpecificDaysRadio" name="DaysRadiosInputs" [(ngModel)]="CronGeneratorFromDaysMethodeSelected" [value]="'SpecificDays'" (change)="CronExpressionDays()">
          <p class="mx-2"><lib-translate [key]="'lbl-every'"></lib-translate></p>
          <ng-select appearance="outline" [(ngModel)]="SpecificDaysValue" (change)="CronExpressionDaysNg_Select_Change()">
            <ng-option *ngFor="let number of listOfDays" [value]="number.value">{{number.label}}</ng-option>
          </ng-select>
          <p class="mx-2"><lib-translate [key]="'lbl-specific-days-radio-text-part1'"></lib-translate> : </p>
          <ng-select appearance="outline" [(ngModel)]="SpecificDaysValuePart2" (change)="CronExpressionDaysNg_Select_Change()">
            <ng-option *ngFor="let number of listOfDays" [value]="number.value">{{number.label}}</ng-option>
          </ng-select>
          <p class="mx-2"><lib-translate [key]="'lbl-specific-days-radio-text-part2'"></lib-translate></p>

        </div>

        <div class="specificDayOfWeek">
          <div class="d-flex specificDayOfWeekBegin">
            <input class="form-check-input" type="radio" id="SpecificDaysOfTheWeekRadio" name="DaysRadiosInputs" [(ngModel)]="CronGeneratorFromDaysMethodeSelected" [value]="'SpecificDaysOfTheWeek'" (change)="CronExpressionDays()">
            <p class="mx-2"><lib-translate [key]="'lbl-specific-days-of-the-week-radio-text'"></lib-translate></p>
          </div>

          <form class="d-flex pl-5 pb-2">
            <div *ngFor="let day of listofDaysLabel ; first as isFirst" class="form-check mr-2">
              <div *ngIf="isFirst">
                <input class="form-check-input" type="checkbox" id="day.label" name="checkboxDays" [value]="day.value" (change)="getValueCheckboxDay($event)" checked>
                <label class="form-check-label" for="monday">{{day.label}}</label>
              </div>
              <div *ngIf="!isFirst">
                <input class="form-check-input" type="checkbox" id="day.label" name="checkboxDays" [value]="day.value" (change)="getValueCheckboxDay($event)">
                <label class="form-check-label" for="monday">{{day.label}}</label>
              </div>

            </div>
          </form>
        </div>

        <div class="d-flex">
          <input class="form-check-input" type="radio" id="LastDayOfmonthRadio" name="DaysRadiosInputs" [(ngModel)]="CronGeneratorFromDaysMethodeSelected" [value]="'LastDayOfmonth'" (change)="CronExpressionDays()">
          <p class="mx-2"><lib-translate [key]="'lbl-last-day-of-month-radio-text'"></lib-translate></p>
        </div>

        <div class="d-flex">
          <input class="form-check-input" type="radio" id="FirstDayOfmonthRadio" name="DaysRadiosInputs" [(ngModel)]="CronGeneratorFromDaysMethodeSelected" [value]="'FirstDayOfmonth'" (change)="CronExpressionDays()">
          <p class="mx-2"><lib-translate [key]="'lbl-first-day-of-month-radio-text'"></lib-translate></p>
        </div>

        <div class="d-flex">
          <input class="form-check-input" type="radio" id="LastSpecificDayOfmonthRadio" name="DaysRadiosInputs" [(ngModel)]="CronGeneratorFromDaysMethodeSelected" [value]="'LastSpecificDayOfmonth'" (change)="CronExpressionDays()">
          <div class="d-flex">
            <p class="mx-2"><lib-translate [key]="'lbl-last-specific-day-of-month-radio-text-part1'"></lib-translate></p>
            <ng-select appearance="outline" [(ngModel)]="LastSpecificDayOfmonthValue" class="ng-select-Label" (change)="CronExpressionDaysNg_Select_Change()">
              <ng-option *ngFor="let day of listofDaysLabel_number" [value]="day.value">{{day.label}}</ng-option>
            </ng-select>
            <p class="mx-2"><lib-translate [key]="'lbl-last-specific-day-of-month-radio-text-part2'"></lib-translate></p>
          </div>
        </div>

        <div class="d-flex">
          <input class="form-check-input" type="radio" id="LastSpecificNumberDayOfmonthRadio" name="DaysRadiosInputs" [(ngModel)]="CronGeneratorFromDaysMethodeSelected" [value]="'LastSpecificNumberDayOfmonth'" (change)="CronExpressionDays()">
          <div class="d-flex">
            <p class="mx-2"><lib-translate [key]="'lbl-last-specific-number-day-radio-text-part1'"></lib-translate></p>
            <ng-select appearance="outline" [(ngModel)]="LastSpecificNumberDayOfmonthValue" (change)="CronExpressionDaysNg_Select_Change()">
              <ng-option *ngFor="let number of listOfDays" [value]="number.value">{{number.label}}</ng-option>
            </ng-select>
            <p class="mx-2"><lib-translate [key]="'lbl-last-specific-number-day-radio-text-part2'"></lib-translate></p>
          </div>
        </div>

      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink><lib-translate [key]="'lbl-month'"></lib-translate></a>
    <ng-template ngbNavContent>
      <div class="CronEditormonthContent">

        <div class="d-flex mt-2">
          <input class="form-check-input" type="radio" id="EverymonthRadio" name="monthRadiosInputs" [(ngModel)]="CronGeneratorFrommonthMethodeSelected" [value]="'Everymonth'" (change)="CronExpressionmonths()" checked>
          <p class="mx-2"><lib-translate [key]="'lbl-every-month'"></lib-translate></p>
        </div>

        <div class="d-flex">
          <input class="form-check-input" type="radio" id="EverySpecificmonthRadio" name="monthRadiosInputs" [(ngModel)]="CronGeneratorFrommonthMethodeSelected" [value]="'EverySpecificmonth'" (change)="CronExpressionmonths()">
          <div class="d-flex">
            <p class="mx-2"><lib-translate [key]="'lbl-every'"></lib-translate></p>
            <ng-select appearance="outline" [(ngModel)]="EverySpecificmonthValue" (change)="CronExpressionmonthsNg_Select_Change()">
              <ng-option *ngFor="let number of listOfMonth" [value]="number.value">{{number.label}}</ng-option>
            </ng-select>
            <p class="mx-2"><lib-translate [key]="'lbl-every-specific-month-text'"></lib-translate> : </p>
            <ng-select appearance="outline" [(ngModel)]="EverySpecificmonthValuePart2" class="ng-select-Label" (change)="CronExpressionmonthsNg_Select_Change()">
              <ng-option *ngFor="let month of listOfMonthLabel" [value]="month.value">{{month.label}}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
<div class="form-group" *ngIf="context == 'add'">
  <label for="cronExpression"><lib-translate [key]="'lbl-add-edit-modal-job-cron-expression'"></lib-translate></label>
  <input type="text" class="form-control" name="cronExpression" placeholder="{{addEditModalCronExpression}}" [(ngModel)]="finalCronExpression">
</div>
<div class="form-group" *ngIf="context == 'update'">
  <label for="cronExpression"><lib-translate [key]="'lbl-add-edit-modal-job-cron-expression'"></lib-translate></label>
  <input type="text" class="form-control" name="cronExpression" placeholder="{{addEditModalCronExpression}}" [(ngModel)]="currentTrigger!.CronExpressionString">
</div>
