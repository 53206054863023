<div>
  <div class="modal-header">
    <h4 class="modal-title"><lib-translate [key]="'lbl-edit-translate-modal-title'"></lib-translate></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="enLabel"><lib-translate [key]="'lbl-translation-enLabel'"></lib-translate></label>
      <input type="text" class="form-control" name="enLabel" [(ngModel)]="tradToEdit.EnLabel">
    </div>
    <div class="form-group">
      <label for="frLabel"><lib-translate [key]="'lbl-translation-frLabel'"></lib-translate></label>
      <input type="text" class="form-control" name="frLabel" [(ngModel)]="tradToEdit.FrLabel">
    </div>
    <div class="form-group">
      <label for="deLabel"><lib-translate [key]="'lbl-translation-deLabel'"></lib-translate></label>
      <input type="text" class="form-control" name="deLabel" [(ngModel)]="tradToEdit.DeLabel">
    </div>
    <div class="form-group">
      <label for="context"><lib-translate [key]="'lbl-translation-context'"></lib-translate></label>
      <ng-select [items]="context"
                 bindLabel="Label"
                 bindValue="Label"
                 [(ngModel)]="tradToEdit.Context">
      </ng-select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()"><lib-translate [key]="'close'"></lib-translate></button>
    <button type="button" class="btn btn-primary" (click)="onValidate()"><lib-translate [key]="'validate'"></lib-translate></button>
  </div>
</div>
