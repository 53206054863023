import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnSetting } from '../lib-column-selector/lib-column-selector.interface';
import { IFileDeposit, configFileDeposit } from '../lib-file-deposit/lib-file-deposit.interfaces';
import { LibFileDepositService } from '../lib-file-deposit/lib-file-deposit.service';

@Component({
    selector: 'lib-file-deposit-config',
    templateUrl: './lib-file-deposit-config.component.html',
    styleUrls: ['./lib-file-deposit-config.component.scss']
})
export class LibFileDepositConfigComponent implements OnInit {
    @Input() availableColumns: ColumnSetting[] = [];
    @Input() configFileDeposit: configFileDeposit = {
        fileDepositType: {
            Id: null,
            Label: "",
            Enable: true,
            EnableString: "yes",
            Type: 0,
            Tag: "",
            Filter: "",
            Auth: 0,
            Login: "",
            Password: "",
            Host: "",
            Port: 0,
            RootPath: "",
            Capabilities: [0]
        },
        paramsFileDeposit: [],
        columnLink: { name: "", label: "", type: "", visible: true, nbDecimal: 2, nature: 'normal', horizontalAlign: 'left', displaySum: false, width: null, actionColumnDef: null }
    };
    @Output() fileDepositConfChange = new EventEmitter<configFileDeposit>();


    availableFileDeposit: IFileDeposit[] = [];
    constructor(private fileDepositService: LibFileDepositService) { }

    ngOnInit(): void {
        this.fileDepositService.GetAllFileDepositActive().subscribe((r: any) => {
            this.availableFileDeposit = r;
        });

    }

    onSelectedFileDepositChange(event: any): void {
        if (this.configFileDeposit.fileDepositType) {

            this.getParamForFileDeposit(this.configFileDeposit.fileDepositType.Type);

            if (this.checkConfFileDepositIsComplete(this.configFileDeposit)) {
                this.fileDepositConfChange.emit(this.configFileDeposit);
            }

        }
    }

    onSelectedColumnForParam(): void {
        //this.configFileDeposit.paramsFileDeposit[index].column = event;
        if (this.checkConfFileDepositIsComplete(this.configFileDeposit)) {
            this.fileDepositConfChange.emit(this.configFileDeposit);
        }
    }

    onFileDepositLinkChange(event: ColumnSetting) {
        if (this.checkConfFileDepositIsComplete(this.configFileDeposit)) {
            this.fileDepositConfChange.emit(this.configFileDeposit);
        }

    }

    getParamForFileDeposit(fileDepositType: number): void {
        this.configFileDeposit.paramsFileDeposit = [];
        switch (fileDepositType) {
            case 0: {
                //docuware
                let dataFilter = this.configFileDeposit.fileDepositType.Filter;
                const regex = /['"](.*?)['"]/g;
                const matches = dataFilter.match(regex);

                if (matches) {
                    let words = matches.map(match => match.replace(/["']/g, ''));
                    words = Array.from(new Set(words));
                    words.forEach((word: string) => {
                        this.configFileDeposit.paramsFileDeposit.push({
                            paramName: word,
                            column: {
                                name: "",
                                label: "",
                                type: "",
                                visible: true,
                                nbDecimal: 2,
                                nature: 'normal',
                                horizontalAlign: 'left',
                                displaySum: false,
                                width: null,
                                actionColumnDef: null
                            }
                        });
                    });
                }
                break;
            }
            case 1: {
                //FileSystem
                let dataFilter = this.configFileDeposit.fileDepositType.Filter;
                const regex = /\$([^$]+)\$/g;
                const matches = dataFilter.match(regex);

                if (matches) {
                    let words = matches.map(match => match.slice(1, -1));
                    words = Array.from(new Set(words));
                    words.forEach((word: string) => {
                        this.configFileDeposit.paramsFileDeposit.push({
                            paramName: word,
                            column: {
                                name: "",
                                label: "",
                                type: "",
                                visible: true,
                                nbDecimal: 2,
                                nature: 'normal',
                                horizontalAlign: 'left',
                                displaySum: false,
                                width: null,
                                actionColumnDef: null
                            }
                        });
                    });
                }
                break;
            }
            default: {
                console.log("default from switch filedepositref type id");
                break;
            }
        }
    }

    checkConfFileDepositIsComplete(conf: configFileDeposit): boolean {
        if (conf.paramsFileDeposit.length === 0) {
            return false;
        }

        if (conf.columnLink.name == "") {
            return false;
        }

        if (conf.fileDepositType.Id == null) {
            return false;
        }

        return true;
    }


}
