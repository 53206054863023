import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LibUserService } from '@herdia-common';
import { GetBuildingsResponse, GetPortfoliosResponse } from './lib-specific-param-report-config.interface';
import { LibSpecificParamReportConfigService } from './lib-specific-param-report-config.service';
import { parse, format } from 'date-fns';

@Component({
  selector: 'lib-specific-param-report-config',
  templateUrl: './lib-specific-param-report-config.component.html',
  styleUrls: ['./lib-specific-param-report-config.component.scss'],
  encapsulation: ViewEncapsulation.None, //used to appli style css on ngSelect
})
export class LibSpecificParamReportConfigComponent implements OnInit {
  @Input() buildingsSelected: string = "";
  @Output() inputUpdated = new EventEmitter<any>();

  @Input() ListBuildingsOrigin: GetBuildingsResponse[] = [];
  ListBuildings: GetBuildingsResponse[] = [];
  BuildingsSelected: GetBuildingsResponse[] = [];
  PortfolioSelected!: GetPortfoliosResponse;
  NgSelectBuildingsisClose: boolean = false;

  portfolioItems: { portfolioLabel: string, importDate: string, portfolioItem: GetPortfoliosResponse }[] = [];

  constructor(private specificParamService: LibSpecificParamReportConfigService, private userService: LibUserService) {
    //get all buildings and all portfolios with request to Api
    this.specificParamService.GetBuildings().subscribe((response: GetBuildingsResponse[]) => {
      //change the date format for buildings list according to the language of the user
      this.ListBuildingsOrigin = response.map((element: GetBuildingsResponse) => {
        let UserLanguage = this.userService.language;
        switch (UserLanguage) {
          case 'fr-FR': {
            let date = parse(element.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            let newDate = format(date, 'dd-MM-yyyy');
            element.ImportDate = newDate;
            break;
          }
          case 'en-EN': {
            let date = parse(element.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            let newDate = format(date, 'MM-dd-yyyy');
            element.ImportDate = newDate;
            break;
          }
          case 'de-DE': {
            let date = parse(element.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            let newDate = format(date, 'dd-MM-yyyy');
            element.ImportDate = newDate;
            break;
          }
          default: {
            let date = parse(element.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            let newDate = format(date, 'dd-MM-yyyy');
            element.ImportDate = newDate;
            break;
          }
        };
        return element;
      });

      this.specificParamService.GetPortfolios().subscribe((response: GetPortfoliosResponse[]) => {
        //Set portfolioItems to have the good format for ngSelect
        //get the user's language to know, which label to use in the ng select portfolios +
        //change the date format for portfolio list according to the language of the user
        this.portfolioItems = response.map((e: GetPortfoliosResponse) => {
          let UserLanguage = this.userService.language;
          let label = "";
          switch (UserLanguage) {
            case 'fr-FR': {
              label = e.FrPortfolioLabel;

              let date = parse(e.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
              let newDate = format(date, 'dd-MM-yyyy');
              e.ImportDate = newDate;
              break;
            }
            case 'en-EN': {
              label = e.EnPortfolioLabel;

              let date = parse(e.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
              let newDate = format(date, 'MM-dd-yyyy');
              e.ImportDate = newDate;
              break;
            }
            case 'de-DE': {
              label = e.DePortfolioLabel;

              let date = parse(e.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
              let newDate = format(date, 'dd-MM-yyyy');
              e.ImportDate = newDate;
              break;
            }
            default: {
              label = e.FrPortfolioLabel;

              let date = parse(e.ImportDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
              let newDate = format(date, 'dd-MM-yyyy');
              e.ImportDate = newDate;
              break;
            }
          };

          return { portfolioLabel: label, importDate: e.ImportDate, portfolioItem: e }
        });

        //get the previously selected buildings
        let arrayIdBuilding = this.buildingsSelected.split(',');
        let res: GetBuildingsResponse[] = [];
        arrayIdBuilding.forEach((element: string) => {
          let find = this.ListBuildingsOrigin.find(building => building.IdBuilding == Number(element));
          if (find) {
            res.push(find);
          }
        });
        this.BuildingsSelected = res;

        this.ListBuildings = this.ListBuildingsOrigin;
      });
    })
  }

  ngOnInit(): void {
    
  }

  selectAllBuildings() {
    //select all buildings
    this.BuildingsSelected = this.ListBuildings;
    this.onBuildingSelectedChange();
  }

  unselectAllBuildings() {
    //deselected all buildings
    this.BuildingsSelected = [];
  }

  onPortfolioSelected() {
    // used to update the list of buildings against the selected portfolio
    if (this.PortfolioSelected != undefined) {
      this.ListBuildings = this.ListBuildingsOrigin.filter(item => item.PortfolioCode == this.PortfolioSelected.PortfolioCode);
      this.BuildingsSelected = [];
    }
    else {
      this.ListBuildings = this.ListBuildingsOrigin;
    }
  }

  onBuildingSelectedChange() {
    //used to return on parent component the list of building with this syntaxe "building_code1, building_code2"
    if (!(this.BuildingsSelected.length == 0)) {
      let ListOfBuildingCodeAsString = this.BuildingsSelected.map((element: GetBuildingsResponse) => element.IdBuilding.toString()).join(',');
      this.inputUpdated.emit(ListOfBuildingCodeAsString);
    }
  }

  //used for change the header of the ngSelect buildings if it is close or open 
  onNgSelectBuildingsClose() {
    this.NgSelectBuildingsisClose = true;
  }
  onNgSelectBuildingsOpen() {
    this.NgSelectBuildingsisClose = false;
  }
  //

  
}
