<ngb-accordion #acc="ngbAccordion">
    <ngb-panel
        id="toggle-1"
        title="physical vacancy rate"
    >
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label
                    for="alertVacancyRateRetail"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-physical-vacancy-rate-retail'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertVacancyRateRetail"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_VACANCY_RATE_RETAIL"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertVacancyRateOffice"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-physical-vacancy-rate-office'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertVacancyRateOffice"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_VACANCY_RATE_OFFICE"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertVacancyRateStorage"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-physical-vacancy-rate-storage'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertVacancyRateStorage"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_VACANCY_RATE_STORAGE"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertVacancyRateMixed"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-physical-vacancy-rate-mixed'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertVacancyRateMixed"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_VACANCY_RATE_MIXED"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertVacancyRateResidential"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-physical-vacancy-rate-residential'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertVacancyRateResidential"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_VACANCY_RATE_RESIDENTIAL"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertVacancyRateOther"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-physical-vacancy-rate-other'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertVacancyRateOther"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_VACANCY_RATE_OTHER"].alert_threshold'
                    >
                </div>

            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel
        id="toggle-2"
        title="Duration of vacancy"
    >
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label
                    for="alertVacantUnit"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-vacant-unit'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertVacantUnit"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_VACANT_UNIT"].alert_threshold'
                    >
                </div>

            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel
        id="toggle-3"
        title="Break option"
    >
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label
                    for="alertBreakOption"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-break-option'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertBreakOption"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_LEASE_BREAK_RETAIL"].alert_threshold'
                    >
                </div>

            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel
        id="toggle-4"
        title="Expiring leases"
    >
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label
                    for="alertExpiringLeaseRetail"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-expiring-lease-retail'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertExpiringLeaseRetail"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_LEASE_EXPIRING_RETAIL"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertExpiringLeaseResidential"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-expiring-lease-residential'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertExpiringLeaseResidential"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_LEASE_EXPIRING_RESIDENTIAL"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertExpiringLeaseOther"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-expiring-lease-other'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertExpiringLeaseOther"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_LEASE_EXPIRING_OTHER"].alert_threshold'
                    >
                </div>

            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel
        id="toggle-5"
        title="Rent after adjustment"
    >
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label
                    for="alertRentIncreased"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-rent-increased'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertRentIncreased"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_RENT_INCREASED_AFTER_ADJUSTMENT"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertRentDecreased"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-rent-decreased'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertRentDecreased"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_RENT_DECREASED_AFTER_ADJUSTMENT"].alert_threshold'
                    >
                </div>

            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel
        id="toggle-6"
        title="Current rent compared to initial rent"
    >
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label
                    for="alertRentIncreasedInitialRent"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-rent-increased-initial-rent'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertRentIncreasedInitialRent"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_RENT_INCREASED_INITIAL_RENT"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertRentDecreasedInitialRent"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-rent-decreased-initial-rent'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertRentDecreasedInitialRent"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_RENT_DECREASED_INITIAL_RENT"].alert_threshold'
                    >
                </div>

            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel
        id="toggle-7"
        title="Evolution of the net market value of a property"
    >
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label
                    for="alertPropertyValueIncreased"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-property-value-increased'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertPropertyValueIncreased"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_PROPERTY_VALUE_INCREASED"].alert_threshold'
                    >
                </div>

            </div>
            <div class="form-group row">
                <label
                    for="alertPropertyValueDecreased"
                    class="col-sm-10 col-form-label"
                ><lib-translate [key]="'lbl-alert-property-value-decreased'"></lib-translate></label>
                <div class="col">
                    <input
                        type="number"
                        class="form-control"
                        id="alertPropertyValueDecreased"
                        min="0"
                        [(ngModel)]='alertDictionary["ALERT_PROPERTY_VALUE_DECREASED"].alert_threshold'
                    >
                </div>

            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>