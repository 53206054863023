import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { LibColumnSelectorModule } from '../lib-column-selector/lib-column-selector.module';
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibHerdiaDatatableColumnSelectorButtonComponent } from './lib-herdia-datatable-column-selector-button/lib-herdia-datatable-column-selector-button.component';
import { LibHerdiaDatatableExportButtonComponent } from './lib-herdia-datatable-export-button/lib-herdia-datatable-export-button.component';
library.add(fas);

@NgModule({
    declarations: [
        LibHerdiaDatatableColumnSelectorButtonComponent,
        LibHerdiaDatatableExportButtonComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        LibHerdiaAppTranslateModule,
        TranslateModule,
        FontAwesomeModule,
        LibColumnSelectorModule
    ],
    exports: [
        LibHerdiaDatatableColumnSelectorButtonComponent,
        LibHerdiaDatatableExportButtonComponent
    ]
})
export class LibCardHeaderCommonButtonsModule { }
