import { NgModule } from '@angular/core';
import { LibColumnSelectorModule } from '../lib-column-selector/lib-column-selector.module';

@NgModule({
    declarations: [
    ],
    imports: [
        LibColumnSelectorModule
    ],
    exports: [
    ]
})
export class LibFileDepositModule { }
