export enum PropertyOption {
  IsReadOnly = 0,
  IsForeignKey = 1,
  IsKey = 2,
  IsNullable = 3
}

export enum EntityCRUDUrls {
  GET_CONTEXTS = 'api/EntityCRUD/GetContexts',
  GET_ENTITIES = 'api/EntityCRUD/GetEntities',
  GET_ENTITY = 'api/EntityCRUD/GetEntity',
  GET_ENTITY_FROM_QUERY = 'api/EntityCRUD/GetSQLQueryEntityDefinition',
  CREATE = 'api/EntityCRUD/Create',
  READ = 'api/EntityCRUD/Read',
  READ_FROM_SQL_QUERY = 'api/EntityCRUD/ReadFromSql',
  UPDATE = 'api/EntityCRUD/Update',
  DELETE = 'api/EntityCRUD/Delete',
  CREATE_OR_UPDATE = 'api/EntityCRUD/CreateOrUpdate',
  ASK_EXPORT = 'api/Export/AskExport',
  ASK_IMPORT_CSV_ENTITY = 'api/Import/AskImportFromContextEntitiesFromCSV'
}
