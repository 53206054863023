import { Injectable } from '@angular/core';
import { UserEmailManager } from './api.service';
import { LibHttpClientApiService } from '@herdia-common';
import { HAAddUserEmailTemplateRequest, HASendEmailForTestTemplateRequest, HAUpdateEmailTemplateRequest } from '../models/interfaces';
import {ServerSideRequest, ServerSideResponse} from "@herdia-common";

@Injectable({
  providedIn: 'root'
})
export class UserEmailTemplateService {
  constructor(private apiSvc: LibHttpClientApiService) { }

  GetAllUserEmailTemplate(param: ServerSideRequest) {
    return this.apiSvc.post<ServerSideRequest, ServerSideResponse>(UserEmailManager.GET_ALL_USER_EMAIL_TEMPLATE,param);
  }

  DeleteUserEmailTemplate(tempalteID:number) {
    var url = UserEmailManager.DELETE_USER_EMAIL_TEMPLATE+`${tempalteID}`;
    return this.apiSvc.remove(url);
  }

  AddUserEmailTemplate(param: HAAddUserEmailTemplateRequest) {
    return this.apiSvc.post<HAAddUserEmailTemplateRequest, boolean>(UserEmailManager.ADD_USER_EMAIL_TEMPLATE,param);
  }

  UpdateUserEmailTemplate(param: HAUpdateEmailTemplateRequest) {
    return this.apiSvc.put<HAUpdateEmailTemplateRequest, boolean>(UserEmailManager.UPDATE_USER_EMAIL_TEMPLATE,param);
  }

  GetHtmlPartiel(tempalteID:number) {
    var url = UserEmailManager.GET_USER_EMAIL_CONTENT+`${tempalteID}`;
    return this.apiSvc.get(url);
  }

  SendEmailForTestTemplate(param: HASendEmailForTestTemplateRequest) {
    return this.apiSvc.post<HASendEmailForTestTemplateRequest, any>(UserEmailManager.SEND_EMAIL_FOR_TEST,param);
  }

  GetDescriptionVariablesTemplates() {
    return this.apiSvc.get(UserEmailManager.GET_DESCRIPTION_VARIABLES_TEMPLATES);
  }
}
