import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicComponentsBaseModule } from '../dynamic-components/dynamic-components-base-module';
import { DynamicComponentDeclaration, DynamicComponentPackage } from '../dynamic-components/dynamic-components.model';
import { LibCardHtmlEditorConfigComponent } from './lib-card-html-editor-config/lib-card-html-editor-config.component';
import { LibCardHtmlEditorComponent, SafeHtmlPipe } from './lib-card-html-editor/lib-card-html-editor.component';

@NgModule({
    declarations: [
        LibCardHtmlEditorComponent,
        LibCardHtmlEditorConfigComponent,
        SafeHtmlPipe
    ],
    imports: [
        AngularEditorModule,
        FormsModule,
        NgSelectModule
    ],
    exports: [
        LibCardHtmlEditorComponent,
        LibCardHtmlEditorConfigComponent
    ]
})
export class LibCardHtmlEditorModule extends DynamicComponentsBaseModule {

    override getDynamicComponentsDeclarations(): DynamicComponentDeclaration[] {
        return [
            { component: LibCardHtmlEditorComponent, type: 'card' },
            { component: LibCardHtmlEditorConfigComponent, type: 'config' },
        ]
    }

    override getPackageName(): DynamicComponentPackage {
        return 'common';
    }

}
