import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent, ColumnSetting, LibHttpClientApiService } from '@herdia-common';
import { TranslatePipe } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { ReportColumnSetting } from '../lib-card-report.interfaces';
import { LibCardReportService } from '../lib-card-report.service';

@Component({
    selector: 'lib-card-report-config-rich-report',
    templateUrl: './lib-card-report-config-rich-report.component.html',
    styleUrls: ['./lib-card-report-config-rich-report.component.css']
})
export class LibCardReportConfigRichReportComponent extends CardConfigBaseComponent implements OnInit {

    numberTypes: string[] = ["int", "Int32", "Int32?", "int?", "decimal", "decimal?", "Decimal", "Decimal?", "float", "float?"];
    booleanTypes: string[] = ["bool", "Boolean", "boolean"];
    dateTypes: string[] = ["Datetime", "Datetime?", "Date", "Date?", "DateTime", "DateTime?"]

    availableCols: ColumnSetting[] = [];
    selectedCols: ColumnSetting[] = [];

    availableDecimals: number[] = [1, 2, 3, 4, 5];

    constructor(private dragulaService: DragulaService, private apiService: LibHttpClientApiService, private translatePipe: TranslatePipe, private cardReportService: LibCardReportService) {
      super();
      this.dragulaService.drop('HANDLES').subscribe(({ name, el, target, source, sibling }) => {
        this.config['RichReport']['ColumnsSettings'] = this.selectedCols;
      });
    }

    ngOnInit(): void {
        if (!this.config["RichReport"])
            this.config["RichReport"] = {};

        if (this.config["DataSource"]["ProcedureName"]) {
            let resultSet = this.apiService.getActionResult("/api/alhambra", this.config["DataSource"]["ProcedureName"], "Datas", "post").responses;

            let buffer: ColumnSetting[] = [];

            Object.keys(resultSet).forEach((key: string) => {
                let item = resultSet[key];
                let csType = this.apiService.getCSTypeFromJSONType(item);
                buffer.push({
                    name: key,
                    label: this.translatePipe.transform(key.toUpperCase()),
                    nature: "normal",
                    nbDecimal: 2,
                    type: csType,
                    displaySum: false,
                    horizontalAlign: this.cardReportService.getDefaultAlignForType(csType),
                    visible: true,
                    width: null,
                    actionColumnDef: null
                })
            });
            this.availableCols = buffer;
        }

        if (this.config["RichReport"]["ColumnsSettings"] && Object.keys(this.config["RichReport"]["ColumnsSettings"]).length > 0) {
            // Compatibility with previous ReportColumnSetting, we transform ReportColumnSetting interface to ColumnSetting interface
            if (this.config['RichReport']['ColumnsSettings'][0]['sqlName']) {
                this.selectedCols = this.config['RichReport']['ColumnsSettings'].map((c: ReportColumnSetting) => {
                    return {
                        name: c.sqlName,
                        label: this.translatePipe.transform(c.sqlName.toUpperCase()),
                        nature: c.nature,
                        nbDecimal: c.nbDecimal,
                        type: c.csType,
                        displaySum: c.additional,
                        horizontalAlign: this.cardReportService.getDefaultAlignForType(c.csType),
                        visible: true,
                        width: c.webColumnWidth
                    }
                });
            }
            else {
                this.selectedCols = this.config['RichReport']['ColumnsSettings'];
            }
        }
    }


    isNumber(type: string): boolean {
        return this.numberTypes.indexOf(type) !== -1;
    }

    onSelectedColumnsChange(e: Event): void {
        this.config['RichReport']['ColumnsSettings'] = this.selectedCols;
    }

    copyWebReportConfiguration(): void {
        this.config['RichReport']['ColumnsSettings'] = this.config['WebReport']['ColumnsSettings']
        this.selectedCols = this.config['RichReport']['ColumnsSettings'];
    }

    selectAllColumns(): void {
      this.selectedCols = this.availableCols;
      this.config['RichReport']['ColumnsSettings'] = this.selectedCols;
    }

    unselectAllColumns(): void {
      this.selectedCols = [];
      this.config['RichReport']['ColumnsSettings'] = this.selectedCols;
    }
}
