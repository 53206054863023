import { PersistentNotificationService } from './../shared/service/persistent-notification.service';
import { Component, Input, OnInit } from '@angular/core';
import { LibHerdiaAppEnvironmentService, LibHerdiaAppTranslateService } from '@herdia-common';
import { LibHttpClientApiService } from '@herdia-common';
import { PersistentNotification } from '../shared/models/interfaces';

@Component({
  selector: 'persistent-notification',
  templateUrl: './persistent-notification.component.html',
  styleUrls: ['./persistent-notification.component.scss']
})
export class PersistentNotificationComponent implements OnInit {

  @Input() notification!: PersistentNotification;
  time: string = "";
  hasDownload: boolean = true;
  title: string = "title";
  message: string = "message";

  constructor(private translateService: LibHerdiaAppTranslateService, private environnementService: LibHerdiaAppEnvironmentService, private apiService: LibHttpClientApiService, private persistentNotificationService: PersistentNotificationService) { }

  ngOnInit(): void {
    this.time = this.notification.date.toString();
    this.hasDownload = this.notification.message.ContentDownloadURL !== null &&
                       this.notification.message.ContentDownloadURL !== undefined &&
      this.notification.message.ContentDownloadURL !== "";
    this.title = this.translateService.get(this.notification.message.TitleCode);
    this.message = this.translateService.get(this.notification.message.ContentCode);
  }

  downloadItem(): void {
    this.apiService.downloadFile(this.notification.message.ContentDownloadURL, this.notification.message.ContentDownloadsFilename);
    this.persistentNotificationService.acknowledgeNotifications(this.notification.id);
  }
}
