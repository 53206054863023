<div
    class="input-group input-group-sm divTableSearch"
    [ngStyle]="{'display':displaySearch}"
>
    <input
        type="search"
        placeholder="Rechercher dans les colonnes..."
        class="form-control"
        [(ngModel)]="searchValue"
    >
    <div class="input-group-append">
        <button
            type="submit"
            class="btn btn-tool"
            (click)="onSearch()"
        >
            <fa-icon icon="search"></fa-icon>
        </button>
        <button
            type="button"
            class="btn btn-tool"
            (click)="onHideSearch()"
        >
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
</div>

<div class="d-flex">
    <div
        class="jsuisla"
        *ngIf="dataSourceType !== 'SQL'"
    >
        <button
            type="button"
            class="btn btn-tool"
            #pUpload="ngbPopover"
            [popoverTitle]="popUploadTitle"
            [ngbPopover]="popUploadContent"
            [autoClose]="false"
        >
            <fa-icon icon="upload"></fa-icon>
        </button>
        <ng-template #popUploadTitle><lib-translate [key]="'lbl-uploads'"></lib-translate></ng-template>
        <ng-template #popUploadContent>
            <label><lib-translate [key]="'lbl-file-to-import'"></lib-translate></label>
            <input
                #fileUpload
                type="file"
                class="file-input"
                (change)="onFileSelected($event)"
            >
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    value="{{fileName}}"
                    disabled
                />
                <button
                    class="btn btn-outline-secondary bi bi-calendar3"
                    (click)="fileUpload.click()"
                    type="button"
                ><fa-icon icon="file-csv"></fa-icon></button>
            </div>
            <br />
            <div class="form-group">
                <input
                    type="checkbox"
                    name="allowUpdate"
                    class="form-check-input"
                    [(ngModel)]="allowUpdate"
                />&nbsp;
                <label for="allowUpdate"><lib-translate [key]="'lbl-allow-import-update'"></lib-translate></label>
            </div>
            <ng-select
                [items]="config['DataSource']['Entity']['Properties']"
                [(ngModel)]="selectedImportIdentifier"
                bindLabel="Name"
                bindValue="Name"
            ></ng-select>
            <br />
            <button
                class="btn btn-primary"
                (click)="pUpload.close();onAskImportCSV();"
            ><lib-translate [key]="'lbl-upload-csv'"></lib-translate></button>
        </ng-template>
    </div>

    <button
        type="button"
        class="btn btn-tool"
        #p="ngbPopover"
        [popoverTitle]="popDownloadTitle"
        [ngbPopover]="popDownloadContent"
        [autoClose]="false"
    >
        <fa-icon icon="download"></fa-icon>
    </button>
    <ng-template #popDownloadTitle><lib-translate [key]="'lbl-downloads'"></lib-translate></ng-template>
    <ng-template #popDownloadContent>
        <ngb-accordion
            #acc="ngbAccordion"
            [closeOthers]="true"
        >
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="text-truncate"><fa-icon
                            icon="file-csv"
                            class="nav-icon"
                        ></fa-icon>&nbsp;<lib-translate [key]="'lbl-csv-file'"></lib-translate></span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="form-group">
                        <input
                            type="checkbox"
                            name="quoteAllFields"
                            class="form-check-input"
                            [(ngModel)]="quoteAllFields"
                        />&nbsp;
                        <label for="quoteAllFields"><lib-translate
                                [key]="'lbl-quote-all-fields'"></lib-translate></label>
                    </div>
                    <div class="form-group">
                        <label for="csv-delimiter"><lib-translate
                                [key]="'lbl-csv-delimiter-char'"></lib-translate></label>
                        <input
                            type="text"
                            name="csv-delimiter"
                            class="form-control"
                            [(ngModel)]="delimiter"
                        />
                    </div>
                    <div class="form-group">
                        <label for="csv-export-name"><lib-translate
                                [key]="'lbl-csv-export-name'"></lib-translate></label>
                        <input
                            type="text"
                            name="csv-csv-export-name"
                            class="form-control"
                            [(ngModel)]="exportName"
                        />
                    </div>
                    <button
                        class="btn btn-primary"
                        (click)="p.close();onAskExportCSV();"
                    ><lib-translate [key]="'lbl-generate-csv'"></lib-translate></button>
                </ng-template>
            </ngb-panel>
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="text-truncate"><fa-icon
                            icon="file-excel"
                            class="nav-icon"
                        ></fa-icon>&nbsp;<lib-translate [key]="'lbl-xlsx-file'"></lib-translate></span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="form-group">
                        <label for="csv-export-name"><lib-translate
                                [key]="'lbl-csv-export-name'"></lib-translate></label>
                        <input
                            type="text"
                            name="csv-csv-export-name"
                            class="form-control"
                            [(ngModel)]="exportName"
                        />
                    </div>
                    <button
                        class="btn btn-primary"
                        (click)="p.close();onAskExportXLSX();"
                    ><lib-translate [key]="'lbl-generate-xlsx'"></lib-translate></button>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </ng-template>
    <button
        type="button"
        class="btn btn-tool"
        (click)="onShowFilter()"
    >
        <fa-icon icon="filter"></fa-icon>
    </button>
    <button
        type="button"
        class="btn btn-tool"
        (click)="onShowSearch()"
    >
        <fa-icon icon="search"></fa-icon>
    </button>
    <button
        *ngIf="allowAdd"
        type="button"
        class="btn btn-tool"
        (click)="onAdd()"
    ><fa-icon icon="plus"></fa-icon></button>
    <!--<lib-herdia-datatable-column-selector-button [columnSettings]="columnSettings"
                                                 [config]="this.config">
    </lib-herdia-datatable-column-selector-button>-->
</div>
