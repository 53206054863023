import { ToastService } from './toast-notification.service';
import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { LibHerdiaAppEnvironmentService } from '@herdia-common';
import { PersistentNotificationService } from './persistent-notification.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private hubConnection!: signalR.HubConnection;
  private apiBaseUrl: string = "";
  constructor(private toastService: ToastService, private environmentService: LibHerdiaAppEnvironmentService, private persistentNotificationService: PersistentNotificationService) {
  }

  public startConnection = () => {
    let user = localStorage.getItem("user");
    if (user !== null) {
      let userObj = JSON.parse(user);
      let token = JSON.parse(user).Token;
      this.hubConnection = new signalR.HubConnectionBuilder()
                              .withUrl(this.environmentService.apiBaseUrl + '/notificationhub?email=' + userObj.Email, {
                                accessTokenFactory: () => token,
                                withCredentials: false
                              })
                              .build();
      this.hubConnection
        .start()
        .then(() => console.log('SignalR Connection started'))
        .catch(err => console.log('Error while starting signalR connection: ' + err));
    }
  }

  public addToastNotificationListener(): void {
    this.hubConnection.on('ToastNotification', (id: string, data: string) => {
      this.toastService.show(JSON.parse(data));
    });

    this.hubConnection.on('ToastPersistentNotification', (id: string, date: Date, data: string) => {
      this.toastService.show(JSON.parse(data));
      this.persistentNotificationService.Notifications.push({id: id, date: date, message: JSON.parse(data)});
    });
  }
}
