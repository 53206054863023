import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DynamicComponentDeclaration, DynamicComponentPackage, DynamicComponentsBaseModule } from '@herdia-common';
import { LibHerdiaAppTranslateModule } from '@herdia-common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LibPanelRightComponent } from './lib-panel-right.component';

@NgModule({
    declarations: [
        LibPanelRightComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FontAwesomeModule,
        NgSelectModule,
        FormsModule,
        LibHerdiaAppTranslateModule,
        TranslateModule
    ],
    exports: [
        LibPanelRightComponent
    ]
})
export class LibPanelRightModule extends DynamicComponentsBaseModule {

    override getPackageName(): DynamicComponentPackage {
        return 'alhambra';
    }

    override getDynamicComponentsDeclarations(): DynamicComponentDeclaration[] {
        return [
            { component: LibPanelRightComponent, type: 'default' }
        ]
    }

}
