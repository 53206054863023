<div class="col-md-12 card-container">
  <div class="card card-primary haCardBox container-toggle"
       [class.maximized-card]="this.maximizedCard"
       [class.collapsed-card]="this.collapsedCard"
       [ngClass]="cardInterface.cardCustomClasses">
    <div *ngIf="cardInterface.hasHeader || editMode"
         class="card-header">
      <h3 class="card-title"
          title="{{card.Title}}">
        <fa-icon [icon]="['fas', iconCard]"></fa-icon>&nbsp;{{card.Title}}
      </h3> <!-- icon="{{iconCard}}" -->
      <div class="align-items-baseline card-tools d-flex">

        <ng-container *ngIf="cardInterface.hasButton && cardConfigured">
          <app-dynamic-component *ngIf="cardConfigured || !cardInterface.isConfigurable"
                                 [selector]="card.CardTypeLabel + '-header-buttons'"
                                 [inputs]="{config: card.Configuration, title: card.Title }"></app-dynamic-component>
        </ng-container>
        <button *ngIf="editMode && cardInterface.isConfigurable"
                type="button"
                class="btn btn-tool"
                (click)="editCardConfig()">
          <fa-icon icon="cog"></fa-icon>
        </button>

        <button *ngIf="cardInterface.allowFullScreen && !maximizedCard"
                (click)="fullscreenCard()"
                type="button"
                class="btn btn-tool"
                data-card-widget="maximize"
                data-toggle="tooltip"
                title="Plein écran">
          <fa-icon icon="expand"></fa-icon>
        </button>

        <button *ngIf="cardInterface.allowFullScreen && maximizedCard"
                (click)="fullscreenCard()"
                type="button"
                class="btn btn-tool"
                data-card-widget="maximize"
                data-toggle="tooltip"
                title="minimisé">
          <fa-icon icon="compress"></fa-icon>
        </button>

        <button *ngIf="editMode && this.row"
                type="button"
                class="btn btn-tool"
                (click)="moveCardToLeft()"
                [ngClass]="card.Order == 1 ? 'disabledArrowbutton' : ''">
          <fa-icon icon="arrow-left"></fa-icon>
        </button>
        <button *ngIf="editMode && this.row"
                type="button"
                class="btn btn-tool"
                (click)="moveCardToRight()"
                [ngClass]="card.Order == this.row.HAPageCards[this.row.HAPageCards.length - 1].Order ? 'disabledArrowbutton' : ''">
          <fa-icon icon="arrow-right"></fa-icon>
        </button>
      </div>
    </div>

    <div [ngClass]="cardInterface.hasHeader || cardInterface.hasFooter ? 'card-body' : ''"
         class="card-body">
      <div *ngIf="cardConfigured || !cardInterface.isConfigurable ;then contentBody else unconfiguredContentBody">
        here is ignored
      </div>
      <ng-template #contentBody>
        <app-dynamic-component *ngIf="cardConfigured || !cardInterface.isConfigurable"
                               [selector]="card.CardTypeLabel"
                               [inputs]="{config: card.Configuration, id:card.Id, title: card.Title }"></app-dynamic-component>
      </ng-template>
      <ng-template #unconfiguredContentBody>
        <button *ngIf="editMode && cardInterface.isConfigurable"
                class="btn btn-outline-primary btn-block"
                (click)="editCardConfig()">
          <lib-translate key="lbl-click-here-to-configure-card"></lib-translate>
        </button>
        <div *ngIf="!editMode && cardInterface.isConfigurable"
             class="d-flex flex-column min-vh-50 justify-content-center align-items-center"><lib-translate key="lbl-unconfigure-card"></lib-translate></div>
      </ng-template>
    </div>

    <div *ngIf="(cardInterface.hasFooter && (cardConfigured || !cardInterface.isConfigurable)) || card.CardTypeLabel == 'lib-card-alert'"
         class="card-footer">
      <ng-container>
        <app-dynamic-component [selector]="card.CardTypeLabel + '-footer'"
                               [inputs]="{config: card.Configuration }"></app-dynamic-component>
      </ng-container>
    </div>
  </div>
</div>
