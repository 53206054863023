import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LibPropertyTypeInputModule } from '@herdia-common';
import { LibCardHeaderCommonButtonsModule } from "@herdia-common";
import { LibConfirmBoxModule } from "@herdia-common";
import { LibCronEditorModule } from '@herdia-common';
import { LibDatatablePaginationModule } from "@herdia-common";
import { LibEditModeModule } from '@herdia-common';
import { LibHerdiaAppEnvironmentService } from "@herdia-common";
import { LibHerdiaAppTranslateModule } from '@herdia-common';
import { LibHerdiaDatatableModule } from '@herdia-common';
import { LibHttpClientApiService } from '@herdia-common';
import { LibSchedulerServiceModule } from '@herdia-common';
import { LibUploaderModule } from '@herdia-common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { DragulaModule } from 'ng2-dragula';
import { FileUploadModule } from 'ng2-file-upload';
import { AddRoutingModule } from '../app-routing.module';
import { AppComponent } from '../app.component';
import { HerdiaCommonModule } from '../herdia-common/herdia-common.module';
import { AddCategoryModalComponent } from './add-category-modal/add-category-modal.component';
import { AddPageModalComponent } from './add-page-modal/add-page-modal.component';
import { ImportPageModalComponent } from './add-page-modal/import-page-modal/import-page-modal.component';
import { DatatableAppMailRowActionComponent } from './application-email-manager/datatable-app-mail-row-action/datatable-app-mail-row-action.component';
import { EmailManagerComponent } from './application-email-manager/email-manager.component';
import { CardThemeDesignerComponent } from './card-theme-designer/card-theme-designer.component';
import { CardBoxComponent } from './cards/card-box/card-box.component';
import { CardConfigComponent } from './cards/card-config/card-config.component';
import { ExportCardConfigComponent } from './cards/card-config/export-card-config/export-card-config.component';
import { SaveCardConfigComponent } from './cards/card-config/save-card-config/save-card-config.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { AddDbconnectionModalComponent } from './dbconnection-manager/add-dbconnection-modal/add-dbconnection-modal.component';
import { DBConnectionManagerComponent } from './dbconnection-manager/dbconnection-manager.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { ExportPageConfigModalComponent } from './export-page-config-modal/export-page-config-modal.component';
import { AddFiledepositModalComponent } from './filedeposit-manager/add-filedeposit-modal/add-filedeposit-modal.component';
import { EditFiledepositModalComponent } from './filedeposit-manager/edit-filedeposit-modal/edit-filedeposit-modal.component';
import { FiledepositManagerComponent } from './filedeposit-manager/filedeposit-manager.component';
import { PreviewFiledepositModalComponent } from './filedeposit-manager/preview-filedeposit-modal/preview-filedeposit-modal.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HeaderComponent } from './header/header.component';
import { AddCardConfigurationModalComponent } from './home/add-card/add-card-modal/add-card-configuration-modal/add-card-configuration-modal.component';
import { AddCardModalComponent } from './home/add-card/add-card-modal/add-card-modal.component';
import { AddCardComponent } from './home/add-card/add-card.component';
import { AddPredefinedCardModalComponent } from './home/add-card/add-predefined-card-modal/add-predefined-card-modal.component';
import { HomeComponent } from './home/home.component';
import { LoadingComponent } from './login/loading/loading.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageUnauthorizedComponent } from './page-unauthorized/page-unauthorized.component';
import { PersistentNotificationComponent } from './persistent-notification/persistent-notification.component';
import { AddEditRoleComponent } from './role/add-edit-role/add-edit-role.component';
import { RoleComponent } from './role/role.component';
import { RowsComponent } from './rows/rows.component';
import { MaskPhoneDirective } from './shared/directive/mask-phone.directive';
import { ApiInitInterceptor } from './shared/interceptor/api-init.interceptor';
import { appInitializer } from './shared/interceptor/appInitializer';
import { AccountService } from './shared/service/account.service';
import { LibHerdiaAppMissingTranslationHandler, LibHerdiaAppTranslateCustomLoader } from './shared/service/translate.service';
import { AddEditTaskSchedulerComponent } from './task-scheduler-manager/add-edit-task-scheduler/add-edit-task-scheduler.component';
import { TaskSchedulerManagerComponent } from './task-scheduler-manager/task-scheduler-manager.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { TranslateManagerComponent } from './translate-manager/translate-manager.component';
import { UploaderManagerComponent } from './uploader-manager/uploader-manager.component';
import { DatatableUserMailRowActionComponent } from './user-email-manager/datatable-row-action/datatable-row-action.component';
import { UserEmailManagerComponent } from './user-email-manager/user-email-manager.component';
import { AddEditUserComponent } from './user/add-edit-user/add-edit-user.component';
import { ProfileComponent } from './user/profile/profile.component';
import { UsersComponent } from './user/users/users.component';
import { LibUserModule } from '../herdia-common/user/lib-user.module';
import { EditTranslateModalComponent } from './translate-manager/edit-translate-modal/edit-translate-modal.component'

registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeEn, 'en-GB');

@NgModule({
    declarations: [
        AddCardComponent,
        AddCardConfigurationModalComponent,
        AddCardModalComponent,
        AddCategoryModalComponent,
        AddDbconnectionModalComponent,
        AddEditRoleComponent,
        AddEditTaskSchedulerComponent,
        AddEditUserComponent,
        AddFiledepositModalComponent,
        AddPageModalComponent,
        AddPredefinedCardModalComponent,
        AddPredefinedCardModalComponent,
        AppComponent,
        CardBoxComponent,
        CardConfigComponent,
        CardThemeDesignerComponent,
        ContentManagementComponent,
        DatatableAppMailRowActionComponent,
        DatatableUserMailRowActionComponent,
        DBConnectionManagerComponent,
        EditFiledepositModalComponent,
        EmailConfirmationComponent,
        EmailManagerComponent,
        ExportCardConfigComponent,
        ExportPageConfigModalComponent,
        FiledepositManagerComponent,
        FooterComponent,
        ForgotPasswordComponent,
        HeaderComponent,
        HomeComponent,
        ImportPageModalComponent,
        LoadingComponent,
        LoginComponent,
        MaskPhoneDirective,
        PageNotFoundComponent,
        PageUnauthorizedComponent,
        PersistentNotificationComponent,
        PreviewFiledepositModalComponent,
        ProfileComponent,
        RoleComponent,
        RowsComponent,
        SaveCardConfigComponent,
        TaskSchedulerManagerComponent,
        ToastNotificationComponent,
        TranslateManagerComponent,
        UploaderManagerComponent,
        UserEmailManagerComponent,
        UsersComponent,
        EditTranslateModalComponent,
    ],
    imports: [
        AddRoutingModule,
        AngularEditorModule,
        BrowserModule,
        DataTablesModule,
        DragulaModule.forRoot(),
        FileUploadModule,
        FontAwesomeModule,
        FormsModule,
        GoogleMapsModule,
        HerdiaCommonModule,
        HttpClientModule,
        LibCardHeaderCommonButtonsModule,
        LibConfirmBoxModule,
        LibCronEditorModule,
        LibDatatablePaginationModule,
        LibEditModeModule,
        LibHerdiaAppTranslateModule,
        LibHerdiaDatatableModule,
        LibPropertyTypeInputModule,
        LibSchedulerServiceModule,
        LibUploaderModule,
        NgbModule,
        NgSelectModule,
        ReactiveFormsModule,
        SocialLoginModule,
        LibUserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LibHerdiaAppTranslateCustomLoader,
                deps: [HttpClient, LibHttpClientApiService]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: LibHerdiaAppMissingTranslationHandler
            },
            useDefaultLang: false,
            isolate: false
        }),
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInitInterceptor, multi: true },
        {
            provide: 'SocialAuthServiceConfig',
            useValue:
                {
                    autoLogin: false,
                    providers:
                        [
                            {
                                id: GoogleLoginProvider.PROVIDER_ID,
                                provider: new GoogleLoginProvider
                                    ('456102707862-hu882fp9lir9oa7p6e8ta1mm3atsn3tg.apps.googleusercontent.com')
                            }
                        ],
                    onError: (err) => {
                        console.error(err);
                    }
                } as SocialAuthServiceConfig,
        },
        LibHerdiaAppEnvironmentService,
        NgbActiveModal,
        TranslatePipe,
    ],
    exports: [
        AppComponent
    ]
})
export class CoreModule { }
