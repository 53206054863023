import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent, ColumnSetting, LibHttpClientApiService } from '@herdia-common';
import { TranslatePipe } from "@ngx-translate/core";
import { ReportColumnSetting } from '../lib-card-report.interfaces';
import { LibCardReportService } from '../lib-card-report.service';

@Component({
    selector: 'lib-card-report-config-report',
    templateUrl: './lib-card-report-config-report.component.html',
    styleUrls: ['./lib-card-report-config-report.component.css']
})
export class LibCardReportConfigReportComponent extends CardConfigBaseComponent implements OnInit {

    numberTypes: string[] = ["int", "Int32", "Int32?", "int?", "decimal", "decimal?", "Decimal", "Decimal?", "float", "float?"];
    booleanTypes: string[] = ["bool", "Boolean", "boolean"];
    dateTypes: string[] = ["Datetime", "Datetime?", "Date", "Date?", "DateTime", "DateTime?"]

    availableDecimals: number[] = [1, 2, 3, 4, 5];

    constructor(private apiService: LibHttpClientApiService, private translatePipe: TranslatePipe, private cardReportService: LibCardReportService) {
        super();
    }

    availableColumns: ReportColumnSetting[] = [];
    availableColumnsFiltered: ReportColumnSetting[] = [];
    selectedColumns: ReportColumnSetting[] = [];

    availableCols: ColumnSetting[] = [];
    selectedCols: ColumnSetting[] = [];

    ngOnInit(): void {
        if (!this.config["WebReport"])
            this.config["WebReport"] = {};

        if (this.config["DataSource"]["ProcedureName"]) {
            let resultSet = this.apiService.getActionResult("/api/alhambra", this.config["DataSource"]["ProcedureName"], "Datas", "post").responses;

            if (!this.config["WebReport"]["ColumnsSettings"]) {
                this.config["WebReport"]["ColumnsSettings"] = {};
            }

            let buffer: ReportColumnSetting[] = [];
            let buf: ColumnSetting[] = [];

            Object.keys(resultSet).forEach((key: string) => {
                let item = resultSet[key];
                let csType = this.apiService.getCSTypeFromJSONType(item);
                buf.push({
                    name: key,
                    label: this.translatePipe.transform(key.toUpperCase()),
                    nature: "normal",
                    nbDecimal: 2,
                    type: csType,
                    displaySum: false,
                    horizontalAlign: this.cardReportService.getDefaultAlignForType(csType),
                    visible: true,
                    width: 150,
                    actionColumnDef: null
                })

                buffer.push({
                    sqlName: key.toUpperCase(),
                    csName: key,
                    csType: csType,
                    label: this.translatePipe.transform(key.toUpperCase()),
                    showColumn: true,
                    nbDecimal: 2,
                    nature: "normal",
                    hAlign: this.cardReportService.getDefaultAlignForType(csType),
                    webColumnWidth: 150,
                    additional: true
                });
            });
            this.availableColumns = buffer;
            this.availableColumnsFiltered = buffer;
            this.availableCols = buf;
        }

        if (this.config["WebReport"]["ColumnsSettings"] && Object.keys(this.config["WebReport"]["ColumnsSettings"]).length > 0) {
            // Compatibility with previous ReportColumnSetting, we transform ReportColumnSetting interface to ColumnSetting interface
            if (this.config['WebReport']['ColumnsSettings'][0]['sqlName']) {
                this.selectedCols = this.config['WebReport']['ColumnsSettings'].map((c: ReportColumnSetting) => {
                    return {
                        name: c.sqlName,
                        label: this.translatePipe.transform(c.sqlName.toUpperCase()),
                        nature: c.nature,
                        nbDecimal: c.nbDecimal,
                        type: c.csType,
                        displaySum: c.additional,
                        horizontalAlign: this.cardReportService.getDefaultAlignForType(c.csType),
                        visible: true,
                        width: c.webColumnWidth
                    }
                });
            }
            else {
                this.selectedCols = this.config['WebReport']['ColumnsSettings'];
            }
        }
    }

    isNumber(type: string): boolean {
        return this.numberTypes.indexOf(type) !== -1;
    }

    onSelectedColumnsChange(e: any): void {
        this.config['WebReport']['ColumnsSettings'] = this.selectedCols;
    }

    removeColumn(indexColumn: number) {
        this.selectedCols.splice(indexColumn, 1);
        this.onSelectedColumnsChange(null);
    }
}
