import { Component, OnInit } from '@angular/core';
import { ColumnSetting, LibHttpClientApiService } from '@herdia-common';
import { TranslatePipe } from '@ngx-translate/core';
import { UploadUrls } from '../shared/service/api.service';

@Component({
  selector: 'app-uploader-manager',
  templateUrl: './uploader-manager.component.html',
  styleUrls: ['./uploader-manager.component.scss']
})
export class UploaderManagerComponent implements OnInit {

  cardId: number = 999999992;

  DeleteUpload = (data: any): void => {
    // TODO: Coder le delete d'un upload
  }

  DownloadUpload = (data: any): void => {
    // TODO: Coder le download... d'un upload... :D
  }

  cardCRUDUploadConfig = {
    "icon": "file-download",
    "cardId": 999999992,
    "DataSource": {
      "Context": "HerdiaApp.Api.Models.ApiDbContext",
      "Entity": {
        "Name": "HerdiaApp.Api.Models.Common.HAUploadDefinition",
        "Properties": [
          {
            "Name": "Id",
            "Type": "Int32",
            "Options": [
              2
            ]
          },
          {
            "Name": "FileName",
            "Type": "String",
            "Options": [

            ]
          },
          {
            "Name": "DateUpload",
            "Type": "DateTime",
            "Options": [

            ]
          },
          {
            "Name": "DayRetention",
            "Type": "Int32",
            "Options": [

            ]
          },
          {
            "Name": "SensitiveData",
            "Type": "Boolean",
            "Options": [

            ]
          },
          {
            "Name": "MimeType",
            "Type": "String",
            "Options": [

            ]
          },
          {
            "Name": "MaxSize",
            "Type": "Int64",
            "Options": [

            ]
          },
          {
            "Name": "Hash",
            "Type": "String",
            "Options": [

            ]
          },
          {
            "Name": "Path",
            "Type": "String",
            "Options": [

            ]
          },
          {
            "Name": "Size",
            "Type": "Single",
            "Options": [

            ]
          },
          {
            "Name": "Nature",
            "Type": "HAUploadNature?",
            "Options": [
              3
            ]
          }
        ],
        "ColumnsSettings": [
          {
            "name": "Id",
            "label": "ID",
            nature: 'normal',
            "nbDecimal": 0,
            "type": "Int32",
            "displaySum": false,
            "horizontalAlign": "center",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            "name": "FileName",
            "label": "FILENAME",
            'nature': 'normal',
            "nbDecimal": 0,
            "type": "String",
            "displaySum": false,
            "horizontalAlign": "left",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            "name": "DateUpload",
            "label": "DATEUPLOAD",
            nature: 'normal',
            "nbDecimal": 0,
            "type": "DateTime",
            "displaySum": false,
            "horizontalAlign": "center",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            "name": "DayRetention",
            "label": "DAYRETENTION",
            nature: 'normal',
            "nbDecimal": 0,
            "type": "Int32",
            "displaySum": false,
            "horizontalAlign": "center",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            "name": "SensitiveData",
            "label": "SENSITIVEDATA",
            nature: 'normal',
            "nbDecimal": 0,
            "type": "Boolean",
            "displaySum": false,
            "horizontalAlign": "center",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            "name": "MimeType",
            "label": "MIMETYPE",
            nature: 'normal',
            "nbDecimal": 0,
            "type": "String",
            "displaySum": false,
            "horizontalAlign": "left",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            "name": "Size",
            "label": "SIZE",
            nature: 'normal',
            "nbDecimal": 0,
            "type": "Single",
            "displaySum": false,
            "horizontalAlign": "center",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            "name": "Nature",
            "label": "NATURE",
            nature: 'normal',
            "nbDecimal": 0,
            "type": "HAUploadNature?",
            "displaySum": false,
            "horizontalAlign": "center",
            "visible": true,
            "width": 150,
            "actionColumnDef": null
          },
          {
            name: "Actions",
            label: "Actions",
            type: "custom",
            visible: true,
            actionColumnDef: {
              align: "center",
              buttons: [
                {
                  type: "button",
                  icon: "download",
                  text: "",
                  class: 'btn btn-primary btn-sm btn-tool',
                  onclick: this.DeleteUpload
                },
                {
                  type: "button",
                  icon: "trash",
                  text: "",
                  class: 'btn btn-primary btn-sm btn-tool',
                  onclick: this.DeleteUpload
                }
              ]
            },
            displaySum: false,
            nbDecimal: 0,
            nature: 'normal',
            width: 100,
            horizontalAlign: "center"
          }
        ] as ColumnSetting[]
      },
      "Type": "DbSet",
      "AvailableColumns": [
        {
          "name": "Id",
          "label": "ID",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "Int32",
          "displaySum": false,
          "horizontalAlign": "center",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "FileName",
          "label": "FILENAME",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "String",
          "displaySum": false,
          "horizontalAlign": "left",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "DateUpload",
          "label": "DATEUPLOAD",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "DateTime",
          "displaySum": false,
          "horizontalAlign": "center",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "DayRetention",
          "label": "DAYRETENTION",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "Int32",
          "displaySum": false,
          "horizontalAlign": "center",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "SensitiveData",
          "label": "SENSITIVEDATA",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "Boolean",
          "displaySum": false,
          "horizontalAlign": "center",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "MimeType",
          "label": "MIMETYPE",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "String",
          "displaySum": false,
          "horizontalAlign": "left",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "MaxSize",
          "label": "MAXSIZE",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "Int64",
          "displaySum": false,
          "horizontalAlign": "center",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "Hash",
          "label": "HASH",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "String",
          "displaySum": false,
          "horizontalAlign": "left",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "Path",
          "label": "PATH",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "String",
          "displaySum": false,
          "horizontalAlign": "left",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "Size",
          "label": "SIZE",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "Single",
          "displaySum": false,
          "horizontalAlign": "center",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        },
        {
          "name": "Nature",
          "label": "NATURE",
          "nature": "normal",
          "nbDecimal": 0,
          "type": "HAUploadNature?",
          "displaySum": false,
          "horizontalAlign": "center",
          "visible": true,
          "width": 150,
          "actionColumnDef": null
        }
      ]
    },
    "DataTableConfig": {
      "compactMode": true,
      "cellBorder": true,
      "hover": true,
      "orderColumn": true,
      "rowBorder": false,
      "stripe": true,
      "nowrap": true,
      "defaultRowNumberOnPage": 10,
      autoWidth: false,
      fixedLeftColumns: 0
    }
  };

  constructor(private translatePipe: TranslatePipe, private apiSvc: LibHttpClientApiService) {
  }

  ngOnInit(): void {
  }

  exportZip(): any {
    return this.apiSvc.downloadFile(UploadUrls.EXPORT, "HAAPP_Content_backup.zip");
  }

  onFileSelect(input: any): any {
    const formData = new FormData();
    formData.append('File', input.files[0])
    return this.apiSvc.post<any, any>(UploadUrls.IMPORT, formData)?.subscribe();
  }
}
