<div class="login-box">
    <div class="login-logo">
        <a href="../../index2.html"><b>{{'app-title'|translate}}</b></a>
    </div>
    <div class="card">
        <div class="card-body login-card-body">
            <div class="container">
                <div class="login-box-msg">
                <div class="row">
                    <div class="col-lg-7 mt-2">{{'login-from'|translate}}</div>
                    <div class="col-lg-3">
                    <ng-select [clearable]="false" [searchable]="false" class="langSelector" [ngModel]="selectedLocal" (ngModelChange)="onLocalChanged($event)" [items]="['en-GB', 'fr-FR', 'de-DE']">
                        <ng-template ng-label-tmp let-item="item">
                        <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                        <span class="fi" [ngClass]="{ 'fi-gb': item==='en-GB', 'fi-fr': item==='fr-FR', 'fi-de': item==='de-DE' }"></span>
                        </ng-template>
                    </ng-select>
                    </div>
                </div>
                </div>
            </div>
            <div class="card-body login-card-body">
                <p class="login-box-msg">{{'text-desciption-send-mail-forgot-password'|translate}}</p>

                <form *ngIf="ShowFormSendMail" [formGroup]="formSendMail" (ngSubmit)="sendMail()">
                    <div class="input-group mb-3">
                        <input [ngClass]="{'is-invalid':submitted && formSendMail.controls['email'].errors}" formControlName="email" type="email" class="form-control" placeholder="{{'email'|translate}}">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <fa-icon icon="envelope"></fa-icon>
                            </div>
                        </div>
                        <span class="error invalid-feedback" *ngIf="submitted && getError(formSendMail, 'email', 'required')"> 
                            {{'required-email'|translate}}
                        </span>
                        <span class="error invalid-feedback" *ngIf="submitted && getError(formSendMail, 'email', 'email')"> 
                            {{'invalid-email'|translate}}
                        </span>
                    </div>
                    
                    <span class="error invalid-feedback" *ngIf="ShowErrorSendingMail">
                        {{'email-not-find'|translate}}
                    </span>
                    <span class="valid-feedback" *ngIf="ShowValidateSendingMail">
                        {{'email-send'|translate}}
                    </span>

                    <div class="row">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block">{{'request-new-password'|translate}}</button>
                        </div>
                    </div>
                </form>

                <form *ngIf="ShowFormChangePassword" [formGroup]="formNewpassword" (ngSubmit)="changePassword()">
                    <div class="input-group mb-3">
                        <input readonly type="text" class="form-control" placeholder="{{user_email}}">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <fa-icon icon="envelope"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input [type]="ShowPassword ? 'password' : 'text'" id="password" [ngClass]="{'is-invalid': (submitted && formNewpassword.controls['password'].errors) || ShowErrorValidationPassword}" formControlName="password"  class="form-control" placeholder="{{'password'|translate}}">
                        <div class="input-group-append">
                            <button type="button" class="input-group-text" (click)="showPassword('passsword')">
                                <fa-icon icon="eye" id="show_eye"></fa-icon>
                                <fa-icon icon="eye-slash" id="hide_eye"></fa-icon>
                            </button>
                        </div>
                            
                        <span class="error invalid-feedback" *ngIf="submitted && getError(formNewpassword, 'password', 'required')"> 
                            {{'required-password'|translate}}
                        </span>
               
                    </div>

                    

                    <div class="input-group mb-3">
                        <input [type]="ShowPasswordConfirmation ? 'password' : 'text'" id="passwordConfirm" [ngClass]="{'is-invalid': (submitted && formNewpassword.controls['password_confirm'].errors) || ShowErrorValidationPassword }" formControlName="password_confirm" class="form-control" placeholder="{{'confirmation-password'|translate}}">
                        <div class="input-group-append">
                            <button type="button" class="input-group-text" (click)="showPassword('passwordConfirmation')">
                                <fa-icon icon="eye" id="show_eye2"></fa-icon>
                                <fa-icon icon="eye-slash" id="hide_eye2"></fa-icon>
                            </button>
                        </div>
                        
                        <span class="error invalid-feedback" *ngIf="submitted && getError(formNewpassword, 'password_confirm', 'required')"> 
                            {{'required-password'|translate}}
                        </span>
                        <span class="error invalid-feedback" *ngIf="ShowPasswordNotMatch"> 
                            {{'no-match-password'|translate}}
                        </span>
                    </div>

                    <span class="valid-feedback" *ngIf="ShowValidateChangePassword">
                        {{'confirmation-change-password'|translate}}
                    </span>
                    <span class="error invalid-feedback" id="errorValidationPassword" *ngIf="ShowErrorValidationPassword">
                        {{PasswordValidationErrors}}
                    </span>

                    <div class="row">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block">{{'change-password-btn'|translate}}</button>
                        </div>
                    </div>
                </form>
                <p class="mt-3 mb-1">
                    <a href="../login">{{'login'|translate}}</a>
                </p>
            </div>
        </div>
    </div>
</div> 
