import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { LibHerdiaAppTranslateModule } from '../lib-herdia-app-translate/lib-herdia-app-translate.module';
import { LibUploaderComponent } from './lib-uploader.component';

@NgModule({
    declarations: [
        LibUploaderComponent
    ],
    imports: [
        FileUploadModule,
        CommonModule,
        NgbAlertModule,
        FontAwesomeModule,
        LibHerdiaAppTranslateModule,
        TranslateModule
    ],
    exports: [
        LibUploaderComponent
    ]
})
export class LibUploaderModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
    }
}
