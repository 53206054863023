import { NgModule } from '@angular/core';
import { RoleDirective } from './lib-user-directives/role.directives';

@NgModule({
  declarations: [
    RoleDirective
  ],
  imports: [
  ],
  exports: [
    RoleDirective
  ]
})
export class LibUserModule { }
