import { Component, Input } from '@angular/core';
import { LibConfirmBoxService } from './lib-confirm-box.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-confirm-box',
  templateUrl: './lib-confirm-box.component.html',
  styleUrls: ['./lib-confirm-box.component.scss']
})
export class LibConfirmBoxComponent {

  @Input() title = '';
  @Input() msg = '';
  @Input() okBtnLbl = 'Confirmer';
  @Input() koBtnLbl = 'Annuler';

  constructor(private svc: LibConfirmBoxService, private activeModal: NgbActiveModal) {  }

  dismiss() {
    return this.activeModal.dismiss();
  }

  cancel() {
    this.activeModal.dismiss();
    this.svc.cancel();
  }

  confirm() {
    this.activeModal.close();
    this.svc.confirm();
  }
}
