<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="btn-close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <p>{{msg}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">{{koBtnLbl}}</button>
  <button type="button" class="btn btn-danger" (click)="confirm()">{{okBtnLbl}}</button>
</div>
