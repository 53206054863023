  <ng-select [multiple]="true"
           [closeOnSelect]="false"
           [items]="ListBuildings"
           [(ngModel)]="BuildingsSelected"
           bindLabel="BuildingName"
           (ngModelChange)="onBuildingSelectedChange()"
           (close)="onNgSelectBuildingsClose()"
           (open)="onNgSelectBuildingsOpen()"
           groupBy="ImportDate">
  <ng-template ng-multi-label-tmp let-items="items">
    <div *ngIf="NgSelectBuildingsisClose">
      <div *ngIf="items.length >= 2">
        <span>{{BuildingsSelected.length}}&nbsp;<lib-translate [key]="'lbl-buildings'"></lib-translate></span>
      </div>
      <div *ngIf="items.length == 1">
        <span>{{BuildingsSelected.length}}&nbsp;<lib-translate [key]="'lbl-building'"></lib-translate></span>
      </div>
    </div>
    <span *ngIf="!NgSelectBuildingsisClose" style="display:block" class="ng-placeholder"> </span>
  </ng-template>
  <ng-template ng-header-tmp>
    <div class="multiSelectButtons">
      <button (click)="selectAllBuildings()" class="btn btn-sm btn-primary"><lib-translate [key]="'select-all'"></lib-translate></button>
      <button (click)="unselectAllBuildings()" class="btn btn-sm btn-secondary"><lib-translate [key]="'select-none'"></lib-translate></button>
      <button class="btn btn-sm btn-secondary" [popoverTitle]="popTitle" [ngbPopover]="popContent" [autoClose]="'outside'" [placement]="'right'">
        <lib-translate [key]="'portfolios'"></lib-translate>
      </button>
      <ng-template #popTitle>
        <lib-translate [key]="'list-of-portfolios'"></lib-translate>
      </ng-template>
      <ng-template #popContent>
        <ng-select id="ngSelectPortfolios"
                   [closeOnSelect]="false"
                   [items]="portfolioItems"
                   [(ngModel)]="PortfolioSelected"
                   (change)="onPortfolioSelected()"
                   groupBy="importDate"
                   bindLabel="portfolioLabel"
                   bindValue="portfolioItem">
          <ng-template ng-optgroup-tmp let-item="item">
            {{item.importDate}}
          </ng-template>
        </ng-select>
      </ng-template>
    </div>
  </ng-template>
  <ng-template ng-optgroup-tmp let-item="item">
    {{item.ImportDate}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="ListCheckboxes">
      <input id="item-{{index}}" type="checkbox" class="form-check-input" [ngModel]="item$.selected" /> {{item.BuildingName}}
    </div>
  </ng-template>
  <ng-template ng-footer-tmp>
    {{BuildingsSelected.length}} <lib-translate [key]="'items-selected'"></lib-translate>
  </ng-template>
</ng-select>



