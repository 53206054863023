import { Component } from '@angular/core';
import { CardConfigBaseComponent } from '@herdia-common';

@Component({
    selector: 'lib-card-chart-footer',
    templateUrl: './lib-card-chart-footer.component.html',
    styleUrls: ['./lib-card-chart-footer.component.css']
})
export class LibCardChartFooterComponent extends CardConfigBaseComponent {
}
