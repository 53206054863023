import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent } from '../../dynamic-components/card-config-base-component';
import { EntityDefinition, EntityDefinitionSqlRequest } from '../lib-card-generic-crud.interface';
import { LibCardGenericCRUDService } from '../lib-card-generic-crud.service';

@Component({
    selector: 'lib-card-generic-crud-config',
    templateUrl: './lib-card-generic-crud-config.component.html',
    styleUrls: ['./lib-card-generic-crud-config.component.scss']
})
export class LibCardGenericCrudConfigComponent extends CardConfigBaseComponent implements OnInit {

    selectedDbSetOrSQL: string = "DbSet";

    entityContextList: string[] = [];
    selectedEntityContext: string = "";


    entityList: EntityDefinition[] = [];
    selectedEntity: EntityDefinition = { Name: "", Properties: [], ColumnSettings: [] };

    SQLQuery: string = "";

    constructor(private libCardGenericCrudService: LibCardGenericCRUDService) {
        super();
    }

    ngOnInit(): void {
        if (this.config["DataSource"] === undefined) {
            this.config["DataSource"] = { Context: "", Entity: "" };
            this.config["DataSource"]['Type'] = "DbSet";
        }

        if (this.config["DataTableConfig"] == null) {
            this.config["DataTableConfig"] = {
                compactMode: false,
                cellBorder: true,
                hover: true,
                orderColumn: true,
                rowBorder: false,
                stripe: true,
                nowrap: false,
                defaultRowNumberOnPage: 10
            };
        }

        this.selectedDbSetOrSQL = this.config["DataSource"]['Type'];

        this.libCardGenericCrudService.getContexts().subscribe(list => { this.entityContextList = list });

        if (this.config["DataSource"]['Context'] !== undefined &&
            this.config["DataSource"]['Context'] !== "") {
            this.selectedEntityContext = this.config["DataSource"]['Context'];
            this.libCardGenericCrudService.getEntities(this.selectedEntityContext).subscribe(list => {
                this.entityList = list;
                if (this.config["DataSource"]['Entity'] !== undefined &&
                    this.config["DataSource"]['Entity'] !== "") {
                    this.selectedEntity = this.config["DataSource"]['Entity'];
                }
            });
            if (this.config["DataSource"]['Query'] !== undefined &&
                this.config["DataSource"]['Query'] !== "") {
                this.SQLQuery = this.config["DataSource"]['Query'];
            }
        }
    }

    testQueryAndUpdateModels(): void {
        let request: EntityDefinitionSqlRequest = { ContextTypeName: this.config["DataSource"]['Context'], SqlQuery: this.config["DataSource"]['Query'] }
        this.config["DataSource"]['Query'] = this.SQLQuery;
        this.config["DataSource"]['Type'] = "SQL";
        this.libCardGenericCrudService.getEntityDefinitionSqlRequest(request)?.subscribe((v: any) => {
            this.config["DataSource"]['Entity'] = v.Entity;
        });
    }
    saveCallback = async (): Promise<boolean> => {
        if (this.selectedDbSetOrSQL == "SQL") {
            let request: EntityDefinitionSqlRequest = { ContextTypeName: this.config["DataSource"]['Context'], SqlQuery: this.config["DataSource"]['Query'] }
            this.config["DataSource"]['Query'] = this.SQLQuery;
            this.config["DataSource"]['Type'] = "SQL";

            const entityDefinition = await this.libCardGenericCrudService.getEntityDefinitionSqlRequest(request)?.toPromise();
            if (entityDefinition.QuerySuccessful) {
                this.config["DataSource"]['Entity'] = entityDefinition.Entity;
                return true;
            }
            else {
                return false;
            }
        }
        else {
            this.config["DataSource"]['Type'] = "DbSet";
            return true
        }
    }

    onRadioChange() {
        if (this.selectedDbSetOrSQL == "SQL")
            this.config["DataSource"] = { Context: this.selectedEntityContext, Query: this.SQLQuery };
        else
            this.config["DataSource"] = { Context: this.selectedEntityContext, Entity: this.selectedEntity };
    }

    onContextChange(context: string): void {
        if (context !== undefined && context !== "") {
            this.config["DataSource"]['Context'] = context;
            this.libCardGenericCrudService.getEntities(context).subscribe(list => { this.entityList = list })
        }
    }

    onSQLQueryChange(event: any): void {
        this.config["DataSource"]['Query'] = event.target.value;
        this.SQLQuery = event.target.value;
    }

    onEntityChange(entity: EntityDefinition): void {
        if (entity !== undefined) {
            this.config["DataSource"]['Entity'] = entity;
        }
    }
}
