import { Component, Input } from '@angular/core';
import { PropertyDefinition, PropertyItemDefinition } from '@herdia-common';

@Component({
    selector: 'lib-data-input-parameters',
    templateUrl: './lib-data-input-parameters.component.html',
    styleUrls: ['./lib-data-input-parameters.component.css']
})
export class LibDataInputParametersComponent {

    @Input() config: { [key: string]: any; } = {};
    @Input() displayStoredProcedure!: boolean;
    @Input() allowRawBinding!: boolean;
    @Input() allowCardBinding!: boolean;
    @Input() allowPageBinding!: boolean;
    @Input() listValuesPropertyDefinitions: PropertyDefinition[] = [];

    getPropertyDefinition(propertyName: string): PropertyItemDefinition[] {
        let index: number = this.listValuesPropertyDefinitions.findIndex(pd => pd.Name == propertyName);
        if (index !== -1)
            return this.listValuesPropertyDefinitions[index].AvailableItems;
        return [];
    }
}
