import { Component } from '@angular/core';
import { CardBaseComponent } from '@herdia-common';
import { Context } from '../../lib-header-binding/lib-header-binding.enum';

@Component({
    selector: 'lib-card-report-header-buttons',
    templateUrl: './lib-card-report-header-buttons.component.html',
    styleUrls: ['./lib-card-report-header-buttons.component.scss']
})
export class LibCardReportHeaderButtonsComponent extends CardBaseComponent {
  context = Context.Report;
  constructor() {
      super();
  }

  hasCardFilter(): boolean {
    return this.config["DataSource"]["Parameters"].filter((p: any) => p["ParameterBinding"]["Type"] === "cardFilter" && p.ParameterName !== "AnalysisDate" && p.ParameterName !== "StartDate" && p.ParameterName !== "EndDate").length > 0;
  }
}
