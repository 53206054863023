import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LibEditModeService } from '../lib-edit-mode/lib-edit-mode.service';
import { LibTranslateEditModalComponent } from './lib-translate-edit-modal/lib-translate-edit-modal.component';

@Component({
    selector: 'lib-translate',
    templateUrl: './lib-translate.component.html',
    styleUrls: ['./lib-translate.component.scss']
})
export class LibTranslateComponent implements OnInit {
    @Input() key: string = "";
    translation: string = "";
    constructor(
        private translatePipe: TranslatePipe,
        private modalService: NgbModal,
        private translateService: TranslateService,
        private editModeService: LibEditModeService
    ) { }

    get getEditModeService() {
        return this.editModeService;
    }

    ngOnInit(): void {
        this.translation = this.translatePipe.transform(this.key);
    }

    openTranslateModal(): void {
        const modalRef = this.modalService.open(LibTranslateEditModalComponent);
        modalRef.componentInstance.key = this.key;
        modalRef.componentInstance.currentTranslationLabel = this.translation;
        modalRef.result.then((v: any) => {
            this.translation = this.translatePipe.transform(this.key);
        });
    }
}
