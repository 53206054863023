<div class="d-inline-flex justify-content-between w-100">
  <div class="btn-group btn-group-sm">
    <input type="checkbox" id="compactMode" class="btn-check" [checked]="dtConfig.compactMode" [(ngModel)]="dtConfig.compactMode">
    <label class="btn btn-outline-primary" for="compactMode"><lib-translate [key]="'compact-display'"></lib-translate></label>
  </div>
  <div class="btn-group btn-group-sm">
    <input type="checkbox" id="cellBorder" class="btn-check" [checked]="dtConfig.cellBorder" [(ngModel)]="dtConfig.cellBorder">
    <label class="btn btn-outline-primary" for="cellBorder"><lib-translate [key]="'cellBorder-display'"></lib-translate></label>
  </div>
  <div class="btn-group btn-group-sm">
    <input type="checkbox" id="stripe" class="btn-check" [checked]="dtConfig.stripe" [(ngModel)]="dtConfig.stripe">
    <label class="btn btn-outline-primary" for="stripe"><lib-translate [key]="'stripe-display'"></lib-translate></label>
  </div>
  <div class="btn-group btn-group-sm">
    <input type="checkbox" id="nowrap" class="btn-check" [checked]="dtConfig.nowrap" [(ngModel)]="dtConfig.nowrap" (change)="onWrapChange($event)">
    <label class="btn btn-outline-primary" for="nowrap"><lib-translate [key]="'nowrap-display'"></lib-translate></label>
  </div>
  <div class="btn-group btn-group-sm">
      <input type="checkbox" id="autoWidth" class="btn-check" [checked]="dtConfig.autoWidth" [(ngModel)]="dtConfig.autoWidth" (change)="onAutoWidthChange($event)">
    <label class="btn btn-outline-primary" for="autoWidth"><lib-translate [key]="'autoWidth-display'"></lib-translate></label>
  </div>
</div>
<div class="d-inline-flex justify-content-between w-100 mt-4">
  <div>
    <label for="defaultRowNumberOnPage"><lib-translate [key]="'defaultRowNumberOnPage-display'"></lib-translate></label>
    <ng-select id="defaultRowNumberOnPage" class="ngSelectPerPage"
               [items]="linePerPage"
               bindLabel="value"
               bindValue="id"
               [(ngModel)]="dtConfig.defaultRowNumberOnPage"
               [searchable]="false"
               [clearable]="false">
    </ng-select>
  </div>
  <div>
    <label for="defaultLeftFixedColumns"><lib-translate [key]="'leftFixedColumnsNumber-display'"></lib-translate></label>
    <input min="0" type="number" id="defaultLeftFixedColumns" class="form-control form-control-sm" [(ngModel)]="dtConfig.fixedLeftColumns">
  </div>
</div>
