import { ICardConfig } from '../lib-interface-card/lib-interface-card.interfaces';
import { ComponentFactoryResolver, Injectable, Injector, NgModuleRef, Type, inject, reflectComponentType } from '@angular/core';
import { DynamicComponentItem, DynamicComponentPackage, DynamicComponentType } from './dynamic-components.model'

/**
 * TODO(migration): set minWidth from the registerDynamicComponent()
 * TODO(migration): use a human friendly name rather than the selector
 */
interface DynamicCardOption {
    package: DynamicComponentPackage
    selector: string
    minWidth: number
    disabled: boolean
}

/**
 * TODO(migration): From ICardConfig interface, externalize minWidth and configTreeNode in order to not have to instanciate component using ComponentFactoryResolver.
 * This could be replaced by refactoring config components with [content projection](https://angular.io/guide/content-projection)
 */
@Injectable({
    providedIn: 'root'
})
export class DynamicComponentsService {

    private componentFactoryResolver = inject(ComponentFactoryResolver);
    private injector = inject(Injector);

    private dynamicComponents: { [selector: string]: DynamicComponentItem } = {};

    public registerDynamicComponent(
        packageName: DynamicComponentPackage,
        module: NgModuleRef<any>,
        component: Type<any>,
        type: DynamicComponentType,
    ): void {
        const selector = reflectComponentType(component)?.selector;
        if (!selector) return;
        this.dynamicComponents[selector] = { module, component, package: packageName, type };
    }

    public getDynamicComponent(
        selector: string,
    ): DynamicComponentItem | undefined {
        return this.dynamicComponents[selector];
    }

    public getDynamicCardOptions(): DynamicCardOption[] {
        return Object.entries(this.dynamicComponents)
            .filter(([_selector, dynamicComponent]) => dynamicComponent.type === 'card')
            .map(([selector, dynamicComponent]) => {
                const componentConfig = this.getComponentInstance(dynamicComponent.component, dynamicComponent.module);
                return {
                    package: dynamicComponent.package,
                    selector: selector,
                    minWidth: componentConfig.minWidth,
                    disabled: false,
                }
            });
    }

    public getDynamicCardConfigSelectors(): string[] {
        return Object.entries(this.dynamicComponents)
            .filter(([_selector, dynamicComponent]) => dynamicComponent.type === 'config')
            .map(([selector]) => selector);
    }

    /**
     * TODO(migration): remove this function and stop relying on deprecated ComponentFactoryResolver
     */
    private getComponentInstance(
        component: Type<any>,
        module: NgModuleRef<any>,
    ): ICardConfig {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = componentFactory.create(this.injector, undefined, undefined, module);
        return componentRef.instance as ICardConfig;
    }

    public getCardComponentConfig(
        selector: string
    ): ICardConfig | undefined {
        const dynamicComponent = this.getDynamicComponent(selector);
        if (!dynamicComponent) return;
        return this.getComponentInstance(dynamicComponent.component, dynamicComponent.module);
    }

}
