import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ServerSideResponse } from '@herdia-common';
import { Observable } from 'rxjs';
import { LibHttpClientApiService } from '@herdia-common';
import { ColumnSetting } from '@herdia-common';
import { LibUserService } from '@herdia-common';

export enum ReportUrls {
  GET_WEB_REPORT = 'api/ReportCard/GetWebReport',
  ASK_RICH_REPORT = 'api/ReportCard/AskRichReport'
}

@Injectable({
  providedIn: 'root'
})
export class LibCardReportService {
  constructor(private apiSvc: LibHttpClientApiService, private datePipe: DatePipe, private userService: LibUserService) { }

  read(reportWebRequest: any): Observable<ServerSideResponse>{
    return this.apiSvc.post<any, ServerSideResponse>(ReportUrls.GET_WEB_REPORT, reportWebRequest);
  }

  askReport(askReportRequest: any): Observable<any> | null {
    return this.apiSvc.post<any, any>(ReportUrls.ASK_RICH_REPORT, askReportRequest);
  }

  formatServerType(data: any, columnSetting: ColumnSetting, locale: string): any {
    if (data === "" || data === null)
      return "";
    if (data === "&nbsp;")
      return "&nbsp;";
    switch (columnSetting.type) {
      case "DateTime?":
      case "DateTime":
        return this.datePipe.transform(data, this.userService.getValueFromAKey(this.userService.user!.DateFormat, this.userService.AllDateFormatForDatePipe));
      case "short?":
      case "short":
      case "decimal?":
      case "Decimal":
      case "decimal":
      case "Decimal?":
          if (isNaN(data))
              return "";
          if (typeof data === "string") {
              data = data.replace(",", ".");
              data = parseFloat(data);
          }
          if (columnSetting.nature === "percent")
            data = data * 100;
          return this.round(data, columnSetting.nbDecimal).toLocaleString(locale, { minimumFractionDigits: columnSetting.nbDecimal })
            .concat(columnSetting.nature === "percent" ? " %" : "");
      case "int":
      case "int?":
      case "char":
      case "String":
      case "string?":
      case "string":
          return data;
      default:
          console.log("AlhDatatable, Type non géré: " + columnSetting.type + ", il sera affiché avec un format par défaut");
          return data;
    }
  }

  round(value: number, precision: number): number {
    if (precision < 0) {
      let factor = Math.pow(10, precision);
      return Math.round(value * factor) / factor;
    }
    else
      return +(Math.round(Number(value + "e+" + precision)) + "e-" + precision);
  }

  getDefaultAlignForType(type: string): "left" | "center" | "right" {
    switch (type) {
      default:
        return "center";
      case "string?":
      case "string":
        return "left";
      case "DateTime":
      case "DateTime?":
        return "center";
      case "decimal":
      case "decimal?":
      case "int":
      case "int?":
        return "right";
    }
  }

  getAlignmentClassName(requestedAlignment: string): string {
    switch (requestedAlignment) {
      case "left":
        return "dt-left";
      default:
      case "center":
        return "dt-center";
      case "right":
        return "dt-right";
    }
  }
}


