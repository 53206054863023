import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicComponentComponent } from './dynamic-component/dynamic-component.component';

@NgModule({
    declarations: [
        DynamicComponentComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        DynamicComponentComponent,
    ]
})
export class DynamicComponentsModule { }
