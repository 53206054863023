<div class="row" [ngClass]="editMode ? 'editableRow' : ''">
  <app-card-box *ngFor="let card of row.HAPageCards" [card]="card" [row]="row" class="col-md-{{card.Width}}" [rowAvailableSpace]="availableWidth" (onCardChange)="getCards()"></app-card-box>
  <app-add-card *ngIf="editMode && availableWidth > 0" [row]="row" class="row equal col-md-{{availableWidth}}" (rowChange)="getCards()" [maxWidth]="availableWidth"></app-add-card>
  <div class="d-flex flex-row-reverse p-0">
    <div class="editButtonsContent" *ngIf="editMode"><button class="btn-remove-row" (click)="open(content)"><fa-icon icon="trash-can" class="nav-icon"></fa-icon></button></div>
    <div class="arrowTopButtonsContent" *ngIf="editMode" (click)="moveRowToTop()" [ngClass]="row.Order == 1 ? 'disabledArrowbutton' : ''"><button class="btn-arrow-row"><fa-icon icon="arrow-up" class="nav-icon"></fa-icon></button></div>
    <div class="arrowDownButtonsContent" *ngIf="editMode" (click)="moveRowToDown()" [ngClass]="row.Order == this.haPageVM.HAPageRows![this.haPageVM.HAPageRows!.length - 1].Order ? 'disabledArrowbutton' : ''"><button class="btn-arrow-row"><fa-icon icon="arrow-down" class="nav-icon"></fa-icon></button></div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><lib-translate key="lbl-title-delete-row"></lib-translate></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p><lib-translate key="lbl-modal-delete-row"></lib-translate></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')"><lib-translate key="lbl-btn-cancel-row"></lib-translate></button>
    <button type="button" class="btn btn-danger" (click)="removeRow()"><lib-translate key="lbl-btn-delete-row"></lib-translate></button>
  </div>
</ng-template>
