import { Component, Input, OnInit, inject } from '@angular/core';
import { Subject } from "rxjs";
import { ExportType } from '../../lib-card-generic-crud/lib-card-generic-crud.interface';
import { LibDynamicComponentsEventBusService } from '../../lib-dynamic-component-event-bus/lib-dynamic-component-event-bus.service';

@Component({
  selector: 'lib-herdia-datatable-export-button',
  templateUrl: './lib-herdia-datatable-export-button.component.html',
  styleUrls: ['./lib-herdia-datatable-export-button.component.scss']
})
export class LibHerdiaDatatableExportButtonComponent implements OnInit {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  cardId!: number;

  @Input() config: { [key: string]: any } = {};
  @Input() exportName!: string;

  quoteAllFields: boolean = true;
  delimiter: string = ";";

  ngOnInit(): void {
    if (this.exportName.length > 31) {
      this.exportName = this.exportName.substring(0, 31);
    }
    this.cardId = this.config['cardId'];
  }

  onAskExportCSV(): void {
    this.eventBusService.addSubject("AskExport_Datatable", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["AskExport_Datatable_" + this.cardId].next({ exportType: ExportType.csv, QuoteAllFields: this.quoteAllFields, Delimiter: this.delimiter, exportName: this.exportName });
  }

  onAskExportXLSX(): void {
    this.eventBusService.addSubject("AskExport_Datatable", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["AskExport_Datatable_" + this.cardId].next({ exportType: ExportType.xlsx, exportName: this.exportName });
  }
}
