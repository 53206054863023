<div>
  <div class="modal-header">
    <h4 class="modal-title"><lib-translate [key]="'add-filedeposit-modal-title'"></lib-translate></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="FileDepositEnable"><lib-translate [key]="'FileDeposit-Enable'"></lib-translate></label>
      <input type="checkbox" id="FileDepositEnable" class="form-check-input" name="FileDepositEnable" [checked]="fileDepositToEdit.Enable === true" [(ngModel)]="fileDepositToEdit.Enable">
    </div>
    <div class="form-group">
      <label for="FileDepositLabel"><lib-translate [key]="'FileDeposit-Label'"></lib-translate></label>
      <input type="text" class="form-control" name="FileDepositLabel" [(ngModel)]="fileDepositToEdit.Label">
    </div>
    <div class="form-group">
      <label for="FileDepositType"><lib-translate [key]="'FileDeposit-Type'"></lib-translate></label>
      <ng-select [items]="typeFileDeposit"
                 bindLabel="Label"
                 bindValue="id"
                 [(ngModel)]="fileDepositToEdit.Type">
      </ng-select>
    </div>
    <div class="form-group">
      <label for="FileDepositTag"><lib-translate [key]="'FileDeposit-Tag'"></lib-translate></label>
      <input type="text" class="form-control" name="FileDepositTag" [(ngModel)]="fileDepositToEdit.Tag">
    </div>
    <div class="form-group">
      <label for="FileDepositFilter"><lib-translate [key]="'FileDeposit-Filter'"></lib-translate></label>
      <input type="text" class="form-control" name="FileDepositFilter" [(ngModel)]="fileDepositToEdit.Filter">
    </div>
    <div class="form-group">
      <label for="FileDepositAuth"><lib-translate [key]="'FileDeposit-Auth'"></lib-translate></label>
      <ng-select [items]="authTypeFileDeposit"
                 bindLabel="Label"
                 bindValue="id"
                 [(ngModel)]="fileDepositToEdit.Auth">
      </ng-select>
    </div>
    <div class="form-group">
      <label for="FileDepositLogin"><lib-translate [key]="'FileDeposit-Login'"></lib-translate></label>
      <input type="text" class="form-control" name="FileDepositLogin" [(ngModel)]="fileDepositToEdit.Login">
    </div>
    <div class="form-group">
      <label for="FileDepositPassword"><lib-translate [key]="'FileDeposit-Password'"></lib-translate></label>
      <input type="text" class="form-control" name="FileDepositPassword" [(ngModel)]="fileDepositToEdit.Password">
    </div>
    <div class="form-group">
      <label for="FileDepositHost"><lib-translate [key]="'FileDeposit-Host'"></lib-translate></label>
      <input type="text" class="form-control" name="FileDepositHost" [(ngModel)]="fileDepositToEdit.Host">
    </div>
    <div class="form-group">
      <label for="FileDepositPort"><lib-translate [key]="'FileDeposit-Port'"></lib-translate></label>
      <input type="number" class="form-control form-control-sm" name="FileDepositPort" [(ngModel)]="fileDepositToEdit.Port">
    </div>
    <div class="form-group">
      <label for="FileDepositRootPath"><lib-translate [key]="'FileDeposit-RootPath'"></lib-translate></label>
      <input type="text" class="form-control" name="FileDepositRootPath" [(ngModel)]="fileDepositToEdit.RootPath">
    </div>
    <div class="form-group">
      <label for="FileDepositCapabilities"><lib-translate [key]="'FileDeposit-Capabilities'"></lib-translate></label>
      <ng-select [items]="CapabilitiesFileDeposit"
                 bindLabel="Label"
                 bindValue="id"
                 [(ngModel)]="fileDepositToEdit.Capabilities">
      </ng-select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()"><lib-translate [key]="'close'"></lib-translate></button>
    <button type="button" class="btn btn-primary" (click)="onValidate()"><lib-translate [key]="'validate'"></lib-translate></button>
  </div>
</div>
