import { NgModuleRef, inject } from '@angular/core';
import { DynamicComponentDeclaration, DynamicComponentPackage } from './dynamic-components.model';
import { DynamicComponentsService } from './dynamic-components.service';

export abstract class DynamicComponentsBaseModule {

    private dynamicComponentsService = inject(DynamicComponentsService);
    private ngModuleRef = inject(NgModuleRef);

    constructor() {
        this.registerDynamicComponents();
    }

    abstract getPackageName(): DynamicComponentPackage;
    abstract getDynamicComponentsDeclarations(): DynamicComponentDeclaration[];

    private registerDynamicComponents() {
        const packageName = this.getPackageName();
        const dynamicComponents = this.getDynamicComponentsDeclarations();
        dynamicComponents.forEach(dynamicComponent => {
            this.dynamicComponentsService.registerDynamicComponent(
                packageName,
                this.ngModuleRef,
                dynamicComponent.component,
                dynamicComponent.type,
            )
        });
    }

}