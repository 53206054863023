import { AccountService } from "../service/account.service";

export function appInitializer(accountSvc: AccountService) {
  return () => new Promise((resolve) => {
    const obs = accountSvc.refreshToken();
    if (obs)
      return obs.subscribe(d => resolve(d));
    return resolve(false);
  });
}
