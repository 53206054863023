import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ColumnSetting } from '../lib-column-selector/lib-column-selector.interface';
import { LibDynamicComponentsEventBusService } from '../lib-dynamic-component-event-bus/lib-dynamic-component-event-bus.service';
import { LibHerdiaDatatableComponent } from '../lib-herdia-datatable/lib-herdia-datatable.component';
import { ServerSideRequest, ServerSideResponse } from '../lib-herdia-datatable/lib-herdia-datatable.interfaces';
import { ICardConfig, TreeNode } from "../lib-interface-card/lib-interface-card.interfaces";
import { LibCardGenericCrudActionsComponent } from './lib-card-generic-crud-actions/lib-card-generic-crud-actions.component';
import { PropertyOption } from "./lib-card-generic-crud.enums";
import { CRUDAddOrUpdateRequest, CRUDCreateOrUpdateResponse, CRUDDeleteRequest, CRUDReadFromSqlRequest, CRUDReadRequest, DataFilter, EntityDefinition } from './lib-card-generic-crud.interface';
import { LibCardGenericCRUDService } from "./lib-card-generic-crud.service";
import { CardConfigBaseComponent } from '../dynamic-components/card-config-base-component';

@Component({
  selector: 'lib-card-generic-crud',
  templateUrl: './lib-card-generic-crud.component.html',
  styleUrls: ['./lib-card-generic-crud.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LibCardGenericCrudComponent extends CardConfigBaseComponent implements OnInit, ICardConfig {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  cardId!: number;

  @ViewChild('actions') actions!: LibCardGenericCrudActionsComponent;
  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;

  itemDefinition: EntityDefinition;
  columnSettings: ColumnSetting[] = [];
  customRenderingColumnSettings: ColumnSetting[] = [];
  filters: DataFilter[] = [];
  globalSearchValue = "";
  showFilters = false;

  @Input() contextTypeFullname!: string;
  @Input() entity: EntityDefinition = { Name: "", Properties: [], ColumnSettings: [] };
  @Input() sqlQuery: string = "";
  @Input() specificDeleteRoute: string = "";


  // variables from interface ICardConfig, required for the card to work
  allowFullScreen: boolean = true;
  allowMobileView: boolean = false;
  hasFooter: boolean = true;
  hasHeader: boolean = true;
  hasButton: boolean = true;
  isConfigurable: boolean = true;
  minWidth: number = 4;
  cardCustomClasses: string = "";
  configTreeNode: TreeNode[] = [
    {
      key: 'lib-card-generic-crud-config-aspect',
      label: 'lbl-generic-crud-aspect',
      children: []
    },
    {
      key: 'lib-card-generic-crud-config',
      label: 'lbl-generic-crud-config',
      children: []
    },
    {
      key: 'lib-card-generic-crud-config-table',
      label: 'lbl-generic-crud-table',
      children: []
    },
    {
      key: 'lib-card-generic-crud-config-file-deposit',
      label: 'lbl-file-deposit-config',
      children: []
    }
  ];
  //

  constructor(private libCardGenericCRUDService: LibCardGenericCRUDService, private modalService: NgbModal, private datePipe: DatePipe) {
    super();
    this.itemDefinition = { Name: "", Properties: [], ColumnSettings: [] };
  }
  ngOnInit(): void {
    this.cardId = this.config['cardId'];

    if (this.config["DataSource"]['Context'] !== null)
      this.contextTypeFullname = this.config["DataSource"]['Context'];
    if (this.config["DataSource"]['Entity'] !== null)
      this.entity = this.config["DataSource"]['Entity'];
    if (this.config["DataSource"]['Query'] !== null)
      this.sqlQuery = this.config["DataSource"]['Query'];
    if (this.config["specificDeleteRoute"] !== null)
      this.specificDeleteRoute = this.config["specificDeleteRoute"];

    this.eventBusService.subjectAdded.subscribe((subjectName) => {
      if (subjectName === "AddEntity") {
        this.eventBusService.cardSubjects["AddEntity"]?.subscribe((obj: any) => { this.addEntity(); });
      }

      if (subjectName === "AskExport_GenericCrud") {
        this.eventBusService.cardSubjects["AskExport_GenericCrud"]?.subscribe((exportConfig: any) => {
          this.onAskExport(exportConfig);
        });
      }

      if (subjectName === "AskImportCSV") {
        this.eventBusService.cardSubjects["AskImportCSV"]?.subscribe((fileDefinition: any) => {
          this.onAskImportCSV(fileDefinition);
        });
      }
    });

    this.columnSettings = this.config['DataSource']['Entity']['ColumnsSettings'];
  }



  getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
    switch (this.config["DataSource"]['Type']) {
      case "DbSet":
        const readParams = {
          ContextTypeName: this.contextTypeFullname,
          EntityType: this.entity.Name,
          DatatableParams: dataTablesParameters,
          Filters: this.filters
        } as CRUDReadRequest;
        this.itemDefinition.Name = this.config["DataSource"]["Entity"]["Name"];
        this.itemDefinition.Properties = this.config["DataSource"]["Entity"]["Properties"];
        this.itemDefinition.ColumnSettings = this.columnSettings;

        return this.libCardGenericCRUDService.read(readParams);
      case "SQL":
        const readFromSqlParams = {
          ContextTypeName: this.contextTypeFullname,
          SqlQuery: this.sqlQuery,
          DatatableParams: dataTablesParameters,
          Filters: this.filters
        } as CRUDReadFromSqlRequest;

        this.itemDefinition.Name = "UNKNOWN_SQL_RESULT";
        this.itemDefinition.Properties = this.config["DataSource"]["Entity"]["Properties"];
        this.itemDefinition.ColumnSettings = this.columnSettings;
        return this.libCardGenericCRUDService.readFromSqlQuery(readFromSqlParams);
        break;
      default:
        throw new Error("Unknown DataSource Type");
    }
  }

  addEntity(): void {
    const modalRef = this.modalService.open(LibCardGenericCrudActionsComponent);
    modalRef.componentInstance.context = "add";
    modalRef.componentInstance.title = "Ajout";
    modalRef.componentInstance.showDelete = false;
    modalRef.componentInstance.itemDefinition = this.itemDefinition;
    modalRef.componentInstance.onAdd();
    modalRef.componentInstance.itemAdded.subscribe((newItem: any) => {
      this.onItemAdded(newItem);
    });
  }

  editEntity(item: any): void {
    const modalRef = this.modalService.open(LibCardGenericCrudActionsComponent);
    modalRef.componentInstance.context = "update";
    modalRef.componentInstance.currentItem = item;
    modalRef.componentInstance.title = "Edit";
    modalRef.componentInstance.showDelete = true;
    modalRef.componentInstance.itemDefinition = this.itemDefinition;
    modalRef.componentInstance.itemUpdated.subscribe((updatedItem: any) => {
      this.onItemUpdated(updatedItem);
    });
  }

  onItemUpdated($event: any): void {
    const updateRequest = {
      ContextTypeName: this.contextTypeFullname,
      EntityType: this.entity.Name,
      Data: this.typeItem($event)
    } as CRUDAddOrUpdateRequest;
    this.libCardGenericCRUDService.update(updateRequest)!.subscribe((response: CRUDCreateOrUpdateResponse) => {
      this.herdiaDatatable.reload();
    });
  }

  onItemAdded($event: any): void {
    const addRequest = {
      ContextTypeName: this.contextTypeFullname,
      EntityType: this.entity.Name,
      Data: this.typeItem($event)
    } as CRUDAddOrUpdateRequest;

    this.libCardGenericCRUDService.create(addRequest)!.subscribe((response: CRUDCreateOrUpdateResponse) => {
      this.herdiaDatatable.reload();
    });
  }

  onItemDeleted(item: any): void {
    let keyProperty = this.itemDefinition.Properties.filter(item => item.Options.indexOf(PropertyOption.IsKey) !== -1)[0].Name;
    if (this.specificDeleteRoute == '') {
      const deleteRequest = {
        ContextTypeName: this.contextTypeFullname,
        EntityType: this.entity.Name,
        Key: item[keyProperty]
      } as CRUDDeleteRequest;
      this.libCardGenericCRUDService.delete(deleteRequest)!.subscribe(() => {
        this.herdiaDatatable.reload();
      });
    }
    else {
      this.libCardGenericCRUDService.specificDelete(this.specificDeleteRoute, item[keyProperty])!.subscribe(() => {
        this.herdiaDatatable.reload();
      });
    }
  }

  onAskExport(exportConfig: any): void {
    this.herdiaDatatable.getDatatableParameters().then((parameters: ServerSideRequest) => {
      if (this.config["DataSource"]["Type"] == "SQL") {
        this.libCardGenericCRUDService.askExport("sql", this.contextTypeFullname,
          this.sqlQuery,
          exportConfig.exportType,
          exportConfig,
          parameters,
          this.filters)!.subscribe(() => { });
      }
      if (this.config["DataSource"]["Type"] == "DbSet") {
        this.libCardGenericCRUDService.askExport("dbset", this.contextTypeFullname,
          this.entity.Name,
          exportConfig.exportType,
          exportConfig,
          parameters,
          this.filters)!.subscribe(() => {
          });
      }
    });
  }

  onAskImportCSV(fileUploadDef: any): void {
    this.libCardGenericCRUDService.askImportCSV(this.contextTypeFullname, this.entity.Name, fileUploadDef)!.subscribe(() => {
      //this.dtTrigger.next(this.dtOptions);
    });
  }

  typeItem(targetObject: any): any {
    let result: any = {};
    this.itemDefinition.Properties.forEach((targetItemProperty) => {
      switch (targetItemProperty.Type) {
        case "Int32?":
        case "Int32":
          if (targetItemProperty.Options.indexOf(PropertyOption.IsNullable) !== -1 &&
            targetObject[targetItemProperty.Name] === "") {
            result[targetItemProperty.Name] = null;
          } else {
            if (!isNaN(Number(targetObject[targetItemProperty.Name]))) {
              result[targetItemProperty.Name] = Number(targetObject[targetItemProperty.Name]);
            }
          }
          break;

        case "JsonString":
          let jsonObject = JSON.parse(targetObject[targetItemProperty.Name]);
          result[targetItemProperty.Name] = targetObject[targetItemProperty.Name];
          break;
        case "String":
        case "Boolean":
        default:
          result[targetItemProperty.Name] = targetObject[targetItemProperty.Name];
      }
    });
    return result;
  }
}
