import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { CardBaseComponent } from '../../dynamic-components/card-base-component';
import { ColumnSetting } from '../../lib-column-selector/lib-column-selector.interface';
import { LibDynamicComponentsEventBusService } from '../../lib-dynamic-component-event-bus/lib-dynamic-component-event-bus.service';
import { ExportType } from '../lib-card-generic-crud.interface';

@Component({
  selector: 'lib-card-generic-crud-header-buttons',
  templateUrl: './lib-card-generic-crud-header-buttons.component.html',
  styleUrls: ['./lib-card-generic-crud-header-buttons.component.scss']
})
export class LibCardGenericCrudHeaderButtonsComponent extends CardBaseComponent implements OnInit {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  cardId!: number;

  @ViewChild("fileUpload") inputFileCsv!: ElementRef;

  quoteAllFields: boolean = true;
  allowUpdate: boolean = true;
  allowAdd: boolean = true;
  selectedImportIdentifier: string = "";
  delimiter: string = ";";
  displaySearch: string = "none";
  displayTools: string = "block";
  searchValue = "";
  exportName: string = "";
  dataSourceType = "SQL";

  fileName = '';
  file!: File;
  columnSettings: ColumnSetting[] = [];
  searchInColumns: string = "";

  ngOnInit(): void {
    this.cardId = this.config['cardId'];

    this.allowAdd = this.config["DataSource"]['Type'] ? this.config["DataSource"]['Type'] != "SQL" : false;
    this.dataSourceType = this.config["DataSource"]['Type'];
    this.exportName = this.title;
    this.columnSettings = this.config['DataSource']['Entity']['ColumnsSettings'];
  }

  onSearch(): void {
    this.eventBusService.addSubject("SearchEntity", this.cardId, new Subject<string>());
    this.eventBusService.cardSubjects["SearchEntity_" + this.cardId].next(this.searchValue);
  }

  onShowSearch(): void {
    this.displayTools = "none";
    this.displaySearch = "flex";
    this.eventBusService.addSubject("ShowDisplaySearchColumns", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["ShowDisplaySearchColumns_" + this.cardId].next({});
  }

  onHideSearch(): void {
    this.displayTools = "block";
    this.displaySearch = "none";
    this.eventBusService.addSubject("HideDisplaySearchColumns", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["HideDisplaySearchColumns_" + this.cardId].next({});
  }

  onAdd(): void {
    this.eventBusService.addSubject("AddEntity", this.cardId, new Subject<string>());
    this.eventBusService.cardSubjects["AddEntity_" + this.cardId].next("Add");
  }

  onAskExportCSV(): void {
    this.eventBusService.addSubject("AskExport_GenericCrud", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["AskExport_GenericCrud_" + this.cardId].next({ exportType: ExportType.csv, QuoteAllFields: this.quoteAllFields, Delimiter: this.delimiter, exportName: this.exportName });
  }

  onAskImportCSV(): void {
    this.eventBusService.addSubject("AskImportCSV", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["AskImportCSV_" + this.cardId].next({ allowUpdate: this.allowUpdate, identifierColumn: this.selectedImportIdentifier, file: this.file, fileName: this.fileName });
  }

  onAskExportXLSX(): void {
    this.eventBusService.addSubject("AskExport_GenericCrud", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["AskExport_GenericCrud_" + this.cardId].next({ exportType: ExportType.xlsx, exportName: this.exportName });
  }

  onShowFilter(): void {
    this.eventBusService.addSubject("ShowFilter", this.cardId, new Subject<any>());
    this.eventBusService.cardSubjects["ShowFilter_" + this.cardId].next({});
  }

  onFileSelected(event: any) {
    if (event.target.files[0]) {
      this.fileName = event.target.files[0].name;
      this.file = event.target.files[0];
    }
  }

  onColumnVisibilityChange(columnIndex: number, visible: boolean): void {
    this.eventBusService.addSubject("ToggleColumnVisibility_GenericCrud", this.cardId, new Subject<{ index: number, visible: boolean }>());
    this.eventBusService.cardSubjects["ToggleColumnVisibility_GenericCrud_" + this.cardId].next({ index: columnIndex, visible: visible });
  }
}
