import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ColumnSetting, GetDatatableSpecificInfosRequest, HerdiaDatatableGlobalConfiguration, IFileDeposit, LibFileDepositService, LibHerdiaDatatableComponent, ServerSideResponse } from "@herdia-common";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-preview-filedeposit-modal',
    templateUrl: './preview-filedeposit-modal.component.html',
    styleUrls: ['./preview-filedeposit-modal.component.scss']
})
export class PreviewFiledepositModalComponent implements OnInit {

  cardId: number = 999999995;

  @Input() fileDepositRef: IFileDeposit = {
        Id: null,
        Label: "",
        Enable: true,
        EnableString: "yes",
        Type: 0,
        Tag: "",
        Filter: "",
        Auth: 0,
        Login: "",
        Password: "",
        Host: "",
        Port: 0,
        RootPath: "",
        Capabilities: [0]
    };

    variablesUsedToGetFiles: { key: string, value: string }[] = [];

    //datatable
    @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;
    herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
        compactMode: false,
        cellBorder: true,
        hover: true,
        orderColumn: true,
        rowBorder: false,
        stripe: true,
        nowrap: false,
        defaultRowNumberOnPage: 5,
        autoWidth: false,
        fixedLeftColumns: 0
    };
    columnSettings: ColumnSetting[] = [
        {
            name: "FilePath",
            label: "Nom du fichier",
            type: "string",
            visible: true,
            nbDecimal: 0,
            nature: "normal",
            horizontalAlign: 'left',
            displaySum: false,
            width: 200,
            actionColumnDef: null
        },
        {
            name: "IdHAFilesFromFileDeposit",
            label: "Id de la table",
            type: "number",
            visible: true,
            nbDecimal: 0,
            nature: "normal",
            horizontalAlign: 'left',
            displaySum: false,
            width: 200,
            actionColumnDef: null
        }
    ]

    constructor(private activeModal: NgbActiveModal, private fileDeposit: LibFileDepositService) { }

    dismiss() {
        return this.activeModal.dismiss();
    }
    
    ngOnInit(): void {
        switch (this.fileDepositRef.Type) {
            case 0: {
                //docuware
                let dataFilter = this.fileDepositRef.Filter;
                const regex = /['"](.*?)['"]/g;
                const matches = dataFilter.match(regex);

                if (matches) {
                    let words = matches.map(match => match.replace(/["']/g, ''));
                    words = Array.from(new Set(words));
                    words.forEach((word: string) => {
                        this.variablesUsedToGetFiles.push({ key: word, value: '' });
                    });
                }
                break;
            }
            case 1: {
                //fileSystem
                let dataFilter = this.fileDepositRef.Filter;
                const regex = /\$([^$]+)\$/g;
                const matches = dataFilter.match(regex);

                if (matches) {
                    let words = matches.map(match => match.slice(1, -1));
                    words = Array.from(new Set(words));
                    words.forEach((word: string) => {
                        this.variablesUsedToGetFiles.push({ key: word, value: '' });
                    });
                }
                break;
            }
            default: {
                console.log("default from switch filedepositref type id");
                break;
            }
        }

        this.fileDeposit.FillFileInformationByType(this.fileDepositRef.Type).subscribe((response: any) => {
            if (response != null) {
                console.log(response);
            }
            else {
                console.log(response);
            }
        });
    }

    onValidate(): void {
        console.log(this.variablesUsedToGetFiles);
        this.herdiaDatatable.reload();
    }

    getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
        let requestParam: GetDatatableSpecificInfosRequest = { requestDatatable: dataTablesParameters, type: this.fileDepositRef.Type, variablesFilter: this.variablesUsedToGetFiles }
        return this.fileDeposit.GetDatatableSpecificInformationByType(requestParam);
    }

}
