<div class="row">
  <div class="col-md-12">
    <div>
      <div class="tab-panel" id="configData" role="tabpanel" aria-labelledby="configData">
        <div class="row">
          <div class="col-md-4">
            <div class="container">
              <div class="form-group">
                <label for="selectChart"><lib-translate [key]="'lib-card-chart-select-type'"></lib-translate></label> <br />
                <ng-select [items]="chartService.availableChartTypes" [(ngModel)]="this.config['ChartConfig']['ChartType']" bindLabel="value" bindValue="key"></ng-select>
              </div>
              <div class="form-group">
                <label for="chartBackgroundColor"><lib-translate [key]="'lib-card-chart-select-background-color'"></lib-translate></label> <br />
                <input name="chartBackgroundColor" type="color" [(ngModel)]="this.config['ChartConfig']['BackgroundColor']">
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="container">
              <lib-lib-chart-define-pie *ngIf="this.config['ChartConfig']['ChartType']=='pie'" [config]="this.config"></lib-lib-chart-define-pie>
              <lib-card-chart-define-bar *ngIf="this.config['ChartConfig']['ChartType']=='bar'" [config]="this.config"></lib-card-chart-define-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
