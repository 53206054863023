import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HerdiaCommonModule } from '@herdia-common';
import { LibApiModule } from './lib-api/lib-api.module';
import { LibCardAlertModule } from './lib-card-alert/lib-card-alert.module';
import { LibCardChartModule } from './lib-card-chart/lib-card-chart.module';
import { LibCardReportModule } from './lib-card-report/lib-card-report.module';
import { LibFilterCardModule } from './lib-filter-card/lib-filter-card.module';
import { LibPanelRightModule } from './lib-panel-right/lib-panel-right.module';
import { LibProcedureInputModule } from './lib-procedure-input/lib-procedure-input.module';
import { LibSpecificParamReportConfigModule } from './lib-specific-param-report-config/lib-specific-param-report-config.module';
import { LibHeaderBindingModule } from './lib-header-binding/lib-header-binding.module'
import { LibCardInputMappingModule } from './lib-card-input-mapping/lib-card-input-mapping.module';


@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        HerdiaCommonModule,
        LibApiModule,
        LibCardAlertModule,
        LibCardChartModule,
        LibCardReportModule,
        LibFilterCardModule,
        LibPanelRightModule,
        LibProcedureInputModule,
        LibSpecificParamReportConfigModule,
        LibHeaderBindingModule,
        LibCardInputMappingModule
    ]
})
export class AlhambraModule { }
