import { NgModule } from '@angular/core';
import { LibCardGenericCrudModule } from '../lib-card-generic-crud/lib-card-generic-crud.module';
import { LibHerdiaDatatableModule } from '../lib-herdia-datatable/lib-herdia-datatable.module';
import { LibHttpClientApiModule } from '../lib-http-client-api/lib-http-client-api.module';

@NgModule({
    declarations: [
    ],
    imports: [
        LibCardGenericCrudModule,
        LibHttpClientApiModule,
        LibHerdiaDatatableModule
    ],
    exports: [
    ]
})
export class LibSchedulerServiceModule { }
