import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { CardConfigBaseComponent, LibCronEditorComponent, LibSchedulerService, RegistrationResponse } from '@herdia-common';

@Component({
    selector: 'lib-card-report-config-scheduling-job',
    templateUrl: './lib-card-report-config-scheduling-job.component.html',
    styleUrls: ['./lib-card-report-config-scheduling-job.component.css']
})
export class LibCardReportConfigSchedulingJobComponent extends CardConfigBaseComponent implements AfterViewInit, OnDestroy {

    @ViewChild('actions') actions!: LibCronEditorComponent;

    context: string = "add";
    currentTrigger: any;
    user = JSON.parse(localStorage.getItem("user")!) as RegistrationResponse;
    myFormJob: FormGroup;

    constructor(private changeDetector: ChangeDetectorRef, private taskSchedulerService: LibSchedulerService) {
        super();
        this.myFormJob = new FormGroup({
            jobNameTxt: new FormControl(),
            jobDescTxt: new FormControl()
        });
    }

    ngOnChanges(): void {
        if (this.config["SchedulingReport"]["JobDescription"] && this.config["SchedulingReport"]["JobName"]) {
            this.formJob["jobNameTxt"].setValue(this.config["SchedulingReport"]["JobName"]);
            this.formJob["jobDescTxt"].setValue(this.config["SchedulingReport"]["JobDescription"]);
        }
        else {
            this.config["SchedulingReport"]["JobName"] = "";
            this.config["SchedulingReport"]["JobDescription"] = "";
            this.config["SchedulingReport"]["Creator"] = this.user.UserName;
        }
    }

    ngAfterViewInit(): void {
        if (this.config["SchedulingReport"]) {
            this.actions.finalCronExpression = this.config["SchedulingReport"]["CronExpression"];
            this.changeDetector.detectChanges();
        }
        else {
            this.config["SchedulingReport"]["CronExpression"] = this.actions.finalCronExpression;
        }
    }

    ngOnDestroy() {
        this.config["SchedulingReport"]["JobName"] = this.formJob["jobNameTxt"].value;
        this.config["SchedulingReport"]["JobDescription"] = this.formJob["jobDescTxt"].value;
        this.config["SchedulingReport"]["CronExpression"] = this.actions.finalCronExpression;
        this.config["SchedulingReport"]["Creator"] = this.user.UserName;
    }

    get formJob(): { [key: string]: AbstractControl } {
        return this.myFormJob.controls;
    }
}
