import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnSetting, HerdiaDatatableGlobalConfiguration, LibConfirmBoxService, LibFileDepositService, LibHerdiaAppTranslateService, LibHerdiaDatatableComponent, ServerSideResponse } from "@herdia-common";
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AddFiledepositModalComponent } from './add-filedeposit-modal/add-filedeposit-modal.component';
import { EditFiledepositModalComponent } from './edit-filedeposit-modal/edit-filedeposit-modal.component';
import { PreviewFiledepositModalComponent } from './preview-filedeposit-modal/preview-filedeposit-modal.component';

@Component({
  selector: 'app-filedeposit-manager',
  templateUrl: './filedeposit-manager.component.html',
  styleUrls: ['./filedeposit-manager.component.scss']
})
export class FiledepositManagerComponent implements OnInit {
  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;

  cardId: number = 999999996;

  deleteTitleModal: string = "";
  deleteMsgModal: string = "";
  deleteValidateModal: string = "";
  deleteCancelModal: string = "";
  fileDepositToDelete: any;

    herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
        compactMode: false,
        cellBorder: true,
        hover: true,
        orderColumn: true,
        rowBorder: false,
        stripe: true,
        nowrap: false,
        defaultRowNumberOnPage: 10,
        autoWidth: false,
        fixedLeftColumns: 0
    };
    modalRef!: NgbModalRef;

  constructor(private modalService: NgbModal, private translatePipe: TranslatePipe, private FileDepositService: LibFileDepositService, private translateService: LibHerdiaAppTranslateService, private activeModal: NgbActiveModal, private confirmBoxSvc: LibConfirmBoxService) {
    this.deleteTitleModal = translatePipe.transform("delete-filedeposit-title-modal-card");
    this.deleteMsgModal = translatePipe.transform("delete-filedeposit-message-modal-card");
    this.deleteValidateModal = translatePipe.transform("delete-filedeposit-validate-button-modal-card");
    this.deleteCancelModal = translatePipe.transform("delete-filedeposit-cancel-button-modal-card");

    confirmBoxSvc.confirmationSub.subscribe((c: Boolean) => {
      if (c) {
        this.FileDepositService.RemoveFileDeposit(this.fileDepositToDelete.Id)!.subscribe((c) => {
          if (c) {
            this.herdiaDatatable.reload();
            this.activeModal.close();
          }
        });
      }
    });
  }

  ngOnInit(): void {
  }

  onAddFileDeposit(): any {
    //call modal for add file deposit
    const modalRef = this.modalService.open(AddFiledepositModalComponent);
    modalRef.componentInstance.fileDepositAdded.subscribe(() => {
      this.herdiaDatatable.reload();
    });
  }

  onEditFileDeposit = (data: any) => {
    //call modal for a edit file deposit
    const modalRef = this.modalService.open(EditFiledepositModalComponent);
    modalRef.componentInstance.fileDepositToEdit = data;
    modalRef.componentInstance.fileDepositEdited.subscribe(() => {
      this.herdiaDatatable.reload();
    });
  }

  onRemove = (data: any) => {
    this.fileDepositToDelete = data;

    this.confirmBoxSvc.open({
      title: this.deleteTitleModal,
      msg: this.deleteMsgModal,
      okBtnLbl: this.deleteValidateModal,
      koBtnLbl: this.deleteCancelModal,
    });
  }

  onPreviewFileDeposit = (data: any) => {
    //call modal to see files from file deposit
    const modalRef = this.modalService.open(PreviewFiledepositModalComponent, { size: 'xl' });
    modalRef.componentInstance.fileDepositRef = data;
  }


  getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
    let result = this.FileDepositService.GetAllFileDeposit(dataTablesParameters);
    return result.pipe(map((res: ServerSideResponse) => {
      const modifiedResponse: ServerSideResponse = res;
      modifiedResponse.data?.forEach((element: any) => {
        if (element.Enable == true) {
          element.EnableString = this.translateService.get("yes");
        }
        else {
          element.EnableString = this.translateService.get("no");
        }
      });

      return modifiedResponse;
    }));
  }

  columnSettings: ColumnSetting[] = [
    {
      name: "Label",
      label: "Nom du file deposit",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "EnableString",
      label: "File deposit actif",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "Tag",
      label: "Tag",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "Filter",
      label: "Filter",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "Actions",
      label: "Actions",
      type: "custom",
      visible: true,
      actionColumnDef: {
        align: "center",
        buttons: [
          {
            type: "button",
            icon: "edit",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.onEditFileDeposit
          },
          {
            type: "button",
            icon: "trash",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.onRemove
          },
          {
            type: "button",
            icon: "chevron-circle-right",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.onPreviewFileDeposit
          }
        ]
      },
      displaySum: false,
      nbDecimal: 0,
      nature: "normal",
      width: 200,
      horizontalAlign: "center"
    }
  ]

}
