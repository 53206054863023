<div *ngIf="config['DataSource']['Parameters']">
<button type="button" class="btn btn-tool" [ngbPopover]="popContent" [popoverTitle]="popTitle" [autoClose]="'outside'">
  <fa-icon icon="filter"></fa-icon>
</button>
<ng-template #popContent>
  <div *ngFor="let cardFilterBinding of cardFilterParameters;let index = index">
    <div *ngIf="cardFilterBinding['ParameterBinding']['CardFilterBindingCategory'] == 'raw'">
      <lib-property-type-input [showLabel]="true"
                               [propertyLabel]="cardFilterBinding['ParameterName']"
                               [propertyDefinition]="this.propertyDefinition[index + 1]"
                               [inputValue]="config['CardFilters'][cardFilterBinding['ParameterName']]"
                               (inputUpdated)="onPropertyInputUpdated(config['CardFilters'][cardFilterBinding['ParameterName']], $event)">
      </lib-property-type-input>
    </div>
    <div *ngIf="cardFilterBinding['ParameterBinding']['Type'] == 'cardFilter' && cardFilterBinding['ParameterBinding']['CardFilterBindingCategory'] == 'knowFilter'">

    </div>
  </div>
</ng-template>
<ng-template #popTitle><lib-translate [key]="'cardFilters'"></lib-translate></ng-template>
</div>
