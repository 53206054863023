import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {  ApiBasePath, ApiController, ApiControllerAction } from './lib-http-client-api.interfaces';
import * as OpenAPIParser from "@readme/openapi-parser";

@Injectable({
  providedIn: 'root'
})
export class LibHttpClientApiService {

  apiSwaggerDefinition: any = [];
  apiBasePaths: ApiBasePath[] = [];

  constructor(private http: HttpClient) {
    setTimeout(() => { this.updateSwaggerDefinition(); });
  }

  updateSwaggerDefinition() : void {
    this.get("swagger/v1/swagger.json").subscribe(async (rawData: any) => {
      this.apiSwaggerDefinition = await OpenAPIParser.dereference(rawData);
      let basePathBuffer: string[] = [];
      for (let key in this.apiSwaggerDefinition.paths) {
        let path = this.apiSwaggerDefinition.paths[key];
        let pathMethod = Object.keys(path)[0];
        let tagName = path[pathMethod].tags[0];
        let nbSubPath = key.split('/').length;
        let action = key.split('/')[nbSubPath - 1];
        let controller = key.split('/')[nbSubPath - 2];
        let basePath = key.replace("/" + action, "").replace("/" + controller, "");

        // check if this basePath already registered, if not register it
        if (this.apiBasePaths.find(a => a.basePath == basePath) === undefined)
          this.apiBasePaths.push({ basePath: basePath, controllers: [] });

        // retrieve the related apiBasePath
        let currentApiBasePath = this.apiBasePaths.find(a => a.basePath == basePath);
        // check if controller already registered, if not, register it
        if (currentApiBasePath?.controllers.find(c => c.name == controller) === undefined)
          currentApiBasePath?.controllers.push({ name: controller, actions: []});

        // retrieve the related controller
        let currentController = currentApiBasePath?.controllers.find(c => c.name == controller);
        // check if action already registererd, if not, register it
        if (currentController?.actions.find(a => a.name == action && a.method == pathMethod) === undefined) {
          let actionDefinition : ApiControllerAction = {
            name: action,
            method: pathMethod
          };
          currentController?.actions.push(actionDefinition);
        }
      }
    });
  }

  /**
   * List available controllers via swagger.json file
   *
   * @param {string} basePath - The base path to access requested controllers (ie: /api/alhambra for an alhambra dynamic context)
   * @returns {ApiController[]} An array of ApiController interfaces
   *
   */
  getAvailableControllers(basePath: string): ApiController[]  {
    let result: ApiController[] = [];
    let availableRoutes = this.apiSwaggerDefinition;
     let buffer: string[] = [];
     for (let key in availableRoutes.paths) {
       if (key.indexOf(basePath) == 0) {
        let path = availableRoutes.paths[key];
        let pathMethod = Object.keys(path)[0];
        let techName = path[pathMethod].tags[0];
        if (buffer.indexOf(techName) == -1)
        {
          buffer.push(techName);
          result.push({
            name: techName,
            actions: []
          });
        }
      }
    }

    result = result.sort((a,b) => (a > b) ? -1: 1);
    return result;
  }

  getAvailableActions(basePath: string, controller: string): ApiControllerAction[] {
    let result: ApiControllerAction[] = [];
    // TODO: list availables actions for a controller
    return result;
  }

  getActionParameters(basePath: string, controller: string, action: string, method: string): any {
    let targetRoute = this.apiSwaggerDefinition.paths[basePath + '/' +  controller + '/' + action][method];
    let result: any = {};

    if (targetRoute.hasOwnProperty("requestBody")) {
      result["requestBody"] = targetRoute.requestBody.content["application/json"].schema;
    }

    return result;
  }

  getActionResult(basePath: string, controller: string, action: string, method: string): any {
    let targetRoute = this.apiSwaggerDefinition.paths[basePath + '/' +  controller + '/' + action][method];
    let result: any = {};

    if (targetRoute.hasOwnProperty("responses")) {
      result["responses"] = targetRoute.responses["200"].content["application/json"].schema.items.properties;
    }

    return result;
  }

  getAvailableRoutes() : any {
    return this.apiSwaggerDefinition;
  }

  public getCSTypeFromJSONType(parameter: any): string {
    let result = "string";

    switch (parameter.type) {
      case "string":
        if (parameter.hasOwnProperty("format")) {
          switch (parameter.format) {
            case "date-time":
              result = "DateTime";
              break;
            default:
              throw new Error("Unhandled parameter string format");
          }
        }
        break;
      case "number":
        result = "int";
        if (parameter.hasOwnProperty("format")) {
          switch (parameter.format) {
            case "decimal":
            case "double":
              result = "decimal";
              break;
            default:
              throw new Error("Unhandled parameter number format");
          }
        }
        break;
      case "integer":
        result = "Int32";
        switch (parameter.format) {
          case "int32":
            result = "Int32";
            break;
          case "int64":
            result = "Int64";
            break;
          default:
            throw new Error("Unhandled parameter integer format");
        }
        break;
      default:
        throw new Error("Unhandled parameter type " + parameter.type);
    }
    if (parameter.nullable)
      result = result + "?";

    return result;
  }

  get<TResult>(url: string, params?: { [param: string]: string | number | boolean }) {
    return this.http.get(url, { params: params }).pipe(map(res => res as TResult));
  }

  getText<TResult>(url: string, params?: { [param: string]: string | number | boolean }, isRetTypeJson: boolean = true) {
    return this.http.get(url, { responseType: 'text', params: params });
  }

  post<TRequestData, TResult>(url: string, data: TRequestData) : Observable<TResult> {
    let observable;
    if (data)
      observable = this.http.post(url, data);
    else {
      throw new Error('payload data is null');
    }

    return observable.pipe(
      map(data => {
        return data as TResult;
      }));
  }

  put<TRequestData, TResult>(url: string, data: TRequestData) {
    let observable;
    if (data)
      observable = this.http.put(url, data);
    else {
      console.error('payload data is null');
      return null;
    }

    return observable.pipe(
      map(data => {
        return data as TResult;
      }));
  }

  delete<TRequestData, TResult>(url: string, data: TRequestData) {
    let observable;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    }
    if (data)
      observable = this.http.delete(url, options);
    else {
      console.error('payload data is null');
      return null;
    }

    return observable.pipe(
      map(data => {
        return data as TResult;
      }));
  }

  delete1<TResult>(url: string) {
    let observable = this.http.delete(url);

    return observable.pipe(
      map(data => {
        return data as TResult;
      }));
  }

  remove<TResult>(url: string, params?: { [param: string]: string | number | boolean }) {
    return this.http.delete(url, { params: params }).pipe(map(res => res as TResult));
  }

  downloadFile(url: string, filename: string = ""): void {
    this.http.get(url,{responseType: 'blob' as 'json'}).subscribe(
        (response: any) => {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
                downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }
}
