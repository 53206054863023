import { NgModule } from '@angular/core';
import { LibConfirmBoxComponent } from './lib-confirm-box.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    LibConfirmBoxComponent
  ],
  imports: [
    NgbModule
  ],
  exports: [
    LibConfirmBoxComponent
  ],
  providers: [
    NgbActiveModal
  ]
})
export class LibConfirmBoxModule { }
