import { ColumnSetting } from '../lib-column-selector/lib-column-selector.interface';
import { ServerSideRequest } from '../lib-herdia-datatable/lib-herdia-datatable.interfaces';

export interface PropertyItemDefinition {
    Key: string;
    Label: string;
}
export interface PropertyDefinition {
    Name: string,
    Type: string,
    Options: number[],
    AvailableItems: PropertyItemDefinition[]
}

export interface EntityDefinition {
    Name: string,
    Properties: PropertyDefinition[],
    ColumnSettings: ColumnSetting[]
}

export interface CRUDReadRequest {
    ContextTypeName: string,
    EntityType: string,
    DatatableParams: ServerSideRequest,
    Filters: DataFilter[]
}

export interface CRUDReadFromSqlRequest {
    ContextTypeName: string,
    SqlQuery: string,
    DatatableParams: ServerSideRequest,
    Filters: DataFilter[]
}

export interface CRUDAddOrUpdateRequest {
    ContextTypeName: string,
    EntityType: string,
    Data: any
}

export interface CRUDCreateOrUpdateResponse {
    NewEntity: any
}

export interface CRUDDeleteRequest {
    ContextTypeName: string,
    EntityType: string,
    Key: any
}

export interface EntityDefinitionSqlRequest {
    ContextTypeName: string,
    SqlQuery: string
}

export enum ExportType {
    csv = 0,
    xlsx = 1,
    pdf = 2
}

export enum ExportSourceType {
    dynamicContextProcedureService = 0,
    entity = 1,
    sql = 2
}

export interface ExportDataSource {
    type: ExportSourceType,
    expression: any
}

export interface ExportRequest {
    datatableRequest: ServerSideRequest,
    requestUserEmail: string,
    fileType: ExportType,
    dataSource: ExportDataSource,
    configuration: any,

    filters: DataFilter[]
}

export interface DataFilter {
    column: PropertyDefinition,
    operator: "EqualTo" |
    "Contains" |
    "BeginWith" |
    "EndWith" |
    "InBetween" |
    "NotEqualTo" |
    "GreaterThan" |
    "LessThan" |
    "EqualOrGreaterThan" |
    "EqualOrLessThan",
    operand: string
}
