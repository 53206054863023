import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent } from '../../dynamic-components/card-config-base-component';
import { ColumnSetting } from '../../lib-column-selector/lib-column-selector.interface';
import { EntityDefinition } from '../lib-card-generic-crud.interface';

@Component({
    selector: 'lib-card-generic-crud-config-file-deposit',
    templateUrl: './lib-card-generic-crud-config-file-deposit.component.html',
    styleUrls: ['./lib-card-generic-crud-config-file-deposit.component.scss']
})
export class LibCardGenericCrudConfigFileDepositComponent extends CardConfigBaseComponent implements OnInit {

    availableCols: ColumnSetting[] = [];
    selectedEntity: EntityDefinition = { Name: "", Properties: [], ColumnSettings: [] };

    ngOnInit(): void {
        if (this.config['fileDeposit'] === undefined) {
            this.config['fileDeposit'] = {
                fileDepositType: {
                    Id: null,
                    Label: "",
                    Enable: true,
                    EnableString: "yes",
                    Type: 0,
                    Tag: "",
                    Filter: "",
                    Auth: 0,
                    Login: "",
                    Password: "",
                    Host: "",
                    Port: 0,
                    RootPath: "",
                    Capabilities: [0]
                },
                paramsFileDeposit: [],
                columnLink: { name: "", label: "", type: "", visible: true, nbDecimal: 2, nature: 'normal', horizontalAlign: 'left', displaySum: false, width: null, actionColumnDef: null }
            };
        }

        if (this.config['DataSource']['Entity']['ColumnsSettings']) {
            this.availableCols = this.config['DataSource']['Entity']['ColumnsSettings'];
        }
    }

    onFileDepositConfChange(event: any) {//type d'entré :configFileDeposit
        this.config['fileDeposit'] = event;
    }

}
