import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { DynamicComponentsService } from '@herdia-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HaPageRowVM } from '../../shared/models/interfaces';
import { AddCardModalComponent } from './add-card-modal/add-card-modal.component';
import { AddPredefinedCardModalComponent } from './add-predefined-card-modal/add-predefined-card-modal.component';

@Component({
    selector: 'app-add-card',
    templateUrl: './add-card.component.html',
    styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {

    private dynamicComponentsService = inject(DynamicComponentsService);

    @Input() row: HaPageRowVM | undefined;
    @Input() maxWidth = 12;
    @Output() rowChange: EventEmitter<HaPageRowVM> = new EventEmitter<HaPageRowVM>();


    cardActionsList: { action: string, label: string }[] = [
        {
            action: "addCard",
            label: "lbl-add-card"
        },
        {
            action: "addPredefinedCard",
            label: "lbl-add-predefined-card"
        }
    ];

    constructor(
        public modalService: NgbModal
    ) { }

    ngOnInit(): void {

    }

    addCard(item: { action: string, label: string }): void {
        if (item.action === "addCard") {
            const cardOptions = this.dynamicComponentsService.getDynamicCardOptions();
            const modalRef = this.modalService.open(AddCardModalComponent);
            modalRef.componentInstance.availableDynamicCardSelector = cardOptions;
            modalRef.componentInstance.cardBase.pageRowId = this.row?.Id;
            modalRef.componentInstance.cardBase.cardWidth = this.maxWidth;
            modalRef.componentInstance.maxWidth = this.maxWidth;
            modalRef.componentInstance.cardAdded.subscribe(() => { this.update(); });
        }
        if (item.action === "addPredefinedCard") {
            const modalRef = this.modalService.open(AddPredefinedCardModalComponent);
            modalRef.componentInstance.cardBase.pageRowId = this.row?.Id;
            modalRef.componentInstance.cardBase.cardWidth = this.maxWidth;
            modalRef.componentInstance.cardAdded.subscribe(() => { this.update(); });
        }
    }

    update(): void {
        this.rowChange.emit(this.row);
    }
}
