import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LibUserService, User } from '@herdia-common';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    id?: string;
    dateFormat!: { key: string, value: string };

    model: User = {
        Id: '',
        LastName: '',
        FirstName: '',
        LanguageCode: 'fr-FR',
        Email: '',
        UserName: '',
        DateFormat: '',
        Phone: '',
        Roles: [],
        Img: '',
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private userService: LibUserService,
    ) {
        const routeId = this.activatedRoute.snapshot.paramMap.get('id');
        if (routeId) {
            this.id = routeId;
            this.userService.viewUser(this.id).subscribe((u: User) => {
                if (u.DateFormat == null || u.DateFormat == undefined || u.DateFormat === "")
                    u.DateFormat = "FR";
                this.model = u;
                this.dateFormat = this.userService.getEntryFromAKey(this.model.DateFormat, this.userService.AllDateFormat);
            });
        }
        else {
            console.error('id cannot be 0');
            this.router.navigate(['user']);
        };
    }

    onReturn() {
        this.router.navigate(['user']);
      }

    ngOnInit(): void {

    }

}
