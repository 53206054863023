import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common';
import { apiProcedureDescription, apiProcedureOutputSet, apiProcedureParameter } from './lib-procedure-input.interfaces';
import { TranslatePipe } from '@ngx-translate/core';
import { PropertyDefinition } from '@herdia-common';
import { Observable } from 'rxjs';
export enum LibProcedureUrls {
  GET_PROCEDURE_PARAMETER_LIST_VALUES = 'api/alhambra/ProcedureParameterListValues/GetProcedureParameterListValues'
}

@Injectable({
  providedIn: 'root'
})
export class LibProcedureInputService {
  procedureDescription: apiProcedureDescription[] = [];

  constructor(private apiService: LibHttpClientApiService, private libTranslatePipe: TranslatePipe) {
  }

  getAvailableProcedure(): { technicalName: string, label: string}[]  {
    let result: { technicalName: string, label: string}[] = [];
    this.procedureDescription.forEach(procedure => {
      result.push({technicalName: procedure.CSName, label: this.libTranslatePipe.transform(procedure.CSName)});
    });
    result = result.sort((a,b) => (a > b) ? -1: 1);
    return result;
  }

  getProcedureParameters(procedureName: string): apiProcedureParameter[] | undefined {
    let result: apiProcedureParameter[] = [];
    return result;
    // let procedure = this.procedureDescription.find(p => p.CSName == procedureName);
    // if (procedure === undefined)
    //   return undefined;
    // return procedure.Parameters;
  }

  getProcedureOutputSet(procedureName: string): apiProcedureOutputSet[] | undefined {
    let result: apiProcedureOutputSet[] = [];
    return result;
    // let procedure = this.procedureDescription.find(p => p.CSName == procedureName);
    // if (procedure === undefined)
    //   return undefined;
    // return procedure.OutputSet;
  }

  getProcedureParametersListValues(procedureName: string): Observable<PropertyDefinition[]> {
    return this.apiService.get<PropertyDefinition[]>(LibProcedureUrls.GET_PROCEDURE_PARAMETER_LIST_VALUES, { "procedureName" : procedureName} );
  }
}
