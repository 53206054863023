import { Component } from '@angular/core';
import { CardConfigBaseComponent } from '@herdia-common';

@Component({
    selector: 'lib-card-report-config-parameters',
    templateUrl: './lib-card-report-config-parameters.component.html',
    styleUrls: ['./lib-card-report-config-parameters.component.css']
})

export class LibCardReportConfigParametersComponent extends CardConfigBaseComponent { }
