<!--<div>
  <div class="card card-primary haCardBox container-toggle">
    <div class="card-header">
      <h3 class="card-title"><fa-icon icon="check-circle"></fa-icon>&nbsp;<lib-translate [key]="'input-mapping'"></lib-translate></h3>
      <div class="input-group input-group-sm divTableSearch" >
        <input type="search" placeholder="Rechercher dans les colonnes..." class="form-control" [(ngModel)]="searchValue">
        <div class="input-group-append">
          <button type="submit" class="btn btn-tool" (click)="onSearch()">
            <fa-icon icon="search"></fa-icon>
          </button>
          <button type="button" class="btn btn-tool" (click)="onHideSearch()">
            <fa-icon icon="times"></fa-icon>
          </button>
        </div>
      </div>
  <div class="align-items-baseline card-tools d-flex">
        <div>
          <button type="button" class="btn btn-tool" (click)="add()"><fa-icon icon="plus" class="ng-fa-icon"></fa-icon></button>
        </div>
      </div>
    </div>-->
<div class="card-body">
  <div class="row">
    <div class="col-md-6">
      <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-property-managers'"></lib-translate></div></span>
      <ng-select [(ngModel)]="selectedPropertyManager" (change)="onPropertyManagerChange()">
        <ng-option value="ALL"><lib-translate [key]="'ALL_MANAGER'"></lib-translate></ng-option>
        <ng-option *ngFor="let propertyManager of propertyManagerFilter$ | async" [value]="propertyManager.PROPERTY_MANAGER_CODE">{{ propertyManager.PROPERTY_MANAGER_NAME }}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-6">
      <span class="row col-md-12"><div class="col-md-1"><fa-icon icon="sliders"></fa-icon></div><div class="col-md-11"><lib-translate [key]="'lbl-transcoding-type'"></lib-translate></div></span>
      <ng-select [items]="transcodingTypeFilter$ | async" [(ngModel)]="selectedTranscodingType"
                 bindLabel="TRANSCODING_TYPE_LABEL" bindValue="TRANSCODING_TYPE" (change)="onTranscodingTypeChange()">
      </ng-select>
    </div>
  </div>
  <br />
  <div class="input-group input-group-sm divTableSearch">
    <!-- Search bar for specific translation terms-->
    <input type="search"
           [placeholder]="placeholderText"
           class="form-control"
           [(ngModel)]="searchValue">
    <div class="input-group-append">
      <button type="submit"
              class="btn btn-tool"
              (click)="onSearch()">
        <fa-icon icon="search"></fa-icon>
      </button>
    </div>
  </div>
  <lib-herdia-datatable #herdiaDatatable
                        [cardId]="cardId"
                        [columnSettings]="columnSetting"
                        [dataGetter]="getData"
                        [keepRowNumber]="true"
                        [herdiaDTConfig]="herdiaDTConfig">
  </lib-herdia-datatable>
</div>
<!--<div class="card-footer">
      <lib-datatable-pagination [cardId]="cardId"></lib-datatable-pagination>
    </div>
  </div>
</div>-->
