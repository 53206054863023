<form [formGroup]="importFormGroup" (ngSubmit)="onValidate()">
  <div class="modal-header">
    <h4 class="modal-title"><lib-translate [key]="'import-page-modal-title'"></lib-translate></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label for="pageCategory"><lib-translate [key]="'import-page-modal-page-category'"></lib-translate></label>
      <ng-select [items]="availableCategories" formControlName="pageCategory" bindValue="Id" bindLabel="Label"></ng-select>
      <div *ngIf="importFormGroup.get('pageCategory')?.hasError('required') && importFormGroup.get('pageCategory')?.touched">
        <lib-translate class="text-danger" [key]="'lbl-select-category'"></lib-translate>
      </div>
    </div>

    <div class="form-group">
      <label for="pageImportConfigFile"><lib-translate [key]="'import-page-config-file-title'"></lib-translate></label>
      <input type="file" name="pageImportConfigFile" class="form-control" accept=".dat" (change)="onFileSelected($event)" formControlName="pageImportConfigFile">
      <div *ngIf="importFormGroup.get('pageImportConfigFile')?.hasError('required') && importFormGroup.get('pageImportConfigFile')?.touched">
        <lib-translate class="text-danger" [key]="'lbl-select-file'"></lib-translate>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Fermer</button>
    <button type="submit" class="btn btn-primary" [disabled]="!importFormGroup.valid">Valider</button>
  </div>
</form>
