export enum UserUrls {
  GET_ALL = 'api/UserManagement/GetUsers',
  GET_USERS = 'api/UserManagement/GetAll',
  VIEW = 'api/UserManagement/View',
  UPDATE = 'api/UserManagement/Update',
  ADD = 'api/UserManagement/Add',
  DELETE = 'api/UserManagement/Delete',
}

export enum RoleUrls {
  GET_ALL = 'api/Role/GetAll',
  ADD = 'api/Role/AddRole',
  UPDATE = 'api/Role/UpdateRole',
  UPDATE_ROLE_ACTIONS = 'api/Role/UpdateRoleActions',
  DELETE = 'api/Role/DeleteRole',
  GET_CATEGORIES = 'api/Role/Categories',
  GET_USER_ROLES = 'api/Role/GetRolesForUser',
  GET_CURRENT_USER_ROLES = 'api/role/GetCurrentUserRoles'
}

export enum DateFormatEnum {
  FR = "DD/MM/YYYY",
  EN = "MM/DD/YYYY",
  DE = "DD.MM.YYYY"
}

export enum DateFormatForDatePipeEnum {
  FR = "dd/MM/y",
  EN = "MM/dd/y",
  DE = "dd.MM.y"
}

export enum CurrencyEnum {
  EUR = "€",
  GBP = "£",
  USD = '$'
}

export enum AreaUnitEnum {
  SQM = "m²",
  SQF = "ft²"
}

export enum LanguageCodeEnum {
  FR = 'fr-FR',
  EN = 'en-GB',
  DE = 'de-DE'
}
