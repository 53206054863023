import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, inject, OnDestroy } from '@angular/core';
import { LibDynamicComponentsEventBusService, PropertyDefinition } from '@herdia-common';
import { format } from 'date-fns';
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import addYears from "date-fns/addYears";
import { Subject, takeUntil } from 'rxjs';
import { DataSourceParameter } from '../lib-card-report/lib-card-report.interfaces';
import { LibPanelRightService } from '../lib-panel-right/lib-panel-right.service';
import { LibProcedureInputService } from '../lib-procedure-input/lib-procedure-input.service';

@Component({
  selector: 'lib-filter-card',
  templateUrl: './lib-filter-card.component.html',
  styleUrls: ['./lib-filter-card.component.scss']
})
export class LibFilterCardComponent implements OnInit, OnDestroy {

  private eventBusService = inject(LibDynamicComponentsEventBusService);

  cardId!: number;

  @Input() config: { [key: string]: any } = {};

  cardFilterParameters: any[] = [];
  cardFilterParametersValues: { [key: string]: any } = {};
  propertyDefinition: PropertyDefinition[] = [{ AvailableItems: [], Name: "", Options: [], Type: "" }];
  tempPropertyDefinition: PropertyDefinition[] = [{ AvailableItems: [], Name: "", Options: [], Type: "" }];
  constructor(private datePipe: DatePipe, private libFilterService: LibPanelRightService, private libProcedureInputSvc: LibProcedureInputService) { }

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.cardId = this.config['cardId'];

    this.cardFilterParameters = this.config['DataSource']['Parameters'].filter((p: DataSourceParameter) => p.ParameterBinding.Type == "cardFilter" && p.ParameterName !== "AnalysisDate" && p.ParameterName !== "StartDate" && p.ParameterName !== "EndDate");
    if (!this.config["CardFilters"])
      this.config["CardFilters"] = {};

    this.libProcedureInputSvc.getProcedureParametersListValues(this.config["DataSource"]["ProcedureName"]).pipe(takeUntil(this.destroy$)).subscribe((res: PropertyDefinition[]) => {
      this.tempPropertyDefinition.push(...res); // On récupère les propertyDefinition correspondant aux liste de valeur des parametre de la procédure

      this.cardFilterParameters.forEach((p: DataSourceParameter) => {
        let hasValuesList = this.tempPropertyDefinition.find((pd) => pd.Name == p.ParameterName) //On vérifie que le parametre actuelle a une liste de valeur
        if (hasValuesList != undefined) {
          this.propertyDefinition.push(hasValuesList);
        }
        else {
          let parameterPropertyDefinition: PropertyDefinition = { Name: p.ParameterName, Type: p.ParameterType, Options: [], AvailableItems: [] };
          this.propertyDefinition.push(parameterPropertyDefinition);
        }

        if (p.ParameterType.toLowerCase().indexOf("datetime") !== -1) {
          let resultDate = new Date();
          resultDate.setUTCHours(0, 0, 0, 0);
          switch (p.ParameterBinding.Value.InitDate.toLowerCase()) {
            case "today":
              break;
            case "importdate":
              resultDate = new Date(this.libFilterService.selectedImportDate);
              break;
          }

          let operand = p.ParameterBinding.Value.Operand;
          if (p.ParameterBinding.Value.Operator.toLowerCase() != "plus")
            operand = -operand;

          switch (p.ParameterBinding.Value.Multiplier.toLowerCase()) {
            default:
            case "days":
              resultDate = addDays(resultDate, operand);
              break;
            case "months":
              resultDate = addMonths(resultDate, operand);
              break;
            case "years":
              resultDate = addYears(resultDate, operand);
              break;
          }
          this.config["CardFilters"][p.ParameterName] = format(resultDate, "yyyy-MM-dd");
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPropertyInputUpdated(parameterName: string, event: any): void {
    this.eventBusService.addSubject("CardFilterChange", this.cardId, new Subject<{ parameterName: string, parameterValue: any }>());
    this.eventBusService.cardSubjects["CardFilterChange_" + this.cardId].next({ parameterName: parameterName, parameterValue: event });
  }

  computeValue(paramterDefinition: any): string {
    return "";
  }
}
