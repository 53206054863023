import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LibHttpClientApiService, LibUserService } from '@herdia-common';
import { HaPageCategory, PageManagement } from '../../shared/models/interfaces';
import { PageUrls } from '../../shared/service/api.service';
import { PageService } from '../../shared/service/page.service';
import { RoleService } from '../../shared/service/role.service';
import { Role } from '../../shared/models/interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-import-page-modal',
  templateUrl: './import-page-modal.component.html',
  styleUrls: ['./import-page-modal.component.scss']
})
export class ImportPageModalComponent implements OnInit {

  availableCategories: HaPageCategory[] = [];
  selectedCategory: number = 0;
  fileName = '';
  file!: File;
  roleSvc = inject(RoleService);
  userSvc = inject(LibUserService);
  importFormGroup!: FormGroup;

  @Output() pageImported = new EventEmitter();

  constructor(private apiService: LibHttpClientApiService, private pageService: PageService, private activeModal: NgbActiveModal, private formBuilder: FormBuilder) { }

  dismiss() {
    return this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.importFormGroup = this.formBuilder.group({
      pageCategory: [null, Validators.required],  // Vous pouvez ajuster les validateurs selon vos besoins
      pageImportConfigFile: [null, Validators.required]
    });
    this.apiService.get<PageManagement>(PageUrls.INDEX).subscribe(r => {
      if (r) {
        this.availableCategories = r.Categories;
      }
    });
  }

  onValidate() {
    const pageCategoryControl = this.importFormGroup.get('pageCategory');
    this.selectedCategory = pageCategoryControl?.value;
    const formData = new FormData();
    formData.append(this.fileName, this.file);
    formData.append("categoryId", this.selectedCategory.toString());

    this.pageService.importConfig(formData)?.subscribe(res => {
      if (res) {
        /* 
        Impossible de récuperer les roles du user, un coup ça renvoie un array de string, un coup ça renvoie un array de Role je sais pas pourquoi
        const isPowerUser = this.userSvc.user?.Roles.find(roleName => roleName.Name == 'PowerUser')
        const isAdmin = this.userSvc.user?.Roles.find(roleName => roleName.Name == 'Admin') 
        */
       
        // Donc j'ai mis en dur les roles admin et powerUser par défaut
        this.roleSvc.InsertViewPageRole({ RoleId: "bae3a8d8-5abe-4c9e-8088-bbdf863e4fb9", PageId: res.Id }).subscribe(() => {});
        this.roleSvc.InsertViewPageRole({ RoleId: "7b065115-d19c-4b95-b0ab-bd0e0a301479", PageId: res.Id }).subscribe(() => {});

        this.activeModal.close();
        this.pageImported.emit();
      }
    });
  }

  onFileSelected(event: any) {
    if (event.target.files[0]) {
      this.fileName = event.target.files[0].name;
      // Check if the file has the .dat extension
      if (this.hasDatExtension(this.fileName)) {
        this.file = event.target.files[0];
      } else {
        // Reset the input and show an error message
        this.importFormGroup.get('pageImportConfigFile')?.setValue(null);
      }
    }
  }

  private hasDatExtension(fileName: string): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension === 'dat';
  }
}
