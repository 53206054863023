import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Trigger } from '../lib-scheduler-service/lib-scheduler-service.types';

@Component({
    selector: 'lib-cron-editor',
    templateUrl: './lib-cron-editor.component.html',
    styleUrls: ['./lib-cron-editor.component.scss']
})
export class LibCronEditorComponent implements OnInit {

    @Input() displayMinute!: boolean;
    @Input() displayHours!: boolean;
    @Input() currentTrigger!: Trigger | null;
    @Input() context!: string;

    addEditModalCronExpression: string = "";
    finalCronExpression = "* * * ? * * *";
    @Output() cronEvent = new EventEmitter<string>();

    //cron expression variable for ng-select and checkbox days :
    listOfMinutesWith0 = [{ value: 1, label: 1 }];
    listOfMinutes = [{ value: 1, label: 1 }];
    listOfHoursWith0 = [{ value: 1, label: 1 }];
    listOfHours = [{ value: 1, label: 1 }];
    listOfDays = [{ value: 1, label: 1 }];
    listofDaysLabel = [{ value: 'MON', label: this.translatePipe.transform('lbl-monday') },
    { value: 'TUE', label: this.translatePipe.transform('lbl-tuesday') },
    { value: 'WED', label: this.translatePipe.transform('lbl-wednesday') },
    { value: 'THU', label: this.translatePipe.transform('lbl-thursday') },
    { value: 'FRI', label: this.translatePipe.transform('lbl-friday') },
    { value: 'SAT', label: this.translatePipe.transform('lbl-saturday') },
    { value: 'SUN', label: this.translatePipe.transform('lbl-sunday') },
    ];
    listofDaysLabel_number = [{ value: 2, label: this.translatePipe.transform('lbl-monday') },
    { value: 3, label: this.translatePipe.transform('lbl-tuesday') },
    { value: 4, label: this.translatePipe.transform('lbl-wednesday') },
    { value: 5, label: this.translatePipe.transform('lbl-thursday') },
    { value: 6, label: this.translatePipe.transform('lbl-friday') },
    { value: 7, label: this.translatePipe.transform('lbl-saturday') },
    { value: 1, label: this.translatePipe.transform('lbl-sunday') },
    ];
    listOfMonth = [{ value: 1, label: 1 }];
    listOfMonthLabel = [{ value: '1', label: this.translatePipe.transform('lbl-january') },
    { value: '2', label: this.translatePipe.transform('lbl-february') },
    { value: '3', label: this.translatePipe.transform('lbl-march') },
    { value: '4', label: this.translatePipe.transform('lbl-april') },
    { value: '5', label: this.translatePipe.transform('lbl-may') },
    { value: '6', label: this.translatePipe.transform('lbl-june') },
    { value: '7', label: this.translatePipe.transform('lbl-july') },
    { value: '8', label: this.translatePipe.transform('lbl-august') },
    { value: '9', label: this.translatePipe.transform('lbl-september') },
    { value: '10', label: this.translatePipe.transform('lbl-october') },
    { value: '11', label: this.translatePipe.transform('lbl-november') },
    { value: '12', label: this.translatePipe.transform('lbl-december') },
    ];

    //cron expression generator:
    //for keep the previous cron expression
    oldSecondsCronExpression: string = '*';
    oldMinutesCronExpression: string = '*';
    oldHoursCronExpression: string = '*';
    oldDayOfmonthCronExpression: string = '?';
    oldmonthCronExpression: string = '*';
    oldDayOfWeekCronExpression: string = '*';
    oldYearsCronExpression: string = '*';
    //- section minutes
    CronGeneratorFromMinutesMethodeSelected: string = "EveryMinutes";
    SpecificMinutesValue: number = 1;
    SpecificMinutesValuePart2: number = 0;
    ExpressionCronMinutes: string = '*'
    //- section hours
    CronGeneratorFromHoursMethodeSelected: string = "EveryHours";
    SpecificHoursValue: number = 1;
    SpecificHoursValuePart2: number = 0;
    ExpressionCronHours: string = '*'
    //- section days
    CronGeneratorFromDaysMethodeSelected: string = "EveryDays";
    SpecificDaysValue: number = 1;
    SpecificDaysValuePart2: number = 1;
    LastSpecificDayOfmonthValue: number = 1;
    LastSpecificNumberDayOfmonthValue: number = 1;
    valueCheckboxDay: string[] = ['MON'];
    ExpressionCronDays: string = '*'
    //- section month
    CronGeneratorFrommonthMethodeSelected: string = "Everymonth";
    EverySpecificmonthValue: number = 1;
    EverySpecificmonthValuePart2: number = 1;
    ExpressionCronmonths: string = '*'

    constructor(private translatePipe: TranslatePipe) {

    }

    ngOnInit(): void {
        console.log(this.context, "-------", this.currentTrigger);
        //fill in  variables for the cron generator
        this.numSequence(60);
        this.addEditModalCronExpression = this.translatePipe.transform("add-edit-modal-job-cron-expression");
    }

    //- section minutes
    //function called when we change the ng select, it s use full for testing if the input radio which contains ng select is selected,
    //to avoid calling the function CronExpressionMinute for nothing
    CronExpressionMinuteNg_Select_Change() {
        if (this.CronGeneratorFromMinutesMethodeSelected == "SpecificMinutes") {
            this.CronExpressionMinute();
        }
    }
    //function for build the part minute of the cron expression depends which radio buton as been checked
    CronExpressionMinute() {
        if (this.CronGeneratorFromMinutesMethodeSelected == "EveryMinutes") {
            this.ExpressionCronMinutes = "*";
        }
        else if (this.CronGeneratorFromMinutesMethodeSelected == "SpecificMinutes") {//this input radio contains ng-select
            this.ExpressionCronMinutes = this.SpecificMinutesValuePart2.toString() + "/" + this.SpecificMinutesValue.toString();
        }
        else {
            this.ExpressionCronMinutes = "*";
        }
        //call the function for update the cron expression
        this.CreateCronExpression('minutes', this.CronGeneratorFromMinutesMethodeSelected);
    }

    //- section hours
    //function called when we change the ng select, it s use full for testing if the input radio which contains ng select is selected,
    //to avoid calling the function CronExpressionHours for nothing
    CronExpressionHoursNg_Select_Change() {
        if (this.CronGeneratorFromHoursMethodeSelected == "SpecificHours") {
            this.CronExpressionHours();
        }
    }
    //function for build the part minute of the cron expression depends which radio buton as been checked
    CronExpressionHours() {
        if (this.CronGeneratorFromHoursMethodeSelected == "EveryHours") {
            this.ExpressionCronHours = "*";
        }
        else if (this.CronGeneratorFromHoursMethodeSelected == "SpecificHours") {//this input radio contains ng-select
            this.ExpressionCronHours = this.SpecificHoursValuePart2.toString() + "/" + this.SpecificHoursValue.toString();
        }
        else {
            this.ExpressionCronHours = "*";
        }
        //call the function for update the cron expression
        this.CreateCronExpression('hours', this.CronGeneratorFromHoursMethodeSelected);
    }

    //- section months
    //function called when we change the ng select, it s use full for testing if the input radio which contains ng select is selected,
    //to avoid calling the function CronExpressionmonths for nothing
    CronExpressionmonthsNg_Select_Change() {
        if (this.CronGeneratorFrommonthMethodeSelected == "EverySpecificmonth") {
            this.CronExpressionmonths();
        }
    }
    //function for build the part minute of the cron expression depends which radio buton as been checked
    CronExpressionmonths() {
        if (this.CronGeneratorFrommonthMethodeSelected == "Everymonth") {
            this.ExpressionCronmonths = "*";
        }
        else if (this.CronGeneratorFrommonthMethodeSelected == "EverySpecificmonth") {//this input radio contains ng-select
            this.ExpressionCronmonths = this.EverySpecificmonthValuePart2.toString() + "/" + this.EverySpecificmonthValue.toString();
        }
        else {
            this.ExpressionCronmonths = "*";
        }
        //call the function for update the cron expression
        this.CreateCronExpression('month', this.CronGeneratorFrommonthMethodeSelected);
    }

    //- section days
    //function to update the array valueCheckboxDay which contains the values thats represents the days selected and so update the section days of the cron expression
    getValueCheckboxDay(event: any) {
        var value = event.currentTarget.defaultValue
        if (event.currentTarget.checked == true) {
            this.valueCheckboxDay.push(value);
        }
        else {
            this.valueCheckboxDay.forEach((element, index) => {
                if (element == value) this.valueCheckboxDay.splice(index, 1);
            });
        }

        if (this.CronGeneratorFromDaysMethodeSelected == "SpecificDaysOfTheWeek") {
            //call the function for update the section days of the cron expression
            this.CronExpressionDays();
        }
    }
    //function called when we change the ng select, it s use full for testing if the input radio which contains ng select is selected,
    //to avoid calling the function CronExpressionDays for nothing
    CronExpressionDaysNg_Select_Change() {
        if (this.CronGeneratorFromDaysMethodeSelected == "SpecificDays" ||
            this.CronGeneratorFromDaysMethodeSelected == "LastSpecificDayOfmonth" ||
            this.CronGeneratorFromDaysMethodeSelected == "LastSpecificNumberDayOfmonth") {
            this.CronExpressionDays();
        }
    }
    //function for build the part minute of the cron expression depends which radio buton as been checked
    CronExpressionDays() {
        if (this.CronGeneratorFromDaysMethodeSelected == "EveryDays") {
            this.ExpressionCronDays = "*";
        }
        else if (this.CronGeneratorFromDaysMethodeSelected == "SpecificDays") {//this input radio contains ng-select
            this.ExpressionCronDays = this.SpecificDaysValuePart2.toString() + "/" + this.SpecificDaysValue.toString();
        }
        else if (this.CronGeneratorFromDaysMethodeSelected == "SpecificDaysOfTheWeek") {
            //create the expression days with the array valueCheckboxDay
            this.ExpressionCronDays = "";
            for (let i = 0; i < this.valueCheckboxDay.length; i++) {
                if (i == 0) {
                    this.ExpressionCronDays = this.valueCheckboxDay[0];
                }
                else {
                    this.ExpressionCronDays = this.ExpressionCronDays + ',' + this.valueCheckboxDay[i]
                }
            }
        }
        else if (this.CronGeneratorFromDaysMethodeSelected == "LastDayOfmonth") {
            this.ExpressionCronDays = "L";
        }
        else if (this.CronGeneratorFromDaysMethodeSelected == "FirstDayOfmonth") {
            this.ExpressionCronDays = "1";
        }
        else if (this.CronGeneratorFromDaysMethodeSelected == "LastSpecificDayOfmonth") { //this input radio contains ng-select
            this.ExpressionCronDays = this.LastSpecificDayOfmonthValue.toString() + "L";
        }
        else if (this.CronGeneratorFromDaysMethodeSelected == "LastSpecificNumberDayOfmonth") {//this input radio contains ng-select
            this.ExpressionCronDays = "L-" + this.LastSpecificNumberDayOfmonthValue.toString();
        }
        else {
            this.ExpressionCronDays = "*";
        }
        //call the function for update the cron expression
        this.CreateCronExpression('days', this.CronGeneratorFromDaysMethodeSelected);
    }

    //function to update cron expression according to rules of cron expression
    CreateCronExpression(whichSection: string, radioSelected: string) {
        //use for keep the previous expression and update this
        var seconds = this.oldSecondsCronExpression;
        var minutes = this.oldMinutesCronExpression;
        var hours = this.oldHoursCronExpression;
        var day_of_month = this.oldDayOfmonthCronExpression;
        var month = this.oldmonthCronExpression;
        var day_of_week = this.oldDayOfWeekCronExpression;
        var years = this.oldYearsCronExpression;

        switch (whichSection) {
            case 'minutes': {
                minutes = this.ExpressionCronMinutes;
                this.oldMinutesCronExpression = minutes;
                break;
            }
            case 'hours': {
                hours = this.ExpressionCronHours;
                this.oldHoursCronExpression = hours;
                break;
            }
            case 'days': {
                switch (radioSelected) {
                    case 'EveryDays': {
                        day_of_month = '?';
                        day_of_week = '*';
                        this.oldDayOfmonthCronExpression = day_of_month;
                        this.oldDayOfWeekCronExpression = day_of_week;
                        break;
                    }
                    case 'SpecificDays': case 'LastDayOfmonth': case 'FirstDayOfmonth': case 'LastSpecificNumberDayOfmonth': {
                        day_of_month = this.ExpressionCronDays;
                        day_of_week = '?';
                        this.oldDayOfmonthCronExpression = day_of_month;
                        this.oldDayOfWeekCronExpression = day_of_week;
                        break;
                    }
                    case 'LastSpecificDayOfmonth': {
                        day_of_month = '?';
                        day_of_week = this.ExpressionCronDays;
                        this.oldDayOfmonthCronExpression = day_of_month;
                        this.oldDayOfWeekCronExpression = day_of_week;
                        break;
                    }
                    case 'SpecificDaysOfTheWeek': {
                        //condition to have necessarily a value for the day expression
                        if (this.valueCheckboxDay.length == 0) {
                            this.ExpressionCronDays = 'MON';
                        }
                        day_of_month = '?';
                        day_of_week = this.ExpressionCronDays;
                        this.oldDayOfmonthCronExpression = day_of_month;
                        this.oldDayOfWeekCronExpression = day_of_week;
                        break;
                    }
                    default: {
                        break;
                    }
                }
                break;
            }
            case 'month': {
                month = this.ExpressionCronmonths
                this.oldmonthCronExpression = month
                break;
            }
            default: {
                break;
            }

        }
        if (this.context == "add") {
            this.finalCronExpression = seconds + ' ' + minutes + ' ' + hours + ' ' + day_of_month + ' ' + month + ' ' + day_of_week + ' ' + years;
            this.cronEvent.emit(this.finalCronExpression);
        }
        else {
            this.currentTrigger!.CronExpressionString = seconds + ' ' + minutes + ' ' + hours + ' ' + day_of_month + ' ' + month + ' ' + day_of_week + ' ' + years;
            this.cronEvent.emit(this.currentTrigger!.CronExpressionString);
        }
    }

    //function that allows to fill the arrays used in the ng-select
    numSequence(n: number): void {
        for (let i = 2; i <= n; i++) {
            if (i < 13) {
                this.listOfMonth.push({ value: i, label: i });
                this.listOfHours.push({ value: i, label: i });
                this.listOfHoursWith0.push({ value: i, label: i });
                this.listOfDays.push({ value: i, label: i });
                this.listOfMinutes.push({ value: i, label: i });
                this.listOfMinutesWith0.push({ value: i, label: i });
            }
            else if (i < 25) {
                this.listOfHours.push({ value: i, label: i });
                this.listOfHoursWith0.push({ value: i, label: i });
                this.listOfDays.push({ value: i, label: i });
                this.listOfMinutes.push({ value: i, label: i });
                this.listOfMinutesWith0.push({ value: i, label: i });
            }
            else if (i < 32) {
                this.listOfDays.push({ value: i, label: i });
                this.listOfMinutes.push({ value: i, label: i });
                this.listOfMinutesWith0.push({ value: i, label: i });
            }
            else {
                this.listOfMinutes.push({ value: i, label: i });
                this.listOfMinutesWith0.push({ value: i, label: i });
            }
        }
        //delete le value 0 and the last element(60) for minutes and hours
        this.listOfMinutesWith0.splice(0, 0, { value: 0, label: 0 });
        this.listOfMinutesWith0.pop();
        this.listOfHoursWith0.splice(0, 0, { value: 0, label: 0 });
        this.listOfHoursWith0.pop();
    }
}
