import { Component, Input, OnInit } from '@angular/core';
import { LibCardChartService } from '../lib-card-chart.service';
import { Subject } from 'rxjs/internal/Subject';
import { LibHttpClientApiService } from '@herdia-common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'lib-lib-chart-define-pie',
  templateUrl: './lib-chart-define-pie.component.html',
  styleUrls: ['./lib-chart-define-pie.component.css']
})
export class LibChartDefinePieComponent implements OnInit {
  @Input() config: { [key: string]: any } = {};

  getDatasourceMetadata: Subject<void> = new Subject<void>();
  availableStringDatasources: { key: string, label: string}[] = [];
  availableNumberDatasources: { key: string, label: string}[] = [];
  constructor(private chartService: LibCardChartService, private apiService: LibHttpClientApiService, private translatePipe: TranslatePipe) {
  }

  get availablePalettes() {
    return this.chartService.availablePalettes;
  }
  
  ngOnInit(): void {
    if (!this.config["ChartConfig"]["DataSources"]) {
      this.config["ChartConfig"]["DataSources"] = {
        Labels: "",
        Datas: "",
        ColorPalette: ""
      };
    }

    let stringDatasources: { key: string, label: string}[] = [];
    let numberDatasources: { key: string, label: string}[] = [];
    let resultSet = this.apiService.getActionResult("/api/alhambra", this.config["DataSource"]["ProcedureName"], "Datas", "post").responses;
    Object.keys(resultSet).forEach(key => {
      let item = resultSet[key];
      let CSType = this.apiService.getCSTypeFromJSONType(item);
      if (CSType.toLowerCase().indexOf("string") !== -1 ||
          CSType.toLowerCase().indexOf("datetime") !== -1)
        stringDatasources.push({ key: key, label: this.translatePipe.transform(key)});
      if (CSType.toLowerCase().indexOf("decimal") !== -1 ||
          CSType.toLowerCase().indexOf("int") !== -1)
          numberDatasources.push({ key: key, label: this.translatePipe.transform(key)});
    });
    this.availableStringDatasources = stringDatasources;
    this.availableNumberDatasources = numberDatasources;
  }
}
