<div class="col-12">
    <span class="float-right text-muted text-sm">{{time}}</span>
    <div class="info-box" style="display:inline-flex;">
        <span class="info-box-icon bg-success"><i class="fas fa-print mr-2"></i></span>
        <div class="info-box-content">
            <button *ngIf="hasDownload" target="_blank" (click)="downloadItem();" class="dropdown-item" style="overflow-x: hidden;">
                {{title}}
                <p class="text-center"><small>{{message}}</small></p>
            </button>
        </div>
    </div>
</div>
<div class="dropdown-divider"></div>
