import { Component, ComponentRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewContainerRef, inject } from '@angular/core';
import { environment } from '@environment';
import { DynamicComponentsService } from '../dynamic-components.service';

@Component({
    selector: 'app-dynamic-component',
    template: '', // Template will be build dynamically
})
export class DynamicComponentComponent implements OnChanges, OnDestroy {

    private dynamicComponentsService = inject(DynamicComponentsService);
    private viewContainerRef = inject(ViewContainerRef);

    @Input() selector?: string;
    @Input() inputs?: { [k: string]: any } = {};

    private componentRef?: ComponentRef<any>;

    public get component() {
        return this.componentRef;
    }

    ngOnChanges({ selector, inputs }: SimpleChanges): void {
        if (!!selector) {
            this.renderComponent();
        }
        if (!!inputs) {
            this.updateComponentInputs();
        }
    }

    ngOnDestroy(): void {
        this.clearComponent();
    }

    private renderComponent() {
        this.clearComponent();
        if (!this.selector) return;

        const dynamicComponent = this.dynamicComponentsService.getDynamicComponent(this.selector);
        if (!dynamicComponent) return;

        this.componentRef = this.viewContainerRef.createComponent(
            dynamicComponent.component,
            {
                ngModuleRef: dynamicComponent.module
            }
        );
    }

    private updateComponentInputs() {
        if (!this.componentRef?.instance || !this.inputs) return;

        Object.entries(this.inputs)
            .forEach(([inputName, inputValue]) => {
                if (inputName in this.componentRef!.instance) {
                    this.componentRef?.setInput(inputName, inputValue);
                }
            });

        this.logInputs();
    }

    private logInputs() {
        if (environment.production || !this.selector || !this.inputs || !this.componentRef?.instance) return;

        console.group(`Trying to set the following input values on component %c${this.selector}:`, 'text-decoration: underline');
        Object.entries(this.inputs).forEach(([inputName, inputValue]) => {
            if (inputName in this.componentRef!.instance) {
                console.log(`- ${inputName}: ${inputValue}`);
            } else {
                // If you see this error in your console, just make sure that the input that is not applicable is a normal behavior
                console.log(`%c- (not applicable) ${inputName}: ${inputValue}`, 'color: orange');
            }
        });
        console.groupEnd();
    }

    private clearComponent() {
        this.componentRef?.destroy();
    }

}
