import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common';
import { AddActionsMissingRequest, CategoryRoleActions, InsertViewPageRoleRequest, ModifyRoleViewOnPageRequest, PageRole, Role } from '../models/interfaces';
import { RoleUrls } from './api.service';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private apiSvc: LibHttpClientApiService) { }

  getRoles() {
    return this.apiSvc.get<Role[]>(RoleUrls.GET_ALL);
  }

  getCategories() {
    return this.apiSvc.get<CategoryRoleActions[]>(RoleUrls.GET_CATEGORIES);
  }

  AddUpdateRole(id: string, name: string) {
    if (id)
      return this.apiSvc.post<Role, boolean>(RoleUrls.UPDATE, { Id: id, Name: name })?.pipe(
        tap(res => { if (!res) console.error('Error updating role'); }));
    return this.apiSvc.post<Role, boolean>(RoleUrls.ADD, { Id: id, Name: name })?.pipe(
      tap(res => { if (!res) console.error('Error adding role'); }));
  }

  deleteRole(id: string) {
    return this.apiSvc.delete1<boolean>(`${RoleUrls.DELETE}/${id}`).pipe(
      tap(res => { if (!res) console.error('Error deleting role'); }));
  }

  save(data: CategoryRoleActions[]) {
    return this.apiSvc.post<CategoryRoleActions[], boolean>(RoleUrls.SAVE_CATEGORIES, data)?.pipe(
      tap(res => { if (!res) console.error('Error saving roles'); }));
  }

  addActionsMissing(data: AddActionsMissingRequest) {
    return this.apiSvc.post<AddActionsMissingRequest, boolean>(RoleUrls.ADD_ACTIONS_MISSING, data)?.pipe(
      tap(res => { if (!res) console.error('Error adding actions'); }));
  }

  //MAPA
  GetAllRolesAndPagesAndRelationBetween() {
    return this.apiSvc.get<any>(RoleUrls.GET_ALL_INFORMATIONS);
  }

  AddOrRemoveRoleViewOnPage(requestParam: ModifyRoleViewOnPageRequest) {
    return this.apiSvc.post<ModifyRoleViewOnPageRequest, boolean>(RoleUrls.ADD_REMOVE_VISIBILITY, requestParam);
  }

  InsertViewPageRole(requestParam: InsertViewPageRoleRequest) {
    return this.apiSvc.post<InsertViewPageRoleRequest, boolean>(RoleUrls.INSERT_VIEW_PAGE_ROLE, requestParam);
  }

  GetAllPagesWithRoles() {
    return this.apiSvc.get<PageRole[]>(RoleUrls.GET_ALL_PAGES_ROLES);
  }
}
