import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { environment } from '../../../../environments/environment';
import { AccountService } from '../service/account.service';

@Injectable()
export class ApiInitInterceptor implements HttpInterceptor {

    constructor(private accountSvc: AccountService) { }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        var request = this.addBaseUrl(req);
        return next.handle(request)
            .pipe(catchError((error: HttpErrorResponse) => {
                return this.catchError(error);
            }));
    }

    private addBaseUrl(req: HttpRequest<unknown>) {
        var accessToken = this.accountSvc.userValue?.Token;
        if (accessToken)
            return req.clone({
                url: `${environment.apiBaseUrl}/${req.url}`,
                setHeaders: { 'Authorization': `Bearer ${accessToken}` }
            });

        return req.clone({ url: `${environment.apiBaseUrl}/${req.url}` });
    }

    private catchError(error: HttpErrorResponse) {
        let errorMsg = '';
        if (error.status === 401 || error.status === 403) {
            this.accountSvc.logout();
        }
        if (error.error instanceof ErrorEvent) {
            console.log('This is client side error');
            errorMsg = `Error: ${error.error.message}`;
        } else {
            console.log('This is server side error');
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        this.logError(errorMsg);
        return throwError(error);
    }

    private logError(msg: string) {
        console.log(msg);
    }
}
