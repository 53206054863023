import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnSetting, HerdiaDatatableGlobalConfiguration, LibConfirmBoxService, LibHerdiaDatatableComponent, LibHttpClientApiService, ServerSideResponse } from '@herdia-common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DBConnectionService } from '../shared/service/dbconnection.service';
import { AddDbconnectionModalComponent } from './add-dbconnection-modal/add-dbconnection-modal.component';

@Component({
  selector: 'app-dbconnection-manager',
  templateUrl: './dbconnection-manager.component.html',
  styleUrls: ['./dbconnection-manager.component.scss']
})
export class DBConnectionManagerComponent implements OnInit {

  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;

  cardId: number = 999999991;

  deleteTitleModal: string = "";
  deleteMsgModal: string = "";
  deleteValidateModal: string = "";
  deleteCancelModal: string = "";
  dbConnectionToDelete: any;

    herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
        compactMode: false,
        cellBorder: true,
        hover: true,
        orderColumn: true,
        rowBorder: false,
        stripe: true,
        nowrap: false,
        defaultRowNumberOnPage: 10,
        autoWidth: false,
        fixedLeftColumns: 0
    };

    onRefresh = (data: any): void => {
        // TODO: coder le refresh d'une connexion
    }
    onEdit = (data: any): void => {
        // TODO: coder l'edit d'une connexion (mais c'est quoi exactement l'edit?)
    }

    onDelete = (data: any): void => {
        this.dbConnectionToDelete = data;
        this.confirmBoxSvc.open({
            title: this.deleteTitleModal,
            msg: this.deleteMsgModal,
            okBtnLbl: this.deleteValidateModal,
            koBtnLbl: this.deleteCancelModal,
        });
    }

  onDownloadPDB = (data: any) => {
    this.apiService.downloadFile(
      "api/HAUpload/GetFile/" + data.PDBUploadDefinitionId,
      data.Id + "_" + data.Name + ".pdb"
    );
  }

  onDownloadAssembly = (data: any): void => {
    this.apiService.downloadFile(
      "api/HAUpload/GetFile/" + data.AssemblyUploadDefinitionId,
      data.Id + "_" + data.Name + ".dll"
    );
  }
  onDownloadSource = (data: any): void => {
    this.apiService.downloadFile(
      "api/HAUpload/GetFile/" + data.SourcesUploadDefinitionId,
      data.Id + "_Sources_" + data.Name + ".zip"
    );
  }

    columnSettings: ColumnSetting[] = [
        {
            name: "Name",
            label: "Nom",
            type: "string",
            visible: true,
            nbDecimal: 0,
            nature: "normal",
            horizontalAlign: 'left',
            displaySum: false,
            width: 200,
            actionColumnDef: null
        },
        {
            name: "ConnectionType",
            label: "Connexion",
            type: "string",
            visible: true,
            horizontalAlign: 'left',
            displaySum: false,
            actionColumnDef: null,
            nbDecimal: 0,
            nature: "normal",
            width: 100
        },
        {
            name: "ApiRoute",
            label: "Route",
            type: "string",
            visible: true,
            horizontalAlign: "left",
            width: 100,
            actionColumnDef: null,
            nature: "normal",
            nbDecimal: 0,
            displaySum: false
        },
        {
            name: "Actions",
            label: "Actions",
            type: "custom",
            visible: true,
            actionColumnDef: {
                align: "center",
                buttons: [
                    {
                        type: "button",
                        icon: "download",
                        text: "DLL",
                        class: 'btn btn-primary btn-sm btn-tool',
                        onclick: this.onDownloadAssembly
                    },
                    {
                        type: "button",
                        icon: "download",
                        text: "PDB",
                        class: 'btn btn-primary btn-sm btn-tool',
                        onclick: this.onDownloadPDB
                    },
                    {
                        type: "button",
                        icon: "download",
                        text: "Sources",
                        class: 'btn btn-primary btn-sm btn-tool',
                        onclick: this.onDownloadSource
                    },
                    //{
                    //    type: "button",
                    //    icon: "rotate-right",
                    //    text: "",
                    //    class: 'btn btn-primary btn-sm btn-tool',
                    //    onclick: this.onRefresh
                    //},
                    {
                        type: "button",
                        icon: "trash",
                        text: "",
                        class: 'btn btn-primary btn-sm btn-tool',
                        onclick: this.onDelete
                    }
                ]
            },
            displaySum: false,
            nbDecimal: 0,
            nature: "normal",
            width: 200,
            horizontalAlign: "center"
        }
    ];
    constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private translatePipe: TranslatePipe, private confirmBoxSvc: LibConfirmBoxService, private dbconnectionService: DBConnectionService, private apiService: LibHttpClientApiService) {
        this.deleteTitleModal = translatePipe.transform("delete-dbconnection-title-modal-card");
        this.deleteMsgModal = translatePipe.transform("delete-dbconnection-message-modal-card");
        this.deleteValidateModal = translatePipe.transform("delete-dbconnection-validate-button-modal-card");
        this.deleteCancelModal = translatePipe.transform("delete-dbconnection-cancel-button-modal-card");

    confirmBoxSvc.confirmationSub.subscribe((c: Boolean) => {
      if (c) {
        this.dbconnectionService.delete(this.dbConnectionToDelete.Id)?.subscribe((c) => {
          if (c) {
            this.herdiaDatatable.reload();
            this.activeModal.close();
          }
        });
      }
    });
  }

  getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
    return this.dbconnectionService.read(dataTablesParameters);
  }
  onAddConnection(): void {
    const modalRef = this.modalService.open(AddDbconnectionModalComponent);
    modalRef.componentInstance.dbConnectonAdded.subscribe(() => {
      this.herdiaDatatable.reload();
    });
  }

  ngOnInit(): void {
  }
}
