import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DynamicComponentDeclaration, DynamicComponentPackage, DynamicComponentsBaseModule, LibCardHeaderCommonButtonsModule, LibColumnSelectorModule, LibCronEditorModule, LibDatatablePaginationModule, LibFileDepositConfigModule, LibHerdiaAppTranslateModule, LibHerdiaDatatableModule, LibHerdiaEnumerationModule, LibPropertyTypeInputModule, LibSchedulerServiceModule } from '@herdia-common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { AngularResizeEventModule } from 'angular-resize-event';
import { DragulaModule } from 'ng2-dragula';
import { LibFilterCardModule } from '../lib-filter-card/lib-filter-card.module';
import { LibProcedureInputModule } from '../lib-procedure-input/lib-procedure-input.module';
import { LibCardReportConfigDatatableAspectComponent } from './lib-card-report-config-datatable-aspect/lib-card-report-config-datatable-aspect.component';
import { LibCardReportConfigFileDepositComponent } from './lib-card-report-config-file-deposit/lib-card-report-config-file-deposit.component';
import { LibCardReportConfigParametersComponent } from './lib-card-report-config-parameters/lib-card-report-config-parameters.component';
import { LibCardReportConfigReportComponent } from './lib-card-report-config-report/lib-card-report-config-report.component';
import { LibCardReportConfigRichReportComponent } from './lib-card-report-config-rich-report/lib-card-report-config-rich-report.component';
import { LibCardReportConfigSchedulingGenerateComponent } from './lib-card-report-config-scheduling/lib-card-report-config-scheduling-generate/lib-card-report-config-scheduling-generate.component';
import { LibCardReportConfigSchedulingJobComponent } from './lib-card-report-config-scheduling/lib-card-report-config-scheduling-job/lib-card-report-config-scheduling-job.component';
import { LibCardReportConfigSchedulingParametersComponent } from './lib-card-report-config-scheduling/lib-card-report-config-scheduling-parameters/lib-card-report-config-scheduling-parameters.component';
import { LibCardReportFooterComponent } from './lib-card-report-footer/lib-card-report-footer.component';
import { LibCardReportHeaderButtonsComponent } from './lib-card-report-header-buttons/lib-card-report-header-buttons.component';
import { LibCardReportComponent } from './lib-card-report.component';
import { FilterColumnPipe } from './lib-card-report.filter.columns.pipe';
import { LibHeaderBindingModule } from '../lib-header-binding/lib-header-binding.module';

@NgModule({
    declarations: [
        LibCardReportComponent,
        LibCardReportConfigParametersComponent,
        LibCardReportConfigReportComponent,
        LibCardReportFooterComponent,
        LibCardReportHeaderButtonsComponent,
        LibCardReportConfigRichReportComponent,
        FilterColumnPipe,
        LibCardReportConfigDatatableAspectComponent,
        LibCardReportConfigSchedulingParametersComponent,
        LibCardReportConfigSchedulingJobComponent,
        LibCardReportConfigSchedulingGenerateComponent,
        LibCardReportConfigFileDepositComponent
    ],
    imports: [
        ReactiveFormsModule,
        DataTablesModule,
        CommonModule,
        FormsModule,
        LibProcedureInputModule,
        LibHerdiaAppTranslateModule,
        NgSelectModule,
        FontAwesomeModule,
        LibColumnSelectorModule,
        DragulaModule.forRoot(),
        LibDatatablePaginationModule,
        LibFilterCardModule,
        NgbModule,
        TranslateModule,
        AngularResizeEventModule,
        LibHerdiaDatatableModule,
        LibCardHeaderCommonButtonsModule,
        LibHerdiaDatatableModule,
        LibCronEditorModule,
        LibSchedulerServiceModule,
        LibHerdiaEnumerationModule,
        LibFileDepositConfigModule,
        LibPropertyTypeInputModule,
        LibHeaderBindingModule
    ],
    exports: [
        LibCardReportComponent,
        LibCardReportConfigParametersComponent,
        LibCardReportConfigReportComponent,
        LibCardReportConfigDatatableAspectComponent,
        LibCardReportFooterComponent,
        LibCardReportHeaderButtonsComponent,
        LibCardReportConfigRichReportComponent,
        LibCardReportConfigSchedulingParametersComponent,
        LibCardReportConfigSchedulingJobComponent,
        LibCardReportConfigSchedulingGenerateComponent
    ],
    providers: [
        DatePipe
    ]
})
export class LibCardReportModule extends DynamicComponentsBaseModule {

    override getPackageName(): DynamicComponentPackage {
        return 'alhambra';
    }

    override getDynamicComponentsDeclarations(): DynamicComponentDeclaration[] {
        return [
            { component: LibCardReportComponent, type: 'card' },
            { component: LibCardReportConfigDatatableAspectComponent, type: 'config' },
            { component: LibCardReportConfigFileDepositComponent, type: 'config' },
            { component: LibCardReportConfigParametersComponent, type: 'config' },
            { component: LibCardReportConfigReportComponent, type: 'config' },
            { component: LibCardReportConfigRichReportComponent, type: 'config' },
            { component: LibCardReportConfigSchedulingGenerateComponent, type: 'config' },
            { component: LibCardReportConfigSchedulingJobComponent, type: 'config' },
            { component: LibCardReportConfigSchedulingParametersComponent, type: 'config' },
            { component: LibCardReportFooterComponent, type: 'default' },
            { component: LibCardReportHeaderButtonsComponent, type: 'default' },
        ]
    }

}
