import { Component, OnInit } from '@angular/core';
import { CardConfigBaseComponent } from '@herdia-common';

@Component({
    selector: 'lib-card-report-footer',
    templateUrl: './lib-card-report-footer.component.html',
    styleUrls: ['./lib-card-report-footer.component.css']
})
export class LibCardReportFooterComponent extends CardConfigBaseComponent implements OnInit {
  cardId!: number;

  ngOnInit(): void {
    this.cardId = this.config['cardId'];
  }
  
}
