import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  constructor(private http: HttpClient) { }

  SendMailForForgotPassword(email:string){    
    const contentRequest = { Email: email };
    return this.http.post('api/UserManagement/SendMailForForgotPassword',contentRequest);
  }

  ResetPassword(password:string, password_confirm:string, email:string, token:string){
    const contentRequest = { Password: password, ConfirmPassword: password_confirm, Email: email, Token: token };
    return this.http.post('api/UserManagement/resetPassword',contentRequest);
  }

  CheckPassword(password:string){
    const contentRequest = {Password: password}
    return this.http.post('api/UserManagement/checkPassword',contentRequest);
  }

}
