import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { LibIconPickerComponent } from './lib-icon-picker.component';

@NgModule({
    declarations: [
        LibIconPickerComponent
    ],
    imports: [
        NgSelectModule,
        FormsModule,
        FontAwesomeModule,
    ],
    exports: [
        LibIconPickerComponent,
        FontAwesomeModule,
    ]
})
export class LibIconPickerModule {

    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
    }

}
