import { Injectable } from '@angular/core';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { ServerSideRequest, ServerSideResponse } from '../lib-herdia-datatable/lib-herdia-datatable.interfaces';
import { LibHttpClientApiService } from '../lib-http-client-api/lib-http-client-api.service';
import { AreaUnitEnum, CurrencyEnum, DateFormatEnum, DateFormatForDatePipeEnum, LanguageCodeEnum, RoleUrls, UserUrls } from './lib-user.enum';
import { Role, User, UserResponse } from './lib-user.interface';

@Injectable({
    providedIn: 'root'
})
export class LibUserService {

    private _user!: User | null;
    private _language!: string;
    private _allDateFormat!: { key: string, value: string };
    private _allDateFormatForDatePipe!: { key: string, value: string };
    private _allCurrency!: { key: string, value: string };
    private _allAreaUnit!: { key: string, value: string };
    private _allLanguageCode!: { key: string, value: string };
    public setUserEvent: Subject<User | null> = new Subject<User | null>();

    constructor(private apiSvc: LibHttpClientApiService) {
        this._allDateFormat = this.transformEnumToList(DateFormatEnum);
        this._allCurrency = this.transformEnumToList(CurrencyEnum);
        this._allAreaUnit = this.transformEnumToList(AreaUnitEnum);
        this._allLanguageCode = this.transformEnumToList(LanguageCodeEnum);
        this._allDateFormatForDatePipe = this.transformEnumToList(DateFormatForDatePipeEnum);
    }

    get userName(): string {
        if (this._user !== null) {
            if (this._user.UserName != null)
            {
                return this._user.UserName;
            }
            else
                return this._user.LastName;
        }
        return "";
    }

    get email(): string {
        if (this._user !== null)
            return this._user.Email;
        return "";
    }
    
    get image(): string {
        if (this._user !== null){
            return this._user.Img;
        }
        return "";
    }

    get user(): User | null {
        return this._user;
    }

    set user(theUser: object | null) {
        if (theUser !== null) {
            let userInfo = theUser as User;

            this._user = {
                Id: userInfo.Id,
                LastName: userInfo.LastName,
                FirstName: userInfo.FirstName,
                LanguageCode: this.language,
                Phone: userInfo.Phone,
                Email: userInfo.Email,
                Img: userInfo.Img,
                UserName: userInfo.UserName,
                Roles: userInfo.Roles,
                DateFormat: (userInfo.DateFormat != null && userInfo.DateFormat != undefined && userInfo.DateFormat !== "") ? userInfo.DateFormat : "FR",

            }
        }
        else {
            this._user = null;
        }
    }

    get language(): string {
        return this._language;
    }

    set language(theLanguage: string) {
        this._language = theLanguage;
    }

    setUser(userConnected: any) {
        this._user = userConnected;
        this.setUserEvent.next(this._user);
    }

    getAll(param: ServerSideRequest) {
        return this.apiSvc.post<ServerSideRequest, ServerSideResponse>(UserUrls.GET_ALL, param);
    }

    getUsers() {
        return this.apiSvc.get<UserResponse[]>(UserUrls.GET_USERS);
    }

    viewUser(id: string) {
        return this.apiSvc.get<User>(`${UserUrls.VIEW}/${id}`);
    }

    delete(id: string) {
        return this.apiSvc.delete1<boolean>(`${UserUrls.DELETE}/${id}`);
    }

    addOrUpdate(user: User) {
        const url = user.Id ? UserUrls.UPDATE : UserUrls.ADD
        return this.apiSvc.put<User, boolean>(url, user);
    }

    getAllRole(id: string) {
        return this.apiSvc.get<Role[]>(`${RoleUrls.GET_USER_ROLES}/${id}`);
    }

    isInRoles(roles: string[]): Observable<boolean> {
        return this.apiSvc.get<Role[]>(RoleUrls.GET_CURRENT_USER_ROLES).pipe(
            switchMap((userRoles: Role[]) => {
                let filteredArray = roles.filter(value => userRoles.map((r: Role) => r.Name).includes(value));
                return of(filteredArray.length > 0);
            })
        );
    }

    get AllDateFormat(): { key: string, value: string } {
        return this._allDateFormat;
    }

    get AllDateFormatForDatePipe(): { key: string, value: string } {
        return this._allDateFormatForDatePipe;
    }

    get AllCurrency(): { key: string, value: string } {
        return this._allCurrency;
    }

    get AllAreaUnit(): { key: string, value: string } {
        return this._allAreaUnit;
    }

    get AllLanguageCode(): { key: string, value: string } {
        return this._allLanguageCode;
    }

    private transformEnumToList(enumToTransform: any): any {
        let listFromEnum: { key: string, value: string | unknown }[] = []

        Object.entries(enumToTransform).forEach(([key, value]) => {
            listFromEnum.push({ key, value });
        });

        return listFromEnum;
    }

    getEntryFromAKey(key: string, list: any): { key: string, value: string } {
        return list.find((element: { key: string, value: string }) => element.key == key);
    }

    getValueFromAKey(key: string, list: any): string {
        return (list.find((element: { key: string, value: string }) => element.key == key)).value;
    }

}
