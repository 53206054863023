<ul class="list-group">
    <lib-data-input-parameter
        *ngFor="let param of this.config['DataSource']['Parameters'] | keyvalue; let i = index"
        [displayStoredProcedure]="this.displayStoredProcedure"
        [allowRawBinding]="this.allowRawBinding"
        [allowCardBinding]="this.allowCardBinding"
        [allowPageBinding]="this.allowPageBinding"
        [listValues]="getPropertyDefinition(this.config['DataSource']['Parameters'][i].ParameterName)"
        [parameter]="this.config['DataSource']['Parameters'][i]"
    ></lib-data-input-parameter>
</ul>
