import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LibHerdiaAppTranslateModule, LibPropertyTypeInputModule } from '@herdia-common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LibSpecificParamReportConfigModule } from '../lib-specific-param-report-config/lib-specific-param-report-config.module';
import { LibDataInputParameterComponent } from './lib-data-input-parameter/lib-data-input-parameter.component';
import { LibDataInputParametersComponent } from './lib-data-input-parameters/lib-data-input-parameters.component';
import { LibDataInputSelectorComponent } from './lib-data-input-selector/lib-data-input-selector.component';

@NgModule({
    declarations: [
        LibDataInputSelectorComponent,
        LibDataInputParametersComponent,
        LibDataInputParameterComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        LibPropertyTypeInputModule,
        LibHerdiaAppTranslateModule,
        NgSelectModule,
        TranslateModule,
        LibSpecificParamReportConfigModule
    ],
    exports: [
        LibDataInputSelectorComponent,
        LibDataInputParametersComponent
    ]
})
export class LibProcedureInputModule { }
