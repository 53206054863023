import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LibFilterCardComponent } from './lib-filter-card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { LibHerdiaAppTranslateModule } from '@herdia-common';
import { LibPropertyTypeInputModule } from '@herdia-common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LibFilterCardComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
    LibHerdiaAppTranslateModule,
    LibPropertyTypeInputModule,
    TranslateModule
  ],
  exports: [
    LibFilterCardComponent
  ]
})
export class LibFilterCardModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
