<button type="button" class="btn btn-tool" #p="ngbPopover" placement="bottom-right" [popoverTitle]="popDownloadTitle" [ngbPopover]="popDownloadContent" [autoClose]="false">
  <fa-icon icon="download"></fa-icon>
</button>
<ng-template #popDownloadTitle><lib-translate [key]="'lbl-downloads'"></lib-translate></ng-template>
<ng-template #popDownloadContent>
  <div class="d-inline-flex justify-content-around w-100 mb-2">
    <button class="btn btn-primary btn-sm" (click)="p.close();onAskExportCSV();">CSV</button>
    <button class="btn btn-primary btn-sm" (click)="p.close();onAskExportXLSX();">XSLX</button>
  </div>
  <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <span class="text-truncate"><fa-icon icon="file-csv" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'lbl-csv-file'"></lib-translate></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="form-group">
          <input type="checkbox" id="quoteAllFields" class="form-check-input" [(ngModel)]="quoteAllFields" />&nbsp;
          <label for="quoteAllFields"><lib-translate [key]="'lbl-quote-all-fields'"></lib-translate></label>
        </div>
        <div class="form-group">
          <label for="csv-delimiter"><lib-translate [key]="'lbl-csv-delimiter-char'"></lib-translate></label>
          <input type="text" id="csv-delimiter" class="form-control" [(ngModel)]="delimiter" />
        </div>
        <div class="form-group">
          <label for="csv-export-name"><lib-translate [key]="'lbl-csv-export-name'"></lib-translate></label>
          <input type="text" id="csv-export-name" class="form-control" [(ngModel)]="exportName" maxlength="30" />
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <span class="text-truncate"><fa-icon icon="file-excel" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'lbl-xlsx-file'"></lib-translate></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="form-group">
          <label for="xlsx-export-name"><lib-translate [key]="'lbl-xlsx-export-name'"></lib-translate></label>
          <input type="text" id="xlsx-export-name" class="form-control" [(ngModel)]="exportName" maxlength="30" />
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-template>
