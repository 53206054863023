import { NgModule } from '@angular/core';
import { LibHttpClientApiModule } from '@herdia-common'

@NgModule({
    declarations: [
    ],
    imports: [
        LibHttpClientApiModule
    ],
    exports: [
    ]
})
export class LibApiModule { }
