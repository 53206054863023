<div class="modal-header">
  <h4 class="modal-title"><lib-translate [key]="'add-category-modal-title'" ></lib-translate></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label><lib-translate [key]="'lbl-icon'"></lib-translate></label>
    <lib-icon-picker (iconSelectedOutput)="onIconPickerSelect($event)"></lib-icon-picker>
    <label *ngIf="emptyIcon" class="text-danger"><lib-translate [key]="'lbl-icon-missing'"></lib-translate></label>
  </div>
  <div class="form-group">
    <label for="categoryLabel"><lib-translate [key]="'add-category-modal-category-label'"></lib-translate></label>
    <input type="text" class="form-control" name="categoryLabel" placeholder="{{addCategoryModaCategoryLabel}}" [(ngModel)]="categoryConfig.label">
  </div>
  <!--<div class="form-group">
    <label for="categoryDescription"><lib-translate [key]="'add-category-modal-category-description'" ></lib-translate></label>
    <input type="text" class="form-control" name="categoryDescription" placeholder="{{addCategoryModalCategoryDescription}}" [(ngModel)]="categoryConfig.description">
  </div>-->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="dismiss()"><lib-translate [key]="'lbl-close'"></lib-translate></button>
  <button type="button" class="btn btn-primary" (click)="onValidate()"><lib-translate [key]="'lbl-validate'"></lib-translate></button>
  </div>
