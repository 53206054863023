import { Component, OnChanges } from '@angular/core';
import { CardConfigBaseComponent, PropertyDefinition } from '@herdia-common';

/**
 * TODO(migration): vérifier qu'il est toujours possible de définir les paramètres de type date
 */
@Component({
    selector: 'lib-card-report-config-scheduling-parameters',
    templateUrl: './lib-card-report-config-scheduling-parameters.component.html',
    styleUrls: ['./lib-card-report-config-scheduling-parameters.component.css']
})
export class LibCardReportConfigSchedulingParametersComponent extends CardConfigBaseComponent implements OnChanges {

    listValuesPropertyDefinitions: PropertyDefinition[] = [];

    ngOnChanges(): void {
        if (this.config["SchedulingReport"]) {
            if (this.config["DataSource"]) {
                this.config["SchedulingReport"]["DataSource"] = JSON.parse(JSON.stringify(this.config["DataSource"]));
            }
            else {
                this.config["SchedulingReport"]["DataSource"] = {};
            }
        }
        else {
            this.config["SchedulingReport"] = {};
            if (this.config["DataSource"]) {
                this.config["SchedulingReport"]["DataSource"] = JSON.parse(JSON.stringify(this.config["DataSource"]));
            }
            else {
                this.config["SchedulingReport"]["DataSource"] = {};
            }
        }
    }
}
