<div class="modal-header">
  <h4 class="modal-title"><lib-translate [key]="'app-card-config-save-header'"></lib-translate> {{card?.Title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">
    <div class="form-group">
      <label for="cardDefinedConfigTitle"><lib-translate [key]="'app-card-config-save-title'"></lib-translate></label>
      <input type="text" class="form-control" id="cardDefinedConfigTitle" [placeholder]="configTitlePlaceHolder" [(ngModel)]="templateCardTitle" >
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()"><lib-translate [key]="'app-btn-cancel'"></lib-translate></button>
  <button type="button" class="btn btn-primary" (click)="save()"><lib-translate [key]="'app-btn-save'"></lib-translate></button>
</div>
