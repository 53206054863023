import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { LibHttpClientApiService } from '../lib-http-client-api/lib-http-client-api.service';
import { TranslateUrls } from './lib-herdia-app-translate.enums';

@Injectable({
  providedIn: 'root'
})
export class LibHerdiaAppTranslateService implements OnDestroy {

  private destroy$ = new Subject<void>();

  defaultLang = 'fr';
  selectedLang = this.defaultLang;

  constructor(private apiService: LibHttpClientApiService, private translateService: TranslateService) {
    this.initializeTranslations().pipe(takeUntil(this.destroy$)).subscribe();
  }

  public initializeTranslations(): Observable<void> {
    return new Observable<void>(observer => {
      if (!localStorage.getItem('herdiaTranslations')) {
        this.apiService.get(TranslateUrls.GET_TRANSLATIONS).pipe(
          switchMap((translations: any) => {
            return this.apiService.get(TranslateUrls.GET_SIGNATURE).pipe(
              switchMap((signature: any) => {
                localStorage.setItem('herdiaTranslations', JSON.stringify({ translations }));
                localStorage.setItem('herdiaTranslationsSignature', signature.Signature);
                return this.translateService.use(this.defaultLang);
              }),
              catchError(error => {
                console.error('Error fetching translation signature:', error);
                return this.translateService.use(this.defaultLang);
              }),
              takeUntil(this.destroy$)
            );
          }),
          catchError(error => {
            console.error('Error fetching translations:', error);
            return this.translateService.use(this.defaultLang);
          }),
          takeUntil(this.destroy$)
        ).subscribe({
          complete: () => {
            observer.next(); // Notify that the initialization is complete
            observer.complete();
          }
        });
      }
      else {
        observer.next(); // Notify if translations are already present
        observer.complete();
      }
    });
  }

  public setDefaultLang(lang: string) {
    this.defaultLang = lang;
    this.translateService.setDefaultLang(lang);
  }

  public use(lang: string) {
    this.selectedLang = lang;
    this.translateService.reloadLang(lang);
    this.translateService.use(lang);
  }

  public get(key: string) {
    var response: string = '';
    var observable = this.translateService.get(key);
    observable.subscribe(r => response = r);
    return response;
  }

  updateCurrentTranslation(key: string, translationValue: string): Observable<string> | null {
    return this.apiService.post(TranslateUrls.UPDATE_TRANSLATION, { Language: this.translateService.currentLang, Code: key, Value: translationValue });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
