import { Component, Input, OnInit } from '@angular/core';
import { LibDynamicComponentsEventBusService } from '@herdia-common';

@Component({
  selector: 'lib-card-input-mapping-footer',
  templateUrl: './lib-card-input-mapping-footer.component.html',
  styleUrls: ['./lib-card-input-mapping-footer.component.css']
})
export class LibCardInputMappingFooterComponent implements OnInit {
  @Input() eventBusService!: LibDynamicComponentsEventBusService;
  @Input() config: { [key: string]: any } = {};
  cardId!: number;

  ngOnInit(): void {
    this.cardId = this.config['cardId'];
  }

  hasCardFilter(): boolean {
    return true; //this.config["DataSource"]["Parameters"].filter((p: any) => p["ParameterBinding"]["Type"] === "cardFilter").length > 0;
  }
}
